import React from 'react';
import { useTranslate } from "@hooks/use-translate";
import { ErrorPage, LoginPage } from "..";

interface LoginOrErrorOrPageProps {
  loginTitle?: string;
  errorMsg?: string;
  notLogged?: boolean;
  children?: React.ReactNode;
}

export const LoginOrErrorOrPage: React.FC<LoginOrErrorOrPageProps> = props => {
  const { t } = useTranslate();

  if (props.notLogged) {
    return <LoginPage title={props.loginTitle ?? t('global.need_login_page_title')} reloadSameUrl />
  }
  if (props.errorMsg) {
    return <ErrorPage errMsg={props.errorMsg} />;
  }

  return <React.Fragment>{props.children}</React.Fragment>;
}

interface ErrorOrPageProps {
  errorMsg?: string;
  children?: React.ReactNode;
}

export const ErrorOrPage: React.FC<ErrorOrPageProps> = props => {
  if (props.errorMsg) {
    return <ErrorPage errMsg={props.errorMsg} />;
  }

  return <React.Fragment>{props.children}</React.Fragment>;
}

export default LoginOrErrorOrPage;
