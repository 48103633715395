import React, { useState } from 'react';
import styles from './DatePicker.module.scss';
import { useTranslate } from '@hooks/.';
import classNames from 'classnames';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import moment, { Moment } from 'moment';
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import { OwardButton } from '../Button';

interface OwardFormDatePickerProps {
  id: string;
  label: string;
  placeholder?: string;
  value?: string;
  isClearable?: boolean;
  formik: any;
}

export const OwardFormDatePicker: React.FC<OwardFormDatePickerProps> = props => {
  const { locale } = useTranslate();
  const [focused, setFocused] = useState(false);

  moment.locale(locale);

  let years = [];
  for (let i = 1900; i <= 2100; i++) {
    years.push(<option value={i} key={`year-${i}`}>{i}</option>)
  }

  const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) =>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className={styles.selectContainer}>
        <select
          value={month.month()}
          onChange={(e) => onMonthSelect(month, e.target.value)}
          className={styles.select}
        >
          {moment.months().map((label, value) => (
            <option key={value} value={value}>{label}</option>
          ))}
        </select>
      </div>
      <div className={styles.selectContainer}>
        <select
          value={month.year()}
          onChange={(e) => onYearSelect(month, e.target.value)}
          className={styles.select}
        >
          {years}
        </select>
      </div>
    </div>

  return (
    <div className='field' style={{ width: '100%' }}>
      <label className={classNames(styles.label)}>{props.label}</label>
      <div className={classNames('control', styles.inputAndClear)} style={{ width: '100%' }}>
        <div className={classNames(styles.inputContainer)}>
          <SingleDatePicker
            date={props.formik.values[props.id] === undefined ? undefined : moment(props.formik.values[props.id])}
            onDateChange={(date: Moment) => { props.formik.setFieldValue(props.id, date) }}
            focused={focused}
            onFocusChange={({ focused }) => { setFocused(focused) }}
            id={'date-' + props.id}
            block
            hideKeyboardShortcutsPanel
            isOutsideRange={() => false}
            numberOfMonths={1}
            displayFormat={() => 'DD MMM yyyy'}
            renderMonthElement={renderMonthElement}
          />
        </div>
        {
          (props.isClearable && props.formik.values[props.id]) &&
          <div className={classNames(styles.clearContainer)}>
            <OwardButton
              iconName='fas fa-times'
              outlined
              onClick={() => props.formik.setFieldValue(props.id, undefined)}
            />
          </div>
        }
      </div>
      {
        (props.formik.touched[props.id] && props.formik.errors[props.id]) &&
        <div className={styles.errorContainer}>
          <p className={styles.error}>{props.formik.errors[props.id]}</p>
        </div>
      }
    </div>
  );
}

OwardFormDatePicker.defaultProps = {
  //type: 'text',
} as Partial<OwardFormDatePickerProps>;
