import React, { useState, useEffect } from 'react';
import { send, UserController, User, UserRole, VerificationState, State } from '@oward/openapi';

import { PaginatedList, PaginatedListFilterType, PaginatedListKey, Section } from '@components/admin';
import { EditUser } from './EditUser';
import { strToPath, stateToEmoji } from '@oward/common-utils';
import { emptyIfUndef } from '@utils/.';
import moment from 'moment';
import { UserType } from '@oward/common-enums';

export const UserList: React.FC = () => {

  const [items, setItems] = useState([] as User[]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = async () => {
    try {
      setLoading(true);
      setError(false);
      setItems(await send(UserController.find()))
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const keys: PaginatedListKey<User>[] = [
    {
      label: 'Id',
      value: item => item.id
    },
    {
      label: 'Email',
      value: item => item.email.concat(
        item.role === UserRole.ADMIN ? ' (ADMIN)' : '',
        item.type === UserType.RECRUITER ? '👩‍💻' : '',
        item.isEmailVerified !== VerificationState.VERIFIED ? ' (📧 not verified)' : '',
        ' ', stateToEmoji(item.state)
      )
    },
    {
      label: 'Name',
      value: (item: User) => emptyIfUndef(item.infos?.firstName).concat(' ', emptyIfUndef(item.infos?.lastName)).concat(item.infos.phoneNumber ? ' 📞' : '')
    },
    {
      label: 'Number of profiles',
      value: item => item.profiles?.length
    },
    {
      label: 'Creation Date',
      value: item => moment(item.creationDate).format('DD MMM yyyy'),
      sortValue: item => Date.parse(item.creationDate)
    },
    {
      label: 'Last Connection',
      value: (item: User) => {
        if (item.lastConnectionDate) { return moment(item.lastConnectionDate).format('lll') }
        else { return 'Never connected' }
      },
      sortValue: item => item.lastConnectionDate ? Date.parse(item.lastConnectionDate) : 0
    },
  ];

  const filterItemStrings = (item: User) => [
    item.id.toString(),
    strToPath(item.email),
    strToPath(item.infos?.firstName),
    strToPath(item.infos?.lastName)
  ];

  const filterComponents = [
    {
      name: 'State',
      type: PaginatedListFilterType.DROPDOWN,
      options: Object.values(State).map((state) => {
        return ({ value: state, label: state });
      }),
      filterFunction: (item: User, value: string) => item.state === value
    },
    {
      name: 'number of Profiles',
      type: PaginatedListFilterType.INPUT,
      filterFunction: (item: User, value: string) => item.profiles.length === parseInt(value)
    },
  ];

  const fetchOne = (item: User) => send(UserController.findOne(item.id));

  return (
    <Section
      title='List'
      subtitle='The list of all users in the database'
      childrenFullWidth
    >
      <PaginatedList
        keys={keys}
        items={items}
        filterItemStrings={filterItemStrings}
        filterComponents={filterComponents}
        size={100}
        editComponent={EditUser}
        fetchOne={fetchOne}
        reloadList={loadItems}
        loading={loading}
        error={error}
      />
    </Section>
  );
}
