import React from 'react';

import { Divider } from '@components/admin';
import { AwardCategoryStatistics } from './AwardCategoryStatistics';
import { AwardCategoryList } from './AwardCategoryList';

export const AwardCategoriesComponent: React.FC = () => {
  return (
    <React.Fragment>
      <AwardCategoryStatistics />
      <Divider />
      <AwardCategoryList />
    </React.Fragment>
  );
}
