import React, { useState, useEffect } from 'react';
import { send, MessageController, DiscussionMember, DiscussionType, State } from '@oward/openapi';
import { FormComment, PaginatedList, PaginatedListFilterType, PaginatedListKey, Section, } from '@components/admin';
import { profileToStrWithId, strToPath, stateToEmoji } from '@oward/common-utils';
import moment from 'moment';
import { EditDiscussionMember } from './EditDiscussionMember';
import { DiscussionMemberRole } from '@oward/common-enums';

interface DiscussionMemberListProps {
  discussionId: number;
  discussionType: DiscussionType;
}

export const DiscussionMemberList: React.FC<DiscussionMemberListProps> = props => {
  const [items, setItems] = useState([] as DiscussionMember[]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadItems = async () => {
    try {
      setLoading(true);
      setError(false);
      setItems(await send(MessageController.listMembersOfDiscussion(props.discussionId)))
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const keys: PaginatedListKey<DiscussionMember>[] = [
    {
      label: 'Id',
      value: item => item.id + ' ' + stateToEmoji(item.state),
      sortValue: item => item.id
    },
    {
      label: 'Profile',
      value: item => profileToStrWithId(item.profile)
    },
    {
      label: 'Role',
      value: item => item.role
    },
    {
      label: 'Creation Date',
      value: item => moment(item.creationDate).format('lll'),
      sortValue: item => Date.parse(item.creationDate)
    },
    {
      label: 'Date Notified',
      value: item => moment(item.dateNotified).format('lll'),
      sortValue: item => Date.parse(item.dateNotified)
    }
  ];

  const filterItemStrings = (item: DiscussionMember) => [
    item.id.toString(),
    strToPath(profileToStrWithId(item.profile)),
    strToPath(item.role)
  ];

  const filterComponents = [
    {
      name: 'State',
      type: PaginatedListFilterType.DROPDOWN,
      options: Object.values(State).map((state) => {
        return ({ value: state, label: state + ' ' + stateToEmoji(state) });
      }),
      filterFunction: (item: DiscussionMember, value: string) => item.state === value
    },
    {
      name: 'Role',
      type: PaginatedListFilterType.DROPDOWN,
      options: Object.values(DiscussionMemberRole).map((visibility) => {
        return ({ value: visibility, label: visibility });
      }),
      filterFunction: (item: DiscussionMember, value: string) => item.role === value
    },
  ];

  const fetchOne = (item: DiscussionMember) => send(MessageController.findOneMember(item.id));

  return (
    <React.Fragment>
      <Section
        title='Members'
        subtitle='List of all Members in the Discussion'
        childrenFullWidth
      >
        <FormComment>
          <p>🗑️ DELETED = Left the discussion</p>
          <p>🚫 BANNED = Was Removed form the Discussion by an Admin</p>
        </FormComment>
        <PaginatedList
          keys={keys}
          items={items}
          filterItemStrings={filterItemStrings}
          filterComponents={filterComponents}
          editComponent={props.discussionType !== DiscussionType.ONETOONE ? EditDiscussionMember : undefined}
          fetchOne={fetchOne}
          size={20}
          reloadList={loadItems}
          loading={loading}
          error={error}
        />
      </Section>
      <div style={{ height: '2rem' }} />
    </React.Fragment>
  );
}
