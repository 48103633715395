import React, { useState } from 'react';
import { useFormik } from 'formik';
import styles from './Login.module.scss';
import { useInjection, useTranslate } from '@hooks/.';
import { useRouter } from 'next/router';
import { UserController } from '@oward/openapi';
import { OwardButton, OwardLinkButton, OwardFormInput, OwardFormSwitch, OwardLoader, Link, TitlePage } from '@components/.';
import { StoresBindings } from '@container/.';
import { LayoutStore, ModalKey, ModalStore, PopupStore, UserStore } from '@stores/.';
import * as Yup from 'yup';
import classNames from 'classnames';
import { ERR_MSG_FORBIDDEN, ERR_MSG_GONE, ERR_MSG_NOT_ACCEPTABLE, goToHomePageAndReload, onTouchDevice } from '@utils/.';

interface LoginFormProps {
  title?: string;
  reloadSameUrl?: boolean;
}

const schema = (t: any) => {
  return (
    Yup.object().shape({
      email: Yup.string().email(t('forms.email.invalid')).required(t('forms.email.required')),
      password: Yup.string().required(t('forms.password.required'))
    }))
};

export const LoginForm: React.FC<LoginFormProps> = props => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const [error, setError] = useState<string>(undefined);
  const [showResendEmail, setShowResendEmail] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: false
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      try {
        setLoading(true);
        await UserController.login({
          email: values.email,
          password: values.password
        },
          locale,
          values.remember
        );
        userStore.setAutologinTimer(() => {
          popupStore.openInformationPopUp({
            msg: t('global.force_logout'),
            callback: () => { goToHomePageAndReload() }
          });
        });
        if (props.reloadSameUrl) {
          layoutStore.setPageLoading(true);
          router.reload();
        }
        else {
          router.replace('/[lang]', `/${locale}`);
        }
        setError(undefined);
        setShowResendEmail(false);
      } catch (err) {
        if (err instanceof Error && err.message === ERR_MSG_FORBIDDEN) {
          setError(t('login.error_blocked'));
        }
        else if (err instanceof Error && err.message === ERR_MSG_GONE) {
          setError(t('login.error_deleted'));
        }
        else if (err instanceof Error && err.message === ERR_MSG_NOT_ACCEPTABLE) {
          setError(t('login.error_mail_not_verified', { mail: formik.values.email }));
          setShowResendEmail(true);
        }
        else {
          setError(t('login.error'));
        }
      }
      finally {
        setLoading(false);
      }
    }
  });

  return (
    <div className={classNames(styles.mainContainer)}>
      {
        props.title && onTouchDevice() ?
          <p className={classNames(styles.alternativeTitle)}>{props.title}</p>
          :
          <TitlePage title={t('login.title')} />
      }
      <form className={classNames(styles.formContainer)} onSubmit={formik.handleSubmit} >
        <OwardLoader loading={loading} />
        <OwardFormInput
          id='email'
          type='email'
          label={t('forms.email.label')}
          icon='far fa-envelope'
          placeholder={t('forms.email.placeholder')}
          formik={formik}
        />
        <OwardFormInput
          id='password'
          type='password'
          label={t('forms.password.label')}
          icon='fa fa-lock'
          placeholder='********'
          formik={formik}
        />
        <OwardFormSwitch
          id='remember'
          label={t('forms.remember_me.label')}
          formik={formik}
        />
        {
          error &&
          <div className={styles.errorContainer}>
            <p className={styles.error}>{error}</p>
          </div>
        }
        {
          !showResendEmail &&
          <React.Fragment>
            <OwardButton
              name={t('login.login')}
              submit
              fullWidth
            />
            <div className={styles.linkContainer}>
              <p
                className={styles.link}
                onClick={() => { modalStore.openModalNewStack(router, ModalKey.RESET_PASSWORD, 1) }}
              >
                {t('login.forgotten_password')}
              </p>
            </div>
            <hr />
            <div className={styles.linkContainer}>
              <p>
                {t('login.not_registered_yet')}
              </p>
              <Link href='/register' passHref>
                <OwardLinkButton
                  name={t('login.register')}
                  outlined
                />
              </Link>
            </div>
          </React.Fragment>
        }
      </form >
    </div>
  );
}

export default LoginForm;
