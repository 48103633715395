export const NUMBER_CARD_LOADED_INIT = 20; // # of cards loaded at init (or when setting a filter)
export const NUMBER_CARD_LOADED_SCROLL = 10; // # of cards loaded when reaching bottom of page (inifinte scroll)
export const NUMBER_ARTWORK_LOADED_INIT = 20; // # of artworks (in the gallery) loaded at init (or when setting a filter)
export const NUMBER_ARTWORK_LOADED_SCROLL = 30; // # of artworks (in the gallery) loaded when reaching bottom of page (inifinte scroll)
export const NUMBER_ARTWORK_LOADED_MOBILE = 10; // # of artworks (in the gallery) loaded | on mobile

export const ORDER_INIT_RANDOM = true; // Ordering mode when landing on the site (true = random / false = recent update first)

export const NUMBER_MESSAGES_LOADED_BY_PAGE = 50; // In discussion, for infinite scroll of messages

export const NUMBER_HASHTAG_LOADED = 50;

export const ZOOM_RATIO_MIN = 0.4;
export const ZOOM_RATIO_MAX = 1.2;

export const SHORT_BIO_CHAR_LIMIT = 140;

export const SITEMAP_NUMBER_OF_PROFILES_PER_PAGES = 24000;

export const ERR_MSG_UNAUTHORIZED = 'Unauthorized';
export const ERR_MSG_FORBIDDEN = 'Forbidden';
export const ERR_MSG_ABORTED = 'Aborted';
export const ERR_MSG_BAD_REQUEST = 'Bad Request';
export const ERR_MSG_NOT_FOUND = 'Not Found';
export const ERR_MSG_CONFLICT = 'Conflict';
export const ERR_MSG_GONE = 'Gone';
export const ERR_MSG_NOT_ACCEPTABLE = 'Not Acceptable';
export const ERR_MSG_GATEWAY_TIMEOUT = 'Gateway Timeout';
export const ERR_MSG_IM_A_TEAPOT = 'I\'m a Teapot';

export const COOKIE_NAME_GDPR_PREFS = 'gdpr_prefs';
export const COOKIE_NAME_ZOOM = 'zoom';

export const URL_VALIDATION_REGEX = /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}.*$/;
export const PHONE_VALIDATION_REGEX = /^[\+]?[(]?[0-9]{2,3}[)]?[-\s\.0-9]{6,16}$/g;

export const DEFAULT_PRICING_INTERVAL = 'month';

export const ARTWORK_COVER_PLACEHOLDER_URL = '/artwork_placeholder.png';

export const SWR_USER_SHORTLISTS_GET = '/shortlist/get';
export const SWR_SHORTLIST_GET_PROFILES = '/shortlist/getProfiles';
export const SWR_ON_FIRE_PROFILE_IDS = '/onfire/getProfileIds';
export const SWR_ON_FIRE_PROFILES = '/onfire/getProfiles';
export const SWR_PROFILES_FOR_USER = '/profile/findForUser';
export const SWR_MESSAGES_FOR_DISCUSSION = '/message/getDiscussionMessages';
export const SWR_DISCUSSIONS_FOR_PROFILE = '/profile/findOneForMessages';
export const SWR_DISCUSSIONS_UNREAD_COUNT_FOR_USER = '/message/getNumberOfDiscussionsUnread';
export const SWR_BLOCKED_PROFILE_IDS = '/block/getBlockedProfileIds';
export const SWR_BLOCKED_ARTWORK_IDS = '/block/getBlockedArtworkIds';

export const SITEMAPSLIST = ["agent.tsx", "association.tsx", "festival.tsx", "film_type_locations_combinations.tsx", "job_film_type_combinations.tsx", "job_location_combinations.tsx", "job.tsx", "locations.tsx", "static_links.tsx"];
