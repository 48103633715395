
import React, { useCallback, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutStore, ArtworkStore } from '@stores/.'
import { useInjection, useTranslate } from '@hooks/.';
import { StoresBindings } from '@container/.';
import { Artwork } from '@oward/openapi';
import styles from './GalleryCommon.module.scss';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import Loader from 'react-loader-spinner';
import { ArtworkSize, CardArtwork, artworkToCardArtwork } from '@components/.';
// Intersection Observer polyfill : https://github.com/GoogleChromeLabs/intersection-observer
require('intersection-observer');

export const GalleryArtworks = observer(() => {
  const { t, locale } = useTranslate();
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);
  const artworkStore = useInjection<ArtworkStore>(StoresBindings.ARTWORK);

  // Infinite Scroll (see https://www.youtube.com/watch?v=NZKUirTtxcg)
  const observerLastArtwork = useRef(null);
  const lastArtworkElementRef = useCallback(node => {
    if (artworkStore.isLoading || artworkStore.isMoreLoading) {
      return;
    }
    if (observerLastArtwork.current) {
      observerLastArtwork.current.disconnect();
    }
    observerLastArtwork.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && artworkStore.hasMore) {
        artworkStore.getMoreArtworks();
      }
    })
    if (node) {
      observerLastArtwork.current.observe(node);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artworkStore.isLoading, artworkStore.isMoreLoading, artworkStore.hasMore]);

  const observerBottomBar = useRef(null);
  const displayBottomBarElementRef = useCallback(node => {
    if (observerBottomBar.current) {
      observerBottomBar.current.disconnect();
    }
    observerBottomBar.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && !layoutStore.sidebarOpened) {
        layoutStore.displayBottomBar(true);
      }
      else {
        layoutStore.displayBottomBar(false);
      }
    })
    if (node) {
      observerBottomBar.current.observe(node);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`${styles.mainContainer}`}>
      {
        artworkStore.isLoading &&
        <div className={`${styles.loaderContainer}`}>
          <Loader
            type='Grid'
            color={styles.greyDark}
            height={70}
            width={70}
          />
        </div>
      }
      {
        (artworkStore.artworks !== undefined && artworkStore.artworks.length > 0) ?
          <React.Fragment>
            <div ref={displayBottomBarElementRef} className={styles.displayBottomBarEmptyDiv} />
            <section id='artworks-grid' className={`${styles.gridArtworkContainer}`}>
              {
                artworkStore.artworks.map((artwork: Artwork, index: number) => {
                  // Ref of the last Card on screen, used for the infinite scroll
                  return <div
                    ref={artworkStore.artworks.length === index + 1 ? lastArtworkElementRef : undefined}
                    className={styles.artworkContainer}
                    key={index}
                  >
                    <CardArtwork
                      artwork={artworkToCardArtwork(artwork, locale, artwork.profile)}
                      artworkSize={ArtworkSize.MEDIUM_SMALL}
                      descriptionProfile
                    />
                  </div>;
                })
              }
              {
                artworkStore.isMoreLoading &&
                <div className={`${styles.loaderMoreContainer} ${styles.loaderMoreContainerArtwork}`}>
                  <Loader
                    type='Grid'
                    color={styles.greyDark}
                    height={70}
                    width={70}
                  />
                </div>
              }
            </section >
          </React.Fragment>
          :
          <div className={`${styles.emptyContainer}`}>
            {
              !artworkStore.isLoading &&
              <p>
                {artworkStore.error ? t('gallery.error') : t('gallery.empty_artwork')}
              </p>
            }
          </div>
      }
    </div>
  );
});

const GalleryArtworksScroll = ({ scrollPosition }) => {
  const artworkStore = useInjection<ArtworkStore>(StoresBindings.ARTWORK);
  artworkStore.setScrollPosition(scrollPosition);

  return (
    <GalleryArtworks />
  )
}

export default trackWindowScroll(GalleryArtworksScroll);
