import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import { ModalKey, ModalStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { useInjection, useTranslate } from '@hooks/.';
import { Modal } from '@components/Modal';
import { ArtworkPage } from '@components/Pages/Artwork';
import { ArtworkModalI, ArtworkController, send } from '@oward/openapi';

export const ArtworkModal: React.FC = observer(() => {
  const router = useRouter();
  const { locale } = useTranslate();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  useEffect(() => {
    // In case someone arrives directly on the gallery with a "artwork=...." in the query,
    // or if the path of the artwork in store is different from the one in the url (typically when going backward)
    // we need to fetch artwork data
    (async function getArtwork() {
      try {
        if (
          !!router.query[ModalKey.ARTWORK] &&
          (modalStore.artwork === undefined || modalStore.artwork.cardArtwork.path !== router.query[ModalKey.ARTWORK] as string)
        ) {
          const artwork: ArtworkModalI = await send(ArtworkController.getForModal(locale, router.query[ModalKey.ARTWORK] as string));
          modalStore.setArtwork(artwork);
        }
      }
      catch (err) {
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query[ModalKey.ARTWORK] as string])

  return (
    <Modal modalKey={ModalKey.ARTWORK} dark fixedSize noPadding>
      <ArtworkPage />
    </Modal>
  );
});
