import React, { useState, useEffect, useContext } from 'react';
import { send, JobsController, Gender, JobCategory } from '@oward/openapi';

import { PaginatedList, PaginatedListKey, Section, NavigationContext } from '@components/admin';
import { findTranslation, stateToEmoji, strToPath } from '@oward/common-utils';
import { OwardButton } from '@components/Core';
import { EditJobCategory } from './EditJobCategory';

export const JobCategoryList: React.FC = () => {

  const store = useContext(NavigationContext);
  const [items, setItems] = useState([] as JobCategory[]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = async () => {
    try {
      setLoading(true);
      setError(false);
      setItems(await send(JobsController.findCategories()))
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const keys: PaginatedListKey<JobCategory>[] = [
    {
      label: 'Id',
      value: item => item.id
    },
    {
      label: 'Name',
      value: item => item.name.concat(' ', stateToEmoji(item.state))
    },
    {
      label: 'Name FR',
      value: item => findTranslation(item, 'fr')
    },
    {
      label: 'Name EN',
      value: item => findTranslation(item, 'en')
    },
    {
      label: 'Number of Jobs',
      value: item => item.jobs?.length
    }
  ];

  const filterItemStrings = (item: JobCategory) => [
    item.id.toString(),
    strToPath(item.name),
    strToPath(findTranslation(item, 'fr')),
    strToPath(findTranslation(item, 'en'))
  ];

  const fetchOne = (item: JobCategory) => send(JobsController.findOneCategory(item.id));

  return (
    <React.Fragment>
      <Section
        title='List of Job Categories'
        subtitle='The list of all job categories in the database'
        childrenFullWidth
      >
        <PaginatedList
          keys={keys}
          items={items}
          filterItemStrings={filterItemStrings}
          size={10}
          editComponent={EditJobCategory}
          fetchOne={fetchOne}
          reloadList={loadItems}
          loading={loading}
          error={error}
        />
      </Section>
      <div style={{ height: '2rem' }} />
      <Section
        title='Create new Job Category'
        subtitle='Create it WHEN PRESSING THIS BUTTON. Cannot be undone, please fill all needed infos in next screen'
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <OwardButton
            name='Create Job Category'
            onClick={async () => {
              let category: JobCategory = await send(JobsController.createOneCategory());
              loadItems();
              store.pop({
                label: 'Create Job Category',
                element: <EditJobCategory item={category} notify={loadItems} noCancel />
              });
            }}
          />
          <p className='has-text-danger'>
            Newly created Job Category will be in BLOCKED State.
          </p>
        </div>
      </Section>
    </React.Fragment>
  );
}
