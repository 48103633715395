import React from 'react';

import { ShortlistStatistics } from './ShortlistStatistics';
import { Divider } from '@components/admin';
import { ShortlistList } from './ShortlistList';

export const ShortlistComponent: React.FC = () => {
  return (
    <React.Fragment>
      <ShortlistStatistics />
      <Divider />
      <ShortlistList />
    </React.Fragment>
  );
}
