import React from 'react'

interface FormCommentProps {
  children?: React.ReactNode;
}

export const FormComment: React.FC<FormCommentProps> = props => {
  return (
    <div style={{ fontSize: '0.8rem', marginBottom: '1rem', marginTop: '-0.5rem' }}>
      {props.children}
    </div>
  );
}
