import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { FilmType, FilmsController, State } from '@oward/openapi';
import { locales } from '@locales/.';
import { groupByLocale, flattenFromGrouped, yupTranslationsShape } from '../utils';
import { Section, Divider, SubmitOrCancel, NavigationContext } from '@components/admin';
import { EditProps } from '../types';
import { OwardFormInput, Heading, OwardFormSelect } from '@components/Core';
import * as Yup from 'yup';
import { useTranslate } from '@hooks/.';
import { stateToEmoji } from '@oward/common-utils';

const schema = (t: any) => {
  return (
    Yup.object().shape({
      name: Yup.string().required(),
      translations: yupTranslationsShape,
    })
  )
};

export const EditFilmType = observer((props: EditProps<FilmType>) => {

  const { t } = useTranslate();
  const [error, setError] = useState(false);
  const navigation = useContext(NavigationContext);

  const formik = useFormik({
    initialValues: {
      name: props.item.name,
      translations: groupByLocale(props.item.translations),
      state: props.item.state
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      // Take the french name as the name
      props.item.name = values.translations['fr']?.name;
      props.item.translations = flattenFromGrouped(values.translations);
      props.item.state = values.state;
      try {
        await FilmsController.saveOneType(props.item);
        if (props.notify) { props.notify(props.item) };
        setError(false);
        navigation.unpop();
      } catch { setError(true) }
    }
  });

  return (
    <Section
      title={`Edit "${props.item.name}" Film Type`}
      subtitle='Change your entity properties'>
      <form className='box' onSubmit={formik.handleSubmit} >
        <Heading>General</Heading>
        {
          locales.map(locale =>
            <React.Fragment key={locale}>
              <OwardFormInput
                id={`translations.${locale}.name`}
                label={`Name ${locale}*`}
                icon='fas fa-signature'
                value={formik.values.translations[locale]?.name}
                error={formik.errors.translations && formik.errors.translations[locale]?.name}
                formik={formik}
              />
            </React.Fragment>
          )
        }
        <OwardFormSelect
          id='state'
          label='State *'
          options={Object.values(State).map((state) => {
            return ({ value: state, label: state.concat(' ', stateToEmoji(state)) });
          })}
          formik={formik}
        />
        <Divider />
        <SubmitOrCancel error={error} noCancel={props.noCancel} />
      </form >
      <div style={{ height: '200px' }} />
    </Section >
  )
});
