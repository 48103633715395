import React from 'react';

import { DashboardHeader } from './DashboardHeader';

export const DashboardComponent: React.FC = () => {
  return (
    <React.Fragment>
      <DashboardHeader />
    </React.Fragment>
  );
}
