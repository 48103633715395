import React from 'react';
import { GDPR, HtmlHead, OwardModals, OwardToastContainer, PopUp, WithNavbar } from '@components/.';

interface PageWrapperProps {
  pageTitle?: string;
  pageDescription?: string;
  pageImage?: string;
  navbarJustLogo?: boolean;
  children?: React.ReactNode;
  noIndex?: boolean;
}

export const PageWrapper: React.FC<PageWrapperProps> = props => {
  return (
    <React.Fragment>
      <HtmlHead
        title={props.pageTitle}
        description={props.pageDescription}
        image={props.pageImage}
        noIndex={props.noIndex}
      />
      <GDPR />
      <WithNavbar justLogo={props.navbarJustLogo}>
        <OwardModals />
        <OwardToastContainer />
        <PopUp />
        {props.children}
      </WithNavbar>
    </React.Fragment>
  );
}
