import { OwardFormTextArea, OwardInsertYellow, OwardLoader, ToastError, ToastSucess } from '@components/Core';
import { StoresBindings } from '@container/.';
import { useInjection, useTranslate } from '@hooks/.';
import { ArtworkController, BlockController, ReportController } from '@oward/openapi';
import { UserStore, ModalKey, ModalStore } from '@stores/.';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { Modal, ModalConfirmOrCancelButtons, ModalTitle } from '..';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { mutate } from 'swr';
import { SWR_BLOCKED_ARTWORK_IDS } from '@utils/.';

export const ReportBlockArtworkModal: React.FC = observer(() => {
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const [showReportForm, setShowReportForm] = useState(userStore.isLogged ? false : true);

  useEffect(() => {
    // In case someone arrives directly on the gallery with a "report_artwork=...." in the query,
    // or if the id of the artwork in store is different from the one in the url (typically when going backward)
    // we need to fetch artwork data
    (async function getArtworkName() {
      try {
        if (
          !!router.query[ModalKey.REPORT_BLOCK_ARTWORK] &&
          !modalStore.reportArtworkInfos?.artworkName
        ) {
          modalStore.setReportArtworkInfos({
            artworkName: (await ArtworkController.findName(parseInt(router.query[ModalKey.REPORT_BLOCK_ARTWORK] as string))).text
          })
        }
      }
      catch (err) {
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query[ModalKey.REPORT_BLOCK_ARTWORK] as string]);

  useEffect(() => {
    setShowReportForm(userStore.isLogged ? false : true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query[ModalKey.REPORT_BLOCK_ARTWORK] as string]);

  return (
    <React.Fragment>
      {
        showReportForm ?
          <ReportForm setShowReportForm={setShowReportForm} />
          :
          <ReportOrBlock setShowReportForm={setShowReportForm} />
      }
    </React.Fragment>
  )
});

interface ReportModalProps {
  setShowReportForm: (show: boolean) => void
}

const ReportOrBlock: React.FC<ReportModalProps> = observer(props => {
  const { t } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  const blockArtwork = async () => {
    try {
      await BlockController.blockArtwork(parseInt(router.query[ModalKey.REPORT_BLOCK_ARTWORK] as string));
      mutate(SWR_BLOCKED_ARTWORK_IDS);
      toast.dark(<ToastSucess msg={t('modal.report_block_artwork.block_validation_toast', { name: modalStore.reportArtworkInfos?.artworkName })} />);
      modalStore.unpopAll();
      modalStore.unpopModal(router);
    } catch (err) {
      toast.dark(<ToastError msg={t('global.error_with_code', { code: err })} />);
    }
  }

  return (
    <React.Fragment>
      <Modal
        modalKey={ModalKey.REPORT_BLOCK_ARTWORK}
        header={
          <React.Fragment>
            {
              modalStore.reportArtworkInfos?.artworkName ?
                <ModalTitle name={t(
                  'modal.report_block_artwork.title',
                  { name: modalStore.reportArtworkInfos?.artworkName }
                )} />
                :
                <OwardLoader loading={true} positionStatic />
            }
          </React.Fragment>
        }
        footer={
          <ModalConfirmOrCancelButtons
            confirmTxt={t('modal.report_artwork.validation_button')}
            noValidation
          />
        }
      >
        <OwardInsertYellow
          title={t('modal.report_block_artwork.report_title')}
          text={t('modal.report_block_artwork.report_text', { name: modalStore.reportArtworkInfos?.artworkName })}
          onClick={() => { props.setShowReportForm(true) }}
        />
        <OwardInsertYellow
          title={t('modal.report_block_artwork.block_title')}
          text={t('modal.report_block_artwork.block_text', { name: modalStore.reportArtworkInfos?.artworkName })}
          onClick={blockArtwork}
        />
      </Modal>
    </React.Fragment>
  );
});


const ReportForm: React.FC<ReportModalProps> = observer(props => {
  const { t } = useTranslate();
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  const schema = (t: any) => {
    return (
      Yup.object().shape({
        message: Yup.string().required(t('modal.report_artwork.need_msg')),
      }))
  };

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      try {
        await ReportController.reportArtwork(values.message, parseInt(router.query[ModalKey.REPORT_BLOCK_ARTWORK] as string));
        toast.dark(<ToastSucess msg={t('modal.report_artwork.validation_toast')} />);
        formik.resetForm();
        modalStore.unpopModal(router);
      } catch (err) {
        toast.dark(<ToastError msg={t('global.error_with_code', { code: err })} />);
      }
    }
  });

  return (
    <React.Fragment>
      <Modal
        modalKey={ModalKey.REPORT_BLOCK_ARTWORK}
        header={
          <React.Fragment>
            {
              modalStore.reportArtworkInfos?.artworkName ?
                <ModalTitle name={t(
                  'modal.report_artwork.title',
                  { name: modalStore.reportArtworkInfos?.artworkName }
                )} />
                :
                <OwardLoader loading={true} positionStatic />
            }
          </React.Fragment>
        }
        footer={
          <ModalConfirmOrCancelButtons
            confirmTxt={t('modal.report_artwork.validation_button')}
            validation={() => {
              formik.handleSubmit();
            }}
            cancelation={() => {
              if (userStore.isLogged) {
                props.setShowReportForm(false);
              }
              else {
                modalStore.unpopModal(router);
              }
            }}
          />
        }
      >
        <p>{t('modal.report_artwork.description')}</p>
        <OwardFormTextArea
          id={'message'}
          placeholder={t('modal.report_artwork.input_placeholder')}
          formik={formik}
        />
      </Modal>
    </React.Fragment>
  );
});
