import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './Messages.module.scss';
import { useInjection, useMessagesForDiscussion, useTranslate } from '@hooks/.';
import { LayoutStore, MessageStore, ModalKey, ModalStore, PopupStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import classNames from 'classnames';
import { OwardLoader } from '@components/Core';
import { observer } from 'mobx-react-lite';
import { Profile, Message } from '@oward/openapi';
import moment from 'moment';
import Linkify from 'linkify-react';
import { DiscussionType, MessageType } from '@oward/common-enums';
import Image from 'next/image'
import { getInitials, onErrorImage, onTouchDevice, remSizeToPixelNumber } from '@utils/.';
import { LongPressDetectEvents, useLongPress } from 'use-long-press';
import { useRouter } from 'next/router';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SeeOrActionProfilePopUp } from '@components/Core/PopUp/ProfilesPopUps';
import { ProfileAvatar } from '@components/Core/Avatar/ProfileAvatars';

interface MessagesProps {
  discussionId: number;
}

export interface IMessageGroup {
  senderProfile: Profile;
  time: string;
  messages: Message[];
}

export const Messages: React.FC<MessagesProps> = observer(props => {
  const { t, locale } = useTranslate();
  const messageStore = useInjection<MessageStore>(StoresBindings.MESSAGE);
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);
  const [lastMessageReceivedId, setLastMessageReceivedId] = useState(undefined);
  const [oldestMessageReceivedId, setOldestMessageReceivedId] = useState(undefined);
  const {
    messages, isError, size, setSize,
    isLoadingInitialMessages, isLoadingMore, isReachingEnd
  } = useMessagesForDiscussion(props.discussionId);
  const [currentDiscussionId, setCurrentDiscussionId] = useState(undefined);

  let timeOfLastMessageGroup: string = undefined;

  const messageGroupsFromMessages = (data: Message[]) => {
    if (data) {
      let messageGroups: IMessageGroup[] = [];
      let messageGroup: IMessageGroup = {
        senderProfile: undefined,
        time: undefined,
        messages: []
      };

      for (const message of data) {
        if (
          messageGroup.senderProfile !== undefined &&
          messageGroup.senderProfile.id === message.senderProfileId &&
          moment.duration(moment(message.creationDate).diff(messageGroup.time)).asMinutes() < 5
        ) {
          // Same message group as prev
          messageGroup.messages.push(message)
        }
        else {
          // New message group, push the prev is any
          if (messageGroup.senderProfile !== undefined) {
            messageGroups.push(messageGroup)
          }
          messageGroup = {
            senderProfile: message.senderProfile,
            time: message.creationDate,
            messages: [message]
          };
        }
      }
      // Push the last message Group, if any
      if (messageGroup.senderProfile !== undefined) {
        messageGroups.push(messageGroup);
      }

      return messageGroups;
    }
  }

  useEffect(() => {
    if (messages && (messages.length > 0 && currentDiscussionId !== props.discussionId)) {
      // All messages are here and go directly to the end with no transitions
      layoutStore.scrollToElement(
        'messages-scrolling-end-discussion',
        false,
        'auto'
      );
      setCurrentDiscussionId(props.discussionId);
    }
    else if (messages && messages[messages.length - 1]?.id !== lastMessageReceivedId) {
      // If received a new message, or writing a new one, scroll to end of discussion
      layoutStore.scrollToElement(
        'messages-scrolling-end-discussion',
        false,
        lastMessageReceivedId === undefined ? 'auto' : 'smooth'
      );
      setLastMessageReceivedId(messages[messages.length - 1]?.id);
    }
    else if (messages && messages[0]?.id !== oldestMessageReceivedId) {
      // Loading more messages using infinite scroll, scroll to "oldest message receive" + an
      // offset (~ taking loader+date container size)
      layoutStore.scrollToElement(`messages-scrolling-${oldestMessageReceivedId}`, true, 'auto');
      setOldestMessageReceivedId(messages[0]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  // Infinite Scroll (see https://www.youtube.com/watch?v=NZKUirTtxcg)
  const observerLastCard = useRef(null);
  const lastCardElementRef = useCallback(node => {
    if (isLoadingInitialMessages || isLoadingMore) {
      return;
    }
    if (observerLastCard.current) {
      observerLastCard.current.disconnect();
    }
    observerLastCard.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && !isReachingEnd) {
        setSize(size + 1);
      }
    })
    if (node) {
      observerLastCard.current.observe(node);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingInitialMessages, isLoadingMore, isReachingEnd]);

  return (
    <div className={styles.messagesMainContainer} key={props.discussionId}>
      {
        !isError ?
          <React.Fragment>
            <OwardLoader loading={isLoadingInitialMessages} />
            {
              (!isLoadingInitialMessages && (!messages || messages?.length === 0)) ?
                <div className={styles.noMessageContainer}>
                  <p className={styles.noMessage}>{t('messages.no_message_in_discussion')}</p>
                </div>
                :
                <div className={styles.messagesContainer} >
                  {
                    isLoadingMore &&
                    <OwardLoader loading={true} positionStatic />
                  }
                  {
                    messageGroupsFromMessages(messages)?.map((messageGroup, i) => {
                      var lastMessageGroupDay = moment(timeOfLastMessageGroup).clone().startOf('day');
                      timeOfLastMessageGroup = messageGroup.time;

                      return (
                        <React.Fragment key={i}>
                          <div className={styles.messageScrollingContainer}>
                            {
                              messageGroup?.messages?.map(message =>
                                <div
                                  key={message.id}
                                  className={styles.messageScrolling}
                                  id={`messages-scrolling-${message.id}`}
                                />
                              )
                            }
                          </div>
                          {
                            !moment(messageGroup.time).isSame(lastMessageGroupDay, 'd') &&
                            <DateLine time={messageGroup.time} />
                          }
                          {
                            // For infinite scroll
                            messageGroup.messages[0].id === oldestMessageReceivedId &&
                            !isReachingEnd &&
                            <div ref={lastCardElementRef} />
                          }
                          <MessageGroup
                            senderProfile={messageStore.currentProfile}
                            messageGroup={messageGroup}

                          />
                        </React.Fragment>
                      );
                    })
                  }
                  {
                    messageStore.currentDiscussion?.type === DiscussionType.ONETOONE &&
                    <MessageSeenAt
                      senderProfile={messageStore.currentProfile}
                      discussionId={props.discussionId}
                    />
                  }
                  <div id='messages-scrolling-end-discussion' />
                </div>
            }
          </React.Fragment>
          :
          <MessagesError />
      }

    </div>
  );
});

interface MessageSeenAtProps {
  senderProfile: Profile;
  discussionId: number;
}

const MessageSeenAt: React.FC<MessageSeenAtProps> = props => {
  const { t, locale } = useTranslate();
  let { messages } = useMessagesForDiscussion(props.discussionId);

  const getLastSeenTime = () => {
    if (
      !messages ||
      messages[messages?.length - 1]?.senderProfileId !== props.senderProfile.id
    ) {
      // Other profile sent the last message, don't display the "seen at"
      return undefined;
    }

    // Returns the last seen time for "other profile"
    return messages[messages?.length - 1]?.messageLastSeens?.find(
      lastSeen => lastSeen.profileId !== props.senderProfile.id
    )?.time;
  }

  const lastSeenDateToStr = (date: string) => {
    var REFERENCE = moment();
    var TODAY = REFERENCE.clone().startOf('day');

    if (moment(date).isSame(TODAY, 'd')) {
      return t('messages.seen_at.today', { time: moment(date).format('HH[h]mm') });
    }
    else {
      return t('messages.seen_at.not_today', {
        date: moment(date).locale(locale).format('D[\u00a0]MMM'),
        time: moment(date).format('HH[h]mm')
      });
    }

  }

  return (
    <React.Fragment>
      {
        getLastSeenTime() &&
        <div className={styles.seenAtContainer}>
          <p className={styles.seenAt}>
            {lastSeenDateToStr(getLastSeenTime())}
          </p>
        </div>
      }
    </React.Fragment>
  );
}

interface DateLineProps {
  time: string;
}

const DateLine: React.FC<DateLineProps> = props => {
  const { t, locale } = useTranslate();
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);


  return (
    <div className={styles.dateLineContainer}>
      <div className={styles.line} />
      <p className={styles.date}>{moment(props.time).locale(locale).format('D[\u00a0]MMMM')}</p>
      <div className={styles.line} />
    </div>
  );
}

interface MessageGroupProps {
  senderProfile: Profile;
  messageGroup: IMessageGroup;
}

interface MessageProps {
  message: Message;
  numberOfMessageInGroup: number;
  positionOfMessageInGroup: number;
}

const MessageGroup: React.FC<MessageGroupProps> = props => {
  const { t, locale } = useTranslate();
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);

  const isMine = (): boolean => {
    return props.messageGroup.senderProfile?.id === props.senderProfile?.id;
  }

  const getPictureDimensions = (url: string) => {
    const h = url?.indexOf('h:') ?? null;
    const hSeparator = url?.indexOf(';') ?? null;
    const w = url?.indexOf('w:') ?? null;
    const wSeparator = url?.indexOf('.jpg') ?? null;
    if (!w || !h || !wSeparator) {
      return null
    }

    let width = Number(url?.substring((w + 2), wSeparator)) ?? 0;
    let height = Number(url?.substring((h + 2), hSeparator)) ?? 0;

    let maxHeight = onTouchDevice() ? 300 : 400;

    if (height > maxHeight) {
      let ratio = maxHeight / height;
      height = maxHeight;
      width *= ratio;
    }

    return { height, width };
  }

  const Message: React.FC<MessageProps> = props => {
    const router = useRouter();
    const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

    const longPress = useLongPress(
      () => { modalStore.openModalNewStack(router, ModalKey.REPORT_MESSAGE, props.message.id); },
      {
        threshold: 600,
        detect: LongPressDetectEvents.TOUCH,
        cancelOnMovement: true
      }
    );

    const MessageDisplayerByType = (message: Message) => {
      switch (message.type) {
        case MessageType.TEXT:
          return (
            <p className={classNames(
              styles.message,
              isMine() ? styles.mine : styles.notMine,
              props.positionOfMessageInGroup === 0 && styles.isFirst,
              props.positionOfMessageInGroup === (props.numberOfMessageInGroup - 1) && styles.isLast,
              props.numberOfMessageInGroup === 1 && styles.alone
            )}>
              <Linkify options={{ className: styles.link, target: '_blank' }}>
                {message.text}
              </Linkify>
            </p>
          )
        case MessageType.PICTURE:
          return (
            <div className={styles.pictureContiner} >
              {
                message.url &&
                <Image
                  className={styles.picture}
                  onClick={() => window.open(message.url)}
                  src={message?.url}
                  alt={''}
                  loading={'eager'}
                  height={getPictureDimensions(message.url)?.height}
                  width={getPictureDimensions(message.url)?.width}
                  quality={100}
                  priority
                  unoptimized
                />
              }
            </div>
          )
        default:
          return null;
      }
    }

    return (
      <React.Fragment key={props.message.id}>
        {
          <div
            className={classNames(
              styles.messageMainContainer,
              isMine() && styles.messageMineMainContainer
            )}
            {...longPress()}
          >
            {
              isMine() &&
              <MessageReportButton messageId={props.message.id} isMine />
            }
            {
              MessageDisplayerByType(props.message)
            }
            {
              !isMine() &&
              <MessageReportButton messageId={props.message.id} />
            }
          </div>
        }
      </React.Fragment >
    );

  }

  return (
    <React.Fragment>{
      <div className={classNames(
        styles.messageGroupMainContainer,
        isMine() && styles.messageGroupMineMainContainer
      )} >
        <div className={styles.messageGroupContainer}>
          <div
            className={styles.senderContainer}
            onClick={() => {
              if (!isMine()) {
                popupStore.openGenericPopUp(
                  <SeeOrActionProfilePopUp profileId={props.messageGroup.senderProfile.id} />
                )
              }
            }}
          >
            {
              !isMine() &&
              <ProfileAvatar
                avatarUrl={props.messageGroup.senderProfile.avatarUrl}
                profileName={props.messageGroup.senderProfile.name}
                size='1.5rem'
                showInitialIfNoUrl={true}
                clickable
              />
            }
            <p className={styles.senderText}>
              <span className={classNames(styles.senderName, !isMine() && styles.clickable)}>
                {isMine() ? t('messages.you') : props.messageGroup.senderProfile.name}
              </span>
              <span className={styles.time}>{moment(props.messageGroup.time).format('HH[h]mm')}</span>
            </p>
          </div>
          {
            props.messageGroup.messages.map((message, i) =>
              <Message
                message={message}
                numberOfMessageInGroup={props.messageGroup.messages.length}
                positionOfMessageInGroup={i}
                key={message.id}
              />
            )
          }
        </div>
      </div>
    }
    </React.Fragment>
  );
}

interface MessageReportButtonProps {
  isMine?: boolean;
  messageId: number;
}

const MessageReportButton: React.FC<MessageReportButtonProps> = props => {
  const { t } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  return (
    <div className={classNames(
      styles.reportButtonContainer,
      props.isMine && styles.isMine
    )}>
      <div
        className={classNames(
          styles.iconContainer,
          props.isMine ? 'has-tooltip-left' : 'has-tooltip-right'
        )}
        data-tooltip={t('modal.report_message.button')}
        onClick={() => { modalStore.openModalNewStack(router, ModalKey.REPORT_MESSAGE, props.messageId); }}
      >
        <span className={classNames('icon')}>
          <div className={classNames(styles.icon)}>
            <i className={classNames('fas fa-ellipsis-h')}></i>
          </div>
        </span>
      </div>
    </div>
  )
}

const MessagesError = () => {
  const { t } = useTranslate();

  return (
    <div className={styles.noMessageContainer}>
      <p>{t('messages.error.fetching_messages')}</p>
      <br />
      <span className='icon'>
        <i className={'fas fa-bug'}></i>
      </span>
      <br />
      {t('global.error_contact')}
    </div>
  );
}
