import { OwardFormSelect, OwardFormTextArea, ToastError, ToastSucess } from "@components/Core";
import { useTranslate } from "@hooks/use-translate";
import { MessageType, ReportArtwork, ReportController, ReportMessage, ReportProfile, ValidationState } from "@oward/openapi";
import { validationStateToEmoji } from '@oward/common-utils';
import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from 'yup';
import { Divider, NavigationContext, Section, SubmitOrCancel } from "../AdminComponents";
import { EditProps } from "../types";

export const EditReportProfile = (props: EditProps<ReportProfile>) => {
  const { t, locale } = useTranslate();
  const [error, setError] = useState(false);
  const navigation = useContext(NavigationContext);

  const schema = (t: any) => {
    return (
      Yup.object().shape({
        validationState: Yup.string().required()
      })
    )
  };

  const formik = useFormik({
    initialValues: {
      validationState: props.item.validationState,
      message: props.item.message,
    },
    validationSchema: schema(t),
    onSubmit: async (values) => {
      props.item.validationState = values.validationState;
      props.item.message = values.message;
      try {
        await ReportController.saveReportProfileAdmin(props.item);
        if (props.notify) { props.notify(props.item) };
        toast.dark(<ToastSucess msg={'Report saved'} />);
        setError(false);
        navigation.unpop();
      }
      catch (err) {
        toast.dark(<ToastError msg={t('global.error_with_code') + err} />);
        setError(true);
      }
    }
  });
  return (

    <Section
      title={`Edit '${props.item.profileReported?.name}' Report`}
      subtitle={'Change the value of the given Report'}
      childrenFullWidth
    >
      <form className='box' onSubmit={formik.handleSubmit} >
        <OwardFormSelect
          id={'validationState'}
          label={'Validation State'}
          isNotClearable
          options={
            Object.values(ValidationState).map(validationState => {
              return {
                value: validationState,
                label: validationState.concat(' ', validationStateToEmoji(validationState))
              }
            })
          }
          formik={formik}
        />
        <OwardFormTextArea
          id={'message'}
          formik={formik}
        />
        <div style={{ height: '8rem' }} />
        <Divider />
        <SubmitOrCancel error={error} noCancel={props.noCancel} />
      </form>
    </Section>
  )
}

export const EditReportArtwork = (props: EditProps<ReportArtwork>) => {
  const { t, locale } = useTranslate();
  const [error, setError] = useState(false);
  const navigation = useContext(NavigationContext);

  const schema = (t: any) => {
    return (
      Yup.object().shape({
        validationState: Yup.string().required()
      })
    )
  };

  const formik = useFormik({
    initialValues: {
      validationState: props.item.validationState,
      message: props.item.message,
    },
    validationSchema: schema(t),
    onSubmit: async (values) => {
      props.item.validationState = values.validationState;
      props.item.message = values.message;
      try {
        await ReportController.saveReportArtworkAdmin(props.item);
        if (props.notify) { props.notify(props.item) };
        toast.dark(<ToastSucess msg={'Report saved'} />);
        setError(false);
        navigation.unpop();
      }
      catch (err) {
        toast.dark(<ToastError msg={t('global.error_with_code') + err} />);
        setError(true);
      }
    }
  });
  return (

    <Section
      title={`Edit '${props.item.artworkReported?.name}' Report`}
      subtitle={'Change the value of the given Report'}
      childrenFullWidth
    >
      <form className='box' onSubmit={formik.handleSubmit} >
        <OwardFormSelect
          id={'validationState'}
          label={'Validation State'}
          isNotClearable
          options={
            Object.values(ValidationState).map(validationState => {
              return {
                value: validationState,
                label: validationState.concat(' ', validationStateToEmoji(validationState))
              }
            })
          }
          formik={formik}
        />
        <OwardFormTextArea
          id={'message'}
          formik={formik}
        />
        <div style={{ height: '8rem' }} />
        <Divider />
        <SubmitOrCancel error={error} noCancel={props.noCancel} />
      </form>
    </Section>
  )
}

export const EditReportMessage = (props: EditProps<ReportMessage>) => {
  const { t, locale } = useTranslate();
  const [error, setError] = useState(false);
  const navigation = useContext(NavigationContext);

  const schema = (t: any) => {
    return (
      Yup.object().shape({
        validationState: Yup.string().required()
      })
    )
  };

  const formik = useFormik({
    initialValues: {
      validationState: props.item.validationState,
      message: props.item.message,
    },
    validationSchema: schema(t),
    onSubmit: async (values) => {
      props.item.validationState = values.validationState;
      props.item.message = values.message;
      try {
        await ReportController.saveReportMessageAdmin(props.item);
        if (props.notify) { props.notify(props.item) };
        toast.dark(<ToastSucess msg={'Report saved'} />);
        setError(false);
        navigation.unpop();
      }
      catch (err) {
        toast.dark(<ToastError msg={t('global.error_with_code') + err} />);
        setError(true);
      }
    }
  });
  return (

    <Section
      title={`Edit Message ID '${props.item.messageReported?.id}' Report`}
      subtitle={'Change the value of the given Report'}
      childrenFullWidth
    >
      <form className='box' onSubmit={formik.handleSubmit} >
        <p>
          {`Message content: ${(props.item.messageReported?.text || props.item.messageReported?.url)}`}
        </p>
        {
          (props.item.messageReported.type === MessageType.PICTURE && props.item.messageReported?.url) &&
          <img src={props.item.messageReported?.url} height={'80rem'} width={'80rem'} alt='' />
        }
        <OwardFormSelect
          id={'validationState'}
          label={'Validation State'}
          isNotClearable
          options={
            Object.values(ValidationState).map(validationState => {
              return {
                value: validationState,
                label: validationState.concat(' ', validationStateToEmoji(validationState))
              }
            })
          }
          formik={formik}
        />
        <OwardFormTextArea
          id={'message'}
          formik={formik}
        />
        <div style={{ height: '8rem' }} />
        <Divider />
        <SubmitOrCancel error={error} noCancel={props.noCancel} />
      </form>
    </Section>
  )
}
