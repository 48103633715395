import React from 'react';
import styles from './InvoicesPage.module.scss';
import { UserStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { useInjection, useTranslate } from '@hooks/.';
import { LayoutPageCentered } from '@components/Core';
import { State, StripeInvoiceInfos } from '@oward/openapi';
import classNames from 'classnames';
import { priceToString } from '@oward/common-utils';
import DownloadIcon from '../../../assets/icons/download.svg';
import InvoiceIcon from '../../../assets/icons/invoice.svg';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { Link } from '@components/I18n';

interface InvoicesPageProps {
  invoices: StripeInvoiceInfos[];
}

export const InvoicesPage: React.FC<InvoicesPageProps> = observer(props => {
  const { t } = useTranslate();
  const userStore = useInjection<UserStore>(StoresBindings.USER);


  return (
    <LayoutPageCentered
      title={t('account.user.invoices.title')}
      subtitle={userStore.currentUser?.email}
    >
      <div className={styles.mainContainer}>
        {
          props.invoices?.length > 0 ?
            <React.Fragment>
              {
                props.invoices?.map((invoice, i) =>
                  <React.Fragment key={i}>
                    <InvoiceInfoLine invoice={invoice} />
                  </React.Fragment>
                )
              }
            </React.Fragment>
            :
            <div className={styles.noInvoiceContainer}>
              <p>{t('account.user.invoices.no_invoice')}</p>
            </div>
        }


      </div>
    </LayoutPageCentered >
  )
});

interface InvoiceInfoLineProps {
  invoice: StripeInvoiceInfos;
}

const InvoiceInfoLine: React.FC<InvoiceInfoLineProps> = observer(props => {
  const { t, locale } = useTranslate();

  return (
    <div className={styles.invoiceContainer}>
      <div className={styles.leftContainer}>
        {
          props.invoice.profile &&
          <p>
            <span className={styles.book}>{t('account.user.invoices.profile')}</span>
            {
              props.invoice.profile.state !== State.DELETED ?
                <Link href={`/account/profile/[profileId]`} as={`/account/profile/${props.invoice.profile.id}`} passHref>
                  <span className={styles.link}>{props.invoice.profile?.name}</span>
                </Link>
                :
                <React.Fragment>
                  {props.invoice.profile?.name}
                </React.Fragment>
            }
          </p>
        }
        <p>
          <span className={styles.book}>{t('account.user.invoices.amount')}</span>
          {priceToString(props.invoice.amount / 100, locale)}
        </p>
        <p>
          <span className={styles.book}>{t('account.user.invoices.date')}</span>
          {moment(props.invoice.creationDate * 1000).locale(locale).format('DD MMMM yyyy')}
        </p>
        <p>
          <span className={styles.book}>{t('account.user.invoices.status.title')}</span>
          {
            props.invoice.status === 'paid' ?
              <span>
                {t(`account.user.invoices.status.${props.invoice.status}`)}
                <span className={`icon`}>
                  <i className={`fas fa-check-circle ${styles.iconSuccess}`}></i>
                </span>
              </span>
              :
              <span className={styles.red}>{t(`account.user.invoices.status.${props.invoice.status}`)}</span>
          }

        </p>
      </div>
      <div className={styles.rightContainer}>
        {
          props.invoice.status === 'paid' ?
            <div className={classNames(styles.buttonsIconContainer)}>
              <a
                className={classNames(styles.buttonsIconCircleContainer, 'has-tooltip-left has-tooltip-arrow')}
                data-tooltip={t('account.user.invoices.download')}
                href={props.invoice.pdfLink}
              >
                <DownloadIcon className={classNames(styles.buttonsIcon)} />
              </a>
            </div>
            :
            <div className={classNames(styles.buttonsIconContainer)}>
              <a
                className={classNames(styles.buttonsIconCircleContainer, 'has-tooltip-left has-tooltip-arrow')}
                data-tooltip={t('account.user.invoices.view')}
                href={props.invoice.hostedInvoiceUrl}
                target='_blank'
                rel='noreferrer'
              >
                <InvoiceIcon className={classNames(styles.buttonsIcon)} />
              </a>
            </div>
        }
      </div>
    </div >
  );
});
