import React from 'react';
import { makeAutoObservable } from 'mobx';
import { ActiveItem, PoppedItem } from '../../types';
import { defaultItem } from '../../items';

export class NavigationStore {
  constructor() {
    makeAutoObservable(this)
  }

  active: ActiveItem = defaultItem;
  popped: PoppedItem[] = new Array<PoppedItem>();

  scrollY: number | undefined = undefined;

  get isPopped() {
    return this.popped?.length > 0;
  }

  pop(popped: PoppedItem) {
    this.scrollY = window.scrollY;
    this.popped.push(popped);
  }

  unpop(iterations = 1) {
    for (let i = 0; i < iterations; i++) {
      this.popped.pop();
    }
  }

  unpopAll() {
    this.popped = new Array<PoppedItem>();
  }

  setActive(active: ActiveItem) {
    this.active = active;
    this.popped = new Array<PoppedItem>();
  }
}

export const NavigationContext = React.createContext(new NavigationStore());
