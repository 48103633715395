/* Custom App
** See https://nextjs.org/docs/advanced-features/custom-app
*/

import '../assets/sass/main.scss';

import ms from 'ms';
import getConfig from 'next/config';
import Router, { useRouter } from 'next/router';
import { OpenAPI, send, InfoController } from "@oward/openapi";
import { enableStaticRendering } from 'mobx-react-lite';
import { AppProps } from 'next/app';
import React, { Fragment, useEffect } from 'react';
import * as Sentry from '@sentry/node';
import { RewriteFrames } from '@sentry/integrations';

import Modal from 'react-modal';
import { useInjection } from '@hooks/.';
import { PopupStore, UserStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { PageLoader } from '@components/.';

import * as gtag from '../utils/google-analytics';
import { goToHomePageAndReload, sendMsgToReactNativeWebview } from '@utils/utils';
import { REACT_NATIVE_SIGNAL_SIGNOUT } from "@oward/common-utils";

if (process.env.GA_TRACKING_ID) {
  // Notice how we track pageview when route is changed
  Router.events.on('routeChangeComplete', (url: string) => gtag.pageview(url))
}

function AppInit() {

  OpenAPI.options.url = process.env.API_URL;

  if (typeof window === 'undefined') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    enableStaticRendering(true);
  }

  if (process.env.SENTRY_DSN) {
    const distDir = `${getConfig().serverRuntimeConfig.ROOT_DIR}/.next`;
    Sentry.init({
      enabled: process.env.NODE_ENV !== 'development',
      integrations: [
        new RewriteFrames({
          iteratee: (frame) => {
            frame.filename = frame.filename.replace(distDir, 'app:///_next');
            frame.filename = decodeURI(frame.filename);
            return frame;
          },
        }),
      ],
      dsn: process.env.SENTRY_DSN,
    })
  }
}

AppInit();

Modal.setAppElement('#__next');

const App = ({ Component, pageProps }: AppProps) => {
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);
  const router = useRouter();

  useEffect(() => {
    async function initInterval() {
      const info = await send(InfoController.info());

      // As we don't have access to locale and words Json here, hard-code the language detection and the words
      let logoutText: string = router.asPath.startsWith('/fr') ?
        "Vous avez été déconnecté.\n\nVous pouvez choisir l'option «Se souvenir de moi» à la prochaine connexion pour ne plus être déconnecté." :
        "You have been disconnected.\n\nYou can select the 'Remember me' option during next login to stay connected.";

      userStore.setAutologinTimer(
        () => {
          popupStore.openInformationPopUp({
            msg: logoutText,
            callback: () => {
              sendMsgToReactNativeWebview({
                type: REACT_NATIVE_SIGNAL_SIGNOUT,
                value: 0
              });
              return goToHomePageAndReload();
            }
          })
        },
        ms(info.AUTH_ACCESS_EXPIRES)
      );
    }

    initInterval()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <React.Fragment>
      {/* Google Tag Manager */
        (process.env.GOOGLE_TAG_MANAGER_ID && userStore.gdprPrefs?.analysis) &&
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${process.env.GOOGLE_TAG_MANAGER_ID}`}
          height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}
        />
      }
      <PageLoader />
      <Component {...pageProps} />
    </React.Fragment>
  );
}
export default App;
