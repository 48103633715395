interface TitlePageProps {
  title: string;
}

export const TitlePage: React.FC<TitlePageProps> = props => {
  return (
    <h1 className='title is-2 is-size-3-touch has-text-centered is-family-secondary'>
      {props.title}
    </h1>
  )
}
