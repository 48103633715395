import { StoresBindings } from "@container/.";
import { useInjection } from "@hooks/use-injection";
import { useTranslate } from "@hooks/use-translate";
import { FilterType, LocationType, SelectionStatus } from "@oward/common-enums";
import { findTranslationOrDefault, GalleryType } from "@oward/common-utils";
import { Artwork, Festival, FilterI, FilterLabelI, Profile } from "@oward/openapi";
import { FilterStore } from "@stores/filter-store";

export const FilterPhraseForTitle = (filter: FilterI, label: FilterLabelI): string => {
  const { t } = useTranslate();

  if (!label || Object.keys(label).length === 0) {
    return t('header.title');
  }

  let phrase: string = '';

  if (filter?.gallery_type?.type === GalleryType.ARTWORK) {
    phrase += t('filter_phrase.artworks');

    if (label.job) {
      phrase += t('filter_phrase.job_for_artwork', { job: label.job });
    }
    else if (label.job_category) {
      phrase += t('filter_phrase.job_category', { job_category: label.job_category });
    }
  }
  else {
    if (label.job) {
      phrase += label.job;
    }
    else {
      if (
        !filter.gallery_type ||
        filter.gallery_type.type === GalleryType.PRO
      ) {
        phrase += t('filter_phrase.cineast')
      }
      else if (filter.gallery_type.type === GalleryType.COMPANY) {
        phrase += t('filter_phrase.companies')
      }

      if (label.job_category) {
        phrase += t('filter_phrase.job_category', { job_category: label.job_category });
      }
    }
  }

  if (label.film_type) {
    phrase += t('filter_phrase.film_type_title', { film_type: label.film_type });
  }

  if (label.location) {
    if (filter.location.type === LocationType.CITIES) {
      phrase += t('filter_phrase.location_city', { location: label.location });
    }
    else {
      phrase += t('filter_phrase.location', { location: label.location });
    }
  }

  for (const [key, value] of Object.entries(label)) {
    if (!['job', 'job_category', 'film_type', 'location'].includes(key)) {
      phrase += ` | ${value}`
    }
  }

  if (filter.festival_awarded_only) {
    phrase += ` |${t('filter_phrase.festival.awarded')}`;
  }

  if (filter.hashtag?.names?.length > 0) {
    for (const hashtag of filter.hashtag?.names) {
      phrase += ` | #${hashtag}`
    }
  }

  return phrase;
}

const profileFilters: FilterType[] = [
  FilterType.JOB,
  FilterType.JOB_CATEGORY,
  FilterType.LOCATION,
  FilterType.LANGUAGE,
  FilterType.HASHTAG,
];

const filmFilters: FilterType[] = [
  FilterType.FILM_TYPE,
  FilterType.FILM_YEAR,
  FilterType.BROADCASTER,
  FilterType.BROADCASTER_CATEGORY,
  FilterType.FESTIVAL,
  FilterType.FESTIVAL_CATEGORY,
  FilterType.FESTIVAL_COUNTRY,
  FilterType.FESTIVAL_AWARD,
  FilterType.FESTIVAL_AWARD_CATEGORY,
  FilterType.FESTIVAL_YEAR,
  FilterType.FESTIVAL_AWARDED_ONLY,
];

const brodacasterFilters: FilterType[] = [
  FilterType.BROADCASTER,
  FilterType.BROADCASTER_CATEGORY,
];

const filmFestivalLinkFilters: FilterType[] = [
  FilterType.FILM_YEAR,
  FilterType.BROADCASTER,
  FilterType.BROADCASTER_CATEGORY,
];

const festivalFilters: FilterType[] = [
  FilterType.FESTIVAL,
  FilterType.FESTIVAL_CATEGORY,
  FilterType.FESTIVAL_COUNTRY,
  FilterType.FESTIVAL_AWARD,
  FilterType.FESTIVAL_AWARD_CATEGORY,
  FilterType.FESTIVAL_YEAR,
  FilterType.FESTIVAL_AWARDED_ONLY,
]

export const FilterPhrase = (filter: FilterI, label: FilterLabelI): string => {
  const { t } = useTranslate();
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);

  let phrase: string;

  if (filterStore.isProfileGallery) {
    phrase = profilePhrasePart(filter, label, t);

    // Test if there's a filter on films
    if (filmFilters.filter(value => Object.keys(filter).includes(value)).length > 0) {
      phrase += t('filter_phrase.film_exposing') + filmPhrasePart(filter, label, t);
    }
  }
  else if (filterStore.isArtworkGallery) {
    phrase = t('filter_phrase.artworks');

    // Test if there's a filter on films
    if (filmFilters.filter(value => Object.keys(filter).includes(value)).length > 0) {
      phrase += t('filter_phrase.film_on') + filmPhrasePart(filter, label, t);
    }

    // Test if there's a filter on profiles
    if (profileFilters.filter(value => Object.keys(filter).includes(value)).length > 0) {
      phrase += t('filter_phrase.profile_exposer') + profilePhrasePart(filter, label, t);
    }
  }

  return phrase;
}

const profilePhrasePart = (filter: FilterI, label: FilterLabelI, t: any): string => {
  let phrasePart: string = '';

  if (label.job) {
    phrasePart += label.job;
  }
  else {
    if (filter.gallery_type?.type === GalleryType.COMPANY) {
      phrasePart += t('filter_phrase.companies')
    }
    else {
      phrasePart += t('filter_phrase.filmmakers')
    }

    if (label.job_category) {
      phrasePart += t('filter_phrase.job_category', { job_category: label.job_category });
    }
  }

  if (label.location) {
    if (filter.location.type === LocationType.CITIES) {
      phrasePart += t('filter_phrase.location_city', { location: label.location });
    }
    else {
      phrasePart += t('filter_phrase.location', { location: label.location });
    }
  }

  if (label.agent) {
    phrasePart += t('filter_phrase.agent', { agent: label.agent })
  }

  if (label.association) {
    if (label.agent) {
      phrasePart += ' ' + t('global.and');
    }
    else {
      phrasePart += ',';
    }

    phrasePart += t('filter_phrase.association', { association: label.association })
  }

  if (label.language) {
    phrasePart += t('filter_phrase.language', { language: label.language })
  }

  if (filter.hashtag?.names?.length > 0) {
    phrasePart += t('filter_phrase.hashtags', {
      hashtags: '#' + filter.hashtag.names.join(' ' + t('global.and') + ' #'),
      smart_count: filter.hashtag.names.length
    })
  }

  return phrasePart;
}

const filmPhrasePart = (filter: FilterI, label: FilterLabelI, t: any) => {
  let phrasePart: string = '';

  if (label.film_type) {
    phrasePart += t('filter_phrase.film_type', { film_type: label.film_type });
  }
  else {
    phrasePart += t('filter_phrase.film_without_type');
  }

  if (label.film_year) {
    if (filter.film_year.id > 0) {
      phrasePart += t('filter_phrase.film_year', { film_year: label.film_year })
    }
    else {
      phrasePart += t('filter_phrase.in_development');
    }
  }

  // Test if there's a filter on broadcaster
  if (brodacasterFilters.filter(value => Object.keys(filter).includes(value)).length > 0) {
    if (label.film_year) {
      phrasePart += ' ' + t('global.and') + ' ';
    }

    phrasePart += t('filter_phrase.broadcaster.prefix');

    if (label.broadcaster_category) {
      phrasePart += t('filter_phrase.broadcaster.category', { broadcaster_category: label.broadcaster_category });
    }

    if (label.broadcaster) {
      phrasePart += t('filter_phrase.broadcaster.broadcaster', { broadcaster: label.broadcaster });
    }
  }

  // Test if there's a filter on broadcaster
  if (
    filmFestivalLinkFilters.filter(value => Object.keys(filter).includes(value)).length > 0 &&
    festivalFilters.filter(value => Object.keys(filter).includes(value)).length > 0
  ) {
    phrasePart += ', ' + t('global.and');
  }


  // Test if there's a filter on festival
  if (festivalFilters.filter(value => Object.keys(filter).includes(value)).length > 0) {
    if (filter.festival_awarded_only) {
      phrasePart += t('filter_phrase.festival.awarded');
    }
    else {
      phrasePart += t('filter_phrase.festival.selected');
    }

    if (label.festival) {
      phrasePart += t('filter_phrase.festival.festival', { festival: label.festival });
    }
    else {
      phrasePart += t('filter_phrase.festival.festival_generic');
    }

    if (label.festival_category) {
      phrasePart += t('filter_phrase.festival.festival_category', { festival_category: label.festival_category });
    }

    if (label.festival_country) {
      if (label.festival) {
        phrasePart += t('filter_phrase.festival.festival_country', { festival_country: label.festival_country });
      }
      else {
        phrasePart += t('filter_phrase.festival.festival_country_generic', { festival_country: label.festival_country });
      }
    }

    if (label.festival_year) {
      phrasePart += t('filter_phrase.festival.festival_year', { festival_year: label.festival_year });
    }

    if (label.festival_award_category) {
      phrasePart += t('filter_phrase.festival.festival_award_category', { festival_award_category: label.festival_award_category });
    }
  }

  return phrasePart;
}

export const ProfileDescription = (profile: Profile) => {
  const { t, locale } = useTranslate();

  interface FestivalNameAwarded {
    festivalName: string;
    awarded: boolean;
  };

  interface FestivalAwarded {
    festival: Festival;
    awarded: boolean;
  };

  let festivalsNames: FestivalNameAwarded[] = [];
  let festivals: FestivalAwarded[] = [];

  profile.artworks.map((artwork: Artwork) => {
    artwork.film?.selections.map(selection => {
      festivals.push({ festival: selection.festival, awarded: selection.status !== SelectionStatus.SELECTED });
    })
  })
  festivals.sort((a, b) => a.festival.id - b.festival.id);
  festivals.map((festival: FestivalAwarded) => festivalsNames.push(
    {
      festivalName: findTranslationOrDefault(festival.festival, locale),
      awarded: festival.awarded
    }));

  let description = '';

  if (festivalsNames.length === 0) {
    description = t('header.profile_description.no_selection', {
      name: profile.name,
      job: findTranslationOrDefault(profile?.job, locale),
      film_type_list: [...new Set(profile.artworks.map(artwork => findTranslationOrDefault(artwork.film.type, locale)))].join(", "),
      city: findTranslationOrDefault(profile.location.city, locale),
      region: findTranslationOrDefault(profile.location.region, locale),
    });
  }
  else if (festivalsNames.length > 0 && !festivalsNames[0].awarded) {
    description = t('header.profile_description.selection', {
      name: profile.name,
      job: findTranslationOrDefault(profile?.job, locale),
      festival: festivalsNames[0].festivalName,
      city: findTranslationOrDefault(profile.location.city, locale),
      region: findTranslationOrDefault(profile.location.region, locale),
      smart_count: festivalsNames.length
    });
  }
  else if (festivalsNames.length > 0 && festivalsNames[0].awarded) {
    description = t('header.profile_description.awarded', {
      name: profile.name,
      job: findTranslationOrDefault(profile?.job, locale),
      festival: festivalsNames[0].festivalName,
      city: findTranslationOrDefault(profile.location.city, locale),
      region: findTranslationOrDefault(profile.location.region, locale),
      smart_count: festivalsNames.length
    });
  }

  return description;
}
