import React from "react";
import { Divider } from "../AdminComponents";
import { CityList } from "./CityList";
import { CountriesCitiesStatistics } from "./CountriesCitiesStatistics";
import { CountryList } from "./CountryList";

export const CountriesCities: React.FC = () => {
  return (
    <React.Fragment>
      <CountriesCitiesStatistics />
      <Divider />
      <CityList />
      <Divider />
      <CountryList />
    </React.Fragment>
  )
}
