import React, { useState, useEffect, useContext } from 'react';
import { send, StatsController, StatsClickContact, User } from '@oward/openapi';
import { PaginatedList, PaginatedListKey, Section, NavigationContext, PaginatedListFilterType } from '@components/admin';
import { strToPath } from '@oward/common-utils';
import { userToStrFull } from '@utils/.';
import moment from 'moment';

export const StatsClickContactList: React.FC = () => {
  const store = useContext(NavigationContext);
  const [items, setItems] = useState([] as StatsClickContact[]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = async () => {
    try {
      setLoading(true);
      setError(false);
      let statsClickContact: StatsClickContact[] = await send(StatsController.findClickContacts());
      setItems(statsClickContact);
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const userToUserContactedStr = (user: User): string => {
    return (userToStrFull(user) ?? '- not logged user -');
  }

  const keys: PaginatedListKey<StatsClickContact>[] = [
    {
      label: 'User Contacter',
      value: item => userToUserContactedStr(item.user)
    },
    {
      label: 'Profile Contacted',
      value: item => `${item.profileContacted.id} - ${item.profileContacted.name}`
    },
    {
      label: 'Profile Contacted Email',
      value: item => item.profileContacted.user.email
    },
    {
      label: 'Profile Contacted First Name',
      value: item => item.profileContacted.user.infos?.firstName ?? item.profileContacted.name
    },
    {
      label: 'Date',
      value: item => moment(item.creationDate).format('lll'),
      sortValue: item => Date.parse(item.creationDate)
    },
  ];

  const filterItemStrings = (item: StatsClickContact) => [
    strToPath(item.profileContacted.name),
    strToPath(userToStrFull(item.user)),
  ];

  const filterComponents = [
    {
      name: 'User Contacter',
      type: PaginatedListFilterType.DROPDOWN,
      options: [...new Set(items.map(item => userToUserContactedStr(item.user)))].map(userStr => {
        return { value: userStr, label: userStr }
      }),
      filterFunction: (item: StatsClickContact, value: string) => userToUserContactedStr(item.user) === value
    },
    {
      name: 'Profile Contacted',
      type: PaginatedListFilterType.DROPDOWN,
      options: [...new Set(items.map(item => item.profileContacted.name))].map(profileStr => {
        return { value: profileStr, label: profileStr }
      }),
      filterFunction: (item: StatsClickContact, value: string) => item.profileContacted.name === value
    },
    {
      name: 'Remove self Contact',
      type: PaginatedListFilterType.ON_OFF,
      filterFunction: (item: StatsClickContact) => item.profileContacted.user.id !== item.userId
    }
  ];

  return (
    <React.Fragment>
      <Section
        title={'Clicks on \'Contact Profile\' (card of profile page) : obsolete since 01/27/22'}
        subtitle={'(card or profile page)'}
        childrenFullWidth
      >
        <PaginatedList
          keys={keys}
          items={items}
          filterItemStrings={filterItemStrings}
          filterComponents={filterComponents}
          size={100}
          reloadList={loadItems}
          loading={loading}
          error={error}
        />
      </Section>
      <div style={{ height: '2rem' }} />
    </React.Fragment>
  );
}
