import React, { useRef } from 'react';
import { Artwork, Profile, ProfileController, send } from '@oward/openapi';
import { AccountArtworkPage, EditProfileLeftContainer, EditProfilePreview, LayoutPageEditProfile } from '@components/.';
import { useLocalObservable, observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { useInjection, useTranslate } from '@hooks/.';
import { LayoutStore, PopupStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { ERR_MSG_UNAUTHORIZED } from '@utils/constants';
import { useRouter } from 'next/router';
import { goToHomePageAndReload } from '@utils/utils';

export interface EditProfileStore {
  profile: Profile,
  avatarPreview?: any;
  activeCategory: EditProfileCategory,
  editArtwork: Artwork,
  refreshProfile: () => void;
  reset: () => void,
  loading: string,
  setLoading: (txt: string) => void,
  error: string,
  setError: (txt: string) => void,
}

export interface EditProfilePannelProps {
  store: EditProfileStore;
}

export enum EditProfileCategory {
  NONE = 'none',
  GENERAL = 'general',
  STATUS = 'status',
  ARTWORKS = 'artworks',
  CONFIDENTIALITY = 'confidentiality',
  PARAMETER = 'parameter',
}

interface AccountProfileProps {
  profile: Profile;
}

export const AccountProfilePage = observer((props: AccountProfileProps) => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);

  const getProfile = async () => {
    try {
      let profile = await send(ProfileController.findOneForUser(store.profile.id, locale));
      runInAction(() => {
        store.profile = profile
      });
      store.setLoading(t('edit_profile.loading.loading_profile'));
      store.setError(undefined);
    }
    catch (err) {
      if (err instanceof Error && err.message === ERR_MSG_UNAUTHORIZED) {
        popupStore.openInformationPopUp({
          msg: t('global.force_logout'),
          callback: () => { goToHomePageAndReload() }
        });
      }
      else {
        store.setError(t('edit_profile.error.loading_profile', { code: err }));
      }
    }
    finally {
      store.setLoading(undefined);
    }
  };

  const store: EditProfileStore = useLocalObservable(() => ({
    profile: props.profile,
    avatarPreview: undefined,
    activeCategory: EditProfileCategory.NONE,
    editArtwork: undefined,
    async refreshProfile() {
      await getProfile();
    },
    async reset() {
      store.activeCategory = EditProfileCategory.NONE;
      store.avatarPreview = undefined;
      await getProfile();
      layoutStore.scrollToElement('edit-profile-menu-scroll-mobile', false, 'auto');
    },
    loading: undefined,
    setLoading: (loadingText) => { store.loading = loadingText },
    error: undefined,
    setError: (errorText) => { store.loading = errorText },
  }));

  return (
    <React.Fragment>
      {
        store.editArtwork ?
          <AccountArtworkPage artwork={store.editArtwork} editProfileStore={store} />
          :
          <LayoutPageEditProfile
            leftComponent={<EditProfileLeftContainer store={store} />}
            rightComponent={<EditProfilePreview store={store} />}
            loading={store.loading}
            error={store.error}
          />
      }
    </React.Fragment>
  )
});

