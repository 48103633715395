import React, { useEffect, useState } from 'react';
import styles from './AddShortlistModal.module.scss';
import { observer } from 'mobx-react-lite';
import { UserStore, ModalStore, ModalKey, FilterStore, CardStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { useInjection, useUserShortlistsGet, useTranslate } from '@hooks/.';
import { Modal, ModalSubTitle, ModalTitle } from '@components/Modal';
import { OwardButton, OwardLoader, NeedConnectionModal, ToastSucess, ToastError } from '@components/.';
import { useRouter } from 'next/router';
import { AddingProfileToShortlistItem, Profile, ProfileController, send, Shortlist, ShortlistController, Visibility } from '@oward/openapi';
import classNames from 'classnames';
import { findTranslationOrDefault, sleep } from '@oward/common-utils';
import { mutate } from 'swr';
import { SWR_USER_SHORTLISTS_GET } from '@utils/.';
import { toast } from 'react-toastify';
import { FilterType } from '@oward/common-enums';

enum AddingOrRemoving {
  ADDING = 'adding',
  REMOVING = 'removing',
}

interface ShortlistLineProps {
  shortlist: Shortlist;
  name: string;
  active?: boolean;
  adding?: AddingOrRemoving;
  onClick: () => void;
}

const ShortlistLine: React.FC<ShortlistLineProps> = observer(props => {
  const { t } = useTranslate();

  return (
    <React.Fragment>
      <div className={styles.listItemContainer} onClick={props.onClick}>
        <span className={classNames(styles.listItemText, { [styles.semibold]: props.active })}>
          {props.shortlist.name}
          {
            props.shortlist.visibility === Visibility.PRIVATE &&
            <span className={classNames('icon')}>
              <div
                data-tooltip={t('shortlist.private')}
                className={classNames('has-tooltip-top', styles.privateIcon)}
              >
                <i className={classNames('far fa-eye-slash')}></i>
              </div>
            </span>
          }
        </span>
        <div className={classNames(styles.checkboxContainer)}>
          {
            props.adding !== undefined &&
            <span className={classNames('icon')}>
              <div
                data-tooltip={t('shortlist.add.' + props.adding + '_tooltip', { name: props.name, shortlist: props.shortlist.name })}
                className={classNames('has-tooltip-left', styles.privateIcon)}
              >
                <i className={classNames(props.adding === AddingOrRemoving.ADDING ? 'fas fa-plus' : 'fas fa-minus')}></i>
              </div>
            </span>
          }
          <div className={classNames(styles.checkbox, { [styles.checkboxActive]: props.active })}></div>
        </div>
      </div>
      <hr className={styles.hr} />
    </React.Fragment>
  );
});

export const AddShortlistModal: React.FC = observer(() => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);
  const cardStore = useInjection<CardStore>(StoresBindings.CARD);

  const [selectedShortlists, setSelectedShortlists] = useState<number[]>(new Array<number>());
  const [alreadyInShortlists, setAlreadyInShortlists] = useState<number[]>(new Array<number>());
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { shortlists, isLoading, isError } = useUserShortlistsGet();

  const isShortlistSelected = (id: number) => {
    return selectedShortlists.find(shortlistId => shortlistId === id) !== undefined;
  };

  const isAlreadyInShortlist = (id: number) => {
    return alreadyInShortlists.find(shortlistId => shortlistId === id) !== undefined;
  };

  const isAddingOrDeleting = (id: number) => {
    if (isShortlistSelected(id) && !isAlreadyInShortlist(id)) {
      return AddingOrRemoving.ADDING;
    }
    else if (!isShortlistSelected(id) && isAlreadyInShortlist(id)) {
      return AddingOrRemoving.REMOVING;
    }
    else {
      return undefined;
    }
  };

  const selectShortlist = (id: number) => {
    if (isShortlistSelected(id)) {
      setSelectedShortlists(selectedShortlists.filter(shortlistId => shortlistId !== id));
    }
    else {
      setSelectedShortlists(selectedShortlists => [...selectedShortlists, id]);
    }
  };

  useEffect(() => {
    // In case someone arrives directly on the gallery with a "add_shortlist=...." in the query,
    // or if the id of the profile in store is different from the one in the url (typically when going backward)
    // we need to fetch profile data
    (async function getAddShortlistInfos() {
      try {
        if (
          !!router.query[ModalKey.ADD_SHORTLIST] &&
          (modalStore.addShortlistInfos === undefined || modalStore.addShortlistInfos.profileId !== parseInt(router.query[ModalKey.ADD_SHORTLIST] as string))
        ) {
          let profileId: number = parseInt(router.query[ModalKey.ADD_SHORTLIST] as string);
          const profile: Profile = await send(ProfileController.getNameAndJob(profileId, locale));
          modalStore.setAddShortlistInfos({
            profileId: parseInt(router.query[ModalKey.ADD_SHORTLIST] as string),
            profileName: profile.name,
            profileJob: findTranslationOrDefault(profile.job, locale)
          });
        }
      }
      catch (err) {

      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query[ModalKey.ADD_SHORTLIST] as string])

  useEffect(() => {
    // Fetch already selected shortlists (each time opening modal)
    (async function getShortlists() {
      try {
        if (!!router.query[ModalKey.ADD_SHORTLIST] && modalStore.addShortlistInfos?.profileId) {
          setLoading(true);
          setError(false);
          let profileShortlistIds: number[] = await send(ShortlistController.getForProfile(modalStore.addShortlistInfos?.profileId))
          setAlreadyInShortlists(profileShortlistIds);
          setSelectedShortlists(profileShortlistIds);
          setLoading(false);
        }
      }
      catch (err) {
        setError(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query[ModalKey.ADD_SHORTLIST] as string, modalStore.addShortlistInfos?.profileId]);

  return (
    <React.Fragment>
      {
        userStore.isLogged ?
          <Modal
            modalKey={ModalKey.ADD_SHORTLIST}
            header={
              <React.Fragment>
                <ModalTitle name={t('shortlist.add.title', { name: modalStore.addShortlistInfos?.profileName })} />
                <div className={styles.subtitleContainer}>
                  <ModalSubTitle name={t('shortlist.add.subtitle')} />
                </div>
              </React.Fragment>
            }
            footer={
              <div className={styles.buttonContainer}>
                <OwardButton name={t('global.validate')} onClick={async () => {
                  let items: AddingProfileToShortlistItem[] = new Array<AddingProfileToShortlistItem>();
                  shortlists?.map((shortlist: Shortlist) => {
                    let item: AddingProfileToShortlistItem;
                    switch (isAddingOrDeleting(shortlist.id)) {
                      case AddingOrRemoving.ADDING:
                        item = { shortlistId: shortlist.id, shortlistName: shortlist.name, isRemoving: false };
                        items.push(item);
                        break;
                      case AddingOrRemoving.REMOVING:
                        item = { shortlistId: shortlist.id, shortlistName: shortlist.name, isRemoving: true };
                        items.push(item);
                        break;
                      default:
                        break;
                    }
                  });
                  try {
                    await send(ShortlistController.addRemoveProfile({
                      profileId: modalStore.addShortlistInfos?.profileId,
                      items: items
                    }));
                    toast.dark(
                      <ToastSucess>
                        {
                          items.length > 0 ?
                            <React.Fragment>
                              <ShortlistToasterContent isRemoving={false} items={items} />
                              <ShortlistToasterContent isRemoving={true} items={items} />
                            </React.Fragment>
                            :
                            <p>{t('edit_profile.saved_no_modif')}</p>
                        }
                      </ToastSucess>
                    );
                    // If we're on the shorlist page from which we're removing a profile, reload the cards
                    // as we want the deleted profile to disapear
                    if (
                      filterStore.filter?.shortlist?.id &&
                      isAddingOrDeleting(Number(filterStore.filter?.shortlist?.id)) === AddingOrRemoving.REMOVING
                    ) {
                      await cardStore.getCards(filterStore.filter, filterStore.openSearch);
                    }
                    mutate(SWR_USER_SHORTLISTS_GET);
                    modalStore.unpopModal(router);
                  } catch (err) {
                    toast.dark(<ToastError msg={t('global.error_with_code', { code: err })} />);
                  }
                }} />
                <OwardButton name={t('global.cancel')} outlined onClick={() => { modalStore.unpopModal(router) }} />
              </div>
            }
          >
            <div className={styles.mainContainer}>
              <React.Fragment>
                {
                  isError || error ?
                    <p className={styles.error}>{t('global.error_retry')}</p>
                    :
                    <React.Fragment>
                      <OwardLoader loading={isLoading || loading} />
                      {
                        shortlists?.map((shortlist: Shortlist, i: number) => {
                          return (
                            <ShortlistLine
                              key={i}
                              shortlist={shortlist}
                              name={modalStore.addShortlistInfos?.profileName}
                              active={isShortlistSelected(shortlist.id)}
                              adding={isAddingOrDeleting(shortlist.id)}
                              onClick={() => selectShortlist(shortlist.id)}
                            />
                          );
                        })
                      }
                      <OwardButton name={t('shortlist.new.title')} outlined onClick={() => {
                        modalStore.popModal(router, ModalKey.NEW_SHORTLIST, 1);
                      }} />
                    </React.Fragment>
                }
              </React.Fragment>
            </div>
          </Modal >
          :
          <Modal modalKey={ModalKey.ADD_SHORTLIST}>
            <NeedConnectionModal headerMessage={t('shortlist.need_connection')} />
          </Modal>
      }
    </React.Fragment>);
});

interface ShortlistToasterContentProps {
  items: AddingProfileToShortlistItem[];
  isRemoving: boolean;
}

const ShortlistToasterContent: React.FC<ShortlistToasterContentProps> = observer(props => {
  const { t } = useTranslate();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  let items: AddingProfileToShortlistItem[] = props.items.filter(item => item.isRemoving === props.isRemoving);

  return (
    <React.Fragment>
      {
        items.length > 0 &&
        <div>
          {t(
            `shortlist.add.toaster_${props.isRemoving ? 'remove' : 'add'}`,
            { name: modalStore.addShortlistInfos.profileName, smart_count: items.length }
          )}
          {items.map((item, i) => <ShortlistLinkLine id={item.shortlistId} name={item.shortlistName} key={i} />)}
        </div>
      }
    </React.Fragment>
  );
});

interface ShortlistLinkLineProps {
  id: number;
  name: string;
}

const ShortlistLinkLine: React.FC<ShortlistLinkLineProps> = observer(props => {
  const router = useRouter();
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);

  return (
    <p>
      {'- '}
      <span
        className={styles.shortlistLink}
        onClick={() => {
          filterStore.resetFilters();
          filterStore.setFilter(router, FilterType.SHORTLIST, { id: props.id }, props.name);
        }}>
        {props.name}
      </span>
    </p>
  );
});
