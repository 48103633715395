import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './PortfolioHeader.module.scss';
import { OwardLinkButton, CreateOrMyProfilesButton } from '@components/.';
import { useTranslate } from '@hooks/.';

export const PortfolioHeader = observer(() => {
  const { t } = useTranslate();

  return (
    <React.Fragment>
      {
        <div id='gallery-header-container' className={`${styles.mainContainer}`}> {/* id is used to scroll when filtering */}
          <div className={styles.topContainer}>
            <div className={styles.titlesContainer}>
              <h1 className={styles.title}>{t('portfolio.header.title')}</h1>
              <h3 className={styles.subtitle}>{t('portfolio.header.subtitle')}</h3>
            </div>
            <div className={styles.buttonsContainer}>
              <a
                href='https://portfolio.oward.co'
                target='_blank'
                rel='noreferrer'
              >
                <OwardLinkButton
                  name={t('portfolio.header.know_more')}
                  transparentBlue
                />
              </a>
              <CreateOrMyProfilesButton portfolio />
            </div>
          </div>
          <div className={styles.useOwardContainer}>
            <p className={styles.useOward}>{t('portfolio.header.use_oward')}</p>
          </div>
        </div >
      }
    </React.Fragment>
  );
});

export default PortfolioHeader;
