import React, { useEffect } from 'react';
import styles from './MessagesPage.module.scss';
import { observer } from 'mobx-react-lite';
import { Profile } from '@oward/openapi';
import { MessagesDiscussionContainer, MessagesSidebar } from '@components/Messages';
import { StoresBindings } from '@container/.';
import { MessageStore } from '@stores/.';
import { useDiscussionsForProfile, useInjection } from '@hooks/.';
import { useRouter } from 'next/router';

interface MessagesPageProps {
  profile: Profile;
}

export const MessagesPage: React.FC<MessagesPageProps> = observer(props => {
  const router = useRouter();
  const messageStore = useInjection<MessageStore>(StoresBindings.MESSAGE);
  const { discussions } = useDiscussionsForProfile(props.profile.id);

  useEffect(() => {
    // Be sure to have discussion container in 100% viewport - Navbar (and get rid of adress bar mess)
    // see : https://css-tricks.com/the-trick-to-viewport-units-on-mobile/

    const setVh = () => {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Different for Safari iOS... that's a mess, and with this solution we don't fully take URL bar
      // into account...
      let vhSafari = document.documentElement.clientHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      document.documentElement.style.setProperty('--vhSafari', `${vhSafari}px`);
    }

    setVh();

    // We listen to the resize event
    window.addEventListener('resize', setVh);

    return (
      () => { window.removeEventListener('resize', setVh) }
    );
  }, []);

  useEffect(() => {
    messageStore.setCurrentProfile(props.profile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.profile.id]);

  useEffect(() => {
    if (discussions) {
      messageStore.setCurrentDiscussion(
        discussions?.find(discussion => discussion.id === parseInt(router.query['d'] as string))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query['d'], discussions]);

  return (
    <div className={styles.mainContainer}>
      <MessagesSidebar />
      <MessagesDiscussionContainer />
    </div>
  );
});
