import React from 'react';
import { NavBar } from './NavBar';

import styles from './NavBar.module.scss';

export interface WithNavbarProps {
  justLogo?: boolean;
  children?: React.ReactNode;
}

export const WithNavbar: React.FC<WithNavbarProps> = props => {
  return (
    <div className={styles.mainContainer}>
      <NavBar justLogo={props.justLogo} />
      {props.children}
    </div>
  )
}

WithNavbar.defaultProps = {
  justLogo: false,
} as Partial<WithNavbarProps>;
