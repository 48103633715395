import React from 'react';
import { FilmsController } from '@oward/openapi';

import { EntityCount, Section } from '@components/admin';

export const FilmTypeStatistics: React.FC = () => {

  return (
    <React.Fragment>
      <Section
        title='Statistics'
        subtitle='Basic statistics about films'>
        <table className="table is-hoverable">
          <thead>
            <tr>
              <th>Key</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Films Count</td>
              <td><EntityCount getCountFunction={FilmsController.count} /></td>
            </tr>
            <tr>
              <td>Types Count</td>
              <td><EntityCount getCountFunction={FilmsController.countType} /></td>
            </tr>
          </tbody>
        </table>
      </Section>
    </React.Fragment>
  );
}
