import React from 'react';
import { FestivalController } from '@oward/openapi';

import { EntityCount, Section } from '@components/admin';

export const FestivalStatistics: React.FC = () => {
  return (
    <React.Fragment>
      <Section
        title='Statistics'
        subtitle='Basic statistics about festivals'>
        <table className="table is-hoverable">
          <thead>
            <tr>
              <th>Key</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Festivals Count</td>
              <td><EntityCount getCountFunction={FestivalController.count} /></td>
            </tr>
            <tr>
              <td>Festival Categories Count</td>
              <td><EntityCount getCountFunction={FestivalController.countCategories} /></td>
            </tr>
          </tbody>
        </table>
      </Section>
    </React.Fragment>
  );
}
