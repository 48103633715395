import React from 'react'
import styles from './EditArtwork.module.scss';
import { ArtworkSize, artworkToCardArtwork, CardArtwork, } from '@components/.'
import { EditArtworkStore } from '@components/Pages';
import { useTranslate } from '@hooks/.';
import { observer } from 'mobx-react-lite';

interface EditArtworkPreviewProps {
  store: EditArtworkStore;
}
export const EditArtworkPreview: React.FC<EditArtworkPreviewProps> = observer(props => {
  const { t, locale } = useTranslate();

  const getPreviewImage = (size: ArtworkSize): string => {
    switch (size) {
      case ArtworkSize.LARGE:
        return props.store.coverPreviewL || props.store.artwork.images.large;
      case ArtworkSize.MEDIUM_LARGE:
        return props.store.coverPreviewML || props.store.artwork.images.mediumLarge;
      case ArtworkSize.MEDIUM_SMALL:
        return props.store.coverPreviewMS || props.store.artwork.images.mediumSmall;
      case ArtworkSize.SMALL:
        return props.store.coverPreviewS || props.store.artwork.images.small;
    }
  }

  return (
    <div className={styles.previewMainContainer} id={'edit-artwork-preview-container'}>
      <ArtworkPreview
        size={ArtworkSize.LARGE}
        store={props.store}
        coverPreview={props.store.coverPreviewL ??
          (!props.store.artwork.images.large
            && (getPreviewImage(ArtworkSize.MEDIUM_LARGE) ?? getPreviewImage(ArtworkSize.MEDIUM_SMALL) ?? getPreviewImage(ArtworkSize.SMALL))
          )}
      />
      <ArtworkPreview
        size={ArtworkSize.MEDIUM_LARGE}
        store={props.store}
        coverPreview={props.store.coverPreviewML ??
          (!props.store.artwork.images.mediumLarge
            && (getPreviewImage(ArtworkSize.LARGE) ?? getPreviewImage(ArtworkSize.MEDIUM_SMALL) ?? getPreviewImage(ArtworkSize.SMALL))
          )}
      />
      <ArtworkPreview
        size={ArtworkSize.MEDIUM_SMALL}
        store={props.store}
        coverPreview={props.store.coverPreviewMS ??
          (!props.store.artwork.images.mediumSmall
            && (getPreviewImage(ArtworkSize.LARGE) ?? getPreviewImage(ArtworkSize.MEDIUM_LARGE) ?? getPreviewImage(ArtworkSize.SMALL))
          )}
      />
      <ArtworkPreview
        size={ArtworkSize.SMALL}
        store={props.store}
        coverPreview={props.store.coverPreviewS ??
          (!props.store.artwork.images.small
            && (getPreviewImage(ArtworkSize.LARGE) ?? getPreviewImage(ArtworkSize.MEDIUM_LARGE) ?? getPreviewImage(ArtworkSize.MEDIUM_SMALL))
          )}
      />
    </div >
  )
})

interface ArtworkPreviewProps {
  size: ArtworkSize;
  store: EditArtworkStore;
  coverPreview: any;
}

const ArtworkPreview: React.FC<ArtworkPreviewProps> = observer(props => {
  const { t, locale } = useTranslate();

  return (
    <div>
      <div className={styles.explanationContainer}>
        <p className={styles.explanation}>{t('edit_artwork.preview_explanation')}{t('edit_artwork.cover_' + props.size + '.number')}</p>
        <p className={styles.ratio}>{t('edit_artwork.cover_' + props.size + '.ratio')}</p>
      </div>
      <div className={styles.artworkContainer} >
        <CardArtwork
          artwork={artworkToCardArtwork(
            props.store.artwork,
            locale,
            props.store.artwork.profile,
          )}
          artworkExtraInfos={{
            jobs: props.store.artwork.jobs,
            broadcasters: props.store.artwork.film?.broadcasters,
            filmSelections: props.store.artwork.film?.selections,
          }}
          artworkSize={props.size}
          preview
          coverPreview={props.coverPreview}
        />
      </div>
    </div>
  );
})
