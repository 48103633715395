
import React, { useEffect, useCallback, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutStore, CardStore } from '@stores/.'
import { useInjection, useTranslate } from '@hooks/.';
import { StoresBindings } from '@container/.';
import { CardI } from '@oward/openapi';
import { Card } from '../../Card';
import { onCardFullWidthDevice } from '@utils/.'
import styles from './GalleryCommon.module.scss';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import Loader from 'react-loader-spinner';
// Intersection Observer polyfill : https://github.com/GoogleChromeLabs/intersection-observer
require('intersection-observer');

interface GalleryCardsProps {
  deactivateFilters?: boolean;
}

export const GalleryCards: React.FC<GalleryCardsProps> = observer(props => {
  const { t } = useTranslate();
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);
  const cardStore = useInjection<CardStore>(StoresBindings.CARD);

  /*
  * DEACTIVTATED (Card ratio - Zoom)
  // Be sure the card scale ratio is 1 when on card full width device
  const rescaleCards = () => {
    if (onCardFullWidthDevice()) {
      layoutStore.scaleCards(1);
    }
    else {
      layoutStore.scaleCards(layoutStore.cardRatio);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', rescaleCards);
    // Use current ratio when re-rendering the gallery (when language change for example)
    layoutStore.scaleCards(layoutStore.cardRatio);

    return (
      () => { window.removeEventListener('resize', rescaleCards) }
    );
  }, []);
  */

  // Infinite Scroll (see https://www.youtube.com/watch?v=NZKUirTtxcg)
  const observerLastCard = useRef(null);
  const lastCardElementRef = useCallback(node => {
    if (cardStore.isLoading || cardStore.isMoreLoading) {
      return;
    }
    if (observerLastCard.current) {
      observerLastCard.current.disconnect();
    }
    observerLastCard.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && cardStore.hasMore) {
        cardStore.getMoreCards();
      }
    })
    if (node) {
      observerLastCard.current.observe(node);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardStore.isLoading, cardStore.isMoreLoading, cardStore.hasMore]);

  const observerBottomBar = useRef(null);
  const displayBottomBarElementRef = useCallback(node => {
    if (observerBottomBar.current) {
      observerBottomBar.current.disconnect();
    }
    observerBottomBar.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && !layoutStore.sidebarOpened) {
        layoutStore.displayBottomBar(true);
      }
      else {
        layoutStore.displayBottomBar(false);
      }
    })
    if (node) {
      observerBottomBar.current.observe(node);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`${styles.mainContainer}`}>
      {
        cardStore.isLoading &&
        <div className={`${styles.loaderContainer}`}>
          <Loader
            type='Grid'
            color={styles.greyDark}
            height={70}
            width={70}
          />
        </div>
      }
      {
        (cardStore.cards !== undefined && cardStore.cards.length > 0) ?
          <React.Fragment>
            <div ref={displayBottomBarElementRef} className={styles.displayBottomBarEmptyDiv} />
            <section id='cards-grid' className={`${styles.gridCardContainer}`}>
              {
                cardStore.cards.map((card: CardI, index: number) => {
                  // Ref of the last Card on screen, used for the infinite scroll
                  return <div
                    ref={cardStore.cards.length === index + 1 ? lastCardElementRef : undefined}
                    key={card.id}
                  >
                    <Card card={card} deactivateFilters={props.deactivateFilters} />
                  </div>;

                })
              }
              {
                cardStore.isMoreLoading &&
                <div className={`${styles.loaderMoreContainer}`}>
                  <Loader
                    type='Grid'
                    color={styles.greyDark}
                    height={70}
                    width={70}
                  />
                </div>
              }
            </section >
          </React.Fragment>
          :
          <div className={`${styles.emptyContainer}`}>
            {
              !cardStore.isLoading &&
              <p>
                {cardStore.error ? t('gallery.error') : t('gallery.empty')}
              </p>
            }
          </div>
      }
    </div>
  );
});

const GalleryCardsScroll = ({ scrollPosition }) => {
  const cardStore = useInjection<CardStore>(StoresBindings.CARD);
  cardStore.setScrollPosition(scrollPosition);

  return (
    <GalleryCards />
  )
}

export default trackWindowScroll(GalleryCardsScroll);
