import React, { useEffect, useState } from 'react';
import styles from './ArtworkPage.module.scss';
import ShareIcon from '../../../assets/icons/paper_plane.svg';
import MoreIcon from '../../../assets/icons/more.svg';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import {
  ArtworkController, ArtworkExtraInfosI, CardArtworkI,
  FilmSelection, Broadcaster, send, FestivalAward, ArtworkType, Profile
} from '@oward/openapi';
import { SelectionStatus, State } from '@oward/common-enums';
import { useBlockedArtworkIds, useInjection, useTranslate } from '@hooks/.';
import { ModalKey, ModalStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { observer } from 'mobx-react-lite';
import { festivalToStr, findTranslationOrDefault } from '@oward/common-utils';
import { Embed } from '@components/Embed';
import { ToastError, ToastSucess } from '@components/Core';
import { BlockedArtworkCover, CardAvatar, ToastPortfolioNotAccessible } from '@components/Card';
import { festivalAwardToStr, hasPortfolioPublic } from '@utils/.';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from '@components/I18n';
import { useRouter } from 'next/router';

interface ArtworkPageProps {
  pageContainer?: boolean;
}

export const ArtworkPage: React.FC<ArtworkPageProps> = observer((props: ArtworkPageProps) => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const { blockedArtworkIds } = useBlockedArtworkIds();

  let cardArtwork: CardArtworkI = modalStore.artwork?.cardArtwork;
  let artworkExtraInfos: ArtworkExtraInfosI = modalStore.artwork?.artworkExtraInfos;

  useEffect(() => {
    // If opening the modal from the gallery, get ArtworkExtraInfo (as we already have CardArtwork)
    // Only necessary for selected/broadcasted films/contributor profiles
    if (
      modalStore.artwork !== undefined &&
      artworkExtraInfos === undefined
    ) {
      (async function getArtworkExtraInfos() {
        try {
          const extraInfos: ArtworkExtraInfosI = await send(
            ArtworkController.getExtraInfos(locale, cardArtwork.id.toString())
          );
          modalStore.setArtwork({
            cardArtwork: modalStore.artwork.cardArtwork,
            artworkExtraInfos: extraInfos
          })
        }
        catch (err) {
          console.error('Error getting artwork extra infos: ', err);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shareArtwork = async () => {
    let url: string = process.env.WEBSITE_URL.concat('/', locale, '/artwork/', cardArtwork.path);
    if (navigator.share) {
      try {
        await navigator.share({
          title: document.title,
          text: t(
            'gallery.artwork.share_msg',
            { name: cardArtwork.profileName, job: cardArtwork.profileJob, title: cardArtwork.name }
          ),
          url: url
        })
      }
      catch (err) {
        // Cancelling 'share' throw an error. We don't really care.
      }
    }
    else {
      var textField = document.createElement('textarea');
      textField.innerText = url;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
      toast(<ToastSucess msg={t('global.toast_share')} />);
    }
  }

  return (
    <div className={classNames({ [styles.backgroundContainer]: props.pageContainer })}>
      {
        blockedArtworkIds?.find(id => id === cardArtwork.id) &&
        <BlockedArtworkCover artworkId={cardArtwork.id} artworkName={cardArtwork.name} />
      }
      <div className={classNames(styles.mainContainer, { [styles.notFullWidthContainer]: props.pageContainer })}>
        {
          modalStore.artwork?.cardArtwork !== undefined ?
            <React.Fragment>
              {
                cardArtwork.type === ArtworkType.VIDEO_LINK ?
                  cardArtwork.embedUrl && <Embed url={cardArtwork?.embedUrl} />
                  :
                  <div className='has-text-centered is-italic is-size-4 has-text-white mt-5 p-5'>
                    {t('gallery.artwork.no_video_link')}
                  </div>
              }
              <div className={styles.textContainer}>
                <div className={styles.titleContainer}>
                  <div className={styles.titleAndYearContainer}>
                    <p className={styles.titleAndYear}>
                      <span className={styles.bold}>{cardArtwork.name}</span>
                      , {cardArtwork.releaseYear === 0 ? t('wip.film') : cardArtwork.releaseYear}
                    </p>

                    <div className={`${styles.iconContainer}`}>
                      <div
                        data-tooltip={t('gallery.artwork.share_tooltip')}
                        className={classNames(
                          styles.iconSvgContainer,
                          styles.withBorder,
                          'has-tooltip-arrow',
                          'has-tooltip-left'
                        )}
                        onClick={shareArtwork}
                      >
                        <ShareIcon className={styles.shareIcon} />
                      </div>
                    </div>
                    <div className={`${styles.iconContainer}`}>
                      <div
                        data-tooltip={t('modal.report_artwork.button')}
                        className={classNames(`${styles.iconSvgContainer}`, 'has-tooltip-arrow', 'has-tooltip-left')}
                        onClick={() => {
                          modalStore.setReportArtworkInfos({ artworkName: cardArtwork.name });
                          modalStore.popModal(router, ModalKey.REPORT_BLOCK_ARTWORK, cardArtwork.id);
                        }}
                      >
                        <MoreIcon className={styles.reportIcon} />
                      </div>
                    </div>
                  </div>
                  <p className={styles.filmType}>
                    {cardArtwork.filmType}
                  </p>
                  {
                    cardArtwork.profileName &&
                    <>
                      {
                        cardArtwork.profileHasPublicPorfolio ?
                          <Link href={`/p/[profilePath]`} as={`/p/${cardArtwork.profilePath}`} passHref>
                            <p className={styles.nameAndJob}>
                              {cardArtwork.profileName + ' - ' + cardArtwork.profileJob}
                            </p>
                          </Link>
                          :
                          <div
                            onClick={() => {
                              toast.dark(<ToastPortfolioNotAccessible profileId={cardArtwork.profileId} profileName={cardArtwork.profileName} />)
                            }}>
                            <p className={classNames(styles.nameAndJob, styles.deactivated)}>
                              {cardArtwork.profileName + ' - ' + cardArtwork.profileJob}
                            </p>
                          </div>
                      }
                    </>
                  }
                </div>
                {
                  cardArtwork.description &&
                  <div className={styles.descriptionContainer}>
                    <p>{cardArtwork.description}</p>
                  </div>
                }
                {
                  cardArtwork.directorName &&
                  <div className={styles.directorContainer}>
                    <p><span className={styles.bold}>{t('gallery.artwork.director')}</span> {cardArtwork.directorName}</p>
                  </div>
                }
                {
                  artworkExtraInfos !== undefined &&
                  <React.Fragment>
                    {
                      // If there's more than one job, or if the job is not the main Profile Job, show artwork jobs
                      (
                        artworkExtraInfos.jobs?.length > 1 ||
                        (artworkExtraInfos?.jobs && artworkExtraInfos?.jobs[0] && (findTranslationOrDefault(artworkExtraInfos?.jobs[0], locale) !== cardArtwork.profileJob))
                      ) &&
                      <div className={styles.selectionsContainer}>
                        <p>
                          <span className={styles.bold}>
                            {t('gallery.artwork.worked_as') + ' '}
                          </span>
                          {artworkExtraInfos.jobs.map((job, i) => {
                            return (
                              <React.Fragment key={i}>
                                <span className={styles.additionnalJobs}>
                                  {findTranslationOrDefault(job, locale)}
                                </span>
                                {(artworkExtraInfos.jobs.length !== i + 1 ? ', ' : '')}
                              </React.Fragment>
                            )
                          })}
                        </p>
                      </div>
                    }
                    {
                      artworkExtraInfos.filmSelections?.length > 0 &&
                      <div className={styles.selectionsContainer}>
                        <p>
                          <span className={styles.bold}>
                            {t('gallery.artwork.film_selection', artworkExtraInfos.filmSelections.length) + ' '}
                          </span>
                        </p>
                        {
                          artworkExtraInfos.filmSelections.length === 1 ?
                            <FilmSelectionString filmSelection={artworkExtraInfos.filmSelections[0]} />
                            :
                            <ul className={styles.selections}>
                              {
                                artworkExtraInfos.filmSelections.map((filmSelection, i) => {
                                  return <li key={i}><FilmSelectionString filmSelection={filmSelection} /></li>;
                                })
                              }
                            </ul>
                        }
                      </div>
                    }
                    {
                      artworkExtraInfos.broadcasters?.length > 0 &&
                      <div className={styles.selectionsContainer}>
                        <p>
                          <span className={styles.bold}>
                            {t('gallery.artwork.broadcaster', artworkExtraInfos.broadcasters.length) + ' '}
                          </span>
                        </p>
                        {
                          artworkExtraInfos.broadcasters.length === 1 ?
                            <BroadcasterString broadcaster={artworkExtraInfos.broadcasters[0]} />
                            :
                            <ul className={styles.selections}>
                              {
                                artworkExtraInfos.broadcasters.map((broadcaster, i) => {
                                  return <li key={i}><BroadcasterString broadcaster={broadcaster} /></li>;
                                })
                              }
                            </ul>
                        }
                      </div>
                    }
                    {
                      artworkExtraInfos.profileContributors?.length > 0 &&
                      <div className={styles.contributorsMainContainer}>
                        <p>
                          <span className={styles.bold}>
                            {t('gallery.artwork.contributors', artworkExtraInfos.profileContributors?.length) + ' '}
                          </span>
                        </p>
                        <div className={styles.contributorsContainer}>
                          {
                            artworkExtraInfos.profileContributors.map((profile, i) =>
                              <ContributorProfile profile={profile} key={i} />
                            )
                          }
                        </div>
                      </div>
                    }
                  </React.Fragment>
                }
              </div>
              {
                cardArtwork.photoUrls.find(url => url.state === State.LIVE) &&
                <div className={styles.photosContainer} >
                  {cardArtwork.photoUrls.map((url, index) => {
                    if (url.state === State.LIVE) {
                      return (
                        <div className={styles.photoContainer} key={index}  >
                          <LazyLoadImage
                            src={url.url}
                            key={index}
                            alt={t('alt.artwork_image', {
                              name: cardArtwork.name,
                              film_type: cardArtwork.filmType,
                              profile_name: cardArtwork.profileName,
                              profile_job: cardArtwork.profileJob,
                              order: url.order?.toString()
                            })}
                            effect='blur'
                          />
                        </div>
                      )
                    }
                  })}
                </div>
              }
            </React.Fragment>
            :
            <div className={styles.errorContainer}>
              <p className={styles.error}>{t('gallery.artwork.error')}</p>
            </div>
        }
      </div>
    </div >
  );
});

ArtworkPage.defaultProps = {
  pageContainer: false
} as Partial<ArtworkPageProps>;

interface ContributorProfileProps {
  profile: Profile;
}

const ContributorProfile: React.FC<ContributorProfileProps> = props => {
  const { t, locale } = useTranslate();

  const ContributorContent = () => {
    return (
      <>
        <div className={styles.avatarContainer}>
          <CardAvatar
            avatarUrl={props.profile.avatarUrl}
            profilePath={props.profile.path}
            profileId={props.profile.id}
            profileName={props.profile.name}
            profileJobString={findTranslationOrDefault(props.profile.job, locale)}
            profileCityString={findTranslationOrDefault(props.profile.location?.city, locale)}
            hasPortfolioPublic={hasPortfolioPublic(props.profile)}
            preview={!hasPortfolioPublic(props.profile)}
          />
        </div>
        <div className={styles.contributorTextContainer}>
          <p className={styles.name}>{props.profile.name}</p>
          <p className={styles.job}>{findTranslationOrDefault(props.profile.job, locale)}</p>
        </div>
      </>
    );
  }

  return (
    <>
      {
        hasPortfolioPublic(props.profile) ?
          <Link href={`/p/[profilePath]`} as={`/p/${props.profile.path}`} passHref>
            <div className={styles.contributorContainer}>
              <ContributorContent />
            </div>
          </Link>
          :
          <div
            className={classNames(styles.contributorContainer, styles.deactivated)}
            onClick={() => { toast.dark(<ToastPortfolioNotAccessible profileId={props.profile.id} profileName={props.profile.name} />) }}
          >
            <ContributorContent />
          </div>
      }
    </>
  )
}

interface BroadcasterStringProps {
  broadcaster: Broadcaster;
}

const BroadcasterString: React.FC<BroadcasterStringProps> = (props: BroadcasterStringProps) => {
  const { t, locale } = useTranslate();
  let broadcaster: Broadcaster = props.broadcaster;

  const categoryToIcon = (category: string): string => {
    switch (category) {
      case 'Cinémas':
        return 'fas fa-ticket-alt';
      case 'Plateforme ou VOD':
        return 'fas fa-th';
      case 'TV':
        return 'fas fa-tv';
      case 'Support physique':
        return 'fas fa-compact-disc';
      case 'Web (accès libre)':
        return 'fas fa-play-circle'
    }
  }

  return (
    <React.Fragment>
      {
        <span className={classNames('icon')} >
          <div
            data-tooltip={t('gallery.artwork.broadcasted.' + broadcaster.category.name)}
            className={classNames('has-tooltip-arrow', 'has-tooltip-right', `${styles.awardedIcon}`)}
          >
            <i className={categoryToIcon(broadcaster.category.name)}></i>
          </div>
        </span>
      }
      {' ' + broadcaster.name}
    </React.Fragment>
  );
}

interface FilmSelectionStringProps {
  filmSelection: FilmSelection;
}

const FilmSelectionString: React.FC<FilmSelectionStringProps> = (props: FilmSelectionStringProps) => {
  const { t, locale } = useTranslate();
  let filmSelection: FilmSelection = props.filmSelection;
  let festivalAwardStr: string = festivalAwardToStr(filmSelection.award as FestivalAward, locale);

  return (
    <React.Fragment>
      {
        filmSelection.status !== SelectionStatus.SELECTED ?
          <span className={classNames('icon')} >
            <div
              data-tooltip={t('gallery.artwork.awarded')}
              className={classNames('has-tooltip-arrow', 'has-tooltip-right', `${styles.awardedIcon}`)}
            >
              <i className={classNames('fas fa-trophy')}></i>
            </div>
          </span>
          :
          '• '
      }
      <span className='has-text-weight-semibold'>
        {' ' + festivalToStr(filmSelection.festival, locale)}
      </span>
      <span>
        {
          (festivalAwardStr ? ', ' + festivalAwardStr : '') +
          ' (' + filmSelection.year.toString() + ')'
        }
      </span>
    </React.Fragment>
  );
}
