import React, { useEffect, useState } from 'react';
import styles from './MessagesDiscussion.module.scss';
import {
  useDiscussionsForProfile,
  useInjection,
  useMessagesForDiscussion,
  useTranslate
} from '@hooks/.';
import { LayoutStore, MessageStore, ModalKey, ModalStore, PopupStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import {
  OwardButton,
  OwardTextArea,
  OwardLoader,
  ToastError,
  OwardLoadFileButton,
  SeeOrActionProfilePopUp,
} from '@components/Core';
import { toast } from 'react-toastify';
import {
  Discussion,
  Message,
  MessageController,
  MessageType,
  Profile,
  State
} from '@oward/openapi';
import { mutate } from 'swr';
import {
  ERR_MSG_BAD_REQUEST,
  ERR_MSG_IM_A_TEAPOT,
  ERR_MSG_UNAUTHORIZED,
  SWR_DISCUSSIONS_FOR_PROFILE,
  SWR_DISCUSSIONS_UNREAD_COUNT_FOR_USER
} from '@utils/constants';
import { observer } from 'mobx-react-lite';
import {
  dataURItoJpegBlob,
  goToHomePageAndReload,
  hasPortfolioPublic,
  onTouchDevice,
  remSizeToPixelNumber
} from '@utils/utils';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { findTranslationOrDefault, MAX_SIZE_PICTURES_DISCUSSION } from '@oward/common-utils';
import { Messages } from '.';
import moment from 'moment';
import { BigErrorMessage, ToastPortfolioNotAccessible } from '..';
import request from "superagent";
import { DiscussionMemberRole, DiscussionType } from '@oward/common-enums';

export const MessagesDiscussionContainer: React.FC = observer(() => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const messageStore = useInjection<MessageStore>(StoresBindings.MESSAGE);
  const { isLoading, isError } = useDiscussionsForProfile(messageStore.currentProfile?.id);
  const [discussionId, setDiscussionId] = useState(parseInt(router.query['d'] as string));

  useEffect(() => {
    setDiscussionId(parseInt(router.query['d'] as string));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query['d']])

  return (
    <div className={styles.messagesDiscussionContainer} id='messages-discussion-container'>
      {
        (discussionId && !isError && !isLoading) ?
          <React.Fragment>
            <DiscussionHeader discussionId={discussionId} />
            <Messages discussionId={discussionId} />
            <SendMessageContainer discussionId={discussionId} />
          </React.Fragment>
          :
          <div className={styles.noDiscussionContainer}>
            {
              isError ?
                <BigErrorMessage />
                :
                isLoading ?
                  <OwardLoader loading={isLoading} />
                  :
                  <React.Fragment>
                    <p>{t('messages.no_active_disucssion')}</p>
                    <p>
                      <span className='icon is-large'>
                        <i className={'fas fa-2x fa-arrow-left'}></i>
                      </span>
                    </p>
                  </React.Fragment>
            }
          </div>
      }
    </div>
  );
});

interface DiscussionHeaderProps {
  discussionId: number;
}

const DiscussionHeader: React.FC<DiscussionHeaderProps> = observer(props => {
  const router = useRouter();
  const { t, locale } = useTranslate();
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);
  const messageStore = useInjection<MessageStore>(StoresBindings.MESSAGE);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);
  const { discussions } = useDiscussionsForProfile(messageStore.currentProfile?.id);
  const [activeDiscussion, setActiveDiscussion] = useState<Discussion>(undefined);
  const [currentProfile, setCurrentProfile] = useState<Profile>(undefined);

  useEffect(() => {
    let activeDiscussion = discussions?.find(discussion => discussion.id === props.discussionId);
    setActiveDiscussion(activeDiscussion);
    setCurrentProfile(activeDiscussion?.members[0]?.profile)
  }, [props.discussionId, discussions]);

  const ProfileHeader = () => {
    return (
      <div
        className={classNames(
          `${styles.profileContentContainer}`,
          'has-tooltip-bottom', 'has-tooltip-arrow'
        )}
        data-tooltip={t('messages.see_profile')}
        onClick={() => {
          popupStore.openGenericPopUp(
            <SeeOrActionProfilePopUp profileId={currentProfile?.id} />
          )
        }}
      >
        {
          currentProfile?.avatarUrl &&
          <div className={styles.avatarContainer}>
            <LazyLoadImage
              src={currentProfile?.avatarUrl}
              width={remSizeToPixelNumber(styles.avatarSize)}
              height={remSizeToPixelNumber(styles.avatarSize)}
              className={classNames(
                styles.avatar,
                hasPortfolioPublic(currentProfile) && styles.goldenBorder
              )}
              alt={t('alt.avatar_no_city', { name: currentProfile?.name, job: currentProfile?.job?.name })}
              effect='blur'
            />
          </div>
        }
        <div className={styles.nameJobContainer}>
          <h2 className={styles.name}>
            {currentProfile?.name}
          </h2>
          <p className={styles.job}>
            {findTranslationOrDefault(currentProfile?.job, locale)}
          </p>
        </div>
      </div>
    );
  }

  const GroupDiscussionHeader = () => {
    const openGroupDiscussionManageModal = () => {
      modalStore.setManageGroupDiscussionInfos({ discussionName: activeDiscussion.name });
      modalStore.openModalNewStack(router, ModalKey.MESSAGES_MANAGE_GROUP_DISCUSSION, activeDiscussion.id);
    }

    const isAdmin = activeDiscussion?.members[0]?.role === DiscussionMemberRole.ADMIN;

    return (
      <>
        <div
          className={styles.profileContentContainer}
          onClick={openGroupDiscussionManageModal}
        >
          <div className={styles.groupDiscussionContainer}>
            <h2 className={styles.name}>
              {activeDiscussion?.name}
            </h2>
            {
              activeDiscussion?.type &&
              <p className={styles.type}>
                {t(`messages.group_discussion.header.${activeDiscussion?.type}`)}
              </p>
            }
          </div>
        </div>
        <div className={styles.parameterContainer}>
          <div
            className={classNames(
              styles.parameterButton,
              'has-tooltip-left', 'has-tooltip-arrow'
            )}
            onClick={openGroupDiscussionManageModal}
            data-tooltip={isAdmin ?
              t('messages.group_discussion.manage_tooltip')
              :
              t('messages.group_discussion.see_profiles_tooltip')
            }
          >
            <p><span className='icon'>
              <i className={'fas fa-ellipsis-h'}></i>
            </span></p>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className={styles.discussionHeaderContainer}>
      <div className={styles.closeDiscussionContainer}>
        <div
          className={styles.closeDiscussion}
          onClick={() => { layoutStore.openMessagesDiscussionContainer(false) }}
        >
          <p><span className='icon'>
            <i className={'fas fa-arrow-left'}></i>
          </span></p>
        </div>
      </div>
      {
        activeDiscussion?.type === DiscussionType.ONETOONE ?
          <ProfileHeader />
          :
          <GroupDiscussionHeader />
      }
    </div>
  );
});

interface SendMessageContainerProps {
  discussionId: number;
}

const SendMessageContainer: React.FC<SendMessageContainerProps> = observer(props => {
  const { t } = useTranslate();
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);
  const messageStore = useInjection<MessageStore>(StoresBindings.MESSAGE);
  let { messages, mutateMessages } = useMessagesForDiscussion(props.discussionId);
  const [loading, setLoading] = useState(false);

  const messageType = () => {
    if (messageStore.messageDraftForDiscussionPicture(props.discussionId)) {
      return MessageType.PICTURE;
    }
    else if (messageStore.messageDraftForDiscussionText(props.discussionId)) {
      return MessageType.TEXT;
    }
  }

  const sendMessage = async () => {
    try {
      if (messageStore.messageDraftForDiscussionText(props.discussionId) || messageStore.messageDraftForDiscussionPicture(props.discussionId)) {
        let text: string, url: string = undefined;

        if (messageStore.messageDraftForDiscussionPicture(props.discussionId)) {
          url = await savePictureToS3(messageStore.messageDraftForDiscussionPicture(props.discussionId));
        }
        else {
          text = messageStore.messageDraftForDiscussionText(props.discussionId);
        }

        let newMessage: Message = {
          id: (messages[messages.length - 1]?.id + 1) ?? 1, // Temporary ID, bigger that last message, for sorting
          state: State.LIVE,
          type: messageType(),
          discussionId: props.discussionId,
          senderProfile: messageStore.currentProfile,
          senderProfileId: messageStore.currentProfile?.id,
          text: text,
          url: url,
          creationDate: moment().format(),
        };
        mutateMessages([[newMessage], [...messages]], false);
        mutate(
          [SWR_DISCUSSIONS_FOR_PROFILE, messageStore.currentProfile?.id],
          (discussions: Discussion[]) => {
            let updatedDiscussion: Discussion = discussions?.find(d => d.id === props.discussionId);
            if (!updatedDiscussion) {
              return;
            }

            if (updatedDiscussion.messages?.length < 1) {
              updatedDiscussion.messages = [newMessage];
            }
            else {
              updatedDiscussion.messages.unshift(newMessage);
            }
            let updatedDiscussionIndex: number = discussions?.findIndex(d => d.id === props.discussionId);
            let updatedDiscussions = [updatedDiscussion, ...discussions];
            updatedDiscussions.splice(updatedDiscussionIndex + 1, 1);
            return updatedDiscussions;
          },
          false
        );

        await MessageController.sendMessage({
          discussionId: props.discussionId,
          senderProfileId: messageStore.currentProfile?.id,
          text: newMessage.text,
          url: newMessage.url,
          type: newMessage.type,
        });
        messageStore.setMessageDraftText(props.discussionId, '');
        messageStore.setMessageDraftPicture(props.discussionId, null);
      }

    } catch (err) {
      if (err instanceof Error && err.message === ERR_MSG_UNAUTHORIZED) {
        popupStore.openInformationPopUp({
          msg: t('global.force_logout'),
          callback: () => { goToHomePageAndReload() }
        });
        return;
      }
      else if (err instanceof Error && err.message === ERR_MSG_BAD_REQUEST) {
        toast.dark(<ToastError msg={t('messages.error.cant_send_not_in_discussion')} />)
      }
      else if (err instanceof Error && err.message === ERR_MSG_IM_A_TEAPOT) {
        toast.dark(<ToastError msg={t('messages.error.cant_send_spectator')} />)
      }
      else {
        toast.dark(<ToastError msg={t('global.error_with_code', { code: err })} />)
      }
    }
    finally {
      mutateMessages();
      mutate([SWR_DISCUSSIONS_FOR_PROFILE, messageStore.currentProfile?.id]);
    }
  }

  const keyPress = (e: any) => {
    enterPress(e);
    deletePress(e);
  }

  const enterPress = (e: any) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      sendMessage();
    }
  }

  const deletePress = (e: any) => {
    if ((e.key == 'Backspace' || e.key == 'Delete') && messageStore.messageDraftForDiscussionPicture(props.discussionId)) {
      messageStore.setMessageDraftPicture(props.discussionId, null);
    }
  }

  const updateLastMessageSeen = async () => {
    if (
      messages?.length > 0 &&
      !messages[messages.length - 1]?.messageLastSeens?.find(lastSeen => lastSeen.profileId === messageStore.currentProfile.id)
    ) {
      await MessageController.updateLastMessageSeen({
        messageId: messages[messages.length - 1]?.id,
        discussionId: props.discussionId,
        profileId: messageStore.currentProfile.id
      });
      mutate(SWR_DISCUSSIONS_UNREAD_COUNT_FOR_USER);
      mutate([SWR_DISCUSSIONS_FOR_PROFILE, messageStore.currentProfile?.id]);
    }
  }

  const onSelectFile = (e: any) => {
    const file = e.target.files[e.target.files.length - 1];
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[e.target.files.length - 1].size >= MAX_SIZE_PICTURES_DISCUSSION) {
        popupStore.openInformationPopUp(t('edit_artwork.artwork_photos_size_limit'))
        return;
      }
      setLoading(true);
      e.target.value = '';
      const reader = new FileReader();
      reader.addEventListener('load', async (e) => {
        const image = reader.result;
        messageStore.setMessageDraftPicture(props.discussionId, image);
        setLoading(false);
      });
      reader.readAsDataURL(file);
    }
  };

  const savePictureToS3 = async (imageToPut: any): Promise<string> => {
    let pictureUrl: string = undefined;
    if (imageToPut) {
      let img = new Image();
      img.src = imageToPut
      const pictureName: string = `${messageStore.currentProfile.path}-messagepicture-${messageStore.currentProfile.creationDate}-${new Date().toISOString()}-h:${img.naturalHeight};w:${img.naturalWidth}.jpg`;
      try {
        setLoading(true);
        let coverS3PutUrl = (await MessageController.getPicturePutUrl(pictureName)).text;
        await request.put(coverS3PutUrl).send(dataURItoJpegBlob(imageToPut));
        pictureUrl = process.env.CDN_MESSAGES_URL_PREFIX + pictureName;
      }
      catch (err) {
        toast.dark(<ToastError msg={t('edit_profile.error.saving_picture', { code: err })} />);
      }
      finally {
        setLoading(false);
      }
    }
    return pictureUrl;
  }

  const InputDisplayer = () => {
    if (messageStore.messageDraftForDiscussionPicture(props.discussionId)) {
      return (
        <React.Fragment>
          <div className={styles.pictureMainContainer}
            contentEditable={true}
            onKeyDown={!onTouchDevice() ? keyPress : undefined}
            onClick={updateLastMessageSeen}
            suppressContentEditableWarning={true} //Otherwise, console in navigators is full of errors when we upload a picture
          >
            <div className={styles.pictureContainer}>
              <img
                className={styles.picture}
                src={messageStore.messageDraftForDiscussionPicture(props.discussionId)}
                alt={''}
                loading={'eager'}
              />
            </div>
            <div className={classNames(styles.deletePictureContainer)}>
              <p onClick={() => { messageStore.setMessageDraftPicture(props.discussionId, null); }}>
                <span className={`icon`}><i className='fas fa-times'></i></span>
              </p>
            </div>
          </div>
        </React.Fragment>
      )
    }
    else {
      return (
        <OwardTextArea
          placeholder={t('messages.send.placeholder')}
          rows={Math.min(messageStore.messageDraftForDiscussionText(props.discussionId).split(/\r|\r\n|\n/)?.length, 4)}
          onChange={(value) => { messageStore.setMessageDraftText(props.discussionId, value); }}
          value={messageStore.messageDraftForDiscussionText(props.discussionId)}
          onKeyDown={!onTouchDevice() ? keyPress : undefined}
          onClick={updateLastMessageSeen}
          noBottomPadding
        />
      )
    }
  }

  return (
    <React.Fragment>
      {loading && <OwardLoader loading={loading} />}
      {
        messageStore.currentDiscussion?.members?.find(member => member.profileId === messageStore.currentProfile?.id)?.role === DiscussionMemberRole.SPECTATOR ?
          <div className={styles.sendMessageContainer}>
            <p className={styles.spectatorCantSend}>{t('messages.send.spectator_cant_send')}</p>
          </div>
          :
          <div className={messageType() === MessageType.PICTURE ? styles.sendMessageContainerPicture : styles.sendMessageContainer}>
            <div className={styles.uploadButton} >
              <OwardLoadFileButton
                id={'message-url-photos'}
                outlined
                onClick={onSelectFile}
                onlyImages
                transparent
                iconName={'fa fa-image'}
                tooltipText={t('messages.send.send_image')}
                tooltipPostion={onTouchDevice() ? 'has-tooltip-right' : 'has-tooltip-top'} //only desktop will have the tooltip on top
              />
            </div>
            {
              InputDisplayer()
            }
            <div style={{ float: 'right' }} >
              <OwardButton
                name={t('messages.send.button')}
                onClick={sendMessage}
              />
            </div>
          </div>
      }
    </React.Fragment>
  )
});
