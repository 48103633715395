import React from 'react';
import styles from './EditArtwork.module.scss';
import { observer } from 'mobx-react-lite';
import { useTranslate } from '@hooks/use-translate';
import { Label, OwardButton, OwardLoadFileButton, ToastError } from '@components/.';
import { StoresBindings } from '@container/keys';
import { useInjection } from '@hooks/use-injection';
import { ArtworkType, State } from '@oward/common-enums';
import { Artwork, ArtworkController, ArtworkUrl, send } from '@oward/openapi';
import { PopupStore } from '@stores/popup-store';
import { dataURItoJpegBlob } from '@utils/utils';
import request from 'superagent';
import { toast } from 'react-toastify';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { action, runInAction } from 'mobx';
import { MAX_SIZE_PICTURES_ARTWORK } from '@oward/common-utils';

interface EditArtworkPhotosProps {
  artwork: Artwork;
  setLoading?: (loading: boolean) => void;
  photosChanged?: () => void;
}

export const EditArtworkPhotos: React.FC<EditArtworkPhotosProps> = observer(props => {
  const { t } = useTranslate();
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);

  const getLivePhotoUrls = (): ArtworkUrl[] => {
    return props.artwork.urls?.filter(url => url?.state === State.LIVE && url?.type === ArtworkType.PICTURE_PORTFOLIO);
  }

  const savePicture = async (imageToPut: any) => {
    if (imageToPut) {
      try {
        props.setLoading && (props.setLoading(true));
        let s3Url = await savePictureToS3(imageToPut);
        await saveArtworkUrlToList(s3Url);
      }
      catch (err) {
        toast.dark(<ToastError msg={t('edit_profile.error.saving_picture', { code: err })} />);
      }
      finally {
        props.setLoading && (props.setLoading(false));
      }
    }
  }

  const savePictureToS3 = async (imageToPut: any): Promise<string> => {
    const liveArtworksLenght: number = props.artwork.urls.filter(artwork => artwork.state === State.LIVE).length;
    const pictureName: string = `${props.artwork.path}-${props.artwork.profile.path}-${liveArtworksLenght}-${new Date().toISOString()}.jpg`;
    let coverS3PutUrl = (await ArtworkController.getPicturePutUrl(pictureName)).text;
    await request.put(coverS3PutUrl).send(dataURItoJpegBlob(imageToPut));
    return process.env.ARTWORK_PHOTO_URL_PREFIX + pictureName;
  }

  const saveArtworkUrlToList = async (s3Url: string) => {
    let newArtworkUrl = await send(ArtworkController.saveOneArtworkUrl({
      url: s3Url,
      artworkUrlLength: getLivePhotoUrls()?.length,
      artworkId: props.artwork.id
    }));
    runInAction(() => {
      newArtworkUrl.state = State.LIVE; // Newly created ArtworkUrl is in BLOCKED state
      props.artwork.urls.push(newArtworkUrl);
    })
    props.photosChanged();
  }

  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[e.target.files.length - 1].size >= MAX_SIZE_PICTURES_ARTWORK) {
        popupStore.openInformationPopUp(t('edit_artwork.artwork_photos_size_limit'))
        return;
      }
      const reader = new FileReader();
      reader.addEventListener('load', async () => {
        const img: (ArrayBuffer | string) = reader.result;
        await savePicture(img);
      });
      reader.readAsDataURL(e.target.files[e.target.files.length - 1]);
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const srcIndex = parseInt(result.source.index);
    const destIndex = parseInt(result.destination.index);
    const draggedLink = getLivePhotoUrls()[srcIndex];
    let newList = getLivePhotoUrls().slice();
    newList.splice(srcIndex, 1);
    newList.splice(destIndex, 0, draggedLink);

    newList.map((artUrl, index) => {
      artUrl.order = index;
    })

    // Get 'no Live Photo urls', to complete the one that are in DND list
    let notInDndArtworkUrl: ArtworkUrl[] = props.artwork.urls?.filter(url =>
      !(url.type === ArtworkType.PICTURE_PORTFOLIO && url.state === State.LIVE)
    );

    props.artwork.urls = [...notInDndArtworkUrl, ...newList];

    props.photosChanged();
  }

  const handleDelete = action((id: number) => {
    let index = props.artwork.urls.findIndex(url => url.id === id);
    if (index !== -1) {
      props.artwork.urls[index].state = State.DELETED;
      props.photosChanged();
    }
  });

  return (
    <React.Fragment>
      <Label
        name={t('edit_artwork.artwork_photos')}
        description={t('edit_artwork.artwork_photos_explanation')}
      />
      <div className={styles.artworkPhotoContainer}>
        <div className={styles.uploadButton}>
          <OwardLoadFileButton
            id={'artwork-url-photos'}
            name={t('edit_artwork.artwork_photos_upload')}
            outlined
            onClick={onSelectFile}
            onlyImages
          />
        </div>
        {
          getLivePhotoUrls().length > 0 &&
          <React.Fragment  >
            <div className={styles.mainContainerPhoto} >
              <div className={styles.descContainer}>
                <p className={styles.desc}>{t('edit_artwork.artwork_photos_dnd_explanation')}</p>
              </div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='artworkPhotoUrls'>
                  {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps} className={styles.listContainer}>
                      {
                        getLivePhotoUrls()?.map((url, index: number) => {
                          return (
                            <Draggable draggableId={`${index}`} index={index} key={index} >
                              {
                                (provided, snapshot) => (
                                  <div
                                    className={classNames(
                                      styles.draggablePhotoContainer,
                                      { [styles.draggingPhotoContainer]: snapshot.isDragging }
                                    )}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div className={styles.photosContainer} >
                                      <LazyLoadImage
                                        src={url?.url}
                                        className={styles.photo}
                                        alt={url?.url}
                                        effect='blur'
                                      />
                                    </div>
                                    <div className={styles.deleteButtonContainer}>
                                      <OwardButton
                                        tooltipText={t('edit_artwork.artwork_photos_delete_tooltip')}
                                        onClick={() => handleDelete(url.id)}
                                        dark={true}
                                        transparent={true}
                                        iconName={'fa fa-times'}
                                      />
                                    </div>
                                  </div>
                                )
                              }
                            </Draggable>
                          );
                        })
                      }
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </React.Fragment>
        }
      </div>
    </React.Fragment>
  )
})
