import React, { useEffect, useState } from 'react';
import styles from './ProfilePersoPage.module.scss';
import { useRouter } from 'next/router';
import { useInjection, useTranslate } from '@hooks/.';
import { FilterStore, UserStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { Artwork, Broadcaster, Festival, Job, Profile } from "@oward/openapi";
import { findTranslationOrDefault } from '@oward/common-utils';
import {
  CardArtwork, Link, artworkToCardArtwork, ArtworkSize,
  BioToReactComponents, OwardLinkButton,
  CardButtonContact, CardButtonOnFire, CardButtonReport, CardButtonShare,
  CardButtonShortlist, ButtonAspect, TitlePage, OwardInsertYellow, OwardInsertBlue
} from '@components/.';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { hasPortfolioPublic, isProfileExposed, onErrorImage, oniOSWebview, remSizeToPixelNumber } from '@utils/.';
import classNames from 'classnames';
import { FilterType } from '@oward/common-enums';
import BadgeCheckIcon from '../../../assets/icons/badge_check.svg';

interface ProfilePersoPageProps {
  profile: Profile;
}

export const ProfilePersoPage: React.FC<ProfilePersoPageProps> = props => {
  const { t, locale } = useTranslate();
  const router = useRouter();

  return (
    <>
      {
        hasPortfolioPublic(props.profile) ?
          <ProfilePersoPageContent profile={props.profile} />
          :
          <div className={styles.notPublishedMainContainer}>
            <div className={styles.headerContainer}>
              <Link href={`/account/profile/[profileId]`} as={`/account/profile/${props.profile.id}`} passHref>
                <p className={styles.link}>{'< ' + t('global.edit')}</p>
              </Link>
              <TitlePage title={t('page.profile.not_published.title')} />
              <div className={styles.buttonsMainContainer}>
                <div className={styles.buttonsContainer}>
                  <OwardInsertYellow
                    title={t('page.profile.not_published.insert_title')}
                    text={oniOSWebview() ? undefined : t('page.profile.not_published.insert_text')}
                  />
                  <Link href={`/account/profile/[profileId]`} as={`/account/profile/${props.profile.id}`}>
                    <OwardLinkButton
                      name={t('page.profile.not_published.edit_button')}
                      bigPadding
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className={styles.notPublishedPageContainer}>
              <ProfilePersoPageContent profile={props.profile} />
            </div>
            <div className={styles.headerContainer}>
              <OwardInsertBlue
                title={t('page.profile.not_published.edit_button')}
                text={t(`edit_profile.portfolio.publish_text`)}
                onClick={() => {
                  router.push(
                    `/[lang]/account/profile/[profileId]`,
                    `/${locale}/account/profile/${props.profile.id}`
                  );
                }}
              />
            </div>
          </div>
      }
    </>
  )
}

export const ProfilePersoPageContent: React.FC<ProfilePersoPageProps> = props => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);

  const [displayedArtworks, setDisplayedArtworks] = useState<Artwork[]>([]);
  const [notDisplayedArtworks, setNotDisplayedArtworks] = useState<Artwork[]>([]);
  const [uniqueAdditionnalJobs, setUniqueAdditionalJobs] = useState<string[]>([]);
  const [uniquesFestivalsName, setUniqueFestivalsName] = useState<string[]>([]);
  const [uniquesBroadcastersName, setUniqueBroadcastersName] = useState<string[]>([]);

  // Refresh artworks lists
  useEffect(() => {
    setDisplayedArtworks(props.profile.artworks?.filter(artwork => artwork.displayed === true));
    setNotDisplayedArtworks(props.profile.artworks?.filter(artwork => artwork.displayed !== true));
  }, [props.profile]);

  useEffect(() => {
    let additionnalJobsNames: string[] = [];
    let additionnalJobs: Job[] = [];

    props.profile.artworks.map((artwork: Artwork) => {
      additionnalJobs = additionnalJobs.concat(artwork.jobs);
    });
    // Remove Profile Job
    additionnalJobs = additionnalJobs.filter(job => job.id !== props.profile.job.id);

    additionnalJobs.sort((a, b) => a.id - b.id);
    additionnalJobs.map((job: Job) => { additionnalJobsNames.push(findTranslationOrDefault(job, locale)) });
    setUniqueAdditionalJobs([...new Set(additionnalJobsNames)]);
  }, [props.profile, locale]);

  useEffect(() => {
    let broadcastersNames: string[] = [];
    let broadcasters: Broadcaster[] = [];

    props.profile.artworks.map((artwork: Artwork) => {
      artwork.film?.broadcasters.map((broadcaster: Broadcaster) => {
        broadcasters.push(broadcaster)
      })
    })
    broadcasters.sort((a, b) => a.id - b.id);
    broadcasters.map((broadcaster: Broadcaster) => { broadcastersNames.push(broadcaster.name) });
    setUniqueBroadcastersName([...new Set(broadcastersNames)]);
  }, [props.profile]);

  useEffect(() => {
    let festivalsNames: string[] = [];
    let festivals: Festival[] = [];

    props.profile.artworks.map((artwork: Artwork) => {
      artwork.film?.selections.map(selection => {
        festivals.push(selection.festival);
      })
    })
    festivals.sort((a, b) => a.id - b.id);
    festivals.map((festival: Festival) => { festivalsNames.push(findTranslationOrDefault(festival, locale)) });
    setUniqueFestivalsName([...new Set(festivalsNames)]);
  }, [props.profile, locale]);

  const printFestivalsOrBroadcasters = ((infos: string[]) => {
    let listOfThreeMax = infos.slice(0, 3);

    return (
      <div>
        {listOfThreeMax.map((festival, i) =>
          <React.Fragment key={i}>
            <span>{festival}</span>
            {(listOfThreeMax.length !== i + 1 ? ', ' : '')}
          </React.Fragment>
        )
        }
        {infos.length > 3 &&
          <span>{t('page.profile.and_more', { count: infos.length - 3 })}</span>
        }
      </div>
    )
  })

  return (
    <div className={styles.mainContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.headerContainer}>
          <div className={styles.avatarContainer}>
            {
              props.profile.avatarUrl &&
              <LazyLoadImage
                src={props.profile.avatarUrl}
                width={remSizeToPixelNumber(styles.avatarSize)}
                height={remSizeToPixelNumber(styles.avatarSize)}
                className={styles.avatar}
                alt={t('alt.avatar', {
                  name: props.profile.name,
                  job: findTranslationOrDefault(props.profile.job, locale),
                  city: findTranslationOrDefault(props.profile.location.city, locale)
                })}
                effect='blur'
                onError={onErrorImage}
              />
            }
          </div>
          <div className={classNames(styles.nameAndBadgeContainer)}>
            <h1 className={styles.name}>{props.profile.name}</h1>
            {
              props.profile.identityVerfied &&
              <div className={classNames(styles.badgeCheckIconContainer)}>
                <BadgeCheckIcon className={styles.icon} />
              </div>
            }
          </div>
          <div className={styles.locationContainer}>
            <h2 className={styles.location}>
              {`${findTranslationOrDefault(props.profile.location.city, locale)}, ${findTranslationOrDefault(props.profile.location.country, locale)}`}
            </h2>
          </div>
          <h2 className={styles.job}>{findTranslationOrDefault(props.profile.job, locale)}</h2>
          {
            props.profile?.bios[0]?.short &&
            <div className={styles.bioShortContainer}>
              <p className={styles.bio}>
                <BioToReactComponents input={props.profile.bios[0].short} />
              </p>
            </div>
          }
          {
            props.profile.agents?.length > 0 &&
            <div className={styles.agentAssoContainer}>
              <h3>
                <span className={styles.agentAssoIntro}>
                  {t('page.profile.agent_intro')}
                </span> {props.profile.agents.map((agent, i) => {
                  return (
                    <React.Fragment key={i}>
                      <span
                        className={styles.agentAsso}
                        onClick={() => {
                          filterStore.resetFilters();
                          filterStore.setFilter(
                            router,
                            FilterType.AGENT,
                            { id: agent.id },
                            agent.name
                          )
                        }}
                      >
                        {agent.name}
                      </span>
                      {(props.profile.agents.length !== i + 1 ? ', ' : '')}
                    </React.Fragment>
                  )
                })}
              </h3>
            </div>
          }
          {
            props.profile.associations?.length > 0 &&
            <div className={styles.agentAssoContainer}>
              <h3>
                <span className={styles.agentAssoIntro}>{t('page.profile.asso_intro')}
                </span> {props.profile.associations.map((association, i) => {
                  return (
                    <React.Fragment key={i}>
                      <span
                        className={styles.agentAsso}
                        onClick={() => {
                          filterStore.resetFilters();
                          filterStore.setFilter(
                            router,
                            FilterType.ASSOCIATION,
                            { id: association.id },
                            association.name
                          )
                        }}
                      >
                        {association.name}
                      </span>
                      {(props.profile.associations.length !== i + 1 ? ', ' : '')}
                    </React.Fragment>
                  )
                })}
              </h3>
            </div>
          }
        </div>
        <div className={styles.buttonsContainer}>
          <div className={styles.iconButtonContainer}>
            <CardButtonReport
              profileId={props.profile.id}
              profileName={props.profile.name}
            />
          </div>
          <CardButtonContact
            profileId={props.profile.id}
            profileName={props.profile.name}
            buttonAspect={ButtonAspect.PERSO_PAGE}
          />
          <div className={styles.iconButtonContainer}>
            <CardButtonOnFire
              profileId={props.profile.id}
              profileName={props.profile.name}
            />
          </div>
          <CardButtonShare
            profileId={props.profile.id}
            profileName={props.profile.name}
            profileJob={findTranslationOrDefault(props.profile.job, locale)}
            profilePath={props.profile.path}
            buttonAspect={ButtonAspect.PERSO_PAGE}
            canShare={hasPortfolioPublic(props.profile)}
          />
          <div className={styles.iconButtonContainer}>
            <CardButtonShortlist
              profileId={props.profile.id}
              profileName={props.profile.name}
              profileJob={findTranslationOrDefault(props.profile.job, locale)}
            />
          </div>

        </div>
        <div className={styles.infosContainer}>
          {
            props.profile?.bios[0]?.long &&
            <p className={classNames(styles.bio)}>
              <BioToReactComponents input={props.profile.bios[0].long} />
            </p>
          }
        </div>
        {
          uniquesFestivalsName.length > 0 &&
          <div className={styles.festivalBroadcasterContainer}>
            <h3>
              <span className={styles.festivalBroadcasterIntro}>{t('page.profile.selected_films')}</span>
              {printFestivalsOrBroadcasters(uniquesFestivalsName)}
            </h3>
          </div>
        }
        {
          uniquesBroadcastersName.length > 0 &&
          <div className={styles.festivalBroadcasterContainer}>
            <h3>
              <span className={styles.festivalBroadcasterIntro}>{t('page.profile.broadcasters')}</span>
              {printFestivalsOrBroadcasters(uniquesBroadcastersName)}
            </h3>
          </div>
        }
        {
          uniqueAdditionnalJobs?.length > 0 &&
          <div className={styles.additionnalJobsContainer}>
            <h3>
              <span className={styles.additionnalIntroJobs}>
                {t('page.profile.additionnal_jobs_intro')}
              </span>
              <br />
              {uniqueAdditionnalJobs.map((jobName, i) => {
                return (
                  <React.Fragment key={i}>
                    <span className={styles.additionnalJobs}>
                      {jobName}
                    </span>
                    {(uniqueAdditionnalJobs.length !== i + 1 ? ', ' : '')}
                  </React.Fragment>
                )
              })}
            </h3>
          </div>
        }
        <div className={styles.artworksMainContainer}>
          <p className={styles.title}>{t('page.profile.artwork_selection')}</p>
          <div className={styles.artworksContainer}>
            {
              displayedArtworks?.map((artwork: Artwork, i) =>
                <div className={styles.artworkContainer} key={i}>
                  <CardArtwork
                    artwork={artworkToCardArtwork(artwork, locale, props.profile)}
                    artworkSize={ArtworkSize.MEDIUM_SMALL}
                    preview
                  />
                </div>
              )
            }
          </div>

          {
            notDisplayedArtworks?.length > 0 &&
            <React.Fragment>
              <p className={styles.title}>{t('page.profile.all_artworks')}</p>
              <div className={styles.artworksContainer}>
                {
                  notDisplayedArtworks?.map((artwork: Artwork, i) =>
                    <div className={styles.artworkContainer} key={i}>
                      <CardArtwork
                        artwork={artworkToCardArtwork(artwork, locale, props.profile)}
                        artworkSize={ArtworkSize.MEDIUM_SMALL}
                        preview
                      />
                    </div>
                  )
                }
              </div>
            </React.Fragment>
          }
        </div>
      </div>
    </div >
  );
}
