import styles from './LandingPage.module.scss'
import { BigWhiteIcon } from '@components/Core/Icon';

export const LandingPage = () => {

  return (
    <section className={`hero is-fullheight is-unselectable ${styles.background}`}>
      <div className={`hero-head ${styles.heroHead}`}>
        <div className='container is-fluid'>
          <p className='has-text-white is-size-4 has-text-weight-bold'>Film <span className='has-text-warning'>•</span> Practices</p>
        </div>
      </div>
      <div className='hero-body'>
        <div className='container is-fluid'>
          <p className={'has-text-white  has-text-weight-bold ' + styles.text}>
            <span className='has-text-warning'>Bonjour.</span><br />
            Nous sommes des producteurs, acteurs, réalisateurs, chefs opérateurs, monteurs, scénaristes, décorateurs, étalonneurs, maquilleurs, festivals, guildes,
            associations, institutions publiques, ingénieurs du son, perchmen, costumiers, pilotes drône, directeurs de production, résidences d&apos;écritures, cadreurs,
            figurants, directeurs de casting, agents artistiques, comptables, fabricants de matériel, distributeurs, cascadeurs, assistants, photographes de plateaux,
            machinistes, investisseurs, reporters d&apos;image, électriciens, chargés de production, motion-designers, régisseurs, tapissiers, présentateurs, scriptes,
            doubleurs, mixeurs, compositeurs, concepteurs VFX, habilleurs, organismes de financement, peintres, dessinateurs de décors, et <span className='has-text-warning'>collectivement</span>,
            nous créons un nouvel outil de financement, de production et de distribution des films. <span className='has-text-warning'>Nous créons la 1ère galerie des professionnels du film.</span>
          </p>
        </div>
      </div >
      <div className={`hero-foot ${styles.heroFoot}`}>
        <div className='content has-text-centered'>
          <BigWhiteIcon iconName='fa-facebook-f' link='https://www.facebook.com/filmpractices/' />
          <BigWhiteIcon iconName='fa-twitter' link='https://twitter.com/home?status=https://film-practices.fr/' />
          <BigWhiteIcon iconName='fa-linkedin-in' link='https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Ffilm-practices.fr%2F' />
          <p className='has-text-white is-size-7-touch'>
            Copyright © 2020 - film • practices - Tous droits réservés.
          </p>
        </div>
      </div>
    </section>
  );
}

export default LandingPage;
