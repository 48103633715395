import React, { useContext, useEffect } from "react";
import classNames from 'classnames';
import { observer } from "mobx-react-lite";

import styles from './Navigation.module.scss';
import { getComponent } from '../Layout';
import { NavigationContext } from ".";

export const WithNavigation = observer(() => {

  const store = useContext(NavigationContext);

  useEffect(() => {
    if (!store.isPopped) {
      window.scroll(0, store.scrollY);
    }
    else {
      window.scroll(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.popped.length]);

  return (
    <React.Fragment>
      <div className={classNames({ [styles.hidden]: store.isPopped })}>
        {getComponent(store.active)}
      </div>
      {store.isPopped && store.popped.slice(-1)[0]?.element}
    </React.Fragment >
  )
});
