import React from 'react';
import { useRouter } from 'next/router';
import styles from './EditArtwork.module.scss';
import classNames from 'classnames';
import { useTranslate } from '@hooks/use-translate';
import { Label, EditArtworkStore } from '@components/.';
import { StoresBindings } from '@container/.';
import { useInjection } from '@hooks/.';
import { ModalKey, ModalStore, PopupStore } from '@stores/.';
import { observer } from 'mobx-react-lite';
import { festivalAwardToStr } from '@utils/.';
import { FestivalAward } from '@oward/openapi';
import { SelectionStatus } from '@oward/common-enums';
import { OwardButton } from '@components/Core';
import { findTranslationOrDefault } from '@oward/common-utils';

interface EditArtworkSelectionsProps {
  store: EditArtworkStore;
}

export const EditArtworkSelections: React.FC<EditArtworkSelectionsProps> = observer(props => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  return (
    <div className={styles.selectionsContainer}>
      <Label name={t('edit_film.selections.title')} />
      {
        props.store.artwork.film.selections?.length > 0 ?
          props.store.artwork.film.selections.map((selection, index) => {
            let awardString = festivalAwardToStr(selection.award as FestivalAward, locale);
            return (
              <div
                className={styles.selectionContainer}
                onClick={() => {
                  modalStore.setEditArtworkLocalStore(props.store);
                  modalStore.openModalNewStack(router, ModalKey.MODIFY_SELECTION, selection.id);
                }}
                key={index}
              >
                <div className={styles.infoContainer}>
                  <p className={styles.festival}>
                    {findTranslationOrDefault(selection.festival, locale)}
                  </p>
                  <p className={styles.award}>
                    {
                      selection.status !== SelectionStatus.SELECTED &&
                      <span className={classNames('icon', styles.trophyIcon)} >
                        <i className={classNames('fas fa-trophy')}></i>
                      </span>
                    }
                    {`${awardString}${awardString ? ', ' : ''} ${selection.year}`}
                  </p>
                </div>
                <div className={styles.modifyIconContainer}>
                  <div
                    // On click dealt at selection level
                    className={styles.modifyIcon}
                  >
                    <span className={classNames('icon')}>
                      <i className={classNames('fas fa-ellipsis-h')}></i>
                    </span>
                  </div>
                </div>
              </div>
            );
          })
          :
          <p>{t('edit_film.selections.none', { name: props.store.artwork.film.name })}</p>
      }
      <div className={styles.buttonContainer}>
        <OwardButton
          name={t('edit_film.selections.add_button')}
          onClick={() => {
            modalStore.setEditArtworkLocalStore(props.store);
            modalStore.openModalNewStack(router, ModalKey.NEW_SELECTION, 1);
          }}
        />
      </div>
    </div>
  )
})
