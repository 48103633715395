import React, { useEffect, useRef } from 'react';
import styles from './PopUp.module.scss';
import { useInjection, useTranslate } from '@hooks/.';
import { PopUpType, PopupStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { OwardButton } from '../Button';
import { observer } from 'mobx-react-lite';

export const PopUp: React.FC = observer(() => {
  const { t } = useTranslate();
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);

  const renderPopUp = () => {
    switch (popupStore.popUpType) {
      case PopUpType.INFORMATION:
        return (
          <div className={styles.popUpOverlay}>
            <div className={styles.popUpContainer} onClick={undefined}>
              {
                popupStore.popUpTitle &&
                <p className={styles.title}>{popupStore.popUpTitle}</p>
              }
              <p className={styles.textInformation}>{popupStore.popUpMsg}</p>
              <div className={styles.buttonContainer}>

                <OwardButton
                  name={t('global.ok')}
                  onClick={() => {
                    if (typeof (popupStore.popUpCallback) === 'function') {
                      popupStore.popUpCallback()
                    }
                    popupStore.closePopUp();
                  }}
                />
              </div>
            </div>
          </div>
        )
      case PopUpType.CONFIRMATION:
        return (
          <div className={styles.popUpOverlay} onClick={() => { popupStore.closePopUp() }}>
            <div className={styles.popUpContainer} onClick={undefined}>
              {
                popupStore.popUpTitle &&
                <p className={styles.title}>{popupStore.popUpTitle}</p>
              }
              <p className={styles.text}>{popupStore.popUpMsg}</p>
              <div className={styles.buttonContainer}>
                <OwardButton name={t('global.cancel')} outlined onClick={() => { popupStore.closePopUp() }} />
                <OwardButton
                  name={t('global.confirm')}
                  onClick={() => {
                    if (typeof (popupStore.popUpCallback) === 'function') {
                      popupStore.popUpCallback()
                    }
                    popupStore.closePopUp();
                  }}
                />
              </div>
            </div>
          </div>
        );
      case PopUpType.GENERIC:
        return (
          <div className={styles.popUpOverlay} onClick={() => { popupStore.closePopUp() }}>
            <div className={styles.popUpContainer} onClick={undefined}>
              {popupStore.popUpContent}
            </div>
          </div>
        );
    }
  }

  return (
    <React.Fragment>
      {
        popupStore.popUpOpened &&
        <>
          {renderPopUp()}
        </>
      }
    </React.Fragment >
  )
})
