import React, { useEffect, ReactElement } from 'react';
import styles from './PaginatedList.module.scss';
import { useLocalObservable, observer } from 'mobx-react-lite';
import { EditProps } from '../../types';
import { PaginationControl } from './PaginationControl';
import { EditButton } from './EditButton';
import { OwardButton, OwardInput, OwardLoader, OwardSelect, SelectOption, ToastError } from '@components/Core';
import { useTranslate } from '@hooks/.';
import { strToPath } from '@oward/common-utils';
import { toast } from 'react-toastify';
import moment from 'moment';
import { ButtonAspect, CardButtonContact } from '@components/Card/CardButtons';
import { Profile } from '@oward/openapi';
import { action, runInAction } from 'mobx';

export enum PaginatedListFilterType {
  ON_OFF = 'on_off',
  INPUT = 'input',
  DROPDOWN = 'dropdown',
}

export interface PaginatedListFilter<T> {
  name: string;
  type: PaginatedListFilterType;
  options?: SelectOption[];
  filterFunction: (item: T, value?: any) => boolean;
}

export interface PaginatedListKey<T> {
  label: string;
  value: (item: T) => string | number;
  sortValue?: (item: T) => number;
}

export interface PaginatedListProps<T> {
  items: T[];
  keys: PaginatedListKey<T>[];
  size: number;
  filterItemStrings: (item: T) => string[];
  filterComponents?: PaginatedListFilter<T>[];
  editComponent?: React.FC<EditProps<T>>;
  editLabel?: string;
  fetchOne?: (item: T) => Promise<T>;
  reloadList?: () => Promise<void>;
  addContactButton?: boolean;
  loading?: boolean;
  error?: boolean;
}

export const PaginatedList = observer(<T extends unknown>(props: PaginatedListProps<T>) => {
  const { t } = useTranslate();

  const store = useLocalObservable(() => ({
    items: [] as T[],
    page: 1,
    input: '',
    filters: props.filterComponents?.map(filterComponent => {
      return {
        value: undefined,
        name: filterComponent.name,
      }
    }
    ),
    setFilter: (name: string, value: any) => { store.filters.find(filter => filter.name === name).value = value },
    setPage: action((page: number) => {
      store.page = page;
    })
  }));

  useEffect(() => {
    runInAction(() => {
      store.items = props.items;
    });
    filter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.items]);

  const onInputChange = action((value: string) => {
    store.input = value;
    filter();
  });

  const filter = action(() => {
    store.page = 1;

    // Filter from text input
    if (store.input.length > 0) {
      store.items = props.items.filter(item => {
        let includes: boolean = false;
        props.filterItemStrings(item).map(filterItemString => {
          if (filterItemString?.includes(strToPath(store.input))) {
            includes = true;
          }
        })
        return includes;
      });
    }
    else {
      store.items = props.items;
    }

    // Filter from other filters
    if (props.filterComponents) {
      props.filterComponents.map(filterComponent => {
        if (isFilterActive(filterComponent.name)) {
          store.items = store.items.filter(item =>
            filterComponent.filterFunction(item, store.filters.find(filter => filter.name === filterComponent.name).value)
          );
        }
      })
    }
  });

  const isFilterActive = (name: string): boolean => {
    return store.filters.find(activeFilter => activeFilter.name === name).value ?? false;
  }

  const reloadList = async () => {
    await props.reloadList();
  }

  const downloadAsCsv = async () => {
    if (store.items.length < 1) {
      toast.dark(<ToastError msg={'No data to export...!'} />);
      return;
    }

    let csvContent = 'data:text/csv;charset=utf-8,' +
      props.keys.map(key => key.label).join(';') + '\n' + // Header line
      store.items.map(item => {
        return props.keys.map(key => {
          // Clean innertext to remove multiple spaces and jumpline (break csv)
          let cellTxt = key.value(item)?.toString().replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ');
          // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
          cellTxt?.replace(/"/g, '""');
          return cellTxt;
        }).join(';')
      }).join('\n');

    // Replacing '#' : See https://stackoverflow.com/questions/55968341/why-is-breaking-my-csv-html-anchor-download
    var encodedUri = encodeURI(csvContent).replaceAll('#', '%23');
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `oward_admin_export.csv`);
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  }

  const sortElements = action((key: PaginatedListKey<T>, a: T, b: T, asc: boolean): number => {
    store.page = 1;
    let sortValue: number;
    if (key.sortValue) {
      sortValue = key.sortValue(a) - key.sortValue(b);
    }
    else if (typeof key.value(a) === 'number') {
      sortValue = (key.value(a) as number) - (key.value(b) as number)
    }
    else if (typeof key.value(a) === 'string') {
      if (moment(key.value(a)).isValid()) {
        sortValue = (moment(key.value(a)).unix() - moment(key.value(b)).unix())
      } else {
        sortValue = (key.value(a) as string).localeCompare((key.value(b) as string));
      }
    }
    return asc ? sortValue : -sortValue;
  });

  const renderFilterComponent = (filterComponent: PaginatedListFilter<T>, index: number) => {
    switch (filterComponent.type) {
      case PaginatedListFilterType.ON_OFF:
        return <OwardButton
          name={filterComponent.name}
          transparentBlue
          isActive={isFilterActive(filterComponent.name)}
          onClick={() => {
            store.setFilter(filterComponent.name, !isFilterActive(filterComponent.name));
            filter();
          }}
          key={index}
        />
          ;
      case PaginatedListFilterType.INPUT:
        return <div className={styles.filterContainer} key={index}>
          <OwardInput
            placeholder={filterComponent.name}
            onChange={(value) => {
              store.setFilter(filterComponent.name, value);
              filter();
            }}
          />
        </div>;
      case PaginatedListFilterType.DROPDOWN:
        return <div className={styles.filterContainer} key={index}>
          <OwardSelect
            placeholder={filterComponent.name}
            options={filterComponent.options}
            onChange={(value) => {
              store.setFilter(filterComponent.name, value)
              filter();
            }}
            value={store.filters.find(filter => filter.name === filterComponent.name).value}
            isClearable
          />
        </div>
      default:
        return;
    }
  }

  return (
    <div className='card'>
      <header className={`card-header ${styles.headerContainer}`}>
        <div className={`${styles.filtersMainContainer}`}>
          {
            props.filterComponents?.length > 0 &&
            <React.Fragment>
              <div className={styles.filtersContainer}>
                <p>Filters&nbsp;: </p>
                {
                  props.filterComponents?.map((filterComponent: PaginatedListFilter<T>, i) => {
                    return renderFilterComponent(filterComponent, i);
                  })
                }
              </div>
              <hr className={styles.hr} />
            </React.Fragment>
          }
        </div>
        <div className={`${styles.inputAndLoadContainer}`}>
          <div className='card-header-title'>
            <OwardInput
              value={store.input}
              onChange={onInputChange}
              placeholder={t('global.search')}
              icon={'fas fa-search'}
            />
          </div>
          <React.Fragment>
            {
              props.reloadList &&
              <div className='card-header-icon'>
                <OwardButton
                  name='Reload List'
                  outlined
                  onClick={reloadList}
                />
              </div>
            }
          </React.Fragment>
          <OwardButton
            name='Download as CSV'
            outlined
            onClick={downloadAsCsv}
          />
        </div>
      </header>
      <div className='card-content'>
        {
          (props.loading || props.error) ?
            <div>
              {
                props.loading ?
                  <OwardLoader loading={true} positionStatic />
                  :
                  <p>{t('global.error')}</p>
              }
            </div>
            :
            <div className='table-container'>
              <table className='table is-fullwidth is-striped is-hoverable'>
                <thead>
                  <tr>{
                    props.keys.map((key, index) =>
                      <th key={index} >
                        <div className={styles.tableLabelContainer}>
                          <div className={styles.labelContainer}>
                            {key.label}
                          </div>
                          <div className={styles.sortMainContainer}>
                            <div className={styles.sortContainer} onClick={action(() => {
                              store.items = store.items.sort((a, b) => {
                                return sortElements(key, a, b, true);
                              })
                            })}>
                              <span className={`icon`}>
                                <i className="fas fa-chevron-up"></i>
                              </span>
                            </div>
                            <div className={styles.sortContainer} onClick={action(() => {
                              store.items = store.items.sort((a, b) => {
                                return sortElements(key, a, b, false);
                              })
                            })}>
                              <span className={`icon`}>
                                <i className="fas fa-chevron-down"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </th>
                    )}
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    store.items.map((item, idx) => {
                      if ((store.page - 1) * props.size <= idx && idx < store.page * props.size) {
                        return (
                          <tr key={idx} >{
                            props.keys.map((key, idx) =>
                              <td key={idx}>
                                {
                                  String(key.value(item)).includes('https://cdn')
                                    ?
                                    <img src={String(key.value(item))} style={{ width: '25%', height: '25%' }} alt={String(key.value(item))} />
                                    :
                                    key.value(item)
                                }
                              </td>                 //May be refractored in the future ? ; For avoiding Link under the picture
                            )}
                            {
                              props.addContactButton &&
                              <td>
                                <div style={{ height: '2.5rem', width: '2.5rem' }}>
                                  <CardButtonContact
                                    buttonAspect={ButtonAspect.ON_FIRE}
                                    profileId={(item as Profile)?.id}
                                    profileName={(item as Profile)?.name}
                                  />
                                </div>
                              </td>
                            }
                            {
                              props.editComponent &&
                              <td>
                                <EditButton
                                  item={item}
                                  notify={reloadList}
                                  component={props.editComponent}
                                  fetchOne={props.fetchOne}
                                  label={props.editLabel}
                                />
                              </td>
                            }
                          </tr>
                        );
                      }
                    })
                  }
                </tbody>
              </table>
            </div>
        }
      </div>
      <PaginationControl
        size={props.size}
        page={store.page}
        setPage={store.setPage}
        count={store.items.length}
      />
    </div>
  );
});
