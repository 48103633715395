import React from 'react';
import styles from './EditProfile.module.scss';
import { useInjection, useTranslate } from '@hooks/.';
import { PopupStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { EditProfilePannelProps, OwardButton, ToastError, Link, EditProfileStore, ProfileSubscriptionDetails } from '@components/.';
import { EditProfileHeader, EditProfileSaveOrCancel } from './EditProfileCommon';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import { PaymentController, ProfileController, send, SubscriptionController } from '@oward/openapi';
import { ERR_MSG_NOT_ACCEPTABLE, ERR_MSG_UNAUTHORIZED } from '@utils/constants';
import { toast } from 'react-toastify';
import { goToHomePageAndReload, hasActiveSubscription, oniOSWebview, premiumEndDateToString, premiumLevelToString } from '@utils/.';
import { PREMIUM_LEVEL, PROMO_CODE_REFERRAL_EXTENSION_MONTHS } from '@oward/common-utils';
import { runInAction } from 'mobx';
import moment from 'moment';

export const EditProfileParameter: React.FC<EditProfilePannelProps> = observer(props => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);

  return (
    <div className={styles.formMainContainer}>
      <div className={classNames(styles.contentContainer)}>
        <EditProfileHeader
          onGoBack={props.store.reset}
          title={t('edit_profile.' + props.store.activeCategory + '.title', { name: props.store.profile?.name })}
        />
        <form className={classNames(styles.formContentContainer)} >
          <div className={styles.subscriptionContainer}>
            <div className={styles.subscriptionsDetailsContainer}>
              <ProfileSubscriptionDetails profile={props.store.profile} withAmount />
              {
                props.store.profile.subscription &&
                <Link href={`/account/user/invoices`} as={`/account/user/invoices`} passHref>
                  {t('edit_profile.parameter.go_to_invoices')}
                </Link>
              }
            </div>
            {
              // On iOS App, all links to subscription stuff must be removed
              (!hasActiveSubscription(props.store.profile) && !oniOSWebview())
              &&
              <div className={styles.subscribeContainer}>
                <Link
                  href={`/subscriptions/[profileId]`}
                  as={`/subscriptions/${props.store.profile.id}`}
                  passHref
                >
                  <a className={styles.link}>
                    {
                      props.store.profile.premiumLevel > PREMIUM_LEVEL.FREE ?
                        t('edit_profile.parameter.change_subscription') :
                        t('edit_profile.parameter.subscribe')
                    }
                  </a>
                </Link>
              </div>
            }
          </div>
          {
            /*
            <hr />
            <ReferralPromoCode store={props.store} />
            */
          }
          <hr />
          {
            props.store.profile.subscription?.status === 'active' &&
            <div className={classNames(styles.deleteButtonContainer)}>
              <OwardButton
                name={t('edit_profile.parameter.cancel_subscription')}
                red
                confirmation={t(
                  'edit_profile.parameter.confirm_cancel_subscription',
                  { name: props.store.profile.name, endDate: moment(props.store.profile.premiumEndDate).locale(locale).format('DD MMMM yyyy') }
                )}
                onClick={async () => {
                  try {
                    await send(SubscriptionController.cancelStripeSubscription(props.store.profile.id));
                    popupStore.openInformationPopUp({
                      msg: t('edit_profile.parameter.cancel_subscription_ok', { name: props.store.profile.name }),
                      callback: () => { router.replace('/[lang]/account/profiles', `/${locale}/account/profiles`); }
                    });
                  } catch (err) {
                    if (err instanceof Error && err.message === ERR_MSG_UNAUTHORIZED) {
                      popupStore.openInformationPopUp({
                        msg: t('global.force_logout'),
                        callback: () => { goToHomePageAndReload() }
                      });
                    }
                    else if (err instanceof Error && err.message === ERR_MSG_NOT_ACCEPTABLE) {
                      toast.dark(<ToastError msg={t('edit_profile.parameter.subscription_not_found') + ' ' + t('global.error_contact')} />);
                    }
                    else {
                      toast.dark(<ToastError msg={t('global.error_retry') + ' ' + t('global.error_contact')} />);
                    }
                  }
                }}
              />
            </div>
          }
          <div className={classNames(styles.deleteButtonContainer)}>
            <OwardButton
              name={t('edit_profile.parameter.delete')}
              red
              confirmation={`${t(
                'edit_profile.parameter.confirm_delete',
                { name: props.store.profile.name })
                }${props.store.profile?.subscription?.status === 'active' ?
                  '\n\n' + t('edit_profile.parameter.confirm_delete_cancel_subscription') :
                  ''
                }`}
              onClick={async () => {
                try {
                  await send(ProfileController.deleteProfile(props.store.profile.id));
                  router.replace('/[lang]/account/profiles', `/${locale}/account/profiles`);
                } catch (err) {
                  if (err instanceof Error && err.message === ERR_MSG_UNAUTHORIZED) {
                    popupStore.openInformationPopUp({
                      msg: t('global.force_logout'),
                      callback: () => { goToHomePageAndReload() }
                    });
                  }
                  else {
                    toast.dark(<ToastError msg={t('global.error_retry')} />);
                  }
                }
              }}
            />
          </div>
        </form>
      </div>
      <form className={styles.formFooterContainer}>
        <EditProfileSaveOrCancel onCancel={props.store.reset} noSave />
      </form>
    </div >
  );
})

interface ReferralPromoCodeProps {
  store: EditProfileStore;
}

const ReferralPromoCode: React.FC<ReferralPromoCodeProps> = observer(props => {
  const { t, locale } = useTranslate();
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);

  return (
    <div className={classNames(styles.deleteButtonContainer)}>
      {
        props.store.profile.referralCode ?
          <React.Fragment>
            <p className={styles.promoCodeTitle}>{t('edit_profile.parameter.promo_code_title')}</p>
            <br />
            <p className={styles.promoCode}>{props.store.profile.referralCode.code}</p>
            <br />
            <p>{t('edit_profile.parameter.promo_code_description', { extension_months: PROMO_CODE_REFERRAL_EXTENSION_MONTHS })}</p>
          </React.Fragment>
          :
          <OwardButton
            name={t('edit_profile.parameter.generate_referral')}
            onClick={async () => {
              try {
                let referralCode = await send(PaymentController.promoCodeReferralGenerate(props.store.profile.id));
                runInAction(() => {
                  props.store.profile.referralCode = referralCode;
                })
              } catch (err) {
                if (err instanceof Error && err.message === ERR_MSG_UNAUTHORIZED) {
                  popupStore.openInformationPopUp({
                    msg: t('global.force_logout'),
                    callback: () => { goToHomePageAndReload() }
                  });
                }
                else {
                  toast.dark(<ToastError msg={t('global.error_retry')} />);
                }
              }
            }}
          />
      }
    </div>
  )
})
