import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import {
  Festival, FestivalController, LocationController, State, ValidationState
} from '@oward/openapi';
import { Divider, Section, SubmitOrCancel, NavigationContext } from '@components/admin';
import { EditProps } from '../types';
import {
  Heading,
  OwardFormInput,
  OwardFormSelect,
  OwardFormSelectLoad,
  OwardFormSelectLoadMulti,
  OwardFormSwitch,
  OwardLoader,
} from '@components/Core';
import { useTranslate } from '@hooks/.';
import { stateToEmoji, validationStateToEmoji } from '@oward/common-utils';
import * as Yup from 'yup';
import { FilmListForFestival } from '../Films';
import { AwardListForFestival } from '../Awards/AwardList';
import { flattenFromGrouped, groupByLocale } from '../utils';
import { locales } from '@locales/.';

const schema = (t: any) => {
  return (
    Yup.object().shape({
      name: Yup.string().required(),
      country: Yup.object().required(),
    }))
};

export const EditFestival = observer((props: EditProps<Festival>) => {
  const { t, locale } = useTranslate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigation = useContext(NavigationContext);

  const formik = useFormik({
    initialValues: {
      name: props.item.name,
      translations: groupByLocale(props.item.translations),
      validationState: props.item.validationState,
      state: props.item.state,
      categories: props.item.categories,
      city: props.item.city,
      country: props.item.country,
      url: props.item.url,
      needCheck: props.item.needCheck,
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      props.item.name = values.name;
      props.item.translations = flattenFromGrouped(values.translations);
      props.item.validationState = values.validationState;
      props.item.state = values.state;
      props.item.categories = values.categories;
      props.item.city = values.city ? values.city : null;
      props.item.country = values.country;
      props.item.url = values.url;
      props.item.needCheck = values.needCheck;
      try {
        setLoading(true);
        await FestivalController.saveOne(props.item);
        if (props.notify) { props.notify(props.item) };
        setError(false);
        navigation.unpop();
      } catch (err) {
        setError(true);
      }
      finally {
        setLoading(false);
      }
    }
  });

  return (
    <React.Fragment>
      <Section
        title={`Edit '${formik.initialValues.name}' festival`}
        subtitle={`Change the festival properties`}
        childrenFullWidth
      >
        <form className='box' style={{ position: 'relative' }} onSubmit={formik.handleSubmit} >
          <OwardLoader loading={loading} />
          <Heading>GENERAL</Heading>
          <OwardFormInput
            id='name'
            type='name'
            label='Name *'
            icon='fas fa-signature'
            formik={formik}
          />
          {
            locales.map(locale =>
              <React.Fragment key={locale}>
                <OwardFormInput
                  id={`translations.${locale}.name`}
                  label={`Name ${locale}`}
                  icon='fas fa-signature'
                  value={formik.values.translations[locale]?.name}
                  error={formik.errors.translations && formik.errors.translations[locale]?.name}
                  formik={formik}
                />
              </React.Fragment>
            )
          }
          <OwardFormSelectLoadMulti
            id='categories'
            label='Categories'
            fetchFunction={FestivalController.findCategories()}
            placeholder={'Click to choose from list'}
            formik={formik}
          />
          <OwardFormSelectLoad
            id='city'
            label='City'
            isClearable
            fetchFunction={LocationController.findCities()}
            formik={formik}
          />
          <OwardFormSelectLoad
            id='country'
            label='Country *'
            fetchFunction={LocationController.findCountries()}
            formik={formik}
          />
          <OwardFormInput
            id='url'
            type='url'
            label='URL'
            icon='fas fa-window-maximize'
            formik={formik}
          />
          <Divider />
          <Heading>ADMIN</Heading>
          {
            props.item.createdBy ?
              <p>Festival created by user: {props.item.createdBy.email}</p>
              :
              <p>Festival created by Oward</p>
          }
          <div style={{ height: '1rem' }} />
          <OwardFormSelect
            id='state'
            label='State *'
            options={Object.values(State).map((state) => {
              return ({ value: state, label: state.concat(' ', stateToEmoji(state)) });
            })}
            formik={formik}
          />
          <OwardFormSelect
            id='validationState'
            label='Validation State *'
            options={Object.values(ValidationState).map((validationState) => {
              return ({ value: validationState, label: validationState.concat(' ', validationStateToEmoji(validationState)) });
            })}
            formik={formik}
          />
          <OwardFormSwitch
            id='needCheck'
            label={'Need Check'}
            formik={formik}
          />
          <Divider />
          <SubmitOrCancel error={error} noCancel={props.noCancel} />
        </form >
        <AwardListForFestival festivalId={props.item.id} />
        <FilmListForFestival festivalId={props.item.id} />
        <div style={{ height: '200px' }} />
      </Section >
    </React.Fragment>
  )
});
