import React, { useEffect, useState } from 'react';
import styles from './Subscriptions&PricingPage.module.scss';
import { useInjection, useProfilesForUser, useTranslate } from '@hooks/.';
import { LayoutPage, Link, OwardButton, OwardLinkButton, SubscriptionCards, SubscriptionClientRecommendations, SubscriptionFooter, SubscriptionPaymentMethods, SubscriptionsBottomCta, SubscriptionsFaq, SubscriptionVideoEmbed } from '@components/.';
import { Profile, ProfileSubscriptionsPrice, State, UserController } from '@oward/openapi';
import { observer } from 'mobx-react-lite';
import { Divider } from '../Admin/AdminComponents';
import { ModalKey, ModalStore, UserStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { useRouter } from 'next/router';
import { RecurringInterval } from '@oward/common-utils';
import { DEFAULT_PRICING_INTERVAL } from '@utils/constants';

interface PricingPageProps {
  prices: ProfileSubscriptionsPrice[];
}

export const PricingPage: React.FC<PricingPageProps> = observer(props => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const { liveProfiles } = useProfilesForUser(locale);
  const [interval, setInterval] = useState<RecurringInterval>(DEFAULT_PRICING_INTERVAL);

  useEffect(() => {
    if (userStore.isLogged) {
      Promise.resolve(UserController.visitPricingPage());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderNetworkCta = () => {
    if (!userStore.currentUser) {
      return <Link href='/register' passHref>
        <OwardLinkButton
          outlined
          name={t('pricing.cta.expose_for_free')}
        />
      </Link>;
    }
    else if (!liveProfiles || liveProfiles?.length === 0) {
      return <OwardButton
        outlined
        name={t('pricing.cta.expose_for_free')}
        onClick={() => modalStore.openModalNewStack(router, ModalKey.NEW_PROFILE, 1)}
      />;
    }
    else if (liveProfiles.length === 1) {
      return <Link href={`/account/profile/[profileId]`} as={`/account/profile/${liveProfiles[0]?.id}`} passHref>
        <OwardLinkButton
          outlined
          name={t('pricing.cta.go_to_profile')}
        />
      </Link>;
    }
    else {
      return <Link href='/account/profiles' passHref>
        <OwardLinkButton
          outlined
          name={t('pricing.cta.select_a_profile')}
        />
      </Link>;
    }
  }

  const renderMainCta = () => {
    if (!userStore.currentUser) {
      return <Link href='/register' passHref>
        <OwardLinkButton
          name={t('pricing.cta.start_for_free')}
        />
      </Link>;
    }
    else if (!liveProfiles || liveProfiles?.length === 0) {
      return <OwardButton
        name={t('pricing.cta.start_for_free')}
        onClick={() => modalStore.openModalNewStack(router, ModalKey.NEW_PROFILE, 1)}
      />;
    }
    else if (liveProfiles.length === 1) {
      return <Link href={`/account/profile/[profileId]`} as={`/account/profile/${liveProfiles[0]?.id}`} passHref>
        <OwardLinkButton
          name={t('pricing.cta.go_to_profile')}
        />
      </Link>;
    }
    else {
      return <Link href='/account/profiles' passHref>
        <OwardLinkButton
          name={t('pricing.cta.select_a_profile')}
        />
      </Link>;
    }
  }

  return (
    <LayoutPage title={t(`pricing.title`)} noPadding>
      <div className={styles.headerContainer}>
        <div className={`${styles.subtitleContainer}`}>
          <p className={`${styles.subtitle}`}>{t('pricing.subtitle')}</p>
        </div>
        {renderMainCta()}
      </div>
      <SubscriptionCards
        prices={props.prices}
        ctaNetwork={renderNetworkCta()}
        ctaPortfolio={renderMainCta()}
        interval={interval}
        setInterval={setInterval}
      />
      <SubscriptionPaymentMethods />
      <div className={styles.mainCtaContainer}>
        {renderMainCta()}
      </div>
      <SubscriptionVideoEmbed />
      <SubscriptionsFaq />
      <SubscriptionClientRecommendations />
      <SubscriptionsBottomCta cta={renderMainCta()} />
      <SubscriptionFooter />
    </LayoutPage>
  );
});

