import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import styles from './PageLoader.module.scss';
import { LayoutStore } from "@stores/layout-store";
import { StoresBindings } from "@container/keys";
import { useInjection } from "@hooks/use-injection";
import { observer } from "mobx-react-lite";
import Loader from 'react-loader-spinner';


export const PageLoader = observer(() => {
  const router = useRouter();
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);

  useEffect(() => {
    const handleStart = (url: string) => layoutStore.setPageLoading(true);
    const handleComplete = (url: string) => layoutStore.setPageLoading(false);

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleComplete)
    router.events.on('routeChangeError', handleComplete)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleComplete)
      router.events.off('routeChangeError', handleComplete)
    }
  })

  return (
    layoutStore.pageLoading && (
      <div className={styles.mainContainer}>
        <div className={`${styles.loaderContainer}`}>
          <Loader
            className={`${styles.loader}`}
            type='Grid'
            color={'white'}
            height={80}
            width={80}
          />
        </div>
      </div>
    ));
})

