import React, { useContext, useState } from "react";
import { Heading, OwardFormInput } from "@components/Core";
import { LocationCountry, LocationController, send } from "@oward/openapi";
import { observer } from "mobx-react-lite";
import { Divider, NavigationContext, Section, SubmitOrCancel } from "../AdminComponents";
import * as Yup from 'yup';
import { EditProps } from "../types";
import { useFormik } from "formik";
import { useTranslate } from "@hooks/use-translate";
import { locales } from '@locales/.';
import { flattenFromGrouped, groupByLocale, yupTranslationsShapeNullable } from "../utils";


const schema = (t: any) => {
  return (
    Yup.object().shape({
      name: Yup.string().required(),
      translations: yupTranslationsShapeNullable,

    })
  )
};

export const EditCountry = observer((props: EditProps<LocationCountry>) => {
  const { t } = useTranslate();
  const [error, setError] = useState(false);
  const navigation = useContext(NavigationContext);

  const formik = useFormik({
    initialValues: {
      name: props.item.name,
      code: props.item.code,
      translations: groupByLocale(props.item.translations),
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      props.item.name = values.translations['fr'].name;
      props.item.code = values.code;
      props.item.translations = flattenFromGrouped(values.translations);
      try {
        await send(LocationController.saveCountry(props.item));
        if (props.notify) { props.notify(props.item) };
        setError(false);
        navigation.unpop();
      } catch {
        setError(true);
      }
    }
  })

  return (
    <Section
      title='Edit'
      subtitle='Change your entity properties'>
      <form className='box' onSubmit={formik.handleSubmit}>
        <Heading>General</Heading>
        <OwardFormInput
          id='code'
          label={'Country ISO code'}
          description={'See https://countrycode.org/'}
          formik={formik}
        />
        {
          locales.map(locale =>
            <React.Fragment key={locale}>
              <OwardFormInput
                id={`translations.${locale}.name`}
                label={`Name ${locale}*`}
                icon='fas fa-globe'
                value={formik.values.translations[locale]?.name}
                error={formik.errors.translations && formik.errors.translations[locale]?.name}
                formik={formik}
              />
            </React.Fragment>
          )
        }
        <Divider />
        <SubmitOrCancel error={error} noCancel={props.noCancel} />
      </form>
    </Section>
  )
});
