import React from 'react';
import styles from './AdminComponents/Layout/Layout.module.scss';

import { items } from './items';
import {
  Divider,
  Header,
  Sidebar,
  WithSidebar,
  Footer
} from '@components/admin';
import { WithNavigation } from './AdminComponents/Navigation/Navigation';

export const AdminIndexPage = () => {

  return (
    <React.Fragment>
      <WithSidebar>
        <Sidebar items={items} />
        <div className={styles.container}>
          <Header />
          <Divider />
          <WithNavigation />
          <Footer />
        </div>
      </WithSidebar>
    </React.Fragment >
  );
}
