import React from 'react';
import styles from './BillingInformationsPage.module.scss';
import { UserStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { useInjection, useTranslate } from '@hooks/.';
import { LayoutPageCentered, OwardButton, OwardLinkButton, ToastError, ToastSucess } from '@components/Core';
import { observer } from 'mobx-react-lite';
import { send, StripeCustomerDetails } from '@oward/openapi';
import { Link } from '@components/I18n';
import { StripeModifyCustomerDetailsForm } from '@components/Payment';
import { CreateOrMyProfilesButton } from '@components/NavBar';

interface BillingInformationsPageProps {
  customerDetails: StripeCustomerDetails;
}

export const BillingInformationsPage: React.FC<BillingInformationsPageProps> = observer(props => {
  const { t } = useTranslate();
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  return (
    <LayoutPageCentered
      title={t('account.user.billing_informations.title')}
      subtitle={userStore.currentUser?.email}
    >
      <div className={styles.mainContainer}>
        <StripeModifyCustomerDetailsForm {...props.customerDetails} />
      </div>
    </LayoutPageCentered >
  )
});

export const BillingInformationsNoCustomerPage: React.FC = observer(props => {
  const { t } = useTranslate();
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  return (
    <LayoutPageCentered
      title={t('account.user.billing_informations.title')}
      subtitle={userStore.currentUser?.email}
    >
      <div className={styles.mainContainer}>
        <div className={styles.noCustomerContainer}>
          <p>{t('account.user.billing_informations.start_by_subscribing')}</p>
        </div>
        <CreateOrMyProfilesButton />
      </div>
    </LayoutPageCentered >
  )
});
