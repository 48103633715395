import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BlockController, CardI } from '@oward/openapi';
import { FilterType } from '@oward/common-enums';
import BadgeCheckIcon from '../../assets/icons/badge_check.svg';
import NotVisibleRed from '../../assets/icons/not_visible_red.svg';

import humanizeDuration from 'humanize-duration';
import styles from './Card.module.scss';

import { CardStore, FilterStore, ModalKey, ModalStore } from '@stores/.'
import { useBlockedProfileIds, useInjection, useProfilesForUser, useTranslate } from '@hooks/.';
import { StoresBindings } from '@container/.';
import { Link } from '@components/I18n';
import {
  remSizeToPixelNumber,
  onErrorImage,
  onCardFullWidthDevice,
  getInitials,
  hasPortfolioPublic,
  SWR_BLOCKED_PROFILE_IDS,
} from '@utils/.';
import { bioParser, BioElementType, BioElement } from '@oward/common-utils';
import CardArtworks from './CardArtworks';
import { CardButtons } from './CardButtons';
import Linkify from 'linkify-react';
import { OwardButton, OwardLoader, ToastError, ToastSucess } from '..';
import { toast } from 'react-toastify';
import { mutate } from 'swr';

export interface CardProps {
  card: CardI;
  preview?: boolean; // Deactivate all buttons
  deactivateFilters?: boolean; // Deactivate all filter-related actions
  avatarPreview?: any;
}

export const Card = (props: CardProps) => {
  const { locale, t } = useTranslate();
  const router = useRouter();
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);
  const [bioOpened, bioOpen] = useState<boolean>(false);
  const { blockedProfileIds } = useBlockedProfileIds();

  const card: CardI = props.card;

  const toggleOpenBio = (): void => {
    let bioHiddenContainer: HTMLElement = document.getElementById('slideHiddenContainer' + card.id);

    if (bioHiddenContainer !== null) {
      if (bioOpened) {
        bioHiddenContainer.style.transform = 'translateY(-100%)';
      }
      else {
        bioHiddenContainer.style.transform = 'translateY(-0%)';
      }

      bioOpen(!bioOpened);
    }
  }



  useEffect(() => {
    // On Mobile, tooltip of update date must be on the left (or it'll be cropped)
    if (onCardFullWidthDevice()) {
      let updateText: HTMLElement = document.getElementById('card-update-text');
      if (updateText !== null) {
        updateText.classList.add('has-tooltip-left');
      }
    }
  }, []);

  return (
    <div
      className={`card-main-container ${styles.mainContainer}`}
    /*
     * DEACTIVTATED (Card ratio - Zoom)
     style={{ transform: !props.preview && `scale(${layoutStore.cardRatio})` }}
    */
    >
      {
        blockedProfileIds?.find(id => id === card.id) &&
        <BlockedProfileCover profileId={card.id} profileName={card.name} />
      }
      <div className={styles.contentContainer}>
        <div className={styles.headerContainer}>
          <div className={styles.locationContainer}>
            <p className={styles.location}>{card.city}, {card.country}</p>
          </div>
          <CardAvatar
            avatarUrl={props.avatarPreview ?? card.avatarUrl}
            profileId={card.id}
            profileName={card.name}
            profilePath={card.path}
            profileJobString={card.job}
            profileCityString={card.city}
            hasPortfolioPublic={card.hasPortfolioPublic}
            preview={props.preview}
          />
          <div className={classNames(styles.updateContainer)}>
            <p
              id='card-update-text'
              className={classNames(
                styles.update,
                'has-tooltip-arrow'
              )}
              data-tooltip={t('card.update_tooltip')}
            >
              {
                card.lastUpdateTimeMs > 5184000000
                  ? '> 2 ' + t('card.months')
                  : humanizeDuration(card.lastUpdateTimeMs, {
                    language: locale,
                    fallbacks: ['en'],
                    largest: 1,
                    units: ['d', 'h', 'm'],
                    round: true
                  })
              }
            </p>
          </div>
        </div>
        <div className={styles.subHeaderContainer}>
          <div className={classNames(styles.nameAndBadgeContainer)}>
            {
              card.hasPortfolioPublic ?
                <Link href={`/p/[profilePath]`} as={`/p/${card.path}`} passHref deactivate={props.preview} >
                  <p className={classNames(styles.name, props.preview && styles.deactivated)}>
                    {card.name}
                  </p>
                </Link>
                :
                <div
                  onClick={() => {
                    if (!props.preview) {
                      toast.dark(<ToastPortfolioNotAccessible profileId={props.card.id} profileName={props.card.name} />);
                    }
                  }}
                >
                  <p className={classNames(styles.name, styles.deactivated)}>
                    {card.name}
                  </p>
                </div>
            }
            {
              (!card.isExposed && !props.preview) &&
              <div className={classNames(styles.badgeIsNotExposedIconContainer)}>
                <NotVisibleRed className={styles.icon} />
              </div>
            }
            {
              card.identityVerfied &&
              <div className={classNames(styles.badgeCheckIconContainer)}>
                <BadgeCheckIcon className={styles.icon} />
              </div>
            }
          </div>

          <p className={styles.jobAsso}>
            <span className={classNames(styles.job, (props.preview || props.deactivateFilters) && styles.deactivated)} onClick={async () => {
              let jobPluralLabel: string = await filterStore.getJobPlurial(card.jobId);
              (!props.preview && !props.deactivateFilters) &&
                filterStore.setFilter(
                  router,
                  FilterType.JOB,
                  { id: card.jobId },
                  jobPluralLabel
                )
            }}>
              {card.job}
            </span>
            {
              card.agent &&
              <React.Fragment>
                {' - '}
                <span className={classNames(styles.job, (props.preview || props.deactivateFilters) && styles.deactivated)} onClick={() => {
                  (!props.preview && !props.deactivateFilters) &&
                    filterStore.setFilter(
                      router,
                      FilterType.AGENT,
                      { id: card.agentId },
                      card.agent
                    )
                }}>
                  {card.agent}
                </span>
              </React.Fragment>
            }
          </p>
        </div>
        <div className={styles.bioContainer}>
          <p className={styles.bio}>
            <BioToReactComponents input={card.bioShort} preview={(props.preview || props.deactivateFilters)} />
            {
              card.bioLong &&
              <React.Fragment>
                {' '}
                <span className={styles.knowMore} onClick={() => { toggleOpenBio() }}>
                  {bioOpened ? t('card.hide') : t('card.next')}
                </span>
              </React.Fragment>
            }
          </p>
        </div>
        <div className={styles.slideContainer}>
          <div id={'slideHiddenContainer' + card.id} className={styles.slideHiddenContainer}>
            <div className={styles.bioHiddenContainer}>
              <p className={styles.bioLong}>
                <BioToReactComponents input={card.bioLong} preview={props.preview} />
              </p>
            </div>
            <CardButtons card={card} preview={props.preview} />
          </div>
          <CardButtons card={card} preview={props.preview} />
          <div className={styles.artworksContainer}>
            {
              card.artworks != null &&
              <CardArtworks artworks={card.artworks} preview={props.preview} />
            }
          </div>
        </div>
      </div>
    </div >
  );
}


interface BioToReactComponentsProps {
  input: string;
  preview?: boolean;

}
export const BioToReactComponents: React.FC<BioToReactComponentsProps> = (props) => {
  const router = useRouter();
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);

  let elements: BioElement[] = bioParser(props.input);

  return (
    <React.Fragment>
      {
        elements.map((element: BioElement, index: number) => {
          switch (element.type) {
            case BioElementType.TEXT: {
              return <span key={index}>
                <Linkify options={{ className: styles.bioExternalLink, target: '_blank' }}>
                  {element.text}
                </Linkify>
              </span>;
            }
            case BioElementType.HASHTAG: {
              return (
                <span className={classNames(styles.bioLink, props.preview && styles.deactivated)} key={index} onClick={() => {
                  !props.preview &&
                    filterStore.setHashtagFilter(
                      router,
                      element.text.toLowerCase()
                    )
                }}>
                  #{element.text}
                </span>
              );
            }
            case BioElementType.PROFILE: {
              // TODO link to profile
              // let profileName: string = element.text;
              // return (
              //   <Link href={'/p'} as={'/p/' + profileName} key={index}>
              //     <a className={styles.bioLink}>
              //       @{element.text}
              //     </a>
              //   </Link>
              // );
              return <span key={index}>{element.text}</span>;
            }
          }
        })
      }
    </React.Fragment >
  );
}

interface CardAvatarProps {
  avatarUrl?: string;
  profilePath?: string;
  profileId: number;
  profileName: string;
  profileJobString?: string;
  profileCityString?: string;
  hasPortfolioPublic?: boolean;
  preview?: boolean;
}

export const CardAvatar: React.FC<CardAvatarProps> = props => {
  const { t } = useTranslate();
  const cardStore = useInjection<CardStore>(StoresBindings.CARD);

  const Avatar: React.FC = () => {
    return (
      <div className={styles.avatarContainer}>
        {
          props.avatarUrl ?
            <LazyLoadImage
              src={props.avatarUrl}
              width={remSizeToPixelNumber(styles.avatarSize)}
              height={remSizeToPixelNumber(styles.avatarSize)}
              className={classNames(
                styles.avatar,
                (props.preview || !props.profilePath || !props.hasPortfolioPublic) && styles.deactivated,
                props.hasPortfolioPublic ? styles.goldenBorder : styles.greyBorder,
              )}
              alt={t('alt.avatar', {
                name: props.profileName,
                job: props.profileJobString,
                city: props.profileCityString
              })}
              effect='blur'
              scrollPosition={cardStore.scrollPosition}
              onError={onErrorImage}
            />
            :
            <div className={classNames(
              styles.initialsContainer,
              (props.preview || !props.hasPortfolioPublic) && styles.deactivated,
              props.hasPortfolioPublic && styles.goldenBorder
            )} >
              <p className={styles.initials}>{getInitials(props.profileName)}</p>
            </div>
        }
      </div>
    );
  }

  return (
    <React.Fragment>
      {
        (props.profilePath && !props.preview) ?
          <>
            {
              props.hasPortfolioPublic ?
                <Link href={`/p/[profilePath]`} as={`/p/${props.profilePath}`} passHref deactivate={props.preview}>
                  <div> {/* div for Link to work */}
                    <Avatar />
                  </div>
                </Link>
                :
                <div onClick={() => { toast.dark(<ToastPortfolioNotAccessible profileId={props.profileId} profileName={props.profileName} />) }}>
                  <Avatar />
                </div>
            }
          </>
          :
          <Avatar />
      }
    </React.Fragment >
  );
}

interface ToastPortfolioNotAccessibleProps {
  profileId: number;
  profileName: string;
}

export const ToastPortfolioNotAccessible: React.FC<ToastPortfolioNotAccessibleProps> = props => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const { profiles } = useProfilesForUser(locale);

  return (
    <ToastError>
      <p>{t('profile.no_public_portfolio.text', { name: props.profileName })}</p>
      {
        profiles && profiles?.findIndex(profile => profile.id === props.profileId) !== -1 &&
        <p>
          <span
            className={styles.toasterLink}
            onClick={() => { router.push('/[lang]/account/profile/[profileId]', `/${locale}/account/profile/${props.profileId}`) }}
          >
            {t('card.toast_not_sharable.link')}
          </span>
          {t('card.toast_not_sharable.link_explanation')}
        </p>
      }
    </ToastError>
  );
}

interface BlockedProfileCoverProps {
  profileId: number;
  profileName: string;
}

export const BlockedProfileCover: React.FC<BlockedProfileCoverProps> = props => {
  const { t } = useTranslate();
  const [loading, setLoading] = useState(false);

  const unblockProfile = async () => {
    try {
      setLoading(true);
      await BlockController.unblockProfile(props.profileId);
      mutate(SWR_BLOCKED_PROFILE_IDS);
      toast.dark(<ToastSucess msg={t('card.block.unblock_validation_toast', { name: props.profileName })} />);
    } catch (err) {
      toast.dark(<ToastError msg={t('global.error_with_code', { code: err })} />);
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.blockMainContainer}>
      {
        loading &&
        <OwardLoader loading={loading} />
      }
      <div className={styles.contentContainer}>
        <p className={styles.text}>{t('card.block.profile_blocked', { name: props.profileName })}</p>
        <OwardButton
          name={t('card.block.unblock')}
          onClick={unblockProfile}
          outlined
          dark
        />
      </div>
    </div>
  );
}
