import { makeAutoObservable } from 'mobx';
import { PromoCode, send, UserController } from '@oward/openapi'
import { User, UserRole } from '@oward/openapi'
import { injectable } from 'inversify';
import ms from 'ms';
import { COOKIE_NAME_GDPR_PREFS } from '@utils/.';

export interface GdprPrefs {
  functional: boolean;
  analysis: boolean;
  youtube: boolean;
  vimeo: boolean;
  facebook: boolean;
  arte: boolean;
  soundcloud: boolean;
}

@injectable()
export class UserStore {
  constructor() {
    makeAutoObservable(this);
  }

  currentUser: User | undefined = undefined;
  promoCode: PromoCode | undefined = undefined;

  setPromoCode = (promoCode: PromoCode) => {
    this.promoCode = promoCode;
  }

  get isLogged() {
    return this.currentUser !== undefined;
  }

  get isAdmin() {
    return this.currentUser !== undefined
      && this.currentUser.role === UserRole.ADMIN;
  }

  setCurrentUser = (currentUser: User) => {
    this.currentUser = currentUser;
  }

  refreshCurrentUser = async () => {
    this.currentUser = await send(UserController.currentUser());
  }

  // Autologin : get a new AUTH_ACCESS token (when it expires), using the AUTH_REFRESH token
  // If the client don't have a valid AUTH_REFRESH token (i.e. no remeber-me option),
  // disconnect the user
  autologinTimer: NodeJS.Timeout;
  autologinTimerMs: number;

  autologin = async (callback: () => void) => {
    if (this.isLogged) {
      try {
        await UserController.autologin();
        this.currentUser = await send(UserController.currentUser());
      } catch (error) {
        if (error.status === 401) {
          this.currentUser = undefined;
          if (typeof (callback) === 'function') { callback() }
        }
      }
    }
  }

  setAutologinTimer = async (callback: () => void, ms?: number) => {
    clearInterval(this.autologinTimer);
    if (ms) {
      this.autologinTimerMs = ms;
    }
    this.autologinTimer = setInterval(
      () => { this.autologin(callback); },
      this.autologinTimerMs
    );
  }

  logout = async () => {
    await UserController.logout();
    this.currentUser = undefined;
  }

  gdprPrefs: GdprPrefs = undefined;

  setGdprPrefs = (prefs: GdprPrefs, withCookie?: boolean) => {
    this.gdprPrefs = prefs;
    if (withCookie) {
      var expires = new Date(Date.now() + ms('1 year')).toUTCString();
      document.cookie = `${COOKIE_NAME_GDPR_PREFS}=${JSON.stringify(prefs)};expires=${expires};path=/`;
    }
  }

  newsletterModalAlreadyShown: boolean = false;

  setNewsletterModalAlreadyShown = () => {
    this.newsletterModalAlreadyShown = true;
  }
}
