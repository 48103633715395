import React, { useState } from 'react';
import { useFormik } from 'formik';
import styles from './Register.module.scss';
import { useTranslate } from '@hooks/.';
import { useRouter } from 'next/router';
import { send, UserController } from '@oward/openapi';
import { OwardButton, OwardFormInput, OwardLoader, Link } from '@components/.';
import classNames from 'classnames';
import * as Yup from 'yup';
import { ERR_MSG_CONFLICT, PHONE_VALIDATION_REGEX } from '@utils/.';
import { OwardFormSwitch, TitlePage } from '@components/Core';
import { SubtitlePage } from '@components/Core/Subtitle';
import { useLocalObservable, observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';

enum FORM {
  MAIL = '1',
  PASSWORD = '2',
  INFOS = '3',
}

interface RegisterFormStore {
  currentForm: FORM,
  email: string,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  password: string,
  acceptDataPolicy: boolean,
  registerNewsletter: boolean,
  loading: boolean,
  setLoading: (loading: boolean) => void,
  error: string,
  setError: (txt: string) => void,
}

interface RegisterFormProps {
  isOnModal?: boolean;
}

export const RegisterForm: React.FC<RegisterFormProps> = observer(props => {
  const { t, locale } = useTranslate();

  const store: RegisterFormStore = useLocalObservable(() => ({
    currentForm: FORM.MAIL,
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    password: '',
    acceptDataPolicy: false,
    registerNewsletter: false,
    loading: false,
    setLoading(loading) {
      store.loading = loading;
    },
    error: '',
    setError(errorTxt) {
      store.error = errorTxt;
    },
  }));

  const renderForm = () => {
    switch (store.currentForm) {
      case FORM.MAIL:
        return <FormMail store={store} isOnModal={props.isOnModal} />;
      case FORM.PASSWORD:
        return <FormPassword store={store} />;
      case FORM.INFOS:
        return <FormInfos store={store} />;
      default:
        return <FormMail store={store} isOnModal={props.isOnModal} />;
    }
  }

  return (
    <div className={classNames(styles.mainContainer)}>
      {
        !props.isOnModal &&
        <TitlePage title={t('login.register_free')} />
      }
      <OwardLoader loading={store.loading} />
      {renderForm()}
      <div style={{ height: '3rem' }} />
    </div>
  );
});

export default RegisterForm;

interface FormProps {
  store: RegisterFormStore;
  isOnModal?: boolean;
}

const FormMail: React.FC<FormProps> = props => {
  const { t, locale } = useTranslate();

  const schema = (t: any) => {
    return (
      Yup.object().shape({
        email: Yup.string().email(t('forms.email.invalid')).required(t('forms.email.required')),
      }))
  };

  const formik = useFormik({
    initialValues: {
      email: props.store.email,
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      try {
        props.store.setLoading(true);
        await send(UserController.userAlreadyExists(values.email));
        runInAction(() => {
          props.store.error = '';
          props.store.email = values.email;
          props.store.currentForm = FORM.PASSWORD;
        });
      } catch (err) {
        if (err instanceof Error && err.message === ERR_MSG_CONFLICT) {
          props.store.setError(t('login.user_already_exists', { email: values.email }));
        }
        else {
          props.store.setError(t('global.error_retry'));
        }
      }
      finally {
        props.store.setLoading(false);
      }
    }
  });

  return (
    <React.Fragment>
      {
        !props.isOnModal &&
        <SubtitlePage subtitle={t('login.register_subtitle')} />
      }
      <form className={classNames(styles.formContainer)} onSubmit={formik.handleSubmit} >
        <OwardFormInput
          id='email'
          type='email'
          label={t('forms.email.label')}
          icon='far fa-envelope'
          placeholder={t('forms.email.placeholder')}
          formik={formik}
        />
        <OwardButton
          name={t('login.register_button')}
          submit
          fullWidth
        />
        {
          props.store.error &&
          <p className={styles.error}>
            {props.store.error}
          </p>
        }
        <hr />
        <div className={styles.alreadyRegisteredContainer}>
          <p>{t('login.already_registered')}</p>
          <Link href='/login'>
            <p className={styles.alreadyRegisteredConnect}>{t('login.already_registered_connect')}</p>
          </Link>
        </div>
      </form>
    </React.Fragment>
  );
}

const FormPassword: React.FC<FormProps> = props => {
  const { t, locale } = useTranslate();

  const schema = (t: any) => {
    return (
      Yup.object().shape({
        password: Yup
          .string()
          .required(t('forms.password.required'))
          .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{7,50}\S$/, t('forms.password.requirements')),
        confirmPassword: Yup
          .string()
          .test(
            'password-match',
            t('forms.password.no_match'),
            function (value) {
              return this.parent.password === value
            }
          ),
      }))
  };

  const formik = useFormik({
    initialValues: {
      password: props.store.password,
      confirmPassword: '',
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      runInAction(() => {
        props.store.password = values.password;
        props.store.currentForm = FORM.INFOS;
      });
    }
  });

  return (
    <React.Fragment>
      <div style={{ height: '1.5rem' }} />
      <form className={classNames(styles.formContainer)} onSubmit={formik.handleSubmit} >
        <OwardFormInput
          id='password'
          type='password'
          label={t('forms.password.label')}
          description={t('forms.password.requirements')}
          icon='fa fa-lock'
          placeholder='********'
          formik={formik}
        />
        <OwardFormInput
          id='confirmPassword'
          type='password'
          label={t('forms.password.label_confirm')}
          icon='fa fa-lock'
          placeholder='********'
          formik={formik}
        />
        <OwardButton
          name={t('login.register_button_password')}
          submit
          fullWidth
        />
      </form>
    </React.Fragment >
  );
}

const FormInfos: React.FC<FormProps> = props => {
  const { t, locale } = useTranslate();
  const router = useRouter();

  const schema = (t: any) => {
    return (
      Yup.object().shape({
        acceptDataPolicy: Yup.bool().oneOf([true], t('login.accept_data_policy_must_accept')),
        phoneNumber: Yup.string().matches(PHONE_VALIDATION_REGEX, t('forms.phone_number.invalid'))
      }))
  };

  const formik = useFormik({
    initialValues: {
      firstName: props.store.firstName,
      lastName: props.store.lastName,
      phoneNumber: props.store.phoneNumber,
      acceptDataPolicy: props.store.acceptDataPolicy,
      registerNewsletter: props.store.registerNewsletter,
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      try {
        props.store.setLoading(true);
        await send(UserController.register({
          email: props.store.email,
          password: props.store.password,
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          registerNewsletter: values.registerNewsletter,
        }, locale));
        router.replace('/[lang]/wait_email_verification', `/${locale}/wait_email_verification`);
        props.store.setError('');
      } catch (err) {
        if (err instanceof Error && err.message === ERR_MSG_CONFLICT) {
          props.store.setError(t('login.user_already_exists', { email: props.store.email }));
        }
        else {
          props.store.setError(t('global.error_retry'));
        }
      }
      finally {
        props.store.setLoading(false);
      }
    }
  });

  return (
    <React.Fragment>
      <div style={{ height: '1.5rem' }} />
      <form className={classNames(styles.formContainer)} onSubmit={formik.handleSubmit} >
        <OwardFormInput
          id='firstName'
          label={t('forms.first_name.label')}
          icon='fas fa-user'
          placeholder={t('forms.first_name.placeholder')}
          formik={formik}
        />
        <OwardFormInput
          id='lastName'
          label={t('forms.last_name.label')}
          icon='fas fa-user'
          placeholder={t('forms.last_name.placeholder')}
          formik={formik}
        />
        <OwardFormInput
          id='phoneNumber'
          label={t('forms.phone_number.label')}
          icon='fas fa-phone'
          placeholder={t('forms.phone_number.placeholder')}
          formik={formik}
        />
        <OwardFormSwitch
          id='acceptDataPolicy'
          label={t('login.accept_data_policy')}
          description={
            <p className={styles.description}>
              {t('login.data_policy_description1')}
              <a
                className={styles.link}
                href='https://discover.oward.co/#donnees'
                target='_blank'
                rel='noreferrer'
              >
                {t('login.data_policy_description2')}
              </a>
            </p>
          }
          formik={formik}
        />
        <OwardFormSwitch
          id='registerNewsletter'
          label={t('login.register_newsletter')}
          description={
            <p className={styles.description}>
              {t('login.register_newsletter_description')}
            </p>
          }
          formik={formik}
        />
        {
          props.store.error &&
          <p className={styles.error}>
            {props.store.error}
          </p>
        }
        <OwardButton
          name={t('login.register_button')}
          submit
          fullWidth
        />
      </form>
    </React.Fragment>
  );
}
