import React from 'react';
import { observer } from 'mobx-react-lite';
import { UserStore, ModalKey } from '@stores/.';
import { StoresBindings } from '@container/.';
import { useInjection, useTranslate } from '@hooks/.';
import {
  NeedConnectionModal, Modal, ModalTitle
} from '@components/.';

export const AddOnFireModal: React.FC = observer(() => {
  const { t } = useTranslate();
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  return (
    <React.Fragment>
      {
        userStore.isLogged ?
          <Modal
            modalKey={ModalKey.ADD_ON_FIRE}
          >
            {/* For now it's only possible to open AddOnFire Modal if we are not connected*/}
          </Modal >
          :
          <Modal modalKey={ModalKey.ADD_ON_FIRE}>
            <NeedConnectionModal headerMessage={t('on_fire.modal.need_connection')} />
          </Modal>
      }
    </React.Fragment>
  );
});
