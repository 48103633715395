"use strict";
/*
** OpenAPI Client - CONFIGURATION
*/
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.withBearer = exports.withCookie = exports.send = exports.OpenAPI = void 0;
__exportStar(require("./schemas"), exports);
__exportStar(require("./routes"), exports);
var OpenAPI;
(function (OpenAPI) {
    OpenAPI.options = { url: '/api' };
})(OpenAPI = exports.OpenAPI || (exports.OpenAPI = {}));
/**
 * Use this method to send a request
 * Then obtain the body of the response
 *
 * @param request The request you want to send.
 * @exemple `const body = await send(request);`
 *
 */
const send = (request) => request.then(response => response.body);
exports.send = send;
/**
 * Use this method to send a request with a given cookie
 * Then obtain the body of the response.
 *
 * @param cookie A string containing cookie's values.
 * @param request The request you want to send.
 * @exemple `const body = await withCookie(cookie)(request);`
 *
 */
const withCookie = (cookie, onlyBody = true) => (request) => onlyBody ?
    request
        .set('Cookie', cookie.split(';'))
        .then(response => response.body)
    :
        request
            .set('Cookie', cookie.split(';'))
            .then(response => response);
exports.withCookie = withCookie;
/**
 * Use this method to send a request with a given bearer.
 *
 * @param token A string representing a token.
 * @param request The request you want to send.
 * @exemple `const body = await withBearer(token)(request);`
 *
 */
const withBearer = (token) => (request) => request
    .set('Authorization', 'Bearer '.concat(token));
exports.withBearer = withBearer;
