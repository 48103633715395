import React, { useEffect, useState } from 'react';
import styles from './ManageGroupDiscussionModal.module.scss';
import stylesCommon from '../ModalCommon.module.scss';
import { StoresBindings } from '@container/.';
import { useInjection, useTranslate } from '@hooks/.';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { Modal, ModalConfirmOrCancelButtons, ModalTitle, NeedConnectionModal } from '..';
import { UserStore, MessageStore, ModalKey, ModalStore, PopupStore } from '@stores/.';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useRouter } from 'next/router';
import { ERR_MSG_NOT_ACCEPTABLE, ERR_MSG_NOT_FOUND, ERR_MSG_UNAUTHORIZED, SWR_DISCUSSIONS_FOR_PROFILE } from '@utils/constants';
import { goToHomePageAndReload } from '@utils/utils';
import { Discussion, MessageController, send } from '@oward/openapi';
import { DiscussionMemberRole, DiscussionType } from '@oward/common-enums';
import { OwardButton, OwardLoader, DiscussionRoleExplanation, DiscussionMemberViewRoleLine, OwardFormInput, OwardFormSwitch, ToastSucess, DiscussionMemberManageLine, NewMemberI, NewMembersList, GroupDiscussionProfileSearchAndList, NewMemberRoleLine, LeaveDiscussionButton, DiscussionMemberCount } from '@components/.';
import { toast } from 'react-toastify';
import { mutate } from 'swr';

enum PANEL {
  ADMIN_MENU = 'admin_menu',
  ADD_PROFILES_SEARCH = 'add_profiles_search',
  ADD_PROFILES_ROLES = 'add_profiles_roles',
  SEE_PROFILES_LIST = 'see_profiles_list',
  MANAGE_PROFILES_LIST = 'manage_profiles_list',
  PARAMETERS = 'parameters',
}

export interface ManageDiscussionStore {
  discussion: Discussion,
  refreshDiscussionInfos: (discussionId: number) => Promise<Discussion>,
  roleInDiscussion: DiscussionMemberRole,
  setRoleInDiscussion: (role: DiscussionMemberRole) => void,
  searchProfileInput: string,
  setSearchProfileInput: (value: string) => void,
  newMembers: NewMemberI[],
  addNewMember: (member: NewMemberI) => void,
  removeNewMember: (profileId: number) => void,
  setNewMemberRole: (profileId: number, role: DiscussionMemberRole) => void,
  currentPanel: PANEL,
  setCurrentPanel: (panel: PANEL) => void,
  loading: boolean,
  setLoading: (loading: boolean) => void,
  error: string,
  setError: (txt: string) => void,
}

export const ManageGroupDiscussionModal: React.FC = observer(() => {
  const { t } = useTranslate();
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);
  const messageStore = useInjection<MessageStore>(StoresBindings.MESSAGE);
  const [getDiscussionLoading, setGetDiscussionLoading] = useState<boolean>(false);
  const [getDiscussionError, setGetDiscussionError] = useState<string>(undefined);

  const store: ManageDiscussionStore = useLocalObservable(() => ({
    discussion: undefined,
    async refreshDiscussionInfos(discussionId: number) {
      let discussion: Discussion = undefined;
      try {
        setGetDiscussionLoading(true);
        setGetDiscussionError(undefined);
        discussion = await send(MessageController.getGroupDiscussionInfos(
          messageStore.currentProfile?.id,
          discussionId
        ));
        modalStore.setManageGroupDiscussionInfos({ discussionName: discussion.name });
        let currentMember = discussion.members.find(member => member.profileId === messageStore.currentProfile?.id);
        store.setRoleInDiscussion(currentMember?.role);
      }
      catch (err) {
        if (err instanceof Error && err.message === ERR_MSG_UNAUTHORIZED) {
          modalStore.unpopModal(router);
          popupStore.openInformationPopUp({
            msg: t('global.force_logout'),
            callback: () => { goToHomePageAndReload() }
          });
        }
        else if (err instanceof Error && err.message === ERR_MSG_NOT_ACCEPTABLE) {
          setGetDiscussionError(t('modal.manage_group_discussion.error_not_member'));
        }
        else if (err instanceof Error && err.message === ERR_MSG_NOT_FOUND) {
          setGetDiscussionError(t('modal.manage_group_discussion.error_not_found'));
        }
        else {
          setGetDiscussionError(t('global.error_with_code', { code: err }));
        }
      }
      finally {
        setGetDiscussionLoading(false);
      }
      store.discussion = discussion;
      return discussion;
    },
    roleInDiscussion: undefined,
    setRoleInDiscussion(role) {
      store.roleInDiscussion = role;
    },
    searchProfileInput: '',
    setSearchProfileInput(value) {
      store.searchProfileInput = value;
    },
    newMembers: [],
    addNewMember: (newMember: NewMemberI) => {
      store.newMembers.push(newMember);
    },
    removeNewMember: (profileId: number) => {
      store.newMembers = store.newMembers.filter(member => member.profileInfos.profileId !== profileId);
    },
    setNewMemberRole: (profileId: number, role: DiscussionMemberRole) => {
      let member = store.newMembers.find(member => member.profileInfos.profileId === profileId);
      member.role = role;
    },
    currentPanel: PANEL.SEE_PROFILES_LIST,
    setCurrentPanel(panel) {
      store.currentPanel = panel;
    },
    loading: false,
    setLoading(loading) {
      store.loading = loading;
    },
    error: '',
    setError(errorTxt) {
      store.error = errorTxt;
    },
  }));

  useEffect(() => {
    // Reset error & loading
    store.newMembers = [];
    store.setSearchProfileInput('');
    store.setError('');
    store.setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query[ModalKey.MESSAGES_MANAGE_GROUP_DISCUSSION] as string]);

  useEffect(() => {
    (async function getDiscussionInfos() {
      if (!!router.query[ModalKey.MESSAGES_MANAGE_GROUP_DISCUSSION] && messageStore.currentProfile?.id) {
        await store.refreshDiscussionInfos(parseInt(router.query[ModalKey.MESSAGES_MANAGE_GROUP_DISCUSSION] as string));
        if (store.roleInDiscussion === DiscussionMemberRole.ADMIN) {
          store.setCurrentPanel(PANEL.ADMIN_MENU);
        }
        else {
          store.setCurrentPanel(PANEL.SEE_PROFILES_LIST);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query[ModalKey.MESSAGES_MANAGE_GROUP_DISCUSSION] as string, messageStore.currentProfile?.id]);

  const addProfilesToDiscussion = async () => {
    try {
      store.setLoading(true);
      store.setError(undefined);
      await send(MessageController.addProfilesToDiscussion({
        adminProfileId: messageStore.currentProfile?.id,
        discussionId: store.discussion.id,
        members: store.newMembers.map(member => {
          return {
            profileId: member.profileInfos.profileId,
            role: member.role
          }
        })
      }));
      store.refreshDiscussionInfos(store.discussion.id);
      toast.dark(<ToastSucess msg={t('modal.manage_group_discussion.profiles_added', {
        smart_count: store.newMembers?.length,
        name: store.discussion.name
      })} />);
      modalStore.unpopModal(router);
    } catch (err) {
      store.setError(t('global.error_with_code', { code: err }));
    }
    finally {
      store.setLoading(false);
    }
  }

  const renderModal = () => {
    switch (store.currentPanel) {
      case PANEL.ADMIN_MENU:
        return <Modal
          modalKey={ModalKey.MESSAGES_MANAGE_GROUP_DISCUSSION}
          header={<ModalTitle name={t('modal.manage_group_discussion.title', { name: modalStore.manageGroupDiscussionInfos?.discussionName })} />}
          footer={
            <ModalConfirmOrCancelButtons
              noValidation
              cancelationTxt={t('global.close')}
            />
          }
        >
          <div className={stylesCommon.mainContainer}>
            <OwardLoader loading={store.loading} />
            <AdminMenuPanel store={store} />
          </div>
        </Modal>;
      case PANEL.ADD_PROFILES_SEARCH:
        return <Modal
          modalKey={ModalKey.MESSAGES_MANAGE_GROUP_DISCUSSION}
          header={<ModalTitle name={t('modal.manage_group_discussion.title_add_profiles_search', { name: modalStore.manageGroupDiscussionInfos?.discussionName })} />}
          footer={
            <ModalConfirmOrCancelButtons
              confirmTxt={t('global.next')}
              validation={() => {
                if (store.newMembers.length < 1) {
                  store.setError(t('modal.manage_group_discussion.at_least_1_new'))
                }
                else {
                  store.setCurrentPanel(PANEL.ADD_PROFILES_ROLES);
                }
              }}
              cancelationTxt={t('global.back')}
              cancelation={() => { store.setCurrentPanel(PANEL.ADMIN_MENU) }}
              error={store.error}
            />
          }
        >
          <div className={stylesCommon.mainContainer}>
            <OwardLoader loading={store.loading} />
            <AddProfilesSearchPanel store={store} />
          </div>
        </Modal>;
      case PANEL.ADD_PROFILES_ROLES:
        return <Modal
          modalKey={ModalKey.MESSAGES_MANAGE_GROUP_DISCUSSION}
          header={<ModalTitle name={t('modal.manage_group_discussion.title_add_profiles_roles', {
            smart_count: store.newMembers?.length,
            name: modalStore.manageGroupDiscussionInfos?.discussionName
          })} />}
          footer={
            <ModalConfirmOrCancelButtons
              confirmTxt={t('modal.manage_group_discussion.add_profiles_button', { smart_count: store.newMembers?.length })}
              validation={addProfilesToDiscussion}
              cancelationTxt={t('global.back')}
              cancelation={() => { store.setCurrentPanel(PANEL.ADD_PROFILES_SEARCH) }}
              error={store.error}
            />
          }
        >
          <div className={stylesCommon.mainContainer}>
            <OwardLoader loading={store.loading} />
            <AddProfilesRolesPanel store={store} />
          </div>
        </Modal>;
      case PANEL.SEE_PROFILES_LIST:
        return <Modal
          modalKey={ModalKey.MESSAGES_MANAGE_GROUP_DISCUSSION}
          header={<>
            <ModalTitle name={t('modal.manage_group_discussion.title_profiles', { name: modalStore.manageGroupDiscussionInfos?.discussionName })} />
            <DiscussionMemberCount count={store.discussion?.members.length} />
          </>}
          footer={<LeaveDiscussionButton store={store} />}
        >
          <div className={stylesCommon.mainContainer}>
            <OwardLoader loading={store.loading} />
            {
              store.discussion?.members?.map((member, i) =>
                <DiscussionMemberViewRoleLine member={member} key={i} />
              )
            }
            <DiscussionRoleExplanation />
          </div>
        </Modal>;
      case PANEL.MANAGE_PROFILES_LIST:
        return <Modal
          modalKey={ModalKey.MESSAGES_MANAGE_GROUP_DISCUSSION}
          header={<>
            <ModalTitle name={t('modal.manage_group_discussion.title_profiles', { name: modalStore.manageGroupDiscussionInfos?.discussionName })} />
            <DiscussionMemberCount count={store.discussion?.members.length} />
          </>}
          footer={
            <ModalConfirmOrCancelButtons
              noValidation
              cancelationTxt={t('global.back')}
              cancelation={() => { store.setCurrentPanel(PANEL.ADMIN_MENU) }}
              error={store.error}
            />
          }
        >
          <div className={stylesCommon.mainContainer}>
            <OwardLoader loading={store.loading} />
            {
              store.discussion?.members?.map((member, i) =>
                <DiscussionMemberManageLine member={member} store={store} key={i} />
              )
            }
            <DiscussionRoleExplanation />
          </div>
        </Modal>;
      case PANEL.PARAMETERS:
        return <Modal
          modalKey={ModalKey.MESSAGES_MANAGE_GROUP_DISCUSSION}
          header={<ModalTitle name={t('modal.manage_group_discussion.title_parameters', { name: modalStore.manageGroupDiscussionInfos?.discussionName })} />}
        >
          <div className={stylesCommon.mainContainer}>
            <OwardLoader loading={store.loading} />
            <ParametersPanel store={store} />
          </div>
        </Modal>;
    }
  }

  return (
    <React.Fragment>
      {
        userStore.isLogged ?
          <>
            {
              getDiscussionLoading ?
                <Modal
                  modalKey={ModalKey.MESSAGES_MANAGE_GROUP_DISCUSSION}
                  header={
                    modalStore.manageGroupDiscussionInfos?.discussionName &&
                    <ModalTitle name={t('modal.manage_group_discussion.title', { name: modalStore.manageGroupDiscussionInfos?.discussionName })} />
                  }
                >
                  <OwardLoader positionStatic loading={true} />
                </Modal>
                :

                getDiscussionError ?
                  <Modal modalKey={ModalKey.MESSAGES_MANAGE_GROUP_DISCUSSION}>
                    <p>{getDiscussionError}</p>
                  </Modal>
                  :
                  renderModal()
            }
          </>
          :
          <Modal modalKey={ModalKey.MESSAGES_MANAGE_GROUP_DISCUSSION}>
            <NeedConnectionModal headerMessage={t('modal.manage_group_discussion.need_connection')} />
          </Modal>
      }
    </React.Fragment >
  )
});

interface ManageDiscussionPanelProps {
  store: ManageDiscussionStore;
}


/**
 * ADMIN MENU PANEL
 */

const AdminMenuPanel: React.FC<ManageDiscussionPanelProps> = observer(props => {
  const { t } = useTranslate();
  const messageStore = useInjection<MessageStore>(StoresBindings.MESSAGE);

  const store = props.store;

  return (
    <div className={styles.adminMenuContainer}>
      <AdminMenuButton
        iconName='fas fa-user-plus'
        name={t('modal.manage_group_discussion.admin_menu_buttons.add_profiles_title')}
        description={t('modal.manage_group_discussion.admin_menu_buttons.add_profiles_desc')}
        onClick={() => { store.setCurrentPanel(PANEL.ADD_PROFILES_SEARCH) }}
      />
      <AdminMenuButton
        iconName='fas fa-users'
        name={t('modal.manage_group_discussion.admin_menu_buttons.profiles_list_title')}
        description={t('modal.manage_group_discussion.admin_menu_buttons.profiles_list_desc')}
        onClick={() => { store.setCurrentPanel(PANEL.MANAGE_PROFILES_LIST) }}
      />
      <AdminMenuButton
        iconName='fas fa-gear'
        name={t('modal.manage_group_discussion.admin_menu_buttons.parameters_title')}
        description={t('modal.manage_group_discussion.admin_menu_buttons.parameters_desc')}
        onClick={() => { store.setCurrentPanel(PANEL.PARAMETERS) }}
      />
      <LeaveDiscussionButton store={store} />
    </div>
  );
});

interface AdminMenuButtonProps {
  iconName: string;
  name: string;
  description?: string;
  onClick: () => void;
}

const AdminMenuButton: React.FC<AdminMenuButtonProps> = observer(props => {
  return (
    <div
      className={styles.adminMenuButtonContainer}
      onClick={props.onClick}
    >
      <div className={styles.iconContainer}>
        <p className={styles.icon}>
          <span className={`icon`}>
            <i className={props.iconName}></i>
          </span>
        </p>
      </div>
      <div className={styles.nameContainer}>
        <p className={styles.name}>{props.name}</p>
        {
          props.description &&
          <p className={styles.desc}>
            {props.description}
          </p>
        }
      </div>
    </div>
  )
});


/**
 * ADD PROFILES SEARCH PANEL
 */

const AddProfilesSearchPanel: React.FC<ManageDiscussionPanelProps> = observer(props => {
  const { t } = useTranslate();
  const store = props.store;

  return (
    <>
      <NewMembersList newMembers={store.newMembers} removeNewMember={store.removeNewMember} />
      <GroupDiscussionProfileSearchAndList store={store} alreadyInDiscussion={store.discussion.members.map(member => member.profileId)} />
    </>
  );
});


/**
 * ADD PROFILES ROLE PANEL
 */

const AddProfilesRolesPanel: React.FC<ManageDiscussionPanelProps> = observer(props => {
  const { t } = useTranslate();
  const store = props.store;

  return (
    <>
      <div className={styles.membersRoleMainContainer}>
        <DiscussionRoleExplanation />
        <div className={styles.membersContainer}>
          {store.newMembers.map((member, i) => <NewMemberRoleLine newMember={member} store={store} key={i} />)}
        </div>
      </div>
    </>
  );
});

/**
 * PARAMETERS PANEL
 */

const ParametersPanel: React.FC<ManageDiscussionPanelProps> = observer(props => {
  const { t } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const messageStore = useInjection<MessageStore>(StoresBindings.MESSAGE);

  const store = props.store;

  const schema = (t: any) => {
    return (
      Yup.object().shape({
        name: Yup.string().required(t('forms.required'))
      }))
  };

  const formik = useFormik({
    initialValues: {
      name: store.discussion.name,
      isPrivate: store.discussion.type === DiscussionType.PRIVATE_GROUP ? true : false,
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      try {
        store.setLoading(true);
        store.setError(undefined);
        await send(MessageController.modifyGroupDiscussionParameters({
          profileId: messageStore.currentProfile.id,
          discussionId: store.discussion.id,
          name: values.name,
          type: values.isPrivate ? DiscussionType.PRIVATE_GROUP : DiscussionType.PUBLIC_GROUP,
        }))
        await mutate([SWR_DISCUSSIONS_FOR_PROFILE, messageStore.currentProfile?.id]);
        toast.dark(<ToastSucess msg={t('modal.manage_group_discussion.parameter_modified', { name: values.name })} />);
        modalStore.unpopModal(router);
      } catch (err) {
        store.setError(t('global.error_retry'));
      }
      finally {
        store.setLoading(false);
      }
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div style={{ padding: '0 0.1rem' }}>
        <OwardFormInput
          id='name'
          label={t('modal.create_group_discussion.infos_form.name')}
          formik={formik}
        />
        {
          /*
          <OwardFormSwitch
          id='isPrivate'
          label={t('modal.create_group_discussion.infos_form.private')}
          description={t('modal.create_group_discussion.infos_form.private_desc')}
          formik={formik}
        />
          */
        }
        <ModalConfirmOrCancelButtons
          confirmTxt={t('global.save')}
          cancelationTxt={t('global.back')}
          cancelation={() => { store.setCurrentPanel(PANEL.ADMIN_MENU) }}
          error={store.error}
        />
      </div>
    </form>
  );
});
