import React, { useState, useEffect, useContext } from 'react';
import { send, BroadcasterController, Broadcaster } from '@oward/openapi';

import { PaginatedList, PaginatedListKey, Section, NavigationContext } from '@components/admin';
import { EditBroadcaster } from './EditBroadcaster';
import { strToPath, stateToEmoji, validationStateToEmoji } from '@oward/common-utils';
import { useTranslate } from '@hooks/use-translate';

export const BroadcasterList: React.FC = () => {

  const store = useContext(NavigationContext);
  const { t, locale } = useTranslate();
  const [items, setItems] = useState([] as Broadcaster[]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadItems = async () => {
    try {
      setLoading(true);
      setError(false);
      setItems(await send(BroadcasterController.findAdmin(locale)))
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const keys: PaginatedListKey<Broadcaster>[] = [
    {
      label: 'Id',
      value: item => item.id
    },
    {
      label: 'Name',
      value: item => item.name.concat(' ', stateToEmoji(item.state))
    },
    {
      label: 'Category',
      value: item => item.category?.name
    },
    {
      label: 'Validation State',
      value: item => item.validationState.concat(' ', validationStateToEmoji(item.validationState))
    }
  ];

  const filterItemStrings = (item: Broadcaster) => [item.id.toString(), strToPath(item.name)]

  const fetchOne = (item: Broadcaster) => send(BroadcasterController.findOne(item.id, locale));

  return (
    <React.Fragment>
      <Section
        title='List'
        subtitle='The list of all broadcasters in the database'
        childrenFullWidth
      >
        <PaginatedList
          keys={keys}
          items={items}
          filterItemStrings={filterItemStrings}
          size={100}
          editComponent={EditBroadcaster}
          fetchOne={fetchOne}
          reloadList={loadItems}
          loading={loading}
          error={error}
        />
      </Section>
      <div style={{ height: '2rem' }} />
    </React.Fragment>
  );
}
