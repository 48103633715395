import React, { useEffect, useState } from 'react';
import styles from './ModifyShortlistModal.module.scss';
import { observer } from 'mobx-react-lite';
import { UserStore, ModalStore, ModalKey, FilterStore, CardStore, PopupStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { useInjection, useShortlistGetProfiles, useTranslate } from '@hooks/.';
import { Modal, ModalTitle } from '@components/Modal';
import { OwardButton, OwardFormInput, OwardFormSwitch, OwardFormTextArea, OwardLoader, NeedConnectionModal } from '@components/.';
import { useRouter } from 'next/router';
import { Profile, send, Shortlist, ShortlistChangeOrder, ShortlistChangeOrderItem, ShortlistController, Visibility } from '@oward/openapi';
import classNames from 'classnames';
import { mutate } from 'swr';
import { ERR_MSG_UNAUTHORIZED, remSizeToPixelNumber, SWR_USER_SHORTLISTS_GET, SWR_SHORTLIST_GET_PROFILES, getInitials, goToHomePageAndReload } from '@utils/.';
import { useFormik } from 'formik';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export const ModifyShortlistModal: React.FC = observer(() => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>(undefined);

  useEffect(() => {
    // In case someone arrives directly on the gallery with a "modify_shortlist=...." in the query,
    // or if the id of the shortlist in store is different from the one in the url (typically when going backward)
    // we need to fetch shortlist data
    (async function getModifyShortlistInfos() {
      try {
        if (
          !!router.query[ModalKey.MODIFY_SHORTLIST] &&
          (modalStore.modifyShortlistInfos === undefined || modalStore.modifyShortlistInfos.shortlistId !== parseInt(router.query[ModalKey.MODIFY_SHORTLIST] as string))
        ) {
          let shortlistId: number = parseInt(router.query[ModalKey.MODIFY_SHORTLIST] as string);
          setLoading(true);
          setError('');
          const shortlist: Shortlist = await send(ShortlistController.getById(shortlistId));
          modalStore.setModifyShortlistInfos({
            shortlistId: parseInt(router.query[ModalKey.MODIFY_SHORTLIST] as string),
            shortlistName: shortlist.name,
            shortlistPrivate: shortlist.visibility === Visibility.PRIVATE,
            shortlistDescription: shortlist.description,
          });
        }
      }
      catch (err) {
        if (err instanceof Error && err.message === ERR_MSG_UNAUTHORIZED) {
          modalStore.unpopModal(router);
          popupStore.openInformationPopUp({
            msg: t('global.force_logout'),
            callback: () => { goToHomePageAndReload() }
          });
        }
        else {
          setError(t('global.error_with_code', { code: err }));
        }
      }
      finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query[ModalKey.MODIFY_SHORTLIST] as string])

  // Update formik values && list of profiles when opening the modal
  useEffect(() => {
    if (!!router.query[ModalKey.MODIFY_SHORTLIST] && modalStore.modifyShortlistInfos?.shortlistId) {
      mutate(SWR_SHORTLIST_GET_PROFILES);
      formik.setValues({
        name: modalStore.modifyShortlistInfos?.shortlistName,
        private: modalStore.modifyShortlistInfos?.shortlistPrivate,
        description: modalStore.modifyShortlistInfos?.shortlistDescription
      })
    }
    else {
      // Clear the profile list when closing the modal
      mutate(SWR_SHORTLIST_GET_PROFILES, [], false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query[ModalKey.MODIFY_SHORTLIST] as string, modalStore.modifyShortlistInfos?.shortlistId]);

  const formik = useFormik({
    initialValues: {
      name: modalStore.modifyShortlistInfos?.shortlistName,
      private: modalStore.modifyShortlistInfos?.shortlistPrivate,
      description: modalStore.modifyShortlistInfos?.shortlistDescription
    },
    onSubmit: async values => {
      try {
        await send(ShortlistController.modify({
          id: modalStore.modifyShortlistInfos?.shortlistId,
          name: values.name,
          visibility: values.private === true ? Visibility.PRIVATE : Visibility.PUBLIC,
          description: values.description
        }));
        setError(undefined);
        modalStore.unpopModal(router);
      } catch (err) {
        if (err instanceof Error && err.message === ERR_MSG_UNAUTHORIZED) {
          popupStore.openInformationPopUp({
            msg: t('global.force_logout'),
            callback: () => { goToHomePageAndReload() }
          });
        }
        else {
          setError(t('global.error_retry'));
        }
      }
      mutate(SWR_USER_SHORTLISTS_GET);
    }
  });

  return (
    <React.Fragment>
      {
        userStore.isLogged ?
          <Modal
            modalKey={ModalKey.MODIFY_SHORTLIST}
            header={<ModalTitle name={t('shortlist.modify.title', { name: modalStore.modifyShortlistInfos?.shortlistName })} />}
            footer={
              <form onSubmit={formik.handleSubmit} >
                <div className={styles.buttonContainer}>
                  <OwardButton name={t('global.validate')} submit />
                  <OwardButton name={t('global.cancel')} outlined onClick={() => { modalStore.unpopModal(router) }} />
                </div>
              </form>
            }
          >
            <div className={styles.mainContainer}>
              <OwardLoader loading={loading} />
              <div className={styles.contentContainer}>
                <form onSubmit={formik.handleSubmit} >
                  <OwardFormInput
                    id='name'
                    label={t('shortlist.new.name_label')}
                    placeholder={t('shortlist.new.name_placeholder')}
                    formik={formik}
                  />
                  <OwardFormSwitch
                    id='private'
                    label={t('shortlist.private')}
                    description={t('shortlist.new.private_desc')}
                    formik={formik}
                  />
                  <OwardFormTextArea
                    id='description'
                    label={t('shortlist.new.description_label')}
                    placeholder={t('shortlist.new.description_placeholder')}
                    formik={formik}
                  />
                  {
                    error &&
                    <p className={styles.error}>{error}</p>
                  }
                  <ProfileList />
                  <div className={styles.buttonContainer}>
                    <OwardButton
                      name={t('shortlist.modify.delete')}
                      red
                      confirmation={t('shortlist.modify.confirm_delete', { name: modalStore.modifyShortlistInfos?.shortlistName })}
                      onClick={async () => {
                        try {
                          await send(ShortlistController.deleteShortlist(modalStore.modifyShortlistInfos?.shortlistId));
                          mutate(SWR_USER_SHORTLISTS_GET);
                          modalStore.unpopModal(router);
                          if (modalStore.modifyShortlistInfos?.shortlistId === Number(filterStore.filter?.shortlist?.id)) {
                            router.replace('/[lang]', `/${locale}`);
                          }
                        } catch (err) {
                          if (err instanceof Error && err.message === ERR_MSG_UNAUTHORIZED) {
                            popupStore.openInformationPopUp({
                              msg: t('global.force_logout'),
                              callback: () => { goToHomePageAndReload() }
                            });
                          }
                          else {
                            setError(t('global.error_retry'));
                          }
                        }
                      }} />
                  </div>
                </form>
              </div>
            </div>
          </Modal >
          :
          <Modal modalKey={ModalKey.MODIFY_SHORTLIST}>
            <NeedConnectionModal headerMessage={t('shortlist.need_connection')} />
          </Modal >
      }
    </React.Fragment >
  );
});

const ProfileList: React.FC = observer(() => {
  const { t } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);
  const cardStore = useInjection<CardStore>(StoresBindings.CARD);
  const [error, setError] = useState<string>(undefined);
  const { profiles, isValidating } = useShortlistGetProfiles();
  const [profilesTmp, setProfilesTmp] = useState<Profile[]>([]);

  useEffect(() => {
    if (profiles !== undefined) {
      setProfilesTmp(profiles?.slice());
    }
  }, [profiles]);

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    let sourceIdx = parseInt(result.source.index)
    let destIdx = parseInt(result.destination.index)
    let draggedLink = profilesTmp[sourceIdx]
    let newList = profilesTmp.slice();
    newList.splice(sourceIdx, 1);
    newList.splice(destIdx, 0, draggedLink);
    setProfilesTmp(newList);

    let req: ShortlistChangeOrder = {
      shortlistId: modalStore.modifyShortlistInfos.shortlistId,
      items: new Array<ShortlistChangeOrderItem>()
    }

    newList.map((profile, index) => {
      req.items.push({
        profileId: profile.id,
        order: index + 1
      })
    });

    try {

      await send(ShortlistController.changeOrder(req));
      mutate(SWR_SHORTLIST_GET_PROFILES);

      if (Number(filterStore.filter?.shortlist?.id) === modalStore.modifyShortlistInfos.shortlistId) {
        cardStore.getCards(filterStore.filter, filterStore.openSearch);
      }
      setError(undefined)
    } catch (err) {
      if (err instanceof Error && err.message === ERR_MSG_UNAUTHORIZED) {
        popupStore.openInformationPopUp({
          msg: t('global.force_logout'),
          callback: () => { goToHomePageAndReload() }
        });
      }
      else {
        setError(t('global.error_retry'));
      }
    }
  }

  return (
    <div className={styles.profilesContainer}>
      {/* Show a loader only during the fetch at modal openning (and not when changing order) */}
      <OwardLoader loading={(isValidating && (profilesTmp === undefined || profilesTmp.length <= 0))} positionStatic />
      {
        (profilesTmp !== undefined && profilesTmp.length > 0) &&
        <React.Fragment>
          <hr />
          <div className={styles.descContainer}>
            <p className={styles.desc}>{t('shortlist.modify.order_explanation')}</p>
            {
              error &&
              <p className={styles.error}>{error}</p>
            }
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='profileList'>
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps} className={styles.listContainer}>
                  {
                    profilesTmp.map((profile: Profile, i) => {
                      return (
                        <Draggable draggableId={`${i}`} index={i} key={i}>
                          {
                            (provided, snapshot) => (
                              <div
                                className={classNames(styles.profileContainer, { [styles.draggingProfileContainer]: snapshot.isDragging })}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className={styles.avatarContainer}>
                                  {
                                    profile.avatarUrl ?
                                      <LazyLoadImage
                                        src={profile.avatarUrl}
                                        width={remSizeToPixelNumber(styles.avatarSize)}
                                        height={remSizeToPixelNumber(styles.avatarSize)}
                                        className={styles.avatar}
                                        alt={t('alt.avatar_just_name', { name: profile.name, })}
                                        effect='blur'
                                      />
                                      :
                                      <div className={classNames(styles.initialsContainer)} >
                                        <p className={styles.initials}>{getInitials(profile.name)}</p>
                                      </div>
                                  }
                                </div>
                                <p className={styles.name}>{profile.name}</p>
                              </div>
                            )
                          }
                        </Draggable>
                      );
                    })
                  }
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <hr />
        </React.Fragment>
      }
    </div>
  );
});
