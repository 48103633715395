import React from 'react';

import { JobStatistics } from './JobStatistics';
import { Divider } from '@components/admin';
import { JobList } from './JobList';
import { JobCategoryList } from './JobCategoryList';

export const JobsComponent: React.FC = () => {
  return (
    <React.Fragment>
      <JobStatistics />
      <Divider />
      <JobList />
      <Divider />
      <JobCategoryList />
    </React.Fragment>
  );
}
