import { ReportArtwork, ReportController, ReportMessage, ReportProfile, send, User, VerificationState } from "@oward/openapi";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react"
import { EditReportArtwork, EditReportMessage, EditReportProfile } from './EditReport'
import { Section, PaginatedList, PaginatedListKey, PaginatedListFilterType } from "../AdminComponents";
import { strToPath, userToStrFull, validationStateToEmoji } from "@oward/common-utils";

export const ReportProfileList: React.FC = () => {
  const [items, setItems] = useState([] as ReportProfile[]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems()
  }, []);

  const loadItems = async () => {
    setLoading(true);
    setError(false);
    try {
      setItems(await send(ReportController.getReportsProfileAdmin()));
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const userToUserReporterStr = (user: User): string => {
    return (userToStrFull(user) ?? '- not logged user -');
  }

  const keys: PaginatedListKey<ReportProfile>[] = [
    {
      label: 'reportId',
      value: item => item.id
    },
    {
      label: 'Reported Profile',
      value: item => `${item.profileReported?.id} - ${item.profileReported?.name}`
    },
    {
      label: 'User Reporter',
      value: item => userToUserReporterStr(item.userReporter)
    },
    {
      label: 'Message',
      value: item => item.message
    },
    {
      label: 'Date Of Report',
      value: item => moment(item.creationDate).format('lll')
    },
    {
      label: 'NumberOfReports/Profile',
      value: item => getNumberOfReports(item)
    },
    {
      label: 'Validation State',
      value: item => item.validationState.concat(' ', validationStateToEmoji(item.validationState))
    }
  ]

  const getNumberOfReports = (item: ReportProfile): number => {
    return items?.filter(report => report?.profileReported?.id === item.profileReported?.id).length;
  }

  const filterItemStrings = (item: ReportProfile) => [
    item.id.toString(),
    strToPath(item.profileReported?.name),
    strToPath(item.userReporter?.email)
  ];

  const filterComponents = [
    {
      name: 'User Reporter',
      type: PaginatedListFilterType.DROPDOWN,
      options: [...new Set(items.map(item => userToUserReporterStr(item.userReporter)))].map(userStr => {
        return { value: userStr, label: userStr }
      }),
      filterFunction: (item: ReportProfile, value: string) => userToUserReporterStr(item.userReporter) === value
    },
    {
      name: 'Profile Reported',
      type: PaginatedListFilterType.DROPDOWN,
      options: [...new Set(items.map(item => item.profileReported.name))].map(profileStr => {
        return { value: profileStr, label: profileStr }
      }),
      filterFunction: (item: ReportProfile, value: string) => item.profileReported.name === value
    },
    {
      name: 'Validation State',
      type: PaginatedListFilterType.DROPDOWN,
      options: Object.values(VerificationState).map((state) => {
        return ({ value: state, label: state });
      }),
      filterFunction: (item: ReportProfile, value: string) => item.validationState === value
    },
    {
      name: 'Number min of reports',
      type: PaginatedListFilterType.INPUT,
      filterFunction: (item: ReportProfile, value: string) => getNumberOfReports(item) >= parseInt(value)
    },
  ];

  const fetchOne = (item: ReportProfile): Promise<ReportProfile> => send(ReportController.getOneReportProfileAdmin(item.id));

  return (
    <React.Fragment>
      <Section
        title='List of Reports for Profiles'
        subtitle='The list of all Reports for Profiles in the database (Reload the list after each changements)'
        childrenFullWidth
      ></Section>
      <PaginatedList
        keys={keys}
        items={items}
        filterItemStrings={filterItemStrings}
        filterComponents={filterComponents}
        size={100}
        editComponent={EditReportProfile}
        fetchOne={fetchOne}
        reloadList={loadItems}
        loading={loading}
        error={error}
      />
    </React.Fragment>
  )
}

export const ReportArtworkList: React.FC = () => {
  const [items, setItems] = useState([] as ReportArtwork[]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems()
  }, []);

  const loadItems = async () => {
    setLoading(true);
    setError(false);
    try {
      setItems(await send(ReportController.getReportsArtworkAdmin()));
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const userToUserReporterStr = (user: User): string => {
    return (userToStrFull(user) ?? '- not logged user -');
  }

  const keys: PaginatedListKey<ReportArtwork>[] = [
    {
      label: 'reportId',
      value: item => item.id
    },
    {
      label: 'Reported Artwork',
      value: item => `${item.artworkReported?.id} - ${item.artworkReported?.name}`
    },
    {
      label: 'User Reporter',
      value: item => userToUserReporterStr(item.userReporter)
    },
    {
      label: 'Message',
      value: item => item.message
    },
    {
      label: 'Date Of Report',
      value: item => moment(item.creationDate).format('lll')
    },
    {
      label: 'NumberOfReports/Artwork',
      value: item => getNumberOfReports(item)
    },
    {
      label: 'Validation State',
      value: item => item.validationState.concat(' ', validationStateToEmoji(item.validationState))
    }
  ]

  const getNumberOfReports = (item: ReportArtwork): number => {
    return items?.filter(report => report?.artworkReported?.id === item.artworkReported?.id).length;
  }

  const filterItemStrings = (item: ReportArtwork) => [
    item.id.toString(),
    strToPath(item.artworkReported?.name),
    strToPath(item.userReporter?.email)
  ];

  const filterComponents = [
    {
      name: 'User Reporter',
      type: PaginatedListFilterType.DROPDOWN,
      options: [...new Set(items.map(item => userToUserReporterStr(item.userReporter)))].map(userStr => {
        return { value: userStr, label: userStr }
      }),
      filterFunction: (item: ReportArtwork, value: string) => userToUserReporterStr(item.userReporter) === value
    },
    {
      name: 'Artwork Reported',
      type: PaginatedListFilterType.DROPDOWN,
      options: [...new Set(items.map(item => item.artworkReported.name))].map(artworkStr => {
        return { value: artworkStr, label: artworkStr }
      }),
      filterFunction: (item: ReportArtwork, value: string) => item.artworkReported.name === value
    },
    {
      name: 'Validation State',
      type: PaginatedListFilterType.DROPDOWN,
      options: Object.values(VerificationState).map((state) => {
        return ({ value: state, label: state });
      }),
      filterFunction: (item: ReportArtwork, value: string) => item.validationState === value
    },
    {
      name: 'Number min of reports',
      type: PaginatedListFilterType.INPUT,
      filterFunction: (item: ReportArtwork, value: string) => getNumberOfReports(item) >= parseInt(value)
    },
  ];

  const fetchOne = (item: ReportArtwork): Promise<ReportArtwork> => send(ReportController.getOneReportArtworkAdmin(item.id));

  return (
    <React.Fragment>
      <Section
        title='List of Reports for Artworks'
        subtitle='The list of all Reports for Artworks in the database (Reload the list after each changements)'
        childrenFullWidth
      ></Section>
      <PaginatedList
        keys={keys}
        items={items}
        filterItemStrings={filterItemStrings}
        filterComponents={filterComponents}
        size={100}
        editComponent={EditReportArtwork}
        fetchOne={fetchOne}
        reloadList={loadItems}
        loading={loading}
        error={error}
      />
    </React.Fragment>
  )
}

export const ReportMessageList: React.FC = () => {
  const [items, setItems] = useState([] as ReportMessage[]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems()
  }, []);

  const loadItems = async () => {
    setLoading(true);
    setError(false);
    try {
      setItems(await send(ReportController.getReportsMessageAdmin()));
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const userToUserReporterStr = (user: User): string => {
    return (userToStrFull(user) ?? '- not logged user -');
  }

  const keys: PaginatedListKey<ReportMessage>[] = [
    {
      label: 'reportId',
      value: item => item.id
    },
    {
      label: 'Reported Message',
      value: item => `${item.messageReported?.id} - ${(item.messageReported?.text || item.messageReported?.url)}`
    },
    {
      label: 'User Reporter',
      value: item => userToUserReporterStr(item.userReporter)
    },
    {
      label: 'Message of the report',
      value: item => item.message
    },
    {
      label: 'Date Of Report',
      value: item => moment(item.creationDate).format('lll')
    },
    {
      label: 'Validation State',
      value: item => item.validationState.concat(' ', validationStateToEmoji(item.validationState))
    }
  ]

  const filterItemStrings = (item: ReportMessage) => [
    item.id.toString(),
    strToPath(item.messageReported?.text),
    strToPath(item.messageReported?.url),
    strToPath(item.userReporter?.email)
  ];

  const filterComponents = [
    {
      name: 'User Reporter',
      type: PaginatedListFilterType.DROPDOWN,
      options: [...new Set(items.map(item => userToUserReporterStr(item.userReporter)))].map(userStr => {
        return { value: userStr, label: userStr }
      }),
      filterFunction: (item: ReportMessage, value: string) => userToUserReporterStr(item.userReporter) === value
    },
    {
      name: 'Validation State',
      type: PaginatedListFilterType.DROPDOWN,
      options: Object.values(VerificationState).map((state) => {
        return ({ value: state, label: state });
      }),
      filterFunction: (item: ReportMessage, value: string) => item.validationState === value
    }
  ];

  const fetchOne = (item: ReportMessage): Promise<ReportMessage> => send(ReportController.getOneReportMessageAdmin(item.id));

  return (
    <React.Fragment>
      <Section
        title='List of Reports for Messages'
        subtitle='The list of all Reports for Messages in the database (Reload the list after each changements)'
        childrenFullWidth
      ></Section>
      <PaginatedList
        keys={keys}
        items={items}
        filterItemStrings={filterItemStrings}
        filterComponents={filterComponents}
        size={100}
        editComponent={EditReportMessage}
        fetchOne={fetchOne}
        reloadList={loadItems}
        loading={loading}
        error={error}
      />
    </React.Fragment>
  )
}
