import React from 'react';
import classNames from 'classnames';

import styles from './Item.module.scss';

import { Menu, Icon } from '@components/Core';

interface ItemProps {
  label: string;
  icon: string;
  active: boolean;
  onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export const Item = (props: ItemProps) => {
  return (
    <React.Fragment>
      <Menu.List.Item active={props.active} onClick={props.onClick}>
        <Icon icon={props.icon} className={styles.icon} />
        <span className={classNames('menu-item-label', styles.label)}>
          {props.label}
        </span>
      </Menu.List.Item>
    </React.Fragment >
  );
}
