import React from 'react';
import { useRouter } from 'next/router';
import styles from './AccordionContent.module.scss';
import { observer } from 'mobx-react-lite';
import { useTranslate, useInjection } from '@hooks/.';
import { ArtworkStore, CardStore, FilterStore } from '@stores/.';
import { FilterListItemI } from '@oward/openapi';
import { FilterType, LocationType } from '@oward/common-enums';
import { StoresBindings } from '@container/.';
import {
  OwardSwitch, OwardFilterSelectStyles, SelectGroup, SelectOption,
  entitiesToOptions, entitiesToOptionsWithTranslation, festivalAwardToOptions,
  festivalToOptions, jobsToOptions, agentsToOptions, broadcastersToOptions, OwardHeaderFilterSelectStyles, jobFilterName, jobCategoryFilterName, OwardSearchInput,
} from '@components/Core/.';
import { deepEqual, GalleryType } from '@oward/common-utils';
import classNames from 'classnames';
import Select from 'react-select';
import { useState } from 'react';
import { toJS } from 'mobx';
import { onTouchDevice } from '../../utils';

interface FilterSelectProps {
  id: number,
  name: string,
  filter_type: FilterType,
  onGalleryHeader?: boolean,
}

export const FilterSelect: React.FC<FilterSelectProps> = observer((props: FilterSelectProps) => {
  const { t, locale } = useTranslate();
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [options, setOptions] = useState(undefined);
  const [filter, setFilter] = useState(undefined);

  // Used for dropdowns listing
  const getFilterSelectOptions = (type: FilterType, entities: any[]) => {
    let options: SelectOption[] = new Array<SelectOption>();

    switch (type) {
      case FilterType.ASSOCIATION:
      case FilterType.JOB_CATEGORY:
      case FilterType.FESTIVAL_COUNTRY:
        return entitiesToOptionsWithTranslation(entities, locale, true);

      case FilterType.LANGUAGE:
      case FilterType.FILM_TYPE:
      case FilterType.BROADCASTER_CATEGORY:
      case FilterType.FESTIVAL_CATEGORY:
      case FilterType.FESTIVAL_AWARD_CATEGORY:
        return entitiesToOptionsWithTranslation(entities, locale);

      case FilterType.JOB:
        return jobsToOptions(entities);

      case FilterType.AGENT:
        return agentsToOptions(entities);

      case FilterType.BROADCASTER:
        return broadcastersToOptions(entities);

      case FilterType.FILM_YEAR:
        {
          entities.map((year: number) => {
            let option: SelectOption = new SelectOption();
            // Year is used 'as an ID' ('0' means in development)
            option.value = { id: year };
            option.label = year === 0 ? t('wip.film') : year.toString();
            options.push(option);
          });
          return options;
        }
      case FilterType.LOCATION:
        {
          let groups: SelectGroup[] = new Array<SelectGroup>();

          Object.values(LocationType).map((locationType: string) => {
            let group: SelectGroup = new SelectGroup();
            groups.push(group);
            group.label = t('location.' + locationType);
            group.options = new Array<SelectOption>();
            if (entities !== undefined && entities[locationType] !== undefined) {
              let locationList: Array<FilterListItemI> = toJS(entities[locationType]) as Array<FilterListItemI>;
              locationList.sort((a: FilterListItemI, b: FilterListItemI) => {
                // Put the 'other' at the end of the list
                if (a.name === t('global.other')) {
                  return 1;
                }
                return a.name.localeCompare(b.name, locale);
              })
              locationList.map((locationDetail: FilterListItemI) => {
                let option: SelectOption = new SelectOption();
                option.value = locationDetail;
                option.label = locationDetail.name;
                option.type = locationType;
                group.options.push(option);
              });
            }
          })
          return groups;
        }

      case FilterType.FESTIVAL:
        return festivalToOptions(entities, locale)

      case FilterType.FESTIVAL_AWARD:
        return festivalAwardToOptions(entities, locale);

      case FilterType.FESTIVAL_YEAR:
        entities.map((year: number) => {
          let option: SelectOption = new SelectOption();
          // Year is used 'as an ID'
          option.value = { id: year };
          option.label = year.toString();
          options.push(option);
        });
        return options;
    }
  }

  const handleLoadOptions = async () => {
    try {
      let entities: any = await filterStore.getFilterEntities(props.filter_type);
      setOptions(getFilterSelectOptions(props.filter_type, entities));
      setFilter(toJS(filterStore.filter));
    }
    catch (err) {
      console.error(err);
      setFilter(undefined);
      setIsError(true);
    }
    finally {
      setIsLoading(false);
    }
  }

  const handleFocus = async () => {
    // If filter didn't change, no need to re-load the list.)
    // Also used for first load, as filter is undefined
    if (!deepEqual(filter, toJS(filterStore.filter))) {
      setOptions(undefined);
      setIsLoading(true);
      await handleLoadOptions();
    }

    let element: HTMLElement = document.getElementById('accordion_dropdown_' + props.filter_type);
    if (element !== null && onTouchDevice()) {
      element.scrollIntoView();
    }
  }

  const handleChange = (selectedOption: SelectOption) => {
    if (selectedOption !== null) {
      filterStore.setFilter(
        router,
        props.filter_type,
        { id: selectedOption.value.id, type: selectedOption.type },
        selectedOption.label
      );
    }
    else {
      filterStore.setFilter(router, props.filter_type, undefined, undefined);
    }
  }

  return (
    <div className={classNames(styles.dropdownContainer)} id={'accordion_dropdown_' + props.filter_type}>
      <Select
        instanceId={props.id}
        onFocus={handleFocus}
        options={options}
        placeholder={t(props.name)}
        noOptionsMessage={() => isError ? t('sidebar.error') : t(props.name + '_no_options')}
        styles={props.onGalleryHeader ? OwardHeaderFilterSelectStyles : OwardFilterSelectStyles}
        isClearable
        isLoading={isLoading}
        loadingMessage={() => t('global.loading')}
        components={props.onGalleryHeader && { DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        onChange={handleChange}
        value={filterStore.filter[props.filter_type] === undefined ? null : {
          value: filterStore.filter[props.filter_type],
          label: filterStore.label[props.filter_type]
        }}
      />
    </div>
  );
});

export const SearchProfile: React.FC = observer(() => {
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);

  return (
    <div className={classNames(styles.dropdownsContainer)}>
      <FilterSelect name={jobCategoryFilterName(filterStore?.filter.gallery_type?.type)} id={1} filter_type={FilterType.JOB_CATEGORY} />
      <FilterSelect name={jobFilterName(filterStore.filter?.gallery_type?.type)} id={2} filter_type={FilterType.JOB} />
      <FilterSelect name='sidebar.search_profile.location' id={3} filter_type={FilterType.LOCATION} />
      <FilterSelect name='sidebar.search_profile.language' id={6} filter_type={FilterType.LANGUAGE} />
    </div>
  );
});

export const SearchRepresentation: React.FC = observer(() => {

  return (
    <div className={classNames(styles.dropdownsContainer)}>
      <FilterSelect name='sidebar.search_representation.agent' id={4} filter_type={FilterType.AGENT} />
      <FilterSelect name='sidebar.search_representation.association' id={5} filter_type={FilterType.ASSOCIATION} />
    </div>
  );
});

export const SearchFilm: React.FC = observer(() => {
  const { t } = useTranslate();
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);

  return (
    <div className={classNames(styles.dropdownsContainer)}>
      <SidebarSearchExplanation text={t(`sidebar.search_film.intro${filterStore.isArtworkGallery ? '_artwork' : ''}`)} />
      <FilterSelect name='sidebar.search_film.film_type' id={11} filter_type={FilterType.FILM_TYPE} />
      <FilterSelect name='sidebar.search_film.film_year' id={12} filter_type={FilterType.FILM_YEAR} />
    </div>
  );
});

export const SearchFestival: React.FC = observer(() => {
  const { t } = useTranslate();
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);
  const router = useRouter();

  const handleFestivalAwardedChange = () => {
    filterStore.setFilter(
      router,
      FilterType.FESTIVAL_AWARDED_ONLY,
      filterStore.filter.festival_awarded_only === undefined ? { id: 1 } : undefined,
      undefined
    );
  }

  return (
    <div className={classNames(styles.dropdownsContainer)}>
      {
        filterStore.isProfileGallery &&
        <SidebarSearchExplanation text={t('sidebar.search_festival.intro')} />
      }
      <FilterSelect name='sidebar.search_festival.festival_category' id={21} filter_type={FilterType.FESTIVAL_CATEGORY} />
      <FilterSelect name='sidebar.search_festival.festival' id={22} filter_type={FilterType.FESTIVAL} />
      <FilterSelect name='sidebar.search_festival.festival_award_category' id={23} filter_type={FilterType.FESTIVAL_AWARD_CATEGORY} />
      <FilterSelect name='sidebar.search_festival.festival_country' id={24} filter_type={FilterType.FESTIVAL_COUNTRY} />
      {/*<SearchDropdown name='sidebar.search_festival.festival_award' id={25} filter_type={FilterType.FESTIVAL_AWARD} />*/}
      <FilterSelect name='sidebar.search_festival.festival_year' id={26} filter_type={FilterType.FESTIVAL_YEAR} />
      <OwardSwitch
        label={t('sidebar.search_festival.awarded_only')}
        active={filterStore.filter.festival_awarded_only === undefined ? false : true}
        onChange={handleFestivalAwardedChange}
      />
      <p className={classNames(styles.explanation)}>{t('sidebar.search_festival.awarded_only_explanation')}</p>
    </div>
  );
});

export const SearchBroadcast: React.FC = observer(() => {
  const { t } = useTranslate();
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);

  return (
    <div className={classNames(styles.dropdownsContainer)}>
      {
        filterStore.isProfileGallery &&
        <SidebarSearchExplanation text={t(`sidebar.search_broadcast.intro`)} />
      }
      <FilterSelect name='sidebar.search_broadcast.broadcaster_category' id={13} filter_type={FilterType.BROADCASTER_CATEGORY} />
      <FilterSelect name='sidebar.search_broadcast.broadcaster' id={14} filter_type={FilterType.BROADCASTER} />
    </div>
  );
});

export const SearchAdmin: React.FC = observer(() => {
  const { t } = useTranslate();
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);
  const cardStore = useInjection<CardStore>(StoresBindings.CARD);
  const artworkStore = useInjection<ArtworkStore>(StoresBindings.ARTWORK);
  const router = useRouter();

  const handleSeeAllProfiles = () => {
    filterStore.setFilter(
      router,
      FilterType.ADMIN_SEE_ALL_PROFILES,
      filterStore.filter?.admin_see_all_profiles === undefined ? { id: 1 } : undefined,
      undefined
    );
  }

  return (
    <div className={classNames(styles.dropdownsContainer)}>
      <SidebarSearchExplanation text={`Admin Only : see ${filterStore.isArtworkGallery ? ' artworks of ' : ''} ALL profiles (deleted, no subscription, no artwork, ...) + by name`} />
      <OwardSwitch
        label={`See ${filterStore.isArtworkGallery ? ' artworks of ' : ''} All Profiles`}
        active={filterStore.filter.admin_see_all_profiles === undefined ? false : true}
        onChange={handleSeeAllProfiles}
      />
      <div style={{ paddingTop: '0.5rem' }}>
        <OwardSearchInput
          placeholder={t('sidebar.search_name')}
          value={filterStore.openSearch}
          onChange={value => {
            filterStore.setOpenSearch(value);
            if (filterStore.isArtworkGallery) {
              artworkStore.getArtworks(filterStore.filter, value);
            }
            else {
              cardStore.getCards(filterStore.filter, value);
            }
          }}
        />
      </div>
    </div>
  );
});

interface SidebarSearchExplanationProps {
  text: string;
}

export const SidebarSearchExplanation: React.FC<SidebarSearchExplanationProps> = props => {

  return (
    <p className={classNames(styles.explanation, styles.categoryIntro)}>{props.text}</p>
  );
}
