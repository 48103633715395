import React from 'react';
import styles from './ModalCommon.module.scss'
import { useRouter } from 'next/router';
import { StoresBindings } from '@container/.';
import { useInjection, useTranslate } from '@hooks/.';
import { ModalKey, ModalStore, UserStore } from '@stores/.';
import { observer } from 'mobx-react-lite';
import { Modal, ModalConfirmOrCancelButtons, ModalTitle } from '.';
import { StripeAddCard } from '..';

export const AddBankCardModal: React.FC = observer(() => {
  const { t } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  return (
    <React.Fragment>
      <Modal
        modalKey={ModalKey.ADD_BANK_CARD}
        header={<ModalTitle name={t('modal.add_bank_card.title')} />}
      >
        <div className={styles.mainContainer}>
          <StripeAddCard />
        </div>
      </Modal>
    </React.Fragment>
  )
});
