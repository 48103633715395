import { useTranslate } from "@hooks/use-translate";
import styles from './ProfilePopUps.module.scss';
import { Profile, ProfileController, send } from "@oward/openapi";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { OwardLoader } from "../Loader";
import { ProfileAvatar } from "../Avatar/ProfileAvatars";
import classNames from "classnames";
import { findTranslationOrDefault } from "@oward/common-utils";
import { ButtonAspect, CardButtonContact, CardButtonOnFire, CardButtonReport, CardButtonShare, CardButtonShortlist, CardButtons } from "@components/Card";
import { hasPortfolioPublic } from "@utils/utils";
import { OwardButton, OwardLinkButton } from "../Button";
import { Link } from "@components/I18n";

interface SeeOrActionProfilePopUpProps {
  profileId: number;
}

export const SeeOrActionProfilePopUp: React.FC<SeeOrActionProfilePopUpProps> = observer(props => {
  const { t, locale } = useTranslate();
  const [profile, setProfile] = useState<Profile>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    (async function getProfile() {
      try {
        setLoading(true);
        setError(undefined)
        let profile: Profile = await send(ProfileController.findOneForPopUp(props.profileId, locale))
        setProfile(profile);
      }
      catch (err) {
        setError(t('global.error_with_code', { code: err }));
      }
      finally {
        setLoading(false);
      }
    })();

  }, [props.profileId]);

  return (
    <PopUpLoaderOrError loading={loading} error={error}>
      {
        profile &&
        <div className={classNames(styles.seeOrContactProfileContainer)}>
          <ProfileAvatar
            avatarUrl={profile.avatarUrl}
            profileName={profile.name}
            size="3rem"
            showInitialIfNoUrl
          />
          <p className={styles.name}>{profile.name}</p>
          <div className={styles.locationContainer}>
            <p className={styles.location}>
              {`${findTranslationOrDefault(profile.location.city, locale)}, ${findTranslationOrDefault(profile.location.country, locale)}`}
            </p>
          </div>
          <p className={styles.job}>{findTranslationOrDefault(profile.job, locale)}</p>
          <div className={styles.seePortfolioContainer}>
            {
              hasPortfolioPublic(profile) ?
                <Link href={`/p/[profilePath]`} as={`/p/${profile.path}`} passHref>
                  <OwardLinkButton
                    name={t('pop_ups.profile_see_or_action.see_portfolio')}
                  />
                </Link>
                :
                <p className={styles.notPublished}>{t('pop_ups.profile_see_or_action.portfolio_not_published')}</p>
            }

          </div>
          <div className={styles.buttonsContainer}>
            <CardButtonReport
              profileId={profile.id}
              profileName={profile.name}
            />
            <CardButtonContact
              profileId={profile.id}
              profileName={profile.name}
              buttonAspect={ButtonAspect.CARD}
            />
            <CardButtonOnFire
              profileId={profile.id}
              profileName={profile.name}
            />
            <CardButtonShare
              profileId={profile.id}
              profileJob={findTranslationOrDefault(profile.job, locale)}
              profilePath={profile.path}
              profileName={profile.name}
              buttonAspect={ButtonAspect.CARD}
              canShare={hasPortfolioPublic(profile)}
            />
            <CardButtonShortlist
              profileId={profile.id}
              profileName={profile.name}
              profileJob={findTranslationOrDefault(profile.job, locale)}
            />
          </div>
        </div>
      }
    </PopUpLoaderOrError>
  );
})

interface PopUpLoaderOrErrorProps {
  loading: boolean;
  error: string;
  children: React.ReactNode;
}

const PopUpLoaderOrError: React.FC<PopUpLoaderOrErrorProps> = props => {
  return (
    <div>
      {
        props.loading ?
          <OwardLoader loading={true} positionStatic />
          :
          <>
            {
              props.error ?
                <p>{props.error}</p>
                :
                <>
                  {props.children}
                </>
            }</>
      }
    </div>
  );
}
