import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import {
  FestivalAward,
  FestivalController,
  FilmsController, FilmSelection, State, ValidationState
} from '@oward/openapi';
import { SelectionStatus } from '@oward/common-enums';
import { Divider, Section, SubmitOrCancel, NavigationContext } from '@components/admin';
import { EditProps } from '../types';
import {
  festivalAwardToOptions,
  festivalToOptions,
  Heading,
  OwardFormInput,
  OwardFormSelect,
  OwardFormSelectLoad,
  OwardLoader,
} from '@components/Core';
import { useTranslate } from '@hooks/.';
import { stateToEmoji } from '@oward/common-utils';
import * as Yup from 'yup';

const schema = (t: any) => {
  return (
    Yup.object().shape({
      festival: Yup.string().required(),
      year: Yup.number().required().max(2050).min(1900),
    }))
};

export const EditFilmSelection = observer((props: EditProps<FilmSelection>) => {
  const { t, locale } = useTranslate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigation = useContext(NavigationContext);

  const formik = useFormik({
    initialValues: {
      state: props.item.state,
      festival: props.item.festival,
      award: props.item.award as FestivalAward,
      year: props.item.year,
      selectionStatus: props.item.status,
      proofUrl: props.item.proofUrl,
      validationState: props.item.validationState,
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      props.item.state = values.state;
      props.item.festival = values.festival;
      props.item.award = values.award === undefined ? null : values.award;
      props.item.year = values.year;
      props.item.status = values.selectionStatus;
      props.item.proofUrl = values.proofUrl;
      props.item.validationState = values.validationState;
      try {
        setLoading(true);
        await FilmsController.saveOneSelection(props.item);
        if (props.notify) { props.notify(props.item) };
        setError(false);
        navigation.unpop();
      } catch (err) {
        setError(true);
      }
      finally {
        setLoading(false);
      }
    }
  });

  return (
    <React.Fragment>
      <Section
        title={`Edit film selection for film "${props.item.film?.name}"`}
        subtitle={`Change the film selection properties for film "${props.item.film?.name}"`}
        childrenFullWidth
      >
        <form className='box' style={{ position: 'relative' }} onSubmit={formik.handleSubmit} >
          <OwardLoader loading={loading} />
          <Heading>GENERAL</Heading>
          <OwardFormSelectLoad
            id='festival'
            label='Festival *'
            fetchFunction={FestivalController.findAdmin(locale)}
            entitiesToOptions={entities => { return festivalToOptions(entities, locale) }}
            formik={formik}
          />
          <OwardFormSelectLoad
            id='award'
            label='Award'
            fetchFunction={FestivalController.findAwardsAdmin(formik.values.festival?.id)}
            entitiesToOptions={entities => { return festivalAwardToOptions(entities, locale, formik.values.festival?.id) }}
            formik={formik}
            isClearable
            reload
          />
          {
            formik.values.award?.festival?.id && formik.values.award?.festival?.id !== formik.values.festival?.id &&
            <p className='has-text-danger'>
              {`Error : award "${formik.values.award?.name}" not valid for festival "${formik.values.festival?.name}"`}
            </p>
          }
          {
            formik.values.award && formik.values.award.state !== State.LIVE &&
            <p className='has-text-danger'>
              {`Error : award "${formik.values.award?.name}" has state : ${formik.values.award?.state}`}
            </p>
          }
          <OwardFormInput
            id='year'
            type='number'
            label={'Year of selection *'}
            formik={formik}
          />
          <OwardFormSelect
            id='selectionStatus'
            label='SelectionStatus *'
            options={Object.values(SelectionStatus).map((selectionStatus) => {
              return ({ value: selectionStatus, label: selectionStatus });
            })}
            formik={formik}
          />
          <OwardFormInput
            id='proofUrl'
            label={'Proof Url'}
            formik={formik}
          />
          <Divider />
          <Heading>ADMIN</Heading>
          {
            props.item.createdBy ?
              <p>Film Selection created by user: {props.item.createdBy.email}</p>
              :
              <p>Film Selection created by Oward</p>
          }
          <div style={{ height: '1rem' }} />
          <OwardFormSelect
            id='state'
            label='State *'
            options={Object.values(State).map((state) => {
              return ({ value: state, label: state.concat(' ', stateToEmoji(state)) });
            })}
            formik={formik}
          />
          <OwardFormSelect
            id='validationState'
            label='Validation State *'
            options={Object.values(ValidationState).map((validationState) => {
              return ({ value: validationState, label: validationState });
            })}
            formik={formik}
          />
          <SubmitOrCancel error={error} noCancel={props.noCancel} />
        </form >
        <div style={{ height: '200px' }} />
      </Section >
    </React.Fragment>
  )
});
