import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import { observer } from "mobx-react-lite";
import { State, PromoCode, PaymentController, PromoCodeValidity } from '@oward/openapi';
import { Section, Divider, SubmitOrCancel, NavigationContext } from '@components/admin';
import { OwardFormInput, Heading, OwardFormSelect, OwardFormDatePicker, OwardFormTextArea, ToastSucess } from '@components/Core';
import { useTranslate } from '@hooks/.';
import { EditProps } from '../types';
import * as Yup from 'yup';
import { durationWithExtensionString, PAYMENT_OPTION, priceWithReductionString, stateToEmoji } from '@oward/common-utils';
import { toast } from 'react-toastify';
import { PaymentProfileList } from '../Profiles/EditProfile';

const schema = (t: any) => {
  return (
    Yup.object().shape({
      code: Yup.string().required(),
      validity: Yup.string().required()
    }))
};

export const EditPromoCode = observer((props: EditProps<PromoCode>) => {
  const { t, locale } = useTranslate();
  const [error, setError] = useState(false);
  const navigation = useContext(NavigationContext);

  const formik = useFormik({
    initialValues: {
      code: props.item?.code,
      premiumLevel: props.item?.premiumLevel,
      reductionPercentage: props.item?.reductionPercentage,
      extensionMonths: props.item?.extensionMonths,
      validity: props.item?.validity,
      expirationDate: props.item?.expirationDate ? new Date(props.item?.expirationDate) : undefined,
      description: props.item?.description,
      state: props.item?.state,
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      try {
        setError(false);
        if (props.item === undefined) {
          // Promo code Creation
          await PaymentController.promoCodeCreate({
            code: values.code,
            premiumLevel: values.premiumLevel?.toString() === '' ? undefined : values.premiumLevel,
            reductionPercentage: values.reductionPercentage?.toString() === '' ? undefined : values.reductionPercentage,
            extensionMonths: values.extensionMonths?.toString() === '' ? undefined : values.extensionMonths,
            validity: values.validity,
            expirationDate: values.expirationDate?.toISOString(),
            description: values.description,
          });
        }
        else {
          // Promo code modification
          props.item.code = values.code;
          props.item.premiumLevel = values.premiumLevel?.toString() === '' ? null : values.premiumLevel;
          props.item.reductionPercentage = values.reductionPercentage?.toString() === '' ? null : values.reductionPercentage;
          props.item.extensionMonths = values.extensionMonths?.toString() === '' ? null : values.extensionMonths;
          props.item.validity = values.validity;
          props.item.expirationDate = values.expirationDate?.toISOString() ?? null;
          props.item.description = values.description;
          props.item.state = values.state;
          props.item.paymentProfiles = undefined; // We don't EVER modify payment profile list
          await PaymentController.promoCodeSave(props.item);
          setError(false);
          navigation.unpop();
        }
        toast.dark(<ToastSucess msg={'OK'} />);
        if (props.notify) { props.notify(props.item) };
      } catch {
        setError(true);
      }
    }
  });

  const isNewPromoCode = () => {
    return props.item === undefined;
  }


  return (
    <React.Fragment>
      <Section
        title={isNewPromoCode() ? 'Create a new Promo Code' : 'Edit a Promo Code'}
        subtitle={isNewPromoCode() && 'It will be activated as soon as createad'}
        childrenFullWidth={!isNewPromoCode()}
      >
        <form className='box' onSubmit={formik.handleSubmit} >
          <Heading>General</Heading>
          <OwardFormInput
            id='code'
            label='Code *'
            formik={formik}
          />
          <OwardFormInput
            id='premiumLevel'
            label='Premium Level'
            type='number'
            description='Empty for all Premium Levels | 10 : PRO | 100 : PORTFOLIO | 1000 : COMPANY'
            placeholder='All premium levels'
            formik={formik}
          />
          <OwardFormInput
            id='reductionPercentage'
            label='Reduction %'
            type='number'
            description='Empty if no price reduction'
            placeholder='No price reduction'
            formik={formik}
          />
          <OwardFormInput
            id='extensionMonths'
            label='Months extension'
            type='number'
            description={'Empty if no extension | If >1000 months, show \'Lifetime subscrition\''}
            placeholder='No extension'
            formik={formik}
          />
          <ReductionPreview
            paymentOption={PAYMENT_OPTION.PRO}
            premiumLevelConcerned={formik.values.premiumLevel}
            reductionPercentage={formik.values.reductionPercentage}
            extensionMonths={formik.values.extensionMonths}
          />
          <ReductionPreview
            paymentOption={PAYMENT_OPTION.PORTFOLIO}
            premiumLevelConcerned={formik.values.premiumLevel}
            reductionPercentage={formik.values.reductionPercentage}
            extensionMonths={formik.values.extensionMonths}
          />
          <ReductionPreview
            paymentOption={PAYMENT_OPTION.COMPANY}
            premiumLevelConcerned={formik.values.premiumLevel}
            reductionPercentage={formik.values.reductionPercentage}
            extensionMonths={formik.values.extensionMonths}
          />
          <div style={{ height: '2rem' }} />
          <OwardFormSelect
            id='validity'
            label='Validity *'
            isNotClearable
            options={Object.values(PromoCodeValidity).map((validity) => {
              return ({ value: validity, label: validity });
            })}
            formik={formik}
          />
          <OwardFormDatePicker
            id='expirationDate'
            label='Expiration Date'
            isClearable
            formik={formik}
          />
          <OwardFormTextArea
            id='description'
            label='Description'
            placeholder=''
            formik={formik}
            rows={3}
          />
          {
            !isNewPromoCode() &&
            <React.Fragment>
              <Divider />
              <OwardFormSelect
                id='state'
                label='State *'
                isNotClearable
                options={Object.values(State).map((state) => {
                  return ({ value: state, label: state.concat(' ', stateToEmoji(state)) });
                })}
                formik={formik}
              />
              <div style={{ height: '5rem' }} />
            </React.Fragment>
          }
          <Divider />
          <SubmitOrCancel error={error} noCancel={props.noCancel || isNewPromoCode()} />

        </form >
      </Section >
      {
        !isNewPromoCode() &&
        <React.Fragment>
          <Divider />
          <Heading>PAYMENT MADE WITH THIS CODE</Heading>
          <p>{`List of ALL payment made with code '${props.item.code}'. WARNING LIST NOT AUTOMATICALLY RELOADED`}</p>
          <PaymentProfileList paymentProfiles={props.item?.paymentProfiles} />
        </React.Fragment>
      }
    </React.Fragment>
  )
});

interface ReductionPreviewProps {
  paymentOption: PAYMENT_OPTION;
  premiumLevelConcerned: number;
  reductionPercentage: number;
  extensionMonths: number;
}

export const ReductionPreview: React.FC<ReductionPreviewProps> = props => {
  const { t, locale } = useTranslate();

  return (
    <p>
      {
        `${props.paymentOption} with promo code :
        ${priceWithReductionString(props.paymentOption, locale, props.reductionPercentage, props.premiumLevelConcerned)} /
        ${durationWithExtensionString(props.paymentOption, locale, props.extensionMonths, props.premiumLevelConcerned, t('account.profiles.subscription_lifetime'))}
        `
      }
    </p>
  );
}
