import React, { useState, useEffect } from 'react';
import { send, Profile, ProfileController, User, Shortlist, ShortlistProfile, State, ProfileType } from '@oward/openapi';

import { EditButton, PaginatedListFilterType, FormComment, PaginatedList, PaginatedListKey, Section } from '@components/admin';
import { EditProfile } from './EditProfile';
import { findTranslationOrDefault, strToPath, stateToEmoji, visibilityToEmoji } from '@oward/common-utils';
import moment from 'moment';
import { hasPortfolioPublic, isProfileExposed, isProfileExposedToEmoji } from '@utils/.';
import { useTranslate } from '@hooks/.';
import { Visibility } from '@oward/common-enums';

export const ProfileList: React.FC = () => {

  const { locale } = useTranslate();
  const [items, setItems] = useState([] as Profile[]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = async () => {
    try {
      setLoading(true);
      setError(false);
      setItems(await send(ProfileController.find()))
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const keys: PaginatedListKey<Profile>[] = [
    {
      label: 'Id',
      value: item => item.id
    },
    {
      label: 'Name',
      value: item => item.name.concat(' ', visibilityToEmoji(item.visibility), stateToEmoji(item.state), isProfileExposedToEmoji(item), hasPortfolioPublic(item) ? '🚀' : '')
    },
    {
      label: 'User',
      value: item => item.user?.email
    },
    {
      label: '# of artworks',
      value: item => item.artworks.length
    },
    {
      label: 'Profile Type',
      value: item => item.type
    },
    {
      label: 'Premium Level',
      value: item => item.premiumLevel
    },
    {
      label: 'Expiration Date',
      value: item => moment(item.premiumEndDate).format('DD MMM yyyy'),
      sortValue: item => Date.parse(item.premiumEndDate)
    },
    {
      label: 'Creation Date',
      value: item => moment(item.creationDate).format('DD MMM yyyy'),
      sortValue: item => Date.parse(item.creationDate)
    },
    {
      label: 'Update Date',
      value: item => moment(item.updateDate).format('DD MMM yyyy'),
      sortValue: item => Date.parse(item.updateDate)
    },
  ];

  const filterItemStrings = (item: Profile) => [item.id.toString(), strToPath(item.name), strToPath(item.user?.email)];

  const filterComponents = [
    {
      name: 'only \'Exposed\'',
      type: PaginatedListFilterType.ON_OFF,
      filterFunction: (item: Profile) => isProfileExposed(item)
    },
    {
      name: 'only \'NOT Exposed\'',
      type: PaginatedListFilterType.ON_OFF,
      filterFunction: (item: Profile) => !isProfileExposed(item)
    },
    {
      name: 'only \'Portfolio Published\'',
      type: PaginatedListFilterType.ON_OFF,
      filterFunction: (item: Profile) => hasPortfolioPublic(item)
    },
    {
      name: 'only \'Portfolio NOT Published\'',
      type: PaginatedListFilterType.ON_OFF,
      filterFunction: (item: Profile) => !hasPortfolioPublic(item)
    },
    {
      name: 'only \'Expired\'',
      type: PaginatedListFilterType.ON_OFF,
      filterFunction: (item: Profile) => item.premiumLevel > 0 && Date.parse(item.premiumEndDate) < new Date().getTime()
    },
    {
      name: 'number of Artworks',
      type: PaginatedListFilterType.INPUT,
      filterFunction: (item: Profile, value: string) => item.artworks.length === parseInt(value)
    },
    {
      name: 'State',
      type: PaginatedListFilterType.DROPDOWN,
      options: Object.values(State).map((state) => {
        return ({ value: state, label: state });
      }),
      filterFunction: (item: Profile, value: string) => item.state === value
    },
    {
      name: 'Visibility',
      type: PaginatedListFilterType.DROPDOWN,
      options: Object.values(Visibility).map((visibility) => {
        return ({ value: visibility, label: visibility });
      }),
      filterFunction: (item: Profile, value: string) => item.visibility === value
    },
    {
      name: 'Profile type',
      type: PaginatedListFilterType.DROPDOWN,
      options: Object.values(ProfileType).map((type) => {
        return ({ value: type, label: type });
      }),
      filterFunction: (item: Profile, value: string) => item.type === value
    }
  ];

  const fetchOne = (item: Profile) => send(ProfileController.findOneAdmin(item.id, locale));

  return (
    <React.Fragment>
      <Section
        title='List'
        subtitle='The list of all profiles in the database'
        childrenFullWidth
      >
        <FormComment>
          <p>✅ = Exposed in the gallery | ❌ = NOT exposed</p>
          <p>🚀 = Portfolio Published</p>
          <p>🔒 = Private Profile</p>
        </FormComment>
        <PaginatedList
          keys={keys}
          items={items}
          filterItemStrings={filterItemStrings}
          filterComponents={filterComponents}
          size={100}
          editComponent={EditProfile}
          fetchOne={fetchOne}
          reloadList={loadItems}
          addContactButton
          loading={loading}
          error={error}
        />
      </Section>
    </React.Fragment>
  );
}

interface ProfileListForUserProps {
  user: User;
}

export const ProfileListForUser: React.FC<ProfileListForUserProps> = props => {

  const { locale } = useTranslate();

  const fetchOne = (item: Profile) => send(ProfileController.findOneAdmin(item.id, locale));

  return (
    <React.Fragment>
      {
        props.user.profiles?.length > 0 ?
          <table className='table is-fullwidth'>
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Premium Level</th>
                <th>Premium End Date</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {

                props.user.profiles?.map((profile: Profile, i) =>
                  <tr key={i}>
                    <td><p>
                      {profile.id}
                    </p></td>
                    <td><p>
                      {profile.name.concat(' ', stateToEmoji(profile.state))}
                    </p></td>
                    <td><p>
                      {profile.premiumLevel.toString()}
                    </p></td>
                    <td><p>
                      {moment(profile.premiumEndDate).format('DD MMM yyyy')}
                    </p></td>
                    <td><EditButton
                      component={EditProfile}
                      fetchOne={fetchOne}
                      item={profile}
                      label={'Edit profile'}
                    /></td>
                  </tr>
                )

              }
            </tbody>
          </table>
          :
          <p>No profile yet</p>
      }
    </React.Fragment>
  );
}

interface ProfileListForShortlistProps {
  shortlist: Shortlist;
}

export const ProfileListForShortlist: React.FC<ProfileListForShortlistProps> = props => {

  const { locale } = useTranslate();

  const fetchOne = (item: Profile) => send(ProfileController.findOneAdmin(item.id, locale));

  return (
    <React.Fragment>
      {
        props.shortlist.shortlistProfiles?.map(sp => sp.profile)?.length > 0 ?
          <table className='table is-fullwidth'>
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Job</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {
                props.shortlist.shortlistProfiles?.map((sp: ShortlistProfile, i) =>
                  <tr key={i}>
                    <td><p>
                      {sp.profile.id}
                    </p></td>
                    <td><p>
                      {sp.profile.name.concat(' ', stateToEmoji(sp.profile.state))}
                    </p></td>
                    <td><p>
                      {findTranslationOrDefault(sp.profile.job, 'fr')}
                    </p></td>
                    <td><EditButton
                      component={EditProfile}
                      fetchOne={fetchOne}
                      item={sp.profile}
                      label={'Edit profile'}
                    /></td>
                  </tr>
                )
              }
            </tbody>
          </table>
          :
          <p>No profile yet</p>
      }
    </React.Fragment>
  );
}
