import React from 'react';
import styles from './Login.module.scss';
import LoginForm from './LoginForm';
import { useTranslate } from '@hooks/.';
import { LayoutPageImageOnLeft } from '@components/.';

interface LoginPageProps {
  title?: string;
  reloadSameUrl?: boolean;
}

export const LoginPage: React.FC<LoginPageProps> = props => {

  return (
    <React.Fragment>
      <LayoutPageImageOnLeft
        imageComponent={<LoginImage title={props.title} />}
        rightComponent={<LoginForm title={props.title} reloadSameUrl={props.reloadSameUrl} />}
      />
    </React.Fragment >
  );
}

interface LoginImageProps {
  title?: string;
}
const LoginImage: React.FC<LoginImageProps> = props => {
  const { t } = useTranslate();

  return (
    <div className={styles.imageContainer}>
      <p className={styles.text}>{props.title ?? t('login.login_image_text')}</p>
      <p className={`${styles.credits}`}>
        <a
          className={`${styles.link}`}
          href='https://www.oward.co/fr/p/fulesaulnieretienne'
          target='_blank'
          rel='noreferrer'
        >
          @FuLeSaulnierEtienne
        </a>
      </p>
    </div>
  )
}
