import React, { ReactNode, useState } from 'react';
import styles from './Select.module.scss';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import classNames from 'classnames';
import {
  Festival, FestivalAward,
  Job, JobTranslation, LocationController, Location, GeocodeLocation,
  send,
  Association,
  Broadcaster,
  Film,
  Agent,
  Gender,
  LocationCountry
} from '@oward/openapi';
import { useTranslate } from '@hooks/.';
import { findTranslationOrDefault, getFranceLocation, festivalToStr, GalleryType, ProfileType } from '@oward/common-utils';
import { filmToStr, geocodeLocationToStr, locationToStr, onTouchDevice } from '@utils/.';
import { Label } from '@components/.';

export class SelectOption { value: any; label: string; labelBis?: string; type?: string };
export class SelectGroup { label: string; options: SelectOption[] };

export const OwardSelectStyles = {
  menu: (provided: any) => ({
    ...provided,
    zIndex: 6, // To be above Bulma Input Icon and NeedPortfolio stuff
    borderRadius: `${styles.borderRadius}`,
  }),
  menuList: (provided: any) => ({
    ...provided,
    borderRadius: `${styles.borderRadius}`,
    padding: '0.5rem',
  }),
  option: (provided: any) => ({
    ...provided,
    color: `${styles.greyDark}`,
    borderRadius: `${styles.borderRadius}`,
    fontSize: `${styles.dropdownFontSize}`,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${styles.blue30}`
    },
    '&:active': {
      backgroundColor: `${styles.blue50}`
    },

  }),
  noOptionsMessage: (provided: any) => ({
    ...provided,
    color: `${styles.greyMid}`,
    fontSize: `${styles.dropdownFontSize}`
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: `${styles.greyMidLight}`,
    fontSize: `${styles.dropdownFontSize}`,
    textAlign: 'right',
  }),
  groupHeading: (provided: any) => ({
    ...provided,
    color: `${styles.greyDark}`,
    fontSize: `${styles.dropdownGroupFontSize}`,
    fontWeight: `${styles.semiBold}`
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    '&:hover': {
      cursor: 'pointer',
    }
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: `${styles.borderRadius}`,
    fontSize: `${styles.dropdownFontSize}`,
    border: state.isFocused ? 0 : `1px solid ${styles.greyLight}`,
    boxShadow: state.isFocused ? `0 0 0 2px ${styles.blue}` : 0,
    '&:hover': {
      cursor: 'text',
      border: state.isFocused ? 0 : `1px solid ${styles.greyMid}`,
    },
  }),
  multiValue: (provided: any) => ({
    ...provided,
    borderRadius: '1rem',
    padding: '2px',
    backgroundColor: 'transparent',
    border: `solid thin ${styles.greyLight}`

  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    borderRadius: '1rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${styles.greyMid}`,
      color: 'white'
    }
  })
}

export const OwardFilterSelectStyles = {
  ...OwardSelectStyles,
  placeholder: (provided: any) => ({
    ...provided,
    color: `${styles.greyMid}`,
    fontSize: `${styles.dropdownFontSize}`,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: `${styles.borderRadius}`,
    fontSize: `${styles.dropdownFontSize}`,
    border: state.isFocused ? 0 :
      (state.selectProps.value !== null ? `1px solid ${styles.blue}` : `1px solid ${styles.greyLight}`),
    boxShadow: state.isFocused ? `0 0 0 2px ${styles.blue}` : 0,
    '&:hover': {
      cursor: 'text',
      border: state.isFocused ? 0 :
        (state.selectProps.value !== null ? `1px solid ${styles.blueLogo}` : `1px solid ${styles.greyMid}`),
    }
  }),
}

export const OwardHeaderFilterSelectStyles = {
  ...OwardFilterSelectStyles,
  menu: (provided: any) => ({
    ...provided,
    zIndex: 6, // To be above Bulma Input Icon and NeedPortfolio stuff
    minWidth: styles.headerFiltersMenuWidth, // Fix the dropdown menu size
    borderRadius: `${styles.borderRadius}`,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: `${styles.borderRadius}`,
    fontSize: `${styles.dropdownFontSize}`,
    height: '3rem',
    border: state.isFocused ? 0 :
      (state.selectProps.value !== null ? `1px solid ${styles.blue}` : `1px solid ${styles.greyLight}`),
    boxShadow: state.isFocused ? `0 0 0 2px ${styles.blue}` : 0,
    '&:hover': {
      cursor: 'text',
      border: state.isFocused ? 0 :
        (state.selectProps.value !== null ? `1px solid ${styles.blueLogo}` : `1px solid ${styles.greyMid}`),
    }
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    fontWeight: `${styles.book}`,
    color: `${styles.greyDarker}`,
    justifyContent: state.selectProps.value !== null ? '' : 'center', // Center placeholder
    alignItems: 'center',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: `${styles.greyDarker}`,
    fontSize: `${styles.dropdownFontSize}`,
  }),
}

interface OwardSelectProps {
  label?: string;
  description?: string | ReactNode;
  noOptionMsg?: string;
  placeholder?: string;
  options?: SelectOption[];
  isMulti?: boolean;
  isClearable?: boolean;
  isNotClearable?: boolean;
  onChange: (option: any) => void; // Can provide a handler on top of formik (ex: to preview profile in Edit Profile)
  value: any;
}

export const OwardSelect: React.FC<OwardSelectProps> = props => {
  const { t } = useTranslate();

  return (
    <div style={{ width: '100%' }}>
      {
        props.label &&
        <Label name={props.label} description={props.description} />
      }
      <div className={classNames('control')}>
        <Select
          label={props.label}
          options={props.options}
          noOptionsMessage={() => props.noOptionMsg ?? t('forms.select.no_options')}
          placeholder={props.placeholder ?? t('forms.select.placeholder')}
          styles={OwardSelectStyles}
          isClearable={props.isClearable}
          isMulti={props.isMulti}
          onChange={(option: SelectOption) => { props.onChange(option?.value) }}
          value={props.value === undefined ? undefined :
            {
              value: props.value,
              label: (props.options?.find((option: SelectOption) => option.value === props.value)?.label)
            }}
        />
      </div>
    </div>
  );
}

interface OwardFormSelectProps {
  id: string;
  label?: string;
  description?: string | ReactNode;
  noOptionMsg?: string;
  placeholder?: string;
  options?: SelectOption[];
  isMulti?: boolean;
  isClearable?: boolean;
  isNotClearable?: boolean;
  formik: any;
  onChange?: (option: any) => void; // Can provide a handler on top of formik (ex: to preview profile in Edit Profile)
}

export const OwardFormSelect: React.FC<OwardFormSelectProps> = props => {
  const { t } = useTranslate();

  const handleChange = (selectedOption: SelectOption) => {
    props.formik.setFieldValue(props.id, selectedOption?.value);
  }

  return (
    <div className='field' style={{ width: '100%', paddingBottom: '1rem' }}>
      {
        props.label &&
        <Label name={props.label} description={props.description} />
      }
      <div className={classNames('control')}>
        <Select
          label={props.label}
          options={props.options}
          noOptionsMessage={() => props.noOptionMsg ?? t('forms.select.no_options')}
          placeholder={props.placeholder ?? t('forms.select.placeholder')}
          styles={OwardSelectStyles}
          isClearable={(props.isClearable || props.formik.initialValues[props.id] === undefined) && !props.isNotClearable}
          isMulti={props.isMulti}
          onChange={(option: SelectOption) => { handleChange(option); props.onChange && props.onChange(option?.value) }}
          value={props.formik.values[props.id] === undefined ? undefined :
            {
              value: props.formik.values[props.id],
              label: (props.options?.find((option: SelectOption) => option.value === props.formik.values[props.id]))?.label
            }
          }
        />
      </div>
      <ErrorMsg id={props.id} formik={props.formik} />
    </div>
  );
}

OwardFormSelect.defaultProps = {
} as Partial<OwardFormSelectProps>;

interface OwardFormSelectLoadProps extends OwardFormSelectProps {
  fetchFunction: any;
  entitiesToOptions?: (entities: any[]) => any[];
  entityToStr?: (entity: any) => string;
  reload?: boolean; // reload list each time clicking the dropdown
}

export const OwardFormSelectLoad: React.FC<OwardFormSelectLoadProps> = props => {
  const { t, locale } = useTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [options, setOptions] = useState(undefined);

  const handleChange = (selectedOption: SelectOption) => {
    props.formik.setFieldValue(props.id, selectedOption?.value);
  }

  const handleLoadOptions = async () => {
    if (options === undefined || props.reload) {
      try {
        setIsLoading(true);
        setIsError(false);
        let entities: any = await send(props.fetchFunction);
        setOptions(
          props.entitiesToOptions ?
            props.entitiesToOptions(entities)
            :
            entitiesToOptions(entities)
        );
        setIsLoading(false);
      }
      catch (err) {
        console.error(err);
        setIsError(true);
      }
      finally {
        setIsLoading(false);
      }
    }
  }

  return (
    <div className='field' style={{ width: '100%', paddingBottom: '1rem' }}>
      {
        props.label &&
        <Label name={props.label} description={props.description} />
      }
      <div className={classNames('control')}>
        <Select
          label={props.label}
          instanceId={props.id}
          onFocus={handleLoadOptions}
          options={options}
          placeholder={props.placeholder ?? t('forms.select.placeholder')}
          noOptionsMessage={() => props.noOptionMsg ?? (isError ? t('global.error_retry') : t('forms.select.no_options'))}
          styles={OwardSelectStyles}
          isClearable={(props.isClearable || props.formik.initialValues[props.id] === undefined) && !props.isNotClearable}
          isLoading={isLoading}
          loadingMessage={() => t('global.loading')}
          onChange={value => { handleChange(value); props.onChange && props.onChange(value.value) }}
          value={props.formik.values[props.id] === undefined ? undefined :
            {
              value: props.formik.values[props.id],
              label: props.entityToStr ?
                props.entityToStr(props.formik.values[props.id])
                : findTranslationOrDefault(props.formik.values[props.id], locale)
            }
          }
        />
      </div>
      <ErrorMsg id={props.id} formik={props.formik} />
    </div>
  )
}

export const OwardFormSelectLoadMulti: React.FC<OwardFormSelectLoadProps> = props => {
  const { t, locale } = useTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [entities, setEntities] = useState<any>(undefined);
  const [options, setOptions] = useState<SelectOption[]>(undefined);

  const getValuesFromOptions = async (selectedOptions: SelectOption[]): Promise<any[]> => {
    // Fetch the entities if not already loaded in State
    // (can happen if removing an item before opening the dropdown)
    let entityList: any[]
    if (entities === undefined) { entityList = await getEntities(); }
    else { entityList = entities; }
    // Construct an entity table with ID matching the selection option ID
    // If no selected option, use an empty array
    return (props.id, selectedOptions === null ? [] :
      entityList.filter(
        entity => selectedOptions.map(option => option.value).includes(entity.id)
      )
    );
  }

  const handleChange = async (selectedOptions: SelectOption[]) => {
    // IMPORTANT to NOT fetch values directly as a setFieldValue paramater
    // -> it will create weird stuff, get values in a variable and pass set AFTER
    // as a paramter to setFieldValue
    let valuesToSet: any[] = await getValuesFromOptions(selectedOptions);
    props.formik.setFieldValue(props.id, valuesToSet);
  }

  // Get entities using the provided fetch function, and put it in state
  const getEntities = async (): Promise<any[]> => {
    if (entities === undefined || props.reload) {
      try {
        setIsLoading(true);
        setIsError(false);
        let entityList: any[] = await send(props.fetchFunction)
        setEntities(entityList);
        setIsLoading(false);
        return entityList;
      }
      catch (err) {
        console.error(err);
        setIsError(true);
      }
      finally {
        setIsLoading(false);
      }
    }
    else {
      return entities;
    }
  }

  const handleLoadOptions = async () => {
    if (options === undefined || props.reload) {
      // Fetch the entities if not already loaded in State
      let entityList: any[]
      if (entities === undefined || props.reload) { entityList = await getEntities(); }
      else { entityList = entities; }
      // WARNING, for multi, when setting option we use entites ID
      // (not entities directly)
      setOptions(
        props.entitiesToOptions ?
          props.entitiesToOptions(entityList)
          :
          entityList?.map(entity => {
            return {
              value: entity.id,
              label: findTranslationOrDefault(entity, locale)
            }
          })
      );
    }
  }

  return (
    <div className='field' style={{ width: '100%', paddingBottom: '1rem' }}>
      {
        props.label &&
        <Label name={props.label} description={props.description} />
      }
      <div className={classNames('control')}>
        <Select
          label={props.label}
          instanceId={props.id}
          onFocus={handleLoadOptions}
          options={options}
          isMulti
          placeholder={props.placeholder ?? t('forms.select.placeholder')}
          noOptionsMessage={() => props.noOptionMsg ?? (isError ? t('global.error_retry') : t('forms.select.no_options'))}
          styles={OwardSelectStyles}
          isClearable={!props.isNotClearable && (props.formik.initialValues[props.id] === undefined || props.isClearable)}
          isLoading={isLoading}
          loadingMessage={() => t('global.loading')}
          onChange={async (options: SelectOption[]) => { handleChange(options); props.onChange && props.onChange(await getValuesFromOptions(options)) }}
          defaultValue={
            props.formik.initialValues[props.id] !== undefined &&
            props.formik.initialValues[props.id]?.map(entity => {
              return {
                value: entity.id,
                label: findTranslationOrDefault(entity, locale)
              }
            })
          }
        />
      </div>
      <ErrorMsg id={props.id} formik={props.formik} />
    </div>
  )
}

export const OwardFormSelectLocation: React.FC<OwardFormSelectProps> = props => {
  const { t, locale } = useTranslate();

  const [selectedLocationStr, setSelectedLocationStr] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isInputTooShort, setIsInputTooShort] = useState(false);

  const handleChange = async (selectedOption: SelectOption) => {
    setSelectedLocationStr(geocodeLocationToStr(selectedOption?.value));
    try {
      setIsError(false);
      setIsLoading(true);
      let location: Location = await send(
        LocationController.createFromGeocode(selectedOption?.value)
      );
      props.formik.setFieldValue(props.id, location);
      props.onChange && props.onChange(location);
    }
    catch (err) {
      setIsError(true);
    }
    finally {
      setIsLoading(false);
    }
  }

  const handleLoadOptions = async (inputValue: string) => {
    if (inputValue === '') {
      return;
    }
    if (inputValue.length < 3) {
      setIsInputTooShort(true);
      return
    }
    setIsInputTooShort(false);
    try {
      setIsError(false);
      let entities: GeocodeLocation[] = await getFranceLocation(inputValue, 10);
      return geocodeLocationsToOptions(entities);
    }
    catch (err) {
      console.error(err);
      setIsError(true);
    }
  }

  const getNoOptionsMgs = () => {
    if (isError) {
      return t('global.error_retry');
    }
    if (isInputTooShort) {
      return t('forms.select.at_least_3_chars');
    }
    return props.noOptionMsg ?? t('forms.select.no_options_location');
  }

  return (
    <div className='field' style={{ width: '100%', paddingBottom: '1rem' }}>
      <Label name={props.label} description={props.description} />
      <div className={classNames('control')}>
        <AsyncSelect
          label={props.label}
          instanceId={props.id}
          loadOptions={handleLoadOptions}
          cacheOptions
          placeholder={props.placeholder ?? t('forms.select.placeholder_location')}
          noOptionsMessage={getNoOptionsMgs}
          styles={OwardSelectStyles}
          isLoading={isLoading}
          loadingMessage={() => t('global.loading')}
          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
          onChange={handleChange}
          value={props.formik.values[props.id] === undefined ? undefined :
            {
              value: props.formik.values[props.id],
              label: isLoading ?
                selectedLocationStr
                :
                locationToStr(props.formik.values[props.id] as Location, locale)
            }
          }
        />
      </div>
      <ErrorMsg id={props.id} formik={props.formik} />
    </div>
  )
}

interface ErrorMsgProps {
  id: string;
  formik: any;
}
const ErrorMsg: React.FC<ErrorMsgProps> = props => {
  return (
    <React.Fragment>
      {
        (props.formik.touched[props.id] && props.formik.errors[props.id]) &&
        <div className={styles.errorContainer}>
          <p className={styles.error}>{props.formik.errors[props.id]}</p>
        </div>
      }
    </React.Fragment>
  )
}
/*
 * Utilities functions that transform object to SelectOptions or SelectGroups
 * for react-select dropdowns.
 */

export const entitiesToOptions = (entities: any[], locale?: string, sorted?: boolean): SelectOption[] => {
  let options: SelectOption[] = new Array<SelectOption>();
  if (sorted) {
    entities.sort((a, b) => findTranslationOrDefault(a, locale).localeCompare(findTranslationOrDefault(b, locale), locale));
  }
  entities.map((entity) => {
    let option: SelectOption = new SelectOption();
    option.value = entity;
    option.label = findTranslationOrDefault(entity, locale);
    options.push(option);
  });
  return options;
}

export const entitiesToOptionsWithTranslation = (entities: any[], locale: string, sorted?: boolean): SelectOption[] => {
  return entitiesToOptions(entities, locale, sorted);
}

export const jobsToOptions = (jobs: Job[], useEntityId = false): SelectGroup[] => {
  return entitiesToGroupedOption(jobs, 'category', useEntityId, true);
}

export const jobsBothGenderToOptions = (jobs: Job[], useEntityId = false): SelectGroup[] => {
  return entitiesToGroupedOption(jobs, 'category', useEntityId, true, true);
}

export const associationsToOptions = (associations: Association[]): SelectGroup[] => {
  return entitiesToGroupedOption(associations, 'country', true);
}

export const agentsToOptions = (agents: Agent[], useEntityId = false): SelectGroup[] => {
  return entitiesToGroupedOption(agents, 'category', useEntityId);
}

export const broadcastersToOptions = (broadcasters: Broadcaster[], useEntityId = false): SelectGroup[] => {
  return entitiesToGroupedOption(broadcasters, 'category', useEntityId);
}

// Use Entity ID for Multi Select
const entitiesToGroupedOption = (
  entities: any[],
  entityProperty: string,
  useEntityId = false,
  useEntityTranslation = false,
  useBothGender = false,
): SelectGroup[] => {
  let groups: SelectGroup[] = new Array<SelectGroup>();
  let activeOptionArray: SelectOption[];
  let groupName: string = '';

  entities?.map((entity) => {
    if (entity[entityProperty].translations[0]?.name !== groupName) {
      groupName = entity[entityProperty].translations[0]?.name;
      let alreadyExistingGroup = groups.find(group => group.label === groupName);
      if (alreadyExistingGroup) {
        activeOptionArray = alreadyExistingGroup.options;
      }
      else {
        let group: SelectGroup = new SelectGroup();
        groups.push(group);
        group.label = groupName;
        group.options = new Array<SelectOption>();
        activeOptionArray = group.options;
      }
    }

    // For job listing, showing both genders options (i.e. male & female) as seprate options
    if (useBothGender) {
      entity.translations?.map((translation: JobTranslation) => {
        // Handle case where a job is the same for two genders (ex: 'Acteur' & 'Actrice')
        if (activeOptionArray.find(option => option.label === translation.name)) { return; }
        let jobEntity: Job = { ...entity } as Job;
        let option: SelectOption = new SelectOption();
        jobEntity.translations = jobEntity.translations.filter(
          translationValue => translationValue.gender === translation.gender
        );
        option.value = useEntityId ? jobEntity.id : jobEntity;
        option.label = translation.name;
        activeOptionArray.push(option);
      });
    }
    else {
      // For multi select, we use the entity ID as value
      let option: SelectOption = new SelectOption();
      option.value = useEntityId ? entity.id : entity;
      option.label = useEntityTranslation ? entity.translations[0]?.name : entity.name;
      activeOptionArray.push(option);
    }
  });
  groups.sort((a, b) => a.label.localeCompare(b.label))
  return groups;
}

export const filmToOptions = (films: Film[], inDevStr: string): SelectOption[] => {
  let options: SelectOption[] = new Array<SelectOption>();

  films.map((film) => {
    let option: SelectOption = new SelectOption();
    option.value = film;
    option.label = filmToStr(film, inDevStr);
    options.push(option);
  });
  return options;
}

export const festivalToOptions = (entities: Festival[], locale: string): SelectOption[] => {
  let options: SelectOption[] = new Array<SelectOption>();

  entities.map((festival) => {
    let option: SelectOption = new SelectOption();
    option.value = festival;
    option.label = festivalToStr(festival, locale);
    options.push(option);
  });
  return options;
}

export const countryToCodeOptions = (entities: LocationCountry[], locale: string): SelectOption[] => {
  let options: SelectOption[] = new Array<SelectOption>();

  entities.sort((a, b) => findTranslationOrDefault(a, locale).localeCompare(findTranslationOrDefault(b, locale), locale));

  entities.map((country) => {
    let option: SelectOption = new SelectOption();
    option.value = country;
    option.label = findTranslationOrDefault(country, locale);
    options.push(option);
  });
  return options;
}

export const festivalAwardToOptions = (entities: FestivalAward[], locale: string, festivalId?: number): SelectOption[] => {
  let options: SelectOption[] = new Array<SelectOption>();

  entities.map((festivalAward) => {
    let option: SelectOption = new SelectOption();
    option.value = festivalAward;
    if (festivalAward.name === festivalAward.category?.name) {
      // It's a 'generic' award (i.e. 'Best Movie')
      option.label = findTranslationOrDefault(festivalAward.category, locale);
    }
    else {
      // It's a 'specific' award (i.e. 'Palme d'Or')
      option.label = findTranslationOrDefault(festivalAward, locale).concat(' (', findTranslationOrDefault(festivalAward.category, locale), ')');
      if (festivalId && festivalId !== festivalAward.festival?.id) {
        // If a specific festival is specified, show 'specific' awards of specified festival only
        return;
      }
    }
    options.push(option);
  });
  return options;
}

export const geocodeLocationsToOptions = (locations: GeocodeLocation[]): SelectOption[] => {
  let options: SelectOption[] = new Array<SelectOption>();

  // Don't duplicate cities
  let cityList: string[] = new Array<string>();

  locations.map((location) => {
    if (cityList.includes(location.city)) {
      return;
    }
    cityList.push(location.city);
    let option: SelectOption = new SelectOption();
    option.value = location;
    option.label = location.city.concat(' (', location.region, ') - ', location.country);
    options.push(option);
  });

  return options;
}

export const jobLabelName = (profileType: string | ProfileType): string => {
  switch (profileType) {
    case ProfileType.COMPANY:
      return 'sidebar.search_profile.company_type';
    default:
      return 'sidebar.search_profile.job';
  }
}

export const jobFilterName = (galleryType: string | GalleryType): string => {
  switch (galleryType) {
    case GalleryType.COMPANY:
      return 'sidebar.search_profile.company_type';
    default:
      return 'sidebar.search_profile.job';
  }
}

export const jobCategoryFilterName = (galleryType: string | GalleryType): string => {
  switch (galleryType) {
    case GalleryType.COMPANY:
      return 'sidebar.search_profile.company_type_category';
    default:
      return 'sidebar.search_profile.job_category';
  }
}
