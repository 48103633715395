import React from 'react';
import styles from './ProfilesPage.module.scss';
import moment from 'moment';
import { ModalKey, ModalStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { Profile, State, Visibility } from '@oward/openapi';
import { useInjection, useTranslate } from '@hooks/.';
import { findTranslationOrDefault, PREMIUM_LEVEL, priceToString } from '@oward/common-utils';
import { hasActiveSubscription, hasPortfolioPublic, isProfileExposed, oniOSWebview, premiumEndDateToString, premiumLevelToString, remSizeToPixelNumber, subscriptionIncomplete, subscriptionLatePayment, subscriptionNeedPayment, subscriptionUnpaid } from '@utils/.';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { TitlePage, OwardButton, Link } from '@components/.';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { LayoutPage } from '@components/.';
import { OwardLinkButton } from '@components/Core';

interface AccountProfilesProps {
  profiles: Profile[];
  portfolioWording?: boolean;
}

export function AccountProfilesPage(props: AccountProfilesProps) {
  const { t } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  return (
    <React.Fragment>
      <LayoutPage
        title={props.portfolioWording ? t('account.portfolios.title') : t('account.profiles.title')}
        subtitle={!props.portfolioWording && t('account.profiles.subtitle')}
      >
        {
          (props.profiles && props.profiles.length > 0) ?
            props.profiles.map((profile: Profile, i) => {
              return (
                <ProfileLine profile={profile} portfolioWording={props.portfolioWording} key={i} />
              );
            })
            :
            <p className='has-text-centered' style={{ marginBottom: '2rem' }}>
              {t('account.profiles.no_profile')}
            </p>
        }
        <div className={styles.buttonContainer} >
          <OwardButton
            name={t('account.profiles.create_new')}
            outlined
            onClick={() => modalStore.openModalNewStack(router, ModalKey.NEW_PROFILE, 1)}
          />
        </div>
      </LayoutPage>
    </React.Fragment>
  )
}

interface ProfileLineProps {
  profile: Profile;
  portfolioWording?: boolean;
}

function ProfileLine(props: ProfileLineProps) {
  const { t, locale } = useTranslate();

  return (
    <Link
      href={`/p/[profilePath]`}
      as={`/p/${props.profile.path}`}
    >
      <div
        className={`${styles.profileContainer}`}
      >
        <div className={classNames('columns', styles.profileTopContainer)}>
          <div className={`column ${styles.profileLeftContainer}`}>
            {
              props.profile.avatarUrl &&
              <div className={styles.avatarContainer}>
                <LazyLoadImage
                  src={props.profile.avatarUrl}
                  width={remSizeToPixelNumber(styles.avatarSize)}
                  height={remSizeToPixelNumber(styles.avatarSize)}
                  className={styles.avatar}
                  alt={t('alt.avatar_no_city', {
                    name: props.profile.name,
                    job: findTranslationOrDefault(props.profile?.job, locale)
                  })}
                  effect='blur'
                />
              </div>
            }
            <div className={styles.nameJobContainer}>
              <h2 className={styles.name}>
                {props.profile.name}
              </h2>
              <p className={styles.job}>
                {findTranslationOrDefault(props.profile.job, locale)}
              </p>
            </div>
          </div>
          <div className={`column ${styles.profileRightContainer}`}>
            <ProfileSubscriptionDetails profile={props.profile} withVisibility />
          </div>
        </div>
        <div className={classNames(styles.profileBottomContainer)}>
          <div className={classNames(styles.profileStatusLine)}>
            {
              isProfileExposed(props.profile) ?
                <React.Fragment>
                  <p className={styles.iconContainer}>
                    <span className={`icon`}>
                      <i className={`fas fa-check-circle ${styles.iconSuccess}`}></i>
                    </span>
                  </p>
                  <p className={styles.textContainer}>
                    {t('account.profiles.profile_exposed')}
                  </p>
                </React.Fragment>
                :
                <React.Fragment>
                  <p className={styles.iconContainer}>
                    <span className={`icon`}>
                      <i className={`fas fa-exclamation-circle ${styles.iconError}`}></i>
                    </span>
                  </p>
                  <p className={styles.textContainer}>
                    {t('account.profiles.profile_not_exposed')}
                  </p>
                </React.Fragment>
            }
          </div>
          <div className={classNames(styles.profileStatusLine)}>
            {
              hasPortfolioPublic(props.profile) ?
                <React.Fragment>
                  <p className={styles.iconContainer}>
                    <span className={`icon`}>
                      <i className={`fas fa-check-circle ${styles.iconSuccess}`}></i>
                    </span>
                  </p>
                  <p className={styles.textContainer}>
                    {t('account.profiles.portfolio_published')}
                  </p>
                </React.Fragment>
                :
                <React.Fragment>
                  <p className={styles.iconContainer}>
                    <span className={`icon`}>
                      <i className={`fas fa-exclamation-circle ${styles.iconError}`}></i>
                    </span>
                  </p>
                  <p className={styles.textContainer}>
                    {t('account.profiles.portfolio_not_published')}
                  </p>
                </React.Fragment>
            }
          </div>
        </div>
        <div
          className={classNames(styles.modifyIconContainerDesktop, 'has-tooltip-arrow')}
        >
          <Link href={`/account/profile/[profileId]`} as={`/account/profile/${props.profile.id}`}>
            <OwardLinkButton
              transparentBlue
              name={t('global.edit')}
              data-tooltip={props.portfolioWording ? t('page.profile.edit_portfolio') : t('page.profile.edit_profile')}
            />

          </Link>
        </div>

        <div
          className={styles.modifyIconContainerMobile}
        >
          <Link href={`/account/profile/[profileId]`} as={`/account/profile/${props.profile.id}`}>
            <OwardLinkButton
              name={props.portfolioWording ? t('page.profile.edit_portfolio') : t('page.profile.edit_profile')}
              transparentBlue
            />
          </Link>
        </div>
      </div>
    </Link>
  )
}

interface ProfileSubscriptionDetailsProps {
  profile: Profile;
  withAmount?: boolean;
  withVisibility?: boolean;
}

export const ProfileSubscriptionDetails: React.FC<ProfileSubscriptionDetailsProps> = props => {
  const { t, locale } = useTranslate();

  return (
    <div className={styles.subscriptionDetailsContainer}>
      <p><span className={`has-text-weight-semibold`}>{t('account.profiles.subscription_type')}</span>
        {premiumLevelToString(props.profile.premiumLevel, t)}
      </p>
      {
        (props.profile.premiumLevel > PREMIUM_LEVEL.FREE || subscriptionNeedPayment(props.profile.subscription)) &&
        <React.Fragment>
          {
            (
              (props.profile.subscription && props.profile.subscription.status !== 'active' && props.profile.subscription.status !== 'incomplete_expired')
              &&
              // On iOS App, all links to subscription stuff must be removed
              !oniOSWebview()
            ) &&
            <p className={styles.red}>
              <span className={`has-text-weight-semibold`}>{t('account.profiles.subscription.title')}</span>
              {t(`account.profiles.subscription.${props.profile.subscription.status}`)}
              {
                subscriptionIncomplete(props.profile.subscription) &&
                <Link href='/subscriptions/[profileId]' as={`/subscriptions/${props.profile.id}`} passHref>
                  <span className={styles.link} >{' | ' + t(`account.profiles.subscription.take_back`)}</span>
                </Link>
              }
              {
                subscriptionLatePayment(props.profile.subscription) &&
                <Link href='/profile-subscription-invoice/[profileId]' as={`/profile-subscription-invoice/${props.profile.id}`} passHref>
                  <span className={styles.link} >{' | ' + t(`account.profiles.subscription.settle`)}</span>
                </Link>
              }
              {
                subscriptionUnpaid(props.profile.subscription) &&
                <Link href='/subscriptions/[profileId]' as={`/subscriptions/${props.profile.id}`} passHref>
                  <span className={styles.link} >{' | ' + t(`account.profiles.subscription.settle`)}</span>
                </Link>
              }
            </p>
          }
          {
            (!hasActiveSubscription(props.profile) && props.profile.premiumLevel > PREMIUM_LEVEL.FREE) &&
            <p><span className={`has-text-weight-semibold`}>{t('account.profiles.subscription_end_date')}</span>
              {premiumEndDateToString(props.profile.premiumEndDate, t('account.profiles.subscription_lifetime'), locale)}
            </p>
          }
          {
            props.withAmount &&
            <React.Fragment>
              {
                hasActiveSubscription(props.profile) &&
                <React.Fragment>
                  <p>
                    <span className={`has-text-weight-semibold`}>{t('account.user.invoices.amount')}</span>
                    {
                      priceToString(props.profile.subscription.stripeInfos.amount / 100, locale) + ' ' +
                      t('account.profiles.subscription.per_' + props.profile.subscription.stripeInfos.recurringInterval)
                    }
                  </p>
                  <p>
                    <span className={`has-text-weight-semibold`}>{t('account.profiles.renew_date')}</span>
                    {moment(props.profile.premiumEndDate).locale(locale).format('DD MMMM yyyy')}
                  </p>
                </React.Fragment>
              }
            </React.Fragment>
          }

          {
            props.withVisibility &&
            <p><span className={`has-text-weight-semibold`}>{t('account.profiles.visibility')}</span>
              {t('visibility.' + props.profile.visibility)}
              {
                props.profile.visibility === Visibility.PRIVATE &&
                <span className={classNames('icon')}>
                  <i className={classNames('far fa-eye-slash')} />
                </span>
              }
            </p>
          }

        </React.Fragment>
      }
      {
        props.profile.state !== State.LIVE &&
        <p className={styles.state}><span className={`has-text-weight-semibold`}>{t('account.profiles.state')}</span>
          {t('state.' + props.profile.state)}
        </p>
      }
    </div >
  )
}
