import React, { useState } from 'react';
import Polyglot, { PolyglotOptions } from 'node-polyglot';

import { defaultLocale } from '@locales/.';
import { useEffect } from 'react';

export const I18nContext = React.createContext(
  new Polyglot({
    locale: defaultLocale,
    phrases: {}
  })
);

interface I18nProviderProps extends PolyglotOptions {
  children?: React.ReactNode;
}

const I18nProvider: React.FC<I18nProviderProps> = props => {
  const { children } = props;
  const [polyglot, setPolyglot] = useState(
    new Polyglot({
      locale: props.locale || defaultLocale,
      phrases: props.phrases || {},
      allowMissing: props.allowMissing,
      onMissingKey: props.onMissingKey,
      interpolation: props.interpolation,
    }));

  useEffect(() => {
    let updatedPolyglot = polyglot;
    updatedPolyglot.locale(props.locale);
    setPolyglot(updatedPolyglot);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.locale]);

  useEffect(() => {
    let updatedPolyglot = polyglot;
    polyglot.replace(props.phrases)
    setPolyglot(updatedPolyglot);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.phrases])

  return (
    <I18nContext.Provider value={polyglot}>
      {React.Children.only(children)}
    </I18nContext.Provider>
  )
}

I18nProvider.defaultProps = {
  allowMissing: false,
  onMissingKey: undefined,
  interpolation: undefined,
} as Partial<PolyglotOptions>;

export default I18nProvider;
