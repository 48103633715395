import React, { useState, useEffect, useContext } from 'react';
import { send, OnFireController, OnFire } from '@oward/openapi';
import { PaginatedList, PaginatedListKey, Section, NavigationContext, PaginatedListFilterType } from '@components/admin';
import { profileToStr, strToPath } from '@oward/common-utils';
import { userToStrFull } from '@utils/.';
import { useTranslate } from '@hooks/use-translate';
import moment from 'moment';

export const OnFireList: React.FC = () => {

  const store = useContext(NavigationContext);
  const { t, locale } = useTranslate();
  const [items, setItems] = useState([] as OnFire[]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = async () => {
    try {
      setLoading(true);
      setError(false);
      setItems(await send(OnFireController.listAdmin()))
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const keys: PaginatedListKey<OnFire>[] = [
    {
      label: 'Profile (on fired)',
      value: item => profileToStr(item.profile, locale)
    },
    {
      label: 'User (on firing)',
      value: item => userToStrFull(item.user)
    },
    {
      label: 'Creation Date',
      value: item => moment(item.creationDate).format('lll'),
      sortValue: item => Date.parse(item.creationDate)
    },
    {
      label: 'Date Notified',
      value: item => moment(item.dateNotified).format('lll'),
      sortValue: item => Date.parse(item.dateNotified)
    },
  ];

  const filterItemStrings = (item: OnFire) => [
    strToPath(profileToStr(item.profile, locale)),
    strToPath(userToStrFull(item.user))
  ];

  const filterComponents = [
    {
      name: 'Profile (on fired)',
      type: PaginatedListFilterType.DROPDOWN,
      options: [...new Set(items.map(item => profileToStr(item.profile, locale)))].map(userStr => {
        return { value: userStr, label: userStr }
      }),
      filterFunction: (item: OnFire, value: string) => profileToStr(item.profile, locale) === value
    },
    {
      name: 'User (on firing)',
      type: PaginatedListFilterType.DROPDOWN,
      options: [...new Set(items.map(item => userToStrFull(item.user)))].map(userStr => {
        return { value: userStr, label: userStr }
      }),
      filterFunction: (item: OnFire, value: string) => userToStrFull(item.user) === value
    },
  ];

  return (
    <React.Fragment>
      <Section
        title='List'
        subtitle='The list of all On Fires in the database'
        childrenFullWidth
      >
        <PaginatedList
          keys={keys}
          items={items}
          filterItemStrings={filterItemStrings}
          filterComponents={filterComponents}
          size={100}
          reloadList={loadItems}
          loading={loading}
          error={error}
        />
      </Section>
      <div style={{ height: '2rem' }} />
    </React.Fragment>
  );
}
