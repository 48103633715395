import React, { useContext } from 'react';
import styles from './Layout.module.scss';

import { Breadcrumb } from '@components/Core/Breadcrumb';
import { NavigationContext } from '../Navigation';
import { observer } from 'mobx-react-lite';

export const Header = observer(() => {

  const store = useContext(NavigationContext);

  return (
    <React.Fragment>
      <div className={styles.header}>
        <Breadcrumb separator='arrow'>
          <Breadcrumb.Item active>
            {store.active.categoryKey}
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <div onClick={() => store.unpopAll()}>
              {store.active.itemKey}
            </div>
          </Breadcrumb.Item>
          {
            store.popped?.map((item, i) =>
              <Breadcrumb.Item key={i}>
                <div onClick={() => store.unpop(store.popped.length - i - 1)}>
                  {item.label}
                </div>
              </Breadcrumb.Item>
            )
          }
        </Breadcrumb>
      </div>
    </React.Fragment >
  );
});
