import React from 'react';

export interface PaginationControlProps {
  page: number;
  setPage: (value: number) => void;
  count: number;
  size: number;
}

export const PaginationControl: React.FC<PaginationControlProps> = props => {

  const onClickNext = () => {
    props.setPage(props.page + 1);
  }

  const onClickPrevious = () => {
    props.setPage(props.page - 1);
  }

  return (
    <div className="notification">
      <div className="level">
        <div className="level-left">
          <div className="level-item">
            <div className="buttons has-addons">
              <button
                disabled={props.page === 1}
                className="button"
                onClick={onClickPrevious}>
                Previous
              </button>
              <button
                disabled={props.page >= props.count / props.size}
                className="button"
                onClick={onClickNext}>
                Next
              </button>
            </div>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <span className="has-text-grey">{`${props.count} rows - page ${props.page}/${Math.ceil(props.count / props.size)}`}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
