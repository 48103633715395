import React, { useEffect, useState } from 'react';
import styles from './UserSettings.module.scss';
import { LayoutStore, PopupStore, UserStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { NewPassword, SaveUserInfos, SaveUserNotificationPreferences, send, UserController } from '@oward/openapi';
import { useInjection, useProfilesForUser, useTranslate } from '@hooks/.';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { Link, OwardButton, ToastError, ToastSucess } from '@components/.';
import { toast } from 'react-toastify';
import { ERR_MSG_UNAUTHORIZED, PHONE_VALIDATION_REGEX } from '@utils/constants';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LayoutPageCentered, OwardFormInput, OwardFormSwitch, OwardLinkButton, OwardLoader } from '@components/Core';
import { Divider } from '../Admin/AdminComponents';
import { goToHomePageAndReload } from '@utils/utils';

export function AccountUserSettingsPage() {
  const { t, locale } = useTranslate();
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);
  const { profiles } = useProfilesForUser(locale);

  const hasProfileWithActiveSubscriptionStr = (): string => {
    let profilesWithActiveSubscription = profiles?.filter(profile => profile.subscription?.status === 'active');

    switch (profilesWithActiveSubscription?.length ?? 0) {
      case 0:
        return '';
      case 1:
        return `\n\n${t('account.user.confirm_delete_cancel_subscription', {
          profileName: profilesWithActiveSubscription[0].name
        })}`;
      default:
        return `\n\n${t('account.user.confirm_delete_cancel_subscriptions', {
          profileNames: profilesWithActiveSubscription.map(profile => profile.name).join(', ')
        })}`;
    }
  }

  return (
    <LayoutPageCentered
      title={t('account.user.title')}
      subtitle={userStore.currentUser?.email}
    >
      <div className={styles.mainContainer}>
        <Divider bigMargins />
        <ModifyInfosFrom />
        <Divider bigMargins />
        <NotificationsFrom />
        <Divider bigMargins />
        <ModifyPasswordFrom />
        <Divider bigMargins />
        <InvoicingSection />
        <Divider bigMargins />
        <div className={classNames(styles.deleteButtonContainer)}>
          <OwardButton
            name={t('account.user.delete')}
            red
            confirmation={`${t('account.user.confirm_delete', { email: userStore.currentUser?.email })
              }${hasProfileWithActiveSubscriptionStr()}`}
            onClick={async () => {
              try {
                layoutStore.setPageLoading(true);
                await send(UserController.deleteUser());
                layoutStore.setPageLoading(false);
                popupStore.openInformationPopUp({
                  msg: t('account.user.delete_ok', { email: userStore.currentUser?.email }),
                  callback: async () => {
                    await UserController.logout();
                    goToHomePageAndReload();
                  }
                });

              } catch (err) {
                layoutStore.setPageLoading(false);
                if (err instanceof Error && err.message === ERR_MSG_UNAUTHORIZED) {
                  popupStore.openInformationPopUp({
                    msg: t('global.force_logout'),
                    callback: () => { goToHomePageAndReload() }
                  });
                }
                else {
                  toast.dark(<ToastError msg={t('global.error_retry')} />);
                }
              }
            }}
          />
        </div>
        <div style={{ height: '5rem' }} />
      </div>
    </LayoutPageCentered >
  )
}

export const ModifyInfosFrom: React.FC = () => {
  const { t } = useTranslate();
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);
  const [loading, setLoading] = useState(false);

  const schema = (t: any) => {
    return (
      Yup.object().shape({
        phoneNumber: Yup.string().matches(PHONE_VALIDATION_REGEX, t('forms.phone_number.invalid'))
      }))
  };

  const formik = useFormik({
    initialValues: {
      firstName: userStore.currentUser?.infos?.firstName,
      lastName: userStore.currentUser?.infos?.lastName,
      phoneNumber: userStore.currentUser?.infos.phoneNumber,
      registerNewsletter: (userStore.currentUser?.infos?.preferences as any)?.registerNewsletter ? true : false,
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      let userInfos: SaveUserInfos = {
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        registerNewsletter: values.registerNewsletter,
      }
      try {
        setLoading(true);
        await UserController.saveInfos(userInfos);
        toast.dark(<ToastSucess msg={t('account.user.modify_infos.save_success')} />);
      }
      catch (err) {
        if (err instanceof Error && err.message === ERR_MSG_UNAUTHORIZED) {
          popupStore.openInformationPopUp({
            msg: t('global.force_logout'),
            callback: () => { goToHomePageAndReload() }
          });
        }
        else {
          toast.dark(<ToastError msg={t('global.error_with_code', { code: err })} />);
        }
      }
      finally {
        await userStore.refreshCurrentUser();
        setLoading(false);
      }
    }
  });

  return (
    <form className={classNames(styles.formContainer)} onSubmit={formik.handleSubmit}>
      <p className={styles.categoryTitle}>{t('account.user.modify_infos.title')}</p>
      <OwardLoader loading={loading} />
      <OwardFormInput
        id='firstName'
        label={t('forms.first_name.label')}
        icon='fas fa-user'
        placeholder={t('forms.first_name.placeholder')}
        formik={formik}
      />
      <OwardFormInput
        id='lastName'
        label={t('forms.last_name.label')}
        icon='fas fa-user'
        placeholder={t('forms.last_name.placeholder')}
        formik={formik}
      />
      <OwardFormInput
        id='phoneNumber'
        label={t('forms.phone_number.label')}
        icon='fas fa-phone'
        placeholder={t('forms.phone_number.placeholder')}
        formik={formik}
      />
      <OwardFormSwitch
        id='registerNewsletter'
        label={t('login.register_newsletter')}
        formik={formik}
      />
      <OwardButton
        name={t('account.user.modify_infos.save')}
        submit
        fullWidth
      />
    </form>
  );
}

export const NotificationsFrom: React.FC = () => {
  const { t } = useTranslate();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);

  const formik = useFormik({
    initialValues: {
      notifiedWhenOnFired: (userStore.currentUser?.infos?.preferences as any)?.notifiedWhenOnFired === false ? false : true,
      notifiedWhenReceivingMessage: (userStore.currentUser?.infos?.preferences as any)?.notifiedWhenReceivingMessage === false ? false : true,
    },
    onSubmit: async values => {
      let notificationPreferences: SaveUserNotificationPreferences = {
        notifiedWhenOnFired: values.notifiedWhenOnFired,
        notifiedWhenReceivingMessage: values.notifiedWhenReceivingMessage,
      }
      try {
        setLoading(true);
        await UserController.saveNotificationPreferences(notificationPreferences);
        toast.dark(<ToastSucess msg={t('account.user.notifications.save_success')} />);
      }
      catch (err) {
        if (err instanceof Error && err.message === ERR_MSG_UNAUTHORIZED) {
          popupStore.openInformationPopUp({
            msg: t('global.force_logout'),
            callback: () => { goToHomePageAndReload() }
          });
        }
        else {
          toast.dark(<ToastError msg={t('global.error_with_code', { code: err })} />);
        }
      }
      finally {
        await userStore.refreshCurrentUser();
        setLoading(false);
      }
    }
  });

  return (
    <form className={classNames(styles.formContainer)} onSubmit={formik.handleSubmit}>
      <p className={styles.categoryTitle}>{t('account.user.notifications.title')}</p>
      <OwardLoader loading={loading} />
      <OwardFormSwitch
        id='notifiedWhenOnFired'
        label={t('account.user.notifications.on_fired')}
        formik={formik}
      />
      <p className={styles.desc}>{t('account.user.notifications.on_fired_desc')}</p>
      <OwardFormSwitch
        id='notifiedWhenReceivingMessage'
        label={t('account.user.notifications.receive_message')}
        formik={formik}
      />
      <p className={styles.desc}>{t('account.user.notifications.receive_message_desc')}</p>
      <OwardButton
        name={t('account.user.notifications.save')}
        submit
        fullWidth
      />
    </form>
  );
}

export const ModifyPasswordFrom: React.FC = () => {
  const { t } = useTranslate();
  const router = useRouter();
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);
  const [loading, setLoading] = useState(false);

  const schema = (t: any) => {
    return (
      Yup.object().shape({
        password: Yup
          .string()
          .required(t('forms.password.required'))
          .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{7,50}\S$/, t('forms.password.requirements')),
        confirmPassword: Yup
          .string()
          .test(
            'password-match',
            t('forms.password.no_match'),
            function (value) {
              return this.parent.password === value
            }
          ),
      }))
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      let newPassword: NewPassword = {
        password: values.password
      };
      try {
        setLoading(true);
        await UserController.changePassword(newPassword);
        toast.dark(<ToastSucess msg={t('account.user.change_password.save_success')} />);
      }
      catch (err) {
        if (err instanceof Error && err.message === ERR_MSG_UNAUTHORIZED) {
          popupStore.openInformationPopUp({
            msg: t('global.force_logout'),
            callback: () => { goToHomePageAndReload() }
          });
        }
        else {
          toast.dark(<ToastError msg={t('global.error_with_code', { code: err })} />);
        }
      }
      finally {
        setLoading(false);
      }
    }
  });

  return (
    <form className={classNames(styles.formContainer)} onSubmit={formik.handleSubmit}>
      <p className={styles.categoryTitle}>{t('account.user.change_password.title')}</p>
      <OwardLoader loading={loading} />
      <OwardFormInput
        id='password'
        type='password'
        label={t('forms.password.label_new')}
        description={t('forms.password.requirements')}
        icon='fa fa-lock'
        placeholder='********'
        formik={formik}
      />
      <OwardFormInput
        id='confirmPassword'
        type='password'
        label={t('forms.password.label_confirm')}
        icon='fa fa-lock'
        placeholder='********'
        formik={formik}
      />
      <OwardButton
        name={t('login.reset_password.change_password')}
        submit
        fullWidth
      />
    </form>
  );
}


export const InvoicingSection: React.FC = () => {
  const { t } = useTranslate();

  return (
    <div className={classNames(styles.formContainer)}>
      <p className={styles.categoryTitle}>{t('account.user.invoicing.title')}</p>
      <Link href='/account/user/invoices'>
        <OwardLinkButton
          name={t('account.user.invoices.button')}
          iconName={'fa-solid fa-file-invoice-dollar'}
          outlined
        />
      </Link>
      <Link href='/account/user/payment-methods'>
        <OwardLinkButton
          name={t('account.user.payment_methods.button')}
          iconName={'fa-solid fa-credit-card'}
          outlined
        />
      </Link>
      <Link href='/account/user/billing-informations'>
        <OwardLinkButton
          name={t('account.user.billing_informations.button')}
          iconName={'fa-solid fa-address-card'}
          outlined
        />
      </Link>
    </div>
  );
}
