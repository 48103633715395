import { Items, ActiveItem } from './types';
import { CategoryKey, ItemKey } from './keys';
import { DashboardComponent } from './Dashboard';
import { UsersComponent } from './Users';
import { ProfilesComponent } from './Profiles';
import { ArtworksComponent } from './Artworks';
import { FilmsComponent } from './Films';
import { FestivalsComponent } from './Festivals';
import { JobsComponent } from './Jobs';
import { FilmTypesComponent } from './FilmTypes';
import { AwardCategoriesComponent } from './Awards';
import { AgentComponent } from './Agents';
import { BroadcasterComponent } from './Broadcaster';
import { AssociationComponent } from './Association';
import { PromoCodesComponent } from './PromoCodes';
import { ShortlistComponent } from './Shortlist';
import { StatsClickContactComponent } from './Stats';
import { ReportArtworkComponent, ReportMessageComponent, ReportProfileComponent } from './Report';
import { OnFireComponent } from './OnFire';
import { CountriesCities } from './CountriesCities';
import { DiscussionComponent } from './Messages';
import { SubscriptionsComponent } from './Subscriptions';
import { BlockArtworkComponent, BlockProfileComponent } from './Block';

export const items: Items = {
  [CategoryKey.GENERAL]:
    [
      {
        key: ItemKey.DASHBOARD,
        icon: 'fas fa-tachometer-alt',
        component: DashboardComponent
      },
      {
        key: ItemKey.PROMO_CODE,
        icon: 'fas fa-file-invoice-dollar',
        component: PromoCodesComponent
      },
      {
        key: ItemKey.SUBSCRIPTIONS,
        icon: 'fas fa-file-invoice',
        component: SubscriptionsComponent
      }
    ],
  [CategoryKey.USER_DATA]:
    [
      {
        key: ItemKey.USERS,
        icon: 'fas fa-users',
        component: UsersComponent
      },
      {
        key: ItemKey.PROFILES,
        icon: 'fas fa-address-card',
        component: ProfilesComponent
      },
      {
        key: ItemKey.ARTWORKS,
        icon: 'fas fa-palette',
        component: ArtworksComponent
      },
      {
        key: ItemKey.FILMS,
        icon: 'fas fa-film',
        component: FilmsComponent
      },
      {
        key: ItemKey.SHORTLIST,
        icon: 'fas fa-layer-group',
        component: ShortlistComponent
      },
      {
        key: ItemKey.ON_FIRES,
        icon: 'fas fa-fire',
        component: OnFireComponent
      },
      {
        key: ItemKey.DISCUSSIONS,
        icon: 'fas fa-envelope',
        component: DiscussionComponent
      },
    ],
  [CategoryKey.OWARD_DATA]:
    [
      {
        key: ItemKey.JOBS,
        icon: 'fas fa-briefcase',
        component: JobsComponent
      },
      {
        key: ItemKey.COUNTRIES_CITIES,
        icon: 'fas fa-globe',
        component: CountriesCities
      },
      {
        key: ItemKey.FILM_TYPES,
        icon: 'fas fa-film',
        component: FilmTypesComponent
      },
      {
        key: ItemKey.AGENTS,
        icon: 'fas fa-user-tie',
        component: AgentComponent
      },
      {
        key: ItemKey.ASSOCIATIONS,
        icon: 'fas fa-hands-helping',
        component: AssociationComponent
      },
      {
        key: ItemKey.BRODCASTERS,
        icon: 'fas fa-tv',
        component: BroadcasterComponent
      },
      {
        key: ItemKey.FESTIVAL,
        icon: 'fas fa-trophy',
        component: FestivalsComponent
      },
      {
        key: ItemKey.AWARD_CATEGORIES,
        icon: 'fas fa-award',
        component: AwardCategoriesComponent
      },
    ],
  [CategoryKey.MODERATION]:
    [
      {
        key: ItemKey.REPORTS_PROFILE,
        icon: 'fas fa-flag',
        component: ReportProfileComponent
      },
      {
        key: ItemKey.REPORTS_ARTWORK,
        icon: 'fas fa-flag',
        component: ReportArtworkComponent
      },
      {
        key: ItemKey.REPORTS_MESSAGE,
        icon: 'fas fa-flag',
        component: ReportMessageComponent
      },
      {
        key: ItemKey.BLOCKS_PROFILE,
        icon: 'fa-solid fa-ban',
        component: BlockProfileComponent
      },
      {
        key: ItemKey.BLOCKS_ARTWORK,
        icon: 'fa-solid fa-ban',
        component: BlockArtworkComponent
      },
    ],
  [CategoryKey.STATS]:
    [
      {
        key: ItemKey.STATS_CLICK_CONTACT,
        icon: 'far fa-envelope',
        component: StatsClickContactComponent
      },
    ],
}

export const defaultItem: ActiveItem =
{
  categoryKey: CategoryKey.GENERAL,
  itemKey: ItemKey.DASHBOARD
};
