import React, { useContext, useState } from "react";
import { useFormik } from 'formik';
import { observer } from "mobx-react-lite";
import { AssociationController, State, Association, LocationController } from "@oward/openapi";
import { Section, Divider, SubmitOrCancel, NavigationContext } from '@components/admin';
import { OwardFormInput, Heading, OwardFormSelectLoad, OwardFormSelect } from '@components/Core';
import { useTranslate } from '@hooks/.';
import { EditProps } from '../types';
import * as Yup from 'yup';
import { stateToEmoji, validationStateToEmoji } from '@oward/common-utils';

const schema = (t: any) => {
  return (
    Yup.object().shape({
      name: Yup.string().required(),
      country: Yup.object().required(),
    }))
};

export const EditAssociation = observer((props: EditProps<Association>) => {
  const { t, locale } = useTranslate();
  const [error, setError] = useState(false);
  const navigation = useContext(NavigationContext);

  const formik = useFormik({
    initialValues: {
      name: props.item.name,
      country: props.item.country,
      state: props.item.state,
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      props.item.name = values.name;
      props.item.country = values.country
      props.item.state = values.state;
      try {
        await AssociationController.saveOneAdmin(props.item);
        if (props.notify) { props.notify(props.item) };
        setError(false);
        navigation.unpop();
      } catch {
        setError(true);
      }
    }
  });

  return (
    <Section
      title='Edit'
      subtitle='Change your entity properties'>
      <form className='box' onSubmit={formik.handleSubmit} >
        <Heading>General</Heading>
        <OwardFormInput
          id='name'
          label='Name *'
          formik={formik}
        />
        <OwardFormSelectLoad
          id='country'
          label='Country *'
          fetchFunction={LocationController.findCountries()}
          formik={formik}
        />
        <OwardFormSelect
          id='state'
          label='State *'
          options={Object.values(State).map((state) => {
            return ({ value: state, label: state.concat(' ', stateToEmoji(state)) });
          })}
          formik={formik}
        />
        <div style={{ height: '8rem' }} />
        <Divider />
        <SubmitOrCancel error={error} noCancel={props.noCancel} />
      </form >
    </Section >
  )
});
