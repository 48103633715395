import React from 'react';
import styles from './PaymentMethodsPage.module.scss';
import { LayoutStore, ModalKey, ModalStore, PopupStore, UserStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { useInjection, useTranslate } from '@hooks/.';
import { LayoutPageCentered, OwardButton, OwardLinkButton, ToastError, ToastSucess } from '@components/Core';
import { observer } from 'mobx-react-lite';
import { send, StripePaymentMethodInfos, SubscriptionController } from '@oward/openapi';
import MoreIcon from '../../../assets/icons/more.svg';
import TrashIcon from '../../../assets/icons/trash.svg';
import moment from 'moment';
import classNames from 'classnames';
import { ERR_MSG_UNAUTHORIZED } from '@utils/constants';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import { cardBrandToFontawesomIcon, cardLast4ToString, isPaymentMethodExpired } from '@utils/utils';
import classnames from 'classnames';
import { CreateOrMyProfilesButton, Link } from '@components/index';

interface PaymentMethodsPageProps {
  paymentMethods: StripePaymentMethodInfos[];
}

export const PaymentMethodsPage: React.FC<PaymentMethodsPageProps> = observer(props => {
  const { t } = useTranslate();
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  return (
    <LayoutPageCentered
      title={t('account.user.payment_methods.title')}
      subtitle={userStore.currentUser?.email}
    >
      <div className={styles.mainContainer}>
        {
          props.paymentMethods?.length > 0 ?
            <React.Fragment>
              {
                props.paymentMethods?.map((paymentMethod, i) =>
                  <React.Fragment key={i}>
                    <PaymentMethodInfoLine paymentMethod={paymentMethod} />
                  </React.Fragment>
                )
              }
            </React.Fragment>
            :
            <div className={styles.emptyContainer}>
              <p>{t('account.user.payment_methods.no_payment_method')}</p>
            </div>
        }
        <OwardButton
          outlined
          name={t('account.user.payment_methods.add_card')}
          onClick={() => { modalStore.openModalNewStack(router, ModalKey.ADD_BANK_CARD, 1) }}
        />
        <p className={styles.savedOnStripe}>
          {t('account.user.payment_methods.saved_on_stripe')}
          <a className={`icon ${styles.icon}`} href='https://stripe.com/' target='_blank' rel='noreferrer'>
            <i className={'fab fa-stripe'}></i>
          </a>
        </p>
      </div>
    </LayoutPageCentered >
  )
});

interface PaymentMethodInfoLine {
  paymentMethod: StripePaymentMethodInfos;
}

const PaymentMethodInfoLine: React.FC<PaymentMethodInfoLine> = props => {
  const router = useRouter();
  const { t, locale } = useTranslate();
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);

  const displayedCardNumber = cardLast4ToString(props.paymentMethod.last4);

  const deletePaymentMethod = async () => {
    try {
      await send(SubscriptionController.detachStripePaymentMethod(props.paymentMethod.id));
      toast.dark(<ToastSucess msg={t('account.user.payment_methods.delete.success', { number: displayedCardNumber })} />);
      // Refresh page to reload payment method list
      router.replace(router.asPath);
    }
    catch (err) {
      if (err instanceof Error && err.message === ERR_MSG_UNAUTHORIZED) {
        toast.dark(<ToastError msg={t('global.error_unauthorized')} />);
      }
      else {
        toast.dark(<ToastError msg={t('account.user.payment_methods.delete.error')} />);
      }
    }
  }

  const setPaymentMethodAsDefault = async () => {
    try {
      layoutStore.setPageLoading(true);
      await send(SubscriptionController.setStripePaymentMethodAsDefault(props.paymentMethod.id));
      toast.dark(<ToastSucess msg={t('account.user.payment_methods.set_default.success', { number: displayedCardNumber })} />);
      // Refresh page to reload payment method list
      router.replace(router.asPath);
    }
    catch (err) {
      if (err instanceof Error && err.message === ERR_MSG_UNAUTHORIZED) {
        toast.dark(<ToastError msg={t('global.error_unauthorized')} />);
      }
      else {
        toast.dark(<ToastError msg={t('account.user.payment_methods.set_default.error')} />);
      }
    }
    finally {
      layoutStore.setPageLoading(false);
    }
  }

  return (
    <div className={styles.paymentMethodContainer}>
      <div className={styles.upContainer}>
        <div className={styles.leftContainer}>
          <p className={styles.number}>
            <span className={classNames(`icon`, styles.brandIcon)}>
              <i className={cardBrandToFontawesomIcon(props.paymentMethod.brand)}></i>
            </span>
            {displayedCardNumber}
          </p>
          <p className={isPaymentMethodExpired(props.paymentMethod) && styles.expired}>
            {t('account.user.payment_methods.expires', {
              // moment().month() is 0-based
              date: `${moment().month(props.paymentMethod.expMonth - 1).locale(locale).format('MMMM')} ${props.paymentMethod.expYear}`
            })}
          </p>
          <p className={styles.creationDate}>
            {t('account.user.payment_methods.creation_date', {
              date: moment(props.paymentMethod.creationDate * 1000).locale(locale).format('DD MMM yyyy')
            })}
          </p>
        </div>
        {
          !props.paymentMethod.isDefault &&
          <div className={styles.rightContainer}>
            <div className={styles.buttonsIconContainer}>
              {
                !isPaymentMethodExpired(props.paymentMethod) &&
                <div
                  className={classNames(styles.buttonsIconCircleContainer, 'has-tooltip-left has-tooltip-arrow')}
                  data-tooltip={t('account.user.payment_methods.set_default.button')}
                  onClick={() => {
                    popupStore.openConfirmationPopUp({
                      msg: t('account.user.payment_methods.set_default.confirm', { number: displayedCardNumber }),
                      callback: setPaymentMethodAsDefault
                    });
                  }}
                >
                  <MoreIcon className={styles.buttonsIcon} />
                </div>
              }
              <div
                className={classNames(styles.buttonsIconCircleContainer, 'has-tooltip-left has-tooltip-arrow')}
                data-tooltip={t('account.user.payment_methods.delete.button')}
                onClick={() => {
                  popupStore.openConfirmationPopUp({
                    msg: t('account.user.payment_methods.delete.confirm', { number: displayedCardNumber }),
                    callback: deletePaymentMethod
                  });
                }}
              >
                <TrashIcon className={classNames(styles.buttonsIcon, styles.red)} />
              </div>
            </div>
          </div>
        }
      </div>
      {
        isPaymentMethodExpired(props.paymentMethod) &&
        <div className={classnames(styles.footerContainer, styles.expiredContainer)}>
          <p className={styles.expired}>{t('account.user.payment_methods.expired')}</p>
        </div>
      }
      {
        props.paymentMethod.isDefault &&
        <div className={classnames(styles.footerContainer, styles.defaultContainer)}>
          <p className={styles.default}>{t('account.user.payment_methods.default')}</p>
        </div>
      }
    </div>
  )
}

export const PaymentMethodsNoCustomerPage: React.FC = observer(props => {
  const { t } = useTranslate();
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  return (
    <LayoutPageCentered
      title={t('account.user.payment_methods.title')}
      subtitle={userStore.currentUser?.email}
    >
      <div className={styles.mainContainer}>
        <div className={styles.emptyContainer}>
          <p>{t('account.user.payment_methods.no_payment_method')}</p>
          <p>{t('account.user.payment_methods.start_by_subscribing')}</p>
        </div>
        <CreateOrMyProfilesButton />
      </div>
    </LayoutPageCentered >
  )
});
