import classNames from 'classnames';
import styles from './ProfileAvatars.module.scss';
import { getInitials, onErrorImage, remSizeToPixelNumber } from '@utils/utils';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export type ProfileAvatarSize = "1.5rem" | "2rem" | "3rem" | "4rem";

interface ProfileAvatarProps {
  avatarUrl: string;
  profileName: string;
  size: ProfileAvatarSize;
  showInitialIfNoUrl?: boolean,
  clickable?: boolean,
}

export const ProfileAvatar: React.FC<ProfileAvatarProps> = props => {

  let sizeCssSelector = `size${props.size.replace('.', '_')}`;

  return (
    <div className={classNames(
      styles.avatarContainer,
      styles[sizeCssSelector],
    )
    }>
      {
        props.avatarUrl &&
        <LazyLoadImage
          src={props.avatarUrl}
          width={remSizeToPixelNumber(props.size)}
          height={remSizeToPixelNumber(props.size)}
          className={classNames(
            styles.avatar,
            props.clickable && styles.clickable,
            styles[sizeCssSelector],
          )}
          alt={`${props.profileName} avatar`}
          effect='blur'
          onError={onErrorImage} />}
      {
        (!props.avatarUrl && props.showInitialIfNoUrl) &&
        <div className={classNames(
          styles.initialsContainer,
          props.clickable && styles.clickable,
          styles[sizeCssSelector],
        )}>
          <p className={styles.initials}>{getInitials(props.profileName)}</p>
        </div>
      }
    </div>
  )
}
