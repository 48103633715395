import React, { useState } from 'react';
import { Divider } from '@components/admin';
import { StatsClickContactList } from './StatsList';

export const StatsClickContactComponent: React.FC = () => {

  return (
    <React.Fragment>
      <StatsClickContactList />
    </React.Fragment>
  );
}
