import React from 'react';

import { Divider } from '../AdminComponents';
import { ArtworkStatistics } from './ArtworkStatistics';
import { ArtworkList } from './ArtworkList';

export const ArtworksComponent: React.FC = () => {
  return (
    <React.Fragment>
      <ArtworkStatistics />
      <Divider />
      <ArtworkList />
    </React.Fragment>
  );
}
