import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { CardGetStatus, FilterType, } from '@oward/common-enums';
import styles from './GalleryHeader.module.scss';
import SearchIcon from '../../../assets/icons/search.svg';
import SortIcon from '../../../assets/icons/sort.svg';

import { useInjection, useProfilesForUser, useTranslate } from '@hooks/.';
import { LayoutStore, FilterStore, CardStore, UserStore, ArtworkStore, ModalStore, ModalKey } from '@stores/.';
import { StoresBindings } from '@container/.';
import {
  OwardButton, OwardSlider, OwardSwitchMultiple,
  getFilterQuery, isFilterEmpty, ShortlistHeader, ToastSucess, jobFilterName, ErrorPageGoToGallery, CreateOrMyProfilesButton,
} from '@components/.';
import { ZOOM_RATIO_MIN, ZOOM_RATIO_MAX, FilterPhrase } from '@utils/.';
import { FilterSelect } from '../../Sidebar/AccordionContent';
import { ProfileType } from '@oward/openapi';
import { GalleryType } from '@oward/common-utils';

export const GalleryHeader = observer(() => {
  const { t } = useTranslate();
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);
  const cardStore = useInjection<CardStore>(StoresBindings.CARD);
  const artworkStore = useInjection<ArtworkStore>(StoresBindings.ARTWORK);
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  return (
    <React.Fragment>
      {
        cardStore.cardGetStatus === CardGetStatus.PRIVATE_SHORTLIST ?
          <ErrorPageGoToGallery
            errMsg={t('shortlist.unauthorized')}
            icon={'fas fa-2x far fa-eye-slash'}
          />
          :
          <div id='gallery-header-container' className={`${styles.mainContainer}`}> {/* id is used to scroll when filtering */}
            <div className={`${styles.topContainer}`}>
              {
                filterStore.filter.shortlist !== undefined ?
                  <React.Fragment>
                    <ShortlistHeader />
                    {/* Hack: empty div just to have a mark to where-to-scroll when filtering */}
                    <div id='gallery-header-scroll-to-filters' />
                  </React.Fragment>
                  :
                  <React.Fragment>
                    {/*<WelcomePanel /> */}
                    { /* <KnowMore /> */}
                    { /* <GallerySelector /> */}
                    {/* Hack: empty div just to have a mark to where-to-scroll when filtering */}
                    <div id='gallery-header-scroll-to-filters' />
                    <Titles />
                    <WelcomePanel />
                    {/*<EmphasisedFilters />*/}
                    <ButtonsTop />
                  </React.Fragment>
              }
            </div>
            {
              !isFilterEmpty(filterStore.filter, true) ?
                <ActiveFilters />
                :
                <div className={styles.noFilterSpace} />
            }
            {
              userStore.isAdmin &&
              <p className='has-text-centered has-text-weight-semibold'>
                {
                  filterStore.isProfileGallery ?
                    `Count: ${cardStore.totalCardCount} profiles (admin only)`
                    :
                    `Count: ${artworkStore.totalArtworkCount} artworks (admin only)`
                }
              </p>
            }
            <OrderButton />
          </div >
      }
    </React.Fragment>
  );
});

const Titles = observer(() => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const { profiles } = useProfilesForUser(locale);

  return (
    <div className={`${styles.titlesContainer}`}>
      <h1 className={classNames(
        styles.title,
        FilterPhrase(filterStore.filter, filterStore.label).length > 60 && styles.longTitle,
        FilterPhrase(filterStore.filter, filterStore.label).length > 120 && styles.veryLongTitle
      )}>
        {t('gallery.header.title', { filter_phrase: FilterPhrase(filterStore.filter, filterStore.label) })}
      </h1>
      {
        <div className={`${styles.discoverPhraseContainer}`}>
          <h3 className={`${styles.discoverPhrase}`}>
            {t(`gallery.header.discover`)}
          </h3>
        </div>
      }
    </div>
  );
});

const Logos = () => {
  const { t } = useTranslate();

  interface LogoPartnerProps {
    src: string;
    href: string;
    alt: string;
  }

  const LogoPartner = (props: LogoPartnerProps) => {
    return (
      <div className={`${styles.logoContainer}`}>
        <a href={props.href} target='_blank' rel='noreferrer'>
          <img src={props.src} className={`${styles.logoImg}`} alt={props.alt}></img>
        </a>
      </div>
    );
  }

  return (<div className={`${styles.logosContainer}`}>
    <LogoPartner
      src='https://cdn-oward-common-images.oward.co/logo/belle-de-mai.png'
      href='https://www.belledemai.org/'
      alt={t('alt.logo.bdm')}
    />
    <LogoPartner
      src='https://cdn-oward-common-images.oward.co/logo/french-tech.png'
      href='https://lafrenchtech.com/fr/'
      alt={t('alt.logo.ftt')}
    />
    <LogoPartner
      src='https://cdn-oward-common-images.oward.co/logo/bpi.png'
      href='https://www.bpifrance.fr/'
      alt={t('alt.logo.bpi')}
    />
  </div>);
}

const WelcomePanel = observer(() => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);
  const cardStore = useInjection<CardStore>(StoresBindings.CARD);
  const { profiles, liveProfiles } = useProfilesForUser(locale);

  return (
    <React.Fragment>
      <div className={`${styles.subtitleMainContainer}`}>
        {
          layoutStore.welcomePanelOpened ?
            <div className={`${styles.subtitleContainer}`}>
              <p>
                <span className={`${styles.semibold}`}>{t('gallery.header.know_more.hello')}</span>
                {t('gallery.header.know_more.text_1')}
                <span
                  className={`${styles.link}`}
                  onClick={() => {
                    modalStore.openModalNewStack(router, ModalKey.NEW_PROFILE, 1)
                  }}
                >
                  {t('gallery.header.know_more.link_1')}
                </span>
                {t('gallery.header.know_more.text_2')}
                <span
                  className={`${styles.link}`}
                  onClick={() => {
                    modalStore.openModalNewStack(router, ModalKey.NEW_PORTFOLIO, 1)
                  }}
                >
                  {t('gallery.header.know_more.link_2')}
                </span>
                {t('gallery.header.know_more.text_3')}
                <span
                  className={`${styles.link}`}
                  onClick={() => {
                    router.push(
                      `/[lang]/`,
                      `/${locale}/`
                    );
                    filterStore.setFilter(
                      router,
                      FilterType.GALLERY_TYPE,
                      undefined,
                      undefined,
                      true
                    );
                    // We need to to an explicit getCards here, as if we reset filters + set filters to ProfileType "undefined"
                    // (i.e. going to Pro Gallery), the deepEqual filter/filterStore.filter in index.tsx will be false and no card will
                    // be fetched
                    cardStore.getCards(filterStore.filter, filterStore.openSearch);
                  }}
                >
                  {t('gallery.header.know_more.link_3')}
                </span>
                {t('gallery.header.know_more.text_4')}
                <span
                  className={`${styles.link}`}
                  onClick={() => {
                    router.push(
                      `/[lang]/?filter=gallery_type\[type\]\=company`,
                      `/${locale}/?filter=gallery_type\[type\]\=company`
                    );
                    filterStore.setFilter(
                      router,
                      FilterType.GALLERY_TYPE,
                      { type: GalleryType.COMPANY },
                      undefined,
                      true
                    );
                  }}
                >
                  {t('gallery.header.know_more.link_4')}
                </span>
                {t('gallery.header.know_more.text_5')}
                <span
                  className={`${styles.link}`}
                  onClick={() => {
                    router.push(
                      `/[lang]/?filter=gallery_type\[type\]\=artwork`,
                      `/${locale}/?filter=gallery_type\[type\]\=artwork`
                    );
                    filterStore.setFilter(
                      router,
                      FilterType.GALLERY_TYPE,
                      { type: GalleryType.ARTWORK },
                      undefined,
                      true
                    );
                  }}
                >
                  {t('gallery.header.know_more.link_5')}
                </span>
                {t('gallery.header.know_more.text_6')}
                <span
                  className={`${styles.link}`}
                  onClick={() => {
                    router.push(
                      `/[lang]/?filter=gallery_type\[type\]\=artwork;film_year[id]=0`,
                      `/${locale}/?filter=gallery_type\[type\]\=artwork;film_year[id]=0`
                    );
                    filterStore.setFilter(
                      router,
                      FilterType.GALLERY_TYPE,
                      { type: GalleryType.ARTWORK },
                      undefined,
                      true
                    );
                    filterStore.setFilter(
                      router,
                      FilterType.FILM_YEAR,
                      { id: 0 },
                      t('wip.film'),
                      false
                    );
                  }}
                >
                  {t('gallery.header.know_more.link_6')}
                </span>
                {t('gallery.header.know_more.text_7')}
                <span
                  className={`${styles.link}`}
                  onClick={() => { layoutStore.openOrFlashSidebar() }}
                >
                  {t('gallery.header.know_more.link_7')}
                </span>
                {t('gallery.header.know_more.text_8')}
                <span
                  className={`${styles.link}`}
                  onClick={() => {
                    router.push(
                      `/[lang]/onfire`,
                      `/${locale}/onfire`
                    );
                  }}
                >
                  {t('gallery.header.know_more.link_8')}
                </span>
                {t('gallery.header.know_more.text_9')}
                <span
                  className={`${styles.link}`}
                  onClick={() => {
                    if (liveProfiles?.length === 1) {
                      router.push(
                        `/[lang]/messages/[profilePath]`,
                        `/${locale}/messages/${liveProfiles[0]?.path}`
                      );
                      layoutStore.openMessagesDiscussionContainer(false);
                    }
                    else {
                      modalStore.openModalNewStack(router, ModalKey.GO_TO_MESSAGES, '0');
                    }
                  }}
                >
                  {t('gallery.header.know_more.link_9')}
                </span>
                {t('gallery.header.know_more.text_10')}
                <a
                  className={classNames(styles.link)}
                  href={`mailto:romain@oward.co`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {t('gallery.header.know_more.link_10')}
                </a>
                {t('gallery.header.know_more.text_11')}
                <span
                  className={`${styles.link}`}
                  onClick={() => {
                    if (liveProfiles?.length === 1) {
                      router.push(
                        `/[lang]/messages/[profilePath]`,
                        `/${locale}/messages/${liveProfiles[0]?.path}`
                      );
                      layoutStore.openMessagesDiscussionContainer(false);
                    }
                    else {
                      modalStore.openModalNewStack(router, ModalKey.GO_TO_MESSAGES, '0');
                    }
                  }}
                >
                  {t('gallery.header.know_more.link_11')}
                </span>
                {t('gallery.header.know_more.text_12')}
                <a
                  className={classNames(styles.link)}
                  href={'https://play.google.com/store/apps/details?id=com.owardApp&hl=fr&gl=US'}
                  target='_blank'
                  rel='noreferrer'
                >
                  {t('gallery.header.know_more.link_12')}
                </a>
                {t('gallery.header.know_more.text_13')}
                <a
                  className={classNames(styles.link)}
                  href={'https://apps.apple.com/fr/app/oward/id6448476544'}
                  target='_blank'
                  rel='noreferrer'
                >
                  {t('gallery.header.know_more.link_13')}
                </a>
                {t('gallery.header.know_more.text_14')}
                <span
                  className={`${styles.link}`}
                  onClick={() => {
                    modalStore.openModalNewStack(router, ModalKey.NEW_PROFILE, 1)
                  }}
                >
                  {t('gallery.header.know_more.link_14')}
                </span>
                {t('gallery.header.know_more.text_15')}
              </p>

              <div className={`${styles.closeContainer}`}>
                <OwardButton
                  noMargin
                  outlined
                  name={t('global.close')}
                  onClick={() => { layoutStore.openWelcomPanel(false) }}
                />
              </div>
            </div>
            :
            <div>
              <OwardButton
                outlined
                name={t('gallery.header.know_more.show')}
                onClick={() => { layoutStore.openWelcomPanel(true) }}
              />
            </div>
        }
      </div>
    </React.Fragment >
  );
});

const GallerySelector = observer(() => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);
  const cardStore = useInjection<CardStore>(StoresBindings.CARD);

  return (
    <div className={`${styles.gallerySelectorContainer}`}>
      <p className={`${styles.text}`}>{t(`gallery.header.gallery_selector.choose`)}</p>
      <OwardSwitchMultiple
        activeOption={filterStore.galleryType}
        options={Object.values(GalleryType).map((type) => {
          return { value: type, label: t(`gallery.header.gallery_selector.${type}`) };
        })}
        onChange={option => {
          switch (option.value) {
            case GalleryType.PRO:
              filterStore.setFilter(
                router,
                FilterType.GALLERY_TYPE,
                undefined,
                undefined,
                true
              );
              // We need to to an explicit getCards here, as if we reset filters + set filters to ProfileType "undefined"
              // (i.e. going to Pro Gallery), the deepEqual filter/filterStore.filter in index.tsx will be false and no card will
              // be fetched
              cardStore.getCards(filterStore.filter, filterStore.openSearch);
              break;
            case GalleryType.COMPANY:
              filterStore.setFilter(
                router,
                FilterType.GALLERY_TYPE,
                { type: GalleryType.COMPANY },
                undefined,
                true
              );
              break;
            case GalleryType.ARTWORK:
              filterStore.setFilter(
                router,
                FilterType.GALLERY_TYPE,
                { type: GalleryType.ARTWORK },
                undefined,
                true
              );
              break;
            default:
              console.error('Gallery Type not handled');
              break;
          }
        }}
        black
        stackOnMobile
      />
    </div>
  );
});

interface EmphasisedFilterProps {
  name: string;
  type: FilterType;
  id: number;
}

const EmphasisedFilter = (props: EmphasisedFilterProps) => {
  return (
    <div className={`${styles.filterContainer}`}>
      <FilterSelect
        name={props.name}
        id={props.id}
        filter_type={props.type}
        onGalleryHeader
      />
    </div>
  );
}

const EmphasisedFilters = observer(() => {
  const { t } = useTranslate();
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);

  return (
    <div className={`${styles.filtersMainContainer} ${styles.emphasisedFiltersMainContainer}`}>
      <p className={`is-family-secondary ${styles.title}`}>{t('gallery.header.filters')}</p>
      <div className={`${styles.filtersContainer} ${styles.filtersContainerExtraPadding}`}>
        <EmphasisedFilter name={jobFilterName(filterStore.filter.gallery_type?.type)} id={101} type={FilterType.JOB} />
        <EmphasisedFilter name='sidebar.search_film.film_type' id={102} type={FilterType.FILM_TYPE} />
        <EmphasisedFilter name='sidebar.search_profile.location' id={103} type={FilterType.LOCATION} />
        <EmphasisedFilter name='sidebar.search_festival.festival' id={104} type={FilterType.FESTIVAL} />
        <EmphasisedFilter name='sidebar.search_film.broadcaster' id={105} type={FilterType.BROADCASTER} />
        {
          /* Filters de-activated on front page
          filterStore.filter.gallery_type?.type === GalleryType.PRO &&
          <React.Fragment>
            <EmphasisedFilter name='sidebar.search_profile.agent' id={106} type={FilterType.AGENT} />
            <EmphasisedFilter name='sidebar.search_profile.association' id={107} type={FilterType.ASSOCIATION} />
          </React.Fragment>
          */
        }
      </div>
      <p className={`${styles.moreFilters}`} onClick={() => { layoutStore.openSidebar(!layoutStore.sidebarOpened) }}>
        {layoutStore.sidebarOpened ? t('gallery.header.less_filters') : t('gallery.header.more_filters')}
      </p>
    </div>
  );
});

interface ActiveFilterProps {
  type: FilterType;
  label: string;
  onClick: () => void;
}

const ActiveFilter = (props: ActiveFilterProps) => {
  return (
    <div className={`${styles.activeFilterContainer}`}>
      <h2 className={`${styles.filterName}`}>
        {props.label}
      </h2>
      <div className={`${styles.filterIconContainer}`}>
        <p
          className={styles.filterIcon}
          onClick={props.onClick}
        >
          <span className={`icon`}>
            <i className='fas fa-times'></i>
          </span>
        </p>
      </div>
    </div>
  )
}

export const ActiveFilters = observer(() => {
  const router = useRouter();
  const { t, locale } = useTranslate();
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);

  const shareSearch = async () => {
    let url: string = process.env.WEBSITE_URL.concat('/', locale, '?', getFilterQuery(filterStore.filter));
    if (navigator.share) {
      try {
        await navigator.share({
          title: document.title,
          url: url
        })
      }
      catch (err) {
        // Cancelling 'share' throw an error. We don't really care.
      }
    }
    else {
      var textField = document.createElement('textarea');
      textField.innerText = url;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
      toast.dark(<ToastSucess msg={t('global.toast_share')} />);
    }
  }

  return (
    <div className={`${styles.filtersMainContainer}`}>
      {
        !isFilterEmpty(filterStore.filter, true) &&
        <div className={classNames(styles.titleAndButtonContainer)}>
          <p className={`is-family-secondary ${styles.title}`}>{t('gallery.header.filter_actives')}</p>
          {/*
            filterStore.filter.shortlist === undefined &&
            <div
              className={classNames(styles.shareButtonContainer, 'has-tooltip-arrow')}
              data-tooltip={t('gallery.header.share_search')}
            >
              <p className={classNames(styles.shareButton)} onClick={shareSearch}>
                <span className={`icon`}>
                  <i className='far fa-paper-plane'></i>
                </span>
              </p>
            </div>
      */}
        </div>
      }
      <div className={`${styles.activeFiltersContainer}`}>
        {
          Object.values(FilterType).map((type: FilterType, index: number) => {
            if (type === FilterType.HASHTAG && filterStore.filter[FilterType.HASHTAG]?.names !== undefined) {
              return (
                <React.Fragment key={index}>
                  {
                    filterStore.filter[FilterType.HASHTAG].names.map((hashtagName: string, index: number) => {
                      return (
                        <ActiveFilter
                          key={index}
                          type={type}
                          label={'#' + hashtagName}
                          onClick={() => { filterStore.setHashtagFilter(router, hashtagName, true) }}
                        />
                      );
                    })
                  }
                </React.Fragment>
              )
            }
            else if (filterStore.filter[type] !== undefined) {
              let label: string = filterStore.label[type];
              switch (type) {
                case FilterType.JOB:
                  switch (filterStore.filter.gallery_type?.type) {
                    case GalleryType.COMPANY:
                      label = t('gallery.header.filter_label.company_type', { label: label });
                      break;
                    default:
                      label = t('gallery.header.filter_label.job', { label: label });
                  }
                  break;
                case FilterType.JOB_CATEGORY:
                  switch (filterStore.filter.gallery_type?.type) {
                    case GalleryType.COMPANY:
                      label = t('gallery.header.filter_label.company_type_category', { label: label });
                      break;
                    default:
                      label = t('gallery.header.filter_label.job_category', { label: label });
                  }
                  break;
                case FilterType.LOCATION:
                  label = t('gallery.header.filter_label.'.concat(type, '_', filterStore.filter.location.type), { label: label });
                  break;
                case FilterType.ADMIN_SEE_ALL_PROFILES:
                  label = 'ADMIN: See ALL Profiles';
                  break;
                case FilterType.GALLERY_TYPE:
                case FilterType.SHORTLIST:
                  return;
                default:
                  label = t('gallery.header.filter_label.' + type, { label: label });
                  break;
              }

              return (
                <ActiveFilter
                  key={type}
                  type={type}
                  label={label}
                  onClick={() => { filterStore.setFilter(router, type, undefined, undefined); }}
                />
              );
            }
          })
        }
      </div>
    </div >
  )
});

const ButtonsTop = observer(() => {
  const { t } = useTranslate();
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);
  const artworkStore = useInjection<ArtworkStore>(StoresBindings.ARTWORK);
  const cardStore = useInjection<CardStore>(StoresBindings.CARD);

  return (
    <div className={`${styles.buttonsMainContainer}`}>
      <div className={`${styles.buttonsContainer}`}>
        <div className={`${styles.buttonsTopContainer}`}>
          <OwardButton
            transparentBlue
            name={`🔎  ${t('global.search')}`}
            onClick={() => { layoutStore.openOrFlashSidebar() }}
            bigPadding
          />
          <CreateOrMyProfilesButton fullwidthBigPadding />
        </div>
        {
          /*
          * DEACTIVTATED (Card ratio - Zoom)
          <div className={styles.zoomContainer}>
            <p className={styles.zoomText}>{t('gallery.header.zoom')}</p>
            <OwardSlider
              onChange={(event => { layoutStore.scaleCards(parseFloat(event.currentTarget.value), true) })}
              value={layoutStore.cardRatio}
              min={ZOOM_RATIO_MIN}
              max={ZOOM_RATIO_MAX}
            />
          </div>
        */
        }
      </div>
    </div >
  );
});

const OrderButton = observer(() => {
  const { t } = useTranslate();
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);
  const artworkStore = useInjection<ArtworkStore>(StoresBindings.ARTWORK);
  const cardStore = useInjection<CardStore>(StoresBindings.CARD);

  return (
    <div className={`${styles.buttonsMainContainer}`}>
      {
        filterStore.filter.shortlist === undefined &&
        <div className={styles.orderButtonContainer}>
          <OwardButton
            name={t(filterStore.isRandom ? 'gallery.header.random' : 'gallery.header.recent_first')}
            svgIcon={<SortIcon />}
            transparent
            onClick={() => {
              filterStore.setRandom(!filterStore.isRandom);
              if (filterStore.isArtworkGallery) {
                artworkStore.getArtworks(filterStore.filter, filterStore.openSearch);
              }
              else {
                cardStore.getCards(filterStore.filter, filterStore.openSearch);
              }
            }}
            tooltipText={t('gallery.header.order_button_tooltip')}

          />
        </div>
      }
    </div >
  );
});

export default GalleryHeader;
