import React from 'react';
import styles from './EditProfile.module.scss';
import { Agent, Artwork, Biography, CardI, Profile, } from '@oward/openapi';
import { useTranslate } from '@hooks/.';
import { artworkToCardArtwork, Card, Link } from '@components/.';
import { findTranslationOrDefault } from '@oward/common-utils';
import { observer } from 'mobx-react-lite';
import { EditProfileCategory, EditProfileStore } from '@components/Pages';
import { hasPortfolioPublic } from '@utils/utils';

const profileToCardPreview = (profile: Profile, locale: string): CardI => {
  if (!profile) {
    return;
  }
  let bioShort: string, bioLong: string;

  // Try to find a bio with the good locale. If there's not, take the first one
  profile.bios?.map((bio: Biography) => {
    if (bio.locale === locale) {
      bioShort = bio.short;
      bioLong = bio.long;
    }
  });

  if (bioShort === undefined) {
    bioShort = profile?.bios[0]?.short;
    bioLong = profile?.bios[0]?.long;
  }

  let artworksDisplayed: Artwork[] = profile.artworks?.filter(artwork => artwork.displayed === true);

  let card: CardI = {
    id: profile.id,
    path: profile.path,
    name: profile.name,
    identityVerfied: profile.identityVerfied,
    avatarUrl: profile.avatarUrl,
    country: findTranslationOrDefault(profile.location?.country, locale),
    city: findTranslationOrDefault(profile.location?.city, locale),
    lastUpdateTimeMs: new Date().getTime() - new Date(profile.updateDate).getTime(),
    job: findTranslationOrDefault(profile.job, locale),
    agent: (profile.agentDisplayed as Agent)?.name,
    bioShort: bioShort,
    bioLong: bioLong,
    artworks: artworksDisplayed.map(artwork => artworkToCardArtwork(artwork, locale, profile)),
    type: profile.type,
    hasPortfolioPublic: hasPortfolioPublic(profile)
  }

  return card;
}

interface EditProfilePreviewProps {
  store: EditProfileStore;
}

export const EditProfilePreview: React.FC<EditProfilePreviewProps> = observer(props => {
  const { t, locale } = useTranslate();

  return (
    <div className={styles.previewMainContainer} id={'edit-profile-card-preview-container'}>
      <div className={styles.previewContainer}>
        <p className={styles.explanation}>
          {t('edit_profile.preview.explanation')}
          <br />
          {
            props.store.activeCategory === EditProfileCategory.NONE
            &&
            <Link href='/p/[profilePath]' as={`/p/${props.store.profile?.path}`} passHref>
              <span className={styles.link}>
                {t(`edit_profile.preview.${hasPortfolioPublic(props.store.profile) ? 'see' : 'preview'}_portfolio`)}
              </span>
            </Link>
          }
        </p>
        <div className={styles.previewCardContainer}>
          <Card
            card={profileToCardPreview(props.store.profile, locale)}
            preview
            avatarPreview={props.store.avatarPreview}
          />
        </div>
        <p style={{ color: 'transparent' }}>Dummy transparent text to have a border below the card</p>
      </div>
    </div>
  )
})
