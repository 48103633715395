import React, { useEffect } from 'react';
import { AddShortlistModal, NewShortlistModal, ModifyShortlistModal } from '@components/.';
import { ArtworkModal } from './ArtworkModal';
import { NewProfileModal } from './NewProfileModal';
import { NewsletterModal } from './NewsletterModal';
import { ResetPasswordModal } from './ResetPasswordModal';
import { NewAgentModal } from './NewAgentModal';
import { NewBroadcasterModal } from './NewBroadcasterModal';
import { ModifySelectionModal, NewSelectionModal } from './SelectionModal';
import { NewFestivalModal } from './NewFestivalModal';
import { NewFestivalAwardModal } from './NewFestivalAwardModal';
import { NewArtworkModal } from './NewArtworkModal';
import { useInjection } from '@hooks/.';
import { ModalStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { ReportBlockProfileModal, ReportMessageModal, ReportBlockArtworkModal } from './ReportBlock';
import { AddOnFireModal } from './AddOnFireModal';
import { ContactModal } from './ContactModal';
import { SendBriefModal } from './SendBriefModal';
import { AddBankCardModal } from './AddBankCardModal';
import { GoToMessagesModal, CreateGroupDiscussionModal, ManageGroupDiscussionModal } from './Messages';
import { ContactDetailsModal } from './ContactDetailsModal';
import { NewPortfolioModal } from './NewPortfolioModal';

export const OwardModals = () => {
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  useEffect(() => {
    modalStore.unpopAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <NewsletterModal />
      <ResetPasswordModal />
      <ArtworkModal />
      <ContactModal />
      <ContactDetailsModal />
      <GoToMessagesModal />
      <CreateGroupDiscussionModal />
      <ManageGroupDiscussionModal />
      <AddShortlistModal />
      <NewShortlistModal />
      <ModifyShortlistModal />
      <AddBankCardModal />
      <AddOnFireModal />
      <NewProfileModal />
      <NewPortfolioModal />
      <NewArtworkModal />
      <NewAgentModal />
      <NewBroadcasterModal />
      <NewSelectionModal />
      <ModifySelectionModal />
      <NewFestivalModal />
      <NewFestivalAwardModal />
      <ReportBlockProfileModal />
      <ReportBlockArtworkModal />
      <ReportMessageModal />
      <SendBriefModal />
    </React.Fragment>
  );
}
