import React, { useState } from 'react';
import styles from './NewShortlistModal.module.scss';
import { observer } from 'mobx-react-lite';
import { UserStore, ModalKey, ModalStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { useInjection, useTranslate } from '@hooks/.';
import { Modal, ModalConfirmOrCancelButtons, ModalTitle } from '@components/Modal';
import { OwardButton, OwardFormInput, OwardFormSwitch, OwardFormTextArea, NeedConnectionModal } from '@components/.';
import { useRouter } from 'next/router';
import { useFormik } from 'formik';
import { send, ShortlistController, Visibility } from '@oward/openapi';
import { mutate } from 'swr';
import { SWR_USER_SHORTLISTS_GET } from '@utils/.';
import * as Yup from 'yup';

const schema = (t: any) => {
  return (
    Yup.object().shape({
      name: Yup.string().required(t('forms.required')),
    }))
};


export const NewShortlistModal: React.FC = observer(() => {
  const { t } = useTranslate();
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  const [error, setError] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      private: false,
      description: undefined
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      try {
        await send(ShortlistController.create({
          name: values.name,
          visibility: values.private === true ? Visibility.PRIVATE : Visibility.PUBLIC,
          description: values.description
        }));
        setError(false);
        modalStore.unpopModal(router);
      } catch (error) {
        setError(true);
      }
      mutate(SWR_USER_SHORTLISTS_GET);
    }
  });
  return (
    <React.Fragment>
      {
        userStore.isLogged ?
          <Modal
            modalKey={ModalKey.NEW_SHORTLIST}
            header={<ModalTitle name={t('shortlist.new.title')} />}
            footer={
              <form onSubmit={formik.handleSubmit} >
                <ModalConfirmOrCancelButtons
                  confirmTxt={t('global.create')}
                  error={error ? t('global.error_retry') : ''}
                  cancelation={() => modalStore.unpopModal(router)}
                />
              </form>
            }
          >
            <form onSubmit={formik.handleSubmit} >
              <div className={styles.contentContainer}>
                <OwardFormInput
                  id='name'
                  label={t('shortlist.new.name_label')}
                  placeholder={t('shortlist.new.name_placeholder')}
                  formik={formik}
                />
                <OwardFormSwitch
                  id='private'
                  label={t('shortlist.private')}
                  description={t('shortlist.new.private_desc')}
                  formik={formik}
                />
                <OwardFormTextArea
                  id='description'
                  label={t('shortlist.new.description_label')}
                  placeholder={t('shortlist.new.description_placeholder')}
                  formik={formik}
                />
                {
                  error &&
                  <p className={styles.error}>{t('global.error')}</p>
                }
              </div>
            </form>
          </Modal >
          :
          <Modal modalKey={ModalKey.NEW_SHORTLIST}>
            <NeedConnectionModal headerMessage={t('shortlist.need_connection')} />
          </Modal>
      }
    </React.Fragment>

  );
});
