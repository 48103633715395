import React, { useState, useEffect } from 'react';
import { send, BlockController, BlockProfile, BlockArtwork } from '@oward/openapi';
import { PaginatedList, PaginatedListKey, Section, PaginatedListFilterType } from '@components/admin';
import { profileToStr, strToPath } from '@oward/common-utils';
import { userToStrFull } from '@utils/.';
import { useTranslate } from '@hooks/use-translate';
import moment from 'moment';

export const BlockProfileList: React.FC = () => {
  const { locale } = useTranslate();
  const [items, setItems] = useState([] as BlockProfile[]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = async () => {
    try {
      setLoading(true);
      setError(false);
      setItems(await send(BlockController.listBlockProfilesAdmin()))
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const keys: PaginatedListKey<BlockProfile>[] = [
    {
      label: 'Profile blocked',
      value: item => `${item.profileId} - ${item.profile.name}`
    },
    {
      label: 'User blocker',
      value: item => `${item.userId} - ${userToStrFull(item.user)}`
    },
    {
      label: 'NumberOfBlocks/Profile',
      value: item => getNumberOfBlocks(item)
    },
    {
      label: 'Creation Date',
      value: item => moment(item.creationDate).format('lll'),
      sortValue: item => Date.parse(item.creationDate)
    },
  ];

  const getNumberOfBlocks = (item: BlockProfile): number => {
    return items?.filter(block => block?.profileId === item.profileId).length;
  }

  const filterItemStrings = (item: BlockProfile) => [
    strToPath(`${item.profileId} - ${item.profile.name}`),
    strToPath(`${item.userId} - ${userToStrFull(item.user)}`)
  ];

  const filterComponents = [
    {
      name: 'Profile blocked',
      type: PaginatedListFilterType.DROPDOWN,
      options: [...new Set(items.map(item => `${item.profileId} - ${item.profile.name}`))].map(userStr => {
        return { value: userStr, label: userStr }
      }),
      filterFunction: (item: BlockProfile, value: string) => `${item.profileId} - ${item.profile.name}` === value
    },
    {
      name: 'User blocker',
      type: PaginatedListFilterType.DROPDOWN,
      options: [...new Set(items.map(item => `${item.userId} - ${userToStrFull(item.user)}`))].map(userStr => {
        return { value: userStr, label: userStr }
      }),
      filterFunction: (item: BlockProfile, value: string) => `${item.userId} - ${userToStrFull(item.user)}` === value
    },
  ];

  return (
    <React.Fragment>
      <Section
        title='List'
        subtitle='The list of all Block Profiles in the database'
        childrenFullWidth
      >
        <PaginatedList
          keys={keys}
          items={items}
          filterItemStrings={filterItemStrings}
          filterComponents={filterComponents}
          size={100}
          reloadList={loadItems}
          loading={loading}
          error={error}
        />
      </Section>
      <div style={{ height: '2rem' }} />
    </React.Fragment>
  );
}

export const BlockArtworkList: React.FC = () => {
  const { locale } = useTranslate();
  const [items, setItems] = useState([] as BlockArtwork[]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = async () => {
    try {
      setLoading(true);
      setError(false);
      setItems(await send(BlockController.listBlockArtworksAdmin()))
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const keys: PaginatedListKey<BlockArtwork>[] = [
    {
      label: 'Artwork blocked',
      value: item => `${item.artworkId} - ${item.artwork.name}`
    },
    {
      label: 'User blocker',
      value: item => `${item.userId} - ${userToStrFull(item.user)}`
    },
    {
      label: 'NumberOfBlocks/Artwork',
      value: item => getNumberOfBlocks(item)
    },
    {
      label: 'Creation Date',
      value: item => moment(item.creationDate).format('lll'),
      sortValue: item => Date.parse(item.creationDate)
    },
  ];

  const getNumberOfBlocks = (item: BlockArtwork): number => {
    return items?.filter(block => block?.artworkId === item.artworkId).length;
  }

  const filterItemStrings = (item: BlockArtwork) => [
    strToPath(`${item.artworkId} - ${item.artwork.name}`),
    strToPath(`${item.userId} - ${userToStrFull(item.user)}`)
  ];

  const filterComponents = [
    {
      name: 'Artwork blocked',
      type: PaginatedListFilterType.DROPDOWN,
      options: [...new Set(items.map(item => `${item.artworkId} - ${item.artwork.name}`))].map(userStr => {
        return { value: userStr, label: userStr }
      }),
      filterFunction: (item: BlockArtwork, value: string) => `${item.artworkId} - ${item.artwork.name}` === value
    },
    {
      name: 'User blocker',
      type: PaginatedListFilterType.DROPDOWN,
      options: [...new Set(items.map(item => `${item.userId} - ${userToStrFull(item.user)}`))].map(userStr => {
        return { value: userStr, label: userStr }
      }),
      filterFunction: (item: BlockArtwork, value: string) => `${item.userId} - ${userToStrFull(item.user)}` === value
    },
  ];

  return (
    <React.Fragment>
      <Section
        title='List'
        subtitle='The list of all Block Artworks in the database'
        childrenFullWidth
      >
        <PaginatedList
          keys={keys}
          items={items}
          filterItemStrings={filterItemStrings}
          filterComponents={filterComponents}
          size={100}
          reloadList={loadItems}
          loading={loading}
          error={error}
        />
      </Section>
      <div style={{ height: '2rem' }} />
    </React.Fragment>
  );
}
