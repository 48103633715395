import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Button.module.scss';
import { useInjection } from '@hooks/.';
import { PopupStore } from '@stores/.';
import { StoresBindings } from '@container/.';

type OwardButtonCommonProps = {
  name?: string;
  outlined?: boolean;
  dark?: boolean;
  red?: boolean;
  white?: boolean;
  transparent?: boolean;
  transparentBlue?: boolean;
  fullWidth?: boolean;
  bigPadding?: boolean;
  noMargin?: boolean;
  svgIcon?: ReactElement;
  iconName?: string;
  tooltipText?: string;
  tooltipPostion?: string;
  isActive?: boolean;
  deactivated?: boolean;
  children?: React.ReactNode;
}

type OwardButtonProps = {
  submit?: boolean;
  confirmation?: string;
  onClick?: () => void;
} & OwardButtonCommonProps;

export const OwardButton: React.FC<OwardButtonProps> = props => {
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);

  return (
    <button
      className={buttonClassName(props)}
      onClick={() => {
        if (props.deactivated) {
          return;
        }
        else {
          props.confirmation !== undefined ?
            popupStore.openConfirmationPopUp({
              msg: props.confirmation,
              callback: props.onClick
            }) :
            props.onClick()
        }
      }}
      type={(props.submit && !props.deactivated) ? 'submit' : 'button'}
      data-tooltip={props.tooltipText ? props.tooltipText : null}
    >
      <ButtonContent {...props} />
    </button>
  );
}

OwardButton.defaultProps = {
  outlined: false,
  onClick: () => { }
} as Partial<OwardButtonProps>;

type OwardLoadFileButtonProps = {
  id: string; // Must be unique !
  onlyImages?: boolean;
  onClick?: (e: any) => void;
  stringButton?: string;
} & OwardButtonCommonProps;

export const OwardLoadFileButton: React.FC<OwardLoadFileButtonProps> = props => {
  return (
    <React.Fragment>
      {/* The (ugly) input is hidden, and the UI is handled by the label element above */}
      <input
        style={{ display: 'none' }}
        type='file'
        id={props.id}
        accept={props.onlyImages && 'image/*'}
        onChange={props.onClick}
      />
      {
        props.stringButton ?
          <label
            htmlFor={props.id}
            className={styles.owardLink}
            data-tooltip={props.tooltipText ? props.tooltipText : null}
          >
            {props.stringButton}
          </label>
          :
          <label
            htmlFor={props.id}
            className={buttonClassName(props)}
            data-tooltip={props.tooltipText ? props.tooltipText : null}
          >
            <ButtonContent {...props} />
          </label>
      }
    </React.Fragment>
  );
}

/* Needed when a Button is a child of a Link component.
 * see https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-function-component
 * and
 * https://www.selbekk.io/blog/2020/05/forwarding-refs-in-typescript/
 */
type OwardLinkButtonProps = {
  onClick?: any;
} & OwardButtonCommonProps;

const OwardLinkButton = React.forwardRef<HTMLButtonElement, OwardLinkButtonProps>((props, ref) => {
  return (
    <button
      ref={ref}
      className={buttonClassName(props)}
      data-tooltip={props.tooltipText ? props.tooltipText : null}
      onClick={props.onClick}
    >
      <ButtonContent {...props} />
    </button>
  )
})

OwardLinkButton.displayName = 'OwardLinkButton';

export { OwardLinkButton };

const buttonClassName = (props: Partial<OwardButtonCommonProps>): string => classNames(
  'button',
  styles.button,
  { [styles.deactivated]: props.deactivated },
  { [styles.outlined]: props.outlined },
  { [styles.outlinedDark]: props.outlined && props.dark },
  { [styles.red]: props.red },
  { [styles.white]: props.white && !props.isActive },
  { [styles.whiteActive]: props.white && props.isActive },
  { [styles.transparent]: props.transparent },
  { [styles.transparentBlue]: props.transparentBlue && !props.isActive },
  { [styles.transparentBlueActive]: props.transparentBlue && props.isActive },
  { [styles.notCentered]: !props.name },
  { [styles.fullWidth]: props.fullWidth },
  { [styles.svgIconOnly]: props.svgIcon && !props.name },
  { [styles.bigPadding]: props.bigPadding },
  { [styles.noMargin]: props.noMargin },
  'has-tooltip-arrow',
  { [props.tooltipPostion]: props.tooltipPostion }
)

const ButtonContent = props =>
  <div className={styles.contentContainer}>
    {
      props.svgIcon &&
      <div className={classNames(styles.svgIconContainer, { [styles.svgIconContainerWithName]: props.name })}>
        {
          React.cloneElement(props.svgIcon, { className: `${props.svgIcon.props} ${styles.svgIcon}` })
        }
      </div>
    }
    {
      props.iconName &&
      <span className={classNames('icon', styles.fontawesomeIcon)} >
        <i className={props.iconName}></i>
      </span>
    }
    {
      props.name ?
        <p className={classNames(styles.name)} >{props.name}</p>
        :
        props.children
    }
  </div>;

interface MenuButtonProps {
  name: string,
  icon?: ReactNode,
  isActive?: boolean,
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export const MenuButton: React.FC<MenuButtonProps> = props => {

  return (
    <div className={classNames(styles.menuButtonContainer)} onClick={props.onClick}>
      <div className={classNames(styles.nameIconContainer)}>
        {
          props.icon &&
          <div className={classNames(styles.iconContainer)}>
            {props.icon}
          </div>
        }
        <p className={classNames(styles.name, props.isActive && styles.nameActive)} >
          {props.name}
        </p>
      </div>
      <div className={classNames(styles.angleContainer)}>
        <span className={`icon`}>
          <i className={classNames('fas fa-angle-right', styles.angle, props.isActive && styles.angleRotated)} />
        </span>
      </div>
    </div>
  )
}
