import { makeAutoObservable, runInAction } from 'mobx';
import { CardController, CardI, send, FilterI, CardRequestParamsI, CardGetRespI, Request } from '@oward/openapi';
import { CardGetStatus } from '@oward/common-enums';
import { injectable, inject } from 'inversify';
import {
  NUMBER_CARD_LOADED_INIT, NUMBER_CARD_LOADED_SCROLL,
  ERR_MSG_ABORTED
} from '@utils/.';
import { FilterStore } from '@stores/.';
import { StoresBindings, ApplicationBindings } from '@container/.';

@injectable()
export class CardStore {
  constructor() {
    makeAutoObservable(this);
  }

  @inject(ApplicationBindings.LOCALE) private locale: string;
  @inject(StoresBindings.FILTER) private filterStore: FilterStore;
  cards: CardI[] = new Array<CardI>();
  totalCardCount: number = 0;
  cardGetStatus: CardGetStatus = CardGetStatus.OK;
  isLoading: boolean = false;
  error: boolean = false;
  isMoreLoading: boolean = false;
  hasMore: boolean = false;
  scrollPosition: any;

  // Keep a ref of the last getCards request, to abort it if a new one is sent
  getCardsReq: Request<CardGetRespI> = null;

  getCards = async (filter: FilterI, openSearch?: string) => {
    this.error = false;
    if (this.getCardsReq !== null) {
      this.getCardsReq.abort();
    }
    const params: CardRequestParamsI = {
      limit: NUMBER_CARD_LOADED_INIT,
      offset: 0,
      isRandom: this.filterStore.isRandom
    };
    let req: Request<CardGetRespI> = CardController.get(this.locale, params, filter, openSearch);
    let resp: CardGetRespI;
    try {
      this.setIsLoading(true);
      this.getCardsReq = req;
      resp = await send(req);
      this.getCardsReq = null;
      runInAction(() => {
        this.cards = resp.cards;
        this.totalCardCount = resp.totalCardCount;
        this.cardGetStatus = resp.status as CardGetStatus;
        this.hasMore = this.totalCardCount > resp.cards?.length + params.offset;
      });
      this.setIsLoading(false);
    }
    catch (err) {
      if (err instanceof Error && err.message === ERR_MSG_ABORTED) {
        // Do nothing, as a request aborted mean a new one is sent
      }
      else {
        console.error(err);
        runInAction(() => {
          this.error = true;
          this.cards = undefined;
          this.totalCardCount = 0;
        });
        this.setIsLoading(false);
      }
    }
  }

  getMoreCards = async () => {
    this.isMoreLoading = true;

    let loadedCardsId: number[] = new Array<number>();
    if (this.filterStore.isRandom) {
      this.cards.map((card: CardI) => {
        loadedCardsId.push(card.id);
      });
    }

    let loadedCardsNumber: number = this.cards.length;
    const params: CardRequestParamsI = {
      limit: NUMBER_CARD_LOADED_SCROLL,
      offset: loadedCardsNumber,
      isRandom: this.filterStore.isRandom,
      loadedCardsId: loadedCardsId.join(',')
    };

    const resp: CardGetRespI = await send(CardController.get(this.locale, params, this.filterStore.filter, this.filterStore.openSearch));
    runInAction(() => {
      this.cards = this.cards.concat(resp.cards);
      this.hasMore = this.totalCardCount > resp.cards?.length + loadedCardsNumber;
      this.isMoreLoading = false;
    });
  }

  setIsLoading = (loading: boolean) => {
    let cardsGrid: HTMLElement = document.getElementById('cards-grid');
    this.isLoading = loading;
    // We adjust the card gallery opacity
    if (cardsGrid !== null) {
      cardsGrid.style.opacity = loading ? '0.2' : '1';
    }
  }

  setScrollPosition = (scrollPosition: any) => {
    this.scrollPosition = scrollPosition;
  }
}
