import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { useTranslate } from '@hooks/.';
import { LayoutPageCentered, Link } from '@components/.';
import { OwardLinkButton } from '@components/Core';

interface VerifyEmailPageProps {
  unauthorized: boolean;
}

export const VerifyEmailPage: React.FC<VerifyEmailPageProps> = props => {

  const router = useRouter();
  const { locale, t } = useTranslate();

  return (
    props.unauthorized ?
      <LayoutPageCentered title={t('login.verify_email.unauthorized_title')} isVerticallyCentered>
        <p style={{ textAlign: 'center', whiteSpace: 'pre-wrap', paddingBottom: '1.5rem' }}>
          {t('login.verify_email.unauthorized_description')}
        </p>
        <Link href='/login'>
          <OwardLinkButton
            name={t('login.login')}
            fullWidth
          />
        </Link>
      </LayoutPageCentered>
      :
      <LayoutPageCentered title={t('login.verify_email.title')} isVerticallyCentered>
        <p style={{ textAlign: 'center', whiteSpace: 'pre-wrap', paddingBottom: '1.5rem' }}>
          {t('login.verify_email.description')}
        </p>
        <Link href='/?new_profile=1'>
          <OwardLinkButton
            name={t('login.verify_email.button_create_profile')}
            fullWidth
          />
        </Link>
        <Link href='/'>
          <OwardLinkButton
            name={t('login.verify_email.button_go_to_gallery')}
            outlined
            fullWidth
          />
        </Link>
      </LayoutPageCentered>
  );
}
