import 'reflect-metadata'; // Import only once
import { Container } from 'inversify';
import { StoresBindings } from './keys';
import {
  ArtworkStore, LayoutStore, UserStore, CardStore, FilterStore,
  MessageStore, ModalStore, PopupStore
} from '@stores/.';

export const createContainer = (): Container => {
  const container = new Container();

  container.bind<ArtworkStore>(StoresBindings.ARTWORK)
    .to(ArtworkStore)
    .inSingletonScope();

  container.bind<LayoutStore>(StoresBindings.LAYOUT)
    .to(LayoutStore)
    .inSingletonScope();

  container.bind<UserStore>(StoresBindings.USER)
    .to(UserStore)
    .inSingletonScope();

  container.bind<CardStore>(StoresBindings.CARD)
    .to(CardStore)
    .inSingletonScope();

  container.bind<FilterStore>(StoresBindings.FILTER)
    .to(FilterStore)
    .inSingletonScope();

  container.bind<MessageStore>(StoresBindings.MESSAGE)
    .to(MessageStore)
    .inSingletonScope();

  container.bind<ModalStore>(StoresBindings.MODAL)
    .to(ModalStore)
    .inSingletonScope();

  container.bind<PopupStore>(StoresBindings.POPUP)
    .to(PopupStore)
    .inSingletonScope();

  return container;
}
