import { ReactNode, useState } from 'react';
import styles from './Switch.module.scss';
import classNames from 'classnames';
import React from 'react';
import { Label } from '@components/.';
import { SelectOption } from '../Select';

interface SwitchProps {
  active: boolean;
  onChange: any;
  label?: string;
  dark?: boolean;
  blue?: boolean;
  id?: string;
}

export const OwardSwitch: React.FC<SwitchProps> = props => {
  return (
    <div className={classNames(styles.mainContainer)}>
      {
        props.label &&
        < div className={classNames(styles.labelContainer)}>
          <p className={classNames(styles.label)}>
            {props.label}
          </p>
        </div>

      }
      <div className={classNames(styles.switchContainer)}>
        <label className={classNames(styles.switch)}>
          <input
            type='checkbox'
            checked={props.active}
            onChange={props.onChange}
            id={props.id}
          />
          <span className={classNames(
            styles.slider,
            styles.round,
            { [styles.dark]: props.dark },
            { [styles.blue]: props.blue }
          )}></span>
        </label>
      </div>
    </div >
  );
}

interface OwardFormSwitchProps {
  id: string;
  label: string;
  description?: string | ReactNode;
  error?: string;
  formik: any;
}

export const OwardFormSwitch: React.FC<OwardFormSwitchProps> = props => {
  const error = props.error ?? (props.formik.touched[props.id] && props.formik.errors[props.id]);

  return (
    <React.Fragment>
      <div className={classNames('field', styles.formSwitchContainer)}>
        <div className={classNames(styles.labelAndSwitchContainer)}>
          <Label name={props.label} description={props.description} />
          <div className={classNames(styles.switchContainer)}>
            <OwardSwitch
              id={props.id}
              active={props.formik.values[props.id]}
              onChange={props.formik.handleChange}
              blue
            />
          </div>
        </div>
        {
          error &&
          <div className={styles.errorContainer}>
            <p className={styles.error}>{error}</p>
          </div>
        }
      </div>

    </React.Fragment>
  );
}

interface SwitchMultipleProps {
  options: SelectOption[];
  onChange: (option: SelectOption) => void;
  activeOption?: any;
  black?: boolean;
  stackOnMobile?: boolean;
  small?: boolean;
}

export const OwardSwitchMultiple: React.FC<SwitchMultipleProps> = props => {
  return (
    <div className={classNames(
      styles.multipleMainContainer,
      props.black && styles.multipleMainContainerBlack,
      props.stackOnMobile && styles.multipleMainContainerStackOnMobile,
    )}>
      {
        props.options.map((option, index) =>
          <div
            className={classNames(
              styles.optionContainer,
              option.value === props.activeOption ? styles.optionActive : styles.optionNoActive,
            )}
            onClick={() => {
              if (option.value !== props.activeOption) {
                props.onChange(option);
              }
            }}
            key={index}
          >
            {option.label}
            {
              option.labelBis &&
              <span className={styles.labelBis}>{option.labelBis}</span>
            }
          </div>
        )}
    </div >
  );
}

export const OwardSwitchMultipleFlat: React.FC<SwitchMultipleProps> = props => {
  return (
    <div className={classNames(
      styles.multipleFlatMainContainer,
      props.small && styles.small
    )}>
      {
        props.options.map((option, index) =>
          <div
            className={classNames(
              styles.optionContainer,
              option.value === props.activeOption ? styles.optionActive : styles.optionNoActive,
            )}
            onClick={() => {
              if (option.value !== props.activeOption) {
                props.onChange(option);
              }
            }}
            key={index}
          >
            {option.label}
            {
              option.labelBis &&
              <span className={styles.labelBis}>{` ${option.labelBis}`}</span>
            }
          </div>
        )}
    </div >
  );
}
