import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import editProfileStyles from '../EditProfile/EditProfile.module.scss';
import styles from './EditArtwork.module.scss';
import classNames from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslate } from '@hooks/use-translate';
import {
  OwardButton, OwardFormInput, OwardFormTextArea, ToastError, ToastSucess, ArtworkSize, Label,
  EditProfileHeader, EditProfileSaveOrCancel, EditProfileTitle, entitiesToOptionsWithTranslation, OwardFormSelectLoad, OwardFormSelectLoadMulti, broadcastersToOptions, NeedPortfolio as NeedPortfolio
} from '@components/.';
import { EditArtworkStore, EditProfileStore } from '@components/Pages';
import shallowequal from 'shallowequal';
import { StoresBindings } from '@container/.';
import { useInjection } from '@hooks/.';
import { ModalKey, ModalStore, PopupStore } from '@stores/.';
import { toast } from 'react-toastify';
import { ArtworkController, ArtworkSave, ArtworkUrl, BroadcasterController, FilmsController, Job, JobsController, ProfileType, send, State } from '@oward/openapi';
import { dataURItoJpegBlob, ERR_MSG_UNAUTHORIZED, goToHomePageAndReload, URL_VALIDATION_REGEX } from '@utils/.';
import { PREMIUM_LEVEL, urlToThumbnailUrl } from '@oward/common-utils';
import { EditArtworkCover } from './EditArtworkCover';
import { observer } from 'mobx-react-lite';
import request from 'superagent';
import { EditArtworkSelections } from './EditArtworkSelections';
import { jobsToOptions, OwardInsertYellow } from '@components/Core';
import { ArtworkType } from '@oward/common-enums';
import { EditArtworkPhotos } from './EditArtworkPhotos';
import { action, runInAction } from 'mobx';

const schema = (t: any) => {
  return (
    Yup.object().shape({
      name: Yup.string().required(t('forms.required')),
      videoLinkUrl: Yup.string().required(t('forms.required')).matches(URL_VALIDATION_REGEX, t('forms.url.not_valid')),
      jobs: Yup.array().test({ message: t('edit_artwork.jobs_at_least_one'), test: (arr: Job[]) => arr.length > 0 }),
      filmName: Yup.string().required(t('forms.required')),
      filmType: Yup.object().required(t('forms.required')),
      filmReleaseYear: Yup.number().required(t('forms.required')).max(2050, t('forms.year_valid')).min(0, t('forms.year_valid')),
      filmDirectorName: Yup.string().required(t('forms.required')),
    }))
};

interface EditArtworkProps {
  store: EditArtworkStore;
  editProfileStore: EditProfileStore;
}

export const EditArtwork: React.FC<EditArtworkProps> = observer(props => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  const saveCoverToS3 = async (coverPreview: any, size: ArtworkSize): Promise<string> => {
    if (coverPreview) {
      try {
        props.store.setLoading(t('edit_artwork.loading.saving_picture'));
        let coverName: string = props.store.artwork.path + '-' + size + '-' + new Date().toISOString() + '.jpg';
        let coverS3PutUrl = (await ArtworkController.getCoverPutUrl(coverName)).text;
        await request.put(coverS3PutUrl).send(dataURItoJpegBlob(coverPreview));
        return process.env.ARTWORK_COVER_URL_PREFIX + coverName;
      }
      catch (err) {
        toast.dark(<ToastError msg={t('edit_profile.error.saving_picture', { code: err })} />);
      }
      finally {
        props.store.setLoading(undefined);
      }
    }
    return undefined;
  };

  const formik = useFormik({
    initialValues: {
      name: props.store.artwork.name,
      videoLinkUrl: props.store.artwork.urls?.find(url => (url.type === ArtworkType.VIDEO_LINK && url.state === State.LIVE))?.url,
      description: props.store.artwork.description,
      jobs: props.store.artwork.jobs,
      filmName: props.store.artwork.film.name,
      filmType: props.store.artwork.film.type,
      filmReleaseYear: props.store.artwork.film.releaseYear,
      filmDirectorName: props.store.artwork.film.directorName,
      broadcasters: props.store.artwork.film.broadcasters,
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      if (hasValuesChanged()) {
        let artworkSave: ArtworkSave = {
          id: props.store.artwork.id,
          name: values.name,
          videoLinkUrl: values.videoLinkUrl,
          fetchedImageUrl: props.store.artwork.images.fetched ?? '',
          jobs: values.jobs,
          description: values.description,
          filmName: values.filmName,
          filmType: values.filmType,
          filmReleaseYear: values.filmReleaseYear,
          filmDirectorName: values.filmDirectorName,
          broadcasters: (props.editProfileStore.profile.premiumLevel >= PREMIUM_LEVEL.PORTFOLIO) ? values.broadcasters : undefined,
          needSaveSelections: (props.editProfileStore.profile.premiumLevel >= PREMIUM_LEVEL.PORTFOLIO) && props.store.selectionsChanged,
          selections: (props.editProfileStore.profile.premiumLevel >= PREMIUM_LEVEL.PORTFOLIO) ? props.store.artwork.film.selections : undefined,
          photoUrls: props.store.artwork.urls.filter(url => url.type === ArtworkType.PICTURE_PORTFOLIO),
        }

        artworkSave.largeImageUrl = await saveCoverToS3(props.store.coverPreviewL, ArtworkSize.LARGE);
        artworkSave.mediumLargeImageUrl = await saveCoverToS3(props.store.coverPreviewML, ArtworkSize.MEDIUM_LARGE);
        artworkSave.mediumSmallImageUrl = await saveCoverToS3(props.store.coverPreviewMS, ArtworkSize.MEDIUM_SMALL);
        artworkSave.smallImageUrl = await saveCoverToS3(props.store.coverPreviewS, ArtworkSize.SMALL);

        if (hasValuesChanged()) {
          try {
            props.store.setLoading(t('edit_artwork.loading.saving_artwork'));
            await ArtworkController.saveOne(artworkSave);
            toast.dark(<ToastSucess msg={t('edit_artwork.saved')} />);
          }
          catch (err) {
            if (err instanceof Error && err.message === ERR_MSG_UNAUTHORIZED) {
              popupStore.openInformationPopUp({
                msg: t('global.force_logout'),
                callback: () => { goToHomePageAndReload() }
              })
            }
            else {
              toast.dark(<ToastError msg={t('edit_artwork.error.saving_artwork', { code: err })} />);
            }
          }
          finally {
            props.store.setLoading(undefined);
          }
        }
      }
      else {
        toast.dark(<ToastSucess msg={t('edit_profile.saved_no_modif')} />);
      }
      goBack();
    }
  });

  const goBack = action(() => {
    props.editProfileStore.editArtwork = undefined;
    props.editProfileStore.refreshProfile();
  });

  const hasValuesChanged = (): boolean => {
    return (
      !shallowequal(formik.initialValues, formik.values) ||
      artworkPhotosChanged ||
      props.store.selectionsChanged ||
      props.store.coverPreviewL ||
      props.store.coverPreviewML ||
      props.store.coverPreviewMS ||
      props.store.coverPreviewS
    );
  }

  const confirmationBeforeLeaving = (callback?: () => void) => {
    if (hasValuesChanged()) {
      popupStore.openConfirmationPopUp({
        msg: t('edit_profile.go_back_confirm'),
        callback: callback ?? goBack
      });

    }
    else {
      callback ? callback() : goBack();
    }
  }

  const [showEditCover, setShowEditCover] = useState(false);
  const [artworkPhotosChanged, setArtworkPhotosChanged] = useState(false);

  return (
    <div className={editProfileStyles.formMainContainer}>
      <div className={classNames(editProfileStyles.contentContainer)}>
        <EditProfileHeader
          title={t('edit_artwork.title', { name: props.store.artwork.name })}
          onGoBack={() => { confirmationBeforeLeaving() }}
          onGoBackStr={t('edit_artwork.on_go_back_str')}
        />
        <form className={classNames(editProfileStyles.formContentContainer)} onSubmit={formik.handleSubmit} >
          <OwardFormInput
            id='name'
            type='name'
            label={t('edit_artwork.name') + ' *'}
            description={t('edit_artwork.name_description', { artworkName: formik.initialValues.filmName })}
            icon='far fa-user'
            onChange={action(value => { props.store.artwork.name = value })} // For Preview
            formik={formik}
          />
          <OwardFormInput
            id='videoLinkUrl'
            type='url'
            label={t('edit_artwork.link') + ' *'}
            description={t('edit_artwork.link_explanation')}
            onChange={async value => {
              let thumbnail = await urlToThumbnailUrl(value);
              runInAction(() => {
                if (value) {
                  props.store.artwork.urls.find(url => url.type === ArtworkType.VIDEO_LINK).url = value;
                }
                props.store.artwork.images.fetched = thumbnail;
              });
            }} // For Preview
            formik={formik}
          />
          <div className={styles.artworkCoverLabelContainer}>
            <div className={styles.label}>
              <Label name={t('edit_artwork.cover')} />
            </div>
            {
              !showEditCover &&
              <p
                className={styles.customize}
                onClick={() => { setShowEditCover(true) }}
              >
                {t('edit_artwork.cover_customize')}
              </p>
            }
          </div>
          <div style={{ paddingBottom: '2rem' }}>
            {
              !showEditCover &&
              <p className={styles.description}>{t('edit_artwork.cover_description')}</p>
            }
            {
              showEditCover &&
              <React.Fragment>
                <p className={styles.description}>{t('edit_artwork.cover_explanation')}</p>
                <EditArtworkCover size={ArtworkSize.LARGE} store={props.store} />
                <EditArtworkCover size={ArtworkSize.MEDIUM_LARGE} store={props.store} />
                <EditArtworkCover size={ArtworkSize.MEDIUM_SMALL} store={props.store} />
                <EditArtworkCover size={ArtworkSize.SMALL} store={props.store} />
              </React.Fragment>
            }
          </div>
          {
            props.editProfileStore.profile.type === ProfileType.PRO &&
            <OwardFormSelectLoadMulti
              id='jobs'
              label={t('edit_artwork.jobs')}
              description={t('edit_artwork.jobs_explanation')}
              fetchFunction={JobsController.list(locale, props.editProfileStore.profile.type, props.editProfileStore.profile.gender)}
              entitiesToOptions={(entityList) => { return jobsToOptions(entityList, true) }}
              onChange={action(value => { props.store.artwork.jobs = value })} // For Preview
              isNotClearable
              formik={formik}
            />
          }
          <OwardFormTextArea
            id='description'
            label={t('edit_artwork.description')}
            description={t('edit_artwork.description_explanation')}
            onChange={action(value => { props.store.artwork.description = value; })} // For Preview
            formik={formik}
            rows={4}
          />
          <hr />
          <EditArtworkPhotos
            artwork={props.store.artwork}
            setLoading={action(loading => {
              loading ?
                props.store.loading = t('edit_artwork.loading.saving_picture') :
                props.store.loading = undefined
            })}
            photosChanged={() => setArtworkPhotosChanged(true)}
          />
          <hr />

          <EditProfileTitle title={t('edit_film.title', { name: props.store.artwork.film.name })} />
          <OwardInsertYellow>
            {t('edit_film.warning', { name: formik.initialValues.filmName })}
          </OwardInsertYellow>
          <OwardFormInput
            id='filmName'
            label={t('edit_film.name') + ' *'}
            onChange={action(value => { props.store.artwork.film.name = value })} // For Preview
            formik={formik}
          />
          <OwardFormSelectLoad
            id='filmType'
            label={t('edit_film.type') + ' *'}
            fetchFunction={FilmsController.findTypes()}
            entitiesToOptions={entities => entitiesToOptionsWithTranslation(entities, locale)}
            onChange={action(value => { props.store.artwork.film.type = value })} // For Preview
            formik={formik}
          />
          <OwardFormInput
            id='filmReleaseYear'
            type='number'
            label={t('edit_film.year') + ' *'}
            description={t('edit_film.year_desc')}
            onChange={action(value => { props.store.artwork.film.releaseYear = parseInt(value) })} // For Preview
            formik={formik}
          />
          <OwardFormInput
            id='filmDirectorName'
            type='name'
            label={t('edit_film.director') + ' *'}
            description={t('edit_film.director_desc')}
            onChange={action(value => { props.store.artwork.film.directorName = value })} // For Preview
            formik={formik}
          />
          <NeedPortfolio
            premiumLevel={props.editProfileStore.profile.premiumLevel}
            profileId={props.editProfileStore.profile.id}
            text={t('edit_film.need_portfolio')}
            onClick={() => confirmationBeforeLeaving(() => {
              router.push(
                `/[lang]/subscriptions/[profileId]`,
                `/${locale}/subscriptions/${props.editProfileStore.profile.id}`
              );
            })}
          >
            <OwardFormSelectLoadMulti
              id='broadcasters'
              label={t('edit_film.broadcasters')}
              description={
                <p className={editProfileStyles.createEntity}>
                  {t('edit_profile.create_intro')}
                  <span
                    className={editProfileStyles.click}
                    onClick={() => modalStore.openModalNewStack(router, ModalKey.NEW_BROADCASTER, 1)}
                  >{t('edit_profile.create_click')}</span>
                  {t('edit_profile.create_outro')}
                </p>
              }
              reload
              fetchFunction={BroadcasterController.find(locale)}
              entitiesToOptions={(entityList) => { return broadcastersToOptions(entityList, true) }}
              onChange={action(value => { props.store.artwork.film.broadcasters = value })} // For Preview
              formik={formik}
            />
            <EditArtworkSelections store={props.store} />
          </NeedPortfolio>
          <hr />
        </form>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <OwardButton
            name={t('edit_artwork.delete')}
            red
            confirmation={t('edit_artwork.confirm_delete', { name: props.store.artwork.name })}
            onClick={async () => {
              try {
                await send(ArtworkController.deleteArtwork(props.store.artwork.id));
                goBack();
              } catch (err) {
                if (err instanceof Error && err.message === ERR_MSG_UNAUTHORIZED) {
                  popupStore.openInformationPopUp({
                    msg: t('global.force_logout'),
                    callback: () => { goToHomePageAndReload() }
                  })
                }
                else {
                  toast.dark(<ToastError msg={t('global.error_retry')} />);
                }
              }
            }} />
        </div>
      </div>
      <form onSubmit={formik.handleSubmit} className={editProfileStyles.formFooterContainer}>
        <EditProfileSaveOrCancel onCancel={() => { confirmationBeforeLeaving() }} forArtwork formik={formik} />
      </form>
    </div>
  )
})
