import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { PaymentProfile, State, User, UserController, UserRole, VerificationState } from '@oward/openapi';
import { Section, Divider, SubmitOrCancel, NavigationContext, FormComment } from '@components/admin'
import { EditProps } from '../types';
import { OwardFormInput, OwardFormSelect, Heading } from '@components/Core';
import { useTranslate } from '@hooks/.';
import { stateToEmoji, validationStateToEmoji } from '@oward/common-utils';
import { ProfileListForUser } from '../Profiles/ProfileList';
import moment from 'moment';
import { UserType } from '@oward/common-enums';

export const EditUser = observer((props: EditProps<User>) => {

  const { t } = useTranslate();
  const [error, setError] = useState(false);
  const navigation = useContext(NavigationContext);

  const formik = useFormik({
    initialValues: {
      email: props.item.email,
      firstName: props.item.infos?.firstName,
      lastName: props.item.infos?.lastName,
      phoneNumber: props.item.infos?.phoneNumber,
      state: props.item.state,
      role: props.item.role,
      type: props.item.type,
      isEmailVerified: props.item.isEmailVerified,
    },
    onSubmit: async values => {
      props.item.email = values.email;
      props.item.infos.firstName = values.firstName;
      props.item.infos.lastName = values.lastName;
      props.item.infos.phoneNumber = values.phoneNumber;
      props.item.state = values.state;
      props.item.role = values.role;
      props.item.type = values.type;
      props.item.isEmailVerified = values.isEmailVerified;
      props.item.paymentProfiles = undefined; // We don't wanna save payment, just for visualisation
      try {
        await UserController.saveOne(props.item);
        if (props.notify) { props.notify(props.item) };
        setError(false);
        navigation.unpop();
      } catch {
        setError(true);
      }
    }
  });

  return (
    <Section
      title={`Edit ${formik.initialValues.email} user`}
      subtitle='Change your entity properties'
      childrenFullWidth
    >
      <form className="box" onSubmit={formik.handleSubmit} >
        <Heading>Infos</Heading>
        <OwardFormInput
          id='email'
          type='email'
          label={t('forms.email.label')}
          icon='far fa-envelope'
          placeholder={t('forms.email.placeholder')}
          formik={formik}
        />
        <OwardFormInput
          id='firstName'
          label={t('forms.first_name.label')}
          icon='fas fa-user'
          placeholder={t('forms.first_name.placeholder')}
          formik={formik}
        />
        <OwardFormInput
          id='lastName'
          label={t('forms.last_name.label')}
          icon='fas fa-user'
          placeholder={t('forms.last_name.placeholder')}
          formik={formik}
        />
        <OwardFormInput
          id='phoneNumber'
          label={t('forms.phone_number.label')}
          icon='fas fa-phone'
          placeholder={t('forms.phone_number.placeholder')}
          formik={formik}
        />
        <Divider />
        <Heading>ADMIN</Heading>
        <OwardFormSelect
          id='state'
          label='State'
          options={Object.values(State).map((state) => {
            return (
              {
                value: state,
                label: state.concat(' ', stateToEmoji(state))
              });
          })}
          formik={formik}
        />
        <OwardFormSelect
          id='role'
          label='Role'
          options={Object.values(UserRole).map((role) => {
            return (
              {
                value: role,
                label: role.charAt(0) + role.substring(1).toLowerCase()
              });
          })}
          formik={formik}
        />
        <OwardFormSelect
          id='type'
          label='Type'
          options={Object.values(UserType).map((type) => {
            return (
              {
                value: type,
                label: type.charAt(0) + type.substring(1).toLowerCase()
              });
          })}
          formik={formik}
        />
        <OwardFormSelect
          id='isEmailVerified'
          label='Email Verifification State'
          options={Object.values(VerificationState).map((verificationState) => {
            return (
              {
                value: verificationState,
                label: verificationState
              });
          })}
          formik={formik}
        />
        <Divider />
        <Heading>PROFILES</Heading>
        <FormComment>
          <p>{`List of ALL ${formik.initialValues.email} profiles in database. WARNING LIST NOT AUTOMATICALLY RELOADED`}</p>
        </FormComment>
        <ProfileListForUser user={props.item} />
        <Divider />
        <Heading>PAYMENT FOR PROFILES</Heading>
        <FormComment>
          <p>{`List of ALL ${formik.initialValues.email} payment intents for profiles in database. WARNING LIST NOT AUTOMATICALLY RELOADED`}</p>
        </FormComment>
        <PaymentProfileListForUser user={props.item} />
        <SubmitOrCancel error={error} />
      </form >
    </Section >
  )
});

interface PaymentProfileListForUserProps {
  user: User;
}

const PaymentProfileListForUser: React.FC<PaymentProfileListForUserProps> = props => {
  const { locale } = useTranslate();

  return (
    <React.Fragment>
      {
        props.user.paymentProfiles?.length > 0 ?
          <table className='table is-fullwidth'>
            <thead>
              <tr>
                <th>Id</th>
                <th>Profile</th>
                <th>Amount</th>
                <th>Premium Level</th>
                <th>Creation Date</th>
                <th>Update Date</th>
                <th>ValidationState</th>
                <th>Promo Code</th>
              </tr>
            </thead>
            <tbody>
              {

                props.user.paymentProfiles?.map((payment: PaymentProfile, i) =>
                  <tr key={i}>
                    <td><p>
                      {payment.id}
                    </p></td>
                    <td><p>
                      {payment.profile.id + ' - ' + payment.profile.name}
                    </p></td>
                    <td><p>
                      {(payment.amount / 100).toLocaleString(locale, { style: 'currency', currency: 'EUR' })}
                    </p></td>
                    <td><p>
                      {payment.premiumLevel.toString()}
                    </p></td>
                    <td><p>
                      {moment(payment.creationDate).format('DD MMM yyyy HH:mm')}
                    </p></td>
                    <td><p>
                      {moment(payment.updateDate).format('DD MMM yyyy HH:mm')}
                    </p></td>
                    <td><p>
                      {payment.validationState.concat(' ', validationStateToEmoji(payment.validationState))}
                    </p></td>
                    <td><p>
                      {payment.promoCode?.code ? `${payment.promoCode.id} - ${payment.promoCode.code}` : '-'}
                    </p></td>
                  </tr>
                )

              }
            </tbody>
          </table>
          :
          <p>No payment yet</p>
      }
    </React.Fragment >
  );
}

