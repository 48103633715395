import React from 'react';
import { AssociationController } from '@oward/openapi';
import { EntityCount, Section } from '@components/admin';

export const AssociationStatistics: React.FC = () => {

  return (
    <React.Fragment>
      <Section
        title='Statistics'
        subtitle='Basic statistics about associations'>
        <table className="table is-hoverable">
          <thead>
            <tr>
              <th>Key</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Associations Count</td>
              <td><EntityCount getCountFunction={AssociationController.count} /></td>
            </tr>
          </tbody>
        </table>
      </Section>
    </React.Fragment>
  );
}
