import React from 'react';

import { SubscriptionList } from './SubscriptionList';

export const SubscriptionsComponent: React.FC = () => {

  return (
    <React.Fragment>
      <SubscriptionList />
    </React.Fragment>
  );
}
