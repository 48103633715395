
import styles from './LayoutPage.module.scss';
import classNames from 'classnames';
import { TitlePage } from '@components/.';
import { OwardLoader } from '../Loader';
import React from 'react';
import { useTranslate } from '@hooks/.';
import { SubtitlePage } from '../Subtitle';

interface LayoutPageProps {
  title?: string;
  subtitle?: string;
  noPadding?: boolean;
  centered?: boolean;
  children?: React.ReactNode;
}

export const LayoutPage: React.FC<LayoutPageProps> = props => {
  return (
    <div className={classNames(!props.noPadding && 'container', styles.mainCenteredContainer)}>
      {
        props.title &&
        <TitlePage title={props.title} />
      }
      {
        props.subtitle &&
        <SubtitlePage subtitle={props.subtitle} />
      }
      <div className={classNames(styles.contentContainer, props.noPadding && styles.noPadding, props.centered && styles.centered)}>
        {props.children}
      </div >
    </div >
  )
}

interface LayoutPageCenteredProps {
  title?: string;
  subtitle?: string;
  isVerticallyCentered?: boolean;
  children?: React.ReactNode;
}

export const LayoutPageCentered: React.FC<LayoutPageCenteredProps> = props => {
  return (
    <div className={classNames(
      'hero container',
      props.isVerticallyCentered && 'is-fullheight-with-navbar',
      props.isVerticallyCentered ? styles.mainCenteredContainerVerticallyCentered : styles.mainCenteredContainer
    )}>
      <div className={classNames('columns is-centered', styles.columnsContainer)} >
        <div className='column is-1'>
        </div>
        <div className={classNames('column is-8-tablet is-7-widescreen is-6-fullhd', styles.contentContainer)}>
          {
            props.title &&
            <TitlePage title={props.title} />
          }
          {
            props.subtitle &&
            <SubtitlePage subtitle={props.subtitle} />
          }
          {props.children}
        </div>
        <div className='column is-1'>
        </div>
      </div >
    </div >
  )
}

interface LayoutPageEditProfileProps {
  leftComponent: React.ReactElement;
  rightComponent: React.ReactElement;
  loading?: string;
  error?: string;
  noBorder?: boolean;
}

export const LayoutPageEditProfile: React.FC<LayoutPageEditProfileProps> = props => {
  const { t } = useTranslate();
  return (
    <div className={classNames('columns is-desktop is-gapless', styles.editProfileMainContainer)} >
      {props.error ?
        <div className={styles.errorContainer}>
          <p>{props.error}</p>
          <br />
          <p>{t('global.error_contact')}</p>
        </div>
        :
        <React.Fragment>
          <OwardLoader loading={props.loading !== undefined} message={props.loading} />
          <div className='column is-12 is-6-desktop is-7-widescreen'>
            {props.leftComponent}
          </div>
          <div className={classNames('column', !props.noBorder && styles.border)}>
            {props.rightComponent}
          </div>
        </React.Fragment>
      }
    </div>
  )
}

interface LayoutPageImageOnLeftProps {
  imageComponent: React.ReactElement;
  rightComponent: React.ReactElement;
}

export const LayoutPageImageOnLeft: React.FC<LayoutPageImageOnLeftProps> = props => {
  const { t } = useTranslate();
  return (
    <div className={classNames(styles.imageOnLeftMainContainer)} >
      <React.Fragment>
        <div className={styles.leftContainer}>
          {props.imageComponent}
        </div>
        <div className={styles.rightContainer}>
          <div className={classNames('columns is-centered', styles.columnsContainer)} >
            <div className='column is-1'>
            </div>
            <div className={classNames('column is-8-tablet is-12-desktop is-10-widescreen is-8-fullhd', styles.rightContentContainer)}>
              {props.rightComponent}
            </div>
            <div className='column is-1'>
            </div>
          </div >
        </div>
      </React.Fragment>
    </div>
  )
}
