import React from 'react';
import styles from './SubscriptionsElements.module.scss';
import { useTranslate } from '@hooks/.';
import { useState } from 'react';
import classNames from 'classnames';
import { Profile, ProfileSubscriptionsPrice } from '@oward/openapi';
import { observer } from 'mobx-react-lite';
import { OwardButton, OwardSwitchMultipleFlat } from '@components/Core';
import { PREMIUM_LEVEL, priceToString, RecurringInterval } from '@oward/common-utils';
import { useRouter } from 'next/router';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { onErrorImage, remSizeToPixelNumber } from '@utils/utils';
import { Embed } from '@components/Embed';
import { Link } from '@components/I18n';

interface SubscriptionCardsProps {
  profile?: Profile;
  prices: ProfileSubscriptionsPrice[];
  ctaNetwork: React.ReactNode;
  ctaPortfolio: React.ReactNode;
  interval: RecurringInterval;
  setInterval: (interval: RecurringInterval) => void;
}

export const getPrice = (prices: ProfileSubscriptionsPrice[], recurringInterval: RecurringInterval, premiumLevel: PREMIUM_LEVEL): ProfileSubscriptionsPrice => {
  return prices.find(price =>
    (price.recurringInterval as RecurringInterval) === recurringInterval &&
    price.premiumLevel === premiumLevel
  )
}

export const SubscriptionCards = observer((props: SubscriptionCardsProps) => {
  const { locale, t } = useTranslate();

  const NetworkCardList = () => {
    return (
      <div className={styles.includedListContainer}>
        <p className={styles.included}>
          {t('subscriptions.cards.network.included')}
        </p>
        <p className={styles.includedList}>{t('subscriptions.cards.network.included_1')}</p>
        <p className={styles.includedList}>{t('subscriptions.cards.network.included_2')}</p>
        <p className={styles.includedList}>{t('subscriptions.cards.network.included_3')}</p>
        <p className={styles.included}>
          {t('subscriptions.cards.network.not_included')}
        </p>
        <p className={styles.includedList}>{t('subscriptions.cards.network.not_included_1')}</p>
        <p className={styles.includedList}>{t('subscriptions.cards.network.not_included_2')}</p>
        <p className={styles.includedList}>{t('subscriptions.cards.network.not_included_3')}</p>
      </div>
    );
  }

  const PortfolioCardList = () => {
    return (
      <div className={styles.includedListContainer}>
        <p className={styles.included}>
          {t('subscriptions.cards.portfolio.included')}
        </p>
        <p className={styles.includedList}>{t('subscriptions.cards.portfolio.included_1')}</p>
        <p className={styles.includedList}>{t('subscriptions.cards.portfolio.included_2')}</p>
        <p className={styles.includedList}>{t('subscriptions.cards.portfolio.included_3')}</p>
        <p className={styles.includedList}>{t('subscriptions.cards.portfolio.included_4')}</p>
        <p className={styles.includedList}>{t('subscriptions.cards.portfolio.included_5')}</p>
        <p className={styles.includedList}>{t('subscriptions.cards.portfolio.included_6')}</p>
      </div>
    );
  }

  return (
    <div className={styles.subscriptionCardsContainer}>
      {/*
      Uncomment to re-active month/annual pricing interval choice
        <div >
        <OwardSwitchMultipleFlat
          options={[
            { value: 'month', label: t('subscriptions.cards.month') },
            { value: 'year', label: t('subscriptions.cards.year'), labelBis: t('subscriptions.cards.free_months') }
          ]}
          onChange={(option) => { props.setInterval(option.value) }}
          activeOption={props.interval}
        />
      </div>
        */}
      <div className={styles.subscriptionCards}>
        <SubscriptionCard
          title={t('subscriptions.cards.network.title')}
          subtitle={t('subscriptions.cards.network.subtitle')}
          priceString={priceToString(0, locale)}
          cta={props.ctaNetwork}
          underCtaText={t('subscriptions.cards.network.under_cta')}
          featureList={<NetworkCardList />}
        />
        <SubscriptionCard
          title={t('subscriptions.cards.portfolio.title')}
          subtitle={t('subscriptions.cards.portfolio.subtitle')}
          priceString={priceToString(getPrice(props.prices, props.interval, PREMIUM_LEVEL.PORTFOLIO).amount / 100, locale)}
          intervalString={t(`subscriptions.cards.per_${props.interval}`)}
          cta={props.ctaPortfolio}
          underCtaText={t('subscriptions.cards.portfolio.under_cta')}
          featureList={<PortfolioCardList />}
          blueBorder
        />
      </div>
    </div>
  )
});

interface SubscriptionCardProps {
  title: string;
  subtitle: string;
  priceString: string;
  intervalString?: string;
  cta: React.ReactNode;
  underCtaText: string;
  featureList: React.ReactNode;
  blueBorder?: boolean;
}
const SubscriptionCard: React.FC<SubscriptionCardProps> = observer(props => {
  const { t, locale } = useTranslate();

  return (
    <div
      className={classNames(
        styles.subscriptionCard,
        props.blueBorder && styles.blueBorder
      )}
    >
      <p className={styles.title}>{props.title}</p>
      <p className={styles.subtitle}>{props.subtitle}</p>
      <p className={styles.price}>
        <span className={styles.priceAmount}>
          {props.priceString}
        </span>
        {props.intervalString ?? undefined}
      </p>
      <div className={styles.ctaContainer}>{props.cta}</div>
      <p className={styles.underCta}>{props.underCtaText}</p>
      {props.featureList}
    </div>
  );
});

export const SubscriptionPaymentMethods = () => {
  const { t } = useTranslate();

  return (
    <div className={`${styles.paymentMethodMainContainer}`}>
      <div className={styles.paymentMethodContainer}>
        <div className={styles.stripeContainer}>
          <p className={styles.subtitle}>{t('subscriptions.payment_methods.stripe.subtitle')}</p>
          <p className={styles.text}>{t('subscriptions.payment_methods.stripe.text')}</p>
          <div className={styles.imageContainer}>
            <LazyLoadImage
              src={'https://cdn-oward-common-images.oward.co/pricing/payment_methods.png'}
              width={remSizeToPixelNumber(styles.paymentMethodImageWidth)}
              height={remSizeToPixelNumber(styles.paymentMethodImageHeight)}
              className={styles.image}
              alt={'Card payments handled by Stripe'}
              effect='blur'
              onError={onErrorImage}
            />
          </div>
        </div>
        <div className={styles.sslContainer}>
          <p className={styles.subtitle}>{t('subscriptions.payment_methods.ssl.subtitle')}</p>
          <p className={styles.text}>{t('subscriptions.payment_methods.ssl.text')}</p>
          <div className={styles.imageContainer}>
            <LazyLoadImage
              src={'https://cdn-oward-common-images.oward.co/pricing/ssl-secured.png'}
              width={remSizeToPixelNumber(styles.paymentMethodImageWidth)}
              height={remSizeToPixelNumber(styles.paymentMethodImageHeight)}
              className={styles.image}
              alt={'SSL Secured payments'}
              effect='blur'
              onError={onErrorImage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export const SubscriptionVideoEmbed = () => {
  const { t } = useTranslate();
  return (
    <div className={`${styles.videoEmbedMainContainer} ${styles.pageSectionContainer}`}>
      <h2 className={styles.pageSectionTitle}>
        {t('subscriptions.video_embed.title')}
      </h2>
      <div className={styles.embedContainer}>
        <Embed url={'https://vimeo.com/804357306'} />
      </div>
    </div>
  )
}

export const SubscriptionsFaq: React.FC = () => {
  const { t } = useTranslate();

  return (
    <div className={`${styles.mainFaqContainer} ${styles.pageSectionContainer}`}>
      <h2 className={styles.pageSectionTitle}>{t('subscriptions.faq.title')}</h2>
      <AccordionFaq
        question={t('subscriptions.faq.question_1')}
        answer={t('subscriptions.faq.answer_1')}
        iconName='fas fa-chevron-right'
      />
      <AccordionFaq
        question={t('subscriptions.faq.question_2')}
        answer={t('subscriptions.faq.answer_2')}
        iconName='fas fa-chevron-right'
      />
      <AccordionFaq
        question={t('subscriptions.faq.question_3')}
        answer={t('subscriptions.faq.answer_3')}
        iconName='fas fa-chevron-right'
      />
      <AccordionFaq
        question={t('subscriptions.faq.question_4')}
        answer={t('subscriptions.faq.answer_4')}
        iconName='fas fa-chevron-right'
      />
      <AccordionFaq
        question={t('subscriptions.faq.question_5')}
        answer={t('subscriptions.faq.answer_5')}
        iconName='fas fa-chevron-right'
      />
      <AccordionFaq
        question={t('subscriptions.faq.question_6')}
        answer={t('subscriptions.faq.answer_6')}
        iconName='fas fa-chevron-right'
      />
      <AccordionFaq
        question={t('subscriptions.faq.question_7')}
        answer={t('subscriptions.faq.answer_7')}
        iconName='fas fa-chevron-right'
      />
      <AccordionFaq
        question={t('subscriptions.faq.question_8')}
        answer={t('subscriptions.faq.answer_8')}
        iconName='fas fa-chevron-right'
      />
      <AccordionFaq
        question={t('subscriptions.faq.question_9')}
        answer={t('subscriptions.faq.answer_9')}
        iconName='fas fa-chevron-right'
      />
      <AccordionFaq
        question={t('subscriptions.faq.question_10')}
        answer={t('subscriptions.faq.answer_10')}
        iconName='fas fa-chevron-right'
      />
      <AccordionFaq
        question={t('subscriptions.faq.question_11')}
        answer={t('subscriptions.faq.answer_11')}
        iconName='fas fa-chevron-right'
      />
      <AccordionFaq
        question={t('subscriptions.faq.question_12')}
        answer={t('subscriptions.faq.answer_12')}
        iconName='fas fa-chevron-right'
      />
      <AccordionFaq
        question={t('subscriptions.faq.question_13')}
        answer={t('subscriptions.faq.answer_13')}
        iconName='fas fa-chevron-right'
      />
    </div>
  )
}

interface AccordionFaqProps {
  question: string;
  answer: string;
  iconName?: string;
}

const AccordionFaq: React.FC<AccordionFaqProps> = props => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <React.Fragment>
      <div className={styles.questionMainContainer}>
        <div className={styles.questionContainer} onClick={() => setIsOpen(!isOpen)}>
          <p className={styles.textQuestion}>
            {props.question}
          </p>
          <div className={classNames(styles.angleContainer)}>
            <span className={`icon`}>
              <i className={classNames('fas fa-chevron-right', styles.angle, isOpen && styles.angleRotated)} />
            </span>
          </div>
        </div>
      </div>
      {
        isOpen &&
        <>
          <div className={`${styles.containerAnswer}`}>
            <p className={styles.textAnswer}>
              {props.answer}
            </p>
          </div>
          <hr className={styles.breaklinefaq} />
        </>
      }
    </React.Fragment>
  )
}

export const SubscriptionClientRecommendations = () => {
  const { t } = useTranslate();
  return (
    <div className={`${styles.recommendationsMainContainer} ${styles.pageSectionContainer}`}>
      <h2 className={styles.pageSectionTitle}>
        {t('subscriptions.recommendations.title')}
      </h2>
      <div className={styles.recommendationsContainer}>
        <ClientRecommendation
          avatarFileExtension='emmanuel.jpg'
          name='emmanuel'
        />
        <ClientRecommendation
          avatarFileExtension='matthieu.jpg'
          name='matthieu'
        />
        <ClientRecommendation
          avatarFileExtension='victoria.jpg'
          name='victoria'
        />
        <ClientRecommendation
          avatarFileExtension='fiamma.png'
          name='fiamma'
        />
        <ClientRecommendation
          avatarFileExtension='xavierpiroelle.jpg'
          name='xavierpiroelle'
        />
        <ClientRecommendation
          avatarFileExtension='adrien.jpg'
          name='adrien'
        />
        <ClientRecommendation
          avatarFileExtension='karen.jpg'
          name='karen'
        />
        <ClientRecommendation
          avatarFileExtension='hana.jpeg'
          name='hana'
        />
        <ClientRecommendation
          avatarFileExtension='humphrey.jpg'
          name='humphrey'
        />
        <ClientRecommendation
          avatarFileExtension='dimitri.jpeg'
          name='dimitri'
        />
        <ClientRecommendation
          avatarFileExtension='xavierdolleans.jpg'
          name='xavierdolleans'
        />
      </div>
    </div>
  )
}

interface ClientRecommendationProps {
  avatarFileExtension: string;
  name: string;
}

const ClientRecommendation: React.FC<ClientRecommendationProps> = props => {
  const { t } = useTranslate();
  return (
    <div className={styles.recommendationContainer}>
      <div className={styles.avatarContainer}>
        <LazyLoadImage
          src={`https://cdn-oward-common-images.oward.co/pricing/recommendation/${props.avatarFileExtension}`}
          width={remSizeToPixelNumber(styles.avatarSize)}
          height={remSizeToPixelNumber(styles.avatarSize)}
          className={styles.avatar}
          alt={'SSL Secured payments'}
          effect='blur'
          onError={onErrorImage}
        />
      </div>
      <div className={styles.textContainer}>
        <p className={styles.text}>{t(`subscriptions.recommendations.text_${props.name}`)}</p>
      </div>
      <div className={styles.nameContainer}>
        <p className={styles.name}>{t(`subscriptions.recommendations.name_${props.name}`)}</p>
      </div>
    </div>
  )
}

interface SubscriptionsBottomCtaProps {
  cta: React.ReactNode;
}

export const SubscriptionsBottomCta: React.FC<SubscriptionsBottomCtaProps> = props => {
  const { t } = useTranslate();

  return (
    <div className={`${styles.pageSectionContainer}`}>
      <h2 className={styles.pageSectionTitle}>{t('subscriptions.bottom_cta.title')}</h2>
      <div className={styles.bottomCtaButtonContainer}>
        {props.cta}
      </div>
    </div>
  )
}

export const SubscriptionFooter = () => {
  const { t } = useTranslate();

  interface PartnerLogoProps {
    fileName: string;
    alt: string;
  }

  const PartnerLogo: React.FC<PartnerLogoProps> = props => {
    return (
      <div className={styles.logoContainer}>
        <img
          src={`https://cdn-oward-common-images.oward.co/logo/${props.fileName}`}
          width={remSizeToPixelNumber(styles.partnerLogoWidth)}
          height={remSizeToPixelNumber(styles.partnerLogoHeight)}
          className={styles.logo}
          alt={props.alt}
          onError={onErrorImage}
        />
      </div>
    );
  }

  interface FooterLinkProps {
    name: string;
    url: string;
  }

  const FooterLink: React.FC<FooterLinkProps> = props => {
    return (<>
      {
        props.url.startsWith('/') ?
          <Link href={props.url} passHref>
            <p className={styles.link}>{props.name}</p>
          </Link>
          :
          <a href={props.url} target='_blank' rel='noreferrer'>
            <p className={styles.link}>{props.name}</p>
          </a>
      }
    </>);
  }

  return (
    <div className={`${styles.footerMainContainer}`}>
      <div className={styles.topContainer}>
        <div className={styles.firstColumn}>
          <p className={styles.brandName}><span className={`${styles.noLetterSpacing}`}>O</span>WARD</p>
          <p className={styles.explanation}>{t('subscriptions.footer.explanation')}</p>
        </div>
        <div className={styles.column}>
          <p className={styles.columnTitle}>{t('subscriptions.footer.columns_title.2')}</p>
          <FooterLink name={t('subscriptions.footer.links.galleries')} url='/' />
          <FooterLink name={t('subscriptions.footer.links.discover_galleries')} url='https://discover.oward.co' />
          <FooterLink name={t('subscriptions.footer.links.portfolios')} url='/portfolio' />
          <FooterLink name={t('subscriptions.footer.links.discover_portfolios')} url='https://portfolio.oward.co' />
          <FooterLink name={t('subscriptions.footer.links.cgv_cgu')} url='https://discover.oward.co/#cgucgv' />
          <FooterLink name={t('subscriptions.footer.links.data')} url='https://discover.oward.co/#donnees' />
        </div>
        <div className={styles.column}>
          <p className={styles.columnTitle}>{t('subscriptions.footer.columns_title.3')}</p>
          <FooterLink name={t('subscriptions.footer.links.faq')} url='https://discover.oward.co/#faq' />
          <FooterLink name={t('subscriptions.footer.links.doc')} url='https://discover.oward.co/#documentation' />
          <FooterLink name={t('subscriptions.footer.links.contact')} url='https://discover.oward.co/#contact' />
        </div>
        <div className={styles.column}>
          <p className={styles.columnTitle}>{t('subscriptions.footer.columns_title.4')}</p>
          <FooterLink name={t('subscriptions.footer.links.blog')} url='https://blog.oward.co/' />
          <FooterLink name={t('subscriptions.footer.links.who')} url='https://discover.oward.co/#qui-sommes-nous' />
          <FooterLink name={t('subscriptions.footer.links.project')} url='https://discover.oward.co/#vision' />
          <FooterLink name={t('subscriptions.footer.links.press')} url='https://eu1.hubs.ly/H032Qdd0' />
        </div>
        <div className={styles.column}>
          <p className={styles.columnTitle}>{t('subscriptions.footer.columns_title.5')}</p>
          <FooterLink name={t('subscriptions.footer.links.linkedin')} url='https://www.linkedin.com/company/oward-films/' />
          <FooterLink name={t('subscriptions.footer.links.facebook')} url='https://www.facebook.com/oward.films/' />
          <FooterLink name={t('subscriptions.footer.links.instagram')} url='https://www.instagram.com/oward.filmnetwork' />
        </div>
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.logosContainer}>
            <PartnerLogo fileName='bpi.png' alt='BPI France' />
            <PartnerLogo fileName='french-tech.png' alt='French Tech Tremplin' />
            <PartnerLogo fileName='belle-de-mai.png' alt='Incubateur National de la Recherche Publique Belle de Mai' />
            <PartnerLogo fileName='ue.png' alt='UE' />
          </div>
          <p className={styles.partners}>{(t('subscriptions.footer.partners'))}</p>
        </div>
      </div>
      <div className={styles.rightsContainer}>
        <p className={styles.rights}>{(t('subscriptions.footer.rights'))}</p>
      </div>
    </div>
  )
}
