import React from 'react';
import styles from './ModalCommon.module.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { remSizeToPixelNumber } from '@utils/utils';
import { findTranslationOrDefault } from '@oward/common-utils';
import { useTranslate } from '@hooks/.';
import { Profile } from '@oward/openapi';
import { Badge } from '@components/Core';

interface ContactModalProfileProps {
  profile: Profile;
  onClick?: () => void;
  countOfUnreadDiscussions?: number;
}

export const ModalProfileRectangle: React.FC<ContactModalProfileProps> = props => {
  const { t, locale } = useTranslate();

  return (
    <div
      className={`${styles.profileRectangleContainer}`}
      onClick={props.onClick}
    >
      <div className={`${styles.profileContentContainer}`}>
        {
          props.profile.avatarUrl &&
          <div className={styles.avatarContainer}>
            <LazyLoadImage
              src={props.profile.avatarUrl}
              width={remSizeToPixelNumber(styles.avatarSize)}
              height={remSizeToPixelNumber(styles.avatarSize)}
              className={styles.avatar}
              alt={t('alt.avatar_just_name', { name: props.profile.name })}
              effect='blur'
            />
          </div>
        }
        <div className={styles.nameJobContainer}>
          <h2 className={styles.name}>
            {props.profile.name}
          </h2>
          <p className={styles.job}>
            {findTranslationOrDefault(props.profile.job, locale)}
          </p>
        </div>
      </div>
      {
        props.countOfUnreadDiscussions > 0 &&
        <div className={styles.countContainer}>
          <Badge count={props.countOfUnreadDiscussions} staticPosition />
        </div>
      }
    </div>
  )
}
