import React from "react";
import { ReportArtworkList, ReportMessageList, ReportProfileList } from "./ReportList";

export const ReportProfileComponent: React.FC = () => {
  return (
    <React.Fragment>
      <ReportProfileList />
    </React.Fragment>
  );
}

export const ReportArtworkComponent: React.FC = () => {
  return (
    <React.Fragment>
      <ReportArtworkList />
    </React.Fragment>
  );
}

export const ReportMessageComponent: React.FC = () => {
  return (
    <React.Fragment>
      <ReportMessageList />
    </React.Fragment>
  );
}
