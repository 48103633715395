import React, { useContext, useState } from "react";
import { useFormik } from 'formik';
import { observer } from "mobx-react-lite";
import { State, MessageController, Message } from "@oward/openapi";
import { Section, SubmitOrCancel, NavigationContext } from '@components/admin';
import { Heading, OwardFormInput, OwardFormSelect, OwardFormTextArea } from '@components/Core';
import { EditProps } from '../types';
import { stateToEmoji } from '@oward/common-utils';

export const EditMessage = observer((props: EditProps<Message>) => {
  const [error, setError] = useState(false);
  const navigation = useContext(NavigationContext);

  const formik = useFormik({
    initialValues: {
      text: props.item.text,
      url: props.item.url,
      state: props.item.state,
    },
    onSubmit: async values => {
      props.item.text = values.text;
      props.item.url = values.url;
      props.item.state = values.state;
      try {
        await MessageController.saveOneMessageAdmin(props.item);
        if (props.notify) { props.notify(props.item) };
        setError(false);
        navigation.unpop();
      } catch {
        setError(true);
      }
    }
  });

  return (
    <React.Fragment>
      <Section
        title='Edit'
        subtitle='Change your entity properties'
        childrenFullWidth
      >
        <form className='box' onSubmit={formik.handleSubmit} >
          <Heading>General</Heading>
          <OwardFormTextArea
            id='text'
            label={'Text'}
            placeholder={'Text'}
            formik={formik}
          />
          <OwardFormInput
            id='url'
            type='url'
            label={'Url'}
            placeholder={'Url'}
            formik={formik}
          />
          <OwardFormSelect
            id='state'
            label='State *'
            options={Object.values(State).map((state) => {
              return ({ value: state, label: state.concat(' ', stateToEmoji(state)) });
            })}
            formik={formik}
          />
          <SubmitOrCancel error={error} noCancel={props.noCancel} />
        </form >
        <div style={{ height: '8rem' }} />
      </Section >
    </React.Fragment>
  )
});
