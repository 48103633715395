import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';
import { send, Request } from '@oward/openapi';

interface EntityCountProps {
  getCountFunction: () => Request<number>;
}

export const EntityCount: React.FC<EntityCountProps> = props => {
  const [count, setCount] = useState(0);
  const { getCountFunction } = props;

  useEffect(() => {
    (async function getCount() {
      setCount(await send(getCountFunction()));
    })()
  }, [getCountFunction]);

  return (
    <React.Fragment>
      <CountUp end={count} />
    </React.Fragment>
  );
};
