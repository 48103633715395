import React from 'react';
import styles from './Sidebar.module.scss';

interface WithSidebarProps {
  children?: React.ReactNode;
}

export const WithSidebar: React.FC<WithSidebarProps> = props => {

  return (
    <React.Fragment>
      <div className={styles.container}>
        {props.children}
      </div>
    </React.Fragment >
  );
}
