import React, { useEffect } from 'react';
import styles from './GalleryPage.module.scss';
import GalleryBanner from './GalleryBanner';
import GalleryHeader from './GalleryHeader';
import GallerySideBar from './GallerySideBar';
import GalleryCards from './GalleryCards';

import classNames from 'classnames';
import { useInjection, useTranslate } from '@hooks/.';
import { UserStore, LayoutStore, CardStore, ModalStore, ModalKey, FilterStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { observer } from 'mobx-react-lite';
import { CardGetStatus } from '@oward/common-enums';
import { COOKIE_NAME_ZOOM, onTouchDevice } from '@utils/.';
import { useRouter } from 'next/router';
import { OwardButton, WarningProfileNotExposedTopBar } from '@components/Core';
import { GalleryType } from '@oward/common-utils';
import GalleryArtworks from './GalleryArtworks';

export const GalleryPage = observer(() => {
  const router = useRouter();
  const { t, locale } = useTranslate();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const cardStore = useInjection<CardStore>(StoresBindings.CARD);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);

  useEffect(() => {
    // Swipe on mobile to open/close the sidebar
    document.addEventListener('touchstart', handleTouchStart, false);
    document.addEventListener('touchmove', handleTouchMove, false);
    var xDown = null;
    var yDown = null;
    function handleTouchStart(evt) {
      xDown = evt.touches[0].clientX;
      yDown = evt.touches[0].clientY;
    };
    function handleTouchMove(evt) {
      if (!xDown || !yDown) {
        return;
      }

      var xUp = evt.touches[0].clientX;
      var yUp = evt.touches[0].clientY;

      var xDiff = xDown - xUp;
      var yDiff = yDown - yUp;
      if (Math.abs(xDiff) + Math.abs(yDiff) > 100) { //to deal with to short swipes

        if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
          if (xDiff > 0 && layoutStore.sidebarOpened) {/* left swipe */
            layoutStore.openSidebar(false);
          }
          else if (xDiff < 0 && !layoutStore.sidebarOpened) {/* right swipe*/
            layoutStore.openSidebar(true);
          }
        }
        /* reset values */
        xDown = null;
        yDown = null;
      }
    };

    return (
      () => {
        document.removeEventListener('touchstart', handleTouchStart);
        document.removeEventListener('touchmove', handleTouchMove);
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
  * DEACTIVTATED (Card ratio - Zoom)

  useEffect(() => {
    let zoomCookieStr: string = document.cookie?.split('; ')?.find(row => row.startsWith(COOKIE_NAME_ZOOM));
    if (zoomCookieStr !== undefined) {
      let zoom: number = JSON.parse(zoomCookieStr.split('=')[1]);
      layoutStore.scaleCards(zoom);
    }
  }, []);
  */

  useEffect(() => {
    const openNewsletterModal = () => {
      if (
        !userStore.newsletterModalAlreadyShown &&
        !userStore.isLogged &&
        window.location.pathname === `/${locale}` &&
        modalStore.isModalOpened(router) === false
      ) {
        modalStore.popModal(router, ModalKey.NEWSLETTER, 1);
        userStore.setNewsletterModalAlreadyShown();
      }
    };

    if (process.env.NEWSLETTER_MODAL_TIMEOUT_MS) {
      if (onTouchDevice()) {
        setTimeout(openNewsletterModal, parseInt(process.env.NEWSLETTER_MODAL_TIMEOUT_MS));
      }
      document.body.onmouseleave = openNewsletterModal;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router] /* As router is a hook, need to re-evaluate useEffect when router changes */)

  const renderGalleryGrid = () => {
    if (filterStore.isArtworkGallery) {
      return <GalleryArtworks />
    }
    else if (cardStore.cardGetStatus === CardGetStatus.OK) {
      return <GalleryCards />
    }
  }

  return (
    <div className={styles.mainContainer}>
      <div
        id='sidebar-mobile-close'
        className={styles.sidebardMobileClose}
        onClick={() => { layoutStore.openSidebar(false) }}>
      </div>
      <div id='sidebar' className={classNames(
        styles.sidebarContainer,
        { [styles.sidebarContainerSidebarOpened]: layoutStore.sidebarOpened },
        { [styles.sidebarContainerSidebarClosed]: !layoutStore.sidebarOpened }
      )}>
        <GallerySideBar />
      </div>
      <div id='gallery-main-right-container' className={classNames(
        styles.rightContainer,
        { [styles.rightContainerSidebarOpened]: layoutStore.sidebarOpened },
        { [styles.rightContainerSidebarClosed]: !layoutStore.sidebarOpened }
      )}>
        <WarningProfileNotExposedTopBar />
        {/*
          (filterStore.filter?.shortlist === undefined) &&
          <GalleryBanner />
      */}
        <GalleryHeader />
        {renderGalleryGrid()}
        <div id='gallery-bottom-bar' className={styles.bottomBar}>
          <OwardButton
            name={t('global.search')}
            onClick={() => { layoutStore.openOrFlashSidebar() }}
          />
        </div>
      </div>
    </div>
  );
});

export default GalleryPage;
