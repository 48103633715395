import React, { useEffect, useState } from 'react';
import styles from './EditProfile.module.scss';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import { useInjection, useTranslate } from '@hooks/.';
import { LayoutStore, ModalKey, ModalStore, PopupStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import {
  EditProfilePannelProps, ToastError, OwardButton,
} from '@components/.';
import { toast } from 'react-toastify';
import { EditProfileHeader, EditProfileSaveOrCancel } from './EditProfileCommon';
import { Artwork, ArtworkController, ArtworkImagesUrl, ProfileController, send } from '@oward/openapi';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { goToHomePageAndReload, remSizeToPixelNumber } from '@utils/utils';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { EditProfileStore } from '@components/Pages';
import { ARTWORK_COVER_PLACEHOLDER_URL, ERR_MSG_UNAUTHORIZED } from '@utils/.';
import { action, runInAction } from 'mobx';


export const EditProfileArtworks: React.FC<EditProfilePannelProps> = observer(props => {
  const { t } = useTranslate();
  const router = useRouter();
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const [artworksDisplayedTmp, setArtworksDisplayedTmp] = useState<Artwork[]>([]);
  const [artworksNotDisplayedTmp, setArtworksNotDisplayedTmp] = useState<Artwork[]>([]);

  useEffect(() => {
    if (props.store.profile.artworks !== undefined) {
      setArtworksDisplayedTmp(props.store.profile.artworks?.filter(artwork => artwork.displayed === true).slice());
      setArtworksNotDisplayedTmp(props.store.profile.artworks?.filter(artwork => artwork.displayed === false).slice());
    }
  }, [props.store.profile.artworks]);

  const getList = (draggableId: string): Artwork[] => {
    if (draggableId === 'artworkDisplayedList') {
      return artworksDisplayedTmp;
    }
    else {
      return artworksNotDisplayedTmp;
    }
  }

  const onDragEnd = async (result) => {
    const { source, destination } = result;

    // dropped outside of list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const artworks = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );

      if (source.droppableId === 'artworkDisplayedList') {
        let artworksDisplayed: Artwork[] = artworks.map((artwork, index) => { artwork.order = index + 1; return artwork });
        setArtworksDisplayedTmp(artworksDisplayed);
        runInAction(() => {
          props.store.profile.artworks = [...artworksDisplayed, ...artworksNotDisplayedTmp];
        });
      }
      else {
        let artworksNotDisplayed: Artwork[] = artworks.map((artwork, index) => { artwork.order = index + 1; return artwork });
        setArtworksNotDisplayedTmp(artworksNotDisplayed);
        runInAction(() => {
          props.store.profile.artworks = [...artworksDisplayedTmp, ...artworksNotDisplayed];
        });
      }
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );

      // for 'not displayed' order to take into account alraedy 'displayed' artworks
      let numberOfDisplayed: number = 0;

      let artworksDisplayed: Artwork[] = result.artworkDisplayedList?.map(
        (artwork: Artwork, index: number) => {
          runInAction(() => {
            artwork.displayed = true;
            artwork.order = index + 1;
          });
          numberOfDisplayed = index + 1;
          return artwork;
        }
      );
      let artworkNotDisplayed: Artwork[] = result.artworkNotDisplayedList?.map(
        (artwork: Artwork, index: number) => {
          runInAction(() => {
            artwork.displayed = false;
            artwork.order = index + numberOfDisplayed + 1;
          })
          return artwork;
        }
      );

      setArtworksDisplayedTmp(artworksDisplayed);
      setArtworksNotDisplayedTmp(artworkNotDisplayed);
      runInAction(() => {
        props.store.profile.artworks = [...artworksDisplayed, ...artworkNotDisplayed];
      });
    }

    save();
  };

  const save = async () => {
    if (props.store.profile.artworks?.filter(artwork => artwork.displayed === true)?.length > 3) {
      toast.dark(<ToastError msg={t('card.artwork.3_max')} />);
    }
    else {
      try {
        let artworksToSave: Artwork[] = props.store.profile.artworks?.map(
          artwork => {
            // Make a copy of artwork with no film (as we just save 'order' and 'displayed')
            // Copy is necessary so in the preview there still is the 'film'
            let artworkWithNoFilm: Artwork = Object.assign({}, artwork);
            artworkWithNoFilm.film = undefined;
            return artworkWithNoFilm;
          }
        );
        await ProfileController.saveArtworkOrganisation({
          id: props.store.profile.id,
          artworks: artworksToSave
        });
      }
      catch (err) {
        if (err instanceof Error && err.message === ERR_MSG_UNAUTHORIZED) {
          popupStore.openInformationPopUp({
            msg: t('global.force_logout'),
            callback: () => { goToHomePageAndReload() }
          })
        }
        else {
          toast.dark(<ToastError msg={t('edit_profile.error.saving_profile', { code: err })} />);
        }
      }
      finally {
        props.store.setLoading(undefined);
      }
    }
  };

  const resetWithConfirmation = () => {
    props.store.reset();
  }

  return (
    <div className={styles.formMainContainer}>
      <div className={classNames(styles.contentContainer)}>
        <EditProfileHeader
          onGoBack={resetWithConfirmation}
          title={t('edit_profile.' + props.store.activeCategory + '.title', { name: props.store.profile?.name })}
        />
        <div className={classNames(styles.formContentContainer)}>
          <p className={classNames(styles.description)}>{t('edit_profile.artworks.explanation')}</p>
          <p className={styles.artworkSubtitle}>{t('edit_profile.artworks.subtitle_displayed')}</p>
          {
            artworksDisplayedTmp.length > 3 &&
            <p className={styles.moreThan3ArtworksSubtitle}>{t('card.artwork.3_max')}</p>
          }
          <DragDropContext onDragEnd={onDragEnd}>
            <ArtworkDroppable id='artworkDisplayedList' artworks={artworksDisplayedTmp} store={props.store} />
            <p className={styles.artworkSubtitle}>{t('edit_profile.artworks.subtitle_other')}</p>
            <ArtworkDroppable id='artworkNotDisplayedList' artworks={artworksNotDisplayedTmp} store={props.store} />
          </DragDropContext>
          <div className={styles.artworkCreateButtonContainer}>
            <OwardButton
              name={t('edit_profile.artworks.create')}
              onClick={() => {
                modalStore.setEditProfileLocalStore(props.store);
                modalStore.openModalNewStack(router, ModalKey.NEW_ARTWORK, props.store.profile.id);
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.formFooterContainer}>
        <EditProfileSaveOrCancel onSave={resetWithConfirmation} onCancel={resetWithConfirmation} />
      </div>
    </div>
  );
})

interface ArtworkDroppableProps {
  id: string;
  artworks: Artwork[];
  store: EditProfileStore;
}

const ArtworkDroppable: React.FC<ArtworkDroppableProps> = observer(props => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);

  const imageOrOtherSize = (artwork: Artwork) => {
    let goodSize: string = undefined;

    switch (numberOfDisplayed()) {
      case 1:
        goodSize = artwork.images.large;
        break;
      case 2:
        if (artwork.order === 1) {
          goodSize = artwork.images.mediumLarge;
        }
        else if (artwork.order === 2) {
          goodSize = artwork.images.mediumSmall;
        }
        break;
      case 3:
        goodSize = artwork.images.small;
        break;
    }

    return goodSize ??
      artwork.images.large ??
      artwork.images.mediumLarge ??
      artwork.images.mediumSmall ??
      artwork.images.small ??
      artwork.images.fetched ??
      ARTWORK_COVER_PLACEHOLDER_URL;
  }

  const numberOfDisplayed = () => {
    return props.artworks.filter(artwork => artwork.displayed)?.length;
  }

  return (
    <Droppable droppableId={props.id}>
      {provided => (
        <div ref={provided.innerRef} {...provided.droppableProps} className={styles.artworkListContainer}>
          {
            props.artworks.map((artwork: Artwork, i) => {
              return (
                <Draggable draggableId={artwork.id.toString()} index={i} key={artwork.id.toString()}>
                  {
                    (provided, snapshot) => (
                      <div
                        className={classNames(styles.artworkContainer, { [styles.draggingProfileContainer]: snapshot.isDragging })}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className={styles.avatarContainer}>
                          <LazyLoadImage
                            src={imageOrOtherSize(artwork)}
                            width={remSizeToPixelNumber(styles.avatarSize)}
                            height={remSizeToPixelNumber(styles.avatarSize)}
                            className={styles.avatar}
                            alt={'avatar'}
                            effect='blur'
                          />
                        </div>
                        <p className={styles.name}>
                          <span className='has-text-weight-semibold'>{artwork.name}</span>
                          , {artwork.film?.releaseYear === 0 ? t('wip.film') : artwork.film?.releaseYear}
                        </p>
                        <OwardButton
                          transparentBlue
                          name={t('global.edit')}
                          onClick={async () => {
                            try {
                              props.store.setLoading(t('edit_artwork.loading.loading_artwork'));
                              let editArtwork = await send(ArtworkController.findOne(artwork.id, locale));
                              runInAction(() => {
                                props.store.editArtwork = editArtwork;
                              });
                              layoutStore.scrollToElement('edit-profile-menu-scroll-mobile', false, 'auto');
                            }
                            catch (err) {
                              if (err instanceof Error && err.message === ERR_MSG_UNAUTHORIZED) {
                                popupStore.openInformationPopUp({
                                  msg: t('global.force_logout'),
                                  callback: () => { goToHomePageAndReload() }
                                })
                              }
                              else {
                                toast.dark(<ToastError msg={t('edit_artwork.error.loading_artwork', { code: err })} />);
                              }
                            }
                            finally {
                              props.store.setLoading(undefined);
                            }
                          }}
                        />
                      </div>
                    )
                  }
                </Draggable>
              );
            })
          }
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
})

/*
 * Drag And Drop helper functions
 */
// A little function to help us with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number): Artwork[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};


// Moves an item from one list to another list.
const move = (source: any[], destination: any[], droppableSource: any, droppableDestination: any): any => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
