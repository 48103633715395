import React, { useState } from 'react';
import { useFormik } from 'formik';
import { UserController } from '@oward/openapi';
import { Section, Submit, Divider } from '@components/admin';
import { locales } from '@locales/.';
import * as Yup from 'yup';
import { useTranslate } from '@hooks/.';
import { OwardFormInput, OwardLoader, OwardFormSelect } from '@components/Core';
import { ERR_MSG_NOT_FOUND } from '@utils/.';

const schema = (t: any) => {
  return (
    Yup.object().shape({
      email: Yup.string().email(t('forms.email.invalid')).required(t('forms.email.required')),
      locale: Yup.number().required(t('forms.required'))
    }))
};

export const UserPassword: React.FC = () => {
  const { t } = useTranslate();
  const [error, setError] = useState<string>(undefined);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      locale: locales.indexOf('fr')
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      try {
        setLoading(true);
        await UserController.sendResetPasswordEmail(values.email, locales[values.locale]);
        setError(undefined);
      }
      catch (err) {
        if (err instanceof Error && err.message === ERR_MSG_NOT_FOUND) {
          setError(t('login.reset_password.email_not_found', { email: values.email }));
        }
        else {
          setError(t('global.error_retry'));
        }
      }
      finally {
        setLoading(false);
      }
    }
  });

  return (
    <Section
      title='Password'
      subtitle='Send an e-mail to reset password'>
      <form className='box' style={{ position: 'relative' }} onSubmit={formik.handleSubmit} >
        <OwardLoader loading={loading} />
        <OwardFormInput
          id='email'
          type='email'
          icon='far fa-envelope'
          label={t('forms.email.label')}
          placeholder={t('forms.email.placeholder')}
          formik={formik}
        />
        <OwardFormSelect
          id='locale'
          label='Locale'
          placeholder='fr'
          options={Object.values(locales).map((locale, index) => { return ({ value: index, label: locale }) })}
          formik={formik}
        />
        <Divider />
        {
          error &&
          <p style={{ color: 'red' }}>
            {error}
          </p>
        }
        <Submit name={t('global.send')} />
      </form>
    </Section >
  );
}
