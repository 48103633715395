import { useEffect, useState } from 'react';
import styles from './PaymentComponents.module.scss';
import { StoresBindings } from '@container/.';
import { useInjection, useProfilesForUser, useTranslate } from '@hooks/.';
import { ModalStore, UserStore } from '@stores/.';
import { Stripe, loadStripe, StripeElementLocale } from '@stripe/stripe-js';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { countryToCodeOptions, Link, OwardButton, OwardFormInput, OwardFormSelectLoad, OwardInput, OwardLinkButton, OwardLoader, OwardSwitch, PaymentPageStore, ToastSucess } from '@components/.';
import React from 'react';
import { priceToString, URL_FACEBOOK, URL_INSTAGRAM, URL_LINKEDIN } from '@oward/common-utils';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { PHONE_VALIDATION_REGEX } from '@utils/constants';
import { LocationController, send, StripeCustomerDetails, SubscriptionController } from '@oward/openapi';
import { runInAction } from 'mobx';
import { defaultLocale } from '@locales/index';
import { toast } from 'react-toastify';

export interface PaymentPageComponentProps {
  store: PaymentPageStore;
}

export const StripeCheckout: React.FC<PaymentPageComponentProps> = observer(props => {
  const { locale } = useTranslate();
  let stripePromise: Stripe;
  const getStripe = async () => {
    if (!stripePromise) {
      stripePromise = await loadStripe(
        process.env.STRIPE_PUBLISHABLE_KEY,
        { locale: locale as StripeElementLocale }
      );
    }
    return stripePromise;
  }

  return (
    <Elements stripe={getStripe()}>
      {
        props.store.useNewCard ?
          <StripeCheckoutForm store={props.store} />
          :
          <StripeChargeCard store={props.store} />
      }
    </Elements>
  )
});

const StripeCheckoutForm: React.FC<PaymentPageComponentProps> = observer(props => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  const stripe = useStripe();
  const elements = useElements();

  const [disabled, setDisabled] = useState(true);
  const [nameCard, setNameCard] = useState('');

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty || event.error);
    props.store.setError(event.error ? event.error.message : undefined);
  };

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (!nameCard) {
      props.store.setError(t('payment.error_no_name'));
      return;
    }

    let payload;
    try {
      props.store.setLoading(true);
      props.store.setError(undefined);

      payload = await stripe.confirmCardPayment(props.store.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
        receipt_email: userStore.currentUser?.email,
      });

      if (payload.error) {
        props.store.setError(payload.error.message);
      } else {
        props.store.setError(undefined);
        props.store.setSucceeded(true);
        router.query.successful = '1'
        router.push(router);
      }
    }
    catch (err) {
      props.store.setError(t('payment.error_payment', { code: err }));
    }
    finally {
      props.store.setLoading(false);
    }
  };

  return (
    <form className={styles.formContainer} onSubmit={handleSubmit}>
      <OwardLoader loading={props.store.loading} />
      <div className={styles.blockContainer}>
        <p className={styles.title}>{t('payment.payment_method')}</p>
        <p className={styles.secured}>{t('payment.secured')}
          <a className={`icon ${styles.icon}`} href='https://stripe.com/' target='_blank' rel='noreferrer'>
            <i className={'fab fa-stripe'}></i>
          </a>
        </p>
        <OwardInput
          icon='fas fa-user'
          placeholder={t('payment.name_on_card')}
          onChange={(value) => { setNameCard(value) }}
        />
        <div className={styles.cardElementContainer}>
          <CardElement
            className={styles.cardElement}
            onChange={handleChange}
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  fontSize: '16px',
                  color: styles.greyDarker,
                  '::placeholder': {
                    color: styles.placeholderColor,
                  },
                  fontWeight: styles.regular,
                },
                invalid: {
                  color: styles.red,
                },
              },
            }}
          />
        </div>
      </div>
      <div className={styles.footerContainer}>
        <div className={styles.buttonContainer}>
          <OwardButton
            submit
            name={t(
              `payment.pay_button`,
              {
                price: priceToString(props.store.stripePrice.amount / 100, locale)
              })
            }
            deactivated={!stripe || !elements || disabled || props.store.loading || props.store.succeeded}
            outlined={!stripe || !elements || disabled || props.store.loading || props.store.succeeded}
          />
        </div>
        {
          props.store.error &&
          <div className={styles.errorContainer}>
            <p className={styles.error}>{props.store.error}</p>
          </div>
        }
      </div>
    </form>
  )
});

const StripeChargeCard: React.FC<PaymentPageComponentProps> = observer(props => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  const stripe = useStripe();

  useEffect(() => {
    if (!stripe || !props.store.clientSecret || !props.store.stripeCustomerDefaultPaymentMethodInfos?.id) {
      // Wait for Stripe elements to be initialized
      return;
    }

    (async function chargeCard() {
      let payload;
      try {
        props.store.setLoading(true);
        props.store.setError(undefined);

        payload = await stripe.confirmCardPayment(props.store.clientSecret, {
          payment_method: props.store.stripeCustomerDefaultPaymentMethodInfos.id,
          receipt_email: userStore.currentUser?.email,
        });

        if (payload.error) {
          props.store.setError(payload.error.message);
        } else {
          props.store.setError(undefined);
          props.store.setSucceeded(true);
          router.query.successful = '1'
          router.push(router);
        }
      }
      catch (err) {
        props.store.setError(t('payment.error_payment', { code: err }));
      }
      finally {
        props.store.setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.store.clientSecret, stripe])

  return (
    <form className={styles.formContainer} >
      <OwardLoader loading={props.store.loading} />

      <div className={styles.blockContainer}>
        {
          !props.store.error ?
            <React.Fragment>
              <p className={styles.title}>{t('payment.payment_method')}</p>
              <p className={styles.secured}>{t('payment.secured')}
                <a className={`icon ${styles.icon}`} href='https://stripe.com/' target='_blank' rel='noreferrer'>
                  <i className={'fab fa-stripe'}></i>
                </a>
              </p>
            </React.Fragment>
            :
            <div className={styles.errorContainer}>
              <p className={styles.error}>{props.store.error}</p>
              <OwardButton
                onClick={() => {
                  props.store.setError(undefined);
                  props.store.setUseNewCard(true);
                }}
                name={t('payment.use_another_card')}
              />
            </div>
        }
      </div>
    </form>
  )
});

export const StripeAddCard = observer(props => {
  const { locale } = useTranslate();
  let stripePromise: Stripe;

  const getStripe = async () => {
    if (!stripePromise) {
      stripePromise = await loadStripe(
        process.env.STRIPE_PUBLISHABLE_KEY,
        { locale: locale as StripeElementLocale }
      );
    }
    return stripePromise;
  }

  return (
    <Elements stripe={getStripe()}>
      <StripeAddCardForm />
    </Elements>
  )

});

export const StripeAddCardForm = observer(props => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  const stripe = useStripe();
  const elements = useElements();

  const [clientSecret, setClientSecret] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [makeDefault, setMakeDefault] = useState(true);

  useEffect(() => {
    (async function getClientSecret() {
      try {
        setLoading(true);
        setError(undefined);
        const clientSecret = (await SubscriptionController.getStripeSetupIntentSecret()).text;
        setClientSecret(clientSecret);
      }
      catch (err) {
        setError(t('payment.error_payment', { code: err }));
      }
      finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty || event.error);
    setError(event.error ? event.error.message : undefined);
  };

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    try {
      setLoading(true);
      setError(undefined);
      let payload = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        }
      });

      if (makeDefault && payload?.setupIntent?.payment_method) {
        await send(SubscriptionController.setStripePaymentMethodAsDefault(payload.setupIntent?.payment_method as string));
      }

      if (payload.error) {
        setError(payload.error.message);
      } else {
        setError(undefined);
        // Refresh page to reload payment method list
        await router.replace(router.asPath);
        modalStore.unpopAll();
        modalStore.unpopModal(router);
        toast.dark(<ToastSucess msg={
          `${t('modal.add_bank_card.success')}${makeDefault ? t('modal.add_bank_card.success_make_default') : ''}`
        } />);
      }
    }
    catch (err) {
      setError(t('payment.error_payment', { code: err }));
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <form className={styles.formContainer} onSubmit={handleSubmit}>
      <OwardLoader loading={loading} />
      <div className={styles.blockContainer}>
        <div className={styles.cardElementContainer}>
          <CardElement
            className={styles.cardElement}
            onChange={handleChange}
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  fontSize: '16px',
                  color: styles.greyDarker,
                  '::placeholder': {
                    color: styles.placeholderColor,
                  },
                  fontWeight: styles.regular,
                },
                invalid: {
                  color: styles.red,
                },
              },
            }}
          />
        </div>
        <div className={styles.makeDefaultContainer}>
          <OwardSwitch
            label={t('modal.add_bank_card.make_default')}
            active={makeDefault}
            blue
            onChange={() => setMakeDefault(!makeDefault)}
          />
        </div>
      </div>
      <div className={styles.footerContainer}>
        <div className={styles.buttonContainer}>
          <OwardButton
            submit
            name={t('modal.add_bank_card.button')}
            deactivated={!stripe || !elements || disabled || loading || !clientSecret}
            outlined={!stripe || !elements || disabled || loading || !clientSecret}
          />
        </div>
        {
          error &&
          <div className={styles.errorContainer}>
            <p className={styles.error}>{error}</p>
          </div>
        }
      </div>
    </form>
  )
});

const stripeCustomerFromSchema = (t: any) => {
  return (
    Yup.object().shape({
      name: Yup.string().required(t('forms.required')),
      streetName: Yup.string().required(t('forms.required')),
      city: Yup.string().required(t('forms.required')),
      postalCode: Yup.string().required(t('forms.required')),
      country: Yup.object().required(t('forms.required')),
      phone: Yup.string().matches(PHONE_VALIDATION_REGEX, t('forms.phone_number.invalid'))
    }))
};
interface StripeCustomerFormProps {
  formik: any;
  loading: boolean;
  error: string;
}

const StripeCustomerForm: React.FC<StripeCustomerFormProps> = (props) => {
  const { t, locale } = useTranslate();
  return (
    <form className={styles.formContainer} onSubmit={props.formik.handleSubmit}>
      <OwardLoader loading={props.loading} />
      <div className={styles.blockContainer}>
        <p className={styles.title}>{t('payment.billing.title')}</p>
        <OwardFormInput
          id='name'
          placeholder={t('payment.billing.name')}
          formik={props.formik}
          noBottomPadding
        />
        <OwardFormInput
          id='streetName'
          placeholder={t('payment.billing.street_name')}
          formik={props.formik}
          noBottomPadding
        />
        <div className={styles.cityAndCodeContainer}>
          <div className={styles.cityContainer}>
            <OwardFormInput
              id='city'
              placeholder={t('payment.billing.city')}
              formik={props.formik}
              noBottomPadding
            />
          </div>
          <div className={styles.codeContainer}>
            <OwardFormInput
              id='postalCode'
              placeholder={t('payment.billing.postal_code')}
              formik={props.formik}
              noBottomPadding
            />
          </div>
        </div>
        <OwardFormSelectLoad
          id='country'
          placeholder={t('location.country')}
          fetchFunction={LocationController.findCountries()}
          entitiesToOptions={entities => countryToCodeOptions(entities, locale)}
          isNotClearable
          formik={props.formik}
        />
        <OwardFormInput
          id='phone'
          placeholder={t('payment.billing.phone')}
          formik={props.formik}
          noBottomPadding
        />
        <div className={styles.footerContainer}>
          {
            props.error &&
            <div className={styles.errorContainer}>
              <p className={styles.error}>{props.error}</p>
            </div>
          }
          <div className={styles.buttonContainer}>
            <OwardButton
              name={t('global.validate')}
              submit
              fullWidth
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export const StripeCreateCustomerForm: React.FC<PaymentPageComponentProps> = observer(props => {
  const { t, locale } = useTranslate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const formik = useFormik({
    initialValues: {
      name: '',
      streetName: '',
      city: '',
      postalCode: '',
      country: undefined,
      phone: ''
    },
    validationSchema: stripeCustomerFromSchema(t),
    onSubmit: async values => {
      try {
        setLoading(true);
        setError('');
        let customerId: string = (await SubscriptionController.createStripeCustomer({
          name: values.name,
          streetName: values.streetName,
          city: values.city,
          postalCode: values.postalCode,
          countryCode: values.country.code,
          phone: values.phone,
          preferredLocales: [locale, defaultLocale]
        })).text;
        runInAction(() => {
          props.store.stripeCustomerId = customerId;
        })
      }
      catch (err) {
        setError(t('global.error_with_code', { code: err }));
      }
      finally {
        setLoading(false);
      }
    }
  });

  return (
    <StripeCustomerForm formik={formik} loading={loading} error={error} />
  );
});

export const StripeModifyCustomerDetailsForm: React.FC<StripeCustomerDetails> = observer(props => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const formik = useFormik({
    initialValues: {
      name: props.name,
      streetName: props.streetName,
      city: props.city,
      postalCode: props.postalCode,
      country: {
        name: props.countryCode, // Should go fetch the country name based on country code...
        code: props.countryCode,
      },
      phone: props.phone
    },
    validationSchema: stripeCustomerFromSchema(t),
    onSubmit: async values => {
      try {
        setLoading(true);
        setError('');
        await SubscriptionController.saveStripeCustomerDetails({
          name: values.name,
          streetName: values.streetName,
          city: values.city,
          postalCode: values.postalCode,
          countryCode: values.country.code,
          phone: values.phone,
          preferredLocales: [locale, defaultLocale]
        });
        // Refresh page to reload billing informations
        await router.replace(router.asPath);
        toast.dark(<ToastSucess msg={
          `${t('account.user.billing_informations.success')}`
        } />);
      }
      catch (err) {
        setError(t('global.error_with_code', { code: err }));
      }
      finally {
        setLoading(false);
      }
    }
  });

  return (
    <StripeCustomerForm formik={formik} loading={loading} error={error} />
  );
});

export const PaymentRecap: React.FC<PaymentPageComponentProps> = observer(props => {
  const { t, locale } = useTranslate();
  const { profiles } = useProfilesForUser(locale);

  return (
    <div className={styles.recapContainer}>
      <p className={styles.title}>{t('payment.recap.title')}</p>
      <p>
        <span className={styles.book}>{t('payment.recap.profile')}</span>
        {profiles?.find(profile => profile.id === props.store.profileId)?.name ?? t('global.loading')}
      </p>
      {
        props.store.stripePrice.premiumLevel &&
        <p>
          <span className={styles.book}>{t('payment.recap.type')}</span>
          {t(`premium_level.${props.store.stripePrice.premiumLevel}`)}
        </p>
      }
      <p>
        <span className={styles.book}>{t('payment.recap.amount')}</span>
        {`
          ${priceToString(props.store.stripePrice.amount / 100, locale)}
          ${t(`subscriptions.cards.per_${props.store.stripePrice.recurringInterval}`)}
        `}
      </p>
    </div>
  )
});

interface PaymentSuccededProps extends PaymentPageComponentProps {
  sayWelcome?: boolean;
}

export const PaymentSucceded: React.FC<PaymentSuccededProps> = observer(props => {
  const { t } = useTranslate();
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  useEffect(() => {
    if (
      process.env.GOOGLE_TAG_MANAGER_TRACKING_ID &&
      process.env.GOOGLE_TAG_MANAGER_CONVERSION_ID &&
      userStore.gdprPrefs?.analysis
    ) {
      // <!-- Event snippet for Website traffic conversion page -->
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `gtag('event', 'conversion', {'send_to': '${process.env.GOOGLE_TAG_MANAGER_TRACKING_ID}/${process.env.GOOGLE_TAG_MANAGER_CONVERSION_ID}'});`;
      document.head.appendChild(script);
    }
  }, [userStore.gdprPrefs?.analysis]);

  return (
    <div className={styles.successMainContainer}>
      {
        <React.Fragment>
          <p className={styles.title}>{t(`payment.success.title${props.sayWelcome ? '_welcome' : ''}`)}</p>
          <p className={styles.body}>{t(`payment.success.body${props.sayWelcome ? '_welcome' : ''}`)}</p>
          <Link href='/account/user/invoices' passHref>
            <p className={styles.link}>{t('payment.success.go_to_invoices')}</p>
          </Link>
        </React.Fragment>
      }
      <Link href='/account/profiles' passHref>
        <OwardLinkButton
          name={t('payment.success.button')}
        />
      </Link>
      <p className={styles.ps}>
        {t('payment.success.ps')}
        <a href={URL_LINKEDIN} target='_blank' rel='noreferrer' className={styles.link}>Linkedin</a>{', '}
        <a href={URL_FACEBOOK} target='_blank' rel='noreferrer' className={styles.link}>Facebook</a>{', '}
        <a href={URL_INSTAGRAM} target='_blank' rel='noreferrer' className={styles.link}>Instagram</a>
      </p>
    </div>
  );
});
