import React from 'react';
import { useTranslate } from '@hooks/.';

export const WipPage = () => {

  const { t } = useTranslate();

  return (
    <React.Fragment>
      <section className={`hero is-fullheight-with-navbar is-unselectable`}>
        <div className='hero-body'>
          <div className='container is-fluid has-text-centered'>
            <p className={'has-text-black is-size-4 has-text-weight-light'}>
              {t('wip.page')}
            </p>
            <p>
              <span className='icon is-large'>
                <i className={'fas fa-2x fa-paint-roller'}></i>
              </span>
            </p>
          </div>
        </div >
      </section>
    </React.Fragment >
  );
}

export default WipPage;
