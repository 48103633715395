import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import styles from './EditArtwork.module.scss';
import classNames from 'classnames';
import { useTranslate } from '@hooks/use-translate';
import { OwardLoader, OwardLoadFileButton, ArtworkSize, EditArtworkStore } from '@components/.';
import { StoresBindings } from '@container/.';
import { useInjection } from '@hooks/.';
import { PopupStore } from '@stores/.';
import { Crop } from 'react-image-crop';
import ReactCrop from 'react-image-crop';
import { action } from 'mobx';

interface EditArtworkCoverProps {
  size: ArtworkSize;
  store: EditArtworkStore;
}

export const EditArtworkCover: React.FC<EditArtworkCoverProps> = props => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);

  const [upImg, setUpImg] = useState(undefined);
  const [img, setImg] = useState(undefined);
  const [imgLoading, setImgLoading] = useState(false);
  const [crop, setCrop] = useState<Crop>(undefined);
  const [completedCrop, setCompletedCrop] = useState<Crop>(undefined);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImgLoading(true);
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const setPreviewCoverFromCrop = useCallback((crop: Crop, image: any) => {
    if (!crop || !image) {
      return;
    }

    const canvas = document.createElement('canvas');

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Update preview only if there's an actual crop (i.e. width > 0)
    if (canvas.width > 0) {
      setPreviewCover(props.size, props.store, canvas.toDataURL('image/jpeg'));
    }
  }, [props.size, props.store]);

  const onLoad = useCallback((img) => {
    const ratioFromSize = () => {
      switch (props.size) {
        case ArtworkSize.LARGE:
          return 1.33;
        case ArtworkSize.MEDIUM_LARGE:
          return 1 / 1.33;
        case ArtworkSize.MEDIUM_SMALL:
          return 1 / 2;
        case ArtworkSize.SMALL:
          return 1 / 2.66;
        default:
          return 1;
      }
    }

    setImg(img);
    setImgLoading(false);
    const aspect = 1 / ratioFromSize();
    const ratio = ratioFromSize();;

    const width = (img.width > (img.height / ratio) ? (img.height / ratio) : img.width);
    const height = (img.width > (img.height / ratio) ? img.height : (img.width * ratio));

    const y = (img.height - height) / 2;
    const x = (img.width - width) / 2;

    const initialCrop: Crop = {
      unit: 'px',
      width,
      height,
      x,
      y,
      aspect,
    }
    setCrop(initialCrop);
    setPreviewCoverFromCrop(initialCrop, img);
    return false; // Return false if you set crop state in here.
  }, [setPreviewCoverFromCrop, props.size]);

  useEffect(() => {
    setPreviewCoverFromCrop(completedCrop, img);
  }, [completedCrop, img, setPreviewCoverFromCrop]);

  return (
    <div className={styles.uploadCoverContainer}>
      <OwardLoader loading={imgLoading} />
      <div className={styles.uploadCoverHeaderContainer}>
        <div className={styles.nameContainer}>
          <p>{t('edit_artwork.cover_' + props.size + '.number')}</p>
          <p className={styles.ratio}>{t('edit_artwork.cover_' + props.size + '.ratio')}</p>
        </div>
        <div className={styles.buttonContainer}>
          <OwardLoadFileButton
            id={'artwork-cover-' + props.size}
            stringButton={t('edit_artwork.cover_download')}
            onClick={onSelectFile}
            onlyImages
          />
        </div>
      </div>
      {
        upImg &&
        <div className={styles.uploadCoverPreviewContainerContainer}>
          {
            upImg &&
            <span
              className={classNames('icon', styles.uploadCoverDelete)}
              onClick={() => {
                setPreviewCover(props.size, props.store, undefined);
                setUpImg(undefined);
              }}
            >
              <i className='fas fa-times'></i>
            </span>
          }
          <div className={styles.uploadCoverPreviewContainer}>
            <ReactCrop
              src={upImg}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
            />
          </div>
        </div>
      }
    </div>
  )
}

const setPreviewCover = action((size: ArtworkSize, store: EditArtworkStore, preview: any): void => {
  switch (size) {
    case ArtworkSize.LARGE:
      store.coverPreviewL = preview;
      break;
    case ArtworkSize.MEDIUM_LARGE:
      store.coverPreviewML = preview;
      break;
    case ArtworkSize.MEDIUM_SMALL:
      store.coverPreviewMS = preview;
      break;
    case ArtworkSize.SMALL:
      store.coverPreviewS = preview;
      break;
    default:
      return;
  }
});
