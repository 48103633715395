import React from 'react';
import { Artwork, FilmSelection } from '@oward/openapi';
import { LayoutPageEditProfile } from '@components/.';
import { useLocalObservable, observer } from 'mobx-react-lite';
import { useTranslate } from '@hooks/.';
import { EditArtworkPreview } from '@components/EditArtwork/EditArtworkPreview';
import { EditArtwork } from '@components/EditArtwork/EditArtwork';
import { EditProfileStore } from './EditProfilePage';

export interface EditArtworkStore {
  artwork: Artwork,
  coverPreviewL?: any;
  coverPreviewML?: any;
  coverPreviewMS?: any;
  coverPreviewS?: any;
  selectionsChanged: boolean;
  loading: string,
  setLoading: (txt: string) => void,
  error: string,
  setError: (txt: string) => void,
}

interface AccountArtworkProps {
  artwork: Artwork;
  editProfileStore: EditProfileStore;
}

export const AccountArtworkPage: React.FC<AccountArtworkProps> = observer((props: AccountArtworkProps) => {
  const { t, locale } = useTranslate();

  const store: EditArtworkStore = useLocalObservable(() => ({
    artwork: props.artwork,
    coverPreviewL: undefined,
    coverPreviewML: undefined,
    coverPreviewMS: undefined,
    coverPreviewS: undefined,
    selectionsChanged: false,
    loading: undefined,
    setLoading: (loadingText) => { store.loading = loadingText },
    error: undefined,
    setError: (errorText) => { store.loading = errorText },
  }));

  return (
    <LayoutPageEditProfile
      leftComponent={<EditArtwork store={store} editProfileStore={props.editProfileStore} />}
      rightComponent={<EditArtworkPreview store={store} />}
      loading={store.loading}
      error={store.error}
    />
  )
})

