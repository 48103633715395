/**
 * @description
 * Used by Next and the locale middleware.
 */

const cookieName = 'I18N';
const defaultLocale = 'en';

const locales = [
  'fr',
  'en'
];

const languageNames = {
  en: 'English',
  fr: 'français',
};

module.exports = {
  cookieName,
  defaultLocale,
  locales,
  languageNames
}
