import React, { useContext } from 'react';
import { useRouter } from 'next/router'
import ms from 'ms';

import { I18nContext } from './I18nProvider';
import { cookieName } from '../../locales';
import { useInjection } from '@hooks/.';
import { UserStore } from '@stores/.';
import { StoresBindings } from '@container/.';

interface SwitchLanguageProps {
  locale: string;
  className?: string;
}

export const SwitchLanguage: React.FC<SwitchLanguageProps> = props => {
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const locale = useContext(I18nContext).locale();

  if (props.locale === locale) {
    return (
      <React.Fragment>
        <a href={"/"} className={props.className}>{props.locale} </a>
      </React.Fragment>
    );
  }

  const handleClick = async () => {
    if (userStore.gdprPrefs?.functional === true) {
      var expires = new Date(Date.now() + ms('1 year')).toUTCString();
      document.cookie = `${cookieName}=${props.locale};expires=${expires};path=/`;
    }
  }


  return (
    <React.Fragment>
      <a
        href={router.asPath.replace(locale, props.locale)}
        onClick={handleClick}
        className={props.className}>
        {props.locale}
      </a>
    </React.Fragment>
  );
}
