import classNames from "classnames";
import styles from './Section.module.scss';

interface SectionProps {
  title: string;
  subtitle: string;
  childrenFullWidth?: boolean;
  children?: React.ReactNode;
}

export const Section: React.FC<SectionProps> = props => {
  return (
    <div className={props.childrenFullWidth ? '' : 'columns'}>
      <div className={classNames(props.childrenFullWidth ? '' : 'column is-4', styles.header)}>
        <h4 className="title is-4">{props.title}</h4>
        <h6 className={classNames('subtitle is-6', styles.subtitle)}>{props.subtitle}</h6>
      </div>
      {
        props.childrenFullWidth &&
        <div style={{ height: '1rem' }} />
      }
      <div className={props.childrenFullWidth ? '' : 'column is-8'}>
        {props.children}
      </div>
    </div >
  );
}
