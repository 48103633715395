import React, { useState } from 'react';
import styles from './ResetPasswordPage.module.scss';
import { useRouter } from 'next/router';
import { useInjection, useTranslate } from '@hooks/.';
import { LayoutPageCentered, OwardButton, OwardLinkButton, OwardFormInput, OwardLoader, PopUp, Link } from '@components/.';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { UserController, withBearer } from '@oward/openapi';
import { PopupStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { ERR_MSG_UNAUTHORIZED } from '@utils/.';

interface ResetPasswordPage {
  token: string;
  unauthorized?: boolean;
}

const schema = (t: any) => {
  return (
    Yup.object().shape({
      password: Yup
        .string()
        .required(t('forms.password.required'))
        .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{7,50}\S$/, t('forms.password.requirements')),
      confirmPassword: Yup
        .string()
        .test(
          'password-match',
          t('forms.password.no_match'),
          function (value) {
            return this.parent.password === value
          }
        ),
    }))
};

export const ResetPasswordPage: React.FC<ResetPasswordPage> = props => {
  const router = useRouter();
  const { t, locale } = useTranslate();
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);
  const [unauthorized, setUnauthorized] = useState<boolean>(props.unauthorized);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      try {
        setLoading(true);
        setUnauthorized(false);
        await withBearer(props.token)(UserController.resetPassword({ password: values.password }));
        popupStore.openInformationPopUp({
          msg: t('login.reset_password.done_msg'),
          callback: () => { router.push('/[lang]/login', `/${locale}/login`) }
        });
        setError(false);
      } catch (err) {
        if (err instanceof Error && err.message === ERR_MSG_UNAUTHORIZED) {
          setUnauthorized(true);
        }
        else {
          setError(t('global.error_retry'));
        }
      }
      finally {
        setLoading(false);
      }
    }
  });

  return (
    unauthorized ?
      <LayoutPageCentered title={t('login.verify_email.unauthorized_title')} isVerticallyCentered>
        <p style={{ textAlign: 'center', whiteSpace: 'pre-wrap', paddingBottom: '1.5rem' }}>
          {t('login.reset_password.unauthorized_description')}
        </p>
        <Link href='/login?reset_password=1'>
          <OwardLinkButton
            name={t('login.reset_password.title')}
            fullWidth
          />
        </Link>
      </LayoutPageCentered>
      :
      <React.Fragment>
        <PopUp />
        <LayoutPageCentered title={t('login.reset_password.title')} isVerticallyCentered>
          <form className={classNames(styles.formContainer)} onSubmit={formik.handleSubmit} >
            <OwardLoader loading={loading} />
            <OwardFormInput
              id='password'
              type='password'
              label={t('forms.password.label_new')}
              description={t('forms.password.requirements')}
              icon='fa fa-lock'
              placeholder='********'
              formik={formik}
            />
            <OwardFormInput
              id='confirmPassword'
              type='password'
              label={t('forms.password.label_confirm')}
              icon='fa fa-lock'
              placeholder='********'
              formik={formik}
            />
            {
              error &&
              <p className={styles.error}>
                {error}
              </p>
            }
            <OwardButton
              name={t('login.reset_password.change_password')}
              submit
              fullWidth
            />
          </form >
        </LayoutPageCentered>
      </React.Fragment>
  );
}
