import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import {
  FestivalController, FestivalAward, State,
} from '@oward/openapi';
import { Divider, Section, SubmitOrCancel, NavigationContext } from '@components/admin';
import { EditProps } from '../types';
import {
  Heading,
  OwardFormInput,
  OwardFormSelect,
  OwardFormSelectLoad,
  OwardLoader,
} from '@components/Core';
import { useTranslate } from '@hooks/.';
import * as Yup from 'yup';
import { flattenFromGrouped, groupByLocale } from '../utils';
import { locales } from '@locales/.';
import { stateToEmoji } from '@oward/common-utils';

const schema = (t: any) => {
  return (
    Yup.object().shape({
      name: Yup.string().required(),
      category: Yup.object().required(),
    }))
};

export const EditAward = observer((props: EditProps<FestivalAward>) => {
  const { t, locale } = useTranslate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigation = useContext(NavigationContext);

  const formik = useFormik({
    initialValues: {
      name: props.item.name,
      translations: groupByLocale(props.item.translations),
      category: props.item.category,
      festival: props.item.festival,
      state: props.item.state,
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      props.item.name = values.name;
      props.item.translations = flattenFromGrouped(values.translations);
      props.item.category = values.category;
      props.item.state = values.state;
      try {
        setLoading(true);
        await FestivalController.saveOneAward(props.item);
        if (props.notify) { props.notify(props.item) };
        setError(false);
        navigation.unpop();
      } catch (err) {
        setError(true);
      }
      finally {
        setLoading(false);
      }
    }
  });

  return (
    <React.Fragment>
      <Section
        title={`Edit '${formik.initialValues.name}' award`}
        subtitle={`Change the award properties`}
        childrenFullWidth
      >
        <form className='box' style={{ position: 'relative' }} onSubmit={formik.handleSubmit} >
          <OwardLoader loading={loading} />
          <Heading>GENERAL</Heading>
          <OwardFormInput
            id='name'
            type='name'
            label='Name *'
            icon='fas fa-signature'
            formik={formik}
          />
          {
            locales.map(locale =>
              <React.Fragment key={locale}>
                <OwardFormInput
                  id={`translations.${locale}.name`}
                  label={`Name ${locale}`}
                  icon='fas fa-signature'
                  value={formik.values.translations[locale]?.name}
                  error={formik.errors.translations && formik.errors.translations[locale]?.name}
                  formik={formik}
                />
              </React.Fragment>
            )
          }
          <OwardFormSelectLoad
            id='category'
            label='Category *'
            fetchFunction={FestivalController.findAwardCategoriesAdmin()}
            formik={formik}
          />
          <OwardFormSelect
            id='state'
            label='State *'
            options={Object.values(State).map((state) => {
              return ({ value: state, label: state.concat(' ', stateToEmoji(state)) });
            })}
            formik={formik}
          />
          <Divider />
          <SubmitOrCancel error={error} noCancel={props.noCancel} />
        </form >
        <div style={{ height: '200px' }} />
      </Section >
    </React.Fragment>
  )
});
