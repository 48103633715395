import { useEffect } from 'react';
import { useRouter } from 'next/router';
import styles from './Register.module.scss';
import { useInjection, useTranslate } from '@hooks/.';
import { PopupStore, UserStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { send, UserController } from '@oward/openapi';
import { LayoutPageCentered } from '@components/.';
import { goToHomePageAndReload } from '@utils/utils';

export const WaitEmailVerificationPage = () => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  useEffect(() => {
    let currentUserInterval = setInterval(async () => {
      try {
        await send(UserController.currentUser());
        userStore.setAutologinTimer(() => {
          popupStore.openInformationPopUp({
            msg: t('global.force_logout'),
            callback: () => { goToHomePageAndReload() }
          });
        });
        router.replace('/[lang]', `/${locale}`);
      }
      catch {
        // It will fail until the user click on the verification mail
      }

    }, 3000);

    return () => {
      clearInterval(currentUserInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutPageCentered title={t('login.wait_email_verification.title')} isVerticallyCentered>
      <p className={styles.pageDescription}>
        {t('login.wait_email_verification.description')}
      </p>
    </LayoutPageCentered>
  )
}
