import React, { useContext } from 'react'
import { Field, Control, OwardButton } from '@components/Core';

import { NavigationContext } from '@components/admin';
import { useTranslate } from '@hooks/.';

interface SubmitOrCancelProps {
  error?: boolean;
  errorMsg?: string;
  noCancel?: boolean;
}

export const SubmitOrCancel = (props: SubmitOrCancelProps) => {
  const { t } = useTranslate();
  const navigation = useContext(NavigationContext);

  return (
    <React.Fragment>
      {
        props.error &&
        <p className='has-text-danger'>
          {t('global.error')}
        </p>

      }
      {
        props.errorMsg &&
        <p className='has-text-danger'>
          {props.errorMsg}
        </p>

      }
      <Field kind='group' align='right'>
        <Control>
          <OwardButton
            name={t('global.validate')}
            submit
          />
        </Control>
        {
          !props.noCancel &&
          <Control>
            <OwardButton
              name={t('global.cancel')}
              outlined
              onClick={() => navigation.unpop()}
            />
          </Control>
        }
      </Field>
    </React.Fragment>
  )
}
