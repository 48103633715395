import React, { useEffect, useState } from 'react';
import styles from './OnFire.module.scss';
import { useInjection, useOnFireProfiles, useTranslate } from '@hooks/.';
import {
  Link, OwardSelect, ButtonAspect, CardButtonContact, CardButtonOnFire,
  CardButtonShare, CardButtonShortlist, ToastPortfolioNotAccessible
} from '@components/.';
import { OnFireController, Profile, send, User } from '@oward/openapi';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { hasPortfolioPublic, isProfileExposed, remSizeToPixelNumber, userToStrNotFullMail } from '@utils/utils';
import { findTranslationOrDefault } from '@oward/common-utils';
import { OwardButton, OwardLoader, SelectOption, ToastError } from '@components/Core';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { ModalKey, ModalStore } from '@stores/modal-store';
import { StoresBindings } from '@container/keys';
import moment from 'moment';
import { toast } from 'react-toastify';
import { action, runInAction } from 'mobx';
import classNames from 'classnames';

export interface OnFireStore {
  userProfiles: Profile[],
  userProfilesOptions: SelectOption[],
  userProfilesLoading: boolean,
  selectedProfileId: number,
  selectedProfile: () => Profile | undefined,
  onFiringUsers: User[],
  onFiringUsersLoading: boolean,
}

interface OnFireProfilesSelectionProps {
  store: OnFireStore;
}

export const OnFireProfilesSelection: React.FC<OnFireProfilesSelectionProps> = observer(props => {
  const { locale, t } = useTranslate();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const router = useRouter();
  const { onFireProfiles } = useOnFireProfiles();

  const NoProfile = () => {
    return <div className={styles.noProfileContainer}>
      <p>{t('on_fire.gallery.no_profile')}</p>
      <div className={styles.buttonContainer}>
        <OwardButton
          name={t('navbar.create_profile')}
          bigPadding
          onClick={() => modalStore.openModalNewStack(router, ModalKey.NEW_PROFILE, 1)}
        />
      </div>
    </div>;
  }

  useEffect(() => {
    (async function getOnFiringUsers() {
      if (props.store.selectedProfileId) {
        try {
          runInAction(() => {
            props.store.onFiringUsersLoading = true;
          });
          let users = await send(OnFireController.getOnFiringUsers(props.store.selectedProfileId));
          runInAction(() => {
            props.store.onFiringUsers = users;
          });
        }
        catch (err) {
          toast.dark(<ToastError msg={t('global.error_with_code', { code: err })} />);
        }
        finally {
          runInAction(() => {
            props.store.onFiringUsersLoading = false;
          });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.store.selectedProfileId, onFireProfiles]);

  const renderSpecificPage = () => {
    if (!props.store.userProfiles?.length) {
      return <NoProfile />;
    }

    switch (props.store.userProfiles?.length) {
      case 0:
        return <NoProfile />;
      case 1:
        return;
      default:
        return <OnFireProfilesDropdown
          store={props.store}
        />;
    }
  }

  return (
    <div className={styles.profileSelection}>
      {
        props.store.userProfilesLoading ?
          <OwardLoader
            loading={props.store.userProfilesLoading}
            message={t('on_fire.gallery.loading_profiles')}
            positionStatic
          />
          :
          <React.Fragment>
            {renderSpecificPage()}
          </React.Fragment>
      }
    </div>
  );
});

interface OnFireProfilesDropdownProps {
  store: OnFireStore;
}

export const OnFireProfilesDropdown: React.FC<OnFireProfilesDropdownProps> = observer(props => {
  const { locale, t } = useTranslate();

  return (
    <OwardSelect
      label={t('on_fire.gallery.choose_profile_label')}
      options={props.store.userProfilesOptions}
      onChange={action((id) => { props.store.selectedProfileId = id })}
      value={props.store.selectedProfileId}
    />
  );
});

interface OnFireProfileLineProps {
  profile: Profile;
}

export const OnFireProfileLine: React.FC<OnFireProfileLineProps> = props => {
  const { locale, t } = useTranslate();
  const router = useRouter();

  return (
    <div className={`${styles.profileContainer}`}>
      <div
        className={classNames(
          styles.profileLinkContainer,
          !hasPortfolioPublic(props.profile) && styles.deactivated,
          'has-tooltip-arrow')
        }
        data-tooltip={hasPortfolioPublic(props.profile) ? t('card.see_profile') : undefined}
        onClick={() => {
          if (hasPortfolioPublic(props.profile)) {
            router.push(
              `/[lang]/p/[profilePath]`,
              `/${locale}/p/${props.profile?.path}`
            );
          }
          else {
            toast.dark(<ToastPortfolioNotAccessible profileId={props.profile.id} profileName={props.profile.name} />);
          }
        }
        }
      >
        <div className={`column ${styles.profileLeftContainer}`}>
          {
            props.profile.avatarUrl &&
            <div className={styles.avatarContainer}>
              <LazyLoadImage
                src={props.profile.avatarUrl}
                width={remSizeToPixelNumber(styles.avatarSize)}
                height={remSizeToPixelNumber(styles.avatarSize)}
                className={classNames(
                  styles.avatar,
                  hasPortfolioPublic(props.profile) && styles.goldenBorder
                )}
                alt={t('alt.avatar_no_city', {
                  name: props.profile.name,
                  job: findTranslationOrDefault(props.profile?.job, locale)
                })}
                effect='blur'
              />
            </div>
          }
          <div className={styles.nameJobContainer}>
            <h2 className={styles.name}>
              {props.profile.name}
            </h2>
            <p className={styles.job}>
              {findTranslationOrDefault(props.profile.job, locale)}
            </p>
          </div>
        </div>
        <div className={`column ${styles.profileCenterContainer}`}>
          <p>
            {props.profile.bios[0].short}
          </p>
        </div>
      </div>
      <div className={`column ${styles.profileRightContainer}`}>
        <div className={styles.buttonsContainer}>
          <CardButtonContact
            profileId={props.profile.id}
            profileName={props.profile.name}
            buttonAspect={ButtonAspect.ON_FIRE}
          />
          <CardButtonOnFire
            profileId={props.profile.id}
            profileName={props.profile.name}
          />
          <CardButtonShare
            profileId={props.profile.id}
            profileJob={findTranslationOrDefault(props.profile.job, locale)}
            profilePath={props.profile.path}
            profileName={props.profile.name}
            buttonAspect={ButtonAspect.ON_FIRE}
            canShare={hasPortfolioPublic(props.profile)}
          />
          <CardButtonShortlist
            profileId={props.profile.id}
            profileName={props.profile.name}
            profileJob={findTranslationOrDefault(props.profile.job, locale)}
          />
        </div>
      </div>
    </div>
  )
}

interface OnFireUserLineProps {
  user: User;
  store: OnFireStore;
}

export const OnFireUserLine: React.FC<OnFireUserLineProps> = props => {
  const { locale, t } = useTranslate();

  const [exposedProfiles, setExposedProfile] = useState<Profile[]>([]);

  useEffect(() => {
    setExposedProfile(props.user.profiles?.filter(profile => isProfileExposed(profile)));
  }, [props.user.profiles]);

  const getOnFireDate = () => {
    // when getting OnFire from the DB, we're getting only the one for the specified profileId
    return props.user.onFires[0]?.creationDate;
  }

  return (
    <React.Fragment>
      <hr className={styles.hr} />
      <div className={styles.userContainer}>
        <p className={styles.userNameAndDate}>
          <span className={styles.userName}>{userToStrNotFullMail(props.user)}</span>
          <br />
          {moment(getOnFireDate()).format('DD MMM yyyy')}
        </p>
        <div className={styles.profilesContainer}>
          {
            exposedProfiles?.length > 0 ?
              exposedProfiles?.map((profile, i) => <OnFireProfileLine profile={profile} key={i} />)
              :
              <p className={styles.noProfile}>{t('on_fire.gallery.user_no_profile')}</p>
          }
        </div>
      </div>
    </React.Fragment>
  );
}

interface OnFireSubtitleProps {
  subtitle: string;
}

export const OnFireSubtitle: React.FC<OnFireSubtitleProps> = props => {
  const { locale, t } = useTranslate();

  return (
    <div className={styles.subtitleContainer}>
      <p className={styles.subtitle}>{props.subtitle}</p>
    </div>
  );
}
