import React, { useState, useEffect, } from 'react';
import { send, ProfileSubscription, SubscriptionController } from '@oward/openapi';
import { PaginatedList, PaginatedListFilterType, PaginatedListKey, Section, } from '@components/admin';
import { priceToString } from '@oward/common-utils';

export const SubscriptionList: React.FC = () => {
  const [items, setItems] = useState([] as ProfileSubscription[]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadItems = async () => {
    try {
      setLoading(true);
      setError(false);
      let subscriptions: ProfileSubscription[] = await send(SubscriptionController.findProfileSubscriptions());
      setItems(subscriptions);
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const keys: PaginatedListKey<ProfileSubscription>[] = [
    {
      label: 'Id',
      value: item => item.id
    },
    {
      label: 'Status',
      value: item => item.status
    },
    {
      label: 'User',
      value: item => `${item.profile?.user?.email} (${item.profile?.user?.id})`
    },
    {
      label: 'Profile',
      value: item => `${item.profile?.name} (${item.profile?.id})`
    },
    {
      label: 'Recurring Interval',
      value: item => item.stripeInfos?.recurringInterval
    },
    {
      label: 'Amount',
      value: item => priceToString(item.stripeInfos?.amount / 100, 'fr'),
      sortValue: item => item.stripeInfos?.amount
    },
  ];

  const filterItemStrings = (item: ProfileSubscription) => [
    item.id.toString(),
  ]

  const filterComponents = [
    {
      name: 'Status',
      type: PaginatedListFilterType.DROPDOWN,
      options: [...new Set(items.map(item => item.status))].map(status => {
        return { value: status, label: status }
      }),
      filterFunction: (item: ProfileSubscription, value: string) => item.status === value
    },
    {
      name: 'Reccuring Interval',
      type: PaginatedListFilterType.DROPDOWN,
      options: [...new Set(items.map(item => item.stripeInfos?.recurringInterval))].map(recurringInterval => {
        return { value: recurringInterval, label: recurringInterval }
      }),
      filterFunction: (item: ProfileSubscription, value: string) => item.stripeInfos?.recurringInterval === value
    },
    {
      name: 'User',
      type: PaginatedListFilterType.DROPDOWN,
      options: [...new Set(items.map(item => item.profile?.user?.email))].map(userMail => {
        return { value: userMail, label: userMail }
      }),
      filterFunction: (item: ProfileSubscription, value: string) => item.profile?.user?.email === value
    },
  ];

  return (
    <React.Fragment>
      <Section
        title={'Stripe Subscriptions'}
        subtitle={'The list of all Stripe Subscriptions stored in our database'}
        childrenFullWidth
      >
        <p>Full description of Stripe Subscription statuses here :
          <a href='https://stripe.com/docs/billing/subscriptions/overview#subscription-statuses' target='_blank' rel='noreferrer'>
            Stripe docs
          </a>
        </p>
        <PaginatedList
          keys={keys}
          items={items}
          filterItemStrings={filterItemStrings}
          filterComponents={filterComponents}
          size={100}
          reloadList={loadItems}
          loading={loading}
          error={error}
        />
      </Section>
      <div style={{ height: '2rem' }} />
    </React.Fragment>
  );
}
