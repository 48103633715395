import React, { useEffect } from 'react';
import stylesCommon from '../ModalCommon.module.scss';
import styles from './CreateGroupDiscussionModal.module.scss';
import { StoresBindings } from '@container/.';
import { useInjection, useTranslate } from '@hooks/.';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { Modal, ModalConfirmOrCancelButtons, ModalTitle, NeedConnectionModal } from '..';
import { UserStore, MessageStore, LayoutStore, ModalKey, ModalStore } from '@stores/.';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import {
  OwardFormInput, OwardFormSwitch, OwardLoader,
  OwardSwitchMultipleFlat, ToastSucess,
  DiscussionRoleExplanation,
  NewMemberI,
  NewMembersList,
  GroupDiscussionProfileSearchAndList,
  NewMemberRoleLine
} from '@components/.';
import { MessageController, send, DiscussionMemberRole, AddDiscussionMemberI } from '@oward/openapi';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { remSizeToPixelNumber } from '@utils/utils';
import { SWR_DISCUSSIONS_FOR_PROFILE } from '@utils/constants';
import { DiscussionType } from '@oward/common-enums';
import { mutate } from 'swr';

enum PANEL {
  ADD_MEMBERS = 'add_members',
  MEMBERS_ROLE = 'members_role',
  INFOS = 'infos',
}

export interface CreateDiscussionStore {
  newMembers: NewMemberI[],
  addNewMember: (member: NewMemberI) => void,
  removeNewMember: (profileId: number) => void,
  setNewMemberRole: (profileId: number, role: DiscussionMemberRole) => void,
  currentPanel: PANEL,
  setCurrentPanel: (panel: PANEL) => void,
  searchProfileInput: string,
  setSearchProfileInput: (value: string) => void,
  loading: boolean,
  setLoading: (loading: boolean) => void,
  error: string,
  setError: (txt: string) => void,
}

const schema = (t: any) => {
  return (
    Yup.object().shape({
      name: Yup.string().required(t('forms.required'))
    }))
};

export const CreateGroupDiscussionModal: React.FC = observer(() => {
  const { t } = useTranslate();
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  useEffect(() => {
    // Reset form at each modal opening
    store.newMembers = [];
    store.setCurrentPanel(PANEL.ADD_MEMBERS);
    store.setSearchProfileInput('');
    store.setError('');
    store.setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query[ModalKey.MESSAGES_CREATE_GROUP_DISCUSSION] as string]);


  const store: CreateDiscussionStore = useLocalObservable(() => ({
    newMembers: [],
    addNewMember: (newMember: NewMemberI) => {
      store.newMembers.push(newMember);
    },
    removeNewMember: (profileId: number) => {
      store.newMembers = store.newMembers.filter(member => member.profileInfos.profileId !== profileId);
    },
    setNewMemberRole: (profileId: number, role: DiscussionMemberRole) => {
      let member = store.newMembers.find(member => member.profileInfos.profileId === profileId);
      member.role = role;
    },
    currentPanel: PANEL.ADD_MEMBERS,
    setCurrentPanel(panel) {
      store.currentPanel = panel;
    },
    searchProfileInput: '',
    setSearchProfileInput(value) {
      store.searchProfileInput = value;
    },
    loading: false,
    setLoading(loading) {
      store.loading = loading;
    },
    error: '',
    setError(errorTxt) {
      store.error = errorTxt;
    },
  }));

  const renderModal = () => {
    switch (store.currentPanel) {
      case PANEL.ADD_MEMBERS:
        return <Modal
          modalKey={ModalKey.MESSAGES_CREATE_GROUP_DISCUSSION}
          header={<ModalTitle name={t('modal.create_group_discussion.add_members.title')} />}
          footer={
            <ModalConfirmOrCancelButtons
              confirmTxt={t('global.next')}
              validation={() => {
                if (store.newMembers.length < 1) {
                  store.setError(t('modal.create_group_discussion.at_least_1_member'))
                }
                else {
                  store.setCurrentPanel(PANEL.MEMBERS_ROLE);
                }
              }}
              error={store.error}
            />
          }
        >
          <AddMembersPanel store={store} />
        </Modal>;
      case PANEL.MEMBERS_ROLE:
        return <Modal
          modalKey={ModalKey.MESSAGES_CREATE_GROUP_DISCUSSION}
          header={<ModalTitle name={t('modal.create_group_discussion.member_role.title')} />}
          footer={
            <ModalConfirmOrCancelButtons
              confirmTxt={t('global.next')}
              cancelationTxt={t('global.back')}
              cancelation={() => { store.setCurrentPanel(PANEL.ADD_MEMBERS) }}
              validation={() => {
                if (store.newMembers.length < 1) {
                  store.setError(t('modal.create_group_discussion.at_least_1_member'))
                }
                else {
                  store.setCurrentPanel(PANEL.INFOS);
                }
              }}
              error={store.error}
            />
          }
        >
          <MembersRolePanel store={store} />
        </Modal>;
      case PANEL.INFOS:
        return <Modal
          modalKey={ModalKey.MESSAGES_CREATE_GROUP_DISCUSSION}
          header={<ModalTitle name={t('modal.create_group_discussion.infos_form.title')} />}
        >
          <InfosPanel store={store} />
        </Modal>;
    }
  }

  return (
    <React.Fragment>
      {
        userStore.isLogged ?
          <>
            {renderModal()}
          </>
          :
          <Modal modalKey={ModalKey.MESSAGES_CREATE_GROUP_DISCUSSION}>
            <NeedConnectionModal headerMessage={t('modal.create_group_discussion.need_connection')} />
          </Modal>
      }
    </React.Fragment>
  )
});

interface CreateDiscussionPanelProps {
  store: CreateDiscussionStore;
}

/**
 * ADD MEMBERS PANEL
 */

const AddMembersPanel: React.FC<CreateDiscussionPanelProps> = observer(props => {
  const store = props.store;

  return (
    <div className={stylesCommon.mainContainer}>
      <NewMembersList newMembers={store.newMembers} removeNewMember={store.removeNewMember} />
      <GroupDiscussionProfileSearchAndList store={store} />
    </div>
  );
});

/**
 * MEMBER ROLE PANEL
 */

const MembersRolePanel: React.FC<CreateDiscussionPanelProps> = observer(props => {
  const store = props.store;
  const { t } = useTranslate();

  return (
    <div className={stylesCommon.mainContainer}>
      <div className={styles.membersRoleMainContainer}>
        <DiscussionRoleExplanation isCreation />
        <div className={styles.membersContainer}>
          {store.newMembers.map((member, i) => <NewMemberRoleLine newMember={member} store={store} key={i} />)}
        </div>
      </div>
    </div>
  );
});

/**
 * INFOS PANEL
 */

const InfosPanel: React.FC<CreateDiscussionPanelProps> = observer(props => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const messageStore = useInjection<MessageStore>(StoresBindings.MESSAGE);
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);

  const store = props.store;

  const formik = useFormik({
    initialValues: {
      name: '',
      isPrivate: true,
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      try {
        store.setLoading(true);
        store.setError(undefined);

        // Add Discussion creator as admin and first member
        let creatorMember: AddDiscussionMemberI = {
          profileId: messageStore.currentProfile.id,
          role: DiscussionMemberRole.ADMIN,
        };
        let membersToAdd = [creatorMember].concat(store.newMembers.map(member => {
          return {
            profileId: member.profileInfos.profileId,
            role: member.role
          }
        }));
        let discussionId = await send(MessageController.createGroupDiscussion({
          name: values.name,
          type: values.isPrivate ? DiscussionType.PRIVATE_GROUP : DiscussionType.PUBLIC_GROUP,
          members: membersToAdd
        }));

        mutate([SWR_DISCUSSIONS_FOR_PROFILE, messageStore.currentProfile?.id]);
        toast.dark(<ToastSucess msg={t('modal.create_group_discussion.created', { name: values.name })} />);
        formik.resetForm();
        modalStore.unpopModal(router);
        await router.push(
          `/[lang]/messages/[profilePath]?d=${discussionId}`,
          `/${locale}/messages/${messageStore.currentProfile.path}?d=${discussionId}`
        );
        layoutStore.openMessagesDiscussionContainer(true);
      } catch (err) {
        store.setError(t('global.error_retry'));
      }
      finally {
        store.setLoading(false);
      }
    }
  });

  return (
    <form onSubmit={formik.handleSubmit} className={stylesCommon.mainContainer}>
      <OwardLoader loading={store.loading} />
      <div style={{ padding: '0 0.1rem' }}>
        <OwardFormInput
          id='name'
          label={t('modal.create_group_discussion.infos_form.name')}
          formik={formik}
        />
        {/*
        <OwardFormSwitch
          id='isPrivate'
          label={t('modal.create_group_discussion.infos_form.private')}
          description={t('modal.create_group_discussion.infos_form.private_desc')}
          formik={formik}
        />
        */}
        <ModalConfirmOrCancelButtons
          confirmTxt={t('global.create')}
          cancelationTxt={t('global.back')}
          cancelation={() => { store.setCurrentPanel(PANEL.MEMBERS_ROLE) }}
          error={store.error}
        />
      </div>
    </form>
  );
});
