import React, { useState, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './Accordion.module.scss';
import { useInjection, useTranslate } from '@hooks/.';
import { CardStore, FilterStore, UserStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import classNames from 'classnames';
import { OwardSearchInput, MenuButton } from '@components/.';
import { SearchProfile, SearchFilm, SearchFestival, SearchAdmin, SearchRepresentation, SearchBroadcast } from './AccordionContent';
import SearchIcon from '../../assets/icons/search.svg';
import UserIcon from '../../assets/icons/user.svg';
import PlayIcon from '../../assets/icons/play.svg';
import OscarIcon from '../../assets/icons/oscar.svg';
import HashtagIcon from '../../assets/icons/hashtag.svg';
import TelevisionIcon from '../../assets/icons/television.svg';
import BobineIcon from '../../assets/icons/bobine.svg';
import CameraIcon from '../../assets/icons/camera.svg';
import GroupIcon from '../../assets/icons/group.svg';
import AccordionHashtags from './AccordionHashtags';
import { GalleryType } from '@oward/common-utils';

enum AccordionCategory {
  NONE = 'NONE',
  PROFILE = 'profile',
  REPRESENTATION = 'representation',
  FILM = 'film',
  FESTIVAL = 'festival',
  BROADCAST = 'broadcast',
  HASHTAG = 'hashtag'
}

interface AccordionElementProps {
  category: AccordionCategory,
  icon: ReactNode,
  searchElement: ReactNode,
}

const AccordionElement: React.FC<AccordionElementProps> = observer(props => {
  const { t } = useTranslate();
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);
  const [isActive, setIsActive] = useState<boolean>(false);

  return (
    <div>
      <MenuButton
        name={t(
          `sidebar.search_${props.category.toString()}.title${filterStore.isArtworkGallery ? '_artwork' : ''}`
        )}
        icon={props.icon}
        isActive={isActive}
        onClick={() => {
          if (props.category === AccordionCategory.HASHTAG) {
            if (!isActive) {
              filterStore.hashtagPanelActive = true;
              filterStore.getDisplayedHashtags(filterStore.filter);
            }
            else {
              filterStore.hashtagPanelActive = false;
            }
          }
          setIsActive(!isActive);
        }}
      />
      <div className={classNames({ [styles.hide]: !isActive })}>
        {props.searchElement}
      </div>
    </div>
  );
});

export const Accordion: React.FC = observer(() => {
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  return (
    <div className={classNames(styles.mainContainer)}>
      {
        userStore.isAdmin &&
        <SearchAdmin />
      }
      {
        filterStore.isArtworkGallery ?
          <React.Fragment>
            <AccordionElement
              category={AccordionCategory.FILM}
              icon={<BobineIcon className={styles.svgIcon} />}
              searchElement={<SearchFilm />}
            />
            <AccordionElement
              category={AccordionCategory.FESTIVAL}
              icon={<OscarIcon className={styles.svgIcon} />}
              searchElement={<SearchFestival />}
            />
            <AccordionElement
              category={AccordionCategory.BROADCAST}
              icon={<TelevisionIcon className={styles.svgIcon} />}
              searchElement={<SearchBroadcast />}
            />
            <AccordionElement
              category={AccordionCategory.PROFILE}
              icon={<CameraIcon className={styles.svgIcon} />}
              searchElement={<SearchProfile />}
            />
            <AccordionElement
              category={AccordionCategory.REPRESENTATION}
              icon={<GroupIcon className={styles.svgIcon} />}
              searchElement={<SearchRepresentation />}
            />
          </React.Fragment>
          :
          <React.Fragment>
            <AccordionElement
              category={AccordionCategory.PROFILE}
              icon={<CameraIcon className={styles.svgIcon} />}
              searchElement={<SearchProfile />}
            />
            {
              filterStore.galleryType === GalleryType.PRO &&
              <AccordionElement
                category={AccordionCategory.REPRESENTATION}
                icon={<GroupIcon className={styles.svgIcon} />}
                searchElement={<SearchRepresentation />}
              />
            }
            <AccordionElement
              category={AccordionCategory.FILM}
              icon={<BobineIcon className={styles.svgIcon} />}
              searchElement={<SearchFilm />}
            />
            <AccordionElement
              category={AccordionCategory.FESTIVAL}
              icon={<OscarIcon className={styles.svgIcon} />}
              searchElement={<SearchFestival />}
            />
            <AccordionElement
              category={AccordionCategory.BROADCAST}
              icon={<TelevisionIcon className={styles.svgIcon} />}
              searchElement={<SearchBroadcast />}
            />
            <AccordionElement
              category={AccordionCategory.HASHTAG}
              icon={<HashtagIcon className={styles.svgIcon} />}
              searchElement={<AccordionHashtags />}
            />
          </React.Fragment>
      }
    </div>
  );
});

export default Accordion;
