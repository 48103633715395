import React from 'react';
import styles from './PortfolioPage.module.scss';
import classNames from 'classnames';
import { useInjection, useTranslate } from '@hooks/.';
import { CardStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { observer } from 'mobx-react-lite';
import { CardGetStatus } from '@oward/common-enums';
import { GalleryCards, PortfolioHeader } from '..';

export const PortfolioPage = observer(() => {
  const cardStore = useInjection<CardStore>(StoresBindings.CARD);

  return (
    <div className={styles.mainContainer}>
      <div id='gallery-main-right-container' className={classNames(
        styles.rightContainer,
        styles.rightContainerSidebarClosed
      )}>
        <PortfolioHeader />
        {
          cardStore.cardGetStatus === CardGetStatus.OK &&
          <GalleryCards deactivateFilters />
        }
      </div>
    </div>
  );
});

export default PortfolioPage;
