import React from 'react';
import styles from './Slider.module.scss';
import classNames from 'classnames';

interface OwardSliderProps {
  min?: number;
  max?: number;
  value: number;
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
}

export const OwardSlider: React.FC<OwardSliderProps> = props => {

  return (
    <input
      className={styles.slider}
      min={props.min}
      max={props.max}
      onChange={props.onChange}
      step='any'
      type='range'
      value={props.value}
    />
  );
}

OwardSlider.defaultProps = {
  min: 0,
  max: 1,
} as Partial<OwardSliderProps>;
