import React, { useContext, useState } from "react";
import { useFormik } from 'formik';
import { observer } from "mobx-react-lite";
import { ShortlistController, State, Shortlist, Visibility } from "@oward/openapi";
import { Section, Divider, SubmitOrCancel, NavigationContext, FormComment } from '@components/admin';
import { OwardFormInput, Heading, OwardFormSelect, OwardFormTextArea } from '@components/Core';
import { useTranslate } from '@hooks/.';
import { EditProps } from '../types';
import * as Yup from 'yup';
import { stateToEmoji, visibilityToEmoji } from '@oward/common-utils';
import { ProfileListForShortlist } from "../Profiles/ProfileList";

const schema = (t: any) => {
  return (
    Yup.object().shape({
      name: Yup.string().required(),
    }))
};

export const EditShortlist = observer((props: EditProps<Shortlist>) => {
  const { t, locale } = useTranslate();
  const [error, setError] = useState(false);
  const navigation = useContext(NavigationContext);

  const formik = useFormik({
    initialValues: {
      name: props.item.name,
      state: props.item.state,
      visibility: props.item.visibility,
      description: props.item.description,
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      props.item.name = values.name;
      props.item.state = values.state;
      props.item.visibility = values.visibility;
      props.item.description = values.description;
      try {
        await ShortlistController.saveOneAdmin(props.item);
        if (props.notify) { props.notify(props.item) };
        setError(false);
        navigation.unpop();
      } catch {
        setError(true);
      }
    }
  });

  return (
    <Section
      title='Edit'
      subtitle='Change your entity properties'
      childrenFullWidth
    >
      <form className='box' onSubmit={formik.handleSubmit} >
        <Heading>General</Heading>
        <OwardFormInput
          id='name'
          label='Name *'
          formik={formik}
        />
        <OwardFormSelect
          id='state'
          label='State *'
          options={Object.values(State).map((state) => {
            return ({ value: state, label: state.concat(' ', stateToEmoji(state)) });
          })}
          formik={formik}
        />
        <OwardFormSelect
          id='visibility'
          label='Visibility *'
          options={Object.values(Visibility).map((visibility) => {
            return ({ value: visibility, label: visibility.concat(' ', visibilityToEmoji(visibility)) });
          })}
          formik={formik}
        />
        <OwardFormTextArea
          id='description'
          label='Description'
          formik={formik}
          rows={4}
        />
        <SubmitOrCancel error={error} noCancel={props.noCancel} />
        <Divider />
        <Heading>PROFILES IN THE SHORTLIST</Heading>
        <FormComment>
          <p>{`List of ALL profiles in the shortlist, sorted by shortlist order. WARNING LIST NOT AUTOMATICALLY RELOADED`}</p>
        </FormComment>
        <ProfileListForShortlist shortlist={props.item} />
      </form >
    </Section >
  )
});
