import React, { useState, useEffect } from 'react';
import { NextRouter, useRouter } from 'next/router';
import classNames from 'classnames';
import { Contact, OnFireController, MessageController, send } from '@oward/openapi';
import MoreIcon from '../../assets/icons/more.svg';
import FireIcon from '../../assets/icons/fire.svg';
import BookmarkOutlinedIcon from '../../assets/icons/bookmark_outlined.svg';
import BookmarkIcon from '../../assets/icons/bookmark.svg';
import FireOutlinedIcon from '../../assets/icons/fire_outlined.svg';
import MailIcon from '../../assets/icons/email.svg';
import ShareIcon from '../../assets/icons/paper_plane.svg';
import styles from './CardButtons.module.scss';
import { ModalStore, ModalKey, UserStore, LayoutStore } from '@stores/.'
import { useInjection, useOnFireProfileIds, useProfilesForUser, useTranslate, useUserShortlistsGet } from '@hooks/.';
import { StoresBindings } from '@container/.';
import {
  SWR_ON_FIRE_PROFILE_IDS,
  SWR_ON_FIRE_PROFILES
} from '@utils/.';
import { toast } from 'react-toastify';
import { OwardButton, ToastError, ToastSucess } from '@components/Core';
import { mutate } from 'swr';
import { CardProps } from './Card';
import { findTranslationOrDefault } from '@oward/common-utils';
import { observer } from 'mobx-react-lite';
import { UserType } from '@oward/common-enums';

export enum ButtonAspect {
  CARD = 'card',
  PERSO_PAGE = 'perso_page',
  ON_FIRE = 'on_fire',
}

interface CardButtonsIconProps {
  tooltipText?: string;
  tooltipPosition?: string;
  onClick?: () => any;
  preview?: boolean;
  deactivated?: boolean;
  children: React.ReactNode;
}

const CardButtonsIcon: React.FC<CardButtonsIconProps> = props => {
  return (
    <div className={classNames(styles.buttonsIconContainer)}>
      <div
        className={classNames(
          styles.buttonsIconCircleContainer,
          props.tooltipPosition,
          'has-tooltip-hidden-touch has-tooltip-arrow',
          props.preview && styles.preview,
          props.deactivated && styles.deactivated,
        )}
        data-tooltip={props.tooltipText}
        onClick={() => {
          if (props.onClick) {
            props.onClick()
          }
        }}
      >
        {props.children}
      </div>
    </div>
  );
}

interface CardButtonReportProps {
  profileName: string;
  profileId: number;
  preview?: boolean;
}

export const CardButtonReport = observer((props: CardButtonReportProps) => {
  const { t } = useTranslate();
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  return (
    <CardButtonsIcon
      tooltipText={!props.preview ?
        t(`modal.report_block_profile.report${userStore.isLogged ? '_block' : ''}_button`) :
        undefined
      }
      tooltipPosition='has-tooltip-right'
      preview={props.preview}
      onClick={() => {
        if (!props.preview) {
          modalStore.setReportProfileInfos({ profileName: props.profileName });
          modalStore.openModalNewStack(router, ModalKey.REPORT_BLOCK_PROFILE, props.profileId);
        }
      }}
    >
      <MoreIcon className={classNames(styles.buttonsIcon, styles.preview)} />
    </CardButtonsIcon>
  )
})

interface CardButtonContactProps {
  profileName: string;
  profileId: number;
  buttonAspect: ButtonAspect;
  preview?: boolean;
}


export const getOrCreateDiscussion = async (
  contacterId: number,
  contactedId: number,
  profilePath: string,
  openMessagesContainer: () => void,
  router: NextRouter,
  locale: string
) => {
  let discussionId: number = await send(MessageController.get1to1Discussion(contacterId, contactedId));
  await router.push(
    `/[lang]/messages/[profilePath]?d=${discussionId}`,
    `/${locale}/messages/${profilePath}?d=${discussionId}`
  );

  openMessagesContainer();
}

export const CardButtonContact = observer((props: CardButtonContactProps) => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);
  const { profiles } = useProfilesForUser(locale);

  const onClickContact = async () => {
    if (!props.preview) {
      if (userStore.currentUser?.type === UserType.RECRUITER) {
        modalStore.openModalNewStack(router, ModalKey.CONTACT_DETAILS, props.profileId);
      }
      else {
        if (profiles?.map(profile => profile.id)?.includes(props.profileId)) {
          toast.dark(<ToastError msg={t('messages.no_auto_contact_toast')} />);
          return;
        }
        switch (profiles?.length) {
          case 1:
            await getOrCreateDiscussion(
              profiles[0]?.id, props.profileId, profiles[0]?.path,
              () => { layoutStore.openMessagesDiscussionContainer(true) }, router, locale
            );
            break;
          case 0:
          default:
            modalStore.setContactInfos({ profileName: props.profileName, profileId: props.profileId });
            modalStore.openModalNewStack(router, ModalKey.CONTACT, props.profileId);
            break;
        }
      }
    }
  };

  const renderButton = () => {
    switch (props.buttonAspect) {
      case ButtonAspect.CARD:
        return <div
          className={classNames(styles.ctaContainer, props.preview && styles.preview)}
          onClick={onClickContact}
        >
          <p className={styles.cta}>
            {t('card.contact').toUpperCase()}
          </p>
        </div>;
      case ButtonAspect.ON_FIRE:
        return <CardButtonsIcon
          tooltipText={!props.preview ? t('card.contact') : undefined}
          tooltipPosition='has-tooltip-right'
          onClick={onClickContact}
          preview={props.preview}
        >
          <MailIcon className={`${styles.buttonsIcon}`} />
        </CardButtonsIcon>;
      case ButtonAspect.PERSO_PAGE:
        return <OwardButton
          name={t('card.contact')}
          bigPadding
          noMargin
          transparentBlue
          onClick={onClickContact}
        />
    }
  }

  return (
    <React.Fragment>
      {renderButton()}
    </React.Fragment>
  )
})

interface CardButtonShortlistProps {
  profileId: number;
  profileName: string;
  profileJob: string;
  preview?: boolean;
}

export const CardButtonShortlist = observer((props: CardButtonShortlistProps) => {
  const router = useRouter();
  const { t } = useTranslate();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const [isShortlisted, setIsShortlisted] = useState<boolean>(false);
  const { shortlists } = useUserShortlistsGet();

  useEffect(() => {
    let isSavedInShortlist: boolean = false;
    if (shortlists) {
      shortlists.map((shortlist) => {
        shortlist.profiles?.map(profile => {
          if (profile?.id === props.profileId) {
            isSavedInShortlist = true;
          }
        })
      })
    }
    setIsShortlisted(isSavedInShortlist);
  }, [props.profileId, shortlists]);

  const openShortlistModal = () => {
    if (!props.preview) {
      modalStore.setAddShortlistInfos({
        profileId: props.profileId,
        profileName: props.profileName,
        profileJob: props.profileJob
      });
      modalStore.openModalNewStack(router, ModalKey.ADD_SHORTLIST, props.profileId);
    }
  }
  return (
    <CardButtonsIcon
      tooltipText={!props.preview ? (isShortlisted ? t('card.shortlisted') : t('card.shortlist')) : undefined}
      tooltipPosition='has-tooltip-left'
      onClick={openShortlistModal}
      preview={props.preview}
    >
      {
        isShortlisted ?
          <BookmarkIcon className={`${styles.buttonsIcon}`} />
          :
          <BookmarkOutlinedIcon className={`${styles.buttonsIcon}`} />
      }

    </CardButtonsIcon >
  )
})

export const shareProfilePortfolio = async (
  profileId: number,
  profileName: string,
  profileJob: string,
  profilePath: string,
  canShare: boolean,
  isOwnedByUser: boolean,
  t: any,
  locale: string,
  router: NextRouter
) => {
  if (canShare) {
    let url: string = process.env.WEBSITE_URL.concat('/', locale, '/p/', profilePath);
    if (navigator.share) {
      try {
        await navigator.share({
          title: document.title,
          text: t(
            'page.profile.share_msg',
            { name: profileName, job: profileJob }
          ),
          url: url
        })
      }
      catch (err) {
        // Cancelling 'share' throw an error. We don't really care.
      }
    }
    else {
      var textField = document.createElement('textarea');
      textField.innerText = url;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
      toast.dark(<ToastSucess msg={t('page.profile.share_toast_ok', { name: profileName })} />);
    }
  }
  else {
    toast.dark(
      <ToastError>
        <p>{t('card.toast_not_sharable.text', { name: profileName })}</p>
        {
          isOwnedByUser &&
          <p>
            <span
              className={styles.toasterLink}
              onClick={() => { router.push('/[lang]/account/profile/[profileId]', `/${locale}/account/profile/${profileId}`) }}
            >
              {t('card.toast_not_sharable.link')}
            </span>
            {t('card.toast_not_sharable.link_explanation')}
          </p>
        }
      </ToastError>
    );
  }
}

interface CardButtonShareProps {
  profileId: number;
  profilePath: string;
  profileJob: string
  profileName: string;
  buttonAspect: ButtonAspect;
  preview?: boolean;
  canShare?: boolean;
}

export const CardButtonShare = (props: CardButtonShareProps) => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const { profiles } = useProfilesForUser(locale);

  const shareThisProfilePortfolio = () => {
    shareProfilePortfolio(
      props.profileId,
      props.profileName,
      props.profileJob,
      props.profilePath,
      props.canShare,
      profiles && profiles?.findIndex(profile => profile.id === props.profileId) !== -1,
      t,
      locale,
      router
    );
  }

  const renderButton = () => {
    switch (props.buttonAspect) {
      case ButtonAspect.CARD:
        return <div
          className={classNames(
            styles.ctaContainer,
            props.preview && styles.preview,
            !props.canShare && styles.notActive
          )}
          onClick={() => {
            if (!props.preview) { shareThisProfilePortfolio() }
          }}
        >
          <p className={styles.cta}>
            {t('card.share').toUpperCase()}
          </p>
        </div>
      case ButtonAspect.ON_FIRE:
        return <CardButtonsIcon
          tooltipText={(!props.preview && props.canShare) ? t('page.profile.share') : undefined}
          tooltipPosition='has-tooltip-left'
          onClick={!props.preview && shareThisProfilePortfolio}
          preview={props.preview}
          deactivated={!props.canShare}
        >
          <ShareIcon className={classNames(
            styles.buttonsIcon,
            props.preview && styles.preview,
            !props.canShare && styles.deactivated
          )} />
        </CardButtonsIcon>
      case ButtonAspect.PERSO_PAGE:
        return <OwardButton
          name={t('card.share')}
          bigPadding
          noMargin
          transparentBlue
          onClick={shareThisProfilePortfolio}
        />
    }
  }

  return (
    <React.Fragment>
      {renderButton()}
    </React.Fragment>
  )
}

interface CardButtonOnFireProps {
  profileId: number;
  profileName: string;
  preview?: boolean;
}

export const CardButtonOnFire = observer((props: CardButtonOnFireProps) => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const { onFireProfileIds } = useOnFireProfileIds();

  const isOnFire = () => {
    return onFireProfileIds?.includes(props.profileId);
  }

  const addOrRemoveOnFire = async () => {
    if (isOnFire()) {
      try {
        await OnFireController.removeProfile(props.profileId);
        toast.dark(
          <ToastSucess>
            <p>{t('on_fire.toaster_remove', { name: props.profileName })}</p>
          </ToastSucess>
        );
      }
      catch (err) {
        toast.dark(<ToastError msg={t('global.error_with_code', { code: err })} />);
      }
    }
    else {
      try {
        await OnFireController.addProfile(props.profileId);
        toast.dark(
          <ToastSucess>
            <p>{t('on_fire.toaster_add', { name: props.profileName })}</p>
            <p
              className={styles.toasterLink}
              onClick={() => { router.push('/[lang]/onfire', `/${locale}/onfire`) }}
            >
              {t('on_fire.toaster_see')}
            </p>
          </ToastSucess>
        );
      }
      catch (err) {
        toast.dark(<ToastError msg={t('global.error_with_code', { code: err })} />);
      }
    }
    mutate(SWR_ON_FIRE_PROFILE_IDS);
    mutate(SWR_ON_FIRE_PROFILES);
  }

  return (
    <CardButtonsIcon
      tooltipText={!props.preview ? (isOnFire() ? t('card.on_fire_remove') : t('card.on_fire_put')) : undefined}
      tooltipPosition='has-tooltip-right'
      onClick={async () => {
        if (!props.preview) {
          if (!userStore.isLogged) {
            modalStore.openModalNewStack(router, ModalKey.ADD_ON_FIRE, props.profileId);
          }
          else {
            await addOrRemoveOnFire();
          }
        }
      }}
      preview={props.preview}
    >
      {
        isOnFire() ?
          <FireIcon className={`${styles.buttonsIcon}`} />
          :
          <FireOutlinedIcon className={`${styles.buttonsIcon}`} />
      }
    </CardButtonsIcon>
  )
})

export const CardButtons = (props: CardProps) => {
  const { locale } = useTranslate();

  return (
    <div className={styles.buttonsContainer}>
      <CardButtonReport
        profileId={props.card.id}
        profileName={props.card.name}
        preview={props.preview}
      />
      <CardButtonContact
        profileId={props.card.id}
        profileName={props.card.name}
        buttonAspect={ButtonAspect.CARD}
        preview={props.preview}
      />
      <CardButtonOnFire
        profileId={props.card.id}
        profileName={props.card.name}
        preview={props.preview}
      />
      <CardButtonShare
        profileId={props.card.id}
        profileJob={props.card.job}
        profilePath={props.card.path}
        profileName={props.card.name}
        buttonAspect={ButtonAspect.CARD}
        preview={props.preview}
        canShare={props.card.hasPortfolioPublic}
      />
      <CardButtonShortlist
        profileId={props.card.id}
        profileName={props.card.name}
        profileJob={findTranslationOrDefault(props.card.job, locale)}
        preview={props.preview}
      />
    </div>
  );
}
