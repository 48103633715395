import { NextRouter } from 'next/router';
import { send, FilterI, FilterLabelI, FilterController } from '@oward/openapi';
import { FilterType } from '@oward/common-enums';
import qs from 'qs';
import { ModalKey } from '@stores/.';

const DELIMITER = ';'

const getAsPathName = (pathname: string, query: any): string => {
  for (const queryParam of Object.keys(query)) {
    pathname = pathname.replace('[' + queryParam + ']', query[queryParam] as string);
  }
  return pathname;
}

export const pushModal = (
  router: NextRouter,
  modalKey?: ModalKey,
  modalParam?: number | string
) => {
  const modalQuery = `${modalKey}=`.concat(modalParam?.toString());

  // Modal Param is handled as a search param
  let query = {
    ...router.query,
    [modalKey]: modalParam?.toString()
  };

  // Remove other modal key if pushing another modal (can't cumulate modals)
  for (const [queryParam, _] of Object.entries(query)) {
    if (Object.values(ModalKey).includes(queryParam as ModalKey) && queryParam !== modalKey) {
      delete query[queryParam];
    }
  }

  router.push(
    {
      pathname: router.pathname,
      query: query,
    },
    {
      pathname: getAsPathName(router.pathname, query),
      query: modalQuery
    },
    { shallow: true }
  );
}

export const unpushModal = (
  router: NextRouter,
) => {
  // Remove all Modal Params in the query
  for (const modalKey of Object.values(ModalKey)) {
    if (router.query[modalKey]) { delete router.query[modalKey]; }
  }

  router.push(
    {
      pathname: router.pathname,
      query: router.query
    },
    {
      pathname: getAsPathName(router.pathname, router.query),
      query: ''
    },
    { shallow: true }
  );
}

enum QueryKey {
  FILTER = 'filter',
  LABEL = 'label',
  MODAL = 'modal'
}

export const pushWithFilterAndModal = (
  router: NextRouter,
  locale: string,
  filter: FilterI,
  label: FilterLabelI,
  modalKey?: ModalKey,
  modalParam?: number | string
) => {

  const filterQuery = QueryKey.FILTER.concat('=', queryFromObject(filter));
  const labelQuery = QueryKey.LABEL.concat('=', queryFromObject(label, true));
  const modalQuery = `${modalKey}=`.concat(modalParam?.toString());

  let hrefQuery: string;
  let asQuery: string;

  Object.values(QueryKey).map(queryKey => {
    switch (queryKey) {
      case QueryKey.FILTER:
        hrefQuery = filterQuery;
        asQuery = isFilterEmpty(filter) ? '' : filterQuery;
        break;
      case QueryKey.LABEL:
        hrefQuery = hrefQuery.concat('&', labelQuery);
        break;
      case QueryKey.MODAL:
        if (modalParam) {
          hrefQuery = hrefQuery.concat('&', modalQuery);
          asQuery = asQuery.concat((isFilterEmpty(filter) && filter?.shortlist === undefined) ? '' : '&', modalQuery);
        }
        break;
    }
  })

  router.push(
    {
      pathname: '/[lang]/',
      query: hrefQuery
    },
    {
      pathname: `/${locale}/`,
      query: asQuery
    },
    { shallow: true }
  );
}

export const queryFromObject = (value: object, encode: boolean = false) => {

  return qs.stringify(value, { delimiter: DELIMITER, encode: encode, arrayFormat: "brackets" });
}

export const objectFromQuery = <T extends unknown>(query: string, decode: boolean = false) => {

  return qs.parse(query, { delimiter: DELIMITER, decoder: decode ? function (str) { return str } : undefined }) as T;
}

export const getFilterLabel = async (filter: FilterI | undefined, locale: string) => {

  if (filter !== undefined && Object.keys(filter).length > 0) {
    return await send(
      FilterController.getLabel(filter, locale)
    );
  }

  return {};
}

export const getFilterQuery = (filter: FilterI): string => {
  return QueryKey.FILTER.concat('=', queryFromObject(filter));
}

export const isFilterEmpty = (filter: FilterI, excludeShortlistAndType = false) => {
  if (filter === undefined) {
    return true;
  }
  const keys = Object.keys(filter);
  for (const key of keys) {
    if ((key === FilterType.SHORTLIST || key === FilterType.GALLERY_TYPE) && excludeShortlistAndType) {
      continue;
    }
    if (key === 'hashtag') {
      if (filter?.hashtag?.names && filter.hashtag.names.length > 0) {
        return false;
      }
    }
    else {
      if (filter[key] !== undefined) {
        return false;
      }
    }
  }

  return true;
}
