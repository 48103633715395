import classNames from 'classnames';
import { ReactNode } from 'react';
import styles from './Label.module.scss';

interface LabelProps {
  name: string;
  description?: string | ReactNode;
  descriptionColor?: string;
  center?: boolean;
}

export const Label: React.FC<LabelProps> = props => {
  return (
    <div className={classNames(styles.labelContainer, props.center && styles.center)}>
      <label className={classNames(styles.label)}>{props.name}</label>
      {
        props.description &&
          typeof props.description === 'string' ?
          <p className={classNames(
            styles.description,
            props.descriptionColor ?? styles.descriptionColorDefault,
          )}>
            {props.description}
          </p>
          :
          props.description
      }
    </div>
  )
}
