import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import {
  Agent,
  AgentController,
  AssociationController,
  Biography, Gender, JobsController, PaymentProfile, Profile,
  ProfileController, ProfileType, State, ValidationState, Visibility
} from '@oward/openapi';
import { Section, Divider, SubmitOrCancel, NavigationContext, FormComment } from '@components/admin';
import { EditProps } from '../types';
import {
  Heading,
  OwardFormInput, OwardFormDatePicker, OwardFormTextArea,
  OwardFormSelect, OwardFormSelectLoad, jobsToOptions, associationsToOptions,
  OwardFormSelectLocation,
  OwardFormSelectLoadMulti,
  OwardFormSwitch,
  OwardLoader,
  agentsToOptions,
} from '@components/Core';
import { useTranslate } from '@hooks/.';
import { PREMIUM_LEVEL, stateToEmoji, validationStateToEmoji, visibilityToEmoji } from '@oward/common-utils';
import { SHORT_BIO_CHAR_LIMIT } from '@utils/.';
import * as Yup from 'yup';
import { ArtworkListForProfile } from '../Artworks';
import moment from 'moment';

const schema = (t: any) => {
  return (
    Yup.object().shape({
      name: Yup.string().required(),
      premiumLevel: Yup.number().required(),
      premiumEndDate: Yup.date().required(),
      bioShortFr: Yup.string()
        .max(SHORT_BIO_CHAR_LIMIT, t('forms.short_bio.too_long')),
      contactMail: Yup.string().email(t('forms.email.invalid'))
    }))
};

export const EditProfile = observer((props: EditProps<Profile>) => {
  const { t, locale } = useTranslate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigation = useContext(NavigationContext);

  const formik = useFormik({
    initialValues: {
      name: props.item.name,
      state: props.item.state,
      visibility: props.item.visibility,
      validationState: props.item.validationState,
      type: props.item.type,
      premiumLevel: props.item.premiumLevel,
      premiumEndDate: new Date(props.item.premiumEndDate),
      identityVerified: props.item.identityVerfied,
      path: props.item.path,
      avatarUrl: props.item.avatarUrl,
      location: props.item.location,
      job: props.item.job,
      gender: props.item.gender,
      languages: props.item.languages,
      associations: props.item.associations,
      agents: props.item.agents,
      agentDisplayedId: (props.item.agentDisplayed as Agent)?.id,
      birthDate: props.item.birthDate ? new Date(props.item.birthDate) : undefined,
      bioShortFr: props.item.bios.find(bios => bios.locale === 'fr')?.short,
      bioLongFr: props.item.bios.find(bios => bios.locale === 'fr')?.long,
      contactMail: props.item.contact?.emailPerso,
      artworks: props.item.artworks,
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      props.item.name = values.name;
      props.item.state = values.state;
      props.item.visibility = values.visibility;
      props.item.validationState = values.validationState;
      props.item.type = values.type;
      props.item.premiumLevel = values.premiumLevel;
      props.item.premiumEndDate = values.premiumEndDate.toISOString();
      props.item.identityVerfied = values.identityVerified;
      props.item.path = values.path;
      props.item.avatarUrl = values.avatarUrl;
      props.item.location = values.location;
      props.item.job = values.job;
      props.item.gender = values.gender;
      props.item.languages = values.languages === undefined ? [] : values.languages;
      props.item.associations = values.associations === undefined ? [] : values.associations;
      props.item.agents = values.agents;
      let displayedAgentFromAgents: Agent | undefined = values.agents?.find(agent => agent.id === values.agentDisplayedId);
      props.item.agentDisplayed = (values.agentDisplayedId === undefined || displayedAgentFromAgents === undefined) ? null : displayedAgentFromAgents;
      props.item.birthDate = values.birthDate?.toISOString();
      props.item.artworks = values.artworks;
      let bioFr: Biography | undefined = props.item.bios.find(bios => bios.locale === 'fr');
      if (bioFr) {
        bioFr.short = values.bioShortFr;
        bioFr.long = values.bioLongFr;
      }
      props.item.contact.emailPerso = values.contactMail;
      props.item.paymentProfiles = undefined; // We don't wanna save payment, just for visualisation
      try {
        setLoading(true);
        await ProfileController.saveOne(props.item);
        props.notify(props.item);
        setError(false);
        navigation.unpop();
      } catch {
        setError(true);
      }
      finally {
        setLoading(false);
      }
    }
  });

  return (
    <React.Fragment>
      <Section
        title={`Edit ${formik.initialValues.name} profile`}
        subtitle={`Change the profile properties`}
        childrenFullWidth
      >
        <form className='box' style={{ position: 'relative' }} onSubmit={formik.handleSubmit} >
          <OwardLoader loading={loading} />
          <Heading>GENERAL</Heading>
          <OwardFormInput
            id='name'
            type='name'
            label={t('forms.name.label') + ' *'}
            icon='far fa-user'
            placeholder={t('forms.name.placeholder')}
            formik={formik}
          />
          <OwardFormSelect
            id='state'
            label='State *'
            options={Object.values(State).map((state) => {
              return ({ value: state, label: state.concat(' ', stateToEmoji(state)) });
            })}
            formik={formik}
          />
          <OwardFormSelect
            id='visibility'
            label='Visibility *'
            options={Object.values(Visibility).map((visibility) => {
              return ({
                value: visibility,
                label: visibility.concat(' ', visibilityToEmoji(visibility))
              });
            })}
            formik={formik}
          />
          <OwardFormSelect
            id='validationState'
            label='Validation State *'
            options={Object.values(ValidationState).map((validationState) => {
              return ({ value: validationState, label: validationState });
            })}
            formik={formik}
          />
          <Divider />
          <Heading>SUBSCRIPTION DETAILS</Heading>
          <OwardFormSelect
            id='type'
            label='Profile Type *'
            options={Object.values(ProfileType).map((type) => {
              return ({ value: type, label: type });
            })}
            formik={formik}
          />
          <OwardFormInput
            id='premiumLevel'
            type='number'
            label='Premium Level *'
            icon='fas fa-coins'
            placeholder='0'
            formik={formik}
          />
          <FormComment>
            <p>{t('premium_level.pro')} : {PREMIUM_LEVEL.PRO}</p>
            <p>{t('premium_level.portfolio')} : {PREMIUM_LEVEL.PORTFOLIO}</p>
            <p>{t('premium_level.company')} : {PREMIUM_LEVEL.COMPANY} (WARNING: Change also Profile Type if switching to Company)</p>
          </FormComment>
          <OwardFormDatePicker
            id='premiumEndDate'
            label='Premium End Date *'
            formik={formik}
          />
          <OwardFormSwitch
            id='identityVerified'
            label={'Identity Verified'}
            formik={formik}
          />
          <Divider />
          <Heading>INFORMATIONS</Heading>
          <OwardFormInput
            id='path'
            type='name'
            label={'Path *'}
            placeholder={'Must be unique'}
            formik={formik}
          />
          <OwardFormInput
            id='avatarUrl'
            type='url'
            label='Avatar URL'
            icon='fas fa-portrait'
            formik={formik}
          />
          <OwardFormSelectLocation
            id='location'
            label='Location *'
            formik={formik}
          />
          <OwardFormSelectLoad
            id='job'
            label='Job *'
            fetchFunction={JobsController.list(locale, props.item.type, props.item.gender)}
            entitiesToOptions={jobsToOptions}
            formik={formik}
          />
          <OwardFormSelect
            id='gender'
            label='Gender *'
            options={Object.values(Gender).map((type) => {
              return ({ value: type, label: type });
            })}
            formik={formik}
          />
          <FormComment>
            <p style={{ color: 'red' }}>
              {'Don\'t choose OTHER or UNKONWN gender as it will break things.'}
            </p>
          </FormComment>
          <OwardFormSelectLoadMulti
            id='languages'
            label='Languages'
            fetchFunction={ProfileController.listLanguages(locale)}
            placeholder={'Click to choose from list'}
            formik={formik}
          />
          <OwardFormSelectLoadMulti
            id='associations'
            label='Associations'
            fetchFunction={AssociationController.list(locale)}
            entitiesToOptions={associationsToOptions}
            placeholder={'Click to choose from list'}
            formik={formik}
          />
          <OwardFormSelectLoadMulti
            id='agents'
            label='Agents'
            fetchFunction={AgentController.list(locale)}
            entitiesToOptions={(entityList) => { return agentsToOptions(entityList, true) }}
            formik={formik}
          />
          <OwardFormSelect
            id='agentDisplayedId'
            label='Displayed Agent'
            isClearable
            options={formik.values.agents?.map((agent) => {
              return ({ value: agent.id, label: agent.name });
            })}
            formik={formik}
          />
          <FormComment>
            WARNING : displayed agent must be in agent list of the profile
          </FormComment>
          <OwardFormDatePicker
            id='birthDate'
            label='Birthdate'
            formik={formik}
          />
          <OwardFormTextArea
            id='bioShortFr'
            label='Short Bio FR'
            placeholder=''
            formik={formik}
            rows={3}
          />
          <FormComment>
            <p>
              Number of characters:
              <span style={{
                color: formik.values.bioShortFr?.length > SHORT_BIO_CHAR_LIMIT ? 'red' : ''
              }}>
                {formik.values.bioShortFr?.length}
              </span>
              /{SHORT_BIO_CHAR_LIMIT}
            </p>
          </FormComment>
          <OwardFormTextArea
            id='bioLongFr'
            label='Long Bio FR'
            placeholder=''
            formik={formik}
          />
          <OwardFormInput
            id='contactMail'
            type='mail'
            label='Contact Mail'
            icon='far fa-envelope'
            placeholder={t('forms.email.placeholder')}
            formik={formik}
          />
          <Divider />
          <Heading>ARTWORKS</Heading>
          <FormComment>
            <p>{`List of ALL ${formik.initialValues.name} artworks in database. WARNING LIST NOT AUTOMATICALLY RELOADED`}</p>
          </FormComment>
          <ArtworkListForProfile
            formikId='artworks'
            profile={props.item}
            formik={formik}
          />
          <Divider />
          <Heading>PAYMENT FOR PROFILE</Heading>
          <FormComment>
            <p>{`List of ALL payment intents for ${formik.initialValues.name} profile in database. WARNING LIST NOT AUTOMATICALLY RELOADED`}</p>
          </FormComment>
          <PaymentProfileList paymentProfiles={props.item.paymentProfiles} />
          <SubmitOrCancel error={error} />
        </form >
        <div style={{ height: '20px' }} />
      </Section >

    </React.Fragment>
  )
});

interface PaymentProfileListProps {
  paymentProfiles: PaymentProfile[];
}

export const PaymentProfileList: React.FC<PaymentProfileListProps> = props => {
  const { locale } = useTranslate();

  return (
    <React.Fragment>
      {
        props.paymentProfiles?.length > 0 ?
          <table className='table is-fullwidth'>
            <thead>
              <tr>
                <th>Id</th>
                <th>Amount</th>
                <th>Premium Level</th>
                <th>Payment Date</th>
                <th>ValidationState</th>
                <th>Promo Code</th>
                <th>Profile</th>
              </tr>
            </thead>
            <tbody>
              {

                props.paymentProfiles?.map((payment: PaymentProfile, i) =>
                  <tr key={i}>
                    <td><p>
                      {payment.id}
                    </p></td>
                    <td><p>
                      {(payment.amount / 100).toLocaleString(locale, { style: 'currency', currency: 'EUR' })}
                    </p></td>
                    <td><p>
                      {payment.premiumLevel.toString()}
                    </p></td>
                    <td><p>
                      {moment(payment.creationDate).format('DD MMM yyyy HH:mm')}
                    </p></td>
                    <td><p>
                      {payment.validationState.concat(' ', validationStateToEmoji(payment.validationState))}
                    </p></td>
                    <td><p>
                      {payment.promoCode?.code ? `${payment.promoCode.id} - ${payment.promoCode.code}` : '-'}
                    </p></td>
                    <td><p>
                      {payment.profileId.toString() + ' - ' + payment.profile?.name}
                    </p></td>
                  </tr>
                )
              }
            </tbody>
          </table>
          :
          <p>No payment yet</p>
      }
    </React.Fragment>
  );
}
