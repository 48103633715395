import classNames from 'classnames';
import React from 'react';
import styles from './Insert.module.scss';

export enum InsertColor {
  yellow = 'yellow',
  green = 'green',
  blue = 'blue',
  blueOutlined = 'blueOutlined'
}

interface InsertColouredProps {
  title?: string;
  text?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

interface InsertProps extends InsertColouredProps {
  color: InsertColor;
}

export const OwardInsert: React.FC<InsertProps> = props => {
  return (
    <div
      className={classNames(
        styles.mainContainer,
        props.color === InsertColor.yellow && styles.yellow,
        props.color === InsertColor.green && styles.green,
        props.color === InsertColor.blue && styles.blue,
        props.color === InsertColor.blueOutlined && styles.blueOutlined,
        props.onClick && styles.clickable,
      )}
      onClick={props.onClick}
    >
      <div className={styles.contentContainer}>
        {
          props.title &&
          <p className={styles.title}>{props.title}</p>
        }
        <div className={styles.textContainer}>
          {
            props.text ?
              <p>{props.text}</p>
              :
              <>{props.children}</>
          }
        </div>
      </div>
      {
        props.onClick &&
        <div className={styles.chevronContainer}>
          <p>{'>'}</p>
        </div>
      }

    </div>
  );
}

export const OwardInsertYellow: React.FC<InsertColouredProps> = props => {
  return (
    <OwardInsert color={InsertColor.yellow} {...props} />
  )
}

export const OwardInsertGreen: React.FC<InsertColouredProps> = props => {
  return (
    <OwardInsert color={InsertColor.green} {...props} />
  )
}

export const OwardInsertBlue: React.FC<InsertColouredProps> = props => {
  return (
    <OwardInsert color={InsertColor.blue} {...props} />
  )
}

export const OwardInsertBlueOutlined: React.FC<InsertColouredProps> = props => {
  return (
    <OwardInsert color={InsertColor.blueOutlined} {...props} />
  )
}
