import React, { useState, useEffect, useContext } from 'react';
import { send, JobsController, Job, Gender } from '@oward/openapi';

import { PaginatedList, PaginatedListKey, Section, NavigationContext } from '@components/admin';
import { EditJob } from './EditJob';
import { strToPath, stateToEmoji } from '@oward/common-utils';
import { OwardButton } from '@components/Core';

export const JobList: React.FC = () => {

  const store = useContext(NavigationContext);
  const [items, setItems] = useState([] as Job[]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = async () => {
    try {
      setLoading(true);
      setError(false);
      setItems(await send(JobsController.find()))
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const getEnglishSingularName = (item: Job): string | undefined => {
    return item.translations.find(translation => translation.gender === Gender.MALE && translation.locale === 'en')?.name;
  }

  const keys: PaginatedListKey<Job>[] = [
    {
      label: 'Id',
      value: item => item.id
    },
    {
      label: 'Name FR',
      value: item => item.name.concat(' ', stateToEmoji(item.state))
    },
    {
      label: 'Name EN',
      value: item => getEnglishSingularName(item)
    },
    {
      label: 'Category',
      value: item => item.category?.name
    },
    {
      label: 'Profile Type',
      value: item => item.profileType
    }
  ];

  const filterItemStrings = (item: Job) => [
    item.id.toString(),
    strToPath(item.name),
    strToPath(getEnglishSingularName(item)),
    strToPath(item.category?.name),
  ];

  const fetchOne = (item: Job) => send(JobsController.findOne(item.id));

  return (
    <React.Fragment>
      <Section
        title='List of Jobs'
        subtitle='The list of all jobs in the database'
        childrenFullWidth
      >
        <PaginatedList
          keys={keys}
          items={items}
          filterItemStrings={filterItemStrings}
          size={10}
          editComponent={EditJob}
          fetchOne={fetchOne}
          reloadList={loadItems}
          loading={loading}
          error={error}
        />
      </Section>
      <div style={{ height: '2rem' }} />
      <Section
        title='Create new Job'
        subtitle='Create it WHEN PRESSING THIS BUTTON. Cannot be undone, please fill all needed infos in next screen'
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <OwardButton
            name='Create Job'
            onClick={async () => {
              let job: Job = await send(JobsController.createOne());
              loadItems();
              store.pop({
                label: 'Create Job',
                element: <EditJob item={job} notify={loadItems} noCancel />
              });
            }}
          />
          <p className='has-text-danger'>
            Newly created Job will be in BLOCKED State.
          </p>
        </div>
      </Section>
    </React.Fragment>
  );
}
