import React, { useState, useEffect, useContext } from 'react';
import { send, Film, FilmsController, FilmSelection, FestivalAward } from '@oward/openapi';
import {
  Divider, PaginatedList, PaginatedListKey, Section,
  SimpleList, SimpleListKey, NavigationContext
} from '@components/admin';
import { EditFilm } from './EditFilm';
import { findTranslationOrDefault, strToPath, stateToEmoji } from '@oward/common-utils';
import { useTranslate } from '@hooks/.';
import { festivalAwardToStr } from '@utils/.';
import { EditFilmSelection } from './EditFilmSelection';
import { Heading, OwardButton } from '@components/Core';

export const FilmList: React.FC = () => {

  const { locale } = useTranslate();
  const [items, setItems] = useState<Film[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadItems = async () => {
    try {
      setLoading(true);
      setError(false);
      setItems(await send(FilmsController.findAdmin(locale)))
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const keys: PaginatedListKey<Film>[] = [
    {
      label: 'Id',
      value: item => item.id
    },
    {
      label: 'Name',
      value: item => item.name.concat(' ', stateToEmoji(item.state))
    },
    {
      label: 'Type',
      value: item => findTranslationOrDefault(item.type, locale)
    },
    {
      label: 'Release year',
      value: item => item.releaseYear
    },
    {
      label: 'Number of LIVE artworks',
      value: item => item.artworks.length
    },
  ];

  const filterItemStrings = (item: Film) => [item.id.toString(), strToPath(item.name)];

  const fetchOne = (item: Film) => send(FilmsController.findOne(item.id, locale));

  return (
    <Section
      title='List'
      subtitle='The list of all films in the database'
      childrenFullWidth
    >
      <PaginatedList
        keys={keys}
        items={items}
        filterItemStrings={filterItemStrings}
        size={100}
        editComponent={EditFilm}
        fetchOne={fetchOne}
        reloadList={loadItems}
        loading={loading}
        error={error}
      />
    </Section>
  );
}

interface FilmListForFestivalProps {
  festivalId: number;
}

export const FilmListForFestival: React.FC<FilmListForFestivalProps> = props => {

  const { locale } = useTranslate();
  const [items, setItems] = useState<Film[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadItems = async () => {
    try {
      setLoading(true);
      setError(false);
      setItems(await send(FilmsController.findForFestival(props.festivalId, locale)))
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const keys: SimpleListKey<Film>[] = [
    {
      label: 'Id',
      value: item => <p>{item.id}</p>
    },
    {
      label: 'Name',
      value: item => <p>{item.name.concat(' ', stateToEmoji(item.state))}</p>
    },
    {
      label: 'Type',
      value: item => <p>{findTranslationOrDefault(item.type, locale)}</p>
    },
  ];

  const fetchOne = (item: Film) => send(FilmsController.findOne(item.id, locale));

  return (
    <div style={{ marginBottom: '3rem', marginTop: '3rem' }}>
      <Divider />
      <Heading>FILM SELECTED TO THIS FESTIVAL</Heading>
      <SimpleList
        keys={keys}
        items={items}
        editComponent={EditFilm}
        fetchOne={fetchOne}
        reloadList={loadItems}
        loading={loading}
        error={error}
      />
    </div>
  );
}


interface FilmSelectionListProps {
  filmId: number;
}

export const FilmSelectionList: React.FC<FilmSelectionListProps> = props => {

  const { locale } = useTranslate();
  const store = useContext(NavigationContext);
  const [items, setItems] = useState<FilmSelection[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadItems = async () => {
    try {
      setLoading(true);
      setError(false);
      setItems(await send(FilmsController.findSelectionForFilm(props.filmId)))
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const keys: SimpleListKey<FilmSelection>[] = [
    {
      label: 'Id',
      value: item => <p>{item.id}</p>
    },
    {
      label: 'Festival',
      value: item => <p>{item.festival?.name.concat(' ', stateToEmoji(item.state))}</p>
    },
    {
      label: 'Award',
      value: item => <p>{festivalAwardToStr(item.award as FestivalAward, locale)}</p>
    },
    {
      label: 'Status',
      value: item => <p>{item.status}</p>
    },
    {
      label: 'Year',
      value: item => <p>{item.year}</p>
    },
  ];


  const fetchOne = (item: FilmSelection) => send(FilmsController.findOneSelection(item.id, locale));

  return (
    <div style={{ marginBottom: '3rem', marginTop: '3rem' }}>
      <Divider />
      <Heading>FILM SELECTIONS</Heading>
      <SimpleList
        keys={keys}
        items={items}
        editComponent={EditFilmSelection}
        fetchOne={fetchOne}
        reloadList={loadItems}
        loading={loading}
        error={error}
      />
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <OwardButton
          name='Create Film Selection'
          onClick={async () => {
            let filmSelection: FilmSelection = await send(FilmsController.createOneSelection(props.filmId));
            loadItems();
            store.pop({
              label: 'Create Film Selection',
              element: <EditFilmSelection item={filmSelection} notify={loadItems} noCancel />
            });
          }}
        />
        <p className='has-text-danger'>
          Newly created Film Selection will be in BLOCKED State.
        </p>
      </div>
    </div>
  );
}
