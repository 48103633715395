import React from 'react';

import { Divider } from '@components/admin';
import { ProfileStatistics } from './ProfileStatistics';
import { ProfileList } from './ProfileList';

export const ProfilesComponent: React.FC = () => {
  return (
    <React.Fragment>
      <ProfileStatistics />
      <Divider />
      <ProfileList />
    </React.Fragment>
  );
}
