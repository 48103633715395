import { makeAutoObservable, runInAction } from 'mobx';
import { send, FilterI, Request, Artwork, ArtworkGetRespI, ArtworkController, ArtworkRequestParamsI } from '@oward/openapi';
import { injectable, inject } from 'inversify';
import {
  ERR_MSG_ABORTED,
  onTouchDevice,
  NUMBER_ARTWORK_LOADED_INIT,
  NUMBER_ARTWORK_LOADED_SCROLL,
  NUMBER_ARTWORK_LOADED_MOBILE,
} from '@utils/.';
import { FilterStore } from '@stores/.';
import { StoresBindings, ApplicationBindings } from '@container/.';

@injectable()
export class ArtworkStore {
  constructor() {
    makeAutoObservable(this);
  }

  @inject(ApplicationBindings.LOCALE) private locale: string;
  @inject(StoresBindings.FILTER) private filterStore: FilterStore;
  artworks: Artwork[] = new Array<Artwork>();
  totalArtworkCount: number = 0;
  isLoading: boolean = false;
  error: boolean = false;
  isMoreLoading: boolean = false;
  hasMore: boolean = false;
  scrollPosition: any;

  // Keep a ref of the last getCards request, to abort it if a new one is sent
  getArtworksReq: Request<ArtworkGetRespI> = null;

  getArtworks = async (filter: FilterI, openSearch?: string) => {
    this.error = false;
    if (this.getArtworksReq !== null) {
      this.getArtworksReq.abort();
    }
    const params: ArtworkRequestParamsI = {
      limit: onTouchDevice() ? NUMBER_ARTWORK_LOADED_MOBILE : NUMBER_ARTWORK_LOADED_INIT,
      offset: 0,
      isRandom: this.filterStore.isRandom
    };
    let req: Request<ArtworkGetRespI> = ArtworkController.getForGallery(this.locale, params, filter, openSearch);
    let resp: ArtworkGetRespI;
    try {
      this.setIsLoading(true);
      this.getArtworksReq = req;
      resp = await send(req);
      this.getArtworksReq = null;
      runInAction(() => {
        this.artworks = resp.artworks;
        this.totalArtworkCount = resp.totalArtworkCount;
        this.hasMore = this.totalArtworkCount > resp.artworks?.length + params.offset;
      });
      this.setIsLoading(false);
    }
    catch (err) {
      if (err instanceof Error && err.message === ERR_MSG_ABORTED) {
        // Do nothing, as a request aborted mean a new one is sent
      }
      else {
        console.error(err);
        runInAction(() => {
          this.error = true;
          this.artworks = undefined;
          this.totalArtworkCount = 0;
        });
        this.setIsLoading(false);
      }
    }
  }

  getMoreArtworks = async () => {
    this.isMoreLoading = true;

    let loadedArtworksId: number[] = new Array<number>();
    if (this.filterStore.isRandom) {
      this.artworks.map((artwork: Artwork) => {
        loadedArtworksId.push(artwork.id);
      });
    }

    let loadedArtworksNumber: number = this.artworks.length;
    const params: ArtworkRequestParamsI = {
      limit: onTouchDevice() ? NUMBER_ARTWORK_LOADED_MOBILE : NUMBER_ARTWORK_LOADED_SCROLL,
      offset: loadedArtworksNumber,
      isRandom: this.filterStore.isRandom,
      loadedArtworksId: loadedArtworksId.join(',')
    };

    const resp: ArtworkGetRespI = await send(ArtworkController.getForGallery(this.locale, params, this.filterStore.filter));
    runInAction(() => {
      this.artworks = this.artworks.concat(resp.artworks);
      this.hasMore = this.totalArtworkCount > resp.artworks?.length + loadedArtworksNumber;
      this.isMoreLoading = false;
    });
  }

  setIsLoading = (loading: boolean) => {
    let artworksGrid: HTMLElement = document.getElementById('artworks-grid');
    this.isLoading = loading;
    // We adjust the card gallery opacity
    if (artworksGrid !== null) {
      artworksGrid.style.opacity = loading ? '0.2' : '1';
    }
  }

  setScrollPosition = (scrollPosition: any) => {
    this.scrollPosition = scrollPosition;
  }
}
