import React, { useState, useEffect } from 'react';
import styles from './TopBar.module.scss';
import { useInjection, useProfilesForUser, useTranslate } from "@hooks/.";
import { ModalKey, ModalStore } from '@stores/.';
import { useRouter } from 'next/router';
import { StoresBindings } from '@container/.';
import classNames from 'classnames';
import { isProfileExposed } from '@utils/utils';

interface OfferTopBarProps {
  text: string;
  hasSeeOrCreateProfileButton?: boolean;
  linkText?: string;
  linkOnClick?: () => void;
  dontDisplayOnMobile?: boolean;
}

export const OfferTopBar: React.FC<OfferTopBarProps> = props => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const { profiles } = useProfilesForUser(locale);

  return (
    <div className={classNames(styles.topBarContainer, styles.blueBar, props.dontDisplayOnMobile && styles.dontDisplayOnMobile)}>
      <p className={styles.text}>
        {props.text}
        {
          props.hasSeeOrCreateProfileButton &&
          <React.Fragment>
            {
              profiles?.length > 0 ?
                <span
                  onClick={() => router.push('/[lang]/account/profiles', `/${locale}/account/profiles`)}
                  className={styles.link}
                >
                  {t('gallery.top_bar_offer_see_profiles', profiles.length)}
                </span>
                :
                <span
                  onClick={() => modalStore.openModalNewStack(router, ModalKey.NEW_PROFILE, 1)}
                  className={styles.link}
                >
                  {t('gallery.top_bar_offer_create_profile')}
                </span>
            }

          </React.Fragment>
        }
        {
          props.linkText && props.linkOnClick &&
          <span
            onClick={props.linkOnClick}
            className={styles.link}
          >
            {props.linkText}
          </span>
        }
      </p>
    </div>
  );
}

export const WarningProfileNotExposedTopBar: React.FC = () => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const { profiles } = useProfilesForUser(locale);
  const [unpulishedProfileCount, setUnpulishedProfileCount] = useState(undefined);

  useEffect(() => {
    setUnpulishedProfileCount(profiles?.filter(profile => !isProfileExposed(profile))?.length);
  }, [profiles])


  return (
    <React.Fragment>
      {
        unpulishedProfileCount > 0 &&
        <div className={classNames(styles.topBarContainer, styles.redBar)}>
          <p className={styles.text}>
            {t('gallery.top_bar_warning_unpublished_profiles', unpulishedProfileCount) + ' '}
            {
              <React.Fragment>
                {
                  profiles?.length > 0 &&
                  <span
                    onClick={() => router.push('/[lang]/account/profiles', `/${locale}/account/profiles`)}
                    className={styles.link}
                  >
                    {t('gallery.top_bar_offer_see_profiles', unpulishedProfileCount)}
                  </span>

                }
              </React.Fragment>
            }
          </p>
        </div>
      }
    </React.Fragment>
  );
}
