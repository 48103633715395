import styles from './EditProfile.module.scss';
import {
  Link,
  OwardButton,
  OwardLinkButton,
} from '@components/.';
import { useInjection, useTranslate } from '@hooks/.';
import React from 'react';
import { LayoutStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { PREMIUM_LEVEL } from '@oward/common-utils';
import { oniOSWebview } from '@utils/utils';

interface EditProfileHeaderProps {
  onGoBack: () => void,
  onGoBackStr?: string,
  title: string,
}

export const EditProfileHeader: React.FC<EditProfileHeaderProps> = props => {
  const { t } = useTranslate();

  return (
    <React.Fragment>
      <div className={styles.scrollMobileContainer} id='edit-profile-menu-scroll-mobile'></div>
      <div className={styles.headerContainer}>
        <a className={styles.goBack} onClick={props.onGoBack}>
          <p>{props.onGoBackStr ?? t('edit_profile.go_back')}</p>
        </a>
        <EditProfileTitle title={props.title} />
      </div>
    </React.Fragment>
  );
}

interface EditProfileTitleProps {
  title: string,
}

export const EditProfileTitle: React.FC<EditProfileTitleProps> = props => {
  return (
    <div className={styles.titleContainer}>
      <p className={styles.title}>
        {props.title}
      </p>
    </div>
  )
}

interface EditProfileSaveOrCancelProps {
  onCancel: () => void,
  onSave?: () => void,
  noSave?: boolean;
  forArtwork?: boolean,
  formik?: any;
}

export const EditProfileSaveOrCancel: React.FC<EditProfileSaveOrCancelProps> = props => {
  const { t } = useTranslate();
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);

  const hasFormikError = () => {
    let formikError = false;
    for (const [id, error] of Object.entries(props.formik.errors)) {
      if (error && props.formik.touched[id]) {
        formikError = true
      }
    }
    return formikError;
  }

  return (
    <div className={styles.saveOrCancelContainer}>
      <hr className={styles.footerHr} />
      {
        props.formik && hasFormikError() &&
        <div className={styles.errorContainer}>
          <p className={styles.error}>{t('forms.cant_save')}</p>
        </div>
      }
      <div className={styles.buttonsContainer}>
        {
          !props.noSave &&
          <div className={styles.buttonContainer}>
            {
              props.onSave ?
                <OwardButton
                  fullWidth
                  name={t('global.save')}
                  onClick={props.onSave}
                />
                :
                <OwardButton
                  fullWidth
                  name={t('global.save')}
                  submit
                />
            }
          </div>
        }
        <div className={styles.buttonContainer}>
          <OwardButton
            outlined
            fullWidth
            name={t('global.cancel')}
            onClick={props.onCancel}
          />
        </div>
        <div className={styles.previewButtonContainer}>
          <OwardButton
            iconName={'fas fa-eye'}
            outlined
            onClick={() => {
              layoutStore.scrollToElement(
                props.forArtwork ? 'edit-artwork-preview-container' : 'edit-profile-card-preview-container',
                false
              );
            }}
          />
        </div>
      </div>
    </div>
  )
}

interface NeedPortfolioProps {
  premiumLevel: number;
  profileId: number;
  text: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

export const NeedPortfolio: React.FC<NeedPortfolioProps> = props => {
  const { t } = useTranslate();
  return (
    <React.Fragment>
      {
        props.premiumLevel < PREMIUM_LEVEL.PORTFOLIO ?
          <>
            {
              // On iOS App, all links to subscription stuff must be removed
              !oniOSWebview() &&
              <div style={{ position: 'relative' }}>
                <div className={styles.needPortfolioOpacity} />
                <div className={styles.needPortfolioContainer}>
                  <p className={styles.text}>{props.text}</p>
                  {
                    props.onClick ?
                      <OwardButton
                        name={t('edit_profile.see_offer')}
                        onClick={props.onClick}
                      />
                      :
                      <Link href={`/subscriptions/[profileId]`} as={`/subscriptions/${props.profileId}`} passHref>
                        <OwardLinkButton
                          name={t('edit_profile.see_offer')}
                        />
                      </Link>
                  }
                </div>
                <div style={{ padding: '2rem' }}>
                  {props.children}
                </div>
              </div>
            }
          </>
          :
          props.children
      }
    </React.Fragment>
  );
}
