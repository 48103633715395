export const GA_TRACKING_ID = process.env.GA_TRACKING_ID;
export const GOOGLE_OPTIMIZE = process.env.GOOGLE_OPTIMIZE;

// So we don't get errors when calling window.gatg https://stackoverflow.com/a/56402425/1114926
declare const window: any;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  if (window.gtag !== undefined) {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: url,
    })
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  if (window.gtag !== undefined) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    })
  }
}
