import React, { useState, useEffect, useContext } from 'react';
import { send, ShortlistController, Shortlist } from '@oward/openapi';
import { PaginatedList, PaginatedListKey, Section, NavigationContext } from '@components/admin';
import { EditShortlist } from './EditShortlist';
import { stateToEmoji, strToPath, visibilityToEmoji } from '@oward/common-utils';
import { userToStrFull } from '@utils/.';
import { useTranslate } from '@hooks/use-translate';

export const ShortlistList: React.FC = () => {

  const store = useContext(NavigationContext);
  const { t, locale } = useTranslate();
  const [items, setItems] = useState([] as Shortlist[]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = async () => {
    try {
      setLoading(true);
      setError(false);
      setItems(await send(ShortlistController.listAdmin()))
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const keys: PaginatedListKey<Shortlist>[] = [
    {
      label: 'Id',
      value: item => item.id
    },
    {
      label: 'Name',
      value: item => item.name.concat(' ', visibilityToEmoji(item.visibility), stateToEmoji(item.state))
    },
    {
      label: 'User (shortlist owner)',
      value: item => userToStrFull(item.user)
    },
    {
      label: 'Number of profiles',
      value: item => item.shortlistProfiles.length
    }
  ];

  const filterItemStrings = (item: Shortlist) => [
    item.id.toString(),
    strToPath(item.name),
    strToPath(userToStrFull(item.user))
  ];

  const fetchOne = (item: Shortlist) => send(ShortlistController.findOneAdmin(item.id));

  return (
    <React.Fragment>
      <Section
        title='List'
        subtitle='The list of all shortlists in the database'
        childrenFullWidth
      >
        <PaginatedList
          keys={keys}
          items={items}
          filterItemStrings={filterItemStrings}
          size={100}
          editComponent={EditShortlist}
          fetchOne={fetchOne}
          reloadList={loadItems}
          loading={loading}
          error={error}
        />
      </Section>
      <div style={{ height: '2rem' }} />
    </React.Fragment>
  );
}
