import React, { ReactNode } from 'react';
import { useInjection, useProfilesForUser, useTranslate } from '@hooks/.';
import { StoresBindings } from '@container/.';
import { LayoutStore, PopupStore } from '@stores/.';
import styles from './EditProfile.module.scss';
import UserIcon from '../../assets/icons/user.svg';
import HashtagIcon from '../../assets/icons/hashtag.svg';
import PlayIcon from '../../assets/icons/play.svg';
import ChatIcon from '../../assets/icons/chat.svg';
import ParameterIcon from '../../assets/icons/parameter.svg';
import { observer } from 'mobx-react-lite';
import { Link, MenuButton, EditProfileCategory, EditProfileGeneral, EditProfileStore, shareProfilePortfolio } from '@components/.';
import { EditProfileStatus } from './EditProfileStatus';
import { EditProfileArtworks } from './EditProfileArtworks';
import { EditProfileConfidentiality } from './EditProfileConfidentiality';
import { EditProfileParameter } from './EditProfileParameter';
import { InsertColor, OwardInsert, OwardInsertBlue, OwardInsertBlueOutlined, OwardInsertGreen, OwardInsertYellow } from '@components/Core';
import { hasPortfolioPublic, isProfileCurrentlyPremium, isProfileExposed as isProfileExposed, isProfileFree, isProfileLive, isProfileNoArtworkDisplayed, isProfilePremiumExpired, isProfilePrivate, oniOSWebview, subscriptionNeedPayment } from '@utils/utils';
import { action } from 'mobx';
import { useRouter } from 'next/router';
import { findTranslationOrDefault } from '@oward/common-utils';
import moment from 'moment';

interface EditProfileLeftContainerProps {
  store: EditProfileStore;
}

export const EditProfileLeftContainer: React.FC<EditProfileLeftContainerProps> = observer(props => {
  return (
    <div className={styles.mainContainer}>
      {{
        [EditProfileCategory.NONE]: <EditProfileMenu store={props.store} />,
        [EditProfileCategory.GENERAL]: <EditProfileGeneral store={props.store} />,
        [EditProfileCategory.STATUS]: <EditProfileStatus store={props.store} />,
        [EditProfileCategory.ARTWORKS]: <EditProfileArtworks store={props.store} />,
        [EditProfileCategory.CONFIDENTIALITY]: <EditProfileConfidentiality store={props.store} />,
        [EditProfileCategory.PARAMETER]: <EditProfileParameter store={props.store} />,
      }[props.store.activeCategory]}
    </div>
  )
})

interface EditProfileMenuProps {
  store: EditProfileStore;
}

const EditProfileMenu: React.FC<EditProfileMenuProps> = observer(props => {

  return (
    <React.Fragment>
      <div className={styles.scrollMobileContainer} id='edit-profile-menu-scroll-mobile'></div>
      <div className={styles.contentContainer}>
        <EditProfileMenuHeader store={props.store} />
        <EditProfileMenuCTAs store={props.store} />
        <EditProfileMenuEditInfos store={props.store} />
        <EditProfileMenuState store={props.store} />
      </div>
    </React.Fragment>
  )
})

const EditProfileMenuHeader: React.FC<EditProfileMenuProps> = observer(props => {
  const { t } = useTranslate();

  return (
    <div className={styles.headerContainer}>
      <div className={styles.leftContainer}>
        <Link href='/account/profiles'>
          <a className={styles.goBack}>
            {t('edit_profile.go_back_to_profiles')}
          </a>
        </Link>
        <div className={styles.titleContainer}>
          <p className={styles.title}>
            {props.store.profile?.name}
          </p>
        </div>
      </div>
    </div>
  )
});

const EditProfileMenuCTAs: React.FC<EditProfileMenuProps> = observer(props => {
  const { t, locale } = useTranslate();
  const router = useRouter();

  const addArtworkCTA = action(() => { props.store.activeCategory = EditProfileCategory.ARTWORKS });
  const goPublicCTA = action(() => { props.store.activeCategory = EditProfileCategory.CONFIDENTIALITY });

  let exposeProfileCTA = undefined;
  if (isProfileLive(props.store.profile)) {
    if (isProfileNoArtworkDisplayed(props.store.profile) && !isProfilePrivate(props.store.profile)) {
      exposeProfileCTA = addArtworkCTA;
    }
    else if (!isProfileNoArtworkDisplayed(props.store.profile) && isProfilePrivate(props.store.profile)) {
      exposeProfileCTA = goPublicCTA;
    }
  }

  const NeedPayment = () => {
    return (
      <OwardInsertYellow title={t('edit_profile.subscription.need_payment_title')}>
        <p>
          {t('edit_profile.subscription.need_payment_text')}
          <Link href='/profile-subscription-invoice/[profileId]' as={`/profile-subscription-invoice/${props.store.profile.id}`} passHref>
            <span className={styles.link} >{t('edit_profile.subscription.need_payment_settle')}</span>
          </Link>
        </p>
      </OwardInsertYellow>
    );
  }

  const Expired = () => {
    return (
      <OwardInsertYellow title={t('edit_profile.expired.title', { endDate: moment(props.store.profile.premiumEndDate).locale(locale).format('DD MMM yyyy') })}>
        {
          // On iOS App, all links to subscription stuff must be removed
          !oniOSWebview() &&
          <p>
            <Link href={`/subscriptions/[profileId]`} as={`/subscriptions/${props.store.profile.id}`} passHref>
              <span className={styles.link} >{t('edit_profile.expired.link')}</span>
            </Link>
          </p>
        }
      </OwardInsertYellow>
    );
  }

  const ExposeProfile = () => {
    return (
      <>
        {
          isProfileLive(props.store.profile) ?
            <OwardInsertBlue
              title={t('edit_profile.not_exposed.title')}
              onClick={exposeProfileCTA}
            >
              <ul>
                {
                  isProfileNoArtworkDisplayed(props.store.profile) &&
                  <li>{t('edit_profile.not_exposed.no_artwork')}
                    <span
                      onClick={addArtworkCTA}
                      className={styles.whiteLink}
                    >
                      {t('edit_profile.not_exposed.no_artwork_link')}
                    </span>
                  </li>
                }
                {
                  isProfilePrivate(props.store.profile) &&
                  <li>{t('edit_profile.not_exposed.private')}
                    <span
                      onClick={goPublicCTA}
                      className={styles.whiteLink}
                    >
                      {t('edit_profile.not_exposed.private_link')}
                    </span>
                  </li>
                }
                {
                  !isProfileLive(props.store.profile) &&
                  <li>
                    {t('account.profiles.state') + t('state.' + props.store.profile.state)}
                  </li>
                }
              </ul>
            </OwardInsertBlue>
            :
            <OwardInsertYellow
              title={t('edit_profile.not_live.title', { state: t('state.' + props.store.profile.state) })}
              text={t('edit_profile.not_live.text')}
            />
        }
      </>
    );
  }

  const PublishPortfolio = () => {
    return (
      <>
        {
          isProfileCurrentlyPremium(props.store.profile) ?
            <OwardInsertBlue
              title={t(`edit_profile.portfolio.publish_title`)}
              onClick={exposeProfileCTA}
            >
              <ul>
                {
                  isProfileNoArtworkDisplayed(props.store.profile) &&
                  <li>{t('edit_profile.not_exposed.no_artwork')}
                    <span
                      onClick={addArtworkCTA}
                      className={styles.whiteLink}
                    >
                      {t('edit_profile.not_exposed.no_artwork_link')}
                    </span>
                  </li>
                }
                {
                  isProfilePrivate(props.store.profile) &&
                  <li>{t('edit_profile.not_exposed.private')}
                    <span
                      onClick={goPublicCTA}
                      className={styles.whiteLink}
                    >
                      {t('edit_profile.not_exposed.private_link')}
                    </span>
                  </li>
                }
              </ul>
            </OwardInsertBlue>
            :
            <OwardInsert
              title={t(`edit_profile.portfolio.publish_title`)}
              text={t(`edit_profile.portfolio.publish_text`)}
              color={isProfileNoArtworkDisplayed(props.store.profile) ? InsertColor.blueOutlined : InsertColor.blue}
              onClick={() => {
                router.push(`/[lang]/subscriptions/[profileId]`, `/${locale}/subscriptions/${props.store.profile.id}`);
              }}
            />
        }
      </>
    );
  }

  const SharePortfolio = () => {
    const { profiles } = useProfilesForUser(locale);

    return (
      <OwardInsertBlue
        title={t(`edit_profile.portfolio.share_title`)}
        text={t(`edit_profile.portfolio.share_text`)}
        onClick={() => {
          shareProfilePortfolio(
            props.store.profile.id,
            props.store.profile.name,
            findTranslationOrDefault(props.store.profile.job, locale),
            props.store.profile.path,
            hasPortfolioPublic(props.store.profile),
            profiles && profiles?.findIndex(profile => profile.id === props.store.profile.id) !== -1,
            t,
            locale,
            router
          )
        }}
      />
    );
  }

  const ViewPortfolio = () => {
    return (
      <OwardInsertBlueOutlined
        title={t(`edit_profile.portfolio.${hasPortfolioPublic(props.store.profile) ? 'see' : 'preview'}_title`)}
        text={t(`edit_profile.portfolio.${hasPortfolioPublic(props.store.profile) ? 'see' : 'preview'}_text`)}
        onClick={() => {
          router.push(
            `/[lang]/p/[profilePath]`,
            `/${locale}/p/${props.store.profile?.path}`
          );
        }}
      />
    );
  }

  return (
    <div className={styles.blockContainer}>
      {
        subscriptionNeedPayment(props.store.profile.subscription) &&
        <NeedPayment />
      }
      {
        isProfilePremiumExpired(props.store.profile) &&
        <Expired />
      }
      {
        !isProfileExposed(props.store.profile) &&
        <ExposeProfile />
      }
      {
        hasPortfolioPublic(props.store.profile) ?
          <SharePortfolio />
          :
          <>
            {
              // On iOS App, all links to subscription stuff must be removed
              (isProfileLive(props.store.profile) && !oniOSWebview()) &&
              <PublishPortfolio />
            }
          </>
      }
      <ViewPortfolio />
    </div>
  )
});

const EditProfileMenuEditInfos: React.FC<EditProfileMenuProps> = observer(props => {
  const { t } = useTranslate();

  return (
    <div className={styles.blockContainer}>
      <p className={styles.subtitle}>{t('edit_profile.edit_infos.subtitle')}</p>
      <EditProfileCategoryButton
        category={EditProfileCategory.GENERAL}
        icon={<UserIcon className={styles.svgIcon} />}
        store={props.store}
      />
      <EditProfileCategoryButton
        category={EditProfileCategory.STATUS}
        icon={<HashtagIcon className={styles.svgIcon} />}
        store={props.store}
      />
      <EditProfileCategoryButton
        category={EditProfileCategory.ARTWORKS}
        icon={<PlayIcon className={styles.svgIcon} />}
        store={props.store}
      />
      <EditProfileCategoryButton
        category={EditProfileCategory.CONFIDENTIALITY}
        icon={<ChatIcon className={styles.svgIcon} />}
        store={props.store}
      />
      <EditProfileCategoryButton
        category={EditProfileCategory.PARAMETER}
        icon={<ParameterIcon className={styles.svgIcon} />}
        store={props.store}
      />
    </div>

  )
});

interface EditProfileCategoryButtonProps {
  category: EditProfileCategory;
  icon: ReactNode;
  store: EditProfileStore;
}

const EditProfileCategoryButton: React.FC<EditProfileCategoryButtonProps> = observer(props => {
  const { t, locale } = useTranslate();
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);

  return (
    <MenuButton
      name={t('edit_profile.' + props.category + '.title')}
      icon={props.icon}
      onClick={action(() => {
        props.store.activeCategory = props.category;
        layoutStore.scrollToElement('edit-profile-menu-scroll-mobile', false, 'auto');
      })}
    />
  )
});

const EditProfileMenuState: React.FC<EditProfileMenuProps> = observer(props => {
  const { t } = useTranslate();

  return (
    <div className={styles.blockContainer}>
      <p className={styles.subtitle}>{t('edit_profile.profile_state.subtitle')}</p>
      {
        isProfileExposed(props.store.profile) ?
          <OwardInsertGreen
            title={t('edit_profile.profile_state.profile_exposed.title')}
            text={t('edit_profile.profile_state.profile_exposed.text')}
          />
          :
          <OwardInsertYellow
            title={t('edit_profile.profile_state.profile_not_exposed.title')}
            text={t('edit_profile.profile_state.profile_not_exposed.text')}
          />
      }
      {
        hasPortfolioPublic(props.store.profile) ?
          <OwardInsertGreen
            title={t('edit_profile.profile_state.portfolio_exposed.title')}
            text={t('edit_profile.profile_state.portfolio_exposed.text')}
          />
          :
          <OwardInsertYellow
            title={t('edit_profile.profile_state.portfolio_not_exposed.title')}
            text={t('edit_profile.profile_state.portfolio_not_exposed.text')}
          />
      }
    </div>

  )
});
