import React, { useEffect, useState } from 'react';
import { UserController, ProfileController, ArtworkController, FilmsController, Request, ShortlistController, OnFireController, ReportController, StatsController, MessageController, ProfileType, send, BlockController } from '@oward/openapi';
import { Divider, EntityCount } from '../AdminComponents';

export const DashboardHeader: React.FC = () => {
  const [reportsProfilesUnknownCount, setReportsProfilesUnknownCount] = useState(0);
  const [reportsArtworksUnknownCount, setReportsArtworksUnknownCount] = useState(0);
  const [reportsMessagesUnknownCount, setReportsMessagesUnknownCount] = useState(0);

  useEffect(() => {
    (async function getReportsUnknownCounts() {
      setReportsProfilesUnknownCount(await send(ReportController.countReportsProfile(true)))
      setReportsArtworksUnknownCount(await send(ReportController.countReportsArtwork(true)))
      setReportsMessagesUnknownCount(await send(ReportController.countReportsMessage(true)))
    })();

  }, [])

  return (
    <React.Fragment>
      <nav className='level'>
        <HeaderCount name='Users' fetchCount={UserController.count} />
        <HeaderCount name='Profiles' fetchCount={ProfileController.count} />
        <HeaderCount name='Profiles exposed' fetchCount={ProfileController.countExposed} />
        <HeaderCount name='Public portfolios' fetchCount={ProfileController.countPublicPortfolios} />
        <HeaderCount name='(Pro exposed)' fetchCount={() => { return ProfileController.countExposed(undefined, ProfileType.PRO) }} />
        <HeaderCount name='(Company exposed)' fetchCount={() => { return ProfileController.countExposed(undefined, ProfileType.COMPANY) }} />
      </nav>
      <Divider />
      <nav className='level'>
        <HeaderCount name='Artworks' fetchCount={ArtworkController.count} />
        <HeaderCount name='Artworks exposed' fetchCount={ArtworkController.countExposed} />
        <HeaderCount name='Films' fetchCount={FilmsController.count} />
      </nav>
      <Divider />
      <nav className='level'>
        <HeaderCount name='Number of unique Job' fetchCount={ProfileController.countJobsExposed} />
        <HeaderCount name='Real exposed' fetchCount={() => { return ProfileController.countExposed(147) }} />
        <HeaderCount name='Chef Op exposed' fetchCount={() => { return ProfileController.countExposed(118) }} />
      </nav>
      <Divider />
      <nav className='level'>
        <HeaderCount name='Shortlists' fetchCount={ShortlistController.count} />
        <HeaderCount name='Profiles in Shortlists' fetchCount={ShortlistController.countProfiles} />
      </nav>
      <Divider />
      <nav className='level'>
        <HeaderCount
          name='Reports Profile'
          fetchCount={ReportController.countReportsProfile}
          warningStr={reportsProfilesUnknownCount > 0 && `${reportsProfilesUnknownCount} untreated`}
        />
        <HeaderCount
          name='Reports Artwork'
          fetchCount={ReportController.countReportsArtwork}
          warningStr={reportsArtworksUnknownCount > 0 && `${reportsArtworksUnknownCount} untreated`}
        />
        <HeaderCount
          name='Reports Message'
          fetchCount={ReportController.countReportsMessage}
          warningStr={reportsMessagesUnknownCount > 0 && `${reportsMessagesUnknownCount} untreated`}
        />
        <HeaderCount name='Blocks Profiles' fetchCount={BlockController.countBlockProfilesAdmin} />
        <HeaderCount name='Blocks Artworks' fetchCount={BlockController.countBlockArtworksAdmin} />
      </nav>
      <Divider />
      <nav className='level'>
        <HeaderCount name='Discussions' fetchCount={MessageController.countDiscussions} />
        <HeaderCount name='Messages' fetchCount={MessageController.countMessages} />
      </nav>
      <Divider />
      <nav className='level'>
        <HeaderCount name='On Fires' fetchCount={OnFireController.count} />
        <HeaderCount name='Click Contact (obsolete since 01/27/22)' fetchCount={StatsController.countClickContact} />
      </nav>
    </React.Fragment>
  );
}

interface HeaderCountProps {
  name: string;
  fetchCount: () => Request<number>;
  warningStr?: string;
}
const HeaderCount: React.FC<HeaderCountProps> = props => {
  return (
    <div className='level-item has-text-centered'>
      <div>
        <p className='heading'>{props.name}</p>
        <p className='title'><EntityCount getCountFunction={props.fetchCount} /></p>
        {
          props.warningStr &&
          <p className='tag is-large is-danger'>{props.warningStr}</p>
        }
      </div>
    </div>
  );
}
