import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import styles from './GalleryHeaderShortlist.module.scss';
import BackIcon from '../../assets/icons/back_arrow.svg';
import ShareIcon from '../../assets/icons/paper_plane.svg';
import ModifyIcon from '../../assets/icons/more.svg';
import { useInjection, useTranslate, useUserShortlistsGet } from '@hooks/.';
import { FilterStore, ModalKey, ModalStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { OwardButton, OwardLoader, ToastSucess } from '@components/.';
import { send, Shortlist, ShortlistController, Visibility } from '@oward/openapi';
import { toast } from 'react-toastify';

export const ShortlistHeader = () => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  const { shortlists } = useUserShortlistsGet();
  const [shortlist, setShortlist] = useState<Shortlist>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const isShortlistMine = (): boolean => {
    if (shortlists?.length > 0 && filterStore.filter?.shortlist?.id) {
      return shortlists.filter(shortlist => shortlist.id === Number(filterStore.filter?.shortlist?.id)).length > 0;
    }
    return false;
  };

  const shareShortlist = async () => {
    let url: string = process.env.WEBSITE_URL.concat('/shortlist/', filterStore.filter?.shortlist?.id.toString());
    if (navigator.share) {
      try {
        await navigator.share({
          title: document.title,
          text: t(
            'shortlist.share_msg',
            { name: filterStore.label.shortlist }
          ),
          url: url
        })
      }
      catch (err) {
        // Cancelling 'share' throw an error. We don't really care.
      }
    }
    else {
      var textField = document.createElement('textarea');
      textField.innerText = url;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
      toast.dark(<ToastSucess msg={t('global.toast_share')} />);
    }
  }

  useEffect(() => {
    if (shortlists?.length > 0 && filterStore.filter?.shortlist?.id) {
      // Set loading to false as the loading has been done fetching the shortlists list by SWR
      setLoading(false);
      setShortlist(shortlists.filter(shortlist => shortlist.id === Number(filterStore.filter?.shortlist?.id))[0])
    }
    else if (filterStore.filter?.shortlist?.id) {
      (async function getShortlistInfo() {
        try {
          setLoading(true);
          setShortlist(await send(ShortlistController.getById(filterStore.filter?.shortlist?.id)));
        }
        catch (err) {
          toast.error(t('global.error_retry'))
        }
        finally {
          setLoading(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortlists, filterStore.filter?.shortlist?.id]);

  return (
    <div className={`has-text-centered ${styles.mainContainer}`}>
      <h1 className={`is-family-secondary has-text-weight-semibold ${styles.title}`}>
        {t('gallery.header.shortlist.title', { name: shortlist ? shortlist.name : filterStore.label.shortlist })}
      </h1>
      <div >
        <OwardLoader loading={loading} positionStatic />
        {
          shortlist?.description &&
          <p className={styles.shortlistDescription}>{shortlist.description}</p>
        }
        {
          shortlist?.visibility === Visibility.PRIVATE &&
          <p className={styles.shortlistPrivate}>{t('gallery.header.shortlist.private')}</p>
        }
      </div>
      <div className={`${styles.buttonsContainer}`}>
        <OwardButton
          svgIcon={<BackIcon />}
          white
          onClick={() => { router.push(process.env.WEBSITE_URL); }}
          tooltipText={t('gallery.header.shortlist.back-to-gallery-tooltip')}
        />
        <OwardButton
          svgIcon={<ShareIcon />}
          white
          onClick={shareShortlist}
          tooltipText={t('gallery.header.shortlist.share-tooltip')}
        />
        {
          isShortlistMine() &&
          <OwardButton
            svgIcon={<ModifyIcon />}
            white
            onClick={() => {
              modalStore.setModifyShortlistInfos({
                shortlistId: shortlist?.id,
                shortlistName: shortlist?.name,
                shortlistPrivate: shortlist?.visibility === Visibility.PRIVATE,
                shortlistDescription: shortlist.description
              });
              modalStore.openModalNewStack(router, ModalKey.MODIFY_SHORTLIST, Number(filterStore.filter?.shortlist?.id))
            }}
            tooltipText={t('gallery.header.shortlist.modify-tooltip')}
          />
        }
      </div>
    </div>
  )
}
