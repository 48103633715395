import React from 'react';
import stylesCommon from '../ModalCommon.module.scss';
import { StoresBindings } from '@container/.';
import { useInjection, useNumberOfDiscussionsUnread, useProfilesForUser, useTranslate } from '@hooks/.';
import { ModalKey, ModalStore } from '@stores/modal-store';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { OwardLinkButton, OwardLoader } from '@components/Core';
import { Modal, NeedConnectionModal } from '..';
import { UserStore } from '@stores/user-store';
import { Link } from '@components/I18n';
import { LayoutStore } from '@stores/layout-store';
import { ModalProfileRectangle } from '../ModalCommon';

export const GoToMessagesModal: React.FC = observer(() => {
  const { t, locale } = useTranslate();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const { profiles, isLoading } = useProfilesForUser(locale);

  return (
    <React.Fragment>
      {
        userStore.isLogged ?
          <Modal
            modalKey={ModalKey.GO_TO_MESSAGES}
          >
            {
              isLoading ?
                <OwardLoader loading={true} positionStatic />
                :
                <React.Fragment>
                  {
                    profiles?.length === 0 ?
                      <GoToMessagesModalNoProfile />
                      :
                      <GoToMessagesModalProfiles />
                  }
                </React.Fragment>
            }
          </Modal>
          :
          <Modal modalKey={ModalKey.GO_TO_MESSAGES}>
            <NeedConnectionModal headerMessage={t('modal.go_to_messages.need_connection')} />
          </Modal>
      }
    </React.Fragment>
  )
});

const GoToMessagesModalProfiles: React.FC = observer(() => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);
  const { profiles } = useProfilesForUser(locale);
  const { countOfUnreadDiscussionsByProfil } = useNumberOfDiscussionsUnread();

  return (
    <div className={stylesCommon.mainContainer}>
      <p className={stylesCommon.mediumModalHeader}>
        {t('modal.go_to_messages.choose_profile', { contactedName: modalStore.contactInfos?.profileName })}
      </p>
      {
        profiles?.map((profile, i) =>
          <ModalProfileRectangle
            profile={profile}
            onClick={async () => {
              router.push(
                `/[lang]/messages/[profilePath]`,
                `/${locale}/messages/${profile?.path}`
              );
              layoutStore.openMessagesDiscussionContainer(false);
            }}
            countOfUnreadDiscussions={countOfUnreadDiscussionsByProfil?.find(e => e.profileId === profile.id)?.count}
            key={i}
          />
        )
      }
    </div>
  );
});

const GoToMessagesModalNoProfile: React.FC = () => {
  const { t } = useTranslate();

  return (
    <div className={stylesCommon.mainContainer}>
      <p className={stylesCommon.mediumModalHeader}>
        {t('modal.go_to_messages.need_profile')}
      </p>
      <Link href='/?new_profile=1' passHref>
        <OwardLinkButton
          name={t('login.verify_email.button_create_profile')}
          fullWidth
        />
      </Link>

    </div>
  );
}
