import classNames from 'classnames';
import { useInjection, useUserShortlistsGet, useTranslate } from '@hooks/.';
import styles from './SidebarShortlist.module.scss';
import { StoresBindings } from '@container/.';
import { FilterStore, UserStore, ModalStore, ModalKey } from '@stores/.';
import { Shortlist, Visibility } from '@oward/openapi';
import { FilterType } from '@oward/common-enums';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useRouter } from 'next/router';
import { OwardLoader } from '@components/Core';


export const SidebarShortlist: React.FC = observer(() => {
  const { t } = useTranslate();
  const router = useRouter();
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const { shortlists, isLoading, isError } = useUserShortlistsGet();

  const isActive = (shortlistId: number): boolean => {
    return Number(filterStore.filter?.shortlist?.id) === shortlistId;
  };

  return (
    <div className={classNames(styles.mainContainer)}>
      <OwardLoader loading={isLoading && userStore.isLogged} positionAbsolute />
      {
        shortlists === undefined || shortlists?.length < 1 ?
          <p className={classNames(styles.noShortlistText)}>{t('shortlist.no_shortlist')}</p>
          :
          shortlists?.map((shortlist: Shortlist, i) => {
            return (
              <React.Fragment key={i}>
                <hr className={styles.hr} />
                <div className={styles.lineContainer}>

                  <div
                    className={classNames(
                      styles.shortlistNameContainer,
                      { [styles.shortlistNameContainerActive]: isActive(shortlist.id) }
                    )}
                    onClick={() => {
                      if (isActive(shortlist.id)) {
                        filterStore.setFilter(router, FilterType.SHORTLIST, undefined, undefined);
                      }
                      else {
                        filterStore.resetFilters();
                        filterStore.setFilter(router, FilterType.SHORTLIST, { id: shortlist.id }, shortlist.name);
                      }
                    }}
                  >

                    <span>
                      {shortlist.name}
                      {
                        shortlist.visibility === Visibility.PRIVATE &&
                        <span className={classNames('icon')}>
                          <div
                            data-tooltip={t('shortlist.private')}
                            className={classNames('has-tooltip-top', styles.privateIcon)}
                          >
                            <i className={classNames('far fa-eye-slash')}></i>
                          </div>
                        </span>
                      }
                    </span>

                  </div>
                  <div className={styles.modifyContainer}>
                    <div
                      className={styles.modifyIconContainer}
                      onClick={() => {
                        modalStore.setModifyShortlistInfos({
                          shortlistId: shortlist.id,
                          shortlistName: shortlist.name,
                          shortlistPrivate: shortlist.visibility === Visibility.PRIVATE,
                          shortlistDescription: shortlist.description
                        });
                        modalStore.openModalNewStack(router, ModalKey.MODIFY_SHORTLIST, shortlist.id)
                      }}
                    >
                      <span className={classNames('icon')}>
                        <div className={classNames(styles.modifyIcon)}>
                          <i className={classNames('fas fa-ellipsis-h')}></i>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })
      }
    </div>
  );
});
