interface SubtitlePageProps {
  subtitle: string;
}

export const SubtitlePage: React.FC<SubtitlePageProps> = props => {
  return (
    <p className='subtitle is-size-4-desktop is-size-5-tablet is-size-5-mobile has-text-centered pt-2'>
      {props.subtitle}
    </p>
  )
}
