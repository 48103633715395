import React, { useState, useEffect } from 'react';
import styles from './MessagesSidebar.module.scss';
import { useDiscussionsForProfile, useInjection, useProfilesForUser, useTranslate } from '@hooks/.';
import { LayoutStore, MessageStore, ModalKey, ModalStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { Discussion, MessageController, Profile } from '@oward/openapi';
import { observer } from 'mobx-react-lite';
import { hasPortfolioPublic, remSizeToPixelNumber } from '@utils/utils';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import moment from 'moment';
import { SWR_DISCUSSIONS_FOR_PROFILE, SWR_DISCUSSIONS_UNREAD_COUNT_FOR_USER } from '@utils/constants';
import { mutate } from 'swr';
import { OwardButton, OwardLoader } from '..';
import { findTranslationOrDefault } from '@oward/common-utils';
import { DiscussionType, MessageType } from '@oward/common-enums';

export const MessagesSidebar: React.FC = observer(() => {
  const { t } = useTranslate();
  const router = useRouter();
  const messageStore = useInjection<MessageStore>(StoresBindings.MESSAGE);
  const { discussions, isLoading, isError } = useDiscussionsForProfile(messageStore.currentProfile?.id);

  return (
    <div className={styles.sidebarContainer}>
      <MessagesSidebarCurrentProfile />
      <NewGroupDiscussionButton />
      <div className={styles.discussionLinesContainer}>
        {

          (discussions && discussions.length > 0) ?
            discussions?.map((discussion, i) =>
              <DiscussionLine
                discussion={discussion}
                senderProfile={messageStore.currentProfile}
                key={i}
              />
            )
            :
            <div className={styles.noDiscussionContainer}>
              {
                isError ?
                  <p>{t('messages.error.fetching_discussion')}</p>
                  :
                  isLoading ?
                    <OwardLoader loading={isLoading} positionAbsolute />
                    :
                    <React.Fragment>
                      <p>
                        {t('messages.no_discussion1', { name: messageStore.currentProfile?.name })}
                        <span className='icon'>
                          <i className={'fas fa-envelope'}></i>
                        </span>
                        {t('messages.no_discussion2')}
                      </p>
                      <div className={styles.buttonContainer}>
                        <OwardButton
                          name={t('shortlist.go_to_gallery')}
                          onClick={() => { router.push(process.env.WEBSITE_URL); }}
                          outlined
                        />
                      </div>
                    </React.Fragment>
              }
            </div>
        }
      </div>
    </div>
  );
});

const MessagesSidebarCurrentProfile: React.FC = observer(() => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const messageStore = useInjection<MessageStore>(StoresBindings.MESSAGE);
  const { profiles } = useProfilesForUser(locale);

  return (
    <div
      className={classNames(
        styles.currentProfileContainer,
        profiles?.length > 1 && styles.currentProfileContainerClickable,
        'has-tooltip-bottom', 'has-tooltip-arrow'
      )}
      onClick={() => {
        if (profiles?.length > 1) {
          modalStore.openModalNewStack(router, ModalKey.GO_TO_MESSAGES, '0');
        }
      }}
      data-tooltip={profiles?.length > 1 ? t('messages.change_profile') : null}
    >
      {
        messageStore.currentProfile?.avatarUrl &&
        <div className={styles.avatarContainer}>
          <LazyLoadImage
            src={messageStore.currentProfile?.avatarUrl}
            width={remSizeToPixelNumber(styles.avatarSize)}
            height={remSizeToPixelNumber(styles.avatarSize)}
            className={styles.avatar}
            alt={t('alt.avatar_just_name', { name: messageStore.currentProfile?.name })}
            effect='blur'
          />
        </div>
      }
      <p className={styles.text}>{t('messages.current_profile', { name: messageStore.currentProfile?.name })}</p>
    </div>
  );
});

const NewGroupDiscussionButton: React.FC = props => {
  const { t } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  return (
    <div className={styles.newGroupDiscussionContainer}>
      <OwardButton
        iconName='fa-solid fa-plus'
        name={t('messages.create_group_discussion')}
        outlined
        onClick={() => {
          modalStore.openModalNewStack(router, ModalKey.MESSAGES_CREATE_GROUP_DISCUSSION, '0');
        }}
      />
    </div>
  );
}

interface DiscussionLineProps {
  senderProfile: Profile;
  discussion: Discussion;
}

export const DiscussionLine: React.FC<DiscussionLineProps> = observer(props => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);
  const [isActive, setIsActive] = useState(
    parseInt(router.query['d'] as string) === props.discussion.id
  );

  useEffect(() => {
    layoutStore.scrollToElement(
      `messages-sidebar-discussion-line-${props.senderProfile.id}-${parseInt(router.query['d'] as string)}`,
      true,
      'smooth'
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.discussion.id]);

  useEffect(() => {
    setIsActive(parseInt(router.query['d'] as string) === props.discussion.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query['d'], props.discussion]);

  const messageDateToStr = (date: string) => {
    var REFERENCE = moment();
    var TODAY = REFERENCE.clone().startOf('day');
    var A_WEEK_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');

    if (moment(date).isSame(TODAY, 'd')) {
      return moment(date).format('HH[h]mm')
    }
    else if (moment(date).isAfter(A_WEEK_OLD)) {
      return moment(date).locale(locale).format('ddd');
    }
    else {
      return moment(date).locale(locale).format('D[\u00a0]MMM');
    }

  }

  const hasUnreadMessage = (): boolean => {
    let messageLastSeen = props.discussion.messageLastSeens?.find(
      messageLastSeen =>
        messageLastSeen.messageId === props.discussion?.messages[0].id &&
        messageLastSeen.profileId === props.senderProfile.id
    );
    if (
      props.discussion?.messages?.length < 1 ||
      messageLastSeen ||
      props.discussion?.messages[0]?.senderProfileId === props.senderProfile.id
    ) {
      return false;
    }
    return true;
  }

  const goToDiscussion = async () => {
    router.replace(
      `/[lang]/messages/[profilePath]?d=${props.discussion.id}`,
      `/${locale}/messages/${props.senderProfile.path}?d=${props.discussion.id}`,
      { shallow: true }
    );
    layoutStore.openMessagesDiscussionContainer(true);
    if (hasUnreadMessage()) {
      await MessageController.updateLastMessageSeen({
        messageId: props.discussion?.messages[0]?.id,
        discussionId: props.discussion.id,
        profileId: props.senderProfile.id
      });
      mutate(SWR_DISCUSSIONS_UNREAD_COUNT_FOR_USER);
      mutate([SWR_DISCUSSIONS_FOR_PROFILE, props.senderProfile?.id]);
    }
  }

  const lastPictureSent = () => {
    if (props.discussion.messages.find(message => message?.senderProfileId === props?.senderProfile?.id)) {
      return t('messages.sidebar.last_message_picture_send');
    } else {
      return t('messages.sidebar.last_message_picture_receveid', { name: props.discussion?.members[0].profile?.name });
    }
  }

  return (
    <div
      className={classNames(
        styles.discussionLineContainer,
        isActive && styles.discussionLineActive,
        hasUnreadMessage() && styles.discussionLineUnread
      )}
      onClick={goToDiscussion}
      id={`messages-sidebar-discussion-line-${props.senderProfile.id}-${props.discussion.id}`}
    >
      <div className={`${styles.discussionLineContentContainer}`}>
        {
          props.discussion.type === DiscussionType.ONETOONE ?
            props.discussion.members[0].profile?.avatarUrl &&
            <div className={styles.avatarContainer}>
              <LazyLoadImage
                src={props.discussion.members[0].profile?.avatarUrl}
                width={remSizeToPixelNumber(styles.avatarSize)}
                height={remSizeToPixelNumber(styles.avatarSize)}
                className={classNames(
                  styles.avatar,
                  hasPortfolioPublic(props.discussion.members[0].profile) && styles.goldenBorder
                )}
                alt={t('alt.avatar_just_name', { name: props.discussion.members[0].profile?.name })}
                effect='blur'
              />
            </div>
            :
            <p className={styles.groupIcon}>
              <span className={`icon`}>
                <i className='fas fa-users'></i>
              </span>
            </p>
        }
        <div className={styles.rightContainer}>
          <p className={styles.name}>
            {
              props.discussion.type === DiscussionType.ONETOONE ?
                props.discussion?.members[0].profile?.name
                :
                props.discussion.name
            }
          </p>
          {
            props.discussion?.messages[0] &&
            <div className={styles.messageAndTime}>
              <p className={styles.message}>
                {
                  props.discussion?.messages[0]?.url && props.discussion?.messages[0]?.type === MessageType.PICTURE
                    ?
                    lastPictureSent()
                    :
                    props.discussion?.messages[0]?.text
                }
              </p>
              <p className={styles.time}>
                {messageDateToStr(props.discussion?.messages[0]?.creationDate)}
              </p>
            </div>
          }

        </div>
      </div>
    </div>
  );
});
