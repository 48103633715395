import { OwardFormTextArea, ToastError, ToastSucess } from '@components/Core';
import { StoresBindings } from '@container/.';
import { useInjection, useTranslate } from '@hooks/.';
import { ReportController } from '@oward/openapi';
import { ModalKey, ModalStore } from '@stores/modal-store';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React from 'react';
import { Modal, ModalConfirmOrCancelButtons, ModalTitle } from '..';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

export const ReportMessageModal: React.FC = observer(() => {
  const { t } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  const schema = (t: any) => {
    return (
      Yup.object().shape({
        message: Yup.string().required(t('modal.report_message.need_msg')),
      }))
  };

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      try {
        await ReportController.reportMessage(values.message, parseInt(router.query[ModalKey.REPORT_MESSAGE] as string));
        toast.dark(<ToastSucess msg={t('modal.report_message.validation_toast')} />);
        formik.resetForm();
        modalStore.unpopModal(router);
      } catch (err) {
        toast.dark(<ToastError msg={t('global.error_with_code', { code: err })} />);
      }
    }
  })

  return (
    <React.Fragment>
      <Modal
        modalKey={ModalKey.REPORT_MESSAGE}
        header={<ModalTitle name={t('modal.report_message.title')} />}
        footer={
          <ModalConfirmOrCancelButtons
            confirmTxt={t('modal.report_message.validation_button')}
            validation={() => {
              formik.handleSubmit();
            }}
          />
        }
      >
        <p>{t('modal.report_message.description')}</p>
        <OwardFormTextArea
          id={'message'}
          placeholder={t('modal.report_message.input_placeholder')}
          formik={formik}
        />
      </Modal>
    </React.Fragment>
  )
});
