import React, { useState } from 'react';
import { Divider } from '@components/admin';
import { EditPromoCode } from './EditPromoCode';
import { PromoCodeList } from './PromoCodeList';

export const PromoCodesComponent: React.FC = () => {
  const [reloadList, setReloadList] = useState(false);

  return (
    <React.Fragment>
      <EditPromoCode item={undefined} notify={() => { setReloadList(!reloadList) }} />
      <Divider />
      <PromoCodeList reloadList={reloadList} />
      <Divider />
      <PromoCodeList reloadList={reloadList} referralCodes />
    </React.Fragment>
  );
}
