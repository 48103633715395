import React from 'react';
import { DiscussionStatistics } from './DiscussionStatistics';
import { Divider } from '@components/admin';
import { DiscussionList } from './DiscussionList';

export const DiscussionComponent: React.FC = () => {
  return (
    <React.Fragment>
      <DiscussionStatistics />
      <Divider />
      <DiscussionList />
    </React.Fragment>
  );
}
