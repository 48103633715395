import styles from '@assets/sass/variable-export.module.scss';
import { makeAutoObservable } from 'mobx';
import { injectable } from 'inversify';
import { onTouchDevice, remSizeToPixelNumber, checkVisible } from '@utils/.';
import smoothscroll from 'smoothscroll-polyfill';

@injectable()
export class LayoutStore {
  constructor() {
    makeAutoObservable(this);
  }

  /* SIDEBAR */
  sidebarOpened: boolean = false;
  // If you want to have the sidebar opened at page load, initialize 'sidebarFirstInterraction' at true
  // AND uncomment the UseEffect in GallerySideBar.tsx
  sidebarFirstInterraction: boolean = false; // Used for opening the sidebar at page load on desktop

  openSidebar = (open: boolean) => {
    let sidebar: HTMLElement = document.getElementById('sidebar');
    let sidebarMobileClose: HTMLElement = document.getElementById('sidebar-mobile-close');
    let galleryMainRightContainer: HTMLElement = document.getElementById('gallery-main-right-container');

    if (
      (this.sidebarFirstInterraction === false || onTouchDevice()) &&
      (sidebar !== null && galleryMainRightContainer !== null)
    ) {
      // On desktop, we want to add the transition after the first interraction,
      // as the first interraction is the initial opening of the sidebar at page load
      galleryMainRightContainer.style['transition'] = `margin-left ${styles.sidebarTransitionParams}`;
      sidebar.style['transition'] = `left ${styles.sidebarTransitionParams}`;
    }

    if (
      sidebar !== null &&
      galleryMainRightContainer !== null &&
      sidebarMobileClose !== null
    ) {

      if (open) {
        if (onTouchDevice()) {
          sidebar.style.width = styles.sideBarMobileWidth;
          sidebarMobileClose.style.display = 'block';
        }
        else {
          galleryMainRightContainer.style['margin-left'] = styles.sideBarDesktopWidth;
          sidebar.style.width = styles.sideBarDesktopWidth;
        }
        sidebar.style.left = '0';
        this.displayNavbar(true);
        this.displayBottomBar(false);
      }
      else {
        if (onTouchDevice()) {
          sidebar.style.left = `-${styles.sideBarMobileWidth}`;
          sidebar.style.width = styles.sideBarMobileWidth;
        } else {
          sidebar.style.left = `-${styles.sideBarDesktopWidth}`;
          sidebar.style.width = styles.sideBarDesktopWidth;
        }
        sidebarMobileClose.style.display = 'none';
        galleryMainRightContainer.style['margin-left'] = '0';
        this.displayBottomBar(true);
      }
      this.sidebarOpened = open;
      this.sidebarFirstInterraction = false;
    }
  }

  // Open the sidebar if it's closed, or flash it to draw attention to it
  openOrFlashSidebar = () => {
    if (this.sidebarOpened) {
      var element = document.getElementById("gallerySideBarFlashContainer");
      element.classList.remove(styles.flashAnimation);
      void element.offsetWidth; // See https://css-tricks.com/restart-css-animation/
      element.classList.add(styles.flashAnimation);
    }
    else {
      this.openSidebar(true);
    }
  };

  openMessagesDiscussionContainer = (open: boolean) => {
    let discussionContainer: HTMLElement = document.getElementById('messages-discussion-container');

    if (discussionContainer !== null) {
      if (open) {
        discussionContainer.style.left = '0';
      }
      else {
        discussionContainer.style.left = '100vw';
      }
    }
  }

  /* NAVBAR */
  displayNavbar = (display: boolean) => {
    let navbar: HTMLElement = document.getElementById('navbar-mobile');

    if (navbar !== null) {
      if (display) {
        navbar.style.top = '0';
      }
      else {
        navbar.style.top = 'calc(-' + styles.navbarHeight + ' - 0.1rem)';
      }
    }
  }

  /* BOTTOM_BAR */
  displayBottomBar = (display: boolean) => {
    let galleryBottomBar: HTMLElement = document.getElementById('gallery-bottom-bar');

    if (galleryBottomBar !== null) {
      if (display) {
        galleryBottomBar.style.bottom = '0rem';
      }
      else {
        galleryBottomBar.style.bottom = 'calc(-' + styles.navbarHeight + ' - 0.1rem)';;
      }
    }
  }

  welcomePanelOpened: boolean = onTouchDevice() ? false : true;

  openWelcomPanel = (opened: boolean) => {
    this.welcomePanelOpened = opened;
  }

  /* SCROLL ACTIONS */
  scrollToGalleryTop = () => {
    let galleryHeader: HTMLElement = document.getElementById('gallery-header-container');

    if (galleryHeader) {
      galleryHeader.scrollIntoView();
      // Down scroll the navbar height minus a small offset
      window.scrollBy(0, -remSizeToPixelNumber(styles.navbarHeight) + 1);
    }
  }

  scrollToActiveFilters = () => {
    let galleryScrollToFilterDiv: HTMLElement = document.getElementById('gallery-header-scroll-to-filters');

    if (galleryScrollToFilterDiv && !checkVisible(galleryScrollToFilterDiv)) {
      galleryScrollToFilterDiv.scrollIntoView();
      // Down scroll the navbar height minus a small offset
      window.scrollBy(0, -remSizeToPixelNumber(styles.navbarHeight) + 1);
    }
  }

  scrollToElement = (elementId: string, checkIfVisible = true, behavior = 'smooth' as ScrollBehavior) => {
    let element: HTMLElement = document.getElementById(elementId);
    if (element && (!checkIfVisible || !checkVisible(element))) {
      // Polyfill for smooth scrolling (see https://github.com/iamdustan/smoothscroll)
      smoothscroll.polyfill();
      element.scrollIntoView({ behavior: behavior });
    }
  }

  /* PAGE LOADER */
  pageLoading: boolean = false;

  setPageLoading = (isLoading: boolean) => {
    this.pageLoading = isLoading;
  }

  /*
  * DEACTIVTATED (Card ratio - Zoom)
  */

  /* cardRatio: number = 1;


  scaleCards = (ratio: number, withCookie?: boolean) => {
    let cardsGrid: HTMLElement = document.getElementById('cards-grid');
    let cardMainContainers = document.getElementsByClassName('card-main-container');

    // We need to adjust the grid layout when scaling the cards
    if (cardsGrid !== null && cardMainContainers !== null) {
      this.cardRatio = ratio;
      cardsGrid.style.gridTemplateColumns = 'repeat(auto-fit, calc(' + styles.cardWidth + '*' + ratio + '))';
      let gridGap: string = 'calc(' + styles.cardGap + '*' + ratio + ')';
      cardsGrid.style.gap = gridGap;
      cardsGrid.style.gridGap = gridGap;

      if (ratio !== 1) {
        cardsGrid.style.gridAutoRows = 'calc(' + styles.cardHeight + '*' + ratio + ')';
      }
      else {
        // On mobile (always ratio 1) the row height is not fixed
        cardsGrid.style.gridAutoRows = null;

        if (onCardFullWidthDevice()) {
          cardsGrid.style.gap = styles.cardGapFullWidth;
          cardsGrid.style.gridGap = styles.cardGapFullWidth;
        }
      }

      for (let i = 0; i < cardMainContainers.length; i++) {
        let cardMainContainer = cardMainContainers[i];
        if (cardMainContainer instanceof HTMLElement) {
          cardMainContainer.style.transform = 'scale(' + ratio + ')';
        }
      }
    }

    if (withCookie) {
      var expires = new Date(Date.now() + ms('1 year')).toUTCString();
      document.cookie = `${COOKIE_NAME_ZOOM}=${JSON.stringify(ratio)};expires=${expires};path=/`;
    }
  }*/
}
