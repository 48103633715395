import React, { useContext, useState } from "react";
import { useFormik } from 'formik';
import { observer } from "mobx-react-lite";
import { JobCategory, State, JobsController } from "@oward/openapi";
import { locales } from '@locales/.';
import { Section, Divider, SubmitOrCancel, NavigationContext } from '@components/admin';
import { flattenFromGrouped, groupByLocale, yupTranslationsShape } from '../utils';
import { OwardFormInput, Heading, OwardFormSelect } from '@components/Core';
import { useTranslate } from '@hooks/.';
import { EditProps } from '../types';
import * as Yup from 'yup';
import { stateToEmoji } from '@oward/common-utils';

const schema = (t: any) => {
  return (
    Yup.object().shape({
      name: Yup.string().required(),
      translations: yupTranslationsShape,
    }))
};

export const EditJobCategory = observer((props: EditProps<JobCategory>) => {
  const { t, locale } = useTranslate();
  const [error, setError] = useState(false);
  const navigation = useContext(NavigationContext);

  const formik = useFormik({
    initialValues: {
      name: props.item.name,
      state: props.item.state,
      translations: groupByLocale(props.item.translations)
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      props.item.name = values.name;
      props.item.state = values.state;
      props.item.translations = flattenFromGrouped(values.translations);
      try {
        await JobsController.saveOneCategory(props.item);
        if (props.notify) { props.notify(props.item) };
        setError(false);
        navigation.unpop();
      } catch {
        setError(true);
      }
    }
  });

  return (
    <React.Fragment>
      <Section
        title='Edit'
        subtitle='Change your entity properties'>
        <form className='box' onSubmit={formik.handleSubmit} >
          <Heading>General</Heading>
          <OwardFormInput
            id='name'
            label='Name *'
            placeholder='Actorat'
            formik={formik}
          />
          <OwardFormSelect
            id='state'
            label='State *'
            options={Object.values(State).map((state) => {
              return ({ value: state, label: state.concat(' ', stateToEmoji(state)) });
            })}
            formik={formik}
          />
          <p className='has-text-danger'>ALL TRANSLATIONS ARE REQUIRED AND <span className='has-text-weight-bold'>IMPORTANT</span></p>
          {
            locales.map(locale =>
              <React.Fragment key={locale}>
                <OwardFormInput
                  id={`translations.${locale}.name`}
                  label={`Name ${locale}`}
                  icon='fas fa-signature'
                  value={formik.values.translations[locale]?.name}
                  error={formik.errors.translations && formik.errors.translations[locale]?.name}
                  formik={formik}
                />
              </React.Fragment>
            )
          }
          <Divider />
          <SubmitOrCancel error={error} noCancel={props.noCancel} />
        </form >
      </Section >
      <ul>
        <dl>List of all jobs for this category:</dl>
        {
          props.item?.jobs?.map((job, i) =>
            <li key={i}>- {`${job.name} (${job.profileType}) ${stateToEmoji(job.state)}`}</li>
          )
        }
      </ul>
    </React.Fragment>
  )
});
