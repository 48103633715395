import React from 'react';

import { OnFireStatistics } from './OnFireStatistics';
import { Divider } from '@components/admin';
import { OnFireList } from './OnFireList';

export const OnFireComponent: React.FC = () => {
  return (
    <React.Fragment>
      <OnFireStatistics />
      <Divider />
      <OnFireList />
    </React.Fragment>
  );
}
