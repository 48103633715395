import React, { useState, useEffect, useContext } from 'react';
import { send, Festival, FestivalController, Artwork } from '@oward/openapi';
import { PaginatedList, PaginatedListKey, Section, NavigationContext } from '@components/admin';
import { EditFestival } from './EditFestival';
import { findTranslation, findTranslationOrDefault, strToPath, stateToEmoji } from '@oward/common-utils';
import { useTranslate } from '@hooks/.';
import { OwardButton } from '@components/Core';

export const FestivalList: React.FC = () => {

  const { locale } = useTranslate();
  const [items, setItems] = useState([] as Festival[]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();
  const store = useContext(NavigationContext);

  useEffect(() => {
    loadItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadItems = async () => {
    try {
      setLoading(true);
      setError(false);
      setItems(await send(FestivalController.findAdmin(locale)))
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const keys: PaginatedListKey<Festival>[] = [
    {
      label: 'Id',
      value: item => item.id
    },
    {
      label: 'Name',
      value: item => item.name.concat(' ', stateToEmoji(item.state))
    },
    {
      label: 'Need Check',
      value: item => item.needCheck ? 'YES' : ''
    },
    {
      label: 'Name FR',
      value: item => findTranslation(item, 'fr')
    },
    {
      label: 'City',
      value: item => findTranslationOrDefault(item.city, locale)
    },
    {
      label: 'Number of Live Artworks',
      value: item => getNumberOfLiveArtworks(item)
    }
  ];

  const getNumberOfLiveArtworks = (item: Festival): number => {
    let artworks: Artwork[] = new Array();
    item.selections?.map(selection => { artworks = artworks.concat(selection?.film?.artworks) });
    return [...new Set(artworks?.map(artwork => artwork.id))]?.length ?? 0;
  }

  const filterItemStrings = (item: Festival) => [
    item.id.toString(),
    strToPath(item.name),
    strToPath(findTranslationOrDefault(item.city, locale))
  ];

  const fetchOne = (item: Festival) => send(FestivalController.findOne(item.id, locale));

  return (
    <React.Fragment>
      <Section
        title='List of Festivals'
        subtitle='The list of all festivals in the database'
        childrenFullWidth
      >
        <PaginatedList
          keys={keys}
          items={items}
          filterItemStrings={filterItemStrings}
          size={100}
          editComponent={EditFestival}
          fetchOne={fetchOne}
          reloadList={loadItems}
          loading={loading}
          error={error}
        />
      </Section>
      <div style={{ height: '2rem' }} />
      <Section
        title='Create new Festival'
        subtitle='Create it WHEN PRESSING THIS BUTTON. Cannot be undone, please fill all needed infos in next screen'
      >
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingBottom: '3rem' }}>
          <OwardButton
            name='Create Festival '
            onClick={async () => {
              let festival: Festival = await send(FestivalController.createOne());
              loadItems();
              store.pop({
                label: 'Create Festival',
                element: <EditFestival item={festival} notify={loadItems} noCancel />
              });
            }}
          />
          <p className='has-text-danger'>
            Newly created festival will be in BLOCKED State.
          </p>
        </div>
      </Section>
    </React.Fragment>
  );
}
