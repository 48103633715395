import styles from '@assets/sass/variable-export.module.scss'
import { Location, GeocodeLocation, State, Visibility, Film, FestivalAward, FestivalAwardCategory, Festival, User, ValidationState, Profile, FilterController, send, ProfileSubscription, StripePaymentMethodInfos } from '@oward/openapi';
import { findTranslationOrDefault, PREMIUM_LEVEL, StripeSubscriptionStatus } from '@oward/common-utils';
import moment from 'moment';
import { ARTWORK_COVER_PLACEHOLDER_URL } from './constants';
import { FilterI } from '@oward/openapi';
import { FilterLabelI } from '@oward/openapi';
import { FilterType } from '@oward/common-enums';

export const remSizeToPixelNumber = (remStr: string): number => {
  try {
    remStr = remStr.replace('rem', '');
    let rem: number = parseFloat(remStr);
    let bodySize: number = pixelSizeToPixelNumber(styles.bodySize);;
    if (isNaN(rem) || isNaN(bodySize)) {
      return 0;
    }
    return Math.round(rem * bodySize);
  }
  catch {
    return
  }
}

export const pixelSizeToPixelNumber = (pixelStr: string): number => {
  try {
    pixelStr = pixelStr.replace('px', '');
    let pixel: number = parseInt(pixelStr);
    if (isNaN(pixel)) {
      return 0;
    }
    return pixel;
  }
  catch {
    return 0;
  }
}

const isWidthLowerThan = (widthBreakpoint: number): boolean => {
  if (typeof window === `undefined`) {
    return false;
  }

  let width: number = (window.innerWidth > 0) ? window.innerWidth : screen.width;
  return width <= widthBreakpoint;
}

export const checkVisible = (elm: HTMLElement): boolean => {
  if (!elm) {
    return false;
  }
  var rect = elm.getBoundingClientRect();
  var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
  return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
}

// Return yes if we're on a touch device (mobile or tablet, with Bulma sizes)
export const onTouchDevice = (): boolean => {
  return isWidthLowerThan(pixelSizeToPixelNumber(styles.desktopSize));
}

export const oniOSWebview = () => {
  // See https://stackoverflow.com/questions/37591279/detect-if-user-is-using-webview-for-android-ios-or-a-regular-browser
  if (typeof window === `undefined`) {
    return false;
  }

  var userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);

  if (ios) {
    if (safari) {
      // We are in iOS Browser
      return false;
    } else if (!safari) {
      return true;
    };
  } else {
    // We are not on iOS
    return false;
  };
}

// Return yes if we're device where cards take the full width of the screen
export const onCardFullWidthDevice = (): boolean => {
  return isWidthLowerThan(remSizeToPixelNumber(styles.cardFullWidthBreakpoint));
}

export const onPortfolioPage = (pathname: string): boolean => {
  return pathname.match('\/\\[lang\\]\/portfolio.*')?.length > 0;
};

export const onGalleryPage = (pathname: string): boolean => {
  return (pathname === '/[lang]/' || pathname === '/[lang]');
};

export const goToHomePageAndReload = () => {
  window.location.assign(process.env.WEBSITE_URL || 'https://www.oward.co');
}

// Unbiaised shuffle algorithm : Fisher-Yates Shuffle
// See : https://bost.ocks.org/mike/shuffle/
export const shuffleArray = (array: any[]): any[] => {
  let currentIndex: number = array.length;
  let temporaryValue: any, randomIndex: any;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export const capitalizeFirst = (s: string) => {
  if (typeof s !== 'string') { return ''; }
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export const getInitials = (s: string) => {
  if (typeof s !== 'string') { return ''; }
  return s.normalize('NFD').replace(/[^\w\s]/gi, '').match(/\b(\w)/g)?.join('').toUpperCase();
}

export const onErrorImage = (ev: any) => {
  if (ev !== undefined && ev.target !== undefined) {
    ev.target.src = ARTWORK_COVER_PLACEHOLDER_URL;
  }
}

export const dataURItoJpegBlob = (dataURI: any) => {
  var binary = atob(dataURI.split(',')[1]);
  var array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
}

export const emptyIfUndef = (str: string): string => {
  if (str === undefined) {
    return '';
  }

  return str;
}

export const isProfileLive = (profile: Profile) => {
  return profile.state === State.LIVE;
}

export const isProfileFree = (profile: Profile) => {
  return profile.premiumLevel === PREMIUM_LEVEL.FREE;
}

export const isProfilePrivate = (profile: Profile) => {
  return profile.visibility === Visibility.PRIVATE;
}

export const isProfilePremiumExpired = (profile: Profile) => {
  return (profile.premiumLevel !== PREMIUM_LEVEL.FREE && new Date(profile.premiumEndDate) < new Date());
}

export const isProfileCurrentlyPremium = (profile: Profile) => {
  return (profile.premiumLevel !== PREMIUM_LEVEL.FREE && new Date(profile.premiumEndDate) >= new Date());
}

export const isProfileNoArtworkDisplayed = (profile: Profile) => {
  return profile.artworks?.find(artwork => (artwork.displayed === true && artwork.state === State.LIVE)) === undefined;
}

export const isProfileExposed = (profile: Profile) => {
  return isProfileLive(profile) &&
    !isProfilePrivate(profile) &&
    !isProfileNoArtworkDisplayed(profile);
}

export const hasPortfolioPublic = (profile: Profile) => {
  return isProfileLive(profile) &&
    profile.premiumLevel >= PREMIUM_LEVEL.PORTFOLIO &&
    !isProfilePrivate(profile) &&
    !isProfilePremiumExpired(profile) &&
    !isProfileNoArtworkDisplayed(profile);
}

export const isProfileExposedToEmoji = (profile: Profile) => {
  return isProfileExposed(profile) ? '✅' : '❌';
}

export const hasActiveSubscription = (profile: Profile) => {
  return profile.subscription?.status === 'active';
}

export const subscriptionNeedPayment = (subscription: ProfileSubscription) => {
  return subscriptionStatusNeedPayment(subscription?.status as StripeSubscriptionStatus);
}

export const subscriptionStatusNeedPayment = (status: StripeSubscriptionStatus) => {
  return status === 'incomplete' || status === 'past_due';
}

export const subscriptionIncomplete = (subscription: ProfileSubscription) => {
  return subscription?.status === 'incomplete';
}

export const subscriptionUnpaid = (subscription: ProfileSubscription) => {
  return subscription?.status === 'unpaid';
}

export const subscriptionLatePayment = (subscription: ProfileSubscription) => {
  return subscription?.status === 'past_due';
}

export const cardBrandToFontawesomIcon = (brand: string) => {
  switch (brand) {
    case 'mastercard':
      return 'fa-brands fa-cc-mastercard';
    case 'visa':
      return 'fa-brands fa-cc-visa';
    case 'amex':
      return 'fa-brands fa-cc-amex';
    default:
      return 'fa-solid fa-credit-card';
  }
}

export const isPaymentMethodExpired = (paymentMethod: StripePaymentMethodInfos) => {
  // getMonth() is 0-based
  if (new Date().getMonth() > (paymentMethod.expMonth - 1) && new Date().getFullYear() >= paymentMethod.expYear) {
    return true;
  }
  return false;
}

export const cardLast4ToString = (last4: string) => {
  return `•••• •••• •••• ${last4}`;
}

/* ENTITY TO STRING */
export const premiumEndDateToString = (premiumEndDate: string, lifetimeStr: string, locale: string) => {
  return new Date(premiumEndDate) > new Date('01/01/2099') ?
    lifetimeStr :
    moment(premiumEndDate).locale(locale).format('DD MMMM yyyy')
}

export const premiumLevelToString = (premiumLevel: number, t: any): string => {
  switch (premiumLevel) {
    case 0:
      return t('premium_level.free');
    case PREMIUM_LEVEL.PRO:
      return t('premium_level.pro');
    case PREMIUM_LEVEL.PORTFOLIO:
      return t('premium_level.portfolio');
    case PREMIUM_LEVEL.COMPANY:
      return t('premium_level.company');
    default:
      return t('premium_level.custom');
  }
};

// returns names if there is any, email if not
export const userToStr = (user: User): string => {
  if (!user) {
    return;
  }
  return user.infos && (user.infos.firstName || user.infos.lastName) ?
    user.infos.firstName + ' ' + user.infos.lastName :
    user.email;
}

// returns names if there is any, part before @ of email if not
export const userToStrNotFullMail = (user: User): string => {
  if (!user) {
    return;
  }
  return user.infos?.firstName || user.infos?.lastName ?
    user.infos.firstName + ' ' + user.infos.lastName :
    user.email.includes('contact') ? user.email : user.email.split('@')[0];;
}

// returns  email + names if there is any
export const userToStrFull = (user: User): string => {
  if (!user) {
    return;
  }
  let userStr: string = user.email;

  if (user.infos?.firstName || user.infos?.lastName) {
    userStr = userStr.concat(' (', user.infos?.firstName, ' ', user.infos?.lastName, ')')
  }

  return userStr;
}

export const filmToStr = (film: Film, inDevStr: string): string => {
  return film.name.concat(
    ' (',
    film.releaseYear === 0 ? inDevStr : film.releaseYear.toString(), '), ', film.directorName);
}

export const festivalAwardToStr = (award: FestivalAward, locale: string): string => {
  let category: FestivalAwardCategory = award?.category;
  if (!award) {
    return '';
  }
  else if (award.name === category?.name) {
    return findTranslationOrDefault(category, locale);
  }
  else {
    return findTranslationOrDefault(award, locale).concat(' (', findTranslationOrDefault(category, locale), ')')
  }
}

/* LOCATION STUFF */

const locationElementsToStr = (city: string, region: string, country: string): string => {
  return city?.concat(' (', region, ') - ', country);
}

export const geocodeLocationToStr = (geocodeLocation: GeocodeLocation): string => {
  return locationElementsToStr(
    geocodeLocation?.city,
    geocodeLocation?.region,
    geocodeLocation?.country
  );
}

export const locationToStr = (location: Location, locale: string): string => {
  return locationElementsToStr(
    findTranslationOrDefault(location?.city, locale),
    findTranslationOrDefault(location?.region, locale),
    findTranslationOrDefault(location?.country, locale)
  );
}

export const isFilterParamValid = (filter: FilterI, label: FilterLabelI) => {
  const errThrower = () => {
    throw new Error('Bad Request');
  };

  Object.entries(filter).forEach(filterLabel => {

    // Check for shortlist exist / typo
    if (
      filterLabel[0]?.match(FilterType.SHORTLIST.toLowerCase()) &&
      !filter?.shortlist?.id
    ) {
      errThrower();
    }

    if (filterLabel[0] === FilterType.FESTIVAL_AWARDED_ONLY.toLowerCase()) {
      // This filter should always display id 1 if filter is selected
      if (filterLabel[1]?.id != 1) {
        errThrower();
      }
    }
    // Verify if we get well an array with a specific shape for hashtags
    if (filterLabel[0] === FilterType.HASHTAG.toLowerCase()) {
      if (Object.keys(filterLabel[1])?.length > 1) {
        errThrower();
      }
      if (!filterLabel[1]?.names?.forEach) {
        errThrower();
      }
      if (filterLabel[1]?.names.includes('')) {
        errThrower();
      }
    }

    if (filterLabel[0] === FilterType.LOCATION.toLowerCase()) {
      // If location has an error in params array [id] & [type]
      if (label.location === '') {
        errThrower();
      }
      if (!filter.location?.id || !filter.location?.type) {
        errThrower();
      }
      if (!filterLabel[1]?.id || filterLabel[1]?.id === '') {
        errThrower();
      }
    }

    // If a filter is wrong written
    // i.e: jb instead of job
    if (FilterType[filterLabel[0].toUpperCase()] === undefined) {
      errThrower();
    }

  });
  return
}

export const sendMsgToReactNativeWebview = async (msg: { type: string, value: string | number }) => {
  // @ts-ignore
  if (window?.ReactNativeWebView) {
    // @ts-ignore
    await window.ReactNativeWebView.postMessage(JSON.stringify(msg));
  }
}
