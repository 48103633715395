import React, { useState, useEffect, useContext } from 'react';
import { send, Festival, FestivalController, FestivalCategory } from '@oward/openapi';
import { PaginatedList, PaginatedListKey, Section, NavigationContext } from '@components/admin';
import { findTranslationOrDefault, strToPath, stateToEmoji } from '@oward/common-utils';
import { useTranslate } from '@hooks/.';
import { OwardButton } from '@components/Core';
import { EditFestivalCategory } from './EditFestivalCategory';

export const FestivalCategoryList: React.FC = () => {

  const { locale } = useTranslate();
  const [items, setItems] = useState([] as FestivalCategory[]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();
  const store = useContext(NavigationContext);

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = async () => {
    try {
      setLoading(true);
      setError(false);
      setItems(await send(FestivalController.findCategories()))
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const keys: PaginatedListKey<FestivalCategory>[] = [
    {
      label: 'Id',
      value: item => item.id.toString()
    },
    {
      label: 'Name FR',
      value: item => findTranslationOrDefault(item, 'fr').concat(' ', stateToEmoji(item.state))
    },
    {
      label: 'Name EN',
      value: item => findTranslationOrDefault(item, 'en')
    },
  ];

  const filterItemStrings = (item: Festival) => [item.id.toString(), strToPath(item.name)];

  const fetchOne = (item: Festival) => send(FestivalController.findOneCategory(item.id));

  return (
    <React.Fragment>
      <Section
        title='List of Festival Categories'
        subtitle='The list of all festival categories in the database'
        childrenFullWidth
      >
        <PaginatedList
          keys={keys}
          items={items}
          filterItemStrings={filterItemStrings}
          size={10}
          editComponent={EditFestivalCategory}
          fetchOne={fetchOne}
          reloadList={loadItems}
          loading={loading}
          error={error}
        />
      </Section>
      <div style={{ height: '2rem' }} />
      <Section
        title='Create new Festival Category'
        subtitle='Create it WHEN PRESSING THIS BUTTON. Cannot be undone, please fill all needed infos in next screen'
      >
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingBottom: '3rem' }}>
          <OwardButton
            name='Create Festival Category'
            onClick={async () => {
              let festivalCategory: FestivalCategory = await send(FestivalController.createOneCategory());
              loadItems();
              store.pop({
                label: 'Create Festival Category',
                element: <EditFestivalCategory item={festivalCategory} notify={loadItems} noCancel />
              });
            }}
          />
          <p className='has-text-danger'>
            Newly created festival category will be in BLOCKED State.
          </p>
        </div>
      </Section>
    </React.Fragment>
  );
}
