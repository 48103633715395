import React, { useEffect } from 'react';
import styles from './GallerySideBar.module.scss';
import { useInjection, useTranslate } from '@hooks/.';
import { LayoutStore, ModalStore, ModalKey, FilterStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import SearchIcon from '../../../assets/icons/search.svg';
import BookmarkIcon from '../../../assets/icons/bookmark_outlined.svg';
import classNames from 'classnames';
import { Accordion, SidebarShortlist } from '@components/.';
import { useRouter } from 'next/router';
import { onTouchDevice } from '@utils/.';

export const GallerySideBar = () => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  // If want to have Sidebar opened by default, uncomment the following UseEffect
  // AND initialize `sidebarFirstInterraction` at `true` in layout-store.ts
  /*useEffect(() => {
    // At page load, open the sidebar (only on desktop)
    if (!onTouchDevice() && layoutStore.sidebarFirstInterraction) {
      layoutStore.openSidebar(true);
    }
  }, [layoutStore]);*/

  return (
    <div className={classNames(styles.mainContainer)}>
      <div className={classNames(styles.contentContainer)}>
        <div className={classNames(styles.searchContainer)}>
          <div className={classNames(styles.headerContainer)}>

            <div className={classNames(styles.flashContainer)} id='gallerySideBarFlashContainer' />
            <div className={classNames(styles.titleContainer)}>
              <p className={classNames(styles.title)}>
                {t('sidebar.search_by')}
              </p>
            </div>
            <div className={classNames(styles.closeContainer)}>
              <p onClick={() => { layoutStore.openSidebar(false) }}>
                <span className={`icon`}><i className='fas fa-times'></i></span>
              </p>
            </div>
          </div>
          <Accordion />
        </div>
      </div>
      {
        filterStore.isProfileGallery &&
        <div className={classNames(styles.contentContainer)}>
          <div className={classNames(styles.headerContainer)}>
            <div className={classNames(styles.titleContainer)}>
              <div className={classNames(styles.svgIconContainer)}>
                <BookmarkIcon className={`${styles.svgIcon}`} />
              </div>
              <p className={classNames(styles.title)}>
                {t('sidebar.shortlist')}
              </p>
            </div>
          </div>
          <SidebarShortlist />
        </div>
      }
      <div className={classNames(styles.footerContainer)}>
        <p>
          <a
            className={classNames(styles.link)}
            href='https://discover.oward.co'
            target='_blank'
            rel='noreferrer'
          >
            {t('sidebar.about')}
          </a><span> • </span>
          <a
            className={classNames(styles.link)}
            href='https://discover.oward.co/#qui-sommes-nous'
            target='_blank'
            rel='noreferrer'
          >
            {t('sidebar.who_are_we')}
          </a><span> • </span>
          <a
            className={classNames(styles.link)}
            href={`mailto:contact@oward.co?&subject=${t('mail.subject_subscription')}&body=${t('mail.body_subscription')}`}
            target='_blank'
            rel='noreferrer'
          >
            {t('sidebar.contact_us')}
          </a><span> • </span>
          <a
            className={classNames(styles.link)}
            href='https://discover.oward.co/#cgu'
            target='_blank'
            rel='noreferrer'
          >
            {t('sidebar.cgu')}
          </a><span> • </span>
          <a
            className={classNames(styles.link)}
            href='https://discover.oward.co/#cgv'
            target='_blank'
            rel='noreferrer'
          >
            {t('sidebar.cgv')}
          </a><span> • </span>
          <span
            onClick={() => { modalStore.openModalNewStack(router, ModalKey.GDPR_PREFS, 1) }}
            className={classNames(styles.link)}
          >{t('sidebar.cookies')}</span><span> • </span>
          <a
            className={classNames(styles.link)}
            href='https://discover.oward.co/#donnees'
            target='_blank'
            rel='noreferrer'
          >
            {t('sidebar.data_policy')}
          </a><span> • </span>
          <span>{t('sidebar.rights')}</span>
        </p>
      </div>
    </div>
  );
}

export default GallerySideBar;
