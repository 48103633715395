import React, { useState } from 'react';
import styles from './NewsletterModal.module.scss';
import { observer } from 'mobx-react-lite';
import { ModalKey, ModalStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { useInjection, useTranslate } from '@hooks/.';
import { Modal } from '@components/Modal';
import { useFormik } from 'formik';
import { OwardButton, OwardFormInput, OwardFormSwitch, OwardLoader, ToastError, ToastSucess } from '@components/Core';
import * as Yup from 'yup';
import { UserController } from '@oward/openapi';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';

const schema = (t: any) => {
  return (
    Yup.object().shape({
      email: Yup.string().email(t('forms.email.invalid')).required(t('forms.email.required')),
      accept: Yup.bool().oneOf([true], t('modal.newsletter.accept_validation')),
    }))
};

export const NewsletterModal: React.FC = observer(() => {
  const { t } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      accept: false,
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      try {
        setLoading(true);
        await UserController.registerToNewsletter(values.email);
        toast.dark(<ToastSucess msg={t('modal.newsletter.success')} />);
        modalStore.unpopModal(router);
      }
      catch (err) {
        toast.dark(<ToastError msg={t('global.error_with_code', { code: err })} />);
      }
      finally {
        setLoading(false);
      }
    }
  });

  return (
    <Modal modalKey={ModalKey.NEWSLETTER} >
      <div className={styles.mainContainer}>
        <OwardLoader loading={loading} />
        <p className={styles.title}>{t('modal.newsletter.title')}</p>
        <p className={styles.subtitle}>{t('modal.newsletter.subtitle')}</p>
        <p className={styles.description}>{t('modal.newsletter.description')}</p>
        <form onSubmit={formik.handleSubmit}>
          <OwardFormInput
            id='email'
            icon='fas fa-envelope'
            placeholder={t('modal.newsletter.placeholder')}
            formik={formik}
          />
          <OwardFormSwitch
            id='accept'
            label={t('modal.newsletter.accept')}
            formik={formik}
          />
          <OwardButton
            name={t('modal.newsletter.register')}
            submit
            fullWidth
          />
        </form>
      </div>
    </Modal>
  );
});
