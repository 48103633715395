import React, { useEffect, useState } from 'react';
//import styles from './NewShortlistModal.module.scss';
import { useLocalObservable, observer } from 'mobx-react-lite';
import { UserStore, ModalKey, PopupStore, LayoutStore, ModalStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { useInjection, useTranslate } from '@hooks/.';
import {
  OwardFormInput, NeedConnectionModal, Modal, ModalConfirmOrCancelButtons,
  ModalTitle, OwardLoader, OwardFormSelectLoad, jobsBothGenderToOptions, OwardFormSelectLocation, ModalCancelButton, OwardFormSelect, getOrCreateDiscussion
} from '@components/.';
import { useRouter } from 'next/router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { JobsController, Profile, ProfileController, ProfileCreate, send } from '@oward/openapi';
import styles from './NewProfileModal.module.scss';
import { ProfileType } from '@oward/common-utils';
import { action, runInAction } from 'mobx';
import { Gender } from '@oward/common-enums';

enum PANNEL {
  WELCOME = '1',
  CHOOSE_TYPE = '2',
  FORM = '3',
}

interface NewPortfolioStore {
  currentPannel: PANNEL,
  setCurrentPannel: (panel: PANNEL) => void,
  profileType: ProfileType,
}

export const NewPortfolioModal: React.FC = observer(() => {
  const { t } = useTranslate();
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  const store: NewPortfolioStore = useLocalObservable(() => ({
    currentPannel: PANNEL.CHOOSE_TYPE,
    setCurrentPannel: (pannel: PANNEL) => { store.currentPannel = pannel },
    profileType: ProfileType.PRO,
  }));

  const renderPanel = () => {
    switch (store.currentPannel) {
      case PANNEL.WELCOME:
        return <WelcomePanel store={store} />;
      case PANNEL.CHOOSE_TYPE:
        return <ChooseTypePanel store={store} />;
      case PANNEL.FORM:
        return <FormPanel store={store} />;
      default:
        return <WelcomePanel store={store} />;
    }
  }

  useEffect(() => {
    runInAction(() => {
      store.setCurrentPannel(PANNEL.CHOOSE_TYPE);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query[ModalKey.NEW_PORTFOLIO]]);

  return (
    <React.Fragment>
      {
        userStore.isLogged ?
          <React.Fragment>
            {renderPanel()}
          </React.Fragment>
          :
          <Modal modalKey={[ModalKey.NEW_PORTFOLIO]}>
            <NeedConnectionModal headerMessage={t('modal.portfolio.need_connection')} />
          </Modal>
      }
    </React.Fragment>
  );
});

interface PanelProps {
  store: NewPortfolioStore;
}

const WelcomePanel: React.FC<PanelProps> = observer(props => {
  const { t, locale } = useTranslate();

  return (
    <Modal
      modalKey={ModalKey.NEW_PORTFOLIO}
      header={<ModalTitle name={t('modal.profile.welcome.title')} />}
      footer={
        <ModalConfirmOrCancelButtons
          confirmTxt={t('modal.profile.next_button')}
          validation={() => props.store.setCurrentPannel(PANNEL.CHOOSE_TYPE)}
        />
      }
    >
      <div className={styles.introTextContainer}>
        <p className={styles.text}>
          {t('modal.profile.welcome.intro_text')}
          {t('modal.profile.welcome.hint_message')}
          <span className={styles.headerMessage} >{t('modal.profile.welcome.condition_title1')}</span>
          {t('modal.profile.welcome.condition1')}
          <br />
          {t('modal.profile.welcome.outro_text')}
        </p>
      </div>
    </Modal>
  );
});

const ChooseTypePanel: React.FC<PanelProps> = observer(props => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  interface ProfileTypeCardProps {
    title: string;
    description: string;
    icon: string;
    onClick: () => void;
  }

  const ProfileTypeCard: React.FC<ProfileTypeCardProps> = props => {
    return (
      <div
        className={styles.profileTypeCardMainContainer}
        onClick={props.onClick}
      >
        <p className={styles.iconContainer}>
          <span className={'icon'}>
            <i className={props.icon} />
          </span>
        </p>
        <div className={styles.textContainer}>
          <p className={styles.title}>
            {props.title}
          </p>
          <p className={styles.desc}>
            {props.description}
          </p>
        </div>
      </div>
    )
  }

  return (
    <Modal
      modalKey={[ModalKey.NEW_PORTFOLIO]}
      //fixedSize
      header={<ModalTitle name={t('modal.profile.choose_type.title')} />}
      footer={
        <ModalCancelButton
          cancelation={() => {
            modalStore.unpopModal(router);
          }}
        />
      }
    >
      <div className={styles.chooseTypeMainContainer}>
        <ProfileTypeCard
          title={t('modal.profile.choose_type.pro')}
          description={t('modal.profile.choose_type.pro_desc')}
          icon={'fas fa-user'}
          onClick={action(() => {
            props.store.setCurrentPannel(PANNEL.FORM);
            props.store.profileType = ProfileType.PRO;
          })}
        />
        <ProfileTypeCard
          title={t('modal.profile.choose_type.company')}
          description={t('modal.profile.choose_type.company_desc')}
          icon={'fas fa-users'}
          onClick={action(() => {
            props.store.setCurrentPannel(PANNEL.FORM);
            props.store.profileType = ProfileType.COMPANY;
          })}
        />
      </div>
    </Modal>
  );
});

const schema = (t: any) => {
  return (
    Yup.object().shape({
      name: Yup.string().required(t('forms.required')),
      job: Yup.object().required(t('forms.required')),
      location: Yup.object().required(t('forms.required')),
    }))
};

const FormPanel: React.FC<PanelProps> = observer(props => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);

  const [error, setError] = useState<string>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      gender: Gender.MALE,
      job: undefined,
      location: undefined,
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      try {
        let newProfile: ProfileCreate = {
          name: values.name,
          job: values.job,
          type: props.store.profileType,
          gender: values.gender,
          location: values.location,
          email: userStore.currentUser.email,
        };
        setLoading(true);
        let profile: Profile = await send(ProfileController.create(newProfile));
        setError(undefined);
        // Go to profile edition
        popupStore.openInformationPopUp({
          title: t('edit_profile.almost_modal.title'),
          msg: t('edit_profile.almost_modal.msg')
        });
        router.push(`/[lang]/account/profile/[profileId]`, `/${locale}/account/profile/${profile.id}`)
      } catch (err) {
        setError(t('global.error_retry'));
      }
      finally {
        setLoading(false);
      }
    }
  });

  let nameLabel, namePlaceholder, jobLabel: string;

  switch (props.store.profileType) {
    case ProfileType.COMPANY:
      nameLabel = t('forms.company_name.label') + ' *';
      namePlaceholder = t('forms.company_name.placeholder');
      jobLabel = t('edit_profile.general.company_type') + ' *'
      break;
    default:
      nameLabel = t('forms.name.label') + ' *';
      namePlaceholder = t('forms.name.placeholder');
      jobLabel = t('edit_profile.general.job') + ' *';
      break;

  }

  return (
    <Modal
      modalKey={[ModalKey.NEW_PORTFOLIO]}
      fixedSize
      header={<ModalTitle name={
        t('modal.profile.new_title')
      } />}
      footer={
        <form onSubmit={formik.handleSubmit} >
          <ModalConfirmOrCancelButtons
            confirmTxt={t('global.create')}
            error={error}
            cancelation={() => props.store.setCurrentPannel(PANNEL.CHOOSE_TYPE)}
          />
        </form>
      }
    >
      <form onSubmit={formik.handleSubmit} style={{ position: 'relative' }}>
        <OwardLoader loading={loading} />
        <div style={{ padding: '0 0.1rem' }}>
          <OwardFormInput
            id='name'
            label={nameLabel}
            placeholder={namePlaceholder}
            formik={formik}
          />
          {
            props.store.profileType === ProfileType.PRO &&
            <OwardFormSelect
              id='gender'
              label={t('edit_profile.general.gender') + ' *'}
              description={t('edit_profile.general.gender_description')}
              options={[
                { value: Gender.MALE, label: t('edit_profile.general.gender_male') },
                { value: Gender.FEMALE, label: t('edit_profile.general.gender_female') }
              ]}
              formik={formik}
            />
          }
          <OwardFormSelectLoad
            id='job'
            label={jobLabel}
            description={props.store.profileType === ProfileType.PRO && t('edit_profile.general.job_description_portfolio')}
            fetchFunction={JobsController.list(locale, props.store.profileType, formik.values.gender)}
            entitiesToOptions={jobsBothGenderToOptions}
            formik={formik}
            reload
            isNotClearable
          />
          <OwardFormSelectLocation
            id='location'
            label={t('edit_profile.general.location') + ' *'}
            description={t('edit_profile.general.location_description')}
            formik={formik}
          />
        </div>
      </form>
    </Modal >
  );
});
