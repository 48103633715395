import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { Artwork, ArtworkController, ArtworkType, ArtworkUrl, Film, FilmsController, JobsController, send, State } from '@oward/openapi';
import { EditProps } from '../types';
import { Section, Divider, SubmitOrCancel, NavigationContext, EditButton } from '@components/admin';
import { filmToOptions, Heading, jobsBothGenderToOptions, OwardFormInput, OwardFormSelect, OwardFormSelectLoad, OwardFormSelectLoadMulti, OwardFormTextArea, OwardLoader } from '@components/Core';
import { useTranslate } from '@hooks/.';
import { stateToEmoji } from '@oward/common-utils';
import { filmToStr } from '@utils/.';
import * as Yup from 'yup';
import { EditFilm } from '../Films';

const schema = (t: any) => {
  return Yup.object().shape({
    name: Yup.string().required(),
    path: Yup.string().required(),
    film: Yup.object().required(),
    url: Yup.string().required(),
  });
};

export const EditArtwork = observer((props: EditProps<Artwork>) => {
  const { t, locale } = useTranslate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigation = useContext(NavigationContext);

  const formik = useFormik({
    initialValues: {
      name: props.item.name,
      state: props.item.state,
      film: props.item.film,
      path: props.item.path,
      jobs: props.item.jobs,
      description: props.item.description,
      type: props.item.type,
      url: props.item.urls.find((url) => url.type === ArtworkType.VIDEO_LINK).url,
      imageSmall: props.item.images?.small,
      imageMediumSmall: props.item.images?.mediumSmall,
      imageMediumLarge: props.item.images?.mediumLarge,
      imageLarge: props.item.images?.large,
      imageFetched: props.item.images?.fetched,
    },
    validationSchema: schema(t),
    onSubmit: async (values) => {
      props.item.name = values.name;
      props.item.state = values.state;
      props.item.film = values.film;
      props.item.path = values.path;
      props.item.jobs = values.jobs;
      props.item.description = values.description;
      props.item.type = values.type;
      props.item.urls.find((url) => url.type === ArtworkType.VIDEO_LINK).url = values.url;
      props.item.images.small = values.imageSmall;
      props.item.images.mediumSmall = values.imageMediumSmall;
      props.item.images.mediumLarge = values.imageMediumLarge;
      props.item.images.large = values.imageLarge;
      props.item.images.fetched = values.imageFetched;
      try {
        setLoading(true);
        await ArtworkController.saveOneAdmin(props.item);
        if (props.notify) {
          props.notify(props.item);
        }
        setError(false);
        navigation.unpop();
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    },
  });

  const fetchOneFilm = (item: Film) => send(FilmsController.findOne(item.id, locale));

  return ((
    <React.Fragment>
      <Section title={`Edit '${formik.initialValues.name}' artwork`} subtitle={`Change the artwork properties`} childrenFullWidth>
        <form className='box' style={{ position: 'relative' }} onSubmit={formik.handleSubmit}>
          <OwardLoader loading={loading} />
          <Heading>Profile owner</Heading>
          <p className='has-text-weight-bold is-size-5'>{props.item.profile.name}</p>
          <Divider />
          <Heading>GENERAL</Heading>
          <OwardFormInput id='name' type='name' label='Name *' icon='fas fa-signature' formik={formik} />
          <OwardFormSelect
            id='state'
            label='State *'
            options={Object.values(State).map((state) => {
              return {
                value: state,
                label: state.concat(' ', stateToEmoji(state)),
              };
            })}
            formik={formik}
          />
          <OwardFormInput id='path' label='Path (must be unique, no space, lowercase alphanumerical)' placeholder='path' formik={formik} />
          <Divider />
          <Heading>DETAILS</Heading>
          <div style={{ display: 'flex' }}>
            <OwardFormSelectLoad
              id='film'
              label='Film *'
              fetchFunction={FilmsController.findAdmin(locale)}
              entitiesToOptions={(entities) => {
                return filmToOptions(entities, t('wip.film'));
              }}
              entityToStr={(film) => {
                return filmToStr(film, t('wip.film'));
              }}
              formik={formik}
            />
            <EditButton component={EditFilm} fetchOne={fetchOneFilm} item={formik.values.film} label={'Edit film'} />
          </div>
          <OwardFormSelectLoadMulti
            id='jobs'
            label='Job(s)'
            description='AT LEAST ONE JOB'
            fetchFunction={JobsController.list(locale, props.item.profile.type)}
            entitiesToOptions={(entityList) => { return jobsBothGenderToOptions(entityList, true) }}
            isNotClearable
            formik={formik}
          />
          <OwardFormTextArea id='description' label='Description' placeholder='Write a description' formik={formik} />
          <OwardFormSelect
            id='type'
            label='Type *'
            options={Object.values(ArtworkType).map((type) => {
              return { value: type, label: type };
            })}
            formik={formik}
          />
          <OwardFormInput id='url' label='URL (for now only one external_video_link URL) *' placeholder='url' formik={formik} />
          <Divider />
          <Heading>IMAGES</Heading>
          <OwardFormInput id='imageSmall' label='Small image URL' placeholder='url' formik={formik} />
          <OwardFormInput id='imageMediumSmall' label='Medium-small image URL' placeholder='url' formik={formik} />
          <OwardFormInput id='imageMediumLarge' label='Medium-large image URL' placeholder='url' formik={formik} />
          <OwardFormInput id='imageLarge' label='Large image URL' placeholder='url' formik={formik} />
          <OwardFormInput id='imageFetched' label='Fetched image URL' placeholder='url' formik={formik} />

          <Heading>List of ArtworkUrl (type: PicturePortfolio)</Heading>
          <table className='table is-fullwidth'>
            <thead>
              <tr>
                <th>Id</th>
                <th>State</th>
                <th>Order</th>
                <th>UrlPicture</th>
                <th>PreviewPicture</th>
              </tr>
            </thead>
            <tbody>
              {props.item.urls.filter(url => url.type === ArtworkType.PICTURE_PORTFOLIO).map((url: ArtworkUrl, index: number) =>
                url.type === ArtworkType.PICTURE_PORTFOLIO ? (
                  <tr key={index}>
                    <td>
                      <p>{url.id}</p>
                    </td>
                    <td>
                      <OwardFormSelect
                        id={'artworkUrlState' + url.id}
                        label=''
                        isNotClearable={true}
                        placeholder={url.state}
                        options={Object.values(State).map((state) => {
                          return {
                            value: state,
                            label: state.concat(' ', stateToEmoji(state)),
                          };
                        })}
                        formik={formik}
                        onChange={(value) => (url.state = value)}
                      />
                    </td>
                    <td>
                      <OwardFormInput
                        id={'orderUrl' + url.id}
                        placeholder='define order'
                        formik={formik}
                        value={String(url.order)}
                        onChange={(value) => (url.order = Number(value))} />
                    </td>
                    <td>
                      <OwardFormInput
                        id={'pictureUrl' + url.id}
                        placeholder='Change the url'
                        formik={formik}
                        value={url.url}
                        onChange={(value) => (url.url = value)} />
                    </td>
                    <td>
                      <img src={url.url} height={'80rem'} width={'80rem'} alt='' />
                    </td>
                  </tr>
                ) : null
              )}
            </tbody>
          </table>

          <SubmitOrCancel error={error} />
        </form>
        <div style={{ height: '200px' }} />
      </Section>
    </React.Fragment>
  ));
});
