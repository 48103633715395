import { locales } from '@locales/.';
import * as Yup from 'yup';

export const yupTranslationsShape = Yup.object().shape(
  locales.reduce((acc, locale) => ({
    ...acc,
    [locale]: Yup.object().shape({ name: Yup.string().required(`Translation for locale "${locale}" is required`) })
  }), {})
);

export const yupTranslationsShapeNullable = Yup.object().shape(
  locales.reduce((acc, locale) => ({
    ...acc,
    [locale]: Yup.object().shape({ name: Yup.string() })
  }), {})
).nullable();

export type Localized = {
  locale: string;
}

// There's only one translation for a given locale
export type GroupedByLocale<T extends Localized> = {
  [locale: string]: T
}

export function groupByLocale<T extends Localized>(items: T[]): GroupedByLocale<T> {
  return locales.reduce((c, locale) => {
    let item: T = items?.find(item => item.locale === locale);
    if (item) {
      c[locale] = item;
    }
    else {
      // If translation doesn't exist for locale, create it.
      c[locale] = { locale: locale, name: '' };
    }
    return c;
  }, {})
}

export function flattenFromGrouped<T extends Localized>(grouped: GroupedByLocale<T>): T[] {
  return Object.values(grouped).reduce((c, value) => {
    c.push(value);
    return c;
  }, [])
}

// There's multiple translations for a given locale (ex: for Jobs, with Gender multiplicating translations)
export type GroupedByLocaleMultiple<T extends Localized> = {
  [locale: string]: T[]
}

export function groupByLocaleMultiple<T extends Localized>(items: T[]): GroupedByLocaleMultiple<T> {
  return locales.reduce((c, locale) => {
    let itemsFiltered = items?.filter(item => item.locale === locale);
    if (itemsFiltered?.length > 0) {
      c[locale] = itemsFiltered;
    }
    return c;
  }, {})
}

export function flattenFromGroupedMultiple<T extends Localized>(grouped: GroupedByLocaleMultiple<T>): T[] {
  return Object.values(grouped).reduce((c, value) => {
    c.push(...value);
    return c;
  }, [])
}
