import React, { useEffect } from 'react';
import stylesCommon from './ModalCommon.module.scss';
import { StoresBindings } from '@container/.';
import { useInjection, useProfilesForUser, useTranslate } from '@hooks/.';
import { MessageController, ProfileController, send } from '@oward/openapi';
import { ModalKey, ModalStore } from '@stores/modal-store';
import { observer } from 'mobx-react-lite';
import { NextRouter, useRouter } from 'next/router';
import { OwardButton, OwardLoader } from '@components/Core';
import { Modal, NeedConnectionModal } from '.';
import { UserStore } from '@stores/user-store';
import { LayoutStore } from '@stores/layout-store';
import { ModalProfileRectangle } from './ModalCommon';
import { getOrCreateDiscussion } from '@components/Card';

export const ContactModal: React.FC = observer(() => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const { profiles, isLoading } = useProfilesForUser(locale);

  useEffect(() => {
    // In case someone arrives directly on the gallery with a "contact=...." in the query,
    // or if the id of the profile in store is different from the one in the url (typically when going backward)
    // we need to fetch profile data
    (async function getProfileName() {
      try {
        if (
          !!router.query[ModalKey.CONTACT] &&
          !modalStore.contactInfos?.profileName
        ) {
          modalStore.setContactInfos({
            profileName: (await ProfileController.findName(parseInt(router.query[ModalKey.CONTACT] as string))).text,
            profileId: parseInt(router.query[ModalKey.CONTACT] as string)
          })
        }
      }
      catch (err) {

      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query[ModalKey.CONTACT] as string])

  return (
    <React.Fragment>
      {
        userStore.isLogged ?
          <Modal
            modalKey={ModalKey.CONTACT}
          >
            {
              isLoading ?
                <OwardLoader loading={true} positionStatic />
                :
                <React.Fragment>
                  {
                    profiles?.length === 0 ?
                      <ContactModalNoProfile />
                      :
                      <ContactModalProfiles />
                  }
                </React.Fragment>
            }
          </Modal>
          :
          <Modal modalKey={ModalKey.CONTACT}>
            <NeedConnectionModal headerMessage={t('modal.contact.need_connection')} />
          </Modal>
      }
    </React.Fragment>
  )
});

const ContactModalProfiles: React.FC = observer(() => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);
  const { profiles } = useProfilesForUser(locale);

  return (
    <div className={stylesCommon.mainContainer}>
      <p className={stylesCommon.mediumModalHeader}>
        {t('modal.contact.choose_profile', { contactedName: modalStore.contactInfos?.profileName })}
      </p>
      {
        profiles?.map((profile, i) =>
          <ModalProfileRectangle
            profile={profile}
            onClick={async () => {
              await getOrCreateDiscussion(
                profile.id, modalStore.contactInfos?.profileId, profile.path,
                () => { layoutStore.openMessagesDiscussionContainer(true) }, router, locale
              );
            }}
            key={i}
          />
        )
      }
    </div>
  );
});

const ContactModalNoProfile: React.FC = observer(() => {
  const { t } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  return (
    <div className={stylesCommon.mainContainer}>
      <p className={stylesCommon.mediumModalHeader}>
        {t('modal.contact.need_profile', { contactedName: modalStore.contactInfos?.profileName })}
      </p>
      <OwardButton
        name={t('login.verify_email.button_create_profile')}
        fullWidth
        onClick={() => modalStore.popModal(router, ModalKey.NEW_PROFILE_TO_CONTACT, parseInt(router.query[ModalKey.CONTACT] as string))}
      />
    </div>
  );
});
