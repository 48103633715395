import { Discussion, Profile } from '@oward/openapi';
import { injectable } from 'inversify';
import { makeAutoObservable } from 'mobx';

interface MessageDraft {
  discussionId: number;
  text: string;
  picture: any;
}

@injectable()
export class MessageStore {
  constructor() {
    makeAutoObservable(this);
  }

  currentProfile: Profile = undefined;

  setCurrentProfile(profile: Profile) {
    this.currentProfile = profile;
  }

  currentDiscussion: Discussion = undefined;

  setCurrentDiscussion(discussion: Discussion) {
    this.currentDiscussion = discussion;
  }

  messageDrafts: MessageDraft[] = [];

  setMessageDraftText(discussionId: number, text: string) {
    let messageDraft = this.messageDrafts.find(draft => draft.discussionId === discussionId);
    if (messageDraft) {
      messageDraft.text = text;
    }

    else {
      this.messageDrafts.push({
        discussionId: discussionId,
        text: text,
        picture: null,
      })
    }
  }

  setMessageDraftPicture(discussionId: number, picture: any) {
    let messageDraft = this.messageDrafts.find(draft => draft.discussionId === discussionId);
    if (messageDraft) {
      messageDraft.picture = picture;
    }

    else {
      this.messageDrafts.push({
        discussionId: discussionId,
        picture: picture,
        text: '',
      })
    }
  }

  messageDraftForDiscussionText(discussionId: number) {
    return this.messageDrafts.find(draft => draft.discussionId === discussionId)?.text ?? '';
  }

  messageDraftForDiscussionPicture(discussionId: number) {
    return this.messageDrafts.find(draft => draft.discussionId === discussionId)?.picture ?? null;
  }

}
