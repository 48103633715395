import React, { useEffect, useState } from 'react';
import stylesCommon from './ModalCommon.module.scss';
import styles from './ContactDetailsModal.module.scss';
import { StoresBindings } from '@container/.';
import { useInjection, useProfilesForUser, useTranslate } from '@hooks/.';
import { ProfileContactDetails, ProfileController, send } from '@oward/openapi';
import { ModalKey, ModalStore } from '@stores/modal-store';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { OwardButton, OwardLoader, ToastError } from '@components/Core';
import { Modal, NeedConnectionModal } from '.';
import { UserStore } from '@stores/user-store';
import { LayoutStore } from '@stores/layout-store';
import { toast } from 'react-toastify';
import { getOrCreateDiscussion } from '@components/Card';
import { ERR_MSG_BAD_REQUEST } from '@utils/constants';

export const ContactDetailsModal: React.FC = observer(() => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const [loading, setLoading] = useState<boolean>(false);
  const [errror, setError] = useState<string>('');

  useEffect(() => {
    // In case someone arrives directly on the gallery with a "contact=...." in the query,
    // or if the id of the profile in store is different from the one in the url (typically when going backward)
    // we need to fetch profile data
    (async function getProfileContactDetails() {
      try {
        if (
          !!router.query[ModalKey.CONTACT_DETAILS]
        ) {
          setLoading(true);
          setError(undefined);
          let contactInfos: ProfileContactDetails = await send(
            ProfileController.findContactDetails(parseInt(router.query[ModalKey.CONTACT_DETAILS] as string))
          );
          modalStore.setContactDetailsInfos({
            profileId: parseInt(router.query[ModalKey.CONTACT_DETAILS] as string),
            profileName: contactInfos.name,
            mail: contactInfos.mail,
            phoneNumber: contactInfos.phoneNumber
          })
        }
      }
      catch (err) {
        if (err instanceof Error && err.message === ERR_MSG_BAD_REQUEST) {
          setError(t('modal.contact_details.not_recruiter'));
        }
        else {
          setError(t('global.error_with_code', { code: err }));
        }
      }
      finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query[ModalKey.CONTACT_DETAILS] as string])

  return (
    <React.Fragment>
      {
        userStore.isLogged ?
          <Modal
            modalKey={ModalKey.CONTACT_DETAILS}
          >
            {
              loading ?
                <OwardLoader loading={true} positionStatic />
                :
                <ContactDetails error={errror} />
            }
          </Modal>
          :
          <Modal modalKey={ModalKey.CONTACT_DETAILS}>
            <NeedConnectionModal headerMessage={t('modal.contact_details.need_connection')} />
          </Modal>
      }
    </React.Fragment>
  )
});

interface ContactDetailsProps {
  error?: string;
}

const ContactDetails: React.FC<ContactDetailsProps> = observer(props => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  const { profiles } = useProfilesForUser(locale);

  const onClickContact = async () => {
    if (profiles?.map(profile => profile.id)?.includes(modalStore.contactDetailsInfos?.profileId)) {
      toast.dark(<ToastError msg={t('messages.no_auto_contact_toast')} />);
      return;
    }
    switch (profiles?.length) {
      case 1:
        await getOrCreateDiscussion(
          profiles[0]?.id, modalStore.contactDetailsInfos?.profileId, profiles[0]?.path,
          () => { layoutStore.openMessagesDiscussionContainer(true) }, router, locale
        );
        break;
      case 0:
      default:
        modalStore.setContactInfos({ profileName: modalStore.contactDetailsInfos?.profileName, profileId: modalStore.contactDetailsInfos?.profileId });
        modalStore.popModal(router, ModalKey.CONTACT, modalStore.contactDetailsInfos?.profileId);
        break;
    }
  };

  return (
    <div className={stylesCommon.mainContainer}>
      {
        props.error ?
          <p className={styles.error}>
            {props.error}
          </p>
          :
          <>
            <p className={styles.title}>
              {t('modal.contact_details.title', { name: modalStore.contactDetailsInfos?.profileName })}
            </p>
            <div className={styles.contactDetailsContainer}>
              {
                modalStore.contactDetailsInfos &&
                <>
                  <p>{`${modalStore.contactDetailsInfos.mail}`}</p>
                  {
                    modalStore.contactDetailsInfos?.phoneNumber ?
                      <p>{`${modalStore.contactDetailsInfos?.phoneNumber}`}</p>
                      :
                      <p className={styles.noPhone}>{t('modal.contact_details.no_phone')}</p>
                  }
                </>
              }
            </div>
            <OwardButton
              name={t('modal.contact_details.use_messaging_service')}
              outlined
              onClick={onClickContact}
            />
          </>
      }
    </div>
  );
});
