import React from 'react';

import { Divider } from '@components/admin';
import { FestivalStatistics } from './FestivalStatistics';
import { FestivalList } from './FestivalList';
import { FestivalCategoryList } from './FestivalCategoryList';

export const FestivalsComponent: React.FC = () => {
  return (
    <React.Fragment>
      <FestivalStatistics />
      <Divider />
      <FestivalList />
      <Divider />
      <FestivalCategoryList />
    </React.Fragment>
  );
}
