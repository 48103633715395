import React from 'react';

import { Divider } from '@components/admin';
import { FilmStatistics } from './FilmStatistics';
import { FilmList } from './FilmList';

export const FilmsComponent: React.FC = () => {
  return (
    <React.Fragment>
      <FilmStatistics />
      <Divider />
      <FilmList />
    </React.Fragment>
  );
}
