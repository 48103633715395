import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import NextLink, { LinkProps } from 'next/link';
import { I18nContext } from './I18nProvider';

type OwardLinkProps = {
  deactivate?: boolean;
  children?: React.ReactNode;
} & LinkProps;

export const Link: React.FC<OwardLinkProps> = props => {

  const polyglot = useContext(I18nContext);
  const { as, children, href, ...options } = props;

  if (props.deactivate) {
    return (<React.Fragment>{children}</React.Fragment>);
  }

  if (href.toString().startsWith('/')) {
    return (
      <NextLink
        href={`/[lang]${href.toString()}`}
        as={`/${polyglot.locale()}${(as || href).toString()}`}
        legacyBehavior
        {...options}
      >
        {children}
      </NextLink>
    );
  }

  return (
    <NextLink
      href={href}
      as={as}
      legacyBehavior
      {...options}
    >
      {children}
    </NextLink>
  );
}

Link.propTypes = {
  as: PropTypes.string,
  href: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,

};

Link.defaultProps = {
  as: undefined,
};
