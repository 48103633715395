import React, { useContext, useState } from "react";
import { useFormik } from 'formik';
import { observer } from "mobx-react-lite";
import { State, Discussion, MessageController } from "@oward/openapi";
import { Section, Divider, SubmitOrCancel, NavigationContext } from '@components/admin';
import { Heading, OwardFormInput, OwardFormSelect } from '@components/Core';
import { EditProps } from '../types';
import { stateToEmoji } from '@oward/common-utils';
import { MessageList } from "./MessageList";
import { DiscussionType } from "@oward/common-enums";
import { DiscussionMemberList } from "./DiscussionMembersList";

export const EditDiscussion = observer((props: EditProps<Discussion>) => {
  const [error, setError] = useState(false);
  const navigation = useContext(NavigationContext);

  const formik = useFormik({
    initialValues: {
      state: props.item.state,
      type: props.item.type,
      name: props.item.name,
    },
    onSubmit: async values => {
      props.item.state = values.state;
      props.item.type = values.type;
      props.item.name = values.name;
      try {
        await MessageController.saveOneDiscussionAdmin(props.item);
        if (props.notify) { props.notify(props.item) };
        setError(false);
        navigation.unpop();
      } catch {
        setError(true);
      }
    }
  });

  return (
    <React.Fragment>
      <Section
        title='Edit'
        subtitle='Change your entity properties'
        childrenFullWidth
      >
        <form className='box' onSubmit={formik.handleSubmit} >
          <Heading>General</Heading>
          <OwardFormSelect
            id='state'
            label='State *'
            options={Object.values(State).map((state) => {
              return ({ value: state, label: state.concat(' ', stateToEmoji(state)) });
            })}
            formik={formik}
          />
          <OwardFormSelect
            id='type'
            label='Type *'
            options={Object.values(DiscussionType).map((type) => {
              return ({ value: type, label: type });
            })}
            formik={formik}
          />
          {
            (formik.values['type'] as DiscussionType) !== DiscussionType.ONETOONE &&
            <OwardFormInput
              id='name'
              label='Name (only applicable for "private_group" and "public_group" discussions)'
              formik={formik}
            />
          }
          <SubmitOrCancel error={error} noCancel={props.noCancel} />
        </form >
      </Section >
      <Divider />
      <MessageList discussionId={props.item.id} />
      <Divider />
      <DiscussionMemberList discussionId={props.item.id} discussionType={props.item.type} />
    </React.Fragment>
  )
});
