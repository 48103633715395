import React from 'react';
import styles from './EditProfile.module.scss';
import { useInjection, useTranslate } from '@hooks/.';
import { PopupStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import {
  EditProfilePannelProps, OwardFormTextArea, OwardInsertGreen, OwardInsertYellow, ToastError, ToastSucess
} from '@components/.';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import shallowequal from 'shallowequal';
import { Biography, BiographyType, ProfileController, ProfileSaveStatus } from '@oward/openapi';
import { toast } from 'react-toastify';
import { EditProfileHeader, EditProfileSaveOrCancel } from './EditProfileCommon';
import { ERR_MSG_UNAUTHORIZED, goToHomePageAndReload, SHORT_BIO_CHAR_LIMIT } from '@utils/.';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { action } from 'mobx';


const schema = (t: any) => {
  return (
    Yup.object().shape({
      bioShortPublicFr: Yup.string()
        .max(SHORT_BIO_CHAR_LIMIT, t('forms.short_bio.too_long')),
    }))
};

export const EditProfileStatus: React.FC<EditProfilePannelProps> = props => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);

  const findPublicFrBio = (bios: Biography[]): Biography => {
    return bios.find(bio => bio.locale === 'fr' && bio.type === BiographyType.PUBLIC);
  }

  const formik = useFormik({
    initialValues: {
      bioShortPublicFr: findPublicFrBio(props.store.profile.bios)?.short,
      bioLongPublicFr: findPublicFrBio(props.store.profile.bios)?.long,
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      if (!shallowequal(formik.initialValues, formik.values)) {
        let profileSaveStatus: ProfileSaveStatus = {
          id: props.store.profile.id,
          bioShortPublicFr: values.bioShortPublicFr,
          bioLongPublicFr: values.bioLongPublicFr,
        }
        try {
          props.store.setLoading(t('edit_profile.loading.saving_profile'));
          await ProfileController.saveStatus(profileSaveStatus);
          toast.dark(<ToastSucess msg={t('edit_profile.saved')} />);
        }
        catch (err) {
          if (err instanceof Error && err.message === ERR_MSG_UNAUTHORIZED) {
            popupStore.openInformationPopUp({
              msg: t('global.force_logout'),
              callback: () => { goToHomePageAndReload() }
            });
          }
          else {
            toast.dark(<ToastError msg={t('edit_profile.error.saving_profile', { code: err })} />);
          }
        }
        finally {
          props.store.setLoading(undefined);
        }
      }
      else {
        toast.dark(<ToastSucess msg={t('edit_profile.saved_no_modif')} />);
      }

      props.store.reset();
    }
  });


  const resetWithConfirmation = () => {
    if (!shallowequal(formik.initialValues, formik.values)) {
      popupStore.openConfirmationPopUp({
        msg: t('edit_profile.go_back_confirm'),
        callback: props.store.reset
      });
    }
    else {
      props.store.reset();
    }
  }

  return (
    <div className={styles.formMainContainer}>
      <div className={classNames(styles.contentContainer)}>
        <EditProfileHeader
          onGoBack={resetWithConfirmation}
          title={t('edit_profile.' + props.store.activeCategory + '.title', { name: props.store.profile?.name })}
        />
        <form className={classNames(styles.formContentContainer)} onSubmit={formik.handleSubmit} >
          <OwardFormTextArea
            id='bioShortPublicFr'
            label={t('edit_profile.status.shortBio')}
            description={t('edit_profile.status.shortBio_limit', {
              count: formik.values.bioShortPublicFr?.length ?? 0,
              maxCount: SHORT_BIO_CHAR_LIMIT
            })}
            descriptionColor={formik.values.bioShortPublicFr?.length > SHORT_BIO_CHAR_LIMIT && 'has-text-danger'}
            onChange={action(value => { findPublicFrBio(props.store.profile.bios).short = value })} // For Preview
            formik={formik}
            rows={3}
          />
          <OwardFormTextArea
            id='bioLongPublicFr'
            label={t('edit_profile.status.longBio')}
            description={t('edit_profile.status.longBio_description')}
            onChange={action(value => { findPublicFrBio(props.store.profile.bios).long = value })} // For Preview
            formik={formik}
            rows={13}
          />
          <OwardInsertGreen title={t('edit_profile.status.hashtag.title')} >
            <p>
              {t('edit_profile.status.hashtag.desc_1')}
              <span style={{ color: styles.blue }}>{t('edit_profile.status.hashtag.desc_2')}</span>
              {t('edit_profile.status.hashtag.desc_3')}
            </p>
          </OwardInsertGreen>
        </form>
      </div>
      <form onSubmit={formik.handleSubmit} className={styles.formFooterContainer}>
        <EditProfileSaveOrCancel onCancel={resetWithConfirmation} formik={formik} />
      </form>
    </div >
  );
}
