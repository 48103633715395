import React from 'react'
import { Field, Control, OwardButton } from "@components/Core"
import { useTranslate } from '@hooks/.';

interface SubmitProps {
  name?: string;
  loading?: boolean;
  error?: boolean;
}

export const Submit = (props: SubmitProps) => {
  const { t } = useTranslate();

  return (
    <Field kind="group" align="right">
      <Control>
        <OwardButton
          name={props.name ? props.name : t('global.validate')}
          submit
        />
      </Control>
    </Field>
  )
}
