import React from 'react';

import { FilmTypeStatistics } from './FilmTypeStatistics';
import { Divider } from '@components/admin';
import { FilmTypeList } from './FilmTypeList';

export const FilmTypesComponent: React.FC = () => {
  return (
    <React.Fragment>
      <FilmTypeStatistics />
      <Divider />
      <FilmTypeList />
    </React.Fragment>
  );
}
