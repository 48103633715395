import { StoresBindings } from '@container/.';
import { useInjection, useTranslate } from '@hooks/.';
import { UserStore, GdprPrefs, ModalKey, ModalStore } from '@stores/.';
import styles from './GDPR.module.scss';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { COOKIE_NAME_GDPR_PREFS } from '@utils/.';
import { useRouter } from 'next/router';
import { GdprModal } from './GDPRModal';
import { OwardButton } from '@components/Core';

export const GDPR: React.FC = observer(() => {
  const { t } = useTranslate();
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  // On Mobile webview, don't want to show Cookie Prompt ; act like this as default
  const [allowToShowCookiePrompt, setAllowToShowCookiePrompt] = useState(false);

  useEffect(() => {
    // @ts-ignore
    if (!window?.ReactNativeWebView) {
      setAllowToShowCookiePrompt(true);
    }

    let gdprCookieStr: string = document.cookie?.split('; ')?.find(row => row.startsWith(COOKIE_NAME_GDPR_PREFS));
    if (gdprCookieStr !== undefined) {
      let gdprPrefs: GdprPrefs = JSON.parse(gdprCookieStr.split('=')[1]);
      userStore.setGdprPrefs(gdprPrefs);
    }

    // On mobile Apps, accept by default all cookies
    // @ts-ignore
    if (gdprCookieStr === undefined && window?.ReactNativeWebView) {
      userStore.setGdprPrefs({
        functional: true,
        analysis: true,
        youtube: true,
        arte: true,
        facebook: true,
        soundcloud: true,
        vimeo: true
      }, true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <GdprModal />
      {
        (userStore.gdprPrefs === undefined && allowToShowCookiePrompt) &&
        <div className={styles.mainContainer}>
          <div className={styles.shadowContainer}>
          </div>
          <div className={styles.firstPanel}>
            <div className={styles.firstPanelContainer}>
              <div className={styles.firstPanelTextContainer}>
                <p className={styles.firstPanelTextTitle}>{t('gdpr.title')}</p>
                <p className={styles.firstPanelText}>{t('gdpr.description')}</p>
              </div>
              <div className={styles.firstPanelButtonContainer}>
                <OwardButton name={t('gdpr.modify_params')} outlined dark onClick={() => {
                  modalStore.openModalNewStack(router, ModalKey.GDPR_PREFS, 1);
                }} />
                <OwardButton name={t('gdpr.accept')} onClick={() => {
                  let prefs: GdprPrefs = { functional: true, analysis: true, youtube: true, vimeo: true, facebook: true, arte: true, soundcloud: true };
                  userStore.setGdprPrefs(prefs, true);
                }} />
              </div>
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  )
})
