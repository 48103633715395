import Head from 'next/head';
import { Fragment } from 'react';
import { useInjection, useTranslate } from '@hooks/.';
import { UserStore, CardStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { GA_TRACKING_ID, GOOGLE_OPTIMIZE } from '@utils/.'
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';

interface HtmlHeadPros {
  title?: string;
  description?: string;
  image?: string;
  noIndex?: boolean;
}

export const HtmlHead = observer((props: HtmlHeadPros) => {

  const { locale, t } = useTranslate();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const cardStore = useInjection<CardStore>(StoresBindings.CARD);

  const title = props.title || t('header.title');
  const description = props.description || t('header.description');
  const image = props.image || 'https://cdn-oward-common-images.oward.co/card_grid.png';
  const router = useRouter();

  const addSeoTags = () => {
    const canonicalUrlBase: string = `https://www.oward.co`;
    const canonicalUrlProfile: string = canonicalUrlBase + `/${router.query.lang}/p/${router.query.profilePath}`;
    const maxCardNoIndex: number = Number(process.env.INDEXATION_MINIMUM_PROFILE_COUNT);

    if (
      (props.noIndex) ||
      (router.query.filter && (cardStore.totalCardCount < maxCardNoIndex))
    ) {
      return <meta name="googlebot" content="noindex" />
    }
    if (router.route === '/[lang]/p/[profilePath]') {
      return <link rel="canonical" href={canonicalUrlProfile} />
    }
    if (router.asPath === `/${router.query.lang}`) {
      return <link rel="canonical" href={canonicalUrlBase} />
    }
  }

  return (
    <Head>
      <title>{title}</title>
      <meta charSet='UTF-8' />
      <meta name='description' content={description} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={image} />
      <meta httpEquiv='content-language' content={locale} />
      {
        addSeoTags()
      }
      {/* Global Site Tag (gtag.js) - Google Analytics */
        (process.env.GA_TRACKING_ID && userStore.gdprPrefs?.analysis) &&
        <Fragment>
          <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />
          <script
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${GA_TRACKING_ID}');
                ${process.env.GOOGLE_TAG_MANAGER_TRACKING_ID ? `gtag('config', '${process.env.GOOGLE_TAG_MANAGER_TRACKING_ID}');` : ''}
              `,
            }}
          />
        </Fragment>
      }
      {/* Google Tag Manager */
        (process.env.GOOGLE_TAG_MANAGER_ID && userStore.gdprPrefs?.analysis) &&
        <Fragment>
          <script
            dangerouslySetInnerHTML={{
              __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${process.env.GOOGLE_TAG_MANAGER_ID}');
              `,
            }}
          />
        </Fragment>
      }
      {/* Google Optimize */
        (process.env.GOOGLE_OPTIMIZE && userStore.gdprPrefs?.analysis) &&
        <Fragment>
          <script async src={`https://www.googleoptimize.com/optimize.js?id=${GOOGLE_OPTIMIZE}`} />
        </Fragment>
      }
      {/* Hubspot */
        (process.env.HUBSPOT_TRACKING_ID && userStore.gdprPrefs?.analysis) &&
        <Fragment>
          <script
            src={`//js-eu1.hs-scripts.com/${process.env.HUBSPOT_TRACKING_ID}.js`}
            type="text/javascript" id="hs-script-loader" async defer
          />
        </Fragment>
      }
      {/* Facebook Pixel */
        (process.env.FACEBOOK_PIXEL_ID && userStore.gdprPrefs?.facebook) &&
        <Fragment>
          <script
            dangerouslySetInnerHTML={{
              __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${process.env.FACEBOOK_PIXEL_ID}');
              fbq('track', 'PageView');
              `,
            }}
          />
          <noscript>
            <img
              height='1' width='1' style={{ display: 'none' }} alt=''
              src={`https://www.facebook.com/tr?id=${process.env.FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1`}
            />
          </noscript>
        </Fragment>
      }
    </Head>
  );
})

export default HtmlHead;
