import React from 'react';
import styles from './Toast.module.scss';
import { ToastContainer, Slide } from 'react-toastify';
import { useTranslate } from '@hooks/.';


export const ToastCloseButton = ({ closeToast }) => (
  <p className={styles.closeButtonContainer} onClick={closeToast} >
    <span className={`icon`}>
      <i className={`fas fa-times ${styles.closeButton}`}></i>
    </span>
  </p>
);

export const OwardToastContainer: React.FC = () => {
  return (
    <ToastContainer
      position='bottom-left'
      hideProgressBar
      pauseOnHover
      pauseOnFocusLoss
      draggable={false}
      autoClose={3500}
      transition={Slide}
      closeOnClick={false}
      closeButton={ToastCloseButton}
    />
  );
}

interface ToastProps {
  msg?: string;
  children?: React.ReactNode;
}

export const ToastSucess: React.FC<ToastProps> = props => {
  return (
    <div className={styles.contentContainer}>
      <p className={styles.iconContainer}>
        <span className={`icon`}>
          <i className={`fas fa-check-circle ${styles.iconSuccess}`}></i>
        </span>
      </p>
      {
        props.msg &&
        <p className={styles.textContainer}>
          {props.msg}
        </p>
      }
      {
        props.children &&
        <div className={styles.textContainer}>
          {props.children}
        </div>
      }
    </div >
  );
}

export const ToastError: React.FC<ToastProps> = props => {
  const { t } = useTranslate();

  return (
    <div className={styles.contentContainer}>
      <p className={styles.iconContainer}>
        <span className={`icon`}>
          <i className={`fas fa-exclamation-circle ${styles.iconError}`}></i>
        </span>
      </p>
      {
        props.msg &&
        <p className={styles.textContainer}>
          {props.msg}
        </p>
      }
      {
        props.children &&
        <div className={styles.textContainer}>
          {props.children}
        </div>
      }
    </div >
  );
}
