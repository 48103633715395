import React from 'react';
import styles from './Register.module.scss';
import RegisterForm from './RegisterForm';
import { useTranslate } from '@hooks/.';
import { LayoutPageImageOnLeft } from '@components/Core';

export const RegisterPage = () => {
  const { t } = useTranslate();

  return (
    <React.Fragment>
      <LayoutPageImageOnLeft
        imageComponent={<RegisterImage />}
        rightComponent={<RegisterForm />}
      />
    </React.Fragment >
  );
}

const RegisterImage = () => {
  const { t } = useTranslate();

  return (
    <div className={styles.imageContainer}>
      <p className={styles.text}>{t('login.register_image_text')}</p>
      <p className={`${styles.credits}`}>
        <a
          className={`${styles.link}`}
          href='https://www.oward.co/fr/p/fulesaulnieretienne'
          target='_blank'
          rel='noreferrer'
        >
          @FuLeSaulnierEtienne
        </a>
      </p>
    </div>
  )
}
