import React from 'react';
import styles from './AccordionHashtags.module.scss';
import { useInjection, useTranslate } from "@hooks/.";
import { StoresBindings } from '@container/.';
import { FilterStore } from '../../stores';
import { useRouter } from 'next/router';
import { OwardLoader, OwardSearchInput } from '@components/Core';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { SidebarSearchExplanation } from './AccordionContent';

interface HashtagProps {
  name: string;
}
const Hashtag: React.FC<HashtagProps> = observer(props => {
  const router = useRouter();
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);

  let hashtagName: string = props.name.toLowerCase();

  const isHashtagActive = () => {
    if (filterStore.filter?.hashtag?.names) {
      return filterStore.filter.hashtag?.names.indexOf(hashtagName) !== -1;
    }
    return false;
  }

  return (
    <div
      className={classNames(styles.hashtagContainer, { [styles.active]: isHashtagActive() })}
      onClick={() => {
        filterStore.hashtagSearch = '';
        filterStore.setHashtagFilter(router, hashtagName, isHashtagActive());
      }}
    >
      <p>#{props.name}</p>
    </div>
  );
});

const Hashtags: React.FC = observer(() => {
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);
  const { t } = useTranslate();
  let msg: string = t('sidebar.search_hashtag.no_options');
  if (filterStore.hashtagError) {
    msg = t('sidebar.error');
  }

  return (
    <div className={classNames(styles.hashtagContainer, { [styles.loading]: filterStore.hashtagLoading })}>
      <OwardLoader loading={filterStore.hashtagLoading} positionAbsolute />
      {(filterStore.displayedHashtags.length === 0 || filterStore.hashtagError)
        ?
        <p className={styles.msg}>
          {filterStore.hashtagFirstReq ? '' : msg}
        </p>
        :
        <div className={styles.gridContainer}>
          {
            filterStore.displayedHashtags.map((hashtag: string, i: number) => {
              return <Hashtag name={hashtag} key={i} />;
            })
          }
        </div>
      }
    </div >
  );
});

export const AccordionHashtags: React.FC = observer(() => {
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);
  const { t } = useTranslate();

  return (
    <div className={styles.mainContainer}>
      <SidebarSearchExplanation text={t('sidebar.search_hashtag.hint_message')} />
      <div className={styles.searchContainer}>
        <OwardSearchInput
          placeholder={t('sidebar.search_hashtag.search_placeholder')}
          value={filterStore.hashtagSearch}
          onChange={value => {
            filterStore.setHashtagSearch(value);
            filterStore.getDisplayedHashtags(filterStore.filter);
          }}
        />
        <div
          className={classNames('has-tooltip-arrow', 'has-tooltip-left', `${styles.reloadContainer}`)}
          data-tooltip={t('sidebar.search_hashtag.reload')}
          onClick={() => { filterStore.getDisplayedHashtags(filterStore.filter) }}
        >
          <span className={classNames('icon')} >
            <i className={'fas fa-sync-alt'}></i>
          </span>
        </div>
      </div>
      <Hashtags />
    </div>
  );
});

export default AccordionHashtags;
