import React, { useState, useEffect } from 'react';
import { send, MessageController, Message } from '@oward/openapi';
import { PaginatedList, PaginatedListKey, Section, } from '@components/admin';
import { profileToStrWithId, strToPath, stateToEmoji } from '@oward/common-utils';
import moment from 'moment';
import { EditMessage } from './EditMessage';

interface MessageListProps {
  discussionId: number;
}

export const MessageList: React.FC<MessageListProps> = props => {
  const [items, setItems] = useState([] as Message[]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadItems = async () => {
    try {
      setLoading(true);
      setError(false);
      setItems(await send(MessageController.listMessagesOfDiscussion(props.discussionId)))
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const getLastSeenStr = (message: Message): string => {
    let lastSeenNames = '';

    message.messageLastSeens.map(lastSeen => {
      lastSeenNames += profileToStrWithId(lastSeen.profile)
    });

    return lastSeenNames;
  }

  const keys: PaginatedListKey<Message>[] = [
    {
      label: 'Id',
      value: item => item.id + ' ' + stateToEmoji(item.state),
      sortValue: item => item.id
    },
    {
      label: 'Sender Profile',
      value: item => profileToStrWithId(item.senderProfile)
    },
    {
      label: 'Content',
      value: item => item?.text || item?.url
    },
    {
      label: 'Date',
      value: item => moment(item.creationDate).format('lll'),
      sortValue: item => Date.parse(item.creationDate)
    },
    {
      label: 'Last Seen',
      value: item => getLastSeenStr(item)
    }
  ];

  const filterItemStrings = (item: Message) => [
    item.id.toString(),
    strToPath(item.text)
  ]

  const fetchOne = (item: Message) => send(MessageController.findOneMessage(item.id));

  return (
    <React.Fragment>
      <Section
        title='Messages'
        subtitle='List of all messages in the discussion'
        childrenFullWidth
      >
        <PaginatedList
          keys={keys}
          items={items}
          filterItemStrings={filterItemStrings}
          editComponent={EditMessage}
          fetchOne={fetchOne}
          size={20}
          reloadList={loadItems}
          loading={loading}
          error={error}
        />
      </Section>
      <div style={{ height: '2rem' }} />
    </React.Fragment>
  );
}
