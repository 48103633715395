import { makeAutoObservable } from 'mobx';
import { injectable } from 'inversify';

export enum PopUpType {
  INFORMATION = 'information',
  CONFIRMATION = 'confirmation',
  GENERIC = 'generic',
}

interface PopUpElements {
  msg: string;
  title?: string;
  callback?: () => any;
}

@injectable()
export class PopupStore {
  constructor() {
    makeAutoObservable(this);
  }

  popUpOpened: boolean = false;
  popUpType: PopUpType = undefined;
  popUpTitle: string = undefined;
  popUpMsg: string = '';
  popUpCallback: () => void;
  popUpContent: React.ReactNode;

  openConfirmationPopUp(popUpElements: string | PopUpElements) {
    this.popUpType = PopUpType.CONFIRMATION;
    if (typeof popUpElements === 'string') {
      this.popUpMsg = popUpElements;
      this.popUpTitle = undefined;
      this.popUpCallback = undefined;
    }
    else {
      this.popUpMsg = popUpElements.msg;
      this.popUpTitle = popUpElements.title;
      this.popUpCallback = popUpElements.callback;
    }
    this.popUpOpened = true;
  }

  openInformationPopUp(popUpElements: string | PopUpElements) {
    this.popUpType = PopUpType.INFORMATION;
    if (typeof popUpElements === 'string') {
      this.popUpMsg = popUpElements;
      this.popUpTitle = undefined;
      this.popUpCallback = undefined;
    }
    else {
      this.popUpMsg = popUpElements.msg;
      this.popUpTitle = popUpElements.title;
      this.popUpCallback = popUpElements.callback;
    }
    this.popUpOpened = true;
  }

  openGenericPopUp(popUpContent: React.ReactNode) {
    this.popUpType = PopUpType.GENERIC;
    this.popUpContent = popUpContent;
    this.popUpOpened = true;
  }

  closePopUp() {
    this.popUpOpened = false;
  }
}
