import React, { useEffect, useState } from 'react';
import styles from './ModalCommon.module.scss';
import { observer } from 'mobx-react-lite';
import { UserStore, ModalKey, ModalStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { useInjection, useTranslate } from '@hooks/.';
import {
  OwardFormInput, NeedConnectionModal, Modal, ModalConfirmOrCancelButtons,
  ModalTitle, OwardFormSelectLoad, OwardLoader, ToastSucess
} from '@components/.';
import { useRouter } from 'next/router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BroadcasterController, NewBroadcaster } from '@oward/openapi';
import { ERR_MSG_CONFLICT } from '@utils/constants';
import { toast } from 'react-toastify';

const schema = (t: any) => {
  return (
    Yup.object().shape({
      name: Yup.string().required(t('forms.required')),
      category: Yup.object().required(t('forms.required')),
    }))
};

export const NewBroadcasterModal: React.FC = observer(() => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  const [error, setError] = useState<string>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    // Reset form at each modal opening
    formik.resetForm();
    setError(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query[ModalKey.NEW_BROADCASTER] as string]);

  const formik = useFormik({
    initialValues: {
      name: '',
      category: undefined,
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      try {
        let newBroadcaster: NewBroadcaster = {
          name: values.name,
          category: values.category,
        };
        setLoading(true);
        await BroadcasterController.create(newBroadcaster);
        setError(undefined);
        toast.dark(<ToastSucess msg={t('modal.broadcaster.created', { name: values.name })} />);
        modalStore.unpopModal(router);
      } catch (err) {
        if (err instanceof Error && err.message === ERR_MSG_CONFLICT) {
          setError(t('modal.broadcaster.error_conflict', { name: values.name }));
        }
        else {
          setError(t('global.error_retry'));
        }
      }
      finally {
        setLoading(false);
      }
    }
  });

  return (
    <React.Fragment>
      {
        userStore.isLogged ?
          <Modal
            modalKey={ModalKey.NEW_BROADCASTER}
            header={<ModalTitle name={t('modal.broadcaster.new_title')} />}
            footer={
              <form onSubmit={formik.handleSubmit} >
                <ModalConfirmOrCancelButtons confirmTxt={t('global.create')} error={error} />
              </form>
            }
          >
            <form onSubmit={formik.handleSubmit} className={styles.mainContainer}>
              <OwardLoader loading={loading} />
              <div style={{ padding: '0 0.1rem' }}>
                <OwardFormSelectLoad
                  id='category'
                  label={t('modal.broadcaster.category')}
                  fetchFunction={BroadcasterController.listCategories(locale)}
                  isNotClearable
                  formik={formik}
                />
                <OwardFormInput
                  id='name'
                  label={t('forms.name.label')}
                  formik={formik}
                />
                <div style={{ height: '8rem' }} />
              </div>
            </form>
          </Modal >
          :
          <Modal modalKey={ModalKey.NEW_BROADCASTER}>
            <NeedConnectionModal headerMessage={t('modal.broadcaster.need_connection')} />
          </Modal>
      }
    </React.Fragment>
  );
});
