import React, { useEffect, useState } from 'react';
//import styles from './NewShortlistModal.module.scss';
import { observer } from 'mobx-react-lite';
import { UserStore, ModalKey, ModalStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { useInjection, useTranslate } from '@hooks/.';
import {
  OwardFormInput, NeedConnectionModal, Modal, ModalConfirmOrCancelButtons,
  ModalTitle, OwardFormSelectLoad, OwardLoader, ToastSucess, filmToOptions
} from '@components/.';
import { useRouter } from 'next/router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ArtworkController, FilmsController, NewArtwork, NewArtworkNewFilm, send } from '@oward/openapi';
import { toast } from 'react-toastify';
import { filmToStr, URL_VALIDATION_REGEX } from '@utils/.';
import { entitiesToOptionsWithTranslation } from '@components/Core';
import { OwardSwitchMultiple } from '@components/Core/Switch/Switch';

const schema = (t: any) => {
  return (
    Yup.object().shape({
      film: Yup.object().required(t('forms.required')),
      url: Yup.string().required(t('forms.required')).matches(URL_VALIDATION_REGEX, t('forms.url.not_valid')),
    }))
};

const schemaNewFilm = (t: any) => {
  return (
    Yup.object().shape({
      name: Yup.string().required(t('forms.required')),
      type: Yup.object().required(t('forms.required')),
      releaseYear: Yup.number().required(t('forms.required')).max(2050, t('forms.year_valid')).min(0, t('forms.year_valid')),
      directorName: Yup.string().required(t('forms.required')),
      url: Yup.string().required(t('forms.required')).matches(URL_VALIDATION_REGEX, t('forms.url.not_valid')),
    }))
};


export const NewArtworkModal: React.FC = observer(() => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const [newFilmForm, setNewFilmForm] = useState(false);

  const [error, setError] = useState<string>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    // Go back to "film already existing" each time we open the modal
    setNewFilmForm(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query[ModalKey.NEW_ARTWORK] as string]);

  const formik = useFormik({
    initialValues: {
      film: undefined,
      url: '',
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      try {
        let newArtwork: NewArtwork = {
          profileId: parseInt(router.query[ModalKey.NEW_ARTWORK] as string),
          film: values.film,
          url: values.url,
        };
        setLoading(true);
        modalStore.editProfileLocallStore.editArtwork = await send(ArtworkController.createOne(newArtwork));
        setError(undefined);
        toast.dark(<ToastSucess msg={t('modal.artwork.created', { name: values.film.name })} />);
        formik.resetForm();
        modalStore.unpopModal(router);
      } catch (err) {
        setError(t('global.error_retry'));
      }
      finally {
        setLoading(false);
      }
    }
  });

  const formikNewFilm = useFormik({
    initialValues: {
      name: '',
      url: '',
      type: undefined,
      directorName: '',
      releaseYear: '' as unknown as number, // see https://github.com/formium/formik/issues/321#issuecomment-478364302
    },
    validationSchema: schemaNewFilm(t),
    onSubmit: async values => {
      try {
        let newArtwork: NewArtworkNewFilm = {
          profileId: parseInt(router.query[ModalKey.NEW_ARTWORK] as string),
          name: values.name,
          url: values.url,
          type: values.type,
          directorName: values.directorName,
          releaseYear: values.releaseYear,
        };
        setLoading(true);
        modalStore.editProfileLocallStore.editArtwork = await send(ArtworkController.createOneWithFilm(newArtwork));
        setError(undefined);
        toast.dark(<ToastSucess msg={t('modal.artwork.created', { name: values.name })} />);
        formikNewFilm.resetForm();
        setNewFilmForm(false);
        modalStore.unpopModal(router);
      } catch (err) {
        setError(t('global.error_retry'));
      }
      finally {
        setLoading(false);
      }
    }
  });

  return (
    <React.Fragment>
      {
        userStore.isLogged ?
          <Modal
            modalKey={ModalKey.NEW_ARTWORK}
            fixedSize
            header={<ModalTitle name={t('modal.artwork.new_title')} />}
            footer={
              <form onSubmit={newFilmForm ? formikNewFilm.handleSubmit : formik.handleSubmit} >
                <ModalConfirmOrCancelButtons confirmTxt={t('global.add')} error={error} />
              </form>
            }
          >
            <div style={{ position: 'relative', padding: '0 0.1rem' }}>
              <OwardLoader loading={loading} />
              <p style={{ whiteSpace: 'pre-wrap' }}>{t('modal.artwork.explanation')}</p>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '1rem' }}>
                <OwardSwitchMultiple
                  options={[{ value: '1', label: t('modal.artwork.film_already') }, { value: '2', label: t('modal.artwork.film_new') }]}
                  onChange={(option) => { setNewFilmForm(option.value === '2') }}
                  activeOption={newFilmForm === true ? '2' : '1'}
                />
              </div>
              <hr />
              {
                !newFilmForm ?
                  <form onSubmit={formik.handleSubmit}>
                    <OwardFormSelectLoad
                      id='film'
                      label='Film *'
                      description={t('modal.artwork.film_desc')}
                      noOptionMsg={t('modal.artwork.film_no_option')}
                      isNotClearable
                      fetchFunction={FilmsController.find(locale)}
                      entitiesToOptions={(entities) => { return filmToOptions(entities, t('wip.film')) }}
                      entityToStr={(film) => { return filmToStr(film, t('wip.film')) }}
                      formik={formik}
                    />
                    <OwardFormInput
                      id='url'
                      type='url'
                      label={t('edit_artwork.link') + ' *'}
                      description={t('edit_artwork.link_explanation')}
                      formik={formik}
                    />
                    <div style={{ height: '17rem' }}></div>
                  </form>
                  :
                  <form onSubmit={formikNewFilm.handleSubmit} >
                    <OwardFormInput
                      id='name'
                      label={t('edit_film.name') + ' *'}
                      formik={formikNewFilm}
                    />
                    <OwardFormInput
                      id='url'
                      type='url'
                      label={t('edit_artwork.link') + ' *'}
                      description={t('edit_artwork.link_explanation')}
                      formik={formikNewFilm}
                    />
                    <OwardFormSelectLoad
                      id='type'
                      label={t('edit_film.type') + ' *'}
                      fetchFunction={FilmsController.findTypes()}
                      entitiesToOptions={entities => entitiesToOptionsWithTranslation(entities, locale)}
                      formik={formikNewFilm}
                    />
                    <OwardFormInput
                      id='releaseYear'
                      type='number'
                      label={t('edit_film.year') + ' *'}
                      description={t('edit_film.year_desc')}
                      formik={formikNewFilm}
                    />
                    <OwardFormInput
                      id='directorName'
                      type='name'
                      label={t('edit_film.director') + ' *'}
                      description={t('edit_film.director_desc')}
                      placeholder={t('edit_film.director_placeholder')}
                      formik={formikNewFilm}
                    />
                    <div style={{ height: '5rem' }} />
                  </form>
              }
            </div>
          </Modal >
          :
          <Modal modalKey={ModalKey.NEW_ARTWORK}>
            <NeedConnectionModal headerMessage={t('modal.artwork.need_connection')} />
          </Modal>
      }
    </React.Fragment>
  );
});
