import { NextRouter } from "next/router";
import { makeAutoObservable, toJS } from 'mobx';
import { injectable, inject } from 'inversify';
import { LayoutStore } from '@stores/.';
import { StoresBindings, ApplicationBindings } from '@container/.';
import {
  FilterI, FilterItemI, FilterLabelI, FilterController,
  send, Request,
  JobsController,
  JobTranslation,
} from '@oward/openapi';
import {
  FilterType
} from '@oward/common-enums';
import { isFilterEmpty, pushWithFilterAndModal } from '@components/Navigation';
import { ERR_MSG_ABORTED, NUMBER_HASHTAG_LOADED, onTouchDevice, ORDER_INIT_RANDOM } from "@utils/.";
import { GalleryType } from "@oward/common-utils";

@injectable()
export class FilterStore {
  constructor() {
    makeAutoObservable(this);
  }

  @inject(ApplicationBindings.LOCALE) private locale: string;
  @inject(StoresBindings.LAYOUT) private layoutStore: LayoutStore;

  filter: FilterI = {};
  label: FilterLabelI = {};
  openSearch: string = '';
  isRandom: boolean = ORDER_INIT_RANDOM;

  hashtagSearch: string = '';
  hashtagLoading: boolean = false;
  hashtagError: boolean = false;
  hashtagPanelActive: boolean = false;
  hashtagFirstReq: boolean = true;
  displayedHashtags: string[] = [];
  // Keep a ref of the last getDisplayedHashthag request, to abort it if a new one is sent
  getDisplayedHashthagReq: Request<string[]> = null;

  init = (filter: FilterI, label: FilterLabelI) => {
    this.filter = filter;
    this.label = label;
    this.openSearch = '';
  }

  setFilter = (router: NextRouter, type: FilterType, item: FilterItemI, labelArg: string, resetFilters?: boolean) => {
    let filter, label;
    if (resetFilters) {
      [filter, label] = [{}, {}];
    }
    else {
      [filter, label] = [toJS(this.filter), toJS(this.label)];
    }

    filter[type] = item;
    label[type] = labelArg;

    this.commonFilterAction(router, type, filter, label);
  }

  setHashtagFilter = (router: NextRouter, hashtagName: string, isRemoving: boolean = false) => {
    const [filter, label] = [toJS(this.filter), toJS(this.label)];

    let hashtags: string[] =
      (filter.hashtag === undefined || filter.hashtag.names === undefined) ?
        new Array<string>() :
        filter.hashtag.names;

    if (isRemoving) {
      const index = hashtags.indexOf(hashtagName);
      if (index > -1) {
        hashtags.splice(index, 1);
      }
    }
    else {
      if (hashtags.includes(hashtagName)) {
        return; // A filter for this hashtag is already active
      }
      hashtags.push(hashtagName);
    }

    filter.hashtag = { names: hashtags };

    this.commonFilterAction(router, FilterType.HASHTAG, filter, label);
  }

  commonFilterAction = (router: NextRouter, type: FilterType, filter: FilterI, label: FilterLabelI) => {
    if (type === FilterType.GALLERY_TYPE) {
    }
    else if (type === FilterType.SHORTLIST || this.filter.shortlist || isFilterEmpty(filter, true)) {
      this.layoutStore.scrollToGalleryTop();
    }
    else {
      this.layoutStore.scrollToActiveFilters();
    }
    if (onTouchDevice()) {
      this.layoutStore.openSidebar(false);
    }
    pushWithFilterAndModal(router, this.locale, filter, label);
    this.getDisplayedHashtags(filter);
  }

  resetFilters = () => {
    this.filter = {};
    this.label = {};
  }

  setFilterLabel = (filterLabel: FilterLabelI) => {
    this.label = filterLabel;
  }

  setOpenSearch = (openSearch: string) => {
    this.openSearch = openSearch;
  }

  setHashtagSearch = (hashtagSearch: string) => {
    this.hashtagSearch = hashtagSearch;
  }

  getDisplayedHashtags = async (filter: FilterI): Promise<void> => {
    if (!this.hashtagPanelActive) {
      // Deal with hashtag only if the panel is opened
      return;
    }
    this.hashtagError = false;
    if (this.getDisplayedHashthagReq !== null) {
      this.getDisplayedHashthagReq.abort();
    }
    let req: Request<string[]> = FilterController.listHashtags(filter, this.hashtagSearch, NUMBER_HASHTAG_LOADED);
    try {
      this.hashtagLoading = true;
      this.getDisplayedHashthagReq = req;
      this.displayedHashtags = await send(req);
      this.hashtagLoading = false;
    }
    catch (err) {
      if (err instanceof Error && err.message === ERR_MSG_ABORTED) {
        // Do nothing, as a request aborted mean a new one is sent
      }
      else {
        this.hashtagError = true;
        this.hashtagLoading = false;
      }
    }
    finally {
      this.hashtagFirstReq = false;
    }
  }

  getJobPlurial = async (id: number): Promise<string> => {
    if (id === undefined) {
      return;
    }

    let jobTranslation: JobTranslation = await send(JobsController.getPlural(id, this.locale));
    return jobTranslation.name;
  }

  getFilterEntities = async (type: FilterType): Promise<any> => {
    switch (type) {
      case FilterType.JOB:
        return await send(FilterController.listJobs(this.locale, this.filter));
      case FilterType.JOB_CATEGORY:
        return await send(FilterController.listJobCategories(this.locale, this.filter));
      case FilterType.ASSOCIATION:
        return await send(FilterController.listAssociations(this.locale, this.filter));
      case FilterType.AGENT:
        return await send(FilterController.listAgents(this.locale, this.filter));
      case FilterType.LOCATION:
        return await send(FilterController.listLocations(this.locale, this.filter));
      case FilterType.LANGUAGE:
        return await send(FilterController.listLanguages(this.locale, this.filter));
      case FilterType.FILM_TYPE:
        return await send(FilterController.listFilmTypes(this.locale, this.filter));
      case FilterType.FILM_YEAR:
        return await send(FilterController.listFilmYears(this.filter));
      case FilterType.BROADCASTER:
        return await send(FilterController.listBroadcasters(this.locale, this.filter));
      case FilterType.BROADCASTER_CATEGORY:
        return await send(FilterController.listBroadcasterCategories(this.locale, this.filter));
      case FilterType.FESTIVAL:
        return await send(FilterController.listFestivals(this.filter));
      case FilterType.FESTIVAL_CATEGORY:
        return await send(FilterController.listFestivalCategories(this.locale, this.filter));
      case FilterType.FESTIVAL_COUNTRY:
        return await send(FilterController.listFestivalCountries(this.locale, this.filter));
      case FilterType.FESTIVAL_AWARD:
        return await send(FilterController.listFestivalAwards(this.locale, this.filter));
      case FilterType.FESTIVAL_AWARD_CATEGORY:
        return await send(FilterController.listFestivalAwardCategories(this.locale, this.filter));
      case FilterType.FESTIVAL_YEAR:
        return await send(FilterController.listFestivalYears(this.filter));
      default:
        return;
    }
  }

  setRandom = (isRandom: boolean) => {
    this.isRandom = isRandom;
  }

  get galleryType() {
    switch (this.filter?.gallery_type?.type) {
      case GalleryType.COMPANY:
        return GalleryType.COMPANY;
      case GalleryType.ARTWORK:
        return GalleryType.ARTWORK;
      default:
        return GalleryType.PRO;
    }
  }

  get isProfileGallery() {
    return this.galleryType === GalleryType.COMPANY || this.galleryType === GalleryType.PRO;
  }

  get isArtworkGallery() {
    return this.galleryType === GalleryType.ARTWORK;
  }
}
