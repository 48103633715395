import classNames from 'classnames';
import React from 'react';
import styles from './Layout.module.scss';

interface DividerProps {
  bigMargins?: boolean;
}

export const Divider: React.FC<DividerProps> = props => {
  return (
    <React.Fragment>
      <hr className={classNames(styles.divider, props.bigMargins && styles.bigMargin)} />
    </React.Fragment>
  )
}
