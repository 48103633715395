import React from 'react';
import styles from './ErrorPage.module.scss';
import { useTranslate } from '@hooks/.';
import { OwardButton } from '@components/Core';
import { useRouter } from 'next/router';

interface ErrorPageProps {
  errMsg?: string;
}

export const ErrorPage: React.FC<ErrorPageProps> = props => {

  return (
    <section className={`hero is-fullheight-with-navbar is-unselectable`}>
      <div className='hero-body'>
        <div className='container is-fluid has-text-centered'>
          <BigErrorMessage errMsg={props.errMsg} />
        </div >
      </div>
    </section>
  );
}

export const ErrorPageSimple: React.FC<ErrorPageProps> = props => {

  return (
    <section className={`hero is-fullheight-with-navbar is-unselectable`}>
      <div className='hero-body'>
        <div className='container is-fluid has-text-centered'>
          <p className={'has-text-black is-size-4 has-text-weight-light'}>
            {props.errMsg}
          </p>
        </div >
      </div>
    </section>
  );
}

interface ErrorPageGoToGalleryProps {
  icon?: string;
  errMsg?: string;
}

export const ErrorPageGoToGallery: React.FC<ErrorPageGoToGalleryProps> = props => {
  const { t } = useTranslate();
  const router = useRouter();

  return (
    <section className={`hero is-fullheight-with-navbar is-unselectable`}>
      <div className='hero-body'>
        <div className='container is-fluid has-text-centered'>
          {
            props.icon &&
            <p>
              <span className='icon is-large'>
                <i className={props.icon}></i>
              </span>
            </p>
          }
          <p className={'has-text-black is-size-4 has-text-weight-light'}>
            {props.errMsg}
          </p>

          <div className={styles.goToGalleryContainer}>
            <OwardButton
              name={t('shortlist.go_to_gallery')}
              onClick={() => { router.push(process.env.WEBSITE_URL); }}
              outlined
              bigPadding
            />
          </div>
        </div>
      </div >
    </section>
  );
}

export const BigErrorMessage: React.FC<ErrorPageProps> = props => {
  const { t } = useTranslate();

  return (
    <p className={'has-text-black is-size-4 has-text-weight-light'}>
      {
        props.errMsg ?
          t('global.error_with_code', { code: props.errMsg })
          :
          t('global.error_retry')
      }
      <br />
      <br />
      <span className='icon is-large'>
        <i className={'fas fa-2x fa-bug'}></i>
      </span>
      <br />
      <br />
      {t('global.error_contact')}
    </p>
  );
}

export default ErrorPage;
