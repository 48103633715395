import React, { useEffect, useRef, useState } from 'react';
import { StoresBindings } from '@container/.';
import { useInjection, useNumberOfDiscussionsUnread, useProfilesForUser, useTranslate } from '@hooks/.';
import { CardStore, FilterStore, LayoutStore, ModalKey, ModalStore, UserStore } from '@stores/.';
import { useRouter } from 'next/router';
import styles from './NavBar.module.scss';
import {
  goToHomePageAndReload,
  onGalleryPage,
  onPortfolioPage,
  onTouchDevice,
  oniOSWebview,
  remSizeToPixelNumber, sendMsgToReactNativeWebview,
  userToStr
} from '@utils/.';
import {
  GalleryType,
  REACT_NATIVE_SIGNAL_SIGNOUT,
  REACT_NATIVE_SIGNAL_PROFILES_COUNT,
  REACT_NATIVE_SIGNAL_PROFILE_PATH,
  REACT_NATIVE_SIGNAL_PROFILE_ID,
  REACT_NATIVE_SIGNAL_UNREAD_MESSAGES_COUNT,
  REACT_NATIVE_MESSAGE_NAVIGATION,
  ReactNativeMessage,
  REACT_NATIVE_MESSAGE_TYPE,
} from '@oward/common-utils';
import classNames from 'classnames';
import { Link, SwitchLanguage } from '@components/I18n';
import { Profile, UserController } from '@oward/openapi';
import { Badge, OwardButton, OwardLinkButton } from '..';
import { locales } from '@locales/.';
import { observer } from 'mobx-react-lite';
import FireIcon from '../../assets/icons/fire_white.svg';
import { useCallback } from 'react';
import { FilterType } from '@oward/common-enums';

export interface NavbarProps {
  justLogo: boolean;
}

export const NavBar: React.FC<NavbarProps> = observer(props => {
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);
  const [mobileDropdownOpened, openMobileDropdown] = useState<boolean>(false);

  // On 'touch' devices the user scrolls down, hide the navbar.
  // When the user scrolls up, show the navbar.
  //
  const navbarAppearOnScroll = ({ prevPos, currPos }) => {

    // Navbar height is in rem, but scroll position is in pixel
    let navbarPixelHeight: number = remSizeToPixelNumber(styles.navbarHeight);

    if (
      prevPos < currPos &&
      currPos > navbarPixelHeight &&
      onTouchDevice() &&
      !mobileDropdownOpened &&
      !layoutStore.sidebarOpened
    ) {
      layoutStore.displayNavbar(false);
    }
    else {
      layoutStore.displayNavbar(true);
    }
  }

  useScrollPosition(navbarAppearOnScroll, 100);

  function isMobileDropdownOpened(): string {
    return mobileDropdownOpened ? 'is-active' : '';
  }

  return (
    <React.Fragment>
      <nav id='navbar-desktop' className={`navbar is-fixed-top is-hidden-touch ${styles.navBar}`}>
        <NavBarDesktop justLogo={props.justLogo} />
      </nav>
      <nav id='navbar-mobile' className={`navbar is-fixed-top is-hidden-desktop ${styles.navBar}`}>
        <NavBarMobile
          justLogo={props.justLogo}
          isMobileDropdownOpened={isMobileDropdownOpened()}
          openMobileDropdown={openMobileDropdown}
        />
      </nav>
    </React.Fragment>
  )
});

interface NavBarMobileProps {
  isMobileDropdownOpened: string;
  openMobileDropdown: (open: boolean) => void;
  justLogo: boolean;
}

const NavBarMobile: React.FC<NavBarMobileProps> = observer(props => {
  const { locale, t } = useTranslate();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);
  const router = useRouter();
  const { countOfUnreadDiscussions } = useNumberOfDiscussionsUnread();
  const { profiles, liveProfiles } = useProfilesForUser(locale);

  // Handler used for Mobile Application - Bottom Menu
  const handleReactNativeNavigationMessage = useCallback((event: Event) => {
    const message = (event as any).data;
    if (message) {
      let parsedMessage: ReactNativeMessage;
      try {
        parsedMessage = JSON.parse(message);
      }
      catch (err) {
        return;
      };

      if (!parsedMessage) {
        return;
      }
      if (parsedMessage.type === REACT_NATIVE_MESSAGE_TYPE.NAVIGATION) {
        switch (parsedMessage.value) {
          case REACT_NATIVE_MESSAGE_NAVIGATION.PORTFOLIO:
            if (profiles?.length === 1) {
              router.push(
                `/[lang]/p/[profilePath]`,
                `/${locale}/p/${profiles[0]?.path}`
              );
            }
            else if (profiles?.length > 1) {
              router.push(
                `/[lang]/account/portfolios`,
                `/${locale}/account/portfolios`
              );
            }
            else {
              router.push(
                `/[lang]/portfolio`,
                `/${locale}/portfolio`
              );
            }
            break;
          case REACT_NATIVE_MESSAGE_NAVIGATION.PROFILE:
            if (profiles?.length === 1) {
              router.push(
                `/[lang]/account/profile/[profileId]`,
                `/${locale}/account/profile/${profiles[0]?.id}`
              );
            }
            else if (profiles?.length > 1) {
              router.push(
                `/[lang]/account/profiles`,
                `/${locale}/account/profiles`
              );
            }
            else {
              modalStore.openModalNewStack(router, ModalKey.NEW_PROFILE, 1)
            }
            break;
          case REACT_NATIVE_MESSAGE_NAVIGATION.GALLERY:
            router.push(
              `/[lang]/`,
              `/${locale}/`
            );
            break;
          case REACT_NATIVE_MESSAGE_NAVIGATION.ON_FIRE:
            router.push(
              `/[lang]/onfire`,
              `/${locale}/onfire`
            );
            break;
          case REACT_NATIVE_MESSAGE_NAVIGATION.MESSAGES:
            if (liveProfiles?.length === 1) {
              router.push(
                `/[lang]/messages/[profilePath]`,
                `/${locale}/messages/${profiles[0]?.path}`
              );
              layoutStore.openMessagesDiscussionContainer(false);
            }
            else {
              modalStore.openModalNewStack(router, ModalKey.GO_TO_MESSAGES, '0');
            }
            break;
        }
      }
    }

  }, [profiles]);

  useEffect(() => {
    // "document" for Android, "window" for iOS
    window.addEventListener('message', handleReactNativeNavigationMessage, false)
    document.addEventListener('message', handleReactNativeNavigationMessage, false)

    return () => {
      window.removeEventListener('message', handleReactNativeNavigationMessage, false)
      document.removeEventListener('message', handleReactNativeNavigationMessage, false)
    };
  }, [profiles]);

  useEffect(() => {
    // Close dropdown even if we stay on the same page (switching galleries for example)
    // See https://github.com/jgthms/bulma/issues/2514#issuecomment-710771267
    props.openMobileDropdown(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  useEffect(() => {
    sendMsgToReactNativeWebview({ type: REACT_NATIVE_SIGNAL_UNREAD_MESSAGES_COUNT, value: countOfUnreadDiscussions });
  }, [countOfUnreadDiscussions]);

  useEffect(() => {
    if (profiles) {
      sendMsgToReactNativeWebview({ type: REACT_NATIVE_SIGNAL_PROFILES_COUNT, value: profiles.length });

      if (profiles.length === 1) {
        sendMsgToReactNativeWebview({ type: REACT_NATIVE_SIGNAL_PROFILE_ID, value: profiles[0].id });
        sendMsgToReactNativeWebview({ type: REACT_NATIVE_SIGNAL_PROFILE_PATH, value: profiles[0].path });
      }
    }
  }, [profiles?.length]);

  return (
    <React.Fragment>
      <div className={`navbar-brand ${styles.mobileContainerBrandName}`}>
        <Link href={process.env.WEBSITE_URL} passHref>
          <a className={`is-family-secondary ${styles.brandName}`} href={process.env.WEBSITE_URL} >
            <span className={`${styles.noLetterSpacing}`}>O</span>WARD
          </a>
        </Link>
        {
          !props.justLogo &&
          <a className={`navbar-burger burger ${props.isMobileDropdownOpened}`} style={{ color: '#fff' }} onClick={() => props.openMobileDropdown(!props.isMobileDropdownOpened)}>
            <span></span>
            <span></span>
            <span></span>
            <Badge count={countOfUnreadDiscussions} singleNumber />
          </a>
        }
      </div>
      {
        !props.justLogo &&
        <div className={`navbar-menu ${props.isMobileDropdownOpened}`}>
          <hr className='dropdown-divider' />
          <div className={`${styles.mobileContentContainer}`}>
            <p className={`navbar-item ${styles.navbarUserIsLogged}`}>
              {t('navbar.gallery.title')}
            </p>
            <NavBarGalleriesLinks />

          </div>
          <hr className='dropdown-divider' />
          <div className={`${styles.mobileContentContainer}`}>
            <NavBarLink name={t('navbar.portfolios')} href='/portfolio' />
            {
              !oniOSWebview() &&
              <NavBarLink name={t('navbar.pricing')} href='/pricing' />
            }
          </div>
          <hr className='dropdown-divider' />
          {userStore.isLogged ?
            <React.Fragment>
              <div className={`${styles.mobileContentContainer}`}>
                <NavBarMobileMessagesLink />
                <NavBarLink iconSvg={<FireIcon className={`${styles.svgIcon}`} />} name={t('navbar.on_fire')} href={'/onfire'} />
                <CreateOrMyProfilesButton portfolio={onPortfolioPage(router.pathname)} fullwidthBigPadding />
              </div>
              <hr className='dropdown-divider' />
              <div className={`${styles.mobileContentContainer}`}>
                <p className={`navbar-item ${styles.navbarUserIsLogged}`}>
                  {
                    userStore.currentUser.infos && (userStore.currentUser.infos.firstName || userStore.currentUser.infos.firstName) ?
                      userStore.currentUser.infos.firstName + ' ' + userStore.currentUser.infos.lastName :
                      userStore.currentUser.email
                  }
                </p>
                <NavBarLink name={t('navbar.user_settings')} href='/account/user' />
                <NavBarSignoutItem />
              </div>
            </React.Fragment>
            :
            <div className={`${styles.mobileContentContainer}`}>
              <NavBarLink iconName={'fas fa-user'} name={t('navbar.login')} href='/login' />
              <CreateOrMyProfilesButton portfolio={onPortfolioPage(router.pathname)} fullwidthBigPadding />
            </div>
          }
          <React.Fragment>
            <hr className='dropdown-divider' />
            <div className={`${styles.mobileContentContainer}`}>
              <div className={`navbar-item has-dropdown`} style={{ width: '100%' }}>
                <p className={classNames('navbar-item')}>
                  {
                    <span className={`icon ${styles.icon}`}>
                      <i className={'fas fa-globe'}></i>
                    </span>
                  }
                  {locale}
                </p>
                <div className={classNames(`navbar-dropdown`)}>
                  {
                    locales.map((locale, index) => {
                      return (
                        <div className={classNames(styles.borderRadiusContainer)} key={index}>
                          <SwitchLanguage locale={locale} className={classNames('navbar-item', styles.borderRadiusContainer)} />
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            </div>
          </React.Fragment>
          {
            userStore.isAdmin &&
            <div className={`${styles.mobileContentContainer}`}>
              <Link href='/admin'>
                <OwardLinkButton
                  name={t('navbar.admin')}
                  outlined
                  dark
                  fullWidth
                  bigPadding
                />
              </Link>
            </div>
          }
          <hr className='dropdown-divider' />
          <div className={`${styles.mobileContentContainer}`}>
            <NavBarLink iconName={'fas fa-plus'} name={t('navbar.about')} href='https://discover.oward.co' target='_blank' />
            <NavBarLink iconName={'fa-regular fa-newspaper'} name={t('navbar.blog')} href='https://blog.oward.co' target='_blank' />
          </div>
        </div>
      }
    </React.Fragment >
  );
});

const NavBarDesktop: React.FC<NavbarProps> = observer(props => {
  const { locale, t } = useTranslate();
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);

  return (
    <React.Fragment>
      <div className={`${styles.desktopContentContainer}`}>
        {
          !props.justLogo &&
          <div className={`${styles.leftContainer}`}>
            <NavBarLink name={t('navbar.portfolios')} href='/portfolio' />
            <div className={classNames(
              `navbar-item has-dropdown is-hoverable`,
            )}>
              <span className={classNames(
                `is-arrowless`,
                styles.navbarLink,
                onGalleryPage(router.pathname) && styles.navbarLinkActive
              )}>
                {t('navbar.gallery.title')}
                <span className={`icon ${styles.goldIcon}`}>
                  <i className={'fas fa-chevron-down'} ></i>
                </span>
              </span>
              <div
                className={`navbar-dropdown is-boxed ${styles.dropdownContainer}`}
                // Close dropdown even if we stay on the same page (switching galleries for example)
                // See https://github.com/jgthms/bulma/issues/2514#issuecomment-710771267
                key={router.asPath}
              >
                <NavBarGalleriesLinks />
              </div>
            </div>
            <NavBarLink name={t('navbar.pricing')} href='/pricing' />
            <NavBarLink name={t('navbar.about')} href='https://discover.oward.co' target='_blank' />
          </div>
        }
        <div className={`${styles.centerContainer}`}>
          <Link href={process.env.WEBSITE_URL} passHref>
            <a className={`is-family-secondary ${styles.brandName}`} href={process.env.WEBSITE_URL} >
              <span className={`${styles.noLetterSpacing}`}>O</span>WARD
            </a>
          </Link>
        </div>
        {
          !props.justLogo &&
          <div className={`${styles.rightContainer}`} >
            {
              userStore.isLogged &&
              <>
                <NavBarMessagesIcon />
                <NavBarLink iconSvg={<FireIcon className={`${styles.svgIcon}`} />} toolTipText={t('navbar.on_fire')} href='/onfire' />
              </>
            }
            {
              onGalleryPage(router.pathname) &&
              <OwardButton
                transparent
                onClick={() => { layoutStore.openOrFlashSidebar() }}
              >
                <p className={styles.searchButtonName}>
                  <span className={styles.emoji}>🔎</span>
                  {t('global.search')}
                </p>
              </OwardButton>
            }
            {
              userStore.isLogged ?
                <NavBarUserDropdown />
                :
                <NavBarLink name={t('login.login')} href='/login' />
            }
            <CreateOrMyProfilesButton portfolio={onPortfolioPage(router.pathname)} />
            {
              userStore.isAdmin &&
              <Link href='/admin' passHref>
                <OwardLinkButton
                  name={t('navbar.admin')}
                  outlined
                  dark
                />
              </Link>
            }
            <div className={`navbar-item has-dropdown is-hoverable`}>
              <span className={`navbar-item  ${styles.navbarLink}`} style={{ borderRadius: '2rem' }}>
                {locale}
                <span className={`icon ${styles.whiteIcon}`}>
                  <i className={'fas fa-chevron-down'} ></i>
                </span>
              </span>
              <div className={`navbar-dropdown is-right is-boxed ${styles.dropdownContainer}`}>
                {
                  locales.map((locale, index) => {
                    return (
                      <div className={styles.borderRadiusContainer} key={index}>
                        <SwitchLanguage locale={locale} className={classNames('navbar-item', styles.borderRadiusContainer)} />
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </div>
        }
      </div>
    </React.Fragment >
  );
});

// Navbar link with icon for the mobile navbar where there is an icon an a link
interface NavBarLinkProps {
  name?: string;
  iconName?: string;
  iconSvg?: React.ReactNode;
  target?: string;
  href: string;
  as?: string;
  toolTipText?: string;
  isActive?: () => boolean;
  onClick?: () => void;

}
const NavBarLink = (props: NavBarLinkProps) => {
  const router = useRouter();
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    if (props.isActive) {
      setIsActive(props.isActive())
    }
    else if (props.href === '/' || props.href === process.env.WEBSITE_URL) {
      // Handle the index page (url can have a trailing slash or not)
      setIsActive((router.pathname === `/[lang]` || router.pathname === `/[lang]${props.href}`));
    }
    else {
      setIsActive(router.pathname === `/[lang]${props.href}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.href, router]);

  return (
    <Link href={props.href} as={props.as || props.href} passHref>
      <a
        className={classNames(
          'navbar-item',
          styles.navbarLink,
          props.iconName && styles.icon,
          (props.iconSvg && !props.iconName) && styles.svgIconContainer,
          isActive && styles.navbarLinkActive,
          'has-tooltip-arrow has-tooltip-bottom',
        )}
        href={props.href}
        target={props.target}
        data-tooltip={props.toolTipText ? props.toolTipText : null}
        onClick={props.onClick ? props.onClick : () => { }}
      >
        {
          (props.iconName && props.name) &&
          <span>
            {
              <span className={`icon  ${styles.icon}`}>
                <i className={props.iconName}></i>
              </span>
            }
            {props.name}
          </span>
        }
        {
          (props.iconName && !props.name) &&
          <span className={`icon`}>
            <i className={props.iconName}></i>
          </span>
        }
        {
          (props.iconSvg && props.name) &&
          <div className={styles.svgAndNameContainer}>
            <div className={classNames(styles.svgIconContainer)}>
              {props.iconSvg}
            </div>
            {props.name}
          </div>
        }
        {
          (props.iconSvg && !props.name) &&
          <React.Fragment>
            {props.iconSvg}
          </React.Fragment>
        }
        {
          !(props.iconName || props.iconSvg) &&
          <React.Fragment>{props.name}</React.Fragment>
        }
      </a>
    </Link >
  );
}

const NavBarUserDropdown = observer(() => {
  const { t } = useTranslate();
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  return (
    <div className={classNames(
      `navbar-item has-dropdown is-hoverable`,
    )}>
      <span className={classNames(
        'is-arrowless',
        styles.navbarLink,
        router.pathname.match('\/\\[lang\\]\/account.*') && styles.navbarLinkActive
      )}>
        {userToStr(userStore.currentUser)}
      </span>
      <div className={`navbar-dropdown is-boxed ${styles.dropdownContainer}`}>
        <NavBarLink name={t('navbar.user_settings')} href='/account/user' />
        <hr className='dropdown-divider' />
        <NavBarSignoutItem />
      </div>
    </div>
  );
});

const NavBarGalleriesLinks = observer(() => {
  const { t } = useTranslate();
  const router = useRouter();
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);
  const cardStore = useInjection<CardStore>(StoresBindings.CARD);


  const isProActive = () => {
    return (router.pathname === '/[lang]/' || router.pathname === '/[lang]') &&
      (!router.query.filter || (router.query.filter && !router.query.filter.includes(FilterType.GALLERY_TYPE)));
  }

  const isCompanyActive = () => {
    return (router.pathname === '/[lang]/' || router.pathname === '/[lang]') &&
      router.query.filter?.includes(`${FilterType.GALLERY_TYPE}[type]=${GalleryType.COMPANY}`);
  }

  const isArtworkActive = () => {
    return (router.pathname === '/[lang]/' || router.pathname === '/[lang]') &&
      router.query.filter?.includes(`${FilterType.GALLERY_TYPE}[type]=${GalleryType.ARTWORK}`);
  }

  return (
    <React.Fragment>
      <NavBarLink
        name={t('navbar.gallery.pro')}
        href='/'
        isActive={isProActive}
        onClick={() => {
          filterStore.setFilter(
            router,
            FilterType.GALLERY_TYPE,
            undefined,
            undefined,
            true
          );
          // We need to to an explicit getCards here, as if we reset filters + set filters to ProfileType "undefined"
          // (i.e. going to Pro Gallery), the deepEqual filter/filterStore.filter in index.tsx will be false and no card will
          // be fetched
          cardStore.getCards(filterStore.filter, filterStore.openSearch);
        }}
      />
      <NavBarLink
        name={t('navbar.gallery.company')}
        href={`/?filter=gallery_type[type]=${GalleryType.COMPANY}`}
        isActive={isCompanyActive}
        onClick={() => {
          filterStore.setFilter(
            router,
            FilterType.GALLERY_TYPE,
            { type: GalleryType.COMPANY },
            undefined,
            true
          );
        }}
      />
      <NavBarLink
        name={t('navbar.gallery.artwork')}
        href={`/?filter=gallery_type[type]=${GalleryType.ARTWORK}`}
        isActive={isArtworkActive}
        onClick={() => {
          filterStore.setFilter(
            router,
            FilterType.GALLERY_TYPE,
            { type: GalleryType.ARTWORK },
            undefined,
            true
          );
        }}
      />
    </React.Fragment>
  );
});

const NavBarMobileMessagesLink = observer(() => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);
  const { profiles } = useProfilesForUser(locale);
  const { countOfUnreadDiscussions } = useNumberOfDiscussionsUnread();
  const [isActive] = useState<boolean>(router.pathname.startsWith(`/[lang]/messages`));

  return (
    <div
      className={classNames(
        'navbar-item',
        styles.icon,
        styles.navbarLink,
        isActive && styles.navbarLinkActive,
      )}
      data-tooltip={t('navbar.messaging')}
      onClick={() => {
        switch (profiles?.length) {
          case 1:
            router.push(
              `/[lang]/messages/[profilePath]`,
              `/${locale}/messages/${profiles[0]?.path}`
            );
            layoutStore.openMessagesDiscussionContainer(false);
            break;
          case 0:
          default:
            modalStore.openModalNewStack(router, ModalKey.GO_TO_MESSAGES, '0');
            break;
        }
      }}
    >
      <p className={``}>
        {
          <span className={`icon ${styles.icon}`}>
            <i className={'fas fa-envelope'}></i>
          </span>
        }
        {
          t('navbar.messaging')
        }
      </p>
      <Badge count={countOfUnreadDiscussions} singleNumber staticPosition />
    </div>
  );
});

const NavBarMessagesIcon: React.FC = observer(() => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);
  const { profiles } = useProfilesForUser(locale);
  const { countOfUnreadDiscussions } = useNumberOfDiscussionsUnread();
  const [isActive] = useState<boolean>(router.pathname.startsWith(`/[lang]/messages`));

  return (
    <div
      className={classNames(
        'navbar-item',
        styles.navbarLink,
        styles.icon,
        isActive && styles.navbarLinkActive,
        'has-tooltip-arrow has-tooltip-bottom',
      )}
      data-tooltip={t('navbar.messaging')}
      onClick={() => {
        switch (profiles?.length) {
          case 1:
            router.push(
              `/[lang]/messages/[profilePath]`,
              `/${locale}/messages/${profiles[0]?.path}`
            );
            layoutStore.openMessagesDiscussionContainer(false);
            break;
          case 0:
          default:
            modalStore.openModalNewStack(router, ModalKey.GO_TO_MESSAGES, '0');
            break;
        }
      }}
    >
      {
        <span className={`icon`}>
          <i className={'fas fa-envelope'}></i>
        </span>
      }
      <Badge count={countOfUnreadDiscussions} singleNumber />
    </div>
  );
});

const NavBarSignoutItem = observer(() => {
  const { t } = useTranslate();
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);

  const onClick = async () => {
    sendMsgToReactNativeWebview({ type: REACT_NATIVE_SIGNAL_SIGNOUT, value: 1 });
    await UserController.logout();
    layoutStore.setPageLoading(true);
    goToHomePageAndReload();
  }

  return (
    <p
      className={`navbar-item ${styles.navbarLink}`}
      onClick={onClick}
    >
      {t('navbar.signout')}
    </p>
  );
});

interface NavbarCreateOrMyProfilesProps {
  portfolio?: boolean;
  fullwidthBigPadding?: boolean;
}

export const CreateOrMyProfilesButton = observer((props: NavbarCreateOrMyProfilesProps) => {
  const { locale, t } = useTranslate();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const router = useRouter();
  const { profiles } = useProfilesForUser(locale);

  const renderCreateOrMyProfilesButton = () => {
    if (profiles?.length === 1) {
      return (
        <Link href={`/account/profile/[profileId]`} as={`/account/profile/${profiles[0]?.id}`} passHref>
          <OwardLinkButton
            name={t(props.portfolio ? 'portfolio.access' : 'navbar.access_profiles', profiles.length)}
            fullWidth={props.fullwidthBigPadding}
            bigPadding={props.fullwidthBigPadding}
          />
        </Link>
      );
    }
    else if (profiles?.length > 1) {
      return (
        <Link href='/account/profiles' passHref>
          <OwardLinkButton
            name={t(props.portfolio ? 'portfolio.access' : 'navbar.access_profiles', profiles.length)}
            fullWidth={props.fullwidthBigPadding}
            bigPadding={props.fullwidthBigPadding}
          />
        </Link>
      );
    }
    else {
      return (
        <OwardButton
          name={t(props.portfolio ? `portfolio.create` : `navbar.create_profile`)}
          onClick={() => {
            props.portfolio ?
              modalStore.openModalNewStack(router, ModalKey.NEW_PORTFOLIO, 1)
              :
              modalStore.openModalNewStack(router, ModalKey.NEW_PROFILE, 1)
          }}
          fullWidth={props.fullwidthBigPadding}
          bigPadding={props.fullwidthBigPadding}
        />
      );
    }
  }

  return (
    <React.Fragment>
      {
        userStore.isLogged ?
          renderCreateOrMyProfilesButton()
          :
          <Link href='/register' passHref>
            <OwardLinkButton
              name={t(props.portfolio ? 'portfolio.create' : 'login.register_button')}
              fullWidth={props.fullwidthBigPadding}
              bigPadding={props.fullwidthBigPadding}
            />
          </Link>
      }
    </React.Fragment>
  );
});

// Scrolling management
// See https://dev.to/n8tb1t/tracking-scroll-position-with-react-hooks-3bbj
//
const isBrowser = typeof window !== `undefined`;

function getScrollPosition(): number {
  if (!isBrowser) return 0;

  const position: DOMRect = document.body.getBoundingClientRect()

  return -position.top;
}

function useScrollPosition(effect: ({ prevPos, currPos }) => void, wait: number) {
  const position = useRef(getScrollPosition());
  const throttleTimeout = useRef(null);

  const callBack = useCallback(() => {
    const currPos: number = getScrollPosition();
    effect({ prevPos: position.current, currPos });
    position.current = currPos;
    throttleTimeout.current = null;
  }, [effect]);

  useEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout.current === null) {
          throttleTimeout.current = setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    }

    window.addEventListener('scroll', handleScroll);

    return (
      () => { window.removeEventListener('scroll', handleScroll); }
    )
  }, [wait, callBack]);
}

