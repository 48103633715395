import React, { useState, useEffect, useContext } from 'react';
import { send, MessageController, Discussion, DiscussionType } from '@oward/openapi';
import { PaginatedList, PaginatedListKey, Section, NavigationContext, PaginatedListFilterType } from '@components/admin';
import { profileToStrWithId, strToPath, stateToEmoji } from '@oward/common-utils';
import { useTranslate } from '@hooks/use-translate';
import moment from 'moment';
import { EditDiscussion } from './EditDiscussion';

export const DiscussionList: React.FC = () => {

  const store = useContext(NavigationContext);
  const { t, locale } = useTranslate();
  const [items, setItems] = useState([] as Discussion[]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = async () => {
    try {
      setLoading(true);
      setError(false);
      setItems(await send(MessageController.listDiscussions()))
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const getLastMessageDate = (discussion: Discussion): string => {
    return discussion.messages[discussion.messages.length - 1]?.creationDate;
  }

  const getSomeoneNotSeenDiscussion = (discussion: Discussion): boolean => {
    return (discussion.members?.length !== discussion.messageLastSeens.length);
  }

  const getSomeoneNotLastMessage = (discussion: Discussion): boolean => {
    for (const lastSeen of discussion.messageLastSeens) {
      if (lastSeen.messageId !== discussion.messages[discussion.messages.length - 1].id) {
        return true;
      }
    }

    return false;
  }

  const getSeenByEveryone = (discussion: Discussion): string => {
    if (getSomeoneNotSeenDiscussion(discussion)) {
      return '❌';
    }

    if (getSomeoneNotLastMessage(discussion)) {
      return '➡️'
    }

    return '✅';
  }

  const getProfileNames = (discussion: Discussion): string => {
    let profileNames = '';

    discussion.members.map(member => {
      profileNames += member.profile.name.concat(' (', member.profileId.toString(), ') ');
    });

    return profileNames;
  }

  const keys: PaginatedListKey<Discussion>[] = [
    {
      label: 'Id',
      value: item => item.id + ' ' + stateToEmoji(item.state),
      sortValue: item => item.id
    },
    {
      label: 'Type',
      value: item => item.type
    },
    {
      label: 'Name',
      value: item => item.type === DiscussionType.ONETOONE ? "-" : item.name
    },
    {
      label: '# of Profiles in discussion',
      value: item => item.members.length
    },
    {
      label: 'Profiles in discussion',
      value: item => getProfileNames(item)
    },
    {
      label: '# of messages',
      value: item => item.messages.length
    },
    {
      label: 'Last message date',
      value: item => getLastMessageDate(item) ? moment(getLastMessageDate(item)).format('lll') : '- empty discussion -',
      sortValue: item => getLastMessageDate(item) ? Date.parse(getLastMessageDate(item)) : 0
    },
    {
      label: 'Seen (legend below)',
      value: item => getSeenByEveryone(item)
    }
  ];

  const fetchOne = (item: Discussion) => send(MessageController.findOneDiscussion(item.id));

  const filterItemStrings = (item: Discussion) => [
    item.id.toString(),
    strToPath(getProfileNames(item)),
    strToPath(item.name)
  ]

  const filterComponents = [
    {
      name: 'Type',
      type: PaginatedListFilterType.DROPDOWN,
      options: Object.values(DiscussionType).map((state) => {
        return ({ value: state, label: state });
      }),
      filterFunction: (item: Discussion, value: string) => item.type === value
    },
    {
      name: 'Profile in discussion',
      type: PaginatedListFilterType.DROPDOWN,
      options: [...new Set(
        [].concat.apply([], items.map(item => item.members.map(member => profileToStrWithId(member.profile))))
      )].map((profileStr: string) => {
        return { value: profileStr, label: profileStr }
      }),
      filterFunction: (item: Discussion, value: string) => item.members.map(member => profileToStrWithId(member.profile)).includes(value)
    },
    {
      name: 'only empty discussions',
      type: PaginatedListFilterType.ON_OFF,
      filterFunction: (item: Discussion) => item.messages.length === 0
    },
    {
      name: 'only NOT empty discussions',
      type: PaginatedListFilterType.ON_OFF,
      filterFunction: (item: Discussion) => item.messages.length > 0
    },
    {
      name: 'only ❌',
      type: PaginatedListFilterType.ON_OFF,
      filterFunction: (item: Discussion) => getSomeoneNotSeenDiscussion(item)
    },
    {
      name: 'only ➡️',
      type: PaginatedListFilterType.ON_OFF,
      filterFunction: (item: Discussion) => getSomeoneNotLastMessage(item)
    },
    {
      name: 'only ✅',
      type: PaginatedListFilterType.ON_OFF,
      filterFunction: (item: Discussion) => !(getSomeoneNotSeenDiscussion(item) || getSomeoneNotLastMessage(item))
    },
  ]

  return (
    <React.Fragment>
      <Section
        title='List'
        subtitle='The list of all Discussions in the database'
        childrenFullWidth
      >
        <PaginatedList
          keys={keys}
          items={items}
          filterItemStrings={filterItemStrings}
          filterComponents={filterComponents}
          editComponent={EditDiscussion}
          fetchOne={fetchOne}
          size={100}
          reloadList={loadItems}
          loading={loading}
          error={error}
        />
      </Section>
      <p>✅ : Everyone in discussion has seen the last message</p>
      <p>➡️ : Someone in discussion has not seen the last message, but everyone has seen AT LEAST one message</p>
      <p>❌ : Someone in discussion has not even opened the discussion</p>
      <div style={{ height: '2rem' }} />
    </React.Fragment>
  );
}
