import React from 'react';
import styles from './OwardLoader.module.scss';
import Loader from 'react-loader-spinner';
import classNames from 'classnames';

interface OwardLoaderProps {
  loading: boolean;
  message?: string;
  positionAbsolute?: boolean;
  positionStatic?: boolean;
}

// Container must have "position: relative" for Loader to be well positionned
export const OwardLoader: React.FC<OwardLoaderProps> = props => {
  return (
    <React.Fragment>
      {
        props.loading &&
        <div className={classNames(styles.loaderContainer, { [styles.loaderContainerStatic]: props.positionStatic })}>
          <Loader
            className={classNames({ [styles.absolutePositionedLoader]: props.positionAbsolute })}
            type='Grid'
            color={styles.greyDark}
            height={60}
            width={60}
          />
          {
            props.message &&
            <div className={styles.messageContainer}>
              <p>{props.message}</p>
            </div>
          }
        </div>
      }
    </React.Fragment>
  );
}
