import React, { useState, useEffect, useContext } from 'react';
import { send, Broadcaster, AssociationController, Association } from '@oward/openapi';
import { PaginatedList, PaginatedListKey, Section, NavigationContext } from '@components/admin';
import { EditAssociation } from './EditAssociation';
import { strToPath } from '@oward/common-utils';
import { stateToEmoji } from '@oward/common-utils';
import { useTranslate } from '@hooks/use-translate';
import { OwardButton } from '@components/Core';

export const AssociationList: React.FC = () => {

  const store = useContext(NavigationContext);
  const { locale } = useTranslate();
  const [items, setItems] = useState([] as Association[]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadItems = async () => {
    try {
      setLoading(true);
      setError(false);
      setItems(await send(AssociationController.listAdmin(locale)))
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const keys: PaginatedListKey<Association>[] = [
    {
      label: 'Id',
      value: item => item.id
    },
    {
      label: 'Name',
      value: item => item.name.concat(' ', stateToEmoji(item.state))
    },
    {
      label: 'Country',
      value: item => item.country?.name
    }
  ];

  const filterItemStrings = (item: Association) => [item.id.toString(), strToPath(item.name)];

  const fetchOne = (item: Association) => send(AssociationController.findOneAdmin(item.id));

  return (
    <React.Fragment>
      <Section
        title='List'
        subtitle='The list of all associations in the database'
        childrenFullWidth
      >
        <PaginatedList
          keys={keys}
          items={items}
          filterItemStrings={filterItemStrings}
          size={100}
          editComponent={EditAssociation}
          fetchOne={fetchOne}
          reloadList={loadItems}
          loading={loading}
          error={error}
        />
      </Section>
      <div style={{ height: '2rem' }} />
      <Section
        title='Create new Association'
        subtitle='Create it WHEN PRESSING THIS BUTTON. Cannot be undone, please fill all needed infos in next screen'
      >
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingBottom: '3rem' }}>
          <OwardButton
            name='Create Association'
            onClick={async () => {
              let association: Association = await send(AssociationController.createOneAdmin());
              loadItems();
              store.pop({
                label: 'Create Association',
                element: <EditAssociation item={association} notify={loadItems} noCancel />
              });
            }}
          />
          <p className='has-text-danger'>
            Newly created association will be in BLOCKED State.
          </p>
        </div>
      </Section>
    </React.Fragment>
  );
}
