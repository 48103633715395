import React from 'react';

import { AgentStatistics } from './AgentStatistics';
import { Divider } from '@components/admin';
import { AgentList } from './AgentList';

export const AgentComponent: React.FC = () => {
  return (
    <React.Fragment>
      <AgentStatistics />
      <Divider />
      <AgentList />
    </React.Fragment>
  );
}
