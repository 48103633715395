export namespace StoresBindings {
  export const ARTWORK = 'store.artwork';
  export const LAYOUT = 'store.layout';
  export const USER = 'store.user';
  export const CARD = 'store.card';
  export const FILTER = 'store.filter';
  export const MESSAGE = 'store.message';
  export const MODAL = 'store.modal';
  export const POPUP = 'store.popup';
}

export namespace ApplicationBindings {
  export const LOCALE = 'locale';
}
