import React from 'react';
import classNames from 'classnames';
import { Menu } from '@components/Core/Menu';

interface CategoryProps {
  label: string;
  className?: string;
}

export const Category = (props: CategoryProps) => {

  return (
    <React.Fragment>
      <Menu.Label className={classNames(
        `has-text-centered`,
        props.className
      )}>
        {props.label}
      </Menu.Label>
    </React.Fragment >
  );
}
