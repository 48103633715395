import React from 'react';

import { Divider } from '@components/admin';
import { UserStatistics } from './UserStatistics';
import { UserList } from './UserList';
import { UserPassword } from './UserPassword';

export const UsersComponent: React.FC = () => {
  return (
    <React.Fragment>
      <UserStatistics />
      <Divider />
      <UserPassword />
      <Divider />
      <UserList />
    </React.Fragment>
  );
}
