import React, { ReactElement, useEffect } from 'react';
import { useLocalObservable, observer } from 'mobx-react-lite';
import { EditProps } from '../../types';
import { EditButton } from './EditButton';
import { OwardLoader } from '@components/Core';
import { useTranslate } from '@hooks/.';
import { runInAction } from 'mobx';

export interface SimpleListKey<T> {
  label: string;
  value: (item: T) => ReactElement;
}

export interface SimpleListProps<T> {
  items: T[];
  keys: SimpleListKey<T>[];
  editComponent: React.FC<EditProps<T>>;
  editLabel?: string;
  fetchOne: (item: T) => Promise<T>;
  reloadList?: () => Promise<void>;
  loading?: boolean;
  error?: boolean;
}

export const SimpleList = observer(<T extends unknown>(props: SimpleListProps<T>) => {
  const { t } = useTranslate();

  const store = useLocalObservable(() => ({
    items: [] as T[],
  }));

  useEffect(() => {
    runInAction(() => {
      store.items = props.items;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.items]);

  const reloadList = async () => {
    await props.reloadList();
  }

  return (
    (props.loading || props.error) ?
      <div>
        {
          props.loading ?
            <OwardLoader loading={true} positionStatic />
            :
            <p>{t('global.error')}</p>
        }
      </div>
      :
      store.items.length > 0 ?
        <div className='table-container' style={{ position: 'relative' }}>
          <table className='table is-fullwidth is-striped is-hoverable'>
            <thead>
              <tr>{
                props.keys.map((key, index) =>
                  <th key={index}>
                    {key.label}
                  </th>
                )}
                <th></th>
              </tr>
            </thead>
            <tbody >
              {store.items.map((item, idx) => {
                return (
                  <tr key={idx}>{
                    props.keys.map((key, idx) =>
                      <td key={idx}>
                        {key.value(item)}
                      </td>
                    )}
                    <td>
                      <EditButton
                        item={item}
                        notify={reloadList}
                        component={props.editComponent}
                        fetchOne={props.fetchOne}
                        label={props.editLabel}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        :
        <p>No element found</p>
  );
});
