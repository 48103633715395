import React, { useContext } from 'react';

import { EditProps } from '../../types';
import { Icon } from '@components/Core/Icon';
import { NavigationContext } from '../Navigation';

interface EditButtonProps<T> {
  item: T;
  label?: string;
  component: React.FC<EditProps<T>>;
  fetchOne: (item: T) => Promise<T>;
  notify?: (item: T | undefined) => Promise<void>;
}

export const EditButton = <T extends unknown>(props: EditButtonProps<T>) => {

  const store = useContext(NavigationContext);

  const onClick = () => {
    props.fetchOne(props.item).then(item => {
      store.pop({
        label: props.label ? props.label : 'Edit',
        element: <props.component item={item} notify={props.notify} />
      });
    });
  }

  return (
    <div className="buttons is-right">
      <button
        type='button'
        className="button is-small"
        onClick={onClick}>
        <Icon icon='fas fa-edit' />
      </button>
    </div>
  );
}
