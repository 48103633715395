import React, { useEffect } from 'react';
import styles from './Subscriptions&PricingPage.module.scss';
import { useInjection, useTranslate } from '@hooks/.';
import { getPrice, LayoutPage, Link, SubscriptionCards, SubscriptionClientRecommendations, SubscriptionFooter, SubscriptionPaymentMethods, SubscriptionsBottomCta, SubscriptionsFaq, SubscriptionVideoEmbed, } from '@components/.'
import { useState } from 'react';
import { Profile, ProfileController, ProfileSubscriptionsPrice, send, SubscriptionController } from '@oward/openapi';
import { observer } from 'mobx-react-lite';
import { OwardButton, OwardLinkButton, OwardLoader, ToastError } from '@components/Core';
import { Divider } from '../Admin/AdminComponents';
import { UserStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { toast } from 'react-toastify';
import { PREMIUM_LEVEL, RecurringInterval } from '@oward/common-utils';
import { DEFAULT_PRICING_INTERVAL } from '@utils/constants';

interface SubscriptionsPageProps {
  profile: Profile;
  prices: ProfileSubscriptionsPrice[];
}

export const SubscriptionsPage: React.FC<SubscriptionsPageProps> = observer(props => {
  const { t } = useTranslate();
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  useEffect(() => {
    // Reset Promo Code when landing on subscription page
    userStore.setPromoCode(undefined);

    Promise.resolve(ProfileController.visitSubscriptionPage(props.profile.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPage = () => {
    switch (props.profile.subscription?.status) {
      case 'active':
        return <SubscriptionAlreadyActivePage {...props} />;
      case 'incomplete':
        return <SubscriptionIncompletePage {...props} />;
      case 'past_due':
        return <SubscriptionPastDuePage {...props} />;
      case 'unpaid':
        return <SubscriptionUnpaidPage {...props} />;
      case 'incomplete_expired':
      default:
        return <SubscriptionChoicePage {...props} />;
    }
  }

  return (
    <React.Fragment>
      {renderPage()}
    </React.Fragment >
  );
});

const SubscriptionPastDuePage: React.FC<SubscriptionsPageProps> = observer((props) => {
  const { t } = useTranslate();

  return (
    <LayoutPage title={t(`subscriptions.past_due.title`)}>
      <div className={styles.pastDueContainer}>
        <p className={`${styles.pastDue}`}>{t('subscriptions.past_due.subtitle', { name: props.profile.name })}</p>
        <Link href='/profile-subscription-invoice/[profileId]' as={`/profile-subscription-invoice/${props.profile.id}`} passHref>
          <OwardLinkButton
            name={t('subscriptions.past_due.settle')}
            red
          />
        </Link>
      </div>
    </LayoutPage>
  );
});

const SubscriptionUnpaidPage: React.FC<SubscriptionsPageProps> = observer((props) => {
  const { t } = useTranslate();
  const [loading, setLoading] = useState(false);
  const [showSubscriptionChoices, setShowSubscriptionChoices] = useState(false);

  return (
    <React.Fragment>
      {
        showSubscriptionChoices ?
          <SubscriptionChoicePage {...props} />
          :
          <LayoutPage title={t(`subscriptions.unpaid.title`)}>
            <div className={styles.incompleteContainer}>
              <OwardLoader loading={loading} />
              <p className={`${styles.incomplete}`}>
                {t('subscriptions.unpaid.subtitle', {
                  name: props.profile.name,
                  type: t(`premium_level.${props.profile.subscription?.stripeInfos.premiumLevel}`)
                })}
              </p>
              <OwardButton
                name={t('subscriptions.unpaid.go_to_choices')}
                outlined
                onClick={async () => {
                  try {
                    setLoading(true);
                    await send(SubscriptionController.cancelStripeSubscription(props.profile.id));
                    setShowSubscriptionChoices(true);
                  }
                  catch (err) {
                    toast.dark(<ToastError msg={t('global.error_with_code', { code: err })} />);
                  }
                  finally {
                    setLoading(false);
                  }
                }}
              />
            </div>
          </LayoutPage>
      }
    </React.Fragment>
  );
});

const SubscriptionIncompletePage: React.FC<SubscriptionsPageProps> = observer((props) => {
  const { t } = useTranslate();
  const [loading, setLoading] = useState(false);
  const [showSubscriptionChoices, setShowSubscriptionChoices] = useState(false);

  return (
    <React.Fragment>
      {
        showSubscriptionChoices ?
          <SubscriptionChoicePage {...props} />
          :
          <LayoutPage title={t(`subscriptions.title`)}>
            <div className={styles.incompleteContainer}>
              <OwardLoader loading={loading} />
              <p className={`${styles.incomplete}`}>
                {t('subscriptions.incomplete.subtitle', {
                  name: props.profile.name,
                  type: t(`premium_level.${props.profile.subscription?.stripeInfos.premiumLevel}`)
                })}
              </p>
              <Link href='/profile-subscription-invoice/[profileId]' as={`/profile-subscription-invoice/${props.profile.id}`} passHref>
                <OwardLinkButton
                  name={t('subscriptions.incomplete.take_back')}
                />
              </Link>
              <OwardButton
                name={t('subscriptions.incomplete.go_to_choices')}
                outlined
                onClick={async () => {
                  try {
                    setLoading(true);
                    await send(SubscriptionController.cancelStripeSubscription(props.profile.id));
                    setShowSubscriptionChoices(true);
                  }
                  catch (err) {
                    toast.dark(<ToastError msg={t('global.error_with_code', { code: err })} />);
                  }
                  finally {
                    setLoading(false);
                  }
                }}
              />
            </div>
          </LayoutPage>
      }
    </React.Fragment>
  );
});

const SubscriptionAlreadyActivePage: React.FC<SubscriptionsPageProps> = observer((props) => {
  const { t } = useTranslate();

  return (
    <LayoutPage title={t(`subscriptions.title`)}>
      <div className={styles.alreadyActiveContainer}>
        <p className={`${styles.alreadyActive}`}>{t('subscriptions.already_active.subtitle', { name: props.profile.name })}</p>
        <Link href='/account/profile/[profileId]' as={`/account/profile/${props.profile.id}`} passHref>
          <OwardLinkButton
            name={t('subscriptions.already_active.go_to_profile')}
          />
        </Link>
      </div>
    </LayoutPage>
  );
});



const SubscriptionChoicePage: React.FC<SubscriptionsPageProps> = observer((props) => {
  const { t } = useTranslate();
  const [interval, setInterval] = useState<RecurringInterval>(DEFAULT_PRICING_INTERVAL);

  const renderSubscribeCta = () => {
    return <Link href={`/payment-profile/[profileId]/[paymentOption]`} as={`/payment-profile/${props.profile.id}/${getPrice(props.prices, interval, PREMIUM_LEVEL.PORTFOLIO).stripePriceId}`} passHref>
      <OwardLinkButton
        name={t('subscriptions.cards.portfolio.cta')}
      />
    </Link>;
  }

  return (
    <LayoutPage title={t(`subscriptions.title`)} noPadding>
      <div className={styles.headerContainer}>
        <div className={`${styles.subtitleContainer}`}>
          <p className={`${styles.subtitle}`}>{t('subscriptions.subtitle')}</p>
        </div>
      </div>
      <SubscriptionCards
        profile={props.profile}
        prices={props.prices}
        ctaNetwork={
          <OwardButton
            name={t('subscriptions.cards.network.active')}
            outlined
            deactivated
          />
        }
        ctaPortfolio={renderSubscribeCta()}
        interval={interval}
        setInterval={setInterval}
      />
      <SubscriptionPaymentMethods />
      <SubscriptionVideoEmbed />
      <SubscriptionsFaq />
      <SubscriptionClientRecommendations />
      <SubscriptionsBottomCta cta={renderSubscribeCta()} />
      <SubscriptionFooter />
    </LayoutPage>
  );
});
