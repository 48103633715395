import React from 'react';
import styles from './Layout.module.scss';

interface FooterProps {
  children?: React.ReactNode;
}

export const Footer: React.FC<FooterProps> = props => {
  return (
    <React.Fragment>
      <div className={styles.footer}>
        {props.children}
      </div>
    </React.Fragment>
  );
}
