import { LocationController } from '@oward/openapi';
import React from 'react';
import { EntityCount, Section } from '../AdminComponents';

export const CountriesCitiesStatistics: React.FC = () => {
  return (
    <React.Fragment>
      <Section
        title='Statistics'
        subtitle='Basic statistics about Countries & Cities'
      >
        <table className="table is-hoverable">
          <thead>
            <tr>
              <th>Key</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Countries Count</td>
              <td><EntityCount getCountFunction={LocationController.getCountriesCount} /></td>
            </tr>
            <tr>
              <td>Cities Count</td>
              <td><EntityCount getCountFunction={LocationController.getCitiesCount} /></td>
            </tr>
          </tbody>
        </table>
      </Section>
    </React.Fragment>
  )
}
