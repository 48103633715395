import React from 'react';

import { BroadcasterStatistics } from './BroadcasterStatistics';
import { Divider } from '@components/admin';
import { BroadcasterList } from './BroadcasterList';

export const BroadcasterComponent: React.FC = () => {
  return (
    <React.Fragment>
      <BroadcasterStatistics />
      <Divider />
      <BroadcasterList />
    </React.Fragment>
  );
}
