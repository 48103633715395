import React, { useState } from 'react';
import { useRouter } from 'next/router';
import styles from './CardArtworks.module.scss';
import { useBlockedArtworkIds, useInjection, useTranslate } from '@hooks/.';
import { CardStore, FilterStore, ModalStore, ModalKey } from '@stores/.';
import { StoresBindings } from '@container/.';
import { Artwork, ArtworkExtraInfosI, ArtworkType, ArtworkUrl, BlockController, CardArtworkI, Profile, State } from '@oward/openapi';
import { FilterType } from '@oward/common-enums';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ARTWORK_COVER_PLACEHOLDER_URL, hasPortfolioPublic, onErrorImage, SWR_BLOCKED_ARTWORK_IDS } from '@utils/.';
import OscarGoldIcon from '../../assets/icons/oscar_gold.svg';
import VideoIcon from '../../assets/icons/video.svg';
import ImageIcon from '../../assets/icons/image.svg';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { findTranslationOrDefault } from '@oward/common-utils';
import { mutate } from 'swr';
import { OwardButton, OwardLoader, ToastError, ToastSucess } from '..';
import { toast } from 'react-toastify';

export enum ArtworkSize {
  SMALL = 'small',
  MEDIUM_SMALL = 'mediumSmall',
  MEDIUM_LARGE = 'mediumLarge',
  LARGE = 'large'
}

interface CardArtworkProps {
  artwork: CardArtworkI;
  artworkExtraInfos?: ArtworkExtraInfosI;
  artworkSize: ArtworkSize;
  preview?: boolean;
  coverPreview?: any;
  descriptionProfile?: boolean;
}

export const CardArtwork = observer((props: CardArtworkProps) => {
  const { t } = useTranslate();
  const router = useRouter();
  const cardStore = useInjection<CardStore>(StoresBindings.CARD);
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const { blockedArtworkIds } = useBlockedArtworkIds();

  let artworkImageSrc: string = undefined;
  if (props.coverPreview) {
    artworkImageSrc = props.coverPreview;
  }
  else {
    artworkImageSrc = props.artwork.images[props.artworkSize];

    // If there's no url for the right size, take the url for another size (from bigger to smaller)
    if (!artworkImageSrc) {
      let sizes: ArtworkSize[] =
        [
          ArtworkSize.SMALL,
          ArtworkSize.MEDIUM_SMALL,
          ArtworkSize.MEDIUM_LARGE,
          ArtworkSize.LARGE,
        ]

      sizes.map(size => {
        if (props.artwork.images[size]) {
          artworkImageSrc = props.artwork.images[size];
        }
      });

      if (!artworkImageSrc) {
        artworkImageSrc = props.artwork.images.fetched;
      }
    }
  }

  const truncateDescription = (desc: string, artworkSize: ArtworkSize): string => {
    let charLimit: number = 100;

    switch (artworkSize) {
      case ArtworkSize.SMALL:
        charLimit = 120;
        break;
      case ArtworkSize.MEDIUM_SMALL:
        charLimit = 160;
        break;
      case ArtworkSize.MEDIUM_LARGE:
        charLimit = 250;
        break;
      case ArtworkSize.LARGE:
        charLimit = 350;
        break;
    }

    if (desc?.length > charLimit) {
      desc = desc?.substring(0, charLimit) + '...';
    }
    return desc;
  }

  const Description: React.FC = () => {
    return <div className={classNames(styles.artworkDescriptionContainer)}>
      <p className={styles.artworkDescription}>
        {
          (props.artwork.description || props.descriptionProfile) &&
          <React.Fragment>
            {
              props.descriptionProfile ?
                <>
                  {t('card.artwork.profile_description')}
                  <span className={styles.nameAndJob}>{` ${props.artwork.profileName} (${props.artwork.profileJob})`}</span>
                </>
                :
                <>{truncateDescription(props.artwork.description, props.artworkSize)}</>
            }
            <br /><br />
          </React.Fragment>
        }
        <span className={styles.seeArtwork}>{t('card.see_artwork')}</span>
      </p>
    </div>
  }

  return (
    <React.Fragment>
      {
        props.artwork === undefined ?
          null
          :
          <div className={classNames(styles.artworkContainer)}>
            {
              blockedArtworkIds?.find(id => id === props.artwork.id) &&
              <BlockedArtworkCover artworkId={props.artwork.id} artworkName={props.artwork.name} />
            }
            {
              props.artwork.matchFilter &&
              <div className={classNames(styles.matchFilterContainer)} />
            }
            <div className={styles.artworkImageContainer} onClick={() => {
              // artworkExtraInfos are fetched (if needed) in the ArtworkPage effect hook
              modalStore.artwork = { cardArtwork: props.artwork, artworkExtraInfos: props.artworkExtraInfos ?? undefined };
              modalStore.openModalNewStack(router, ModalKey.ARTWORK, props.artwork.path);
            }}>
              <Description />
              {
                artworkImageSrc ?
                  <LazyLoadImage
                    src={artworkImageSrc}
                    alt={t('alt.artwork_cover', {
                      name: props.artwork.name,
                      film_type: props.artwork.filmType,
                      director_name: props.artwork.directorName,
                      profile_name: props.artwork.profileName,
                      profile_job: props.artwork.profileJob,
                    })}
                    effect='blur'
                    className={styles['artwork__' + props.artworkSize]}
                    scrollPosition={cardStore.scrollPosition}
                    onError={onErrorImage}
                  />
                  :
                  <div className={classNames(styles['artwork__' + props.artworkSize], styles.noPhotoContainer)}>
                    <p className={styles.noPhoto}>{props.artwork.name}</p>
                  </div>
              }
              <div className={styles.artworkIconsContainer}>
                {
                  props.artwork.isFestivalSelected &&
                  <div
                    className={classNames(styles.artworkIconContainer, 'has-tooltip-arrow', 'has-tooltip-left')}
                    data-tooltip={t('card.artwork.festival_selected')}
                  >
                    <div className={styles.svgIconContainer}>
                      <OscarGoldIcon className={styles.svgIcon} />
                    </div>
                  </div>
                }
                {
                  props.artwork.isBroadcasted &&
                  <div
                    className={classNames(styles.artworkIconContainer, 'has-tooltip-arrow', 'has-tooltip-left')}
                    data-tooltip={t('card.artwork.broadcasted')}
                  >
                    <div className={styles.svgIconContainer}>
                      <VideoIcon className={styles.svgIcon} />
                    </div>
                  </div>
                }
                {
                  props.artwork.photoUrls?.length > 0 &&
                  <div
                    className={classNames(styles.artworkIconContainer, 'has-tooltip-arrow', 'has-tooltip-left')}
                    data-tooltip={t('card.artwork.has_pictures')}
                  >
                    <div className={styles.svgIconSmallContainer}>
                      <ImageIcon className={styles.svgIcon} />
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className={styles.artworkInfoContainer}>
              {
                !props.artwork.filmType ?
                  <p className={styles.artworkType}>{t('gallery.artwork.no_film')}</p>
                  :
                  <p className={classNames(styles.artworkType, props.preview && styles.preview)} onClick={() => {
                    !props.preview &&
                      filterStore.setFilter(
                        router,
                        FilterType.FILM_TYPE,
                        { id: props.artwork.filmTypeId },
                        props.artwork.filmType
                      )
                  }}>
                    {props.artwork.filmType}
                  </p>
              }
              <p className={styles.artworkTitle}>
                {props.artwork.name}, {props.artwork.releaseYear === 0 ? t('wip.film') : props.artwork.releaseYear}
              </p>
            </div>
          </div >
      }
    </React.Fragment>
  );
})

interface CardArtworksProps {
  artworks: CardArtworkI[];
  preview?: boolean;
}


const CardArtworks = (props: CardArtworksProps) => {
  const { t, locale } = useTranslate();

  switch (props.artworks.length) {
    case 0: {
      return (
        <div className={styles.noArtworkContainer}>
          <p>{t('card.artwork.no_artwork')}</p>
        </div>
      );
    }
    case 1: {
      return (
        <CardArtwork artwork={props.artworks[0]} artworkSize={ArtworkSize.LARGE} preview={props.preview} />
      );
    }
    case 2: {
      return (
        <React.Fragment>
          <CardArtwork artwork={props.artworks[0]} artworkSize={ArtworkSize.MEDIUM_LARGE} preview={props.preview} />
          <CardArtwork artwork={props.artworks[1]} artworkSize={ArtworkSize.MEDIUM_SMALL} preview={props.preview} />
        </React.Fragment>
      );
    }
    case 3: {
      return (
        <React.Fragment>
          <CardArtwork artwork={props.artworks[0]} artworkSize={ArtworkSize.SMALL} preview={props.preview} />
          <CardArtwork artwork={props.artworks[1]} artworkSize={ArtworkSize.SMALL} preview={props.preview} />
          <CardArtwork artwork={props.artworks[2]} artworkSize={ArtworkSize.SMALL} preview={props.preview} />
        </React.Fragment>
      );
    }
    default: {
      return (
        <div className={styles.noArtworkContainer}>
          <p className={styles.max}>{t('card.artwork.3_max')}</p>
        </div>
      );
    }
  }

}

export default CardArtworks;

export const artworkToCardArtwork = (
  artwork: Artwork,
  locale: string,
  profile: Profile,
): CardArtworkI => {
  let cardArtwork: CardArtworkI = {
    id: artwork.id,
    path: artwork.path,
    name: artwork.name,
    description: artwork.description,
    images: {
      small: artwork.images?.small,
      mediumSmall: artwork.images?.mediumSmall,
      mediumLarge: artwork.images?.mediumLarge,
      large: artwork.images?.large,
      fetched: artwork.images?.fetched,
    },
    filmType: findTranslationOrDefault(artwork.film?.type, locale),
    filmTypeId: artwork.film?.type?.id,
    isFestivalSelected: artwork.film?.selections ? artwork.film.selections.length > 0 : false,
    isBroadcasted: artwork.film?.broadcasters ? artwork.film.broadcasters.length > 0 : false,
    directorName: artwork.film?.directorName,
    releaseYear: artwork.film?.releaseYear,
    type: artwork.type,
    photoUrls: getPhotosUrl(artwork?.urls),
    profileId: profile.id,
    profileName: profile.name,
    profileJob: findTranslationOrDefault(profile.job, locale),
    profilePath: profile.path,
    profileHasPublicPorfolio: hasPortfolioPublic(profile),
    embedUrl: getEmbedUrl(artwork?.urls)
  }

  return cardArtwork;
}

const getEmbedUrl = ((artworkUrls: ArtworkUrl[]): string => {
  return artworkUrls?.find(url => url?.type === ArtworkType.VIDEO_LINK)?.url
})

const getPhotosUrl = ((artworkUrls: ArtworkUrl[]): ArtworkUrl[] => {
  let photoUrls: ArtworkUrl[] = [];
  artworkUrls?.filter((url: ArtworkUrl) => {
    if (url?.type === ArtworkType.PICTURE_PORTFOLIO && url?.state === State.LIVE) {
      return photoUrls.push(url);
    }
  })
  return photoUrls;
});

interface BlockedArtworkCoverProps {
  artworkId: number;
  artworkName: string;
}

export const BlockedArtworkCover: React.FC<BlockedArtworkCoverProps> = props => {
  const { t } = useTranslate();
  const [loading, setLoading] = useState(false);

  const unblockArtwork = async () => {
    try {
      setLoading(true);
      await BlockController.unblockArtwork(props.artworkId);
      mutate(SWR_BLOCKED_ARTWORK_IDS);
      toast.dark(<ToastSucess msg={t('gallery.artwork.block.unblock_validation_toast', { name: props.artworkName })} />);
    } catch (err) {
      toast.dark(<ToastError msg={t('global.error_with_code', { code: err })} />);
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.blockMainContainer}>
      {
        loading &&
        <OwardLoader loading={loading} />
      }
      <div className={styles.contentContainer}>
        <p className={styles.text}>{t('gallery.artwork.block.artwork_blocked', { name: props.artworkName })}</p>
        <OwardButton
          name={t('gallery.artwork.block.unblock')}
          onClick={unblockArtwork}
          outlined
          dark
        />
      </div>
    </div>
  );
}

