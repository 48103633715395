import React from 'react';
import styles from './NeedConnectionModal.module.scss';
import { RegisterForm } from '@components/.';

interface NeedConnectionModalProps {
  headerMessage?: string;
}

export const NeedConnectionModal: React.FC<NeedConnectionModalProps> = props => {
  return (
    <div className={styles.mainContainer}>
      {
        props.headerMessage &&
        <div className={styles.headerMessageContainer}>
          <p className={styles.headerMessage}>{props.headerMessage}</p>
          <hr />
        </div>
      }
      <RegisterForm isOnModal />
    </div>
  );
}
