import React, { useState, useEffect } from 'react';
import { send, Artwork, ArtworkController, Profile, ArtworkType } from '@oward/openapi';
import { Section, PaginatedList, PaginatedListKey, EditButton, SimpleListKey, Divider, SimpleList } from '@components/admin';
import { EditArtwork } from './EditArtwork';
import { strToPath, stateToEmoji } from '@oward/common-utils';
import { Heading, OwardButton, OwardInput, OwardSwitch } from '@components/Core';

export const ArtworkList: React.FC = () => {
  const [items, setItems] = useState([] as Artwork[]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadItems = async () => {
    try {
      setLoading(true);
      setError(false);
      setItems(await send(ArtworkController.find()))
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const keys: PaginatedListKey<Artwork>[] = [
    {
      label: 'Id',
      value: item => item.id
    },
    {
      label: 'Name',
      value: item => item.name.concat(' ', stateToEmoji(item.state))
    },
    {
      label: 'Owner',
      value: item => item.profile?.name
    },
    {
      label: 'Video Link',
      value: item => item.urls?.find(url => url.type === ArtworkType.VIDEO_LINK)?.url
    }
  ];

  const filterItemStrings = (item: Artwork) => [
    item.id.toString(),
    strToPath(item.name),
    strToPath(item.profile?.name),
    strToPath(item.urls?.find(url => url.type === ArtworkType.VIDEO_LINK)?.url)
  ];

  const fetchOne = (item: Artwork) => send(ArtworkController.findOneAdmin(item.id));

  return (
    <Section
      title='List'
      subtitle='The list of all artworks in the database'
      childrenFullWidth
    >
      <PaginatedList
        keys={keys}
        items={items}
        filterItemStrings={filterItemStrings}
        size={100}
        editComponent={EditArtwork}
        fetchOne={fetchOne}
        reloadList={loadItems}
        loading={loading}
        error={error}
      />
    </Section>
  );
}

interface ArtworkListForProfileProps {
  formikId: string;
  profile: Profile;
  formik: any;
}

export const ArtworkListForProfile: React.FC<ArtworkListForProfileProps> = props => {
  const fetchOne = (item: Artwork) => send(ArtworkController.findOneAdmin(item.id));

  return (
    <React.Fragment>
      <table className='table is-fullwidth'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Displayed (MAX 3)</th>
            <th>Order</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {
            props.formik.values[props.formikId]?.map((artwork: Artwork, i) =>
              <tr key={i}>
                <td><p>
                  {artwork.name.concat(' ', stateToEmoji(artwork.state))}
                </p></td>
                <td><OwardSwitch
                  active={artwork.displayed === true}
                  onChange={() => {
                    props.formik.setFieldValue(
                      `${props.formikId}[${i}].displayed`,
                      artwork.displayed === true ? false : true
                    )
                  }}
                /></td>
                <td><OwardInput
                  type='number'
                  value={artwork.order.toString()}
                  onChange={(str) => {
                    props.formik.setFieldValue(
                      `${props.formikId}[${i}].order`,
                      parseInt(str)
                    )
                  }}
                /></td>
                <td><EditButton
                  component={EditArtwork}
                  fetchOne={fetchOne}
                  item={artwork}
                  label={'Edit artwork'}
                /></td>
              </tr>
            )
          }
        </tbody>
      </table>
    </React.Fragment>
  );
}

interface ArtworkListForFilmProps {
  filmId: number;
}

export const ArtworkListForFilm: React.FC<ArtworkListForFilmProps> = props => {
  const [items, setItems] = useState<Artwork[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadItems = async () => {
    try {
      setLoading(true);
      setError(false);
      setItems(await send(ArtworkController.findForFilm(props.filmId)))
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const keys: SimpleListKey<Artwork>[] = [
    {
      label: 'Id',
      value: item => <p>{item.id}</p>
    },
    {
      label: 'Artwork Name',
      value: item => <p>{item.name.concat(' ', stateToEmoji(item.state))}</p>
    },
    {
      label: 'Profile',
      value: item => <p>{item.profile?.name}</p>
    },
    {
      label: 'Displayed',
      value: item => <p>{item.displayed === true ? 'Yes' : 'No'}</p>
    },
  ];


  const fetchOne = (item: Artwork) => send(ArtworkController.findOneAdmin(item.id));

  return (
    <div style={{ marginBottom: '3rem', marginTop: '3rem' }}>
      <Divider />
      <Heading>ARTWORKS ASSOCIATED TO THIS FILM</Heading>
      <SimpleList
        keys={keys}
        items={items}
        editComponent={EditArtwork}
        fetchOne={fetchOne}
        reloadList={loadItems}
        loading={loading}
        error={error}
      />
    </div>
  );
}
