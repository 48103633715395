import React, { useState } from 'react';
import styles from './ResetPasswordModal.module.scss';
import { observer } from 'mobx-react-lite';
import { Modal, ModalConfirmOrCancelButtons, ModalTitle } from './Modal';
import { ModalKey, ModalStore, PopupStore } from '@stores/.';
import { useInjection, useTranslate } from '@hooks/.';
import { useFormik } from 'formik';
import { OwardButton, OwardFormInput, OwardLoader } from '@components/Core';
import { useRouter } from 'next/router';
import { UserController } from '@oward/openapi';
import { ERR_MSG_NOT_FOUND } from '@utils/.';
import { StoresBindings } from '@container/.';
import * as Yup from 'yup';

const schema = (t: any) => {
  return (
    Yup.object().shape({
      email: Yup.string().email(t('forms.email.invalid')).required(t('forms.email.required'))
    }))
};

export const ResetPasswordModal: React.FC = observer(() => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);
  const [error, setError] = useState<string>(undefined);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      try {
        setLoading(true);
        await UserController.sendResetPasswordEmail(values.email, locale);
        setError(undefined);
        modalStore.unpopModal(router);
        popupStore.openInformationPopUp(t('login.reset_password.confimation_msg', { email: values.email }));
      } catch (err) {
        if (err instanceof Error && err.message === ERR_MSG_NOT_FOUND) {
          setError(t('login.reset_password.email_not_found', { email: values.email }));
        }
        else {
          setError(t('global.error_retry'));
        }
      }
      finally {
        setLoading(false);
      }
    }

  });

  return (
    <Modal
      modalKey={ModalKey.RESET_PASSWORD}
      header={<ModalTitle name={t('login.reset_password.title')} />}
      footer={
        <form onSubmit={formik.handleSubmit} >
          <ModalConfirmOrCancelButtons confirmTxt={t('global.send')} />
        </form>
      }
    >
      <div className={styles.contentContainer}>
        <OwardLoader loading={loading} />
        <p className={styles.explanation}>
          {t('login.reset_password.explanation')}
        </p>
        <form onSubmit={formik.handleSubmit} >
          <OwardFormInput
            id='email'
            type='email'
            icon='far fa-envelope'
            label={t('forms.email.label')}
            placeholder={t('forms.email.placeholder')}
            formik={formik}
          />
          {
            error &&
            <p className={styles.error}>
              {error}
            </p>
          }
        </form>
      </div>
    </Modal>
  );
});
