import React, { useContext } from 'react';
import styles from './Sidebar.module.scss';

import { Items } from '../../../types';
import { CategoryKey } from '../../../keys';
import { NavigationContext } from '../../Navigation';

import { Menu } from '@components/Core/Menu';
import { Category } from './Category';
import { Item } from './Item';
import { observer } from 'mobx-react-lite';

export interface SidebarProps {
  items: Items;
}

export const Sidebar: React.FC<SidebarProps> = observer(props => {

  const store = useContext(NavigationContext);

  return (
    <aside className={styles.sidebar}>
      <Menu>{
        Object.entries(props.items).map(([key, value]) =>
          <React.Fragment key={key}>
            <Category label={key} className={styles.category} />
            <Menu.List> {value.map(item =>
              <Item
                key={item.key}
                label={item.key.toString()}
                icon={item.icon}
                active={item.key === store.active.itemKey
                  && key === store.active.categoryKey
                }
                onClick={() => store.setActive({
                  categoryKey: key as CategoryKey,
                  itemKey: item.key
                })
                }
              />
            )
            }
            </Menu.List>
          </React.Fragment>
        )
      }
      </Menu>
      <div style={{ height: '3rem' }} />
    </aside>
  );
});
