import { OwardFormTextArea, OwardInsert, OwardInsertYellow, OwardLoader, ToastError, ToastSucess } from '@components/Core';
import { StoresBindings } from '@container/.';
import { useInjection, useProfilesForUser, useTranslate } from '@hooks/.';
import { BlockController, ProfileController, ReportController } from '@oward/openapi';
import { UserStore, ModalKey, ModalStore } from '@stores/.';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { Modal, ModalConfirmOrCancelButtons, ModalTitle } from '..';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { mutate } from 'swr';
import { SWR_BLOCKED_PROFILE_IDS } from '@utils/.';

export const ReportBlockProfileModal: React.FC = observer(() => {
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const [showReportForm, setShowReportForm] = useState(userStore.isLogged ? false : true);

  useEffect(() => {
    // In case someone arrives directly on the gallery with a "report_profile=...." in the query,
    // or if the id of the profile in store is different from the one in the url (typically when going backward)
    // we need to fetch profile data
    (async function getProfileName() {
      try {
        if (
          !!router.query[ModalKey.REPORT_BLOCK_PROFILE] &&
          !modalStore.reportProfileInfos?.profileName
        ) {
          modalStore.setReportProfileInfos({
            profileName: (await ProfileController.findName(parseInt(router.query[ModalKey.REPORT_BLOCK_PROFILE] as string))).text
          })
        }
      }
      catch (err) {
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query[ModalKey.REPORT_BLOCK_PROFILE] as string]);

  useEffect(() => {
    setShowReportForm(userStore.isLogged ? false : true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query[ModalKey.REPORT_BLOCK_PROFILE] as string]);

  return (
    <React.Fragment>
      {
        showReportForm ?
          <ReportForm setShowReportForm={setShowReportForm} />
          :
          <ReportOrBlock setShowReportForm={setShowReportForm} />
      }
    </React.Fragment>
  )
});

interface ReportModalProps {
  setShowReportForm: (show: boolean) => void
}

const ReportOrBlock: React.FC<ReportModalProps> = observer(props => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const { profiles } = useProfilesForUser(locale);

  const blockProfile = async () => {
    if (profiles?.find(profile => profile.id === parseInt(router.query[ModalKey.REPORT_BLOCK_PROFILE] as string))) {
      toast.dark(<ToastError msg={t('modal.report_block_profile.no_block_mine_toast')} />);
      return;
    }

    try {
      await BlockController.blockProfile(parseInt(router.query[ModalKey.REPORT_BLOCK_PROFILE] as string));
      mutate(SWR_BLOCKED_PROFILE_IDS);
      toast.dark(<ToastSucess msg={t('modal.report_block_profile.block_validation_toast', { name: modalStore.reportProfileInfos?.profileName })} />);
      modalStore.unpopModal(router);
    } catch (err) {
      toast.dark(<ToastError msg={t('global.error_with_code', { code: err })} />);
    }
  }

  return (
    <React.Fragment>
      <Modal
        modalKey={ModalKey.REPORT_BLOCK_PROFILE}
        header={
          <React.Fragment>
            {
              modalStore.reportProfileInfos?.profileName ?
                <ModalTitle name={t(
                  'modal.report_block_profile.title',
                  { name: modalStore.reportProfileInfos?.profileName }
                )} />
                :
                <OwardLoader loading={true} positionStatic />
            }
          </React.Fragment>
        }
        footer={
          <ModalConfirmOrCancelButtons
            confirmTxt={t('modal.report_profile.validation_button')}
            noValidation
          />
        }
      >
        <OwardInsertYellow
          title={t('modal.report_block_profile.report_title')}
          text={t('modal.report_block_profile.report_text', { name: modalStore.reportProfileInfos?.profileName })}
          onClick={() => { props.setShowReportForm(true) }}
        />
        <OwardInsertYellow
          title={t('modal.report_block_profile.block_title')}
          text={t('modal.report_block_profile.block_text', { name: modalStore.reportProfileInfos?.profileName })}
          onClick={blockProfile}
        />
      </Modal>
    </React.Fragment>
  );
});

const ReportForm: React.FC<ReportModalProps> = observer(props => {
  const { t } = useTranslate();
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  const schema = (t: any) => {
    return (
      Yup.object().shape({
        message: Yup.string().required(t('modal.report_profile.need_msg')),
      }))
  };

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      try {
        await ReportController.reportProfile(values.message, parseInt(router.query[ModalKey.REPORT_BLOCK_PROFILE] as string));
        toast.dark(<ToastSucess msg={t('modal.report_profile.validation_toast')} />);
        formik.resetForm();
        modalStore.unpopModal(router);
      } catch (err) {
        toast.dark(<ToastError msg={t('global.error_with_code', { code: err })} />);
      }
    }
  });

  return (
    <React.Fragment>
      <Modal
        modalKey={ModalKey.REPORT_BLOCK_PROFILE}
        header={
          <React.Fragment>
            {
              modalStore.reportProfileInfos?.profileName ?
                <ModalTitle name={t(
                  'modal.report_profile.title',
                  { name: modalStore.reportProfileInfos?.profileName }
                )} />
                :
                <OwardLoader loading={true} positionStatic />
            }
          </React.Fragment>
        }
        footer={
          <ModalConfirmOrCancelButtons
            confirmTxt={t('modal.report_profile.validation_button')}
            validation={() => {
              formik.handleSubmit();
            }}
            cancelation={() => {
              if (userStore.isLogged) {
                props.setShowReportForm(false);
              }
              else {
                modalStore.unpopModal(router);
              }
            }}
          />
        }
      >
        <p>{t('modal.report_profile.description')}</p>
        <OwardFormTextArea
          id={'message'}
          placeholder={t('modal.report_profile.input_placeholder')}
          formik={formik}
        />
      </Modal>
    </React.Fragment>
  );
});
