import React, { useContext, useState } from "react";
import { useFormik } from 'formik';
import { observer } from "mobx-react-lite";
import { Job, JobsController, ProfileType, State } from "@oward/openapi";
import { locales } from '@locales/.';
import { Section, Divider, SubmitOrCancel, NavigationContext } from '@components/admin';
import { flattenFromGroupedMultiple, groupByLocaleMultiple } from '../utils';
import { OwardFormInput, Heading, OwardFormSelectLoad, entitiesToOptionsWithTranslation, OwardFormSelect } from '@components/Core';
import { useTranslate } from '@hooks/.';
import { EditProps } from '../types';
import * as Yup from 'yup';
import { stateToEmoji } from '@oward/common-utils';

const schema = (t: any) => {
  return (
    Yup.object().shape({
      name: Yup.string().required(),
      category: Yup.object().required(),
    }))
};

export const EditJob = observer((props: EditProps<Job>) => {
  const { t, locale } = useTranslate();
  const [error, setError] = useState(false);
  const navigation = useContext(NavigationContext);
  const translations = groupByLocaleMultiple(props.item.translations);

  const formik = useFormik({
    initialValues: {
      name: props.item.name,
      state: props.item.state,
      category: props.item.category,
      profileType: props.item.profileType,
      translations: translations
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      props.item.name = values.name;
      props.item.state = values.state;
      props.item.category = values.category;
      props.item.profileType = values.profileType;
      props.item.translations = flattenFromGroupedMultiple(values.translations);
      try {
        await JobsController.saveOne(props.item);
        if (props.notify) { props.notify(props.item) };
        setError(false);
        navigation.unpop();
      } catch {
        setError(true);
      }
    }
  });

  return (
    <Section
      title='Edit'
      subtitle='Change your entity properties'>
      <form className='box' onSubmit={formik.handleSubmit} >
        <Heading>General</Heading>
        <OwardFormInput
          id='name'
          label='Name *'
          placeholder='Réalisateur'
          formik={formik}
        />
        <OwardFormSelect
          id='state'
          label='State *'
          options={Object.values(State).map((state) => {
            return ({ value: state, label: state.concat(' ', stateToEmoji(state)) });
          })}
          formik={formik}
        />
        <OwardFormSelectLoad
          id='category'
          label='Category *'
          fetchFunction={JobsController.findCategories()}
          entitiesToOptions={entities => entitiesToOptionsWithTranslation(entities, locale)}
          formik={formik}
        />
        <OwardFormSelect
          id='profileType'
          label='Profile Type *'
          options={Object.values(ProfileType).map((profileType) => {
            return ({ value: profileType, label: profileType });
          })}
          formik={formik}
        />
        {
          locales.map(locale => {
            return (
              <React.Fragment key={locale}>
                <p className='has-text-danger'>ALL TRANSLATIONS ARE REQUIRED AND <span className='has-text-weight-bold'>IMPORTANT</span></p>
                <Heading>{locale}</Heading>
                {
                  translations[locale]?.map((item, idx) =>
                    <OwardFormInput
                      key={item.id}
                      id={`translations.${locale}[${idx}].name`}
                      label={item.gender.charAt(0) + item.gender.substring(1).toLowerCase() + ' *'}
                      placeholder={item.gender.charAt(0) + item.gender.substring(1).toLowerCase()}
                      value={formik.values.translations[locale][idx].name}
                      formik={formik}
                    />
                  )
                }
              </React.Fragment>
            )
          })
        }
        <Divider />
        <SubmitOrCancel error={error} noCancel={props.noCancel} />
      </form >
    </Section >
  )
});
