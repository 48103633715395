import React, { useCallback, useEffect, useState } from 'react';
import styles from './EditProfile.module.scss';
import { useInjection, useTranslate } from '@hooks/.';
import { ModalKey, ModalStore, PopupStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import {
  EditProfilePannelProps, OwardFormInput, OwardFormSelectLoad,
  OwardFormSelectLocation, OwardFormSelectLoadMulti,
  agentsToOptions, OwardFormSelect, ToastError, ToastSucess, associationsToOptions, Label
} from '@components/.';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import shallowequal from 'shallowequal';
import { Agent, AgentController, AssociationController, JobsController, ProfileController, ProfileSaveGeneral, send } from '@oward/openapi';
import { toast } from 'react-toastify';
import { EditProfileHeader, EditProfileSaveOrCancel, NeedPortfolio } from './EditProfileCommon';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import ReactCrop, { Crop } from 'react-image-crop';
import { jobLabelName, jobsToOptions, OwardLoader, OwardLoadFileButton } from '@components/Core';
import { observer } from 'mobx-react-lite';
import { dataURItoJpegBlob, ERR_MSG_UNAUTHORIZED, goToHomePageAndReload, remSizeToPixelNumber } from '@utils/.';
import request from 'superagent';
import { PREMIUM_LEVEL, ProfileType } from '@oward/common-utils';
import { action, runInAction } from 'mobx';
import { Gender } from '@oward/common-enums';

const schema = (t: any) => {
  return (
    Yup.object().shape({
      name: Yup.string().required(t('edit_profile.general.name_required')),
      job: Yup.object().required(),
      location: Yup.object().required(t('edit_profile.general.location_required'))
    }))
};

export const EditProfileGeneral: React.FC<EditProfilePannelProps> = observer(props => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  const formik = useFormik({
    initialValues: {
      name: props.store.profile.name,
      job: props.store.profile.job,
      gender: props.store.profile.gender,
      location: props.store.profile.location,
      languages: props.store.profile.languages,
      associations: props.store.profile.associations,
      agents: props.store.profile.agents,
      agentDisplayedId: (props.store.profile.agentDisplayed as Agent)?.id,
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      if (hasValuesChanged()) {
        let profileSaveGeneral: ProfileSaveGeneral = {
          id: props.store.profile.id,
          name: values.name,
          job: values.job,
          gender: values.gender,
          languages: values.languages,
          associations: values.associations,
          location: values.location,
          agents: (props.store.profile.premiumLevel >= PREMIUM_LEVEL.PORTFOLIO) ? values.agents : undefined,
          agentDisplayed: (props.store.profile.premiumLevel >= PREMIUM_LEVEL.PORTFOLIO) ? getAgentDisplayed(values.agentDisplayedId) : undefined,
        }
        if (!shallowequal(formik.initialValues, formik.values)) {
          try {
            props.store.setLoading(t('edit_profile.loading.saving_profile'));
            await ProfileController.saveGeneral(profileSaveGeneral);
            toast.dark(<ToastSucess msg={t('edit_profile.saved')} />);
          }
          catch (err) {
            if (err instanceof Error && err.message === ERR_MSG_UNAUTHORIZED) {
              popupStore.openInformationPopUp({
                msg: t('global.force_logout'),
                callback: () => { goToHomePageAndReload() }
              })
            }
            else {
              toast.dark(<ToastError msg={t('edit_profile.error.saving_profile', { code: err })} />);
            }
          }
          finally {
            props.store.setLoading(undefined);
          }
        }
        if (props.store.avatarPreview) {
          try {
            props.store.setLoading(t('edit_profile.loading.saving_picture'));
            let avatarName: string = props.store.profile.path + '-' + new Date().toISOString() + '.jpg';
            let avatarS3PutUrl = (await ProfileController.getAvatarPutUrl(avatarName)).text;
            await request.put(avatarS3PutUrl).send(dataURItoJpegBlob(props.store.avatarPreview));
            await ProfileController.saveAvatarUrl({
              id: props.store.profile.id,
              avatarUrl: process.env.AVATAR_URL_PREFIX + avatarName
            });
            toast.dark(<ToastSucess msg={t('edit_profile.saved_picture')} />);
          }
          catch (err) {
            if (err instanceof Error && err.message === ERR_MSG_UNAUTHORIZED) {
              popupStore.openInformationPopUp({
                msg: t('global.force_logout'),
                callback: () => { goToHomePageAndReload() }
              })
            }
            else {
              toast.dark(<ToastError msg={t('edit_profile.error.saving_picture', { code: err })} />);
            }
          }
          finally {
            props.store.setLoading(undefined);
          }
        }
      }
      else {
        toast.dark(<ToastSucess msg={t('edit_profile.saved_no_modif')} />);
      }

      props.store.reset();
    }
  });

  const hasValuesChanged = (): boolean => {
    return (!shallowequal(formik.initialValues, formik.values) || props.store.avatarPreview);
  }

  const getAgentDisplayed = (agentDisplayedId: number, agents?: Agent[]): Agent | null => {
    let displayedAgentFromAgents: Agent | undefined = (agents ?? formik.values.agents)?.find(
      agent => agent.id === agentDisplayedId
    );
    return (displayedAgentFromAgents === undefined) ? null : displayedAgentFromAgents;
  }

  const confirmationBeforeLeaving = (callback?: () => void) => {
    if (hasValuesChanged()) {
      popupStore.openConfirmationPopUp({
        msg: t('edit_profile.go_back_confirm'),
        callback: callback ?? props.store.reset
      });
    }
    else {
      callback ? callback() : props.store.reset();
    }
  }

  /* IMAGE CROP STUFF */
  const [upImg, setUpImg] = useState(undefined);
  const [img, setImg] = useState(undefined);
  const [imgLoading, setImgLoading] = useState(false);
  const [crop, setCrop] = useState<Crop>(undefined);
  const [completedCrop, setCompletedCrop] = useState<Crop>(undefined);

  const setPreviewAvatar = useCallback((crop: Crop, image: any) => {
    if (!crop || !image) {
      return;
    }

    const canvas = document.createElement('canvas');

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Update preview only if there's an actual crop (i.e. width > 0)
    if (canvas.width > 0) {
      runInAction(() => {
        props.store.avatarPreview = canvas.toDataURL('image/jpeg');
      });
    }
  }, [props.store]);

  const onLoad = useCallback((img) => {
    setImg(img);
    setImgLoading(false);

    const aspect = 1;
    // WARNING : works only for a ratio (i.e. aspect of '1') -> if aspect different, need to be taken into account...
    // https://github.com/DominicTobias/react-image-crop#how-can-i-center-the-crop may ba usefull (/!\ in '%' and we need 'px')
    const width = (img.width > img.height ? img.height : img.width) - remSizeToPixelNumber(styles.borderRadius);
    const height = (img.width > img.height ? img.height : img.width) - remSizeToPixelNumber(styles.borderRadius);

    const y = (img.height - height) / 2;
    const x = (img.width - width) / 2;

    const initialCrop: Crop = {
      unit: 'px',
      width,
      height,
      x,
      y,
      aspect,
    }
    setCrop(initialCrop);
    setPreviewAvatar(initialCrop, img);
    return false; // Return false if you set crop state in here.
  }, [setPreviewAvatar]);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImgLoading(true);
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    setPreviewAvatar(completedCrop, img);
  }, [completedCrop, img, setPreviewAvatar]);

  return (
    <div className={styles.formMainContainer}>
      <div className={classNames(styles.contentContainer)}>
        <EditProfileHeader
          onGoBack={() => { confirmationBeforeLeaving() }}
          title={t('edit_profile.' + props.store.activeCategory + '.title', { name: props.store.profile?.name })}
        />
        <form className={classNames(styles.formContentContainer)} onSubmit={formik.handleSubmit} >
          <OwardFormInput
            id='name'
            type='name'
            label={t('edit_profile.general.name') + ' *'}
            icon='far fa-user'
            placeholder={t('forms.name.placeholder')}
            onChange={action(value => { props.store.profile.name = value })} // For Preview
            formik={formik}
          />
          <OwardFormSelectLoad
            id='job'
            label={t(jobLabelName(props.store.profile.type)) + ' *'}
            description={props.store.profile.type === ProfileType.PRO && t('edit_profile.general.job_description')}
            fetchFunction={JobsController.list(locale, props.store.profile.type, props.store.profile.gender)}
            entitiesToOptions={jobsToOptions}
            onChange={action(value => {
              props.store.profile.job = value; // For Preview
            })}
            reload
            formik={formik}
          />
          {
            props.store.profile.type === ProfileType.PRO &&
            <OwardFormSelect
              id='gender'
              label={t('edit_profile.general.gender') + ' *'}
              description={t('edit_profile.general.gender_description')}
              options={[
                { value: Gender.MALE, label: t('edit_profile.general.gender_male') },
                { value: Gender.FEMALE, label: t('edit_profile.general.gender_female') }
              ]}
              onChange={action(async value => {
                props.store.profile.gender = value; // For Preview
                props.store.profile.job = await send(JobsController.getOneJob(props.store.profile.job.id, locale, value)); // For Preview
              })}
              formik={formik}
            />
          }

          <hr />
          <div className={styles.loadAvatarContainerContainer}>
            <div className={styles.loadAvatarContainer}>
              <Label name={t('edit_profile.general.avatar.title')} center />
              <OwardLoader loading={imgLoading} />
              <OwardLoadFileButton
                id='profile-avatar'
                name={t('edit_profile.general.avatar.button')}
                fullWidth
                outlined
                onClick={onSelectFile}
                onlyImages
              />
              <div className={styles.loadAvatarPreviewContainer}>
                {
                  upImg &&
                  <span
                    className={classNames('icon', styles.loadAvatarDelete)}
                    onClick={action(() => { props.store.avatarPreview = undefined; setUpImg(undefined); })}
                  >
                    <i className='fas fa-times'></i>
                  </span>
                }
                <ReactCrop
                  style={{ borderRadius: styles.borderRadius }}
                  circularCrop
                  src={upImg}
                  onImageLoaded={onLoad}
                  crop={crop}
                  onChange={(c) => setCrop(c)}
                  onComplete={(c) => setCompletedCrop(c)}
                />
              </div>
            </div>
          </div>
          <hr />
          <OwardFormSelectLocation
            id='location'
            label={t('edit_profile.general.location') + ' *'}
            description={t('edit_profile.general.location_description')}
            onChange={action(value => { props.store.profile.location = value; })} // For Preview
            formik={formik}
          />
          <OwardFormSelectLoadMulti
            id='languages'
            label={t('edit_profile.general.languages')}
            fetchFunction={ProfileController.listLanguages(locale)}
            formik={formik}
          />
          {
            props.store.profile.type !== ProfileType.COMPANY &&
            <React.Fragment>
              <hr />
              <OwardFormSelectLoadMulti
                id='associations'
                label={t('edit_profile.general.associations')}
                description={
                  <p className={styles.createEntity}>
                    {t('edit_profile.general.associations_description')}
                    <a
                      href={`mailto:${t('mail.contact_address')}?subject=${t('mail.subject_asso_missing')}`}
                      target='_blank'
                      rel='noreferrer'
                      className={styles.clink}
                    >{t('mail.contact_address')}
                    </a>
                  </p>}
                fetchFunction={AssociationController.list(locale)}
                entitiesToOptions={associationsToOptions}
                formik={formik}
              />
              <hr />
              <NeedPortfolio
                premiumLevel={props.store.profile.premiumLevel}
                profileId={props.store.profile.id}
                text={t('edit_profile.general.need_portfolio')}
                onClick={() => confirmationBeforeLeaving(() => {
                  router.push(
                    `/[lang]/subscriptions/[profileId]`,
                    `/${locale}/subscriptions/${props.store.profile.id}`
                  );
                })}
              >
                <OwardFormSelectLoadMulti
                  id='agents'
                  label={t('edit_profile.general.agent.list_label')}
                  description={
                    <p className={styles.createEntity}>
                      {t('edit_profile.create_intro')}
                      <span
                        className={styles.click}
                        onClick={() => modalStore.openModalNewStack(router, ModalKey.NEW_AGENT, 1)}
                      >{t('edit_profile.create_click')}</span>
                      {t('edit_profile.create_outro')}
                    </p>
                  }
                  reload
                  fetchFunction={AgentController.list(locale)}
                  entitiesToOptions={(entityList) => { return agentsToOptions(entityList, true) }}
                  onChange={action(value => {
                    props.store.profile.agents = value;
                    if (props.store.profile.agents?.length === 1) {
                      props.store.profile.agentDisplayed = props.store.profile.agents[0];
                      formik.setFieldValue('agentDisplayedId', props.store.profile.agents[0]?.id)
                    }
                    else {
                      // Refresh agent displayed in case agent displayed is removed from agent list
                      props.store.profile.agentDisplayed = getAgentDisplayed(formik.values.agentDisplayedId, value);
                      if (props.store.profile.agentDisplayed === null) { formik.setFieldValue('agentDisplayedId', null) }
                    }

                  })} // For Preview
                  formik={formik}
                />
                <OwardFormSelect
                  id='agentDisplayedId'
                  label={t('edit_profile.general.agent.displayed_label')}
                  isClearable
                  options={formik.values.agents?.map((agent) => {
                    return ({ value: agent.id, label: agent.name });
                  })}
                  onChange={action(value => { props.store.profile.agentDisplayed = getAgentDisplayed(value, formik.values.agents) })} // For Preview
                  formik={formik}
                />
              </NeedPortfolio>
            </React.Fragment>
          }
          {/* For dropdown */}
          <div style={{ height: '15rem' }} />
        </form>
      </div>
      <form onSubmit={formik.handleSubmit} className={styles.formFooterContainer}>
        <EditProfileSaveOrCancel onCancel={() => { confirmationBeforeLeaving() }} formik={formik} />
      </form>
    </div>
  );
})
