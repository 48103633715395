import React from 'react';
import styles from './SendBriefModal.module.scss'
import { useRouter } from 'next/router';
import { StoresBindings } from '@container/.';
import { useInjection, useTranslate } from '@hooks/.';
import { UtilitiesController } from '@oward/openapi';
import { ModalKey, ModalStore, UserStore } from '@stores/.';
import { OwardFormInput, OwardFormTextArea, ToastError, ToastSucess } from '@components/Core';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { Modal, ModalConfirmOrCancelButtons, ModalTitle } from '.';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

export const SendBriefModal: React.FC = observer(() => {
  const { t } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  const schema = (t: any) => {
    return (
      Yup.object().shape({
        brief: Yup.string().required(t('modal.send_brief.brief_required')),
        email: !userStore.isLogged && Yup.string().email(t('forms.email.invalid')).required(t('modal.send_brief.mail_required'))
      }))
  };

  const formik = useFormik({
    initialValues: {
      brief: '',
      email: '',
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      try {
        await UtilitiesController.sendBrief(values.brief, values.email);
        toast.dark(<ToastSucess msg={t('modal.send_brief.validation_toast')} />);
        formik.resetForm();
        modalStore.unpopModal(router);
      } catch (err) {
        toast.dark(<ToastError msg={t('global.error_with_code', { code: err })} />);
      }
    }
  })

  return (
    <React.Fragment>
      <Modal
        modalKey={ModalKey.SEND_BRIEF}
        header={<ModalTitle name={t('modal.send_brief.title')} />}
        footer={
          <ModalConfirmOrCancelButtons
            confirmTxt={t('modal.send_brief.button')}
            validation={() => {
              formik.handleSubmit();
            }}
          />
        }
      >
        <div className={styles.descriptionContainer}>
          <p className={styles.bold}>{t('modal.send_brief.description_1')}</p>
          <p>{t('modal.send_brief.description_2')}</p>
        </div>
        <OwardFormTextArea
          id={'brief'}
          placeholder={t('modal.send_brief.input_placeholder')}
          formik={formik}
        />
        {
          !userStore.isLogged &&
          <OwardFormInput
            id='email'
            type='email'
            label={t('modal.send_brief.mail_label')}
            icon='far fa-envelope'
            placeholder={t('forms.email.placeholder')}
            formik={formik}
          />
        }
      </Modal>
    </React.Fragment>
  )
});
