import React, { useContext, useEffect, useState } from "react"
import { OwardButton } from "@components/Core";
import { findTranslation, strToPath } from "@oward/common-utils";
import { LocationController, send, LocationCountry } from "@oward/openapi";
import { NavigationContext, PaginatedList, PaginatedListKey, Section } from "../AdminComponents"
import { EditCountry } from "./EditCountry";

export const CountryList: React.FC = () => {
  const store = useContext(NavigationContext);
  const [items, setItems] = useState([] as LocationCountry[]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = async () => {
    try {
      setLoading(true);
      setError(false);
      setItems(await send(LocationController.findCountries()))
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const keys: PaginatedListKey<LocationCountry>[] = [
    {
      label: 'Country Id',
      value: item => item.id
    },
    {
      label: 'Name',
      value: item => item.name
    },
    {
      label: 'Name Fr',
      value: item => findTranslation(item, 'fr')
    },
    {
      label: 'Name En',
      value: item => findTranslation(item, 'en')
    },
    {
      label: 'ISO Code',
      value: item => item.code
    },
  ];

  const filterItemStrings = (item: LocationCountry) => [
    item.id.toString(),
    strToPath(findTranslation(item, 'fr')),
    strToPath(findTranslation(item, 'en')),
    strToPath(item.code)
  ];

  const fetchOne = async (item: LocationCountry) => await send(LocationController.findCountry(item.id))

  return (
    <React.Fragment>
      <Section
        title='Country List'
        subtitle='List of all the Countries in the database'
        childrenFullWidth>
        <PaginatedList
          keys={keys}
          items={items}
          filterItemStrings={filterItemStrings}
          size={15}
          editComponent={EditCountry}
          fetchOne={fetchOne}
          reloadList={loadItems}
          loading={loading}
          error={error}
        />
      </Section>
      <div style={{ height: '2rem' }} />
      <Section
        title='Create new Country'
        subtitle='Create it WHEN PRESSING THIS BUTTON. Cannot be undone, please fill all needed infos in next screen'
      >
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingBottom: '3rem' }}>
          <OwardButton
            name='Create new Country'
            onClick={async () => {
              let newCountry: LocationCountry = await send(LocationController.createCountry());
              loadItems();
              store.pop({
                label: 'Create Country',
                element: <EditCountry item={newCountry} notify={loadItems} noCancel />
              });
            }}
          />
        </div>
      </Section>
    </React.Fragment>

  );
}
