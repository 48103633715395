import { useState } from 'react';
import styles from './GroupDiscussionElements.module.scss';
import { useInjection, useTranslate } from '@hooks/.';
import { LayoutStore, MessageStore, ModalStore, PopupStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { DiscussionMemberRole } from '@oward/common-enums';
import { DiscussionMember, GroupDiscussionProfileInfoI, MessageController, send, Request } from '@oward/openapi';
import { onTouchDevice, remSizeToPixelNumber } from '@utils/utils';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import classNames from 'classnames';
import { CreateDiscussionStore, ManageDiscussionStore } from '@components/Modal/Messages';
import { toast } from 'react-toastify';
import { OwardInput, OwardLoader, OwardSwitchMultipleFlat, ToastError, ToastSucess } from '..';
import { observer } from 'mobx-react-lite';
import { ERR_MSG_ABORTED, SWR_DISCUSSIONS_FOR_PROFILE } from '@utils/.';
import { useRouter } from 'next/router';
import { mutate } from 'swr';
import { ProfileAvatar } from '@components/Core/Avatar/ProfileAvatars';
import { SeeOrActionProfilePopUp } from '@components/Core/PopUp/ProfilesPopUps';

interface GroupDiscussionProfileSearchAndListProps {
  store: CreateDiscussionStore | ManageDiscussionStore;
  alreadyInDiscussion?: number[]; // Profile Ids of Profile already member of the discussion
}

// Keep a ref of the last groupDiscussionGetProfileInfosList request, to abort it if a new one is sent
let getProfileInfosReq: Request<GroupDiscussionProfileInfoI[]> = null;

export const GroupDiscussionProfileSearchAndList: React.FC<GroupDiscussionProfileSearchAndListProps> = observer(props => {
  const { t, locale } = useTranslate();
  const store = props.store;

  let [searchedProfileInfosList, setSearchedProfileInfosList] = useState<GroupDiscussionProfileInfoI[]>([]);
  const searchProfilInputMinSize = 3;

  const searchProfileInfos = async (input: string) => {
    if (getProfileInfosReq !== null) {
      getProfileInfosReq.abort();
    }
    let req: Request<GroupDiscussionProfileInfoI[]> = MessageController.groupDiscussionGetProfileInfosList(input, locale);

    try {
      setSearchedProfileInfosList([]);
      store.setError('');
      store.setLoading(true);
      getProfileInfosReq = req;
      setSearchedProfileInfosList(await send(req));
      getProfileInfosReq = null;
      store.setLoading(false);
    }
    catch (err) {
      if (err instanceof Error && err.message === ERR_MSG_ABORTED) {
        // Do nothing, as a request aborted mean a new one is sent
      }
      else {
        store.setLoading(false);
        store.setError(t('global.error_with_code', { code: err }));
      }
    }
  };

  return (
    <div>
      <OwardInput
        label={t('modal.create_group_discussion.add_members.search_input_label')}
        placeholder={t('modal.create_group_discussion.add_members.search_input_placeholder')}
        onChange={(input) => {
          store.setSearchProfileInput(input);
          if (input.length >= searchProfilInputMinSize) {
            searchProfileInfos(input);
          }
        }}
        value={store.searchProfileInput}
      />
      <div className={styles.searchedProfileListContainer}>
        {
          store.loading ?
            <OwardLoader loading={store.loading} positionStatic />
            :
            <>
              {
                store.searchProfileInput.length < searchProfilInputMinSize ?
                  <>
                    {
                      store.searchProfileInput &&
                      <p className={styles.noProfileText}>
                        {t('modal.create_group_discussion.add_members.at_least_n_chars', { count: searchProfilInputMinSize })}
                      </p>
                    }
                  </>
                  :
                  searchedProfileInfosList?.length > 0 ?
                    searchedProfileInfosList.map(
                      (profileInfos, i) => <ProfileInfosRectangle
                        profileInfos={profileInfos}
                        store={store}
                        key={i}
                        alreadyInDiscussion={props.alreadyInDiscussion}
                      />
                    )
                    :
                    <p className={styles.noProfileText}>
                      {t('modal.create_group_discussion.add_members.no_profile_found')}
                    </p>
              }
            </>
        }
      </div>
    </div>
  );
});

interface ProfileInfosRectangleProps {
  profileInfos: GroupDiscussionProfileInfoI,
  store: CreateDiscussionStore | ManageDiscussionStore,
  alreadyInDiscussion?: number[]; // Profile Ids of Profile already member of the discussion
}

export const ProfileInfosRectangle: React.FC<ProfileInfosRectangleProps> = props => {
  const { t, locale } = useTranslate();
  const messageStore = useInjection<MessageStore>(StoresBindings.MESSAGE);
  const store = props.store;

  return (
    <div
      className={`${styles.profileRectangleContainer}`}
      onClick={() => {
        if (props.alreadyInDiscussion?.find(id => id === props.profileInfos.profileId)) {
          toast.dark(<ToastError msg={t('modal.manage_group_discussion.already_in_discussion', { name: props.profileInfos.profileName })} />);
          return;
        }
        if (store.newMembers.find(member => member.profileInfos.profileId === props.profileInfos.profileId)) {
          toast.dark(<ToastError msg={t('modal.create_group_discussion.add_members.already_in_discussion', { name: props.profileInfos.profileName })} />);
          return;
        }
        if (messageStore.currentProfile.id === props.profileInfos.profileId) {
          toast.dark(<ToastError msg={t('modal.create_group_discussion.add_members.cant_add_mine')} />);
          return;
        }
        store.addNewMember({
          profileInfos: props.profileInfos,
          role: DiscussionMemberRole.MEMBER
        });
        store.setSearchProfileInput('');
      }}
    >
      <div className={`${styles.profileContentContainer}`}>
        {
          props.profileInfos.profileAvatarUrl &&
          <div className={styles.avatarContainer}>
            <LazyLoadImage
              src={props.profileInfos.profileAvatarUrl}
              width={remSizeToPixelNumber(styles.avatarSizeBig)}
              height={remSizeToPixelNumber(styles.avatarSizeBig)}
              className={styles.avatar}
              alt={t('alt.avatar_just_name', { name: props.profileInfos.profileName })}
              effect='blur'
            />
          </div>
        }
        <div className={styles.nameJobContainer}>
          <h2 className={styles.name}>
            {props.profileInfos.profileName}
          </h2>
          <p className={styles.job}>
            {props.profileInfos.profileJob}
          </p>
        </div>
      </div>
    </div>
  )
}

export interface NewMemberI {
  profileInfos: GroupDiscussionProfileInfoI,
  role: DiscussionMemberRole,
}

interface NewMemberListProps {
  newMembers: NewMemberI[];
  removeNewMember: (profileId: number) => void,
}

export const NewMembersList: React.FC<NewMemberListProps> = observer(props => {
  const { t } = useTranslate();
  const { newMembers, removeNewMember } = props;

  return (
    <>
      {
        newMembers.length > 0 &&
        <div className={styles.newMembersMainContainer}>
          <p className={styles.title}>{t('modal.create_group_discussion.add_members.add_to_discussion')}</p>
          <div className={styles.newMembersContainer}>
            {newMembers.map((member, i) => <NewMember newMember={member} removeNewMember={removeNewMember} key={i} />)}
          </div>
        </div>
      }
    </>
  );
});

interface NewMemberProps {
  newMember: NewMemberI;
  removeNewMember: (profileId: number) => void,
}

const NewMember: React.FC<NewMemberProps> = observer(props => {
  const { t } = useTranslate();
  const { newMember, removeNewMember } = props;

  return (
    <div className={styles.newMemberContainer}>
      {
        props.newMember.profileInfos.profileAvatarUrl &&
        <div className={styles.avatarContainer}>
          <LazyLoadImage
            src={props.newMember.profileInfos.profileAvatarUrl}
            width={remSizeToPixelNumber(styles.avatarSize)}
            height={remSizeToPixelNumber(styles.avatarSize)}
            className={styles.avatar}
            alt={t('alt.avatar_just_name', { name: props.newMember.profileInfos.profileName })}
            effect='blur'
          />
        </div>
      }
      <div className={styles.nameContainer}>
        {newMember.profileInfos.profileName}
      </div>
      <div className={`${styles.memberIconContainer}`}>
        <p
          className={styles.memberIcon}
          onClick={() => { removeNewMember(newMember.profileInfos.profileId) }}
        >
          <span className={`icon`}>
            <i className='fas fa-times'></i>
          </span>
        </p>
      </div>
    </div>
  );
});

interface NewMemberRoleLineProps {
  newMember: NewMemberI;
  store: CreateDiscussionStore | ManageDiscussionStore;
}

export const NewMemberRoleLine: React.FC<NewMemberRoleLineProps> = observer(props => {
  const { t } = useTranslate();
  const { newMember, store } = props;

  return (
    <div className={styles.memberContainer}>
      <div className={styles.avatarAndNameContainer}>
        {
          props.newMember.profileInfos.profileAvatarUrl &&
          <div className={styles.avatarContainer}>
            <LazyLoadImage
              src={props.newMember.profileInfos.profileAvatarUrl}
              width={remSizeToPixelNumber(styles.avatarSize)}
              height={remSizeToPixelNumber(styles.avatarSize)}
              className={styles.avatar}
              alt={t('alt.avatar_just_name', { name: props.newMember.profileInfos.profileName })}
              effect='blur'
            />
          </div>
        }
        <p className={styles.name}>
          {newMember.profileInfos.profileName}
        </p>
      </div>
      <div className={styles.switchContainer}>
        <OwardSwitchMultipleFlat
          activeOption={newMember.role}
          options={Object.values(DiscussionMemberRole).map((role) => {
            return { value: role, label: t(`modal.create_group_discussion.member_role.${role}.name`) };
          })}
          onChange={option => { store.setNewMemberRole(newMember.profileInfos.profileId, option.value) }}
          small
        />
      </div>
    </div>
  );
});

interface DiscussionRoleExplanationProps {
  isCreation?: boolean;
}

export const DiscussionRoleExplanation: React.FC<DiscussionRoleExplanationProps> = props => {
  const [hidden, setHidden] = useState(true);
  const { t } = useTranslate();

  return (
    <div className={styles.explanationContainer}>
      {
        hidden ?
          <p
            className={styles.knowMore}
            onClick={() => { setHidden(false) }}
          >
            {t(`modal.create_group_discussion.member_role.know_more`)}
          </p>
          :
          <>
            {
              Object.values(DiscussionMemberRole).map((role, i) => {
                return (
                  <p key={i}>
                    <span className={styles.explanationName}>{t(`modal.create_group_discussion.member_role.${role}.name`)}</span>
                    <span>{t(`modal.create_group_discussion.member_role.${role}.explanation`)}</span>
                  </p>
                )
              })
            }
            {
              props.isCreation &&
              <p className={styles.creatorAsAdmin}>{t(`modal.create_group_discussion.member_role.creator_as_admin`)}</p>
            }
          </>
      }
    </div>
  )
}

interface DiscussionMemberCountProps {
  count: number;
}

export const DiscussionMemberCount: React.FC<DiscussionMemberCountProps> = props => {
  const { t } = useTranslate();

  return (
    <div className={styles.memberCountContainer}>
      <p className={styles.memberCount}>{t(`modal.manage_group_discussion.member_count`, { memberCount: props.count })}</p>
    </div>
  )
}


interface LeaveDiscussionButtonProps {
  store: ManageDiscussionStore;
}

export const LeaveDiscussionButton: React.FC<LeaveDiscussionButtonProps> = observer(props => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const messageStore = useInjection<MessageStore>(StoresBindings.MESSAGE);
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const layoutStore = useInjection<LayoutStore>(StoresBindings.LAYOUT);
  const { store } = props;

  const leaveDiscussion = async () => {
    try {
      store.setLoading(true);
      store.setError(undefined);
      await send(MessageController.leaveDiscussion(store.discussion.id, messageStore.currentProfile?.id));
      await mutate([SWR_DISCUSSIONS_FOR_PROFILE, messageStore.currentProfile?.id]);
      modalStore.unpopModal(router);
      await router.push(
        `/[lang]/messages/[profilePath]`,
        `/${locale}/messages/${messageStore.currentProfile.path}`
      );
      if (onTouchDevice()) {
        layoutStore.openMessagesDiscussionContainer(false);
      }
      toast.dark(<ToastSucess msg={t('modal.manage_group_discussion.leave.confirmation_toast', { discussionName: store.discussion.name })} />);
    } catch (err) {
      store.setError(t('global.error_with_code', { code: err }));
    }
    finally {
      store.setLoading(false);
    }
  }
  return (
    <div className={styles.leaveDiscussionContainer}>
      <p
        className={styles.leaveDiscussion}
        onClick={() => {
          let otherAdmins = store.discussion.members.filter(
            member => member.role === DiscussionMemberRole.ADMIN && member.profileId !== messageStore.currentProfile.id
          );
          if (otherAdmins?.length < 1) {
            toast.dark(<ToastError msg={t('modal.manage_group_discussion.leave.only_admin')} />);
            return;
          }
          popupStore.openConfirmationPopUp({
            msg: t('modal.manage_group_discussion.leave.confirmation_popup', { discussionName: store.discussion.name }),
            callback: leaveDiscussion
          });
        }}
      >
        {t('modal.manage_group_discussion.leave.button')}
      </p>
    </div>
  );
});

interface DiscussionMemberViewRoleLineProps {
  member: DiscussionMember;
}

export const DiscussionMemberViewRoleLine: React.FC<DiscussionMemberViewRoleLineProps> = props => {
  const { t } = useTranslate();
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);
  const { member } = props;

  return (
    <div className={styles.memberContainer}>
      <div
        className={styles.avatarAndNameContainer}
        onClick={() => {
          popupStore.openGenericPopUp(
            <SeeOrActionProfilePopUp profileId={props.member.profile.id} />
          )
        }}
      >
        {
          props.member.profile.avatarUrl &&
          <div className={styles.avatarContainer}>
            <LazyLoadImage
              src={props.member.profile.avatarUrl}
              width={remSizeToPixelNumber(styles.avatarSize)}
              height={remSizeToPixelNumber(styles.avatarSize)}
              className={styles.avatar}
              alt={t('alt.avatar_just_name', { name: props.member.profile.name })}
              effect='blur'
            />
          </div>
        }
        <p className={styles.name}>
          {member.profile.name}
        </p>
      </div>
      <div className={styles.roleContainer}>
        <p className={styles.role}>{t(`modal.create_group_discussion.member_role.${member.role}.name`)}</p>
      </div>
    </div>
  );
}

interface DiscussionMemberManageLineProps {
  member: DiscussionMember;
  store: ManageDiscussionStore;
}

export const DiscussionMemberManageLine: React.FC<DiscussionMemberManageLineProps> = observer(props => {
  const { t } = useTranslate();
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);
  const messageStore = useInjection<MessageStore>(StoresBindings.MESSAGE);
  const { member, store } = props;

  const removeFromDiscussion = async () => {
    try {
      store.setLoading(true);
      store.setError(undefined);
      await send(MessageController.removeProfileFromDiscussion(store.discussion.id, messageStore.currentProfile.id, member.profile.id));
      await store.refreshDiscussionInfos(store.discussion.id);
      toast.dark(<ToastSucess msg={t('messages.group_discussion.remove.confirmation_toast', { profileName: member.profile.name, discussionName: store.discussion.name })} />);
    } catch (err) {
      store.setError(t('global.error_with_code', { code: err }));
    }
    finally {
      store.setLoading(false);
    }
  }

  const changeRole = async (newRole: DiscussionMemberRole) => {
    try {
      store.setLoading(true);
      store.setError(undefined);
      await send(MessageController.changeProfileRoleinDiscussion(store.discussion.id, messageStore.currentProfile.id, member.profile.id, newRole));
      await store.refreshDiscussionInfos(store.discussion.id);
      toast.dark(<ToastSucess msg={t('messages.group_discussion.change_role.confirmation_toast', { profileName: member.profile.name, discussionName: store.discussion.name })} />);
    } catch (err) {
      store.setError(t('global.error_with_code', { code: err }));
    }
    finally {
      store.setLoading(false);
    }
  }

  interface RemoveIconProps {
    isMobile?: boolean;
  }
  const RemoveIcon: React.FC<RemoveIconProps> = (props) => {
    return <div className={`${styles.memberIconContainer}`}>
      <p
        className={classNames(styles.memberIcon, props.isMobile ? styles.mobile : styles.desktop, 'has-tooltip-left has-tooltip-arrow')}
        data-tooltip={t('messages.group_discussion.remove.tooltip')}
        onClick={() => {
          if (messageStore.currentProfile.id === member.profile.id) {
            toast.dark(<ToastError msg={t('messages.group_discussion.remove.not_self')} />);
            return;
          }
          popupStore.openConfirmationPopUp({
            msg: t('messages.group_discussion.remove.confirmation_popup', { profileName: member.profile.name, discussionName: store.discussion.name }),
            callback: removeFromDiscussion
          });
        }}
      >
        <span className={`icon`}>
          <i className='fas fa-times'></i>
        </span>
      </p>
    </div>;
  }

  return (
    <div className={styles.memberContainer}>
      <div className={styles.avatarAndNameContainer}>
        {
          props.member.profile.avatarUrl &&
          <div
            className={styles.avatarContainer}
            onClick={() => {
              popupStore.openGenericPopUp(
                <SeeOrActionProfilePopUp profileId={props.member.profile.id} />
              )
            }}
          >
            <LazyLoadImage
              src={props.member.profile.avatarUrl}
              width={remSizeToPixelNumber(styles.avatarSize)}
              height={remSizeToPixelNumber(styles.avatarSize)}
              className={styles.avatar}
              alt={t('alt.avatar_just_name', { name: props.member.profile.name })}
              effect='blur'
            />
          </div>
        }
        <p
          className={styles.name}
          onClick={() => {
            popupStore.openGenericPopUp(
              <SeeOrActionProfilePopUp profileId={props.member.profile.id} />
            )
          }}
        >
          {member.profile.name}
        </p>
        {
          messageStore.currentProfile.id !== member.profile.id &&
          <RemoveIcon isMobile />
        }
      </div>
      {
        messageStore.currentProfile.id === member.profile.id ?
          <div className={styles.youAdminContainer}>
            <p className={styles.role}>{t(`modal.manage_group_discussion.you_are_admin`)}</p>
          </div>
          :
          <>
            <div className={styles.switchContainer}>
              <OwardSwitchMultipleFlat
                activeOption={member.role}
                options={Object.values(DiscussionMemberRole).map((role) => {
                  return { value: role, label: t(`modal.create_group_discussion.member_role.${role}.name`) };
                })}
                onChange={option => {
                  if (messageStore.currentProfile.id === member.profile.id) {
                    toast.dark(<ToastError msg={t('messages.group_discussion.change_role.not_self')} />);
                    return;
                  }
                  changeRole(option.value as DiscussionMemberRole)
                }}
                small
              />
            </div>
            <RemoveIcon />
          </>
      }
    </div>
  );
});
