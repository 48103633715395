import React from 'react';
import styles from './GalleryBanner.module.scss'
import { useInjection, useTranslate } from '@hooks/.';
import { ModalKey, ModalStore, UserStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { useRouter } from 'next/router';

export const GalleryBanner = () => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  return (
    <div className={`${styles.mainContainer}`}>

      <div className={`${styles.imageContainer}`}>
        <div className={`${styles.contentContainer}`}>
          <div className={`${styles.textContainer}`}>
            <h3 className={`${styles.title}`}>
              {t('gallery.banner.title_1')}<span className={`${styles.white}`}>{t('gallery.banner.title_2')}</span><br />
              {t('gallery.banner.title_3')}
            </h3>
          </div>
          <div className={styles.linksContainer}>
            {
              userStore.isLogged ?
                <GalleryLink
                  name={t('gallery.banner.my_profiles')}
                  onClick={() => {
                    router.push(
                      '/[lang]/account/profiles',
                      `/${locale}/account/profiles`
                    )
                  }}
                />
                :
                <GalleryLink
                  name={t('gallery.banner.register')}
                  onClick={() => {
                    router.push(
                      '/[lang]/register',
                      `/${locale}/register`
                    )
                  }}
                />
            }
            <GalleryLink
              name={t('gallery.banner.send_brief')}
              onClick={() => { modalStore.openModalNewStack(router, ModalKey.SEND_BRIEF, 1) }}
            />
          </div>
        </div>

      </div>
    </div>
  );
}

export default GalleryBanner;

interface GalleryLinkProps {
  name: string;
  onClick: () => void;
}

const GalleryLink: React.FC<GalleryLinkProps> = props => {

  return (
    <div className={styles.linkContainer}>
      <p onClick={props.onClick} className={styles.link}>
        {props.name}
      </p>
    </div>
  );
}
