import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import {
  BroadcasterController, Film, FilmsController, State
} from '@oward/openapi';
import { Divider, Section, SubmitOrCancel, NavigationContext } from '@components/admin';
import { EditProps } from '../types';
import { ArtworkListForFilm } from '../Artworks';
import {
  broadcastersToOptions,
  entitiesToOptionsWithTranslation,
  Heading,
  OwardFormInput,
  OwardFormSelect,
  OwardFormSelectLoad,
  OwardFormSelectLoadMulti,
  OwardLoader,
} from '@components/Core';
import { useTranslate } from '@hooks/.';
import { stateToEmoji } from '@oward/common-utils';
import * as Yup from 'yup';
import { FilmSelectionList } from './FilmList';

const schema = (t: any) => {
  return (
    Yup.object().shape({
      name: Yup.string().required(),
      releaseYear: Yup.number().required().max(2050),
    }))
};

export const EditFilm = observer((props: EditProps<Film>) => {
  const { t, locale } = useTranslate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigation = useContext(NavigationContext);

  const formik = useFormik({
    initialValues: {
      name: props.item.name,
      state: props.item.state,
      type: props.item.type,
      directorName: props.item.directorName,
      releaseYear: props.item.releaseYear,
      broadcasters: props.item.broadcasters,
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      props.item.name = values.name;
      props.item.state = values.state;
      props.item.type = values.type;
      props.item.directorName = values.directorName;
      props.item.releaseYear = values.releaseYear;
      props.item.broadcasters = values.broadcasters;
      try {
        setLoading(true);
        await FilmsController.saveOne(props.item);
        if (props.notify) { props.notify(props.item) };
        setError(false);
        navigation.unpop();
      } catch (err) {
        setError(true);
      }
      finally {
        setLoading(false);
      }
    }
  });

  return (
    <React.Fragment>
      <Section
        title={`Edit '${formik.initialValues.name}' film`}
        subtitle={`Change the film properties`}
        childrenFullWidth
      >
        <form className='box' style={{ position: 'relative' }} onSubmit={formik.handleSubmit} >
          <OwardLoader loading={loading} />
          <Heading>GENERAL</Heading>
          <OwardFormInput
            id='name'
            type='name'
            label='Name *'
            icon='fas fa-signature'
            formik={formik}
          />
          <OwardFormSelect
            id='state'
            label='State *'
            options={Object.values(State).map((state) => {
              return ({ value: state, label: state.concat(' ', stateToEmoji(state)) });
            })}
            formik={formik}
          />
          <OwardFormSelectLoad
            id='type'
            label='Type *'
            fetchFunction={FilmsController.findTypesAdmin()}
            entitiesToOptions={entities => entitiesToOptionsWithTranslation(entities, locale)}
            formik={formik}
          />
          <OwardFormInput
            id='directorName'
            type='name'
            label='Director Name *'
            icon='fas fa-user'
            formik={formik}
          />
          <OwardFormInput
            id='releaseYear'
            type='number'
            label={'Release Year (\'0\' for \'In Development\') *'}
            formik={formik}
          />
          <OwardFormSelectLoadMulti
            id='broadcasters'
            label='Broadcasters'
            fetchFunction={BroadcasterController.findAdmin(locale)}
            entitiesToOptions={(entityList) => { return broadcastersToOptions(entityList, true) }}
            placeholder={'Click to choose from list'}
            formik={formik}
          />
          <Divider />
          <SubmitOrCancel error={error} />
        </form >
        <FilmSelectionList filmId={props.item.id} />
        <ArtworkListForFilm filmId={props.item.id} />
        <div style={{ height: '200px' }} />
      </Section >
    </React.Fragment>
  )
});
