import React, { useState } from 'react';
import SearchIcon from '../../../assets/icons/search.svg';
import styles from './Input.module.scss';
import classNames from 'classnames';
import { Label } from '@components/.';

interface OwardInputCommonProps {
  label?: string;
  placeholder?: string;
  description?: string;
  descriptionColor?: string;
  value?: string;
  onKeyDown?: (e: any) => void;
  onClick?: () => void;
  noBottomPadding?: boolean;
}
interface OwardInputProps extends OwardInputCommonProps {
  onChange: (value: string) => void;
  type?: string;
  icon?: string;
  error?: string;
}

export const OwardInput: React.FC<OwardInputProps> = props => {
  return (
    <div style={{ width: '100%', padding: '0.5rem 0' }}>
      {
        props.label &&
        <Label name={props.label} />
      }
      <div className={classNames('control', { ['has-icons-left']: props.icon })}>
        <input
          className={classNames('input', styles.input)}
          type={props.type}
          placeholder={props.placeholder}
          onChange={event => props.onChange(event.target.value)}
          value={props.value}
        />
        {
          props.icon &&
          <span className='icon is-small is-left'>
            <i className={props.icon}></i>
          </span>
        }
      </div>
      {
        (props.error) &&
        <div className={styles.errorContainer}>
          <p className={styles.error}>{props.error}</p>
        </div>
      }
    </div>
  );
}

OwardInput.defaultProps = {
  type: 'text',
} as Partial<OwardFormInputProps>;

interface OwardFormInputProps extends OwardInputCommonProps {
  id: string;
  type?: string;
  icon?: string;
  error?: string;
  onChange?: (str: string) => void; // Can provide a handler on top of formik (ex: to preview profile in Edit Profile)
  formik: any;
}

export const OwardFormInput: React.FC<OwardFormInputProps> = props => {
  const error = props.error ?? (props.formik.touched[props.id] && props.formik.errors[props.id]);
  const [passwordReveal, setPasswordReveal] = useState(false);

  return (
    <div className='field' style={{ width: '100%', paddingBottom: !props.noBottomPadding ? '1rem' : '0rem', }} >
      <Label name={props.label} description={props.description} descriptionColor={props.descriptionColor} />
      <div className={classNames(
        'control',
        props.icon && 'has-icons-left',
        props.type === 'password' && 'has-icons-right'
      )} >
        <input
          style={{ display: 'block' }}
          id={props.id}
          className={classNames('input', styles.input)}
          type={passwordReveal ? 'text' : props.type}
          placeholder={props.placeholder}
          onChange={event => { props.formik.handleChange(event); props.onChange && props.onChange(event.target.value); }}
          value={props.value ?? props.formik.values[props.id]}
        />
        {
          props.icon &&
          <span className='icon is-small is-left'>
            <i className={props.icon} />
          </span>
        }
        {
          (props.type === 'password' && (props.value || props.formik.values[props.id])) &&
          <span
            className={`icon is-small is-right ${styles.passwordRevealButton}`}
            onClick={() => setPasswordReveal(!passwordReveal)}
          >
            <i className={classNames(
              passwordReveal ? 'far fa-eye' : 'far fa-eye-slash',
              styles.passwordRevealIcon
            )} />
          </span>
        }
      </div>
      {
        error &&
        <div className={styles.errorContainer}>
          <p className={styles.error}>{error}</p>
        </div>
      }
    </div>
  );
}

OwardFormInput.defaultProps = {
  type: 'text',
} as Partial<OwardFormInputProps>;

interface OwardSearchInputProps {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
}

export const OwardSearchInput: React.FC<OwardSearchInputProps> = props => {
  return (
    <div className='field' style={{ width: '100%' }}>
      <div className={classNames('control has-icons-left', styles.searchContainer)}>
        <input
          className={`input ${styles.searchInput}`}
          type='text'
          placeholder={props.placeholder}
          value={props.value}
          onChange={event => { props.onChange(event.target.value) }} />
        <div className={classNames('icon is-large is-left', styles.searchIconContainer)}>
          <SearchIcon className={styles.icon} />
        </div>
      </div>
    </div >
  );
}

interface OwardTextAreaProps extends OwardInputCommonProps {
  onChange: (value: string) => void;
  rows?: number;
  error?: string;
}

export const OwardTextArea: React.FC<OwardTextAreaProps> = props => {
  return (
    <div
      className='field'
      style={{
        width: '100%',
        height: 'auto',
        paddingBottom: !props.noBottomPadding ? '1rem' : '0rem',
        marginBottom: props.noBottomPadding && '0'
      }}
    >
      {
        props.label &&
        <Label name={props.label} description={props.description} descriptionColor={props.descriptionColor} />
      }
      <div style={{ width: '100%', height: 'auto' }} className={classNames('control')}>
        <textarea
          className={classNames('input', styles.input, styles.textarea)}
          placeholder={props.placeholder}
          onChange={event => props.onChange(event.target.value)}
          value={props.value}
          rows={props.rows}
          style={{ width: '100%', height: 'auto' }}
          onKeyDown={props.onKeyDown}
          onClick={props.onClick}

        />
      </div>
      {
        (props.error) &&
        <div className={styles.errorContainer}>
          <p className={styles.error}>{props.error}</p>
        </div>
      }
    </div>
  );
}

interface OwardFormTextAreaProps extends OwardInputCommonProps {
  id: string;
  rows?: number;
  formik: any;
  onChange?: (str: string) => void; // Can provide a handler on top of formik (ex: to preview profile in Edit Profile)
}

export const OwardFormTextArea: React.FC<OwardFormTextAreaProps> = props => {
  return (
    <div
      className='field'
      style={{
        width: '100%',
        height: 'auto',
        paddingBottom: !props.noBottomPadding ? '1rem' : '0rem',
        marginBottom: props.noBottomPadding && '0'
      }}
    >
      {
        props.label &&
        <Label name={props.label} description={props.description} descriptionColor={props.descriptionColor} />
      }
      <div style={{ width: '100%', height: 'auto' }} className={classNames('control')}>
        <textarea
          id={props.id}
          className={classNames('input', styles.input, styles.textarea)}
          placeholder={props.placeholder}
          onChange={event => { props.formik.handleChange(event); props.onChange && props.onChange(event.target.value); }}
          value={props.value ?? props.formik.values[props.id]}
          rows={props.rows}
          style={{ width: '100%', height: 'auto' }}
          onKeyDown={props.onKeyDown}
          onClick={props.onClick}
        />
      </div>
      {
        (props.formik.touched[props.id] && props.formik.errors[props.id]) &&
        <div className={styles.errorContainer}>
          <p className={styles.error}>{props.formik.errors[props.id]}</p>
        </div>
      }
    </div>
  );
}

OwardTextArea.defaultProps = {
  rows: 5,
} as Partial<OwardTextAreaProps>;

OwardFormTextArea.defaultProps = {
  rows: 5,
} as Partial<OwardFormTextAreaProps>;
