import React, { useState, useEffect, } from 'react';
import { send, PaymentController, PromoCode } from '@oward/openapi';
import { PaginatedList, PaginatedListKey, Section, } from '@components/admin';
import { strToPath, stateToEmoji } from '@oward/common-utils';
import { EditPromoCode } from './EditPromoCode';
import moment from 'moment';

interface PromoCodeListProps {
  reloadList: boolean;
  referralCodes?: boolean;
}

export const PromoCodeList: React.FC<PromoCodeListProps> = props => {
  const [items, setItems] = useState([] as PromoCode[]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reloadList]);

  const loadItems = async () => {
    try {
      setLoading(true);
      setError(false);
      let promoCodes: PromoCode[] = await send(PaymentController.findPromoCodes());
      setItems(promoCodes.filter(promoCode => {
        return props.referralCodes ? promoCode.referralProfileId !== undefined : promoCode.referralProfileId === undefined;
      }))
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const keys: PaginatedListKey<PromoCode>[] = [
    {
      label: 'Id',
      value: item => item.id
    },
    {
      label: 'Code',
      value: item => item.code.concat(' ', stateToEmoji(item.state))
    },
    {
      label: 'Reduction %',
      value: item => item.reductionPercentage?.toString()?.concat('%') ?? '-',
      sortValue: item => item.reductionPercentage ?? -1,
    },
    {
      label: 'Months extension',
      value: item => item.extensionMonths?.toString() ?? '-',
      sortValue: item => item.extensionMonths ?? -1,
    },
    {
      label: 'Expiration Date',
      value: item => item.expirationDate ? moment(item.expirationDate).format('DD MMM yyyy') : '-',
      sortValue: item => parseInt(item.expirationDate) ?? -1,
    },
    {
      label: 'Premium Level',
      value: item => item.premiumLevel?.toString() ?? 'All',
      sortValue: item => item.premiumLevel ?? -1,
    },
    {
      label: 'Validity',
      value: item => item.validity
    },
    {
      label: 'Number of time used',
      value: item => item.paymentProfiles?.length
    },
    {
      label: 'Descripion',
      value: item => item.description
    }
  ];

  const filterItemStrings = (item: PromoCode) => [
    item.id.toString(),
    strToPath(item.code),
    strToPath(item.description)
  ]

  const fetchOne = (item: PromoCode) => send(PaymentController.findOnePromoCode(item.id));

  return (
    <React.Fragment>
      <Section
        title={props.referralCodes ? 'Referral Promo Codes' : 'Classical Promo Codes'}
        subtitle={props.referralCodes ? 'The list of all referral Promo Codes in the database' : 'The list of all classical Promo Codes in the database'}
        childrenFullWidth
      >
        <PaginatedList
          keys={keys}
          items={items}
          filterItemStrings={filterItemStrings}
          size={100}
          editComponent={EditPromoCode}
          fetchOne={fetchOne}
          reloadList={loadItems}
          loading={loading}
          error={error}
        />
      </Section>
      <div style={{ height: '2rem' }} />
    </React.Fragment>
  );
}
