"use strict";
/*
** OpenAPI Client - ENUMS
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.VerificationState = exports.DiscussionMemberRole = exports.DiscussionType = exports.Visibility = exports.BiographyType = exports.ProfileType = exports.ContactPerson = exports.ContactMethod = exports.LocationProvider = exports.Gender = exports.UserType = exports.UserRole = exports.ArtworkType = exports.ValidationState = exports.PromoCodeValidity = exports.MessageType = exports.State = void 0;
var State;
(function (State) {
    State["LIVE"] = "LIVE";
    State["DELETED"] = "DELETED";
    State["BLOCKED"] = "BLOCKED";
    State["BANNED"] = "BANNED";
})(State = exports.State || (exports.State = {}));
var MessageType;
(function (MessageType) {
    MessageType["TEXT"] = "text";
    MessageType["PICTURE"] = "picture";
    MessageType["FILE"] = "file";
})(MessageType = exports.MessageType || (exports.MessageType = {}));
var PromoCodeValidity;
(function (PromoCodeValidity) {
    PromoCodeValidity["UNIQUE"] = "UNIQUE";
    PromoCodeValidity["USED"] = "USED";
    PromoCodeValidity["MULTIPLE"] = "MULTIPLE";
})(PromoCodeValidity = exports.PromoCodeValidity || (exports.PromoCodeValidity = {}));
var ValidationState;
(function (ValidationState) {
    ValidationState["UNKNOWN"] = "UNKNOWN";
    ValidationState["VERIFIED"] = "VERIFIED";
    ValidationState["REJECTED"] = "REJECTED";
})(ValidationState = exports.ValidationState || (exports.ValidationState = {}));
var ArtworkType;
(function (ArtworkType) {
    ArtworkType["VIDEO_LINK"] = "VIDEO_LINK";
    ArtworkType["AUDIO_LINK"] = "AUDIO_LINK";
    ArtworkType["TEXT"] = "TEXT";
    ArtworkType["VOD"] = "VOD";
    ArtworkType["PICTURE_PORTFOLIO"] = "PICTURE_PORTFOLIO";
})(ArtworkType = exports.ArtworkType || (exports.ArtworkType = {}));
var UserRole;
(function (UserRole) {
    UserRole["MEMBER"] = "MEMBER";
    UserRole["ADMIN"] = "ADMIN";
})(UserRole = exports.UserRole || (exports.UserRole = {}));
var UserType;
(function (UserType) {
    UserType["VISITOR"] = "VISITOR";
    UserType["RECRUITER"] = "RECRUITER";
})(UserType = exports.UserType || (exports.UserType = {}));
var Gender;
(function (Gender) {
    Gender["MALE"] = "MALE";
    Gender["FEMALE"] = "FEMALE";
    Gender["PLURAL"] = "PLURAL";
    Gender["OTHER"] = "OTHER";
    Gender["UNKNOWN"] = "UNKNOWN";
})(Gender = exports.Gender || (exports.Gender = {}));
var LocationProvider;
(function (LocationProvider) {
    LocationProvider["GEO_API_GOUV"] = "GEO_API_GOUV";
    LocationProvider["MAPQUEST"] = "MAPQUEST";
    LocationProvider["GOOGLE_GEOCODING"] = "GOOGLE_GEOCODING";
})(LocationProvider = exports.LocationProvider || (exports.LocationProvider = {}));
var ContactMethod;
(function (ContactMethod) {
    ContactMethod["INTERNAL"] = "INTERNAL";
    ContactMethod["EMAIL"] = "EMAIL";
    ContactMethod["PHONE"] = "PHONE";
})(ContactMethod = exports.ContactMethod || (exports.ContactMethod = {}));
var ContactPerson;
(function (ContactPerson) {
    ContactPerson["PERSO"] = "PERSO";
    ContactPerson["AGENT"] = "AGENT";
    ContactPerson["PERSO_AND_AGENT"] = "PERSO_AND_AGENT";
})(ContactPerson = exports.ContactPerson || (exports.ContactPerson = {}));
var ProfileType;
(function (ProfileType) {
    ProfileType["PRO"] = "PRO";
    ProfileType["COMPANY"] = "COMPANY";
    ProfileType["FESTIVAL"] = "FESTIVAL";
    ProfileType["EVENT"] = "EVENT";
    ProfileType["JOB_OFFER"] = "JOB_OFFER";
    ProfileType["FUNDING"] = "FUNDING";
    ProfileType["PRODUCT"] = "PRODUCT";
})(ProfileType = exports.ProfileType || (exports.ProfileType = {}));
var BiographyType;
(function (BiographyType) {
    BiographyType["PUBLIC"] = "PUBLIC";
    BiographyType["PRO"] = "PRO";
})(BiographyType = exports.BiographyType || (exports.BiographyType = {}));
var Visibility;
(function (Visibility) {
    Visibility["PUBLIC"] = "PUBLIC";
    Visibility["PRIVATE"] = "PRIVATE";
})(Visibility = exports.Visibility || (exports.Visibility = {}));
var DiscussionType;
(function (DiscussionType) {
    DiscussionType["ONETOONE"] = "onetoone";
    DiscussionType["PRIVATE_GROUP"] = "private_group";
    DiscussionType["PUBLIC_GROUP"] = "public_group";
})(DiscussionType = exports.DiscussionType || (exports.DiscussionType = {}));
var DiscussionMemberRole;
(function (DiscussionMemberRole) {
    DiscussionMemberRole["ADMIN"] = "ADMIN";
    DiscussionMemberRole["MEMBER"] = "MEMBER";
    DiscussionMemberRole["SPECTATOR"] = "SPECTATOR";
})(DiscussionMemberRole = exports.DiscussionMemberRole || (exports.DiscussionMemberRole = {}));
var VerificationState;
(function (VerificationState) {
    VerificationState["UNKNOWN"] = "UNKNOWN";
    VerificationState["NOT_VERIFIED"] = "NOT_VERIFIED";
    VerificationState["VERIFIED"] = "VERIFIED";
})(VerificationState = exports.VerificationState || (exports.VerificationState = {}));
