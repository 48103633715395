/*
** Admin Page
** KEYS
*/

export enum ItemKey {
  DASHBOARD = 'Dashboard',
  PROMO_CODE = 'Promo Codes',
  SUBSCRIPTIONS = 'Subscriptions',
  USERS = 'Users',
  PROFILES = 'Profiles',
  ARTWORKS = 'Artworks',
  FILMS = 'Films',
  AGENTS = 'Agents',
  ASSOCIATIONS = 'Associations',
  BRODCASTERS = 'Broadcasters',
  FESTIVAL = 'Festivals',
  JOBS = 'Jobs',
  COUNTRIES_CITIES = 'Countries & Cities',
  FILM_TYPES = 'Film Types',
  AWARD_CATEGORIES = 'Award Categories',
  SHORTLIST = 'Shortlists',
  ON_FIRES = 'On Fires',
  DISCUSSIONS = 'Discussions',
  STATS_CLICK_CONTACT = 'Click Contact',
  REPORTS_PROFILE = 'Reports Profiles',
  REPORTS_ARTWORK = 'Reports Artworks',
  REPORTS_MESSAGE = 'Reports Messages',
  BLOCKS_PROFILE = 'Blocks Profiles',
  BLOCKS_ARTWORK = 'Blocks Artworks',
};

export enum CategoryKey {
  GENERAL = 'General',
  USER_DATA = 'User Data',
  OWARD_DATA = 'Oward Data',
  STATS = 'Stats',
  MODERATION = 'Moderation',
};
