import { useState, useEffect } from 'react';
import styles from './Embed.module.scss';
import Loader from 'react-loader-spinner';
import request from 'superagent';
import { useInjection, useTranslate } from '@hooks/.';
import PlayIcon from '../../assets/icons/play.svg';
import { ModalKey, ModalStore, UserStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { useRouter } from 'next/router';
import { capitalizeFirst } from '@utils/.';
import ReactPlayer from "react-player"
import {
  EmbedType, urlToEmbedType, youtubeUrlToYoutubeEmbedUrl,
  VIMEO_OEMBED_API,
  arteUrlToArteEmbedUrl,
  SOUNDCLOUD_OEMBED_API
} from '@oward/common-utils';
import classNames from 'classnames';

interface EmbedProps {
  url: string;
}

export const Embed: React.FC<EmbedProps> = (props: EmbedProps) => {
  const { t, locale } = useTranslate();
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  const [embedJsx, setEmbedJsx] = useState<any>(undefined);
  const [embedLoading, setEmbedLoading] = useState<boolean>(false);
  const [embedError, setEmbedError] = useState<string>(undefined);

  useEffect(() => {
    (async function getEmbed() {
      switch (urlToEmbedType(props.url)) {
        case EmbedType.VIMEO:
          if (userStore.gdprPrefs?.vimeo) {
            try {
              setEmbedLoading(true);
              setEmbedError(undefined);
              const res = await request.get(VIMEO_OEMBED_API).query({ url: props.url, responsive: true });
              setEmbedJsx(
                <div className={`${styles.iframeVimeoContainer}`} dangerouslySetInnerHTML={{
                  __html: res.body?.html
                }} />
              );
            }
            catch (err) {
              setEmbedError(t('gallery.artwork.error', { url: props.url }));
            }
            finally {
              setEmbedLoading(false);
            }
          }
          else {
            setEmbedJsx(<CookieBlocked type={EmbedType.VIMEO} />);
          }
          break;
        case EmbedType.YOUTUBE:
          if (userStore.gdprPrefs?.youtube) {
            setEmbedJsx(
              <div className={`${styles.iframeYoutubeContainer}`}>
                <iframe
                  src={youtubeUrlToYoutubeEmbedUrl(props.url, locale)}
                  className={`${styles.iframe}`}
                  frameBorder='0'
                  allowFullScreen
                />
              </div>
            );
          }
          else {
            setEmbedJsx(<CookieBlocked type={EmbedType.YOUTUBE} />);
          }
          break;
        case EmbedType.FACEBOOK:
          if (userStore.gdprPrefs?.facebook) {
            setEmbedJsx(
              <div className={`${styles.iframeFacebookContainer}`}>
                <ReactPlayer
                  url={props.url}
                  controls
                  width='100%'
                  height='100%'
                />
              </div>
            );
          }
          else {
            setEmbedJsx(<CookieBlocked type={EmbedType.FACEBOOK} />);
          }
          break;
        case EmbedType.ARTE:
          if (userStore.gdprPrefs?.arte) {
            setEmbedJsx(
              <div className={`${styles.iframeYoutubeContainer}`}>
                <iframe
                  src={arteUrlToArteEmbedUrl(props.url, locale)}
                  className={`${styles.iframe}`}
                  frameBorder='0'
                  allowFullScreen
                />
              </div>
            );
          }
          else {
            setEmbedJsx(<CookieBlocked type={EmbedType.ARTE} />);
          }
          break;
        case EmbedType.SOUNDCLOUD:
          if (userStore.gdprPrefs?.soundcloud) {
            try {
              setEmbedLoading(true);
              setEmbedError(undefined);
              const res = await request.get(SOUNDCLOUD_OEMBED_API).query({ url: props.url, color: '000000' });
              setEmbedJsx(
                <div className={`${styles.iframeVimeoContainer}`} dangerouslySetInnerHTML={{
                  __html: res.body?.html
                }} />
              );
            }
            catch (err) {
              setEmbedError(t('gallery.artwork.error', { url: props.url }));
            }
            finally {
              setEmbedLoading(false);
            }
          }
          else {
            setEmbedJsx(<CookieBlocked type={EmbedType.SOUNDCLOUD} />);
          }
          break;
        default:
          let url: string = props.url;
          if (!url.startsWith('http')) {
            url = 'https://' + url;
          }
          setEmbedJsx(
            <div className={`${styles.otherLinkContainer}`}>
              <a href={url} target='_blank' rel='noreferrer' >
                <button className={`${styles.button}`}>
                  <p className={`${styles.text}`}>
                    {t('gallery.artwork.link')}
                    <span className={classNames('icon', styles.iconLink)} >
                      <i className={'fas fa-link'}></i>
                    </span>
                  </p>
                </button>
              </a >
            </div>
          );
          break;
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.url, locale,
    userStore.gdprPrefs?.arte, userStore.gdprPrefs?.facebook, userStore.gdprPrefs?.soundcloud,
    userStore.gdprPrefs?.vimeo, userStore.gdprPrefs?.youtube
  ]);

  return (
    <div className={styles.embedContainer}>
      {
        embedError &&
        <div className={`${styles.loaderContainer}`}>
          <p>{embedError}</p>
        </div>
      }
      {
        embedLoading &&
        <div className={`${styles.loaderContainer}`}>
          <Loader
            type='Grid'
            color='white'
            height={70}
            width={70}
          />
        </div>
      }
      {
        embedJsx !== undefined &&
        embedJsx
      }
    </div>
  );
}

interface CookieBlockedProps {
  type: EmbedType;
}

const CookieBlocked: React.FC<CookieBlockedProps> = props => {
  const { t } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  return (
    <div className={`${styles.otherLinkContainer}`}>
      <div className={` ${styles.textContainer}`}>
        <p>
          <span className={'icon'} >
            <i className={`fas fa-cookie-bite ${styles.cookieIcon}`}></i>
          </span>
        </p>
        <p>
          {t('gallery.artwork.authorize_cookies', { type: capitalizeFirst(props.type) })}
          <span className={`${styles.clickHere}`} onClick={() => { modalStore.popModal(router, ModalKey.GDPR_PREFS, 1) }}>
            {t('gallery.artwork.click_here')}
          </span>
          {t('gallery.artwork.to_activate_cookies', { type: capitalizeFirst(props.type) })}
        </p>
      </div>
    </div >
  );
}

export default Embed;
