import classnames from 'classnames';
import React from 'react';
import styles from './Badge.module.scss';

interface BadgeProps {
  count: number;
  singleNumber?: boolean;
  staticPosition?: boolean;
}

export const Badge: React.FC<BadgeProps> = props => {

  const getCount = () => {
    if (props.singleNumber && props.count > 9) {
      return '9+';
    }
    return props.count;
  }

  return (
    <React.Fragment>
      {
        props.count > 0 &&
        <div className={classnames(
          styles.badgeContainer,
          props.staticPosition ? styles.static : styles.absolute ,
        )}>
          <div className={classnames(
            styles.badge,
            props.count > 9 && styles.widerBadge
          )}>
            <p className={styles.text}>
              {getCount()}
            </p>
          </div>
        </div>
      }
    </React.Fragment>
  )
}
