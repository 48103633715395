import React, { useContext, useState } from "react";
import { useFormik } from 'formik';
import { observer } from "mobx-react-lite";
import { AgentController, Agent, State, ValidationState } from "@oward/openapi";
import { Section, Divider, SubmitOrCancel, NavigationContext } from '@components/admin';
import { OwardFormInput, Heading, OwardFormSelectLoad, entitiesToOptionsWithTranslation, OwardFormSelect } from '@components/Core';
import { useTranslate } from '@hooks/.';
import { EditProps } from '../types';
import * as Yup from 'yup';
import { stateToEmoji, validationStateToEmoji } from '@oward/common-utils';

const schema = (t: any) => {
  return (
    Yup.object().shape({
      name: Yup.string().required(),
      category: Yup.object().required(),
    }))
};

export const EditAgent = observer((props: EditProps<Agent>) => {
  const { t, locale } = useTranslate();
  const [error, setError] = useState(false);
  const navigation = useContext(NavigationContext);

  const formik = useFormik({
    initialValues: {
      name: props.item.name,
      category: props.item.category,
      state: props.item.state,
      validationState: props.item.validationState
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      props.item.name = values.name;
      props.item.state = values.state;
      props.item.category = values.category;
      props.item.validationState = values.validationState;
      try {
        await AgentController.saveOne(props.item);
        if (props.notify) { props.notify(props.item) };
        setError(false);
        navigation.unpop();
      } catch {
        setError(true);
      }
    }
  });

  return (
    <Section
      title='Edit'
      subtitle='Change your entity properties'>
      <form className='box' onSubmit={formik.handleSubmit} >
        <Heading>General</Heading>
        <OwardFormInput
          id='name'
          label='Name *'
          formik={formik}
        />
        <OwardFormSelectLoad
          id='category'
          label='Category *'
          fetchFunction={AgentController.listCategories(locale)}
          entitiesToOptions={entities => entitiesToOptionsWithTranslation(entities, locale)}
          formik={formik}
        />
        <Divider />
        <Heading>ADMIN</Heading>
        {
          props.item.createdBy ?
            <p>Agent created by user: {props.item.createdBy.email}</p>
            :
            <p>Agent created by Oward</p>
        }
        <div style={{ height: '1rem' }} />
        <OwardFormSelect
          id='state'
          label='State *'
          options={Object.values(State).map((state) => {
            return ({ value: state, label: state.concat(' ', stateToEmoji(state)) });
          })}
          formik={formik}
        />
        <OwardFormSelect
          id='validationState'
          label='Validation State *'
          options={Object.values(ValidationState).map((validationState) => {
            return ({ value: validationState, label: validationState.concat(' ', validationStateToEmoji(validationState)) });
          })}
          formik={formik}
        />
        <div style={{ height: '8rem' }} />
        <Divider />
        <SubmitOrCancel error={error} noCancel={props.noCancel} />
      </form >
    </Section >
  )
});
