import { OwardButton } from "@components/Core";
import { useTranslate } from "@hooks/use-translate";
import { findTranslation, strToPath } from "@oward/common-utils";
import { LocationController, send, LocationCity } from "@oward/openapi";
import React, { useContext, useEffect, useState } from "react"
import { NavigationContext, PaginatedList, PaginatedListKey, Section } from "../AdminComponents"
import { EditCity } from "./EditCity";

export const CityList: React.FC = () => {
  const { t } = useTranslate();
  const store = useContext(NavigationContext);
  const [items, setItems] = useState([] as LocationCity[]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = async () => {
    try {
      setLoading(true);
      setError(false);
      setItems(await send(LocationController.findCities()))
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const keys: PaginatedListKey<LocationCity>[] = [
    {
      label: 'City Id',
      value: item => item.id
    },
    {
      label: 'Name',
      value: item => item.name
    },
    {
      label: 'Name Fr',
      value: item => findTranslation(item, 'fr')
    },
    {
      label: 'Name En',
      value: item => findTranslation(item, 'en')
    },
  ];

  const filterItemStrings = (item: LocationCity) => [
    item.id.toString(),
    item.name,
    strToPath(findTranslation(item, 'fr')),
    strToPath(findTranslation(item, 'en'))
  ];

  const fetchOne = async (item: LocationCity) => await send(LocationController.findCity(item.id))


  return (
    <React.Fragment>
      <Section
        title='City List'
        subtitle='List of all the Cities in the database'
        childrenFullWidth>
        <PaginatedList
          keys={keys}
          items={items}
          filterItemStrings={filterItemStrings}
          size={15}
          editComponent={EditCity}
          fetchOne={fetchOne}
          reloadList={loadItems}
          loading={loading}
          error={error}
        />
      </Section>
      <div style={{ height: '2rem' }} />
      <Section
        title='Create new City'
        subtitle='Create it WHEN PRESSING THIS BUTTON. Cannot be undone, please fill all needed infos in next screen'
      >
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingBottom: '3rem' }}>
          <OwardButton
            name='Create new city'
            onClick={async () => {
              let newCity: LocationCity = await send(LocationController.createCity());
              loadItems();
              store.pop({
                label: 'Create City',
                element: <EditCity item={newCity} notify={loadItems} noCancel />
              });
            }}
          />
        </div>
      </Section>
    </React.Fragment>
  )
}
