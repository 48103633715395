import React, { useEffect, useState } from 'react';
import styles from './OnFirePage.module.scss';
import { useInjection, useOnFireProfileIds, useOnFireProfiles, useTranslate } from '@hooks/.';
import { LayoutPage, Link, OnFireProfileLine, OnFireProfilesSelection, OnFireStore, OnFireSubtitle, OnFireUserLine, OwardLinkButton, OwardLoader, OwardSwitchMultiple, SubtitlePage, ToastError } from '@components/.';
import { useRouter } from 'next/router';
import { Profile, ProfileController, send, State, User } from '@oward/openapi';
import { useLocalObservable, observer } from 'mobx-react-lite';
import { findTranslationOrDefault, profileToStr } from '@oward/common-utils';
import { isProfileExposed } from '@utils/utils';
import { toast } from 'react-toastify';
import { ERR_MSG_UNAUTHORIZED } from '@utils/constants';
import { StoresBindings } from '@container/keys';
import { ModalKey, ModalStore } from '@stores/modal-store';
import { UserStore } from '@stores/user-store';
import { runInAction } from 'mobx';

interface OnFireProps {
}

export enum ON_FIRE_PAGES {
  mine = 'mine',
  received = 'received',
  mutual = 'mutual'
}

export const OnFirePage: React.FC<OnFireProps> = observer(props => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  useEffect(() => {
    (async function getProfilesForUser() {
      try {
        runInAction(() => {
          store.userProfilesLoading = true;
        });
        let userProfiles: Profile[] = await send(ProfileController.findForUser(locale));
        runInAction(() => {
          store.userProfiles = userProfiles.filter(profile => profile.state === State.LIVE)
          store.userProfilesOptions = store.userProfiles?.map((profile) => {
            return {
              value: profile.id,
              label: `${profile.name} (${findTranslationOrDefault(profile.job, locale)})`
            }
          });

          if (store.userProfiles?.length > 0) {
            store.selectedProfileId = store.userProfiles[0].id;
          }
        });

      }
      catch (err) {
        if (err instanceof Error && err.message === ERR_MSG_UNAUTHORIZED) {
          modalStore.openModalNewStack(router, ModalKey.ADD_ON_FIRE, 1);
        }
        else {
          toast.dark(<ToastError msg={t('global.error_with_code', { code: err })} />);
        }
      }
      finally {
        runInAction(() => {
          store.userProfilesLoading = false;
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const store: OnFireStore = useLocalObservable(() => ({
    userProfiles: undefined,
    userProfilesOptions: undefined,
    userProfilesLoading: false,
    selectedProfileId: undefined,
    selectedProfile: () => {
      if (!store.selectedProfileId) {
        return undefined
      }
      else {
        return store.userProfiles?.find(profile => profile.id === store.selectedProfileId);
      }
    },
    onFiringUsers: undefined,
    onFiringUsersLoading: false,
  }));

  const activePage = () => {
    if (router.query[ON_FIRE_PAGES.received] !== undefined) {
      return ON_FIRE_PAGES.received;
    }
    else if (router.query[ON_FIRE_PAGES.mutual] !== undefined) {
      return ON_FIRE_PAGES.mutual;
    }
    else {
      return ON_FIRE_PAGES.mine;
    }
  }

  const renderSpecificPage = () => {
    switch (activePage()) {
      case ON_FIRE_PAGES.mine:
        return <OnFireMine store={store} />;
      case ON_FIRE_PAGES.received:
        return <OnFireReceived store={store} />;
      case ON_FIRE_PAGES.mutual:
        return <OnFireMutual store={store} />;
    }
  }

  return (
    <LayoutPage title={t('on_fire.title')}>
      <div className={styles.selectorContainer}>
        <OwardSwitchMultiple
          activeOption={activePage()}
          options={Object.values(ON_FIRE_PAGES).map((page) => {
            return { value: page, label: t(`on_fire.${page}.selector`) }
          })}
          onChange={(option) => {
            switch (option.value) {
              case ON_FIRE_PAGES.mine:
                router.replace(`/[lang]/onfire`, `/${locale}/onfire`, { shallow: true })
                break;
              case ON_FIRE_PAGES.received:
                router.replace(`/[lang]/onfire?${ON_FIRE_PAGES.received}=1`, `/${locale}/onfire?${ON_FIRE_PAGES.received}=1`, { shallow: true })
                break;
              case ON_FIRE_PAGES.mutual:
                router.replace(`/[lang]/onfire?${ON_FIRE_PAGES.mutual}=1`, `/${locale}/onfire?${ON_FIRE_PAGES.mutual}=1`, { shallow: true })
                break;
            }
          }}
        />
      </div>
      {renderSpecificPage()}
    </LayoutPage>
  );
});

interface OnFirePageWithUserProps {
  store: OnFireStore;
}

const OnFireMine: React.FC<OnFirePageWithUserProps> = observer(props => {
  const { t } = useTranslate();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const { onFireProfiles, isLoading } = useOnFireProfiles();

  return (
    <React.Fragment>
      {
        userStore.isLogged ?
          <React.Fragment>
            {
              isLoading ?
                <OwardLoader
                  loading={isLoading}
                  message={t('on_fire.gallery.loading_profiles')}
                  positionStatic
                />
                :
                <React.Fragment>
                  {
                    onFireProfiles?.length > 0 ?
                      <React.Fragment>
                        <OnFireSubtitle subtitle={t('on_fire.mine.gallery_subtitle', onFireProfiles?.length)} />
                        {
                          onFireProfiles?.map((profile, i) =>
                            <OnFireProfileLine profile={profile} key={i} />
                          )
                        }
                      </React.Fragment>
                      :
                      <div className={styles.noProfileContainer}>
                        <p>{t('on_fire.mine.no_profile')}</p>
                        <br />
                        <p>{t('on_fire.gallery.explanation.common')}</p>
                      </div>
                  }
                </React.Fragment>
            }
          </React.Fragment>
          :
          <div className={styles.notLoggedContainer}>
            <p>{t('on_fire.mine.not_logged')}</p>
            <div className={styles.buttonContainer}>
              <Link href='/login'>
                <OwardLinkButton
                  name={t('login.login')}
                />
              </Link>
            </div>
          </div>
      }
    </React.Fragment>
  );
});

const OnFireReceived: React.FC<OnFirePageWithUserProps> = observer(props => {
  const { t, locale } = useTranslate();

  return (
    <React.Fragment>
      <OnFireProfilesSelection
        store={props.store}
      />
      <div className={styles.userListContainer}>
        {
          props.store.onFiringUsersLoading ?
            <OwardLoader
              loading={props.store.onFiringUsersLoading}
              message={t('on_fire.gallery.loading_users')}
              positionStatic
            />
            :
            <React.Fragment>
              <div className={styles.subtitleContainer}>
                {
                  (props.store.selectedProfileId !== undefined && props.store.onFiringUsers?.length > 0) &&
                  <OnFireSubtitle
                    subtitle={t('on_fire.received.gallery_subtitle', props.store.onFiringUsers?.length)}
                  />
                }

              </div>
              {
                props.store.onFiringUsers?.length > 0 ?
                  props.store.onFiringUsers?.map((user, i) => <OnFireUserLine user={user} store={props.store} key={i} />)
                  :
                  <React.Fragment>
                    {
                      props.store.selectedProfile() &&
                      <div className={styles.noProfileContainer}>
                        <p>
                          {t('on_fire.received.no_user', { profileNameAndJob: profileToStr(props.store.selectedProfile(), locale) })}
                        </p>
                        <br />
                        <p>{t('on_fire.gallery.explanation.common')}</p>
                      </div>
                    }
                  </React.Fragment>
              }
            </React.Fragment>
        }
      </div>
    </React.Fragment>
  );
});

const OnFireMutual: React.FC<OnFirePageWithUserProps> = observer(props => {
  const { t, locale } = useTranslate();
  const { onFireProfileIds } = useOnFireProfileIds();
  const [profilesMutualOnFire, setProfilesMutualOnFire] = useState<Profile[]>([]);

  const isOnFire = (id: number) => {
    return onFireProfileIds?.includes(id);
  }

  useEffect(() => {
    let profilesMutual: Profile[] = new Array<Profile>();

    props.store.onFiringUsers?.map((user) => {
      profilesMutual = [
        ...profilesMutual,
        ...user.profiles.filter(profile => isProfileExposed(profile) && isOnFire(profile.id))
      ];
    })

    setProfilesMutualOnFire(profilesMutual);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.store.onFiringUsers]);

  return (
    <React.Fragment>
      <OnFireProfilesSelection
        store={props.store}
      />
      <div className={styles.userListContainer}>
        {
          props.store.onFiringUsersLoading ?
            <OwardLoader
              loading={props.store.onFiringUsersLoading}
              message={t('on_fire.gallery.loading_profiles_mutual')}
              positionStatic
            />
            :
            <React.Fragment>
              <div className={styles.subtitleContainer}>
                {
                  (props.store.selectedProfileId !== undefined && profilesMutualOnFire?.length > 0) &&
                  <OnFireSubtitle
                    subtitle={t('on_fire.mutual.gallery_subtitle', profilesMutualOnFire?.length)}
                  />
                }
              </div>
              {
                profilesMutualOnFire?.length > 0 ?
                  profilesMutualOnFire?.map((profile, i) => <OnFireProfileLine profile={profile} key={i} />)
                  :
                  <React.Fragment>
                    {
                      props.store.selectedProfile() &&
                      <div className={styles.noProfileContainer}>
                        <p>
                          {t('on_fire.mutual.no_user', { profileNameAndJob: profileToStr(props.store.selectedProfile(), locale) })}
                        </p>
                        <br />
                        <p>{t('on_fire.gallery.explanation.common')}</p>
                      </div>
                    }
                  </React.Fragment>
              }
            </React.Fragment>
        }
      </div>
    </React.Fragment>
  );
});
