import React, { useEffect, useState } from 'react';
import styles from './ModalCommon.module.scss';
import { observer } from 'mobx-react-lite';
import { UserStore, ModalKey, ModalStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import { useInjection, useTranslate } from '@hooks/.';
import {
  OwardFormInput, NeedConnectionModal, Modal, ModalConfirmOrCancelButtons,
  ModalTitle, OwardFormSelectLoad, OwardLoader, ToastSucess, festivalToOptions, festivalAwardToOptions, OwardFormSelect
} from '@components/.';
import { useRouter } from 'next/router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Festival, FestivalAward, FestivalController, FilmSelection, NewFilmSelection, send } from '@oward/openapi';
import { toast } from 'react-toastify';
import { SelectionStatus } from '@oward/common-enums';
import { OwardButton } from '@components/Core';
import shallowEqual from 'shallowequal';
import { action, runInAction } from 'mobx';
import { festivalAwardToStr } from '@utils/utils';

const schema = (t: any) => {
  return (
    Yup.object().shape({
      festival: Yup.object().required(t('forms.required')),
      year: Yup.number()
        .required(t('forms.required'))
        .max(2050, t('forms.year_valid'))
        .min(1932, t('forms.year_valid')),
    }))
};


export const NewSelectionModal: React.FC = observer(() => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  const [error, setError] = useState<string>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    // Reset form at each modal opening
    formik.resetForm();
    setError(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query[ModalKey.NEW_SELECTION] as string]);

  const formik = useFormik({
    initialValues: {
      festival: undefined,
      award: undefined,
      selectionStatus: SelectionStatus.SELECTED,
      year: '' as unknown as number, // see https://github.com/formium/formik/issues/321#issuecomment-478364302,
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      if (awardNotValidForFestival(formik)) {
        setError(
          t('modal.selection.award_not_valid', {
            award_name: formik.values.award.name,
            festival_name: formik.values.festival.name
          }
          )
        );
      }
      else {
        try {
          let newSelection: NewFilmSelection = {
            festival: values.festival,
            award: values.award,
            selectionStatus: values.selectionStatus,
            year: values.year,
          };
          setLoading(true);
          let filmSelection: FilmSelection = await send(FestivalController.createSelection(newSelection));
          runInAction(() => {
            if (!modalStore.editArtworkLocallStore.artwork.film.selections) {
              modalStore.editArtworkLocallStore.artwork.film.selections = [] as FilmSelection[];
            }
            modalStore.editArtworkLocallStore.artwork.film.selections.push(filmSelection);
            modalStore.editArtworkLocallStore.selectionsChanged = true;
          });
          setError(undefined);
          toast.dark(<ToastSucess msg={t('modal.selection.created', { name: values.festival.name })} />);
          modalStore.unpopModal(router);
        } catch (err) {
          setError(t('global.error_retry'));
        }
        finally {
          setLoading(false);
        }
      }
    }
  });

  return (
    <React.Fragment>
      {
        userStore.isLogged ?
          <Modal
            modalKey={ModalKey.NEW_SELECTION}
            header={<ModalTitle name={t('modal.selection.new_title')} />}
            footer={
              <form onSubmit={formik.handleSubmit} >
                <ModalConfirmOrCancelButtons confirmTxt={t('global.add')} error={error} />
              </form>
            }
          >
            <form onSubmit={formik.handleSubmit} className={styles.mainContainer}>
              <OwardLoader loading={loading} />
              <SelectionForm formik={formik} />
            </form>
          </Modal >
          :
          <Modal modalKey={ModalKey.NEW_SELECTION}>
            <NeedConnectionModal headerMessage={t('modal.selection.need_connection')} />
          </Modal>
      }
    </React.Fragment>
  );
});

export const ModifySelectionModal: React.FC = observer(() => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const [selectionBeforeModif, setSelectionBeforeModif] = useState(undefined);

  const [error, setError] = useState<string>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let selection = modalStore.editArtworkLocallStore?.artwork.film.selections?.find(
      selection => selection.id === parseInt(router.query[ModalKey.MODIFY_SELECTION] as string)
    );

    if (selection) {
      let initialValues = {
        festival: selection.festival,
        award: selection.award as FestivalAward,
        selectionStatus: selection.status as SelectionStatus,
        year: selection.year,
      };
      formik.setValues(initialValues);
      setError(undefined);
      setSelectionBeforeModif(initialValues);
    }
    else {
      //fetch selection.... Not very useful, only if someone arrives directly on the modify_seletion URL
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query[ModalKey.MODIFY_SELECTION] as string]);

  const formik = useFormik({
    initialValues: {
      festival: undefined as Festival,
      award: undefined as FestivalAward,
      selectionStatus: SelectionStatus.SELECTED,
      year: '' as unknown as number, // see https://github.com/formium/formik/issues/321#issuecomment-478364302,
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      try {
        if (awardNotValidForFestival(formik)) {
          setError(
            t('modal.selection.award_not_valid', {
              award_name: formik.values.award.name,
              festival_name: formik.values.festival.name
            })
          );
        }
        else if (shallowEqual(selectionBeforeModif, formik.values)) {
          toast.dark(<ToastSucess msg={t('edit_profile.saved_no_modif')} />);
          modalStore.unpopModal(router);
        }
        else {
          let selection = modalStore.editArtworkLocallStore.artwork.film.selections?.find(
            selection => selection.id === parseInt(router.query[ModalKey.MODIFY_SELECTION] as string)
          );

          runInAction(() => {
            if (selection) {
              selection.festival = values.festival;
              selection.award = values.award ?? null;
              selection.status = values.selectionStatus;
              selection.year = values.year;
            }

            modalStore.editArtworkLocallStore.selectionsChanged = true;
          });
          setError(undefined);
          toast.dark(<ToastSucess msg={t('modal.selection.modified', { name: values.festival.name })} />);
          modalStore.unpopModal(router);
        }
      }
      catch (err) {
        setError(t('global.error_retry'));
      }
      finally {
        setLoading(false);
      }
    }
  });

  const deleteSelection = action(() => {
    let id = parseInt(router.query[ModalKey.MODIFY_SELECTION] as string);
    modalStore.editArtworkLocallStore.artwork.film.selections = modalStore.editArtworkLocallStore.artwork.film.selections.filter(
      selection => selection.id !== id
    );
    modalStore.editArtworkLocallStore.selectionsChanged = true;
  });

  return (
    <React.Fragment>
      {
        userStore.isLogged ?
          <Modal
            modalKey={ModalKey.MODIFY_SELECTION}
            header={<ModalTitle name={t('modal.selection.modify_title')} />}
            footer={
              <form onSubmit={formik.handleSubmit} >
                <ModalConfirmOrCancelButtons confirmTxt={t('global.modify')} error={error} />
              </form>
            }
          >
            <form onSubmit={formik.handleSubmit} className={styles.mainContainer}>
              <OwardLoader loading={loading} />
              <SelectionForm formik={formik} />
              <OwardButton
                name={t('modal.selection.delete')}
                red
                onClick={() => {
                  deleteSelection();
                  modalStore.unpopModal(router);
                }}
              />
            </form>
          </Modal >
          :
          <Modal modalKey={ModalKey.MODIFY_SELECTION}>
            <NeedConnectionModal headerMessage={t('modal.selection.need_connection')} />
          </Modal>
      }
    </React.Fragment>
  );
});

interface SelectionFormProps {
  formik: any;
}

const awardNotValidForFestival = (formik: any): boolean => {
  return (
    formik.values.award?.festival?.id &&
    formik.values.award?.festival?.id !== formik.values.festival?.id
  );
}

const SelectionForm: React.FC<SelectionFormProps> = observer(props => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  return (

    <div style={{ padding: '0 0.1rem' }}>
      <OwardFormSelectLoad
        id='festival'
        label={t('modal.selection.festival') + ' *'}
        description={
          <p className={styles.createEntity}>
            {t('edit_profile.create_intro')}
            <span
              className={styles.click}
              onClick={() => modalStore.popModal(router, ModalKey.NEW_FESTIVAL, 1)}
            >{t('edit_profile.create_click')}</span>
            {t('edit_profile.create_outro')}
          </p>
        }
        fetchFunction={FestivalController.find(locale)}
        entitiesToOptions={entities => { return festivalToOptions(entities, locale) }}
        isNotClearable
        formik={props.formik}
      />
      <OwardFormSelectLoad
        id='award'
        label={t('modal.selection.award')}
        description={
          (props.formik.values.festival && props.formik.values.festival.needCheck === false) &&
          < p className={styles.createEntity}>
            {t('modal.selection.award_create_intro', { festival_name: props.formik.values.festival.name })}
            <span
              className={styles.click}
              onClick={() => modalStore.popModal(router, ModalKey.NEW_FESTIVAL_AWARD, props.formik.values.festival.id)}
            >{t('modal.selection.award_create_click')}</span>
            {t('modal.selection.award_create_outro')}
          </p>
        }
        fetchFunction={FestivalController.findAwards(props.formik.values.festival?.id)}
        entitiesToOptions={entities => { return festivalAwardToOptions(entities, locale, props.formik.values.festival?.id) }}
        entityToStr={entity => festivalAwardToStr(entity, locale)}
        formik={props.formik}
        isClearable
        reload
      />
      <OwardFormSelect
        id='selectionStatus'
        label={t('modal.selection.status.awarded') + ' *'}
        options={Object.values(SelectionStatus).map((selectionStatus) => {
          return ({
            value: selectionStatus,
            label: t('modal.selection.status.' + selectionStatus)
          });
        })}
        isNotClearable
        formik={props.formik}
      />
      <OwardFormInput
        id='year'
        type='number'
        label={t('modal.selection.year') + ' *'}
        formik={props.formik}
      />
      <div style={{ height: '8rem' }} />
    </div>
  );
});
