import React from 'react';
import { BlockArtworkList, BlockProfileList } from './BlockList';

export const BlockProfileComponent: React.FC = () => {
  return (
    <React.Fragment>
      <BlockProfileList />
    </React.Fragment>
  );
}

export const BlockArtworkComponent: React.FC = () => {
  return (
    <React.Fragment>
      <BlockArtworkList />
    </React.Fragment>
  );
}
