import React, { useState, useEffect, useContext } from 'react';
import { send, Film, FestivalController, FestivalAward } from '@oward/openapi';
import {
  Divider, NavigationContext, SimpleList, SimpleListKey
} from '@components/admin';
import { Heading, OwardButton } from '@components/Core';
import { EditAward } from './EditAward';
import { locales } from '@locales/.';
import { findTranslation, stateToEmoji } from '@oward/common-utils';

interface AwardListForFestivalProps {
  festivalId: number;
}

export const AwardListForFestival: React.FC<AwardListForFestivalProps> = props => {

  const store = useContext(NavigationContext);
  const [items, setItems] = useState<FestivalAward[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    loadItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadItems = async () => {
    try {
      setLoading(true);
      setError(false);
      setItems(await send(FestivalController.findAwardsAdmin(props.festivalId, true)))
    }
    catch (err) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }

  const keys: SimpleListKey<FestivalAward>[] = [
    {
      label: 'Id',
      value: item => <p>{item.id}</p>
    },
    {
      label: 'Name',
      value: item => <p>{item.name.concat(' ', stateToEmoji(item.state))}</p>
    },
    {
      label: 'Name FR',
      value: item => <p>{findTranslation(item, 'fr')}</p>
    },
    {
      label: 'Name EN',
      value: item => <p>{findTranslation(item, 'en')}</p>
    },
    {
      label: 'Category',
      value: item => <p>{item.category?.name}</p>
    },
  ];

  const fetchOne = (item: Film) => send(FestivalController.findOneAward(item.id));

  return (
    <div style={{ marginBottom: '3rem', marginTop: '3rem' }}>
      <Divider />
      <Heading>AWARDS SPECIFIC TO THIS FESTIVAL</Heading>
      <SimpleList
        keys={keys}
        items={items}
        editComponent={EditAward}
        fetchOne={fetchOne}
        reloadList={loadItems}
        loading={loading}
        error={error}
      />
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <OwardButton
          name='Create Festival Award'
          onClick={async () => {
            let festivalAward: FestivalAward = await send(FestivalController.createOneAwardAdmin(props.festivalId));
            loadItems();
            store.pop({
              label: 'Create Festival Award',
              element: <EditAward item={festivalAward} notify={loadItems} noCancel />
            });
          }}
        />
        <p className='has-text-danger'>
          Newly created Film Selection will be in BLOCKED State.
        </p>
      </div>
    </div>
  );
}
