import React, { SetStateAction, useEffect, useState } from 'react';
import styles from './GDPRModal.module.scss';
import { UserStore, ModalStore, GdprPrefs, ModalKey } from '@stores/.';
import { StoresBindings } from '@container/.';
import { useInjection, useTranslate } from '@hooks/.';
import { OwardButton, OwardSwitch } from '@components/Core';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { Modal } from '@components/Modal';

interface CookieDescProps {
  cookieType: string;
}
const CookieDesc: React.FC<CookieDescProps> = props => {
  const { t } = useTranslate();

  return (
    <React.Fragment>
      <p className={styles.subtitle}>{t(`gdpr.${props.cookieType}.title`)}</p>
      <p className={styles.description}>{t(`gdpr.${props.cookieType}.description`)}</p>
      <p><span className={styles.example}>{t('gdpr.example')}</span> {t(`gdpr.${props.cookieType}.example`)}</p>
    </React.Fragment>
  );
}

const GpdrModalHeader: React.FC = () => {
  const { t } = useTranslate();

  return (
    <React.Fragment>
      <p className={styles.title}>{t('gdpr.title')}</p>
    </React.Fragment>
  );
}

interface GpdrModalFooterProps {
  gdprPrefs: GdprPrefs;
}
const GpdrModalFooter: React.FC<GpdrModalFooterProps> = observer(props => {
  const { t } = useTranslate();
  const router = useRouter();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  return (
    <React.Fragment>
      <div className={styles.buttonContainer}>
        <OwardButton name={t('gdpr.save')} onClick={() => {
          userStore.setGdprPrefs(props.gdprPrefs, true)
          modalStore.unpopModal(router);
        }} />
        <OwardButton name={t('global.cancel')} outlined onClick={() => {
          modalStore.unpopModal(router);
        }} />

      </div>
    </React.Fragment>
  );
})

interface GpdrModalContentProps {
  gdprPrefs: GdprPrefs;
  setGdprPrefs: React.Dispatch<SetStateAction<GdprPrefs>>;
}
const GpdrModalContent: React.FC<GpdrModalContentProps> = observer(props => {
  const { gdprPrefs, setGdprPrefs } = props;
  const { t } = useTranslate();
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  useEffect(() => {
    if (userStore.gdprPrefs !== undefined) {
      setGdprPrefs({ ...userStore.gdprPrefs });
    }
  }, [userStore.gdprPrefs, setGdprPrefs]);

  return (
    <div className={styles.contentContainer}>
      <div className={styles.buttonContainer}>
        <OwardButton name={t('gdpr.accept_all')} onClick={() => {
          setGdprPrefs({
            functional: true,
            analysis: true,
            youtube: true,
            vimeo: true,
            facebook: true,
            arte: true,
            soundcloud: true,
          })
        }} />
        <OwardButton name={t('gdpr.reject_all')} onClick={() => {
          setGdprPrefs({
            functional: false,
            analysis: false,
            youtube: false,
            vimeo: false,
            facebook: false,
            arte: false,
            soundcloud: false,
          })
        }} />
      </div>
      <CookieDesc cookieType='necessary' />
      <hr />
      <CookieDesc cookieType='functional' />
      <div className={styles.switchContainer}>
        <OwardSwitch
          label={t(`gdpr.activation`)}
          active={gdprPrefs.functional}
          onChange={() => {
            setGdprPrefs((prevGdprPref) => {
              prevGdprPref.functional = !gdprPrefs.functional;
              return ({ ...prevGdprPref })
            });
          }}
          dark
        />
      </div>
      <hr />
      <CookieDesc cookieType='analysis' />
      <div className={styles.switchContainer}>
        <OwardSwitch
          label={t(`gdpr.activation`)}
          active={gdprPrefs.analysis}
          onChange={() => {
            setGdprPrefs((prevGdprPref) => {
              prevGdprPref.analysis = !gdprPrefs.analysis;
              return ({ ...prevGdprPref })
            });
          }}
          dark
        />
      </div>
      <hr />
      <CookieDesc cookieType='partners' />
      <div className={styles.switchContainer}>
        <OwardSwitch
          label={t(`gdpr.partners.activate_all`)}
          active={gdprPrefs.youtube && gdprPrefs.vimeo && gdprPrefs.facebook && gdprPrefs.arte && gdprPrefs.soundcloud}
          onChange={() => {
            setGdprPrefs((prevGdprPref) => {
              let deactivateAll: boolean = gdprPrefs.youtube && gdprPrefs.vimeo && gdprPrefs.facebook && gdprPrefs.arte && gdprPrefs.soundcloud;
              prevGdprPref.youtube = deactivateAll ? false : true;
              prevGdprPref.vimeo = deactivateAll ? false : true;
              prevGdprPref.facebook = deactivateAll ? false : true;
              prevGdprPref.arte = deactivateAll ? false : true;
              prevGdprPref.soundcloud = deactivateAll ? false : true;
              return ({ ...prevGdprPref })
            });
          }}
          dark
        />
      </div>
      <p className={styles.partnersList}>{t('gdpr.partners.list')}</p>
      <div className={styles.partnerSwitchContainer}>
        <OwardSwitch
          label={'Youtube'}
          active={gdprPrefs.youtube}
          onChange={() => {
            setGdprPrefs((prevGdprPref) => {
              prevGdprPref.youtube = !gdprPrefs.youtube;
              return ({ ...prevGdprPref })
            });
          }}
          dark
        />
      </div>
      <div className={styles.partnerSwitchContainer}>
        <OwardSwitch
          label={'Vimeo'}
          active={gdprPrefs.vimeo}
          onChange={() => {
            setGdprPrefs((prevGdprPref) => {
              prevGdprPref.vimeo = !gdprPrefs.vimeo;
              return ({ ...prevGdprPref })
            });
          }}
          dark
        />
      </div>
      <div className={styles.partnerSwitchContainer}>
        <OwardSwitch
          label={'Facebook'}
          active={gdprPrefs.facebook}
          onChange={() => {
            setGdprPrefs((prevGdprPref) => {
              prevGdprPref.facebook = !gdprPrefs.facebook;
              return ({ ...prevGdprPref })
            });
          }}
          dark
        />
      </div>
      <div className={styles.partnerSwitchContainer}>
        <OwardSwitch
          label={'Arte'}
          active={gdprPrefs.arte}
          onChange={() => {
            setGdprPrefs((prevGdprPref) => {
              prevGdprPref.arte = !gdprPrefs.arte;
              return ({ ...prevGdprPref })
            });
          }}
          dark
        />
      </div>
      <div className={styles.partnerSwitchContainer}>
        <OwardSwitch
          label={'Soundcloud'}
          active={gdprPrefs.soundcloud}
          onChange={() => {
            setGdprPrefs((prevGdprPref) => {
              prevGdprPref.soundcloud = !gdprPrefs.soundcloud;
              return ({ ...prevGdprPref })
            });
          }}
          dark
        />
      </div>
      <p className={styles.footer}>{t('gdpr.modify', { link: t('sidebar.cookies') })}</p>
    </div>
  );
})

export const GdprModal: React.FC = observer(() => {
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  const [gdprPrefs, setGdprPrefs] = useState<GdprPrefs>({
    functional: userStore.gdprPrefs === undefined ? false : userStore.gdprPrefs.functional,
    analysis: userStore.gdprPrefs === undefined ? false : userStore.gdprPrefs.analysis,
    youtube: userStore.gdprPrefs === undefined ? false : userStore.gdprPrefs.youtube,
    vimeo: userStore.gdprPrefs === undefined ? false : userStore.gdprPrefs.vimeo,
    facebook: userStore.gdprPrefs === undefined ? false : userStore.gdprPrefs.facebook,
    arte: userStore.gdprPrefs === undefined ? false : userStore.gdprPrefs.arte,
    soundcloud: userStore.gdprPrefs === undefined ? false : userStore.gdprPrefs.soundcloud,
  });

  return (
    <Modal
      modalKey={ModalKey.GDPR_PREFS}
      fixedSize
      header={<GpdrModalHeader />}
      footer={<GpdrModalFooter gdprPrefs={gdprPrefs} />}
    >
      <GpdrModalContent
        gdprPrefs={gdprPrefs}
        setGdprPrefs={setGdprPrefs}
      />
    </Modal>
  );
})
