import { makeAutoObservable } from 'mobx';
import { inject, injectable } from 'inversify';
import { ArtworkModalI } from '@oward/openapi';
import { NextRouter } from 'next/router';
import { ApplicationBindings, StoresBindings } from '@container/.';
import { FilterStore } from './filter-store';
import {
  pushModal, pushWithFilterAndModal, unpushModal,
  EditArtworkStore, EditProfileStore
} from '@components/.';

export enum ModalKey {
  ARTWORK = 'artwork',
  CONTACT = 'contact',
  CONTACT_DETAILS = 'contact_details',
  GO_TO_MESSAGES = 'go_to_messages',
  MESSAGES_CREATE_GROUP_DISCUSSION = 'create_group_discussion',
  MESSAGES_MANAGE_GROUP_DISCUSSION = 'manage_group_discussion',
  NEW_PROFILE = 'new_profile',
  NEW_PORTFOLIO = 'new_portfolio',
  NEW_PROFILE_TO_CONTACT = 'new_profile_to_contact',
  NEW_AGENT = 'new_agent',
  NEW_BROADCASTER = 'new_broadcaster',
  NEW_ARTWORK = 'new_artwork',
  NEW_SELECTION = 'new_selection',
  MODIFY_SELECTION = 'modify_selection',
  NEW_FESTIVAL = 'new_festival',
  NEW_FESTIVAL_AWARD = 'new_festival_award',
  NEW_SHORTLIST = 'new_shortlist',
  ADD_SHORTLIST = 'add_shortlist',
  MODIFY_SHORTLIST = 'modify_shortlist',
  ADD_ON_FIRE = 'add_on_fire',
  ADD_BANK_CARD = 'add_bank_card',
  GDPR_PREFS = 'gdpr_prefs',
  RESET_PASSWORD = 'reset_password',
  NEWSLETTER = 'newsletter',
  REPORT_BLOCK_PROFILE = 'report_block_profile',
  REPORT_BLOCK_ARTWORK = 'report_block_artwork',
  REPORT_MESSAGE = 'report_message',
  SEND_BRIEF = 'send_brief'
}

interface AddShortlistInfosI {
  profileId: number;
  profileName: string;
  profileJob: string;
}

interface ModifyShortlistInfosI {
  shortlistId: number;
  shortlistName: string;
  shortlistPrivate: boolean;
  shortlistDescription: string;
}

interface ManageGroupDiscussionInfosI {
  discussionName: string;
}

interface ReportProfileInfosI {
  profileName: string;
}

interface ReportArtworkInfosI {
  artworkName: string;
}

interface ContactInfosI {
  profileName: string;
  profileId: number;
}

interface ContactDetailsInfosI {
  profileName: string;
  profileId: number;
  mail: string;
  phoneNumber: string;
}

interface ModalParams {
  key: ModalKey,
  param: number | string,
}

@injectable()
export class ModalStore {
  constructor() {
    makeAutoObservable(this);
  }

  @inject(ApplicationBindings.LOCALE) private locale: string;
  @inject(StoresBindings.FILTER) private filterStore: FilterStore;

  artwork: ArtworkModalI = undefined;
  addShortlistInfos: AddShortlistInfosI = undefined;
  modifyShortlistInfos: ModifyShortlistInfosI = undefined;
  manageGroupDiscussionInfos: ManageGroupDiscussionInfosI = undefined;
  reportProfileInfos: ReportProfileInfosI = undefined;
  reportArtworkInfos: ReportArtworkInfosI = undefined;
  contactInfos: ContactInfosI = undefined;
  contactDetailsInfos: ContactDetailsInfosI = undefined;
  editProfileLocallStore: EditProfileStore = undefined;
  editArtworkLocallStore: EditArtworkStore = undefined;

  setArtwork(artwork: ArtworkModalI) {
    this.artwork = artwork;
  }

  setAddShortlistInfos(addShortlistInfos: AddShortlistInfosI) {
    this.addShortlistInfos = addShortlistInfos;
  }

  setModifyShortlistInfos(modifyShortlistInfos: ModifyShortlistInfosI) {
    this.modifyShortlistInfos = modifyShortlistInfos;
  }

  setManageGroupDiscussionInfos(manageGroupDiscussionInfos: ManageGroupDiscussionInfosI) {
    this.manageGroupDiscussionInfos = manageGroupDiscussionInfos;
  }

  setReportProfileInfos(reportProfileInfos: ReportProfileInfosI) {
    this.reportProfileInfos = reportProfileInfos;
  }

  setReportArtworkInfos(reportArtworkInfos: ReportArtworkInfosI) {
    this.reportArtworkInfos = reportArtworkInfos;
  }

  setContactInfos(contactInfos: ContactInfosI) {
    this.contactInfos = contactInfos;
  }

  setContactDetailsInfos(contactDetailsInfos: ContactDetailsInfosI) {
    this.contactDetailsInfos = contactDetailsInfos;
  }

  setEditProfileLocalStore(editProfileLocallStore: EditProfileStore) {
    this.editProfileLocallStore = editProfileLocallStore;
  }

  setEditArtworkLocalStore(editArtworkLocallStore: EditArtworkStore) {
    this.editArtworkLocallStore = editArtworkLocallStore;
  }

  // If no modalKey, check if any modal is opened
  isModalOpened(router: NextRouter, modalKey?: ModalKey): boolean {
    if (modalKey) {
      return !!router.query[modalKey];
    }
    else {
      for (const modalKey of Object.values(ModalKey)) {
        if (!!router.query[modalKey]) {
          return true;
        }
      }
      return false;
    }
  }

  modalStack: ModalParams[] = new Array<ModalParams>();

  // Open a Modal and store it in the Modal Stack
  popModal(router: NextRouter, modalKey: ModalKey, modalParam: number | string) {
    this.modalStack.push({ key: modalKey, param: modalParam });
    this.openModal(router, modalKey, modalParam);
  }

  // Open a Modal, with a new Modal Stack
  openModalNewStack(router: NextRouter, modalKey: ModalKey, modalParam: number | string) {
    this.unpopAll();
    this.modalStack.push({ key: modalKey, param: modalParam });
    this.openModal(router, modalKey, modalParam);
  }

  // If a Modal was previsouly opened (i.e. in the Modal Stack), open it, otherwise close the Modal
  unpopModal(router: NextRouter) {
    if (this.modalStack.length <= 1) {
      // It's the last Modal on the Stack, just close the Modal and clean the Stack
      this.closeModal(router);
      this.unpopAll()
    }
    else {
      this.modalStack.pop(); // Pop to remove the current modal
      let previousModal: ModalParams = this.modalStack.slice(-1)[0];
      this.openModal(router, previousModal?.key, previousModal?.param);
    }
  }

  // Used to reset Modal Stack
  unpopAll() {
    this.modalStack = new Array<ModalParams>();
  }

  openModal(router: NextRouter, modalKey: ModalKey, modalParam: number | string) {
    if (router.pathname !== `/[lang]` && router.pathname !== `/[lang]/`) {
      pushModal(router, modalKey, modalParam);
    }
    else {
      pushWithFilterAndModal(
        router,
        this.locale,
        this.filterStore.filter,
        this.filterStore.label,
        modalKey,
        modalParam
      );
    }
  }

  closeModal(router: NextRouter) {
    if (router.pathname !== `/[lang]` && router.pathname !== `/[lang]/`) {
      unpushModal(router);
    }
    else {
      pushWithFilterAndModal(
        router,
        this.locale,
        this.filterStore.filter,
        this.filterStore.label
      );
    }
  }
}
