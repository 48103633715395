import React from 'react';
import Error from 'next/error';
import { User, UserRole } from '@oward/openapi';
import { useInjection } from '@hooks/.';
import { UserStore } from '@stores/.';
import { StoresBindings } from '@container/.';

export interface AuthenticationProps {
  currentUser: User | null;
  authorizedRoles?: UserRole[];
  children?: React.ReactNode;
}

export const WithAuthentication: React.FC<AuthenticationProps> = props => {
  const { currentUser, authorizedRoles, children } = props;
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  if (currentUser !== null) {
    userStore.setCurrentUser(currentUser);
  }

  if (authorizedRoles) {
    if (currentUser === null || !authorizedRoles.includes(currentUser.role)) {
      return <><Error
        title={'Unauthorized access'}
        statusCode={401} />
      </>
    }
  }

  return (
    <React.Fragment>{children}</React.Fragment>
  );
}
