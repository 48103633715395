import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite'
import { useInjection, useTranslate } from '@hooks/.';
import { ModalStore, ModalKey, UserStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import {
  Request, send, Shortlist, Profile, ShortlistController,
  OnFireController, ProfileController, State, Message, MessageController, Discussion, CountOfDiscussionsUnreadRespI, BlockController
} from '@oward/openapi';
import {
  SWR_USER_SHORTLISTS_GET,
  SWR_SHORTLIST_GET_PROFILES,
  SWR_ON_FIRE_PROFILE_IDS,
  SWR_ON_FIRE_PROFILES,
  SWR_PROFILES_FOR_USER,
  SWR_MESSAGES_FOR_DISCUSSION,
  SWR_DISCUSSIONS_FOR_PROFILE,
  SWR_DISCUSSIONS_UNREAD_COUNT_FOR_USER,
  NUMBER_MESSAGES_LOADED_BY_PAGE,
  SWR_BLOCKED_PROFILE_IDS,
  SWR_BLOCKED_ARTWORK_IDS,
} from '@utils/.';
import { useRouter } from 'next/router';

const apiFetcher = async (_url: any, req: Request<any>) => {
  return await send(req);
};

export function useUserShortlistsGet() {
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  const { data, error } = useSWR(
    userStore.isLogged ? SWR_USER_SHORTLISTS_GET : null,
    url => apiFetcher(url, ShortlistController.get()) as Promise<Shortlist[]>
  );

  return {
    shortlists: data,
    isLoading: !error && !data,
    isError: error
  }
}

export function useShortlistGetProfiles() {
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);
  const router = useRouter();

  const { data, error, isValidating } = useSWR(
    (userStore.isLogged && modalStore.isModalOpened(router, ModalKey.MODIFY_SHORTLIST)) ? SWR_SHORTLIST_GET_PROFILES : null,
    url => apiFetcher(url, ShortlistController.getProfiles(modalStore.modifyShortlistInfos?.shortlistId)) as Promise<Profile[]>,
    { dedupingInterval: 100 }
  );

  return {
    profiles: data,
    isValidating: isValidating,
    isError: error
  }
}


export function useOnFireProfileIds() {
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  const { data, error } = useSWR(
    userStore.isLogged ? SWR_ON_FIRE_PROFILE_IDS : null,
    url => apiFetcher(url, OnFireController.getProfileIds()) as Promise<number[]>
  );

  return {
    onFireProfileIds: data,
    isLoading: !error && !data,
    isError: error
  }
}

export function useOnFireProfiles() {
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  const { data, error } = useSWR(
    userStore.isLogged ? SWR_ON_FIRE_PROFILES : null,
    url => apiFetcher(url, OnFireController.getProfiles()) as Promise<Profile[]>,
  );

  return {
    onFireProfiles: data,
    isLoading: !error && !data,
    isError: error
  }
}

export function useProfilesForUser(locale: string) {
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  const { data, error } = useSWR(
    userStore.isLogged ? SWR_PROFILES_FOR_USER : null,
    url => apiFetcher(url, ProfileController.findForUser(locale)) as Promise<Profile[]>
  );

  return {
    profiles: data?.filter(profile => profile.state !== State.DELETED),
    liveProfiles: data?.filter(profile => profile.state === State.LIVE),
    isLoading: !error && !data,
    isError: error
  }
}

export function useMessagesForDiscussion(discussionId: number) {
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  const { data, error, size, setSize, mutate, isValidating } = useSWRInfinite(
    (index) => {

      let returnValue = (userStore.isLogged && discussionId) ?
        [SWR_MESSAGES_FOR_DISCUSSION + `?index=${index}`, discussionId]
        :
        null;
      return returnValue;
    },
    async (url: string) => {
      const urlParams = new URLSearchParams(url.split('?')[1]);

      return apiFetcher(
        url,
        MessageController.getDiscussionMessages({
          discussionId: discussionId,
          numberOfMessagesToLoad: NUMBER_MESSAGES_LOADED_BY_PAGE,
          offsetOfMessagesToLoad: (parseInt(urlParams.get('index'))) * NUMBER_MESSAGES_LOADED_BY_PAGE
        })
      ) as Promise<Message[]>
    },
    { refreshInterval: 3000 }
  );

  const sortAndRemoveDuplicate = (messages: Message[]) => {
    // messages are sorted in anti-chronological order (we fetch more recent messages first)
    // We remove duplicates (can happen if receiving new messages between two 'inifite scroll reload')
    return messages.sort((a, b) => a.id - b.id).filter(function (item, pos, ary) {
      return !pos || item.id != ary[pos - 1].id;
    })
  }

  // See https://swr.vercel.app/examples/infinite-loading
  const isLoadingInitialMessages = !error && !data;
  const isEmpty = data?.[0]?.length === 0;
  const isReachingEnd = isEmpty || (data && data[data.length - 1]?.length < NUMBER_MESSAGES_LOADED_BY_PAGE);

  return {
    messages: data ? sortAndRemoveDuplicate([].concat(...data)) : [],
    mutateMessages: mutate,
    isLoadingInitialMessages: isLoadingInitialMessages,
    isLoadingMore: (size > 0 && data && typeof data[size - 1] === 'undefined'),
    isReachingEnd: isReachingEnd,
    isError: error,
    size: size,
    setSize: setSize,
  }
}

export function useDiscussionsForProfile(profileId: number) {
  const { locale } = useTranslate();
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  const { data, error } = useSWR(
    (userStore.isLogged && profileId) ? [SWR_DISCUSSIONS_FOR_PROFILE, profileId] : null,
    url => apiFetcher(
      url,
      MessageController.getDiscussionsForProfile(profileId, locale),
    ) as Promise<Discussion[]>,
    { refreshInterval: 5000 }
  );

  return {
    discussions: data,
    isLoading: !error && !data,
    isError: error
  }
}

export function useNumberOfDiscussionsUnread() {
  const { locale } = useTranslate();
  const userStore = useInjection<UserStore>(StoresBindings.USER);
  const { profiles } = useProfilesForUser(locale);

  const { data, error } = useSWR(
    (userStore.isLogged && profiles?.length > 0) ? SWR_DISCUSSIONS_UNREAD_COUNT_FOR_USER : null,
    url => apiFetcher(
      url,
      MessageController.getCountOfDiscussionsUnread({ profileIds: profiles.map(profile => profile.id) }),
    ) as Promise<CountOfDiscussionsUnreadRespI[]>,
    { refreshInterval: 5000 }
  );

  return {
    countOfUnreadDiscussions: data?.map(e => e.count).reduce((p, n) => p + n),
    countOfUnreadDiscussionsByProfil: data,
    isLoading: !error && !data,
    isError: error
  }
}

export function useBlockedProfileIds() {
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  const { data, error } = useSWR(
    userStore.isLogged ? SWR_BLOCKED_PROFILE_IDS : null,
    url => apiFetcher(url, BlockController.getBlockedProfileIds()) as Promise<number[]>
  );

  return {
    blockedProfileIds: data,
    isLoading: !error && !data,
    isError: error
  }
}

export function useBlockedArtworkIds() {
  const userStore = useInjection<UserStore>(StoresBindings.USER);

  const { data, error } = useSWR(
    userStore.isLogged ? SWR_BLOCKED_ARTWORK_IDS : null,
    url => apiFetcher(url, BlockController.getBlockedArtworkIds()) as Promise<number[]>
  );

  return {
    blockedArtworkIds: data,
    isLoading: !error && !data,
    isError: error
  }
}
