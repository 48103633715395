import React, { useContext, useState } from "react";
import { useFormik } from 'formik';
import { observer } from "mobx-react-lite";
import { State, MessageController, DiscussionMember } from "@oward/openapi";
import { Section, SubmitOrCancel, NavigationContext, FormComment } from '@components/admin';
import { Heading, OwardFormInput, OwardFormSelect, OwardFormTextArea } from '@components/Core';
import { EditProps } from '../types';
import { stateToEmoji } from '@oward/common-utils';
import { DiscussionMemberRole } from "@oward/common-enums";
import { useTranslate } from "@hooks/use-translate";

export const EditDiscussionMember = observer((props: EditProps<DiscussionMember>) => {
  const { t } = useTranslate();
  const [errorMsg, setErrorMsg] = useState('');
  const navigation = useContext(NavigationContext);

  const formik = useFormik({
    initialValues: {
      state: props.item.state,
      role: props.item.role,
    },
    onSubmit: async values => {
      props.item.state = values.state;
      props.item.role = values.role;
      try {
        console.log(props.item);
        await MessageController.saveOneMemberAdmin(props.item);
        if (props.notify) { props.notify(props.item) };
        setErrorMsg('');
        navigation.unpop();
      } catch (err) {
        setErrorMsg(t('global.error_with_code', { code: err }));
      }
    }
  });

  return (
    <React.Fragment>
      <Section
        title='Edit'
        subtitle='Change your entity properties'
        childrenFullWidth
      >
        <form className='box' onSubmit={formik.handleSubmit} >
          <Heading>General</Heading>
          <FormComment>
            <p>🗑️ DELETED = Left the discussion</p>
            <p>🚫 BANNED = Was Removed form the Discussion by an Admin</p>
          </FormComment>
          <OwardFormSelect
            id='state'
            label='State *'
            options={Object.values(State).map((state) => {
              return ({ value: state, label: state.concat(' ', stateToEmoji(state)) });
            })}
            formik={formik}
          />
          <OwardFormSelect
            id='role'
            label='Role *'
            options={Object.values(DiscussionMemberRole).map((role) => {
              return ({ value: role, label: role });
            })}
            formik={formik}
          />
          <SubmitOrCancel errorMsg={errorMsg} noCancel={props.noCancel} />
        </form >
        <div style={{ height: '8rem' }} />
      </Section >
    </React.Fragment>
  )
});
