import React, { ReactNode } from 'react';
import { useRouter } from 'next/router';
import ReactModal from 'react-modal';
import styles from './Modal.module.scss';
import { StoresBindings } from '@container/.';
import { useInjection, useTranslate } from '@hooks/.';
import { ModalStore, ModalKey } from '@stores/.';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { OwardButton } from '@components/.';


interface ModalProps {
  modalKey: ModalKey | ModalKey[];
  dark?: boolean;
  fixedSize?: boolean;
  noPadding?: boolean;
  header?: ReactNode;
  footer?: ReactNode;
  children?: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = observer(props => {
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  const unpopAllModals = () => {
    modalStore.unpopAll();
    modalStore.unpopModal(router);
  }

  const isOpened = () => {
    if (Array.isArray(props.modalKey)) {
      for (const modalKey in props.modalKey) {
        if (!!router.query[`${props.modalKey[modalKey]}`]) {
          return true;
        }
      }
      return false;
    }
    else {
      return !!router.query[`${props.modalKey}`];
    }
  }

  return (
    <ReactModal
      isOpen={isOpened()}
      onRequestClose={unpopAllModals}
      className={classNames(
        styles.modal,
        { [styles.modalDark]: props.dark },
        { [styles.modalFixedSize]: props.fixedSize },
        { [styles.modalNoPadding]: props.noPadding }
      )}
      overlayClassName={classNames(styles.modalOverlay, { [styles.modalOverlayDark]: props.dark }, { [styles.modalOverlayFixedSize]: props.fixedSize })}
      htmlOpenClassName={'ReactModal__Html--open'} // Use to block gallery scroll when modal is opened
    >
      <div className={styles.closeIconContainer}>
        <p className={classNames(styles.closeIcon, { [styles.closeIconDark]: !props.dark && props.fixedSize })} onClick={unpopAllModals}>
          <span className={`icon`}>
            <i className='fas fa-times'></i>
          </span>
        </p>
      </div>
      {
        props.header &&
        <React.Fragment>
          <header className={styles.headerContainer}>
            {props.header}
          </header>
          <hr className={styles.hrHeaderFooter} />
        </React.Fragment>
      }
      <div className={classNames(styles.contentContainer, !props.noPadding && styles.contentContainerPadding)}>
        {props.children}
      </div>
      {
        props.footer &&
        <React.Fragment>
          <hr className={styles.hrHeaderFooter} />
          <footer className={styles.footerContainer}>
            {props.footer}
          </footer>
        </React.Fragment>
      }
    </ReactModal>
  );
});

interface ModalConfirmOrCancelButtonsProps {
  confirmTxt?: string;
  cancelationTxt?: string;
  error?: string;
  validation?: () => void;
  noValidation?: boolean;
  cancelation?: () => void;
}

export const ModalConfirmOrCancelButtons: React.FC<ModalConfirmOrCancelButtonsProps> = observer(props => {
  const { t } = useTranslate();
  const router = useRouter();
  const modalStore = useInjection<ModalStore>(StoresBindings.MODAL);

  return (
    <div className={styles.buttonsAndErrorContainer}>
      <p className={styles.error}>{props.error}</p>
      <div className={styles.buttonsContainer}>
        {
          !props.cancelation ?
            <OwardButton name={props.cancelationTxt ?? t('global.cancel')} outlined onClick={() => { modalStore.unpopModal(router); }} />
            :
            <OwardButton name={props.cancelationTxt ?? t('global.cancel')} outlined onClick={props.cancelation} />
        }
        {
          !props.noValidation &&
          <React.Fragment>
            {
              !props.validation ?
                <OwardButton name={props.confirmTxt ?? t('global.confirm')} submit />
                :
                <OwardButton name={props.confirmTxt ?? t('global.confirm')} onClick={props.validation} />
            }
          </React.Fragment>
        }
      </div>
    </div>
  )
});

export const ModalCancelButton: React.FC<ModalConfirmOrCancelButtonsProps> = props => {
  return (
    <ModalConfirmOrCancelButtons
      noValidation
      error={props.error}
      cancelation={props.cancelation}
    />
  )
}

interface ModalTitleProps {
  name: string;
}

export const ModalTitle: React.FC<ModalTitleProps> = props => {
  return (
    <p className={styles.title}>{props.name}</p>
  );
}

export const ModalSubTitle: React.FC<ModalTitleProps> = props => {
  return (
    <p className={styles.subtitle} >{props.name}</p>
  );
}
