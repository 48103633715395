import React from 'react';
import styles from './EditProfile.module.scss';
import { useInjection, useTranslate } from '@hooks/.';
import { PopupStore } from '@stores/.';
import { StoresBindings } from '@container/.';
import {
  EditProfilePannelProps, OwardFormInput, OwardFormSwitch, ToastSucess, ToastError
} from '@components/.';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import shallowequal from 'shallowequal';
import { Contact, ProfileController, ProfileSaveContact, Visibility } from '@oward/openapi';
import { toast } from 'react-toastify';
import { EditProfileHeader, EditProfileSaveOrCancel } from './EditProfileCommon';
import { ERR_MSG_UNAUTHORIZED, goToHomePageAndReload } from '@utils/.';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import { action } from 'mobx';

const schema = (t: any) => {
  return (
    Yup.object().shape({
      emailPerso: Yup.string().email(t('forms.email.invalid')),
    }))
};

export const EditProfileConfidentiality: React.FC<EditProfilePannelProps> = observer(props => {
  const { t, locale } = useTranslate();
  const router = useRouter();
  const popupStore = useInjection<PopupStore>(StoresBindings.POPUP);

  const formik = useFormik({
    initialValues: {
      emailPerso: props.store.profile.contact.emailPerso,
      isPrivate: props.store.profile.visibility === Visibility.PRIVATE
    },
    validationSchema: schema(t),
    onSubmit: async values => {
      if (!shallowequal(formik.initialValues, formik.values)) {
        let profileSaveContact: ProfileSaveContact = {
          id: props.store.profile.id,
          emailPerso: values.emailPerso,
          isPublic: !values.isPrivate,
        }
        try {
          props.store.setLoading(t('edit_profile.loading.saving_profile'));
          await ProfileController.saveContact(profileSaveContact);
          toast.dark(<ToastSucess msg={t('edit_profile.saved')} />);
        }
        catch (err) {
          if (err instanceof Error && err.message === ERR_MSG_UNAUTHORIZED) {
            popupStore.openInformationPopUp({
              msg: t('global.force_logout'),
              callback: () => { goToHomePageAndReload() }
            })
          }
          else {
            toast.dark(<ToastError msg={t('edit_profile.error.saving_profile', { code: err })} />);
          }
        }
        finally {
          props.store.setLoading(undefined);
        }
      }
      else {
        toast.dark(<ToastSucess msg={t('edit_profile.saved_no_modif')} />);
      }

      props.store.reset();
    }
  });


  const resetWithConfirmation = () => {
    if (!shallowequal(formik.initialValues, formik.values)) {
      popupStore.openConfirmationPopUp({
        msg: t('edit_profile.go_back_confirm'),
        callback: props.store.reset
      });
    }
    else {
      props.store.reset();
    }
  }

  return (
    <div className={styles.formMainContainer}>
      <div className={classNames(styles.contentContainer)}>
        <EditProfileHeader
          onGoBack={resetWithConfirmation}
          title={t('edit_profile.' + props.store.activeCategory + '.title', { name: props.store.profile?.name })}
        />
        <form className={classNames(styles.formContentContainer)} onSubmit={formik.handleSubmit} >
          {
            /*<OwardFormInput
              id='emailPerso'
              type='mail'
              label={t('edit_profile.contact.mail')}
              description={t('edit_profile.contact.mail_explanation')}
              icon='fas fa-envelope'
              placeholder={t('forms.email.placeholder')}
              onChange={action(value => {
                // Copy and modify so MobX know contact has been changed (for preview to update)
                let contact: Contact = { ...props.store.profile.contact };
                contact.emailPerso = value;
                props.store.profile.contact = contact
              })}
              formik={formik}
            />
            <hr />
            */
          }
          <OwardFormSwitch
            id='isPrivate'
            label={t('edit_profile.confidentiality.is_private')}
            description={t('edit_profile.confidentiality.is_private_description')}
            formik={formik}
          />
        </form>
      </div>
      <form onSubmit={formik.handleSubmit} className={styles.formFooterContainer}>
        <EditProfileSaveOrCancel onCancel={resetWithConfirmation} formik={formik} />
      </form>
    </div >
  );
})
