import React, { useEffect, useState } from 'react';
import styles from './PaymentPage.module.scss';
import { useTranslate } from '@hooks/.';
import { LayoutPageCentered, Link, OwardButton, OwardLinkButton, PaymentPageComponentProps, PaymentRecap, PaymentSucceded, StripeCheckout, StripeCreateCustomerForm } from '@components/.';
import { useLocalObservable, observer } from 'mobx-react-lite';
import { ProfileController, ProfileSubscriptionsPrice, send, StripePaymentMethodInfos, SubscriptionController } from '@oward/openapi';
import { action } from 'mobx';
import { StripeSubscriptionStatus } from '@oward/common-utils';
import { cardBrandToFontawesomIcon, cardLast4ToString, isPaymentMethodExpired, subscriptionStatusNeedPayment } from '@utils/utils';
import moment from 'moment';
import { ERR_MSG_CONFLICT } from '@utils/constants';
import { useRouter } from 'next/router';
import classNames from 'classnames';

export interface PaymentPageStore {
  profileId: number;
  stripePrice: ProfileSubscriptionsPrice;
  stripeCustomerId?: string;
  stripeCustomerDefaultPaymentMethodInfos?: StripePaymentMethodInfos;
  clientSecret: string;
  setClientSecret: (clientSecret: string) => void;
  useNewCard: boolean;
  setUseNewCard?: (useNewCard: boolean) => void;
  succeeded?: boolean;
  setSucceeded: (succeded: boolean) => void;
  loading?: boolean;
  setLoading: (loading: boolean) => void;
  error: string;
  setError: (error: string) => void;
}

interface PaymentPageCommonProps {
  profileId: number;
  stripePrice: ProfileSubscriptionsPrice;
  stripeCustomerId?: string;
  stripeCustomerDefaultPaymentMethodInfos?: StripePaymentMethodInfos;
}

export interface PaymentPageNewSubscriptionProps extends PaymentPageCommonProps { }

export const PaymentPageNewSubscription: React.FC<PaymentPageNewSubscriptionProps> = observer(props => {
  const { t } = useTranslate();

  const store: PaymentPageStore = useLocalObservable(() => ({
    profileId: props.profileId,
    stripePrice: props.stripePrice,
    stripeCustomerId: props.stripeCustomerId,
    stripeCustomerDefaultPaymentMethodInfos: props.stripeCustomerDefaultPaymentMethodInfos,
    clientSecret: undefined,
    setClientSecret: action((clientSecret) => { store.clientSecret = clientSecret }),
    useNewCard: (props.stripeCustomerDefaultPaymentMethodInfos && !isPaymentMethodExpired(props.stripeCustomerDefaultPaymentMethodInfos)) ? false : true,
    setUseNewCard: action((useNewCard) => { store.useNewCard = useNewCard }),
    succeeded: false,
    setSucceeded: action((succeeded) => { store.succeeded = succeeded }),
    loading: false,
    setLoading: action((loading) => { store.loading = loading }),
    error: undefined,
    setError: action((error) => { store.error = error }),
  }));

  useEffect(() => {
    if (store.profileId) {
      Promise.resolve(ProfileController.visitPaymentPage(store.profileId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.profileId]);

  return (
    <React.Fragment>
      <LayoutPageCentered
        title={t('payment.page_title')}
      >
        {
          store.succeeded ?
            <PaymentSucceded store={store} sayWelcome />
            :
            <React.Fragment>
              <PaymentRecap store={store} />
              <div className={styles.paymentInfosContainer}>
                <p className={styles.firstPayment}>{t('payment.first_payment')}</p>
              </div>
              <React.Fragment>
                {
                  store.stripeCustomerId ?
                    <DefaultPaymentMethodOrNewCard store={store} />
                    :
                    <div className={styles.stripeContainer}>
                      <StripeCreateCustomerForm store={store} />
                    </div>
                }
              </React.Fragment>
            </React.Fragment>
        }
      </LayoutPageCentered>
    </React.Fragment >
  );
});

const DefaultPaymentMethodOrNewCard: React.FC<PaymentPageComponentProps> = observer(props => {
  const { t, locale } = useTranslate();
  const [useDefaultPayment, setUseDefaultPayment] = useState(false);

  const cardInfo = props.store.stripeCustomerDefaultPaymentMethodInfos;

  return (
    <React.Fragment>
      {
        (props.store.useNewCard || useDefaultPayment) ?
          <CreateSubAndCheckout store={props.store} />
          :
          <div className={styles.defaultOrNewCardContainer}>
            <div
              className={styles.defaultContainer}
            >
              <p className={styles.title}>{t('payment.default_card')}</p>
              <div className={styles.cardInfoContainer}>
                <p className={styles.number}>
                  <span className={classNames(`icon`, styles.brandIcon)}>
                    <i className={cardBrandToFontawesomIcon(cardInfo.brand)}></i>
                  </span>
                  {cardLast4ToString(cardInfo.last4)}
                </p>
                <p className={styles.expiration}>
                  {t('account.user.payment_methods.expires', {
                    date: `${moment().month(cardInfo.expMonth - 1).locale(locale).format('MMMM')} ${cardInfo.expYear}`
                  })}
                </p>
              </div>
              <OwardButton
                name={t('payment.charge_default_card')}
                onClick={() => { setUseDefaultPayment(true) }}
              />
            </div>
            <div className={styles.newCardContainer}>
              <p className={styles.link} onClick={() => { props.store.setUseNewCard(true) }}>{t('payment.use_another_card')}</p>
            </div>
          </div>
      }
    </React.Fragment>
  )
});

const CreateSubAndCheckout: React.FC<PaymentPageComponentProps> = observer(props => {
  const { t, locale } = useTranslate();
  const router = useRouter();

  useEffect(() => {
    (async function createSubscription() {
      try {
        props.store.setLoading(true);
        props.store.setError(undefined);
        let subscriptionCreated = await send(SubscriptionController.createStripeSubscription(
          props.store.stripePrice.stripePriceId,
          props.store.stripeCustomerId,
          props.store.profileId,
          props.store.useNewCard ? undefined : props.store.stripeCustomerDefaultPaymentMethodInfos.id
        ));
        props.store.setClientSecret(subscriptionCreated.clientSecret);
        props.store.setLoading(false);
      }
      catch (err) {
        if (err instanceof Error && err.message === ERR_MSG_CONFLICT) {
          props.store.setError(t('payment.error_payment_intent', { code: err }));
          props.store.setLoading(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.store.stripeCustomerId, props.store.profileId, props.store.stripePrice.stripePriceId]);

  return (
    <div className={styles.stripeContainer}>
      <StripeCheckout store={props.store} />
    </div>
  );
});

export interface PaymentPageLastInvoiceProps extends PaymentPageCommonProps {
  clientSecret: string;
  subscriptionStatus: StripeSubscriptionStatus;
  invoiceDueDate: number;
}

export const PaymentPageLastInvoice: React.FC<PaymentPageLastInvoiceProps> = observer(props => {
  const { t, locale } = useTranslate();

  const store: PaymentPageStore = useLocalObservable(() => ({
    profileId: props.profileId,
    stripePrice: props.stripePrice,
    stripeCustomerId: props.stripeCustomerId,
    clientSecret: props.clientSecret,
    setClientSecret: action((clientSecret) => { store.clientSecret = clientSecret }),
    useNewCard: true,
    succeeded: false,
    setSucceeded: action((succeeded) => { store.succeeded = succeeded }),
    loading: false,
    setLoading: action((loading) => { store.loading = loading }),
    error: undefined,
    setError: action((error) => { store.error = error }),
  }));

  return (
    <React.Fragment>
      {
        store.succeeded ?
          <LayoutPageCentered>
            <PaymentSucceded store={store} />
          </LayoutPageCentered>
          :
          <React.Fragment>
            {
              subscriptionStatusNeedPayment(props.subscriptionStatus) ?
                <LayoutPageCentered
                  title={t('payment.page_title')}
                >
                  <PaymentRecap store={store} />
                  <div className={styles.paymentInfosContainer}>
                    {
                      (props.subscriptionStatus === 'past_due' || props.subscriptionStatus === 'unpaid') ?
                        <p className={styles.latePayment}>{
                          props.invoiceDueDate > 0 ?
                            t('payment.late_payment', { date: moment(props.invoiceDueDate).locale(locale).format('DD MMMM yyyy') })
                            :
                            t('payment.late_payment_no_date')
                        }</p>
                        :
                        <p className={styles.firstPayment}>{t('payment.first_payment')}</p>
                    }
                  </div>
                  <div className={styles.stripeContainer}>
                    {
                      store.stripeCustomerId ?
                        <StripeCheckout store={store} />
                        :
                        <StripeCreateCustomerForm store={store} />
                    }
                  </div>
                </LayoutPageCentered>

                :
                <LayoutPageCentered>
                  <div className={styles.noPaymentNeededContainer}>
                    <p className={styles.noPaymentNeeded}>{t('payment.no_payment_needed')}</p>
                    <Link href='/account/profile/[profileId]' as={`/account/profile/${props.profileId}`} passHref>
                      <OwardLinkButton
                        name={t('subscriptions.already_active.go_to_profile')}
                      />
                    </Link>
                  </div>
                </LayoutPageCentered>
            }
          </React.Fragment >
      }
    </React.Fragment >
  );
});







